import React, { Component } from "react";    
import {connect} from 'react-redux'; 
import { withTranslation } from 'react-i18next';

class ConfirmModal extends Component {
	constructor(){
	    super();
	}

    render() {
        const { t } = this.props;
        return (
        	<div className={
                this.props.showing
                ? "custom-modal-container show-modal"
                : "custom-modal-container"
            }
            size="sm">
                <div className={
                    this.props.showing
                        ? "modal modal-action modal-dialog is-show border-radius-4"
                        : "modal modal-action modal-dialog border-radius-4"
                    }>
                    {this.props.title && (
                      <div className="d-flex w-100 modal-header-charge">
                          <div className="d-flex justify-content-center align-items-center">
                              <i className="icon-service" />
                          </div>
                          <div className="header-ttl pr-5 fs-12 ml-2 clr-000000 font-weight-bold">{this.props.title}</div>
                      </div>
                    )}
                    <div className="modal-body modal-body-charge">
                        <div className="form-block mt-0 width-100">
                          <div className="form-group lst-payments fs-18 font-weight-bold modal-content-charge">
                              {this.props.content}
                          </div> 
                          <div className="form-group text-center btn-submit-box">
                            <button className="btn btn-green-38 border-radius-4 width-100 fs-16" onClick={this.props.handleClose}>
                              戻る
                            </button>
                          </div>
                        </div>
                    </div>
                </div>
                <div onClick={this.props.handleClose} className="back-drop" />
		        </div>
        ); 
    }
}
 
const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({  
	//
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConfirmModal)); 
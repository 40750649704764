import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setModalUserTargetAction,
  setUserTargetAction,
} from "../../../actions/action_user_targets";

export function GiftToModal() {
  const dispatch = useDispatch();

  const setUserTarget = (payload) => dispatch(setUserTargetAction(payload));
  const modalUserTarget = useSelector(
    (state) => state.userTarget.modalUserTarget
  );
  const userTarget = useSelector((state) => state.userTarget.userTarget);
  const setModalUserTarget = (payload) =>
    dispatch(setModalUserTargetAction(payload));
  const userTargets = useSelector((state) => state.listUserTargets);

  function enterUserTarget() {
    setUserTarget(modalUserTarget);
    window.disappearModal();
  }

  function closeGiftTo() {
    setUserTarget(userTarget);
    window.disappearModal();
  }

  return (
    <div className="modal" tabIndex={-1} id="giftToModal">
      <button
        className="modal-btn"
        id="modalClose"
        type="button"
        onClick={closeGiftTo}
      >
        <svg className="icon">
          <title>閉じる</title>
          <use xlinkHref="/assets/img/common/icon.svg#times" />
        </svg>
      </button>
      <div className="modal-header heading-lg">
        <div className="title">贈り相手</div>
      </div>
      <div className="modal-body">
        <div className="field field-radio field-pill">
          {userTargets.map((item) => (
            <label key={item.menuUserTargetId}>
              <input
                className="before-icon"
                value={item.name}
                readOnly
                type="radio"
                checked={modalUserTarget === item.name}
                onClick={(e) => {
                  if (modalUserTarget === item.name) {
                    setModalUserTarget("");
                  } else setModalUserTarget(e.target.value);
                }}
              />
              <div className="btn btn-sm btn-outline btn-black">
                {item.name}
              </div>
            </label>
          ))}
        </div>
      </div>
      <div className="modal-footer">
        <div className="btn-group">
          <button
            className="btn"
            //  id="enterGiftTo"
            onClick={enterUserTarget}
          >
            決定する
          </button>
        </div>
      </div>
    </div>
  );
}

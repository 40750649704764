import React, { Component } from "react";    
import {connect} from "react-redux"; 
import { withTranslation } from 'react-i18next';

class NotFound404 extends Component {

    render() {
			const { t } = this.props;
			return (
				<div className="shop-not-avai text-center">
					<img src={require('../../../assets/img/icons/gochi_logo_print.png')}
							 width="320" alt={t('other.This feast button cannot be used because the store is not open')}
							 className="image-not-available"
					/>

					<div className="fs-18 font-weight-bold text-left">
						{t('other.This feast button cannot be used because the store is not open')}
					</div>
				</div>
			);
    }
}
const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NotFound404));


import React, { Component } from "react";    
import {connect} from "react-redux";
import { withTranslation } from 'react-i18next';
import {saveGuestInfo} from "../../../actions/action_guest";

class GuestInfo extends Component {
	constructor(props){
		super(props);
		this.state = {
			customerEmail: props.email,
			customerName: props.userName,
		}
		this.emailChangeHandler = this.emailChangeHandler.bind(this);
		this.customerNameChangeHandler = this.customerNameChangeHandler.bind(this);
	}
	emailChangeHandler(e) {
		this.setState({
			customerEmail: e.target.value
		});

		var guestInfo = {
			customerEmail: e.target.value,
			customerName: this.props.guestInfo.customerName
		}
		this.props.saveGuestInfo(guestInfo);
	}

	customerNameChangeHandler(e) {
		this.setState({
			customerName: e.target.value
		});

		var guestInfo = {
			customerEmail: this.props.guestInfo.customerEmail,
			customerName: e.target.value
		}
		this.props.saveGuestInfo(guestInfo);
	}
    render() {
        const { t } = this.props;

		const isNoPayment2 = this.props.menuInfo?.noPayment === 2;

		return (
			<div className="mt-3">
				{/* <div className="divider-dashed"></div> */}

				{/* <label className="ttl-label fs-16">
					{t('pages.invite.receipt')}
				</label> */}
				<div className="required-dot">
					<div className="dot-red"></div>
					<div className="dot-text-label fs-18">{isNoPayment2 ? '申込者情報' : t('pages.invite.receipt')}</div>
				</div>
				<div className="line-horizontal label-ln mb-2"></div>
				<div className="fs-13 pl-1">{isNoPayment2 ? '申込後に受付完了メールを送信いたします。' : t('pages.invite.We will send you a receipt by e-mail')}</div>

				<div className="dish-infor mt-3">
					<label className="ttl-label">
						{isNoPayment2 ? '申込者情報' : t('pages.invite.Customer name')}
						{/* <span className="required-tag">{t('other.requiredTag')} </span> */}
					</label>

					<div className="d-flex align-items-center">
						<input className="form-control"
							placeholder={t('pages.invite.Placeholder customer name')}
							type="text"
							onChange={this.customerNameChangeHandler}
							value={(this.state.customerName ? 
                                        this.state.customerName : 
                                        (this.props.guestInfo && this.props.guestInfo.customerName)) || ''}
                            name="customerName"
                            readOnly={this.props.isShashoku}
						/>
						{/* <span className="ml-2 fs-14 font-weight-bold">{t('pages.invite.sama')}</span> */}
					</div>

					{this.props.customerNameError &&
						<div className="text-danger fs-14 mb-2 fs-12 box-warning-message">{this.props.customerNameError}</div>
					}
				</div>

				<div className="dish-infor mt-3">
					<label className="ttl-label">
						{t('pages.invite.Email address')}
						{/* <span className="required-tag">{t('other.requiredTag')} </span> */}
					</label>

					<input className="form-control"
						   placeholder={t('pages.invite.Placeholder email address')}
						   type="text"
						   onChange={this.emailChangeHandler}
						   value={(this.state.customerEmail ? this.state.customerEmail : (this.props.guestInfo && this.props.guestInfo.customerEmail)) || ''}
						   name="customerEmail"
                           readOnly={this.props.isShashoku}
					/>

					{this.props.customerEmailError &&
						<div className="text-danger fs-14 mb-2 fs-12 box-warning-message">{this.props.customerEmailError}</div>
					}

					{!isNoPayment2 && <>
						<div className="fs-13 mb-2 mt-2 space-pre-line txt-indent-10">{t('pages.invite.Note receipt 1')}</div>
						<div className="fs-13 mb-2 mt-2 space-pre-line txt-indent-10">{t('pages.invite.Note receipt 2')}</div>
						<div className="fs-13 mb-2 mt-2 space-pre-line txt-indent-10">{t('pages.invite.Note receipt 3')}</div>
						<div 
							className="fs-13 mb-2 mt-2 space-pre-line txt-indent-10 gochimeshi-app"
							dangerouslySetInnerHTML={{__html: t('pages.invite.In some cases the email may not arrive due to the settings of the mobile carrier'), interpolation: {escapeValue: false}}}
						/>
					</>}
				</div>

			</div>
        ); 
    }
}

// export default DishInfor;

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
	// saveUserInfo: (isLogined, isGuest, userInfo) => dispatch(saveUserInfo(isLogined, isGuest, userInfo)),
	saveGuestInfo: (guestInfo) => dispatch(saveGuestInfo(guestInfo))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GuestInfo));


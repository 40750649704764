import { HTTP_API, makeRequest } from "../../HttpClient";

const API_BASE_URL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : '';

export default class DonateService {
  static async charge(params, crfsToken) {
    const url = `${API_BASE_URL}/gochi-kid/donate`;
    return makeRequest(HTTP_API.POST, url, params, false, {'csrf_token': crfsToken});
  }

  static async createCheckoutSession(params) {
    const url = `${API_BASE_URL}/gochi-kid/donate/session/create`;
    return makeRequest(HTTP_API.POST, url, params);
  }

  static async completePayment(merchantPaymentId, params) {
    const url = `${API_BASE_URL}/gochi-kid/donate/session/complete/${merchantPaymentId}`;
    return makeRequest(HTTP_API.GET, url, params);
  }

  static async getAvailableBillingGroup() {
    const url = `${API_BASE_URL}/gochi-kid/donate/subscription/available`;
    return makeRequest(HTTP_API.GET, url);
  }

  static async createDonateSubscription(params, crfsToken) {
    const url = `${API_BASE_URL}/gochi-kid/donate/subscription`;
    return makeRequest(HTTP_API.POST, url, params, true, {'csrf_token': crfsToken});
  }

  static async searchRegion(params) {
    const url = `${API_BASE_URL}/gochi-kid/search/region`;
    return makeRequest(HTTP_API.GET, url, params);
  }

  static async searchActivity(params) {
    const url = `${API_BASE_URL}/gochi-kid/search/activity`;
    return makeRequest(HTTP_API.GET, url, params);
  }

  static async getListRankRegion(params) {
    const url = `${API_BASE_URL}/gochi-kid/donate/region/rank`;
    return makeRequest(HTTP_API.GET, url, params);
  }

  static async getListRankActivity(params) {
    const url = `${API_BASE_URL}/gochi-kid/donate/activity/rank`;
    return makeRequest(HTTP_API.GET, url, params);
  }
}

import React from "react";
import { Link } from "react-router-dom";

const SHOP_NO_IMAGE = require("../../../assets/img/icons/shop01_blank.png");

export function ShopSearchItem(props) {
  const { item } = props;
  const { introduction } = item;
  const display =
    introduction?.length >= 120
      ? introduction.slice(0, 119) + "…"
      : introduction;
  return (
    <article
      className={props.loading ? "shop loading" : "shop"}
      key={item.shopId}
    >
      <Link className="shop-link" to={`/shopdetails/${item.shopId}`} />
      <div className="shop-thumbnail">
        <a href={`/shopdetails/${item.shopId}`} className="link-image-shop">
          <img
            src={
              item.shopImage
                ? item.shopImage[0] && item.shopImage[0].image
                : SHOP_NO_IMAGE
            }
            loading="lazy"
            alt=""
          />
        </a>
      </div>
      <div className="shop-header">
        <div className="d-flex">
          {item.genreName && (
            <div className="badge-group">
              <div className="badge badge-outline badge-category badge-gray">
                {item.genreName}
              </div>
            </div>
          )}
          <div className="action-group shop-action-group">
            <div
              className={
                (
                  props.tempShopWishList[item.shopId] !== undefined
                    ? props.tempShopWishList[item.shopId]
                    : item.isWishList
                )
                  ? "action action-list is-active"
                  : "action action-list is-inactive"
              }
            >
              <button
                type="button"
                aria-label="記事を保存"
                onClick={() =>
                  props.onToggleShopWishList(item.shopId, item.isWishList)
                }
              >
                <svg className="icon">
                  <use xlinkHref="/assets/img/common/icon.svg#list" />
                </svg>
              </button>
            </div>
            <div
              className={
                (
                  props.tempShopFavorite[item.shopId] !== undefined
                    ? props.tempShopFavorite[item.shopId]
                    : item.isFavourite
                )
                  ? "action action-like is-active"
                  : "action action-like is-inactive"
              }
            >
              <button
                type="button"
                aria-label="記事を保存"
                onClick={() =>
                  props.onToggleShopFavorite(item.shopId, item.isFavourite)
                }
              >
                <svg className="icon">
                  <use xlinkHref="/assets/img/common/icon.svg#heart" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <h3 className="shop-name text-bold">{item.shopName}</h3>
      </div>
      <div className="shop-body">
        <dl className="list-place">
          <div className="list-place-item">
            <dt className="title">
              <svg className="icon">
                <use xlinkHref="/assets/img/common/icon.svg#map-marker" />
              </svg>
              <span className="title-text">場所</span>
            </dt>
            <dd className="text">
              {`${item.prefectures} ${item.city} ${item.address} ${item.building}`}
            </dd>
          </div>
          <div className="list-place-item">
            <dt className="title">
              <svg className="icon">
                <use xlinkHref="/assets/img/common/icon.svg#map" />
              </svg>
              <span className="title-text">アクセス</span>
            </dt>
            <dd className="text">{item.access}</dd>
          </div>
          <div className="list-place-item">
            <dt className="title">
              <svg className="icon">
                <use xlinkHref="/assets/img/common/icon.svg#train" />
              </svg>
              <span className="title-text">最寄駅</span>
            </dt>
            <dd className="text">
              <ul className="list-block">
                <li>{item.nearestStation}</li>
              </ul>
            </dd>
          </div>
        </dl>
      </div>
      <div className="clear-both"></div>
      <div className="shop-footer mt-20 pre-line">{display}</div>
    </article>
  );
}

import React, { useState } from "react";
import GiftService from "../../../../api/services/GiftService";

export function CancelSubscriptionGochiKid(props) {

  const [uploading, setUploading] = useState(false);

  let destination = '';
  if (props.item.subscriptionDestination) {
    destination = props.item.destinationName;
  } else {
    destination = props.item.destination;
  }
  
  async function sumitCancelSubscription() {
    try {
      const data = await GiftService.postCancelSubGochiKid(
          props.item.merchantPaymentId
      );
      setUploading(false);
      props.handleClosePopupCancelSubscriptionGochiKid();
      props.getGochiKidSupportContinue(1, 'created_at');
      if (data.status === 200) {
        props.handleShowPopupCancelSubscriptionGochiKidSuccess(props.item);
      } else {
        props.handShowErrorUseGochiKidPopup('エラーが発生しました');
      }
      return data;
    } catch (error) {
        console.log(error);
        alert('fail');
        setUploading(false);
    } 
  }

  return (
    <div
      className={`modal modal-profile modal-profile-01 modal-01 modal-lg history-modal cancel-subscription-modal ${props.showPopupCancelSubscriptionGochiKid ? 'is-show' : ''}`}
      tabIndex={-1}
    >
      <div className="header-register-user-kid">
        <button
          className="modal-btn modal-btn-close"
          id="modalClose"
          onClick={props.handleClosePopupCancelSubscriptionGochiKid}
          type="button"
        >
          <svg className="icon">
            <title>閉じる</title>
            <use xlinkHref="/assets/img/common/icon.svg#times" />
          </svg>
        </button>
      </div>
      <div className="title-modal-charge">
        <div className="title"></div>
      </div>
      <div className={`modal-body`}>
        {`本当に${destination}の継続支援を中止してもよろしいでしょうか？`}
      </div>
      <div className="modal-footer">
        <div className="btn-group">
          <button
            onClick={() => sumitCancelSubscription()}
            className={uploading ? 'btn btn-rect loading' : 'btn btn-rect btn-rect-red'}
          >
            中止する
          </button>
          <button
            onClick={props.handleClosePopupCancelSubscriptionGochiKid}
            id="submitConfirm"
            className={'btn btn-outline-rect btn-outline-cancel-red'}
          >
            継続する
          </button>
        </div>
      </div>
    </div>
  );
}

import React from "react";

export function ListShopModal(props) {

  return (
    <div
      id="groupShopsModal"
      className="modal modal-group modal-group-01 modal-01 modal-shops"
      tabIndex={-1}
    >
      <button
        className="modal-btn modal-btn-close"
        id="modalClose"
        onClick={() => window.disappearModal()}
        type="button"
      >
        <svg className="icon">
          <title>閉じる</title>
          <use xlinkHref="/assets/img/common/icon.svg#times" />
        </svg>
      </button>

      <div className="searchShop">
        <div className="inputField">
          <div className="fieldset fieldset-lg">
            <div className="fieldset-header">
              <svg className="icon">
                <use xlinkHref="/assets/img/common/icon.svg#search" />
              </svg>
              <div className="title">キーワード（ジャンル・住所・店舗名等）</div>
            </div>
            <div className="fieldset-body">
              <div className="field field-pill field-lg">
                <input
                  type="text"
                  placeholder="キーワード"
                  value={props.keyword}
                  onChange={(e) => props.setKeyword(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="btnSearchF">
          <div className="fieldset fieldset-submit">
            <button type="button" className="btn btn-submit search-btn" onClick={() => props.ShowListShop()}>
              検索する
            </button>
          </div>
        </div>
      </div>

      {!props.loading && (
        <div className="modal-header heading-sm">
          <div className="title">この商品が使えるお店一覧</div>
          <div className="subtitle">
            <em className="text-xl">{props.totalResults}</em>店舗で利用可能です
          </div>
        </div>
      )}
      
      <div
        style={{ overflow: "auto" }}
        className={props.loading ? "modal-body loading" : "modal-body"}
      >
        <div className="shop-group">
          {props.listShops.map((item) => (
            <article key={item.shopId} className="shop shop-sm">
              <div className="shop-header">
                <h3 className="shop-name text-bold">{item.shopName}</h3>
              </div>
              <div className="shop-body">
                <dl className="list-place text-gray">
                  <div className="list-place-item">
                    <dt className="title">
                      <svg className="icon">
                        <use xlinkHref="/assets/img/common/icon.svg#map-marker" />
                      </svg>
                      {/* <span className="title-text">場所</span> */}
                    </dt>
                    <dd className="text">
                      {item.prefectures + " "}
                      {item.city + ""}
                      {item.address + ""}
                      {item.building}
                    </dd>
                  </div>
                </dl>
              </div>
            </article>
          ))}
        </div>
      </div>
     
      {/* /.modal-body */}
      <div className="modal-footer">
        {props.hasMore && (
          <div className="btn-group">
            <button className="btn btn-rect" onClick={props.loadMore}>
              もっと見る
            </button>
          </div>
        )}
      </div>
      {/* /.modal-body */}
      
    </div>
  );
}

import React, { useState } from "react";
import moment from 'moment';
import {
  uploadImage,
  toBlob
} from "../../../../utils";
import GiftService from "../../../../api/services/GiftService";
const NO_IMAGE_THANK = require("../../../../assets/img/icons/no_image_thank_message.png");
const NO_IMAGE_THANK_CONFIRM = require("../../../../assets/img/icons/no_image_thank_message_confirm.png");

export function SendMessageGochiKid(props) {
  const [hasSubmit, setHasSubmit] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isError, setIsError] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [uploading, setUploading] = useState(null);
  const [gochiKidUseId, setGochiKidUseId] = useState(null);
  const [thankMessage, setThankMessage] = useState("");
  const [thankMessageError, setThankMessageError] = useState(null);
  const [isValid, setIsValid] = useState(false);

  function imagePreview(preview) {
    const reader = new FileReader();
    reader.readAsDataURL(preview);
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
  };

  function handleChange(e) {
    console.log(e);
    const tempThankMessage = e.target.value;
    if (tempThankMessage.length === 0) {
      setThankMessageError("この項目は必須です。");
      setIsValid(false);
    } else if (tempThankMessage.length >= 200) {
      setThankMessageError("200文字以内ご入力ください。");
      setIsValid(true);
    } else {
      setThankMessageError(null);
      setIsValid(true);
    }
    setThankMessage(tempThankMessage);
  }

  async function sumitThankMessage() {
    const params = {
      gochiKidUseId: props.item.gochiKidUseId || null,
      thanksMessage: thankMessage || null,
      thanksImageUrl: null
    }
    if (previewImage) {
      const blob = await toBlob(previewImage, '', () => {
        console.log("Upload img error");
      });
      setUploading(true);
      await uploadImage("user", 1, blob, "public-read")
        .then((data) => {
          console.log("upload response: ", data);
          params.thanksImageUrl = data;
        })
        .catch(e => {
          console.log('Uploading error:', e);
          setUploading(false);
        });
    }
    try {
      const data = await GiftService.uploadThankMessage(
          params
      );
      setUploading(false);
      props.handleClosePopupSendMessageGochiKid();
      props.getUserKidGochiKid(props.page, 'latest');
      if (data.status === 200) {
        props.handleShowPopupSendMessageGochiKidSuccess();
      } else {
        props.handShowErrorUseGochiKidPopup('エラーが発生しました');
      }
      return data;
    } catch (error) {
        console.log(error);
        alert('エラーが発生しました');
        setUploading(false);
    } 
  }

  function comfirmThankMessage() {
    if (thankMessage.trim().length === 0) {
      setThankMessageError("この項目は必須です。");
      setIsValid(false);
      return;
    } else if (isValid === false) {
      return;
    } else {
      setThankMessageError(null);
      setIsValid(true);
    }
    setIsConfirm(true);
  }

  var image = props.item.menuImage && props.item.menuImage.length > 0 && props.item.menuImage[0].image ? 
                  props.item.menuImage[0].image : props.NO_IMAGE_MENU;
  var shopName = props.item.shopName ? props.item.shopName : null;
  var menuName = props.item.menuName ? props.item.menuName : null;
  var userKidNumUse = props.item?.userKidNumUse ? props.item.userKidNumUse : 0;
  var usedAmountPerKid = props.item.usedAmount ? (props.item.usedAmount / (userKidNumUse || 1)) : 0;

  return (
    <div
      className={`modal modal-profile modal-profile-01 modal-01 modal-lg history-modal thank-message-modal ${props.showPopupSendMessageGochiKid ? 'is-show' : ''}`}
      tabIndex={-1}
    >
      <div className="modal-header-history">
        <button
          className="modal-btn modal-btn-close"
          id="modalClose"
          onClick={props.handleClosePopupSendMessageGochiKid}
          type="button"
        >
          <svg className="icon">
            <title>閉じる</title>
            <use xlinkHref="/assets/img/common/icon.svg#times" />
          </svg>
        </button>
      </div>
      <div className="title-modal-charge">
        <div className="title">{isConfirm ? "感謝メッセージの確認" : "感謝メッセージの投稿"}</div>
      </div>
      <div className="body-thank-message-modal">
        {!isConfirm && (
          <div className="required-box">
            <div className="dot-red"></div>
            <div className="text">
              必須入力ですので、必ずご入力お願い致します
            </div>
          </div>
        )}
        <div className="gochi-kid-support-item">
          <dl className="list-description text-sm">
            <div className="products-digest info-shop">
              <div className="products-thumbnail">
                <div className="thumbnail">
                  <img
                    src={image}
                    alt="menu image"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="shop-header-group">
                <div className="shop-header">
                  <div className="shop-name">
                    {shopName}
                  </div>
                  <h2 className="menu-name">
                    {menuName}
                  </h2>
                </div>
                <hr />
                {props.item.menuId
                  ?
                    <div className="price-area">
                      <span className="unit">¥</span>
                      <span className="num">
                        {`${usedAmountPerKid?.toLocaleString()} x ${userKidNumUse}`}
                      </span>
                    </div>
                  :
                    <div className="price-area">
                      <div>
                        <div>
                          <span>利用人数: </span>
                          <span className="num">{userKidNumUse}</span>
                          <span className="unit">人</span>
                        </div>
                        <div>
                          <span>合計金額: </span>
                          <span className="num">{props.item.usedAmount?.toLocaleString()}</span>
                          <span className="unit">円</span>
                        </div>
                      </div>
                    </div>
                }
              </div>
            </div>
            <div className="list-description-item gochi-detail">
              <dt className="title font-weight-normal">利用日</dt>
              <dd className="text font-weight-normal">
                {props.item.usedAt ? props.formatDate(props.item.usedAt) : ''}
              </dd>
            </div>
          </dl>
        </div>
        <div className="required-dot">
          <div className="dot-red"></div>
          <div className="dot-text-label">
            感謝メッセージ
          </div>
        </div>
        <div className="textarea-placeholder">
          {!isConfirm ? (
            <>
              <textarea 
                className={isError ? "form-control mt-1 textarea-message ex-form-required" : "form-control mt-1 textarea-message" } 
                value={thankMessage} 
                onChange={handleChange}
                name="messageShop"
                placeholder="感謝メッセージをご入力ください。"
                maxLength={200}
              />
              <div className="thank-message-error">{thankMessageError}</div>
            </>
          ) : (
            <div className="confirm-thank-message">
              <span>{thankMessage}</span>
            </div>
          )}
        </div>
        {!(isConfirm && !previewImage) && (
          <>
            <div className="title-thank-image">
              写真
            </div>
            <div className="thank-thumbnail-upload thank-image">
              <img
                id="image-result"
                src={previewImage ? previewImage : (isConfirm ? NO_IMAGE_THANK_CONFIRM : NO_IMAGE_THANK)}
                loading="lazy"
                className={`${isConfirm ? "image-thank-confirm" : "image-thank"} ${previewImage ? "have-image" : ""}`}
              />
              {!isConfirm && (
                <>
                  <input
                    name="img-input"
                    id="img-input"
                    style={{ display: "none" }}
                    type="file"
                    accept="image/png, image/jpeg"
                    // data-target="modal01"
                    onChange={(e) => {
                      const fileName =  e.target.files[0].name;
                      const re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
                      if (!re.exec(fileName)) {
                        alert("画像の形式が正しくありません。");
                        return;
                      }
                      if (e.target.files[0]) {
                        imagePreview(e.target.files[0]);
                      }
                      e.target.value = null;
                    }}
                  />
                  <label
                    className="thank-thumbnail-btn"
                    htmlFor="img-input"
                  >
                    <svg className="icon">
                      <title>変更する</title>
                      <use xlinkHref="/assets/img/common/icon.svg#camera" />
                    </svg>
                  </label>
                </>
              )}
            </div>
          </>
        )}
        {!isConfirm && (
          <div className="btn-group">
            <button
              onClick={comfirmThankMessage}
              id="submitConfirm"
              className={'btn btn-rect'}
            >
              確認画面へ進む
            </button>
          </div>
        )}
        {isConfirm && (
          <div className="btn-group">
            <button
              onClick={sumitThankMessage}
              id="submitThankMessage"
              className={uploading ? 'btn btn-rect loading' : 'btn btn-rect'}
            >
              投稿する
            </button>
            <div className="btn-back">
              <button
                onClick={() => setIsConfirm(false)}
              >
                前の画面に戻る
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

import React from "react";

export function BoxHomePageButton() {
  return (
    <div className="background-FDF5F5 box-home-page-button">
      <p className="font-family-rounded title-home-page-button">こどもごちめしで、食と笑顔の輪を広げましょう！</p>
      <div className="box-button">
        <button className="btn-tertiary">
          <i className="icon-support" />
          食堂支援する
        </button>
        <button className="btn-outline-tertiary">
          <i className="icon-use" />
          食堂利用する
        </button>
      </div>
    </div>
  )
}
import React, { useState } from "react";

export function PolicyGochiKidUse(props) {
  const [policy0, setPolicy0] = useState(false);
  const [policy1, setPolicy1] = useState(false);
  const [policy2, setPolicy2] = useState(false);
  const [policy3, setPolicy3] = useState(false);
  const [policy4, setPolicy4] = useState(false);

  const handleRedirectGochiKidUse = () => {
    window.location.href = props.linkRedirect;
  }

  return (
    <div
      id={5342}
      className={`modal modal-profile modal-profile-01 modal-01 modal-lg history-modal policy-gochi-kid-modal ${
        props.showPolicyGochiKidUseModal ? "is-show" : ""
      }`}
      tabIndex={-1}
    >
      <div className="modal-header-history">
        <button
          className="modal-btn modal-btn-close"
          id="modalClose"
          onClick={props.handleClosePolicyGochiKidUseModal}
          type="button"
        >
          <svg className="icon">
            <title>閉じる</title>
            <use xlinkHref="/assets/img/common/icon.svg#times" />
          </svg>
        </button>
      </div>
      <div className={`modal-body`}>
        <div className="policy-text">
          こどもごちめしサービスは、個人や企業からの寄付によって運営しています。
          <br />
          下記をご了承の上ご参加ください。
        </div>
        <div className="checkbox-container">
          <div className="field field-checkbox policy-text-box">
            <label>
              <input
                className="before-icon"
                type="checkbox"
                checked={policy0}
                onChange={(e) => {
                  setPolicy0(e.target.checked);
                }}
              />
              <i className="icon" />
            </label>
            <div className="policy-text-description">
              寄付者や店舗のご好意によって運営されているサービスです。マナーを守り、感謝の気持ちを忘れずにご利用いただきますようお願い申し上げます。
            </div>
          </div>
          <div className="field field-checkbox policy-text-box">
            <label>
              <input
                className="before-icon"
                type="checkbox"
                checked={policy1}
                onChange={(e) => {
                  setPolicy1(e.target.checked);
                }}
              />
              <i className="icon" />
            </label>
            <div className="policy-text-description">
              店舗の利用可能数をご確認ください。残数がない場合はご利用いただけません。
            </div>
          </div>
          <div className="field field-checkbox policy-text-box">
            <label>
              <input
                className="before-icon"
                type="checkbox"
                checked={policy2}
                onChange={(e) => {
                  setPolicy2(e.target.checked);
                }}
              />
              <i className="icon" />
            </label>
            <div className="policy-text-description">
              寄付された方への報告のため、ご利用後はコメントのご記入をお願いいたします。コメントを投稿していないと、次回以降のご利用ができません。
            </div>
          </div>
          <div className="field field-checkbox policy-text-box">
            <label>
              <input
                className="before-icon"
                type="checkbox"
                checked={policy3}
                onChange={(e) => {
                  setPolicy3(e.target.checked);
                }}
              />
              <i className="icon" />
            </label>
            <div className="policy-text-description">
              店舗によって、利用対象者を制限している場合がございます。利用店舗一覧より、ご利用可能店舗を事前にご確認ください。
            </div>
          </div>
          <div className="field field-checkbox policy-text-box">
            <label>
              <input
                className="before-icon"
                type="checkbox"
                checked={policy4}
                onChange={(e) => {
                  setPolicy4(e.target.checked);
                }}
              />
              <i className="icon" />
            </label>
            <div className="policy-text-description">
              <div>ご利用にあたり不適切な行為等があったと当社が判断した場合、予告なくユーザー登録の抹消などを行う事があります。（利用規約第12条）</div>
              <div>利用規約：</div>
              <a href="https://gochimeshi.com/policy/terms/" target="_blank">https://gochimeshi.com/policy/terms/</a>
            </div>
          </div>
        </div>
        <div className="btn-container">
          <button
            className="btn btn-rect btn-accept-policy"
            disabled={!(policy0 && policy1 && policy2 && policy3 && policy4)}
            onClick={() => handleRedirectGochiKidUse()}
          >
            利用する
          </button>
        </div>
      </div>
    </div>
  );
}

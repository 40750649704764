const initialState = {
  thankYouText: null,
  thankYouImage: null,
  giftCardId: null
};

export default (state = initialState, action) => { 
  switch (action.type){
    case 'MESSAGE_CARD':  
      return  {       		
        thankYouText: action.thankYouText,
        thankYouImage: action.thankYouImage,
        giftCardId: action.giftCardId
      };
    default:
      return state;
  }
};
import React, { useEffect, useRef, useState } from "react";
import { log } from "../../../utils";

export default function ReadMoreText({
  className,
  maxLines,
  lineHeight,
  children
}) {
  const contentRef = useRef(null);

  const [pageReady, setPageReady] = useState(false);
  const [truncated, setTruncated] = useState(false);

  const maxHeight = lineHeight * maxLines;

  const onPageLoad = () => {
    setPageReady(true);
  };

  useEffect(() => {
    log('ReadMoreText componentDidMount');
    if (document.readyState === 'complete') {
      log('page already complete');
      onPageLoad();
    } else {
      log('add event listener "load"');
      window.addEventListener('load', onPageLoad);
    }
  }, []);

  useEffect(() => {
    if (!pageReady) {
      return;
    }
    contentRef.current.style.setProperty('line-height', lineHeight + 'px');
    contentRef.current.style.setProperty('-webkit-line-clamp', String(maxLines));
    const contentHeight = contentRef.current.clientHeight;
    if (contentHeight > maxHeight) {
      setTruncated(true);
    }
  }, [pageReady, children, lineHeight, maxLines, maxHeight]);

  let wrapperClassName = 'read-more-text';
  if (className) {
    wrapperClassName += ' ' + className;
  }

  /*
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  */

  return (
    <div
      className={wrapperClassName}
    >
      <div style={truncated ? { maxHeight } : {}}>
        <div ref={contentRef} className={truncated ? 'line-clamp-box' : ''}>
          {children}
        </div>
      </div>
      {truncated && <div>
        <span className="btn-read-more" onClick={() => setTruncated(false)}>もっと見る</span>
      </div>}
    </div>
  );
}

export const getSituationsSuccess = (list = []) => {
    return {
      type : 'GET_SITUATIONS_SUCCESS',
      payload: list 
    }
  };

export const setModalSituationAction = (modalSituation = null) => {
    return {
        type: 'SET_MODAL_SITUATION',
        payload: modalSituation
    }
}

export const setSituationAction = (situation = null) => {
    return {
        type: 'SET_SITUATION',
        payload: situation
    }
}
import React from "react";

export function SliderPagination(props) {
  return (
    <div
      className={
        props.className
          ? props.className + "slider-pagination-area"
          : "slider-pagination-area"
      }
    >
      <div className="slider-btn-group">
        <button className="slider-btn slider-btn-prev">
          <svg className="icon">
            <use xlinkHref="/assets/img/common/icon.svg#angle-left" />
          </svg>
        </button>
        <button className="slider-btn slider-btn-next">
          <svg className="icon">
            <use xlinkHref="/assets/img/common/icon.svg#angle-right" />
          </svg>
        </button>
      </div>
      <div className="swiper-pagination" />
    </div>
  );
}

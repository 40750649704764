import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import {
  useArchive,
  useScript, 
  useStyleSheet
} from "../../../../utils";
import { Footer } from "../../layout/Footer";
import Header from "../../layout/Header";
import GiftService from "../../../../api/services/GiftService";
import { CustomPagination } from "../../common/Pagination";
import { connect } from "react-redux";
import { showLoading } from "../../../../actions/action_loading";
import { showError } from "../../../../actions/action_error_alert";
import { ModalGroup } from "../../common/ModalGroup";
import { SituationModal } from "../../common/SituationModal";
import { GiftToModal } from "../../common/GiftToModal";
import { Link, useLocation } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { Explanation } from "../../layout/Explanation";

export function ListNews(props){
  useStyleSheet("https://unpkg.com/swiper/swiper-bundle.min.css",
    null,
    {
      integrity:
        "sha384-HUXJxzkMpCxEdXkgl8BSjN5SUcUODepsOLIYIWOtKva+Qo2teMmV1mh6ZVwnthaV",
      crossorigin: "anonymous",
      referrerpolicy: "no-referrer",
    }
  );
  useScript("https://unpkg.com/swiper/swiper-bundle.min.js",
    null,
    { integrity: "sha384-XVTmRHMO2XUTzy8IerJVEy8ddg47Yz5pZrLc7nEWipaTowqT1xNb8qTLaw64Bd4S",
      crossorigin: "anonymous"
    }
  );
  useScript("/assets/js/app.js");

  const [page, setPage] = useState(1);
  const LIMIT = 5;
  const [showNews, setShowNews] = useState([]);
  const [totalResult, setTotalResult] = useState(0);
  const [loadingList, setLoadingList] = useState(false);
  const archive = useArchive();
  const location = useLocation();
  const detailYear = location.state?.year;
  const [year, setYear] = useState(detailYear);
  
  const { t } = props;

  useEffect(() =>{
    window.scrollTo(0, 0);
    if (year) {
      getNewsMonth(year, page, LIMIT);
    } else {
      getNews(page, LIMIT);
    }
  }, [year, page, LIMIT]);

  async function getNews(page, perPage) {
    setLoadingList(true);
    GiftService.getNews(page, perPage).then((response) => {
      setLoadingList(false);
      if(response.status === 200){
        setShowNews(response.data.contents);
        setTotalResult(response.data.totalCount);
      } else {
        setShowNews([]);
        setTotalResult(0);
      }
    })
    .catch((e) => {
      setLoadingList(false);
      console.error("GET ALL NEWS ERROR: ", e);
    });
  }

  async function getNewsMonth(year, page, perPage) {
    setLoadingList(true);
    GiftService.getNewsMonth(year, page, perPage).then((response) => {
      setLoadingList(false);
      if(response.status === 200){
        setShowNews(response.data.contents);
        setTotalResult(response.data.totalCount);
      } else {
        setShowNews([]);
        setTotalResult(0);
      }
    })
    .catch((e) => {
      setLoadingList(false);
      console.error("GET MONTH NEWS ERROR: ", e);
    });
  }

  function handleChangePage(toPage) {
    console.log("HANDLE CHANGE PAGE:", toPage);
    setPage(toPage);
  }

  function formatDate(value) {
    return value && value !== ""
      ? moment(value).format('YYYY.MM.DD')
      : value;
  }

  function handleClickArchive() {
    getNewsMonth(year, page, LIMIT);
  }

  const loading = () => {
    if (archive.length > 0) {
      return false;
    }
    return true;
  }

  return (
    <>
      <MetaTags>
        <title>お知らせ一覧｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content="お知らせ一覧ページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content="お知らせ一覧｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta name="twitter:description" content="お知らせ一覧ページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:image" content={window.location.origin + "/assets/img/common/ogp.png"}  />
        <meta name="twitter:site" content="@gochi_meshi" />
        <meta name="twitter:creator" content="@gochi_meshi" />
        <meta name="twitter:image:width" content="300" />
        <meta name="twitter:image:height" content="300" />
        <meta property="og:site_name" content="お知らせ一覧｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:title" content="お知らせ一覧｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:type" content="blog" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={window.location.origin + "/assets/img/common/ogp.png"} />
        <meta property="og:description" content="お知らせ一覧ページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
      </MetaTags>
      <Header />
    
      <main>
        <div className="breadcrumb">
          <div className="container">
            <ol className="breadcrumb-list">
              <li>
                <a href="/">{t('pages.news.topPage')}</a>
              </li>
              <li>
                <span>{t('pages.news.shortTitle')}</span>
              </li>
            </ol>
          </div>
        </div>

        <div className="container">
          <div className="sidebar-group">
            <div className="article-group">
              <div className="article-group-header">
                <div className="heading-xl">
                  <h1 className="title">{t('pages.news.list.title')}</h1>
                </div>
              </div>

              <div className={
                loadingList 
                  ? "article-group-body loading" 
                  : "article-group-body"}>
                {showNews && showNews.length > 0 && (
                  showNews.map((news, key) => {
                    let index = `news-${key}`;
                    return (
                      <a
                        className={
                          loadingList
                          ? "article-link loading"
                          : "article-link"}
                        href={`/news/${news.post_id}`}
                        key={index}
                       >
                        <article className={loadingList ? "article loading" : "article"}>
                          <header className="article-header">
                            <div className="article-header-info">
                              <div className="article-header-info-date ml-0">
                                <ul className="text-gray text-nowrap pl-0">
                                  <li>{formatDate(news.modified)}</li>
                                </ul>
                              </div>
                            </div>
                            <div className="heading">
                              <h1 className="title">{news.title}</h1>
                            </div>
                          </header>
                          <section className="article-body">
                            <p>{news.excerpt}</p>
                          </section>
                          <div className="article-footer">
                            <div className="link text-main">{t('pages.news.showMore')}</div>
                          </div>
                         </article>
                       </a> 
                    )
                  })
                )}
              </div>
              {showNews && showNews.length > 0 && (
                <CustomPagination
                count={Math.ceil(totalResult / LIMIT)}
                page={page}
                onChange={handleChangePage}
                />
              )}
            </div>

            <aside className="sidebar box">
              <section className="sidebar-section">
                <header className="sidebar-header">
                  <div className="heading">
                    <h3 className="title">{t('pages.news.list.archive')}</h3>
                  </div>
                </header>
                <div className={loading() ? "sidebar-body loading" : "sidebar-body"}>
                  {archive && archive.length > 0 && (
                    <ul className="archive-list">
                      {archive.map((item, key) => {
                        let index = `archive-${key}`;
                        return (
                          <li className="archive-list-item" key={index}>
                            <Link
                              className="text-black"
                              onClick={() => {
                                setYear(item.year)
                                setPage(1)
                                setShowNews([])
                                handleClickArchive()
                              }}
                              to="#"
                            >
                              {item.year}{t('other.year')}{t('other.leftBracket')}{item.count}{t('other.rightBracket')}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </div>
                <div className="sidebar-footer">
                  <div className="sidebar-banner-group">
                    <a 
                      href="https://user-guide.gochimeshi.com/"
                      className="sidebar-banner-faq bg-white"
                      target="blank"
                    >
                      <img src="/assets/img/common/faq-banner.png" alt="よくある質問" className="img" />
                    </a>
                  </div>
                </div>
              </section>
            </aside>
          </div>
        </div>

        <Explanation />
      </main>
    
      <Footer />

      <ModalGroup>
        <SituationModal />
        <GiftToModal />
      </ModalGroup>
    </>
  )
}

const mapStateToProps = (state) => ({ props: state.props });
const mapDispatchToProps = (dispatch) => ({
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content, buttonName, gotoUrl) =>
    dispatch(showError(showing, title, content, buttonName, gotoUrl)),
});

export default connect(mapDispatchToProps, mapStateToProps)(withTranslation()(ListNews));
import { HTTP_API, makeRequest } from "../HttpClient";

const API_BASE_URL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
  : "";

export default class MogugoriService {
  static async getListGenreOfShop(shopId) {
    const url = API_BASE_URL + "/mogugori/shops/" + shopId + "/genres";
    return makeRequest(HTTP_API.GET, url);
  }

  static async getListRankOfShop(shopId) {
    const url = API_BASE_URL + "/mogugori/rank/shop/" + shopId;
    return makeRequest(HTTP_API.GET, url);
  }

  static async getMogugoriShops(params) {
    const url = API_BASE_URL + "/mogugori/shops";
    return makeRequest(HTTP_API.GET, url, params);
  }

  static async getListUserOfShop(shopId, page, perPage) {
    const params = {
        page: page,
        perPage: perPage
    };
    const url = API_BASE_URL + `/mogugori/rank/shop/${shopId}/users`;
    return makeRequest(HTTP_API.GET, url, params);
  }
}

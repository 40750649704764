import React, { Component } from 'react';
import { connect } from "react-redux";

import { withTranslation } from 'react-i18next';
import {Modal, Button} from 'react-bootstrap'


class PopupConfirm extends Component { 

  render() { 

    return ( 
      <Modal size="sm" show={this.props.showing} onHide={this.props.handleClose} animation={false} aria-labelledby="contained-modal-title-vcenter"
      centered> 
        {this.props.title &&  this.props.title !== '' && (
          <Modal.Header className="title-popup">
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body className="space-pre-line">{this.props.content}</Modal.Body>
        <Modal.Footer className="footer-popup">
          <Button variant="primary" onClick={this.props.handleConfirm}>
            { this.props.confirmation ? this.props.confirmation : 'Ok' }
          </Button>
          <Button variant="secondary" onClick={this.props.handleClose}>
            {this.props.cancelation ? this.props.cancelation :this.props.t('pages.mypage.title button cancel')}
          </Button>
        </Modal.Footer>
      </Modal>

    );
  }
}

const mapStateToProps = state => ({
  ...state
});

export default connect(mapStateToProps)(withTranslation()(PopupConfirm));

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { savePaymentMethod } from "../../../actions/action_payment";
import PaymentService from "../../../api/services/PaymentService";
import * as constants from "../../../constants";
import { log } from "../../../utils";
import logoAtone from "../../../assets/img/icons/logo_atone.png";
import PopupAtoneDescription from "./PopupAtoneDescription";
import RadioInput from "./RadioInput";
import PaymentCardBrands from "../../common/PaymentCardBrands";
import ListPaymentCardModal from "./ListPaymentCardModal";
import ErrorAlert from "./ErrorAlert";

function Payment(props) {
  const [showListCard, setShowListCard] = useState(false);
  const [showAtoneDescription, setShowAtoneDescription] = useState(false);
  const [atoneHide, setAtoneHide] = useState(false);

  useEffect(() => {
    if (!window.Atone) {
      setAtoneHide(true);
    } else {
      setAtoneHide(false);
    }
  }, [window.Atone]);

  const fetchPaymentDefault = async (userId) => {
    props.setLoading(true);
    try {
      const { data } = await PaymentService.getDefaultCard(userId);
      if (data.list.length > 0) {
        props.savePaymentMethod(data.list[0]);
      }
    } catch (error) {
      log('getDefaultCard error with userId ' + props.userInfo.userId);
      // TODO: showError
    }
    props.setLoading(false);
  };

  const onChangePaymentType = (e) => {
    props.setPaymentType(parseInt(e.target.value));
  };

  useEffect(() => {
    if (props.readOnly) {
      return;
    }
    if (props.userInfo?.userId) {
      fetchPaymentDefault(props.userInfo.userId);
    }
    props.setPaymentType(constants.PAYMENT_TYPE_CREDIT_CARD);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className={props.className}>
        {props.paymentTypeError && <ErrorAlert className="mb-3">{props.paymentTypeError}</ErrorAlert>}

        <div className="d-flex justify-content-between">
          <RadioInput
            inputName="paymentType"
            inputValue={constants.PAYMENT_TYPE_CREDIT_CARD}
            disabled={props.readOnly && props.paymentType !== constants.PAYMENT_TYPE_CREDIT_CARD}
            checked={props.paymentType === constants.PAYMENT_TYPE_CREDIT_CARD}
            onChange={onChangePaymentType}
          >クレジットカードで決済</RadioInput>
          {!props.readOnly && props.paymentType === constants.PAYMENT_TYPE_CREDIT_CARD && props.userInfo.userId &&
            <button
              className="btn-outline btn-update-credit-card"
              onClick={() => setShowListCard(true)}
            >{!props.paymentMethod || props.paymentMethod.length === 0 ? '登録する' : '変更する'}</button>
          }
        </div>
        {props.paymentType === constants.PAYMENT_TYPE_CREDIT_CARD && props.userInfo.userId && <>
          {!props.paymentMethod || props.paymentMethod.length === 0
            ?
              <div className="fs-12px fw-400 mt-2">クレジットカード情報を登録してください</div>
            :
              <div className="mt-2">
                <div>****-****-****-{props.paymentMethod.last4}</div>
                <div>有効期限：{props.paymentMethod.expireMonth.toString().padStart(2, '0')}/{props.paymentMethod.expireYear}</div>
              </div>
          }
        </>}
        {!props.readOnly && <div className="mt-3">
          <PaymentCardBrands className="justify-content-between" />
        </div>}

        <div className="mt-3">
          <RadioInput
            inputName="paymentType"
            inputValue={constants.PAYMENT_TYPE_PAYPAY}
            disabled={(props.readOnly && props.paymentType !== constants.PAYMENT_TYPE_PAYPAY) || props.disabledNonSubscription}
            checked={props.paymentType === constants.PAYMENT_TYPE_PAYPAY}
            onChange={onChangePaymentType}
          >PayPayで決済</RadioInput>
        </div>

        <div className="mt-3">
          <RadioInput
            inputName="paymentType"
            inputValue={constants.PAYMENT_TYPE_ONEPAY_ALIPAY}
            disabled={(props.readOnly && props.paymentType !== constants.PAYMENT_TYPE_ONEPAY_ALIPAY) || props.disabledNonSubscription}
            checked={props.paymentType === constants.PAYMENT_TYPE_ONEPAY_ALIPAY}
            onChange={onChangePaymentType}
          >Alipayで決済</RadioInput>
        </div>

        <div className="mt-3">
          {!atoneHide &&
          <RadioInput
            inputName="paymentType"
            inputValue={constants.PAYMENT_TYPE_ATONE}
            disabled={(props.readOnly && props.paymentType !== constants.PAYMENT_TYPE_ATONE) || props.disabledNonSubscription}
            checked={props.paymentType === constants.PAYMENT_TYPE_ATONE}
            onChange={onChangePaymentType}
          >atone 翌月払い（コンビニ/口座振替）</RadioInput>
          }
          {!atoneHide &&
            <div className={(props.readOnly && props.paymentType !== constants.PAYMENT_TYPE_ATONE) || props.disabledNonSubscription ? "text-gray-B1ADAD mb-2" : "text-red mb-2"}>※atoneアカウントをお持ちではない方は、会員登録が必要です</div>
          }
          {!props.readOnly && <div className="atone-description">
            {!atoneHide ? (
            <>
              <div>
                <a
                  href="https://atone.be/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={logoAtone} alt="Atone" />
                </a>
              </div>
            </>
            ) : (
              <div className="fs-12">
                ※広告ブロック等の理由により、atone決済が表示されません。<br />
                atone決済をご利用ご希望の方は、ご利用のブラウザを変更してお試しください。
              </div>
            )}
            <span onClick={() => setShowAtoneDescription(true)}>atone説明文</span>
          </div>}
        </div>
      </div>

      <ListPaymentCardModal show={showListCard} handleClose={() => setShowListCard(false)} />
      <PopupAtoneDescription show={showAtoneDescription} handleClose={() => setShowAtoneDescription(false)} />
    </>
  );
}

const mapStateToProps = state => ({
  userInfo: state.userInfo,
  paymentMethod: state.paymentMethod
});

const mapDispatchToProps = dispatch => ({
  savePaymentMethod: (paymentMethod) => dispatch(savePaymentMethod(paymentMethod))
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);

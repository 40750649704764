import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import queryString from "query-string";
import { log } from "../../utils";

import ShopService from "../../api/services/ShopService";
import { showLoading } from "../../actions/action_loading";
import { showError } from "../../actions/action_error_alert";
import { Modal } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Media from "react-bootstrap/Media";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

class ListShop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuId: null,
      redirectUrl: "",
      paramShashoku: "",
      src: "",
      listShop: [],
      shopCount: 0,
      hasMore: false,
      keyword: "",
      page: 1,
      sort: "distance",
      latitude: 0,
      longitude: 0,
      showListShopPopup: this.props.showListShopPopup,
    };
    this.getListShops = this.getListShops.bind(this);
    this.loadMoreShop = this.loadMoreShop.bind(this);
    this.searchKeyword = this.searchKeyword.bind(this);
    this.handleShowListShops = this.handleShowListShops.bind(this);
  }

  getListShops = async (
    menuId,
    loginUserId,
    sort,
    page,
    latitude,
    longitude,
    keyword
  ) => {
    this.props.showLoading(true);
    try {
      const { data } = await ShopService.getListlShopsCmm(
        menuId,
        loginUserId,
        sort,
        page,
        latitude,
        longitude,
        keyword
      );
      console.log("datee", data);
      const listShop = this.state.listShop;
      // incase have 0 shops, api returns listShop = NULL
      if (data.list && data.list.length > 0) {
        data.list.map((shop) => {
          return listShop.push(shop);
        });
      }
      this.setState({
        listShop: listShop,
        // childFoods: data.childFoods,
        shopCount: data.count,
      });
      if (listShop.length < data.count) {
        this.setState({
          hasMore: true,
        });
      } else {
        this.setState({
          hasMore: false,
        });
      }
      this.props.showLoading(false);
      // return listShop;
    } catch (error) {
      console.log("error getListShops ============", error);
      this.props.showLoading(false);
      this.props.showError(true, "There is something wrong", "");
    }
  };

  getCoords() {
    var options = {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    };

    this.props.showLoading(true);

    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(
        (success) => {
          this.props.showLoading(false);
          resolve(success);
        },
        (error) => {
          this.props.showLoading(false);
          if (error.code === 1) {
            resolve(null);
          } else {
            reject(error);
          }
        },
        options
      )
    );
  }

  async loadMoreShop() {
    const userInfo = this.props.userInfo;
    try {
      await this.getListShops(
        this.state.menuId,
        userInfo.userId,
        this.state.sort,
        this.state.page + 1,
        this.state.latitude,
        this.state.longitude,
        this.state.keyword
      );
      this.setState({
        page: this.state.page + 1,
      });
    } catch (error) {
      console.log("error loadMoreShop", error);
    }
  }

  setKeyword(event) {
    var value = event.target.value;
    this.setState({
      keyword: value
    });
  }

  async searchKeyword() {
    await this.setState({
      page: 1,
    });
    await this.setState({
      listShop: []
    });
    const userInfo = this.props.userInfo;
    try {
      await this.getListShops(
        this.state.menuId,
        userInfo.userId,
        this.state.sort,
        this.state.page,
        this.state.latitude,
        this.state.longitude,
        this.state.keyword
      );
    } catch (error) {
      console.log("error SearchShop", error);
    }
  }

  async handleShowListShops() {
    this.setState({
      showListShopPopup: true,
    });

    if (
      (this.props.menuInfo &&
        (this.props.menuInfo.menuChildType === 3 ||
          this.props.menuInfo.menuChildType === 4 ||
          this.props.menuInfo.menuChildType === 5)) ||
      this.props.menuChildType === 3 ||
      this.props.menuChildType === 4 ||
      this.props.menuChildType === 5
    ) {
      console.log("this.props.menuInfo", this.props.menuInfo);
      let latitude = null;
      let longitude = null;

      let loginUserId = null;

      try {
        const position = await this.getCoords();
        console.log("ggg=====", position);

        if (position && position.coords) {
          latitude = position.coords.latitude;
          longitude = position.coords.longitude;
        }
        this.setState({
          loading: false,
        });
      } catch (e) {
        this.setState({
          loading: false,
        });
        console.log("======================", e);
      }

      this.setState({
        latitude: latitude,
        longitude: longitude,
      });
      if (this.state.menuId) {
        await this.getListShops(
          this.state.menuId,
          loginUserId,
          this.state.sort,
          this.state.page,
          latitude,
          longitude
        );
      }
    }
  }

  UNSAFE_componentWillMount() {
    let url = window.location.search;
    let params = queryString.parse(url);
    var menuId = params.id;
    const fromShashoku = params.fromShashoku;
    var src = params.src;
    if (src) {
      this.setState({
        src: src,
      });
    }

    this.setState({
      menuId: menuId || this.props.menuId,
    });

    this.props.showLoading(false);
    this.setState({
      buttonDisabled: false,
    });

    this.setState({
      menuId: menuId,
    });

    if (fromShashoku) {
      this.setState({
        paramShashoku: `&fromShashoku=${encodeURIComponent(fromShashoku)}`,
      });
    }
  }

  componentDidMount() {
    let url = window.location.search;
    let params = queryString.parse(url);

    var menuId = params.id;
    this.setState({
      menuId: menuId || this.props.menuId,
    });

    this.handleShowListShops();
  }

  render() {
    log(["props on list shops popup", this.props]);
    const { t } = this.props;

    const gochiInfo = this.props.gochiInfo;
    const showUsingBtn = this.props.showUsingBtn;
    const onOpenGochiUse = this.props.onOpenGochiUse;

    console.log("gochiInfo on Listshop ===", gochiInfo);

    return (
      <>
        <Modal
          className="modal-list-shop modal-custom modal-list-shop-custome"
          size="sm"
          show={this.state.showListShopPopup}
          onHide={this.props.handleCloseListShopPopup}
          aria-labelledby="contained-modal-title-vcenter"
          // backdrop="static"
        >
          {/* <Modal.Header className="header-close" closeButton /> */}
          <Modal.Body>
            {/* {this.state.listShop.length > 0 && ( */}
              <div className="lst-shop-cmm mt-4 searchKeyword mt-4-custome width-custom text-left">
                <div className="title-lst-shop align-center">
                  {t(
                    "pages.login.List of stores where this meal voucher can be used"
                  )}
                </div>
                <div className="searchShop custom-search-shop custom-search-shop-1">
                  <div className="fieldset">
                    <div className="fieldset-header">
                      <svg className="icon">
                        <use xlinkHref="/assets/img/common/icon.svg#search" />
                      </svg>
                      <div className="title">キーワード（ジャンル・住所・店舗名等）</div>
                    </div>
                  </div>
                </div>
                <div className="searchShop custom-search-shop">
                  <div className="inputField">
                    <div className="fieldset fieldset-lg">
                      <div className="fieldset-body">
                        <div className="field field-pill field-lg">
                          <input
                            type="text"
                            placeholder="キーワード"
                            value={this.state.keyword}
                            onChange={event => this.setKeyword(event)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btnSearchF">
                    <div className="fieldset fieldset-submit">
                      <button type="button" className="btn btn-submit search-btn custome-search-btn" onClick={this.searchKeyword}>
                        検索する
                      </button>
                    </div>
                  </div>
                </div>

                <div className="lst-shops text-left">
                  <div className="d-flex align-items-center mt-3 mb-2 mt-3-custome mb-2-custome">
                    <div className="font-weight-bold fs-15 clr-000000">
                      {t("pages.login.searchResult")}
                    </div>
                    <div className="ml-auto count-left">
                      {t("pages.login.total", {
                        count: this.state.listShop && this.state.shopCount,
                      })}
                    </div>
                  </div>
                  <ListGroup className="list-items">
                    {this.state.listShop.map(function (shop) {
                      const gochiInfoShop = {
                        ...gochiInfo,
                        shopId: shop.shopId,
                        shopName: shop.shopName,
                      };
                      return (
                        <ListGroup.Item className="shop-item">
                          <Media>
                            {/* <img
													width={66}
													className="shop-img"
													src={shop.shopImage && 
													(shop.shopImage.length > 0) 
														? shop.shopImage[0].image 
														: require("../../../assets/img/icons/gochi_noimg-ver4.jpg")} 
													alt={shop.shopName}
												/> */}
                            <Media.Body className="shop-body">
                              <h5 className="shop-name">{shop.shopName}</h5>
                              <p className="shop-distance">
                                {!(!shop.distance && !shop.nearestStation) && (
                                  <FontAwesomeIcon
                                    icon={faMapMarkerAlt}
                                    className="mr-2 fs-16"
                                  />
                                )}
                                {shop.prefectures ? shop.prefectures + " " : ""}
                                {shop.city ? shop.city + " " : ""}
                                {shop.address ? shop.address + " " : ""}
                                {shop.building}
                              </p>
                              {/* {shop.shopTag && shop.shopTag.length > 0 && (
														<div className="lst-shop-tag">
															{shop.shopTag.map((tag, index) => {
															return (
																tag.tagName && (
																<span className="shop-tag" key={tag.shopTagId}>{tag.tagName}</span>
																)
															);
															})}
														</div>
													)}  */}
                              {showUsingBtn && (
                                <button
                                  type="button"
                                  onClick={() => onOpenGochiUse(gochiInfoShop)}
                                  className="btn btn-red-38 mt-2 align-center fs-13 mt-2-custome"
                                  style={{ height: "30px" }}
                                >
                                  {t("pages.menu.Use at this shop")}
                                </button>
                              )}
                            </Media.Body>
                          </Media>
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>

                  {this.state.hasMore && (
                    <div className="text-center mt-3">
                      <Button
                        variant="link"
                        className="btn btn-load-more"
                        onClick={this.loadMoreShop}
                      >
                        {t("pages.login.Load more")}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            {/* )} */}
          </Modal.Body>
          <div className="fs-15 clr-000000 align-center mb-3">
            <button
              type="button"
              onClick={this.props.handleCloseListShopPopup}
              className="btn btn-close-popup fs-16 custome-btn-close"
            >
              {t("pages.share.Close")}
            </button>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content, buttonName, gotoUrl) =>
    dispatch(showError(showing, title, content, buttonName, gotoUrl)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ListShop));

import {HTTP_API, makeRequest} from "../HttpClient";

const API_BASE_URL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : '';

export default class AuthService {
  static async authenticateByCognitoToken(token, mfaToken) {
    const params = {
      grantType: 'cognito_token',
      token,
      mfaToken
    };
    return this.authenticate(params);
  }

  static async authenticateByFirebaseToken(token) {
    const params = {
      grantType: 'firebase_token',
      token
    };
    return this.authenticate(params);
  }

  static async authenticateByRefreshToken(token) {
    const params = {
      grantType: 'refresh_token',
      token
    };
    return this.authenticate(params);
  }

  static async authenticate(params) {
    const url = `${API_BASE_URL}/auth`;
    const response = await makeRequest(HTTP_API.POST, url, params, false);
    if (response.status === HTTP_API.SUCCESS) {
      const { accessToken, refreshToken } = response.data;
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
    }
    return response;
  }

  static async logout() {
    const url = `${API_BASE_URL}/account/logout`;
    return makeRequest(HTTP_API.POST, url);
  }

  static async loginMfa(params) {
    const url = `${API_BASE_URL}/login-mfa`;
    return makeRequest(HTTP_API.POST, url, params);
  }
}

import React, { Component } from "react";    
import {connect} from 'react-redux'; 
import { withTranslation } from 'react-i18next';
import {showLoading} from "../../../actions/action_loading";
import {showError} from "../../../actions/action_error_alert";
import queryString from 'query-string'

class AddCardModal extends Component {
	constructor(){
	    super(); 
	    this.state = { 
	    	menuId: null,
            redirectToReferrer: false, 
			paymentList: [],
			src: "",
            paramShashoku: '',
			shopId: null
        }
	    this.handleSubmit = this.handleSubmit.bind(this);
	}

	openRegisCard(){
		//this.props.onHide;
		this.props.showRegCard(true);
    this.props.setFlagResetRegisterCard(true);
	}

	UNSAFE_componentWillMount(){
		let url = window.location.search;
		let params = queryString.parse(url);
		var menuId = params.id; 
        var src = params.src;
		var shopId = params.shopId;
        const fromShashoku = params.fromShashoku;
		const referer = params.referer;

		if (src) {
			this.setState({
				src: src
			});
		}

		this.setState({
			menuId: menuId,
			referer,
        });
        
        if (fromShashoku) {
            this.setState({
				paramShashoku: `&fromShashoku=${encodeURIComponent(fromShashoku)}`
			});
        }

		if (shopId) {
			this.setState({
				shopId: shopId
			});
		}
	}

	componentDidMount(){
		//
	}

	handleSubmit(e){
	    e.preventDefault();  
		var token = e.target.elements.paymentSelect.value;

		var paymentList = this.props.paymentList; 

		var payment = paymentList.list.find(function(element) {
		  return element.token === token;
		});  

		sessionStorage.setItem('paymentMethod', JSON.stringify(payment)); 

		this.props.setPaymentMethod(payment);
		this.props.onHide(true);
	}

    render() {
		// return console.log(this.props)
		var userInfo = this.props.userInfo;
		var srcParam = this.state.src ? '&src=' + this.state.src : '';
		const refererParam = this.state.referer ? `&referer=${encodeURIComponent(this.state.referer)}` : '';
		srcParam += refererParam; 


    	var link = "";
		if (this.state.referer) {
			link = this.state.referer;
		} else
		if (!this.state.menuId && this.state.shopId) {
			link = "/invite?shopId=" + this.state.shopId;
		} else {
			link = "/invite?id=" + this.state.menuId + srcParam + this.state.paramShashoku;
		}

    	const { t } = this.props;

    	var paymentList = this.props.paymentList;   

        return (
        	<div className={
                this.props.isShow
                ? "custom-modal-container show-modal"
                : "custom-modal-container"
            }
            size="sm">
                <div className={
                    this.props.isShow
                        ? "modal modal-action modal-dialog border-radius-4 add-card-modal is-show"
                        : "modal modal-action modal-dialog border-radius-4 add-card-modal"
                    }>
                    <div className="modal-body p-2 px-10 add-card-body">
                        <div className="form-block mt-0">
                            <form onSubmit={this.handleSubmit}>  
                                <div className="form-group lst-payments">
                                    <div className="title-lst-shop align-center">{t('pages.invite.Payment method selection')}</div> 

                                    <div className="list-payment mt-4 payment-popup">
                                      {(paymentList.count > 0 && userInfo.isGuest === false) &&
                                        <div>
                                          {paymentList.list.map(function(card, key){
                                             return <div className="card" key={ key }>
                                              <div className="card-body">
                                                  <ul className="lst-payment-ul"> 
                                                    <li key={ key }> 
                                                        <label className="box-payment w-100 mb-0">
                                                            <div className="radio-custom">
                                                                <input type="radio" name="paymentSelect" value={card.token} defaultChecked={card.defaultFlag}/>
                                                                <span className="checkmark"></span> 
                                                            </div>

                                                            <div className="payment-info">
                                                                <div className="number-card">{card.brand}****-****-****-{card.last4}</div>
                                                                <div className="name-card font-weight-bold">{card.holder}</div>
                                                                <div className="payment-date">
                                                                    <div className="">
                                                                        <span>{t('pages.invite.expiredDate')}: </span>
                                                                        <span>{card.expireMonth.toString().padStart(2, '0')}/{card.expireYear}</span>
                                                                    </div> 
                                                                </div>
                                                            </div> 
                                                        </label>
                                                    </li>
                                                  </ul>
                                              </div>
                                            </div>
                                          })}
                                        </div>
                                      }
                                    </div> 
                                    <div className="select-custom mt-2 d-flex align-items-center add-card-button">
                                        <div className="icon-plus mr-2">+</div>
                                        <span className="text-add-card clr-000000 text-decoration-underline cursor-pointer" onClick={() => {this.openRegisCard()}}>
                                          新しいお支払い方法を登録する</span>
                                    </div> 
                                </div> 

                                <div className="form-group text-center mt-4">
                                    <button className="btn btn-green-38 width-100 border-radius-4 submit-button" disabled={paymentList.length === 0 || (paymentList.list && paymentList.list.length === 0)} type="submit">
                                        {t('pages.invite.Confirm payment method')}
                                    </button>
                                </div>

                                <div className="align-center">
                                    <span type="button" className="cursor-pointer align-center text-decoration-underline back-step-btn" onClick={this.props.onHide}>
                                        {t('pages.mypage.title button cancel')}
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div onClick={this.props.onHide} className="back-drop" />
			</div>
        ); 
    }
}
 
const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({  
    showLoading: (showing) => dispatch(showLoading(showing)),
    showError: (showing, title, content) => dispatch(showError(showing, title, content)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddCardModal)); 
import React, { useState, useEffect } from "react";
import MetaTags from 'react-meta-tags';
import {
  useScript, 
  useStyleSheet 
} from "../../../../utils";
import { Footer } from "../../layout/Footer";
import Header from "../../layout/Header";
import GiftService from "../../../../api/services/GiftService";
import { CustomPagination } from "../../common/Pagination";
import { showLoading } from "../../../../actions/action_loading";
import { showError } from "../../../../actions/action_error_alert";
import { connect } from "react-redux";
import { ModalGroup } from "../../common/ModalGroup";
import { SituationModal } from "../../common/SituationModal";
import { GiftToModal } from "../../common/GiftToModal";
import { withTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { Explanation } from "../../layout/Explanation";

export function SpecialList(props) {
  useStyleSheet("https://unpkg.com/swiper/swiper-bundle.min.css",
    null,
    {
      integrity:
        "sha384-HUXJxzkMpCxEdXkgl8BSjN5SUcUODepsOLIYIWOtKva+Qo2teMmV1mh6ZVwnthaV",
      crossorigin: "anonymous",
      referrerpolicy: "no-referrer",
    }
  );
  useScript("https://unpkg.com/swiper/swiper-bundle.min.js",
    null,
    { integrity: "sha384-XVTmRHMO2XUTzy8IerJVEy8ddg47Yz5pZrLc7nEWipaTowqT1xNb8qTLaw64Bd4S",
      crossorigin: "anonymous"
    }
  );
  useScript("/assets/js/app.js");

  const NO_IMAGE = require("../../../../assets/img/icons/noimage.png");

  const [page, setPage] = useState(1);
  const LIMIT = 9;
  const [specialList, setSpecialList] = useState([]);
  const [totalResult, setTotalResults] = useState(0);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const { t } = props;

  useEffect(() =>{
    window.scrollTo(0, 0);
    getSpecialList(page, LIMIT);
  }, [page, LIMIT]);

  function getTitle() {
    return "特集一覧｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス";
  }

  function getMetaDescription() {
    return "特集一覧のページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。";
  }

  async function getSpecialList(page, perPage) {
    setLoading(true);
    GiftService.getSpecialCampaigns(page, perPage).then((response) => {
      setLoading(false);
      if(response.status === 200) {
        setSpecialList(response.data.contents);
        setTotalResults(response.data.totalCount);
      } else {
        setSpecialList([]);
        setTotalResults(0);
      }
    })
    .catch((e) => {
      setLoading(false);
      console.error("GET LIST SPECIAL ERROR: ", e);
    });
  }

  function handleChangePage(toPage) {
    console.log("HANDLE CHANGE PAGE:", toPage);
    setPage(toPage);
  }
  
  function formatDate(value) {
    return value && value !== ""
      ? moment(value).format('YYYY.MM.DD')
      : value;
  }

  function handleClickSpecial(slug) {
    history.push({
      pathname: '/specials/' + slug,
    });
  }

  return (
    <>
      <MetaTags>
        <title>{getTitle()}</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content={getMetaDescription()} />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content={getTitle()} />
        <meta name="twitter:description" content={getMetaDescription()} />
        <meta name="twitter:image" content={window.location.origin + "/assets/img/common/ogp.png"}  />
        <meta name="twitter:site" content="@gochi_meshi" />
        <meta name="twitter:creator" content="@gochi_meshi" />
        <meta name="twitter:image:width" content="300" />
        <meta name="twitter:image:height" content="300" />
        <meta property="og:site_name" content={getTitle()} />
        <meta property="og:title" content={getTitle()} />
        <meta property="og:type" content="blog" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={window.location.origin + "/assets/img/common/ogp.png"} />
        <meta property="og:description" content={getMetaDescription()} />
      </MetaTags>
      <Header />

      <main>
        <div className="breadcrumb">
          <div className="container">
            <ol className="breadcrumb-list">
              <li>
                <a href="/">{t('pages.specials.topPage')}</a>
              </li>
              <li>
                <span>{t('pages.specials.list.breadcrum')}</span>
              </li>
            </ol>
          </div>
        </div>

        <div className="container">
          <section 
            className={
              loading && specialList.length === 0
                ? "section loading"
                : "section"
            }
          >
            <div className="section-header">
              <div className="heading-lg">
                <h1 className="title">{t('pages.specials.list.title')}</h1>
              </div>
            </div>
            <div className="section-body">
              <div className="special-group mt-20">
                {specialList && specialList.length > 0 && (
                  specialList.map((special, key) => {
                    let index = `special-${key}`;
                    return (
                      <a
                        className={
                          loading
                            ? "special loading"
                            : "special"}
                        href
                        onClick={() => handleClickSpecial(special.slug)}
                        key={index}
                      >
                        <div className="special-thumbnail">
                          <img 
                            src={special.thumbnails?.large?.url 
                              ? special.thumbnails.large.url 
                              : NO_IMAGE}
                            className="img"
                            alt={special.short_title}
                            loading="lazy"
                          />
                        </div>
                        <div className="special-header">
                          <div className="date">{formatDate(special.modified)}</div>
                          <div className="heading-sm">
                            <div className="title">{special.short_title}</div>
                          </div>
                        </div>
                      </a>
                    )
                  })
                )}
              </div>
              {specialList && specialList.length > 0 && (
                <CustomPagination
                count={Math.ceil(totalResult / LIMIT)}
                page={page}
                onChange={handleChangePage}
                />
              )}
            </div>
          </section>
        </div>

        <Explanation />
      </main>

      <Footer />

      <ModalGroup>
        <SituationModal />
        <GiftToModal />
      </ModalGroup>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content, buttonName, gotoUrl) =>
    dispatch(showError(showing, title, content, buttonName, gotoUrl)),
});

export default connect(null, mapDispatchToProps)(withTranslation()(SpecialList));
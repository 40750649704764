import React, { Component } from "react";
import { Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import queryString from 'query-string';
import { log } from '../../../utils';
import { Modal, Card } from 'react-bootstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';  
import moment from 'moment';
import {showLoading} from "../../../actions/action_loading";
import {showError} from "../../../actions/action_error_alert";
import { withTranslation } from 'react-i18next';
import GochiService from "../../../api/services/GochiService";
import ShopService from "../../../api/services/ShopService";
import CouponService from "../../../api/services/CouponService";
import Header from "../../layout/header/Header";
import GochiUseInfor from "../../common/GochiUse/GochiUseInfor";
// import GochiUseStaff from '../../common/GochiUse/GochiUseStaff';
import ListShop from '../../common/ListShop';
import * as constants from '../../../constants';

class ToMeDetail extends Component { 
	constructor(){
    super(); 
    this.state = { 
      textButtonSort: "現在地からの距離",
      gochiDetail: {},
      menuId: 0,
      menuChildType: 0,
      showGochiUse: false,
      gochiInfo: {},
      showGochiUseStaff: false,
      quantity: 1,
      includedTaxPrice: 0,
      listFavourTemp: [],
      showListShopPopup: false,
      messageText: null,
      messageTextError: null,
      disabledBtnSendMessage: true,
      showConfirmMessagePopup: false,
      sentMessage: null,
      sentMessageToGiver: false
    }
	  this.getGochi = this.getGochi.bind(this);
    this.onCopy = this.onCopy.bind(this);
    this.onFavouriteShop = this.onFavouriteShop.bind(this);
    this.onOpenGochiUse = this.onOpenGochiUse.bind(this);
    this.onCloseGochiInfo = this.onCloseGochiInfo.bind(this);
    this.onCloseGochiStaff = this.onCloseGochiStaff.bind(this);
    this.openMap = this.openMap.bind(this);
    this.handleShowListShops = this.handleShowListShops.bind(this);
    this.handleCloseListShopPopup= this.handleCloseListShopPopup.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOpenConfirmPopup = this.handleOpenConfirmPopup.bind(this);
    this.onCloseConfirmMessagePopup = this.onCloseConfirmMessagePopup.bind(this);
    this.handleSendMessage = this.handleSendMessage.bind(this);
  }

  handleShowListShops() {
		this.setState({
			showListShopPopup: true
		});
	};

  handleCloseListShopPopup() {
		this.setState({
			showListShopPopup: false
		});
	};

  handleChange = (e) => {
    let mess = e.target.value;
    console.log('message to buyer ===', mess);
    if (mess.trim() !== '') {
        this.setState({
            disabledBtnSendMessage: false
        });
    } else {
        this.setState({
            disabledBtnSendMessage: true
        });
    }
    this.setState({
        messageText: mess
    });
  }

  handleOpenConfirmPopup() {
    const { t } = this.props;

    if (this.state.messageText.length < 1 || this.state.messageText.length > 200) {
      this.setState({
          showConfirmMessagePopup: false,
          messageTextError: t('pages.invite.Please enter a message within 200 characters')
      });
    } else {
      this.setState({
          showConfirmMessagePopup: true,
          messageTextError: null
      });
    }
  }

  async handleSendMessage() {
    let gochiBillId = this.state.gochiDetail.gochiBillId;
    let couponId = this.state.gochiDetail.couponMessage && this.state.gochiDetail.couponMessage.find(item => !item.couponMessageId && !item.useDate) 
                        && this.state.gochiDetail.couponMessage.find(item => !item.couponMessageId && !item.useDate).couponId;
    if(couponId) {
      this.props.showLoading(true);
      try {
          const { data } = await CouponService.sendReviewMessageToReceivedUser(this.state.messageText, couponId, gochiBillId);
          console.log('data ===', data);
          this.props.showLoading(false);
          this.setState({
              showConfirmMessagePopup: false,
              sentMessageToGiver: true,
              sentMessage: this.state.messageText
          });
      } catch(error) {
          console.log(error);
          this.props.showLoading(false);
          this.props.showError(true, this.props.t("There is something wrong"), "");
      }
    }
  }

  onCloseConfirmMessagePopup() {
    this.setState({
        showConfirmMessagePopup: false
    });
  }

  getGochi = async (gochiBillId) => {   
	this.props.showLoading(true);
    try {
      const { data } = await GochiService.getGochi({gochiBillId});   
      this.props.showLoading(false);
      return data;
    } catch (error) { 
      this.props.showLoading(false);
      this.props.showError(true, this.props.t("pages.login.There is something wrong"), "");
    }
  };

  openMap() {
    let lat = this.state.gochiDetail.shop && this.state.gochiDetail.shop.latitude ? this.state.gochiDetail.shop.latitude : '';
    let long = this.state.gochiDetail.shop && this.state.gochiDetail.shop.longitude ? this.state.gochiDetail.shop.longitude : '';
    window.open("https://maps.google.com?q="+lat+","+long, "_blank");
  }
  
  async componentDidMount() {
    const url = window.location.search;
    const userInfo = this.props.userInfo;
    let params = queryString.parse(url);
    const gochiBillId = params.gochiBillId; 

    if (userInfo) { 
      if (userInfo.isLogined && !userInfo.isGuest) {
        const dataGochi = await this.getGochi(gochiBillId);

        // const loginUserId = userInfo.userId;
        const includedTaxPrice = this.state.gochiDetail.gochiType === 'PRICE' ? 
                              (this.state.gochiDetail.amount/this.state.gochiDetail.gochiCount + this.state.gochiDetail.taxAmount/this.state.gochiDetail.gochiCount) :
                              (this.state.gochiDetail.menu && this.state.gochiDetail.menu.includedTaxPrice);

        if(dataGochi.menuId && (dataGochi.menu.menuChildType === 3 || dataGochi.menu.menuChildType === 4 || dataGochi.menu.menuChildType === 5)) {
          this.setState({
            menuId: dataGochi.menuId,
            menuChildType: dataGochi.menu.menuChildType
          });
        }
        this.setState({
          gochiDetail: dataGochi,
          includedTaxPrice: includedTaxPrice,
          sentMessageToGiver: dataGochi.couponMessage && dataGochi.couponMessage.find(item => item.couponMessageId) ? true : false,
          sentMessage: dataGochi.couponMessage && dataGochi.couponMessage.find(item => item.couponMessageId) 
                          && dataGochi.couponMessage.find(item => item.couponMessageId).message ? dataGochi.couponMessage.find(item => item.couponMessageId).message : ''
        });
      }
    }
  }

  onCopy(e){  
    this.setState({copied: true});
  };

  async onFavouriteShop(shopId, isFavorite){
    const userId = this.props.userInfo && this.props.userInfo.userId;
    this.props.showLoading(true);
    let data = null;
    const lstTemp = this.state.listFavourTemp;
    try {
      if (isFavorite) {
        data = await ShopService.destroyFavoriteShop(userId, shopId);
      } else {
        data = await ShopService.createFavoriteShop(userId, shopId);
      }

      if (data.status === 200) { 
        const found = lstTemp.some(el => el.shopId === shopId);
        if (!found) {
          lstTemp.push({
            isFavorite: !isFavorite,
            shopId: shopId
          });
        } else {
          const objIndex = lstTemp.findIndex(
            obj => obj.shopId === shopId
          );
          lstTemp[objIndex].isFavorite = !isFavorite;
        }

        this.setState({
            listFavourTemp: lstTemp
        })
      } else {
        this.props.showError(true, data.message, "");
      }
      this.props.showLoading(false);
      // return data;
    } catch (error) { 
        console.log('----------error', error);
        this.props.showLoading(false);
        this.props.showError(true, this.props.t("pages.login.There is something wrong"), "");
    }
  }; 

  dateFormat = timeStr => {
    const date = new Date(timeStr);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }
    const year = date.getFullYear();
    return `${year}${this.props.t('other.year')}${month}${this.props.t('other.month')}${day}${this.props.t('other.day')}${this.props.t('other.until')}`;
  };


  async onOpenGochiUse(gochiInfo) { 
    console.log('gochiInfo TomeDetail ===', gochiInfo);
    if (gochiInfo.gochiCount === 1 && gochiInfo.menuChildType !== 3) {
      this.setState({
        showGochiUseStaff: true,
        // showGochiUse: true,
        gochiInfo: gochiInfo
      });
      var url = "/gochiuse/staff";
      sessionStorage.setItem('quantityWillBeUsed', 1);
      sessionStorage.setItem('usingFlowGochiInfo', JSON.stringify(gochiInfo));
      window.location.href = url+'?gochiBillId='+gochiInfo.gochiBillId;
    } else {
      this.setState({
        // showGochiUseStaff: true,
        showGochiUse: true,
        gochiInfo: gochiInfo
      });
    }
    this.handleCloseListShopPopup();
  }

  onCloseGochiInfo() {
		this.setState({
			showGochiUse: false,
    });
  }
  
  onCloseGochiStaff() {
		this.setState({
			showGochiUseStaff: false
		});
	}

  render() {
    console.log('this.state.gochiDetail ==', this.state.gochiDetail);
    log(["props on list tome detail", this.props]);
    const { t } = this.props;   
    var userInfo = this.props.userInfo;
    var link = "/login";
    let isLoggedIn = false; 
    if (userInfo) {
        if (userInfo.userId) {
            isLoggedIn = true;
        } 
      if (userInfo.isLogined === false || userInfo.isGuest) { 
        window.history.pushState(null, null, '/login');
        return <Redirect to={link} />
      } 
    } else {
      window.history.pushState(null, null, '/login');
      return <Redirect to={link} />
    } 

    // const onFavouriteShop = this.onFavouriteShop;
    const onOpenGochiUse = this.onOpenGochiUse;
    const menuName = this.state.gochiDetail.gochiType === 'PRICE' ? 
                      t('pages.menu.Menu Price') : (this.state.gochiDetail.menu && this.state.gochiDetail.menu.menuName);
    const menuId = this.state.gochiDetail.menuId;
    let menuImage = require("../../../assets/img/icons/gochi_noimg-ver4.jpg");
    if (this.state.gochiDetail.gochiType === 'PRICE') {
      menuImage = require("../../../assets/img/icons/gochi_price.jpg");
    } else {
      menuImage = this.state.gochiDetail.gochiBillImage && this.state.gochiDetail.gochiBillImage.length > 0 && this.state.gochiDetail.gochiBillImage[0].image ? 
                    this.state.gochiDetail.gochiBillImage[0].image : require("../../../assets/img/icons/gochi_noimg-ver4.jpg");
    }
    const includedTaxPrice = this.state.gochiDetail.gochiType === 'PRICE' ? 
                              (this.state.gochiDetail.amount/this.state.gochiDetail.couponCount + this.state.gochiDetail.taxAmount/this.state.gochiDetail.couponCount) :
                              (this.state.gochiDetail.menu && this.state.gochiDetail.menu.includedTaxPrice);
    const excludedTaxPrice = this.state.gochiDetail.gochiType === 'PRICE' ? 
                              (this.state.gochiDetail.amount/this.state.gochiDetail.couponCount) :
                              (this.state.gochiDetail.menu && this.state.gochiDetail.menu.excludedTaxPrice);
    const displayAmount = this.state.gochiDetail && this.state.gochiDetail.menu && this.state.gochiDetail.menu.displayAmount;
    const gochiCount = this.state.gochiDetail.gochiCount;
    const couponCount = this.state.gochiDetail.couponCount;
    const gochiExpiredDay = this.state.gochiDetail.gochiExpiredDay;
    const menuChildType = this.state.gochiDetail.menu && this.state.gochiDetail.menu.menuChildType;
    // const childFoods = this.state.childFoods.length > 0 ? this.state.childFoods : [];
    const shopId = this.state.gochiDetail.shop && this.state.gochiDetail.shop.shopId;
    const shopName = this.state.gochiDetail.shop && this.state.gochiDetail.shop.shopName;
    // const groupName = this.state.gochiDetail.groupInfo && this.state.gochiDetail.groupInfo.groupName;
    const groupId = this.state.gochiDetail && this.state.gochiDetail.groupId;
    const gochiUserType = this.state.gochiDetail && this.state.gochiDetail.gochiUserType;
    const giftCardMessage = this.state.gochiDetail && this.state.gochiDetail.gochiBillMessage && this.state.gochiDetail.gochiBillMessage.length > 0 && this.state.gochiDetail.gochiBillMessage[0].message;
    const giftCardImage = this.state.gochiDetail && this.state.gochiDetail.giftCard && this.state.gochiDetail.giftCard.image;
    const userName = this.state.gochiDetail.gochiRegister && this.state.gochiDetail.gochiRegister.displayName;

    const gochiInfo = {
      gochiBillId: this.state.gochiDetail.gochiBillId,
      gochiBillNo: this.state.gochiDetail.gochiBillNo,
      shopId: shopId,
      shopName: shopName,
      menuName: menuName,
      menuId: menuId,
      menuImage: menuImage,
      includedTaxPrice: includedTaxPrice,
	    displayAmount: displayAmount,
      excludedTaxPrice: excludedTaxPrice,
      gochiCount: gochiCount,
      gochiExpiredDay: gochiExpiredDay,
      menuChildType: menuChildType,
      // childFoods: childFoods,
      groupId: groupId,
      shopStatus: this.state.gochiDetail.shop && this.state.gochiDetail.shop.status
    };

    // const listFavourTemp = this.state.listFavourTemp;
      
    return (
      <>
        <div className="content">
          <Header 
            title = {t('pages.tome.Address to yourself')}
            isLoggedIn={isLoggedIn}
            t={t}
            hasBack={true}
            usingFlow={true}
          />
          <div className="content-mypage">
            {this.state.gochiDetail.shop && this.state.gochiDetail.shop.status === 'CLOSED' &&
              <div className="shop-closed">
                {t('pages.menu.This shop has closed')}
              </div>
            }
            {/* <div className="label-page">
              <i className="label-tome"></i>
              <span className="label-symbol">/</span>
              <span className="label-text">{t('pages.tome.Address to yourself')}</span>
            </div> */}

            {gochiUserType !== constants.GOCHI_TYPE_YOURSELF && this.state.gochiDetail.giftCard && (
              <>
                <div
                  className="pb-2 fs-18 clr-000000 font-weight-bold gift-card-user-info"
                  dangerouslySetInnerHTML={{__html: t('pages.gochiuse.A feast from USERNAME has arrived', {username: userName}), interpolation: {escapeValue: false}}}
                >
                </div>

                <div className="box-card mb-3 text-center">
                  <img src={giftCardImage} alt="" />
                </div>
                {giftCardMessage && (
                  <>
                    <div className="corner-border-top-left"></div>
                    <div className="mt-1 space-pre-line break-word font-weight-bold" style={{padding: '0 15px'}}>
                      {giftCardMessage}
                    </div>
                    <div className="corner-border-bottom-right float-right"></div>
                    <div className="clearfix mb-3"></div>
                  </>
                )}
              </>
            )}
            
            {gochiUserType !== constants.GOCHI_TYPE_YOURSELF ? (
              this.state.sentMessageToGiver ? (
                <>
                  <div className="fs-13 clr-000000 font-weight-bold">{t('pages.gochiuse.I sent a thank you message')}</div>
                  <div className="message-thank-you-to-giver space-pre-line mt-1 mb-4 break-word">
                    <span className="fs-35 font-weight-bold">MESSAGE THANK YOU</span>
                    <br />
                    {this.state.sentMessage}
                  </div>
                </>
              ) : (
                <>
                  <div className="fs-13 clr-000000 font-weight-bold">{t('pages.gochiuse.Would you like to send thank you message')}</div>
                  <textarea 
                    className={this.state.messageTextError ? "form-control mt-1 textarea-message ex-form-required" : "form-control mt-1 textarea-message" } 
                    placeholder={t('pages.gochiuse.messageToBuyerPlaceholder')}  
                    value={this.state.messageText ? this.state.messageText : ''}
                    onChange={this.handleChange}
                    name="messageToBuyer"
                  />
                  {this.state.messageTextError &&
                    <div className="text-danger fs-14 mb-2 fs-12">{this.state.messageTextError}</div>
                  }

                  <button type="button" className="btn btn-green-38 mt-2 mb-4" onClick={this.handleOpenConfirmPopup} disabled={this.state.disabledBtnSendMessage}>
                    {t('pages.gochiuse.Send a message')}
                  </button>
                </>
              )
            ) : null }

            <div className="dish-infor">
              <div className="media mb-2">
                <div className="dish-image">
                  <figure className="mb-0 dish-fig">
                    <img 
                      src={menuImage} 
                      alt={menuName} 
                      width='100%'
                    /> 
                  </figure>
                </div> 
                <div className="media-body dish-body pl-2">
                  <div className="fs-19 dish-link mt-0">
                    {menuName}
                    <div className="line-horizontal menu-name-ln"></div>
                    <div className="fs-19">
                      <span>{t('pages.gochiuse.Unit price')}</span>
                      <span className="pl-5">{displayAmount ? displayAmount.toLocaleString() : includedTaxPrice && includedTaxPrice.toLocaleString()}</span>
                      <span className="fs-13">{t('pages.invite.Yen Unit')}</span>
                    </div>
                    <div className="fs-19">
                      <span>{t('pages.invite.quantityLabel')}</span>
                      <span className="pl-5 fs-21">{gochiCount}/</span>
                      <span className="fs-15">{couponCount}{t('pages.invite.pieces')}</span>
                    </div>
                  </div>
                </div>
              </div>

              {this.state.gochiDetail.menu && this.state.gochiDetail.menu.description && (
                <div className="fs-13 break-line mb-2">
                  {/* <ShowMoreText
                      lines={3}
                      more={t('pages.mypage.showmore')}
                      less={t('pages.mypage.showless')}
                      anchorClass='showmore'
                      onClick={this.executeOnClick}
                      expanded={false}
                      width={0}
                  >
                    <span className='break-all'>
                      {this.state.gochiDetail.menu && this.state.gochiDetail.menu.description}
                    </span>
                  </ShowMoreText> */}
                  {this.state.gochiDetail.menu.description}
                </div>
              )}

              {this.state.gochiDetail.groupId && 
                <button type="button" onClick={this.handleShowListShops} className="btn btn-outline-red-38 mt-2 align-center fs-16 font-weight-bold mb-3">
                  {t('pages.login.List of stores where this meal voucher can be used')}
                </button>
              }
            </div>

            {this.state.gochiDetail.shopId && (
              <>
                <div className="line-horizontal menu-name-ln mb-2"></div>

                <div className="mt-3">
                  <div className="fs-18 clr-000000 font-weight-bold">
                    {shopName}
                  </div>
                  {this.state.gochiDetail.shop && this.state.gochiDetail.shop.prefectures && (
                    <div className="fs-13">
                      {this.state.gochiDetail.shop.prefectures}
                      {this.state.gochiDetail.shop.city ? this.state.gochiDetail.shop.city : ''}
                      {this.state.gochiDetail.shop.address ? this.state.gochiDetail.shop.address : ''}
                      {this.state.gochiDetail.shop.building ? this.state.gochiDetail.shop.building : ''}
                    </div>
                  )}
                </div>

                <button type="button" onClick={this.openMap} className="btn btn-green-38 mt-2 mb-3 align-center fs-16">
                  {t('pages.tome.View map')}
                </button>
              </>
            )}

            <div className="line-horizontal menu-name-ln mb-2"></div>

            {this.state.gochiDetail && this.state.gochiDetail.gochiCode && (
              <div className="fs-13 space-pre-line clr-000000 mt-4">
                <span className="font-weight-bold">{t('pages.share.gochiCode')}: </span>
                <span>{this.state.gochiDetail.gochiCode}</span>
              </div>
            )}

            {this.state.gochiDetail.gochiUserType !== 'ANYONE' && (
              <div className="input-copy mt-2">
                <div className="w-100 position-relative">
                  {this.state.copied &&
                  <div className="copied-tag">{t('pages.mypage.Copied')}</div>
                  }
                  <input
                    className="form-control text-center fs-14"
                    readOnly 
                    defaultValue={this.state.gochiDetail && this.state.gochiDetail.inviteUrl}
                    style={{height:53}}
                  />
                </div>
                <div className="mt-2">
                  <CopyToClipboard
                    onCopy={this.onCopy}
                    text={this.state.gochiDetail && this.state.gochiDetail.inviteUrl}
                  >
                    <button className="btn btn-green-38 ml-auto mr-auto" onCopy={this.onCopy}>
                      {t('pages.share.Copy link')}
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
            )}

            <div className="mt-4">
              <div className="font-weight-bold clr-000000">{t('pages.tome.Ticket expiration date')}</div>

              <Card className="fs-18 align-center" style={{height:53}}>
                {/* <label className="mb-0 mr-4">{t('pages.menu.Expiration date')}</label> */}
                <span className="clr-53a79f font-weight-bold">
                  {this.state.gochiDetail && 
                    moment(this.state.gochiDetail.gochiExpired).format(`YYYY${t('other.year')}MM${t('other.month')}DD${t('other.day')}${t('other.until')}`)}
                </span>
              </Card> 

              <div className="fs-13 mt-3 text-justify">
                {t('pages.menu.Expiration date is 6 months after the order is completed')}<br />
                {t('pages.tome.The expiration date will be displayed 6 months after the date of checking this screen')}
              </div>
            </div>

            {(this.state.gochiDetail.gochiType === 'PRICE' || (this.state.gochiDetail.menu && 
              this.state.gochiDetail.menu.menuChildType !== 3 && 
              this.state.gochiDetail.menu.menuChildType !== 4 && 
              this.state.gochiDetail.menu.menuChildType !== 5)) && (
                <>  
                  <button className="btn btn-red-38 mb-3 mt-3" onClick={() => onOpenGochiUse(gochiInfo)}>
                      {t('pages.tome.Use this ticket')}
                  </button>
                  <div className="fs-13 text-justify">
                    {t('pages.tome.Use ticket note')}
                  </div>
                </>
            )}

            {/* User Infor */}
            <div className="pt-2 pb-2 fs-14">
                <div className="fs-14">
                    <img 
                        src={this.state.gochiDetail.gochiRegister ? 
                                this.state.gochiDetail.gochiRegister.userImage ?
                                    this.state.gochiDetail.gochiRegister.userImage.length > 0 ?
                                        this.state.gochiDetail.gochiRegister.userImage[0].image : 
                                        require("../../../assets/img/icons/gochi_noimg-ver4.jpg"): 
                                    require("../../../assets/img/icons/gochi_noimg-ver4.jpg"): 
                                require("../../../assets/img/icons/gochi_noimg-ver4.jpg")
                                    
                        } 
                        alt={userName}
                        width={20}
                        className="mr-1 rounded-circle"
                    />
                    <span className="text-green font-weight-bold">
                        {userName}
                    </span>
                    <span>{t('pages.tome.Message from')}</span>
                </div>
                {/* {this.state.gochiDetail.gochiBillMessage && this.state.gochiDetail.gochiBillMessage.length > 0 && (
                    <div className="break-line">{this.state.gochiDetail.gochiBillMessage[0].message}</div>
                )} */}
            </div>
            <div className="mb-3">
              <div className="explain-icon ic-1 align-center">
                <div className="explain-note">{t('pages.gochiuse.Explain IC 1')}</div>
              </div>
              <div className="explain-icon ic-2 align-center">
                <div className="explain-note">{t('pages.gochiuse.Explain IC 2')}</div>
              </div>
              <div className="explain-icon ic-3 align-center">
                <div className="explain-note">{t('pages.gochiuse.Explain IC 3')}</div>
              </div>
            </div>
          </div>
        </div>

        {this.state.showListShopPopup && 
          <ListShop
            showListShopPopup={this.state.showListShopPopup}
            handleCloseListShopPopup={this.handleCloseListShopPopup}
            menuId={this.state.menuId}
            menuChildType={this.state.menuChildType}
            showUsingBtn={true}
            gochiInfo={gochiInfo}
            onOpenGochiUse={this.onOpenGochiUse}
          />
        }

        {this.state.showGochiUse ? (
          <GochiUseInfor 
            showing={this.state.showGochiUse}
            onCloseGochiInfo={this.onCloseGochiInfo}
            gochiInfo={this.state.gochiInfo}
          />
        ) : null}

        {/* {this.state.showGochiUseStaff ? (
          <GochiUseStaff
            showing={this.state.showGochiUseStaff} 
            onCloseGochiStaff={this.onCloseGochiStaff}
            gochiInfo={this.state.gochiInfo}
            quantity = {1} 
          />
        ) : null} */}

        <Modal
          size="sm"
          show={this.state.showConfirmMessagePopup}
          onHide={this.onCloseConfirmMessagePopup}
          animation={false} 
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
        >
          <Modal.Header className="header-close" />
          <Modal.Body className="gochi-content mt-2">
            <div className="dish-infor">
              <div className="gochi-infor-shop mb-2 mt-1">{t('pages.gochiuse.Message confirmation')}</div>
              <div className="fs-13 align-center mt-3">{t('pages.gochiuse.The following message will be sent')}</div>
              <div className="line-horizontal break-ln mt-1 mb-2"></div>
              <div className="space-pre-line mt-3 mb-3 break-word">
                  {this.state.messageText}
              </div>
              <div className="line-horizontal break-ln mt-2 mb-3"></div>
              <button type="button" className="btn btn-green-38 mt-2" onClick={this.handleSendMessage}>
                  {t('pages.gochiuse.Send a message review')}
              </button>
            </div>
          </Modal.Body>
          <div className="fs-15 align-center mb-3">
						<button type="link" onClick={this.onCloseConfirmMessagePopup} className="btn btn-close-popup">
              <u className="clr-000000">{t('pages.mypage.title button cancel')}</u>
						</button>
					</div> 
        </Modal>
      </>
    ); 
  }
}
 

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
    showLoading: (showing) => dispatch(showLoading(showing)),
    showError: (showing, title, content) => dispatch(showError(showing, title, content))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ToMeDetail));
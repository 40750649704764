const stateDefault =  [];

export default (state = stateDefault, action) => {
  switch (action.type){
    case 'GET_USER_TARGETS_SUCCESS':
      return action.payload ;
    default:
      return state;
  }
};

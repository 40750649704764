const stateDefault = {
    userTarget: null,
    modalUserTarget: null,
  };
  
  export default (state = stateDefault, action) => {
    switch (action.type) {
      case "SET_MODAL_USER_TARGET":
        return {
          ...state,
          modalUserTarget: action.payload,
        };
      case "SET_USER_TARGET":
        return {
          ...state,
          userTarget: action.payload,
        };
      default:
        return state;
    }
  };
  
export const confirmDonate = (paymentData) => {
  return {
    type: 'GOCHI_KID_DONATE_CONFIRM',
    paymentData
  };
};

export const clearDonate = () => {
  return {
    type: 'GOCHI_KID_DONATE_CLEAR'
  }
}

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { showWishListModal } from "../../../../actions/action_show_wish_list_modal";
// import { useDispatch, useSelector } from "react-redux";
// import { setTempFavoriteMenuAction } from "../../../../actions/action_favorite_menu";
// import { setTempWishListMenuAction } from "../../../../actions/action_wishlist_menu";
import {
  toggleFavoriteMenu,
  toggleWishListMenu
} from "../../../../utils";

export function DishMenu(props) {
  const dispatch = useDispatch();
  var menuId = props.dishInfo.menuId;
  var image = props.dishInfo.image;
  var menuName = props.dishInfo.menuName;
  var menuPriceName = props.dishInfo.menuPriceName;
  var isMenuCombo = props.dishInfo.isMenuCombo;
  var ticketFree = props.dishInfo.ticketFree;
  var comboDescription = props.dishInfo.comboDescription;
  var isMenuPrice = props.dishInfo.isMenuPrice;

  const [tempWishList, setTempWishList] = useState(false);
  const [tempFavorite, setTempFavorite] = useState(false);


  function handleWishListMenu(menuId) {
    toggleWishListMenu(menuId, tempWishList, () => {
      setTempWishList(!tempWishList);
      if (!tempWishList && !props.fromDetailGochi) dispatch(showWishListModal(true));
    });
    var tmp = {
      menuId,
      tempWishList: !tempWishList,
      tempFavorite
    }
    props.handleUpdate(tmp);
  }

  function handleFavoriteMenu(menuId) {
    toggleFavoriteMenu(menuId, tempFavorite, () => {
      setTempFavorite(!tempFavorite);
      if (!tempFavorite && !props.fromDetailGochi) dispatch(showWishListModal(true, true));
    });
    var tmp = {
      menuId,
      tempWishList,
      tempFavorite: !tempFavorite
    }
    props.handleUpdate(tmp);
  }

  useEffect(() => {
    setTempWishList(props.dishInfo.isWishList);
    setTempFavorite(props.dishInfo.isFavourite);
  }, [props.dishInfo.isWishList, props.dishInfo.isFavourite]);

  return (
    <div className={`products-thumbnail ${props.loadingDetailGochi ? 'loading' : ''}`}>
      <div className="thumbnail">
        <img
          src={image}
          alt={menuName || menuPriceName}
          loading="lazy"
        />
      </div>
      {isMenuCombo && (
        <div className="badge-group">
          {ticketFree > 0 && (
            <div className="badge badge-black">無料</div>
          )}
          {comboDescription && comboDescription !== '' && (
            <div className="badge">お得</div>
          )}
        </div>
      )}
      {!isMenuPrice && !props.isGochiCharge && (
        <div className="action-group">
          <div className={`action action-list ${tempWishList ? 'is-active' : 'is-inactive'}`}>
            <button type="button" aria-label="記事を保存" onClick={() => handleWishListMenu(menuId)}>
              <svg className="icon">
                <use xlinkHref="/assets/img/common/icon.svg#list" />
              </svg>
            </button>
          </div>
          <div className={`action action-like ${tempFavorite ? 'is-active' : 'is-inactive'}`}>
            <button type="button" aria-label="記事を保存" onClick={() => handleFavoriteMenu(menuId)}>
              <svg className="icon">
                <use xlinkHref="/assets/img/common/icon.svg#heart" />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

import React, { Component } from "react";    
import {connect} from 'react-redux';
import {Redirect, Link} from 'react-router-dom'; 
import moment from 'moment';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import {log} from '../../../utils';
import {showLoading} from "../../../actions/action_loading";
import {showError} from "../../../actions/action_error_alert";
import * as constants from '../../../constants';
import GochiService from "../../../api/services/GochiService";
import GochiUserType from './GochiUserType';
import {
	FacebookShareButton,
	LineShareButton,
	TwitterShareButton
} from "react-share";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faTwitter, faLine } from '@fortawesome/free-brands-svg-icons'; 
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import Header from "../../layout/header/Header";

class DetailGochi extends Component { 
	constructor(props) { 
	  	super(props); 
	  	this.state = {
			gochiBillId: null, 
			gochiBill: null,
			tagList: null,
			userType: null,
			amount: 0,
			isSakimeshi: true,
			redirectMypage: false,
			gochiCode: null
		};
		this.addComma = this.addComma.bind(this);
		this.getGochi = this.getGochi.bind(this);
		this.handleSetMemo = this.handleSetMemo.bind(this);
		this.handleSaveMemo = this.handleSaveMemo.bind(this);
	}

	async fetchData(gochiBillId){ 
        try {       
            this.props.showLoading(true);     

            let data = await this.getGochi(gochiBillId);
            if(data.length <= 0) {
                this.props.showError(true, "There are no data", "");
            } else {  
				const feeGigiAmount  =   data.feeGigiAmount ? data.feeGigiAmount : 0
  				const discountAmount  =   data.discountAmount ? data.discountAmount : 0
  				const feeSystemAmount  =   data.feeSystemAmount ? data.feeSystemAmount : 0
                this.setState({
					gochiBill: data,
					tagList: (data.shop && data.shop.shopName) || (data.menu && data.menu.menuName) ? ["さきめし", data.shop && data.shop.shopName ? data.shop.shopName : '', data.menu && data.menu.menuName ? data.menu.menuName : ''].filter(Boolean).map((s = "") => s.replace(/\s+/g, "_")) : null,
					userType: data.userType === "ANYONE" ? this.props.t("pages.mypage.title how to dust") : this.props.t("pages.mypage.title fool"),
					amount: this.addComma(
						data.amount + data.taxAmount + data.feeAmount - feeGigiAmount - discountAmount - feeSystemAmount, true
					),
					memo: data.memo,
					gochiCode: data.gochiCode
                });
                
            }
            this.props.showLoading(false); 
		} catch (e) {
            log(e); 
            this.props.showLoading(false);
            this.props.showError(true, this.props.t("pages.login.There is something wrong"), "");
		}	 
	}
	
	addComma = (num, addY) => {
		if (!num) {
			return "0";
		}
		const parts = num.toString().split(".");
		parts[0] = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
		if(addY){
			return "¥" + parts.join(".");
		}else{
			return parts.join(".");
		}
	}
    
    getGochi = async (gochiBillId) => { 
			try {
			  	const { data } = await GochiService.getGochi({gochiBillId});   
			  	return data;
			} catch (error) { 
                log('getGochiList error');
                this.props.showError(true, this.props.t("pages.login.There is something wrong"), "");
			}
	};

	handleSetMemo = (e) => {
		this.setState({
			memo: e.target.value
		});
	}

	handleSaveMemo = async (e) => {
		e.preventDefault();
		try {
			const result = await GochiService.updateMemo({
				memo: this.state.memo ? this.state.memo.toString() : '',
				gochiBillId: this.state.gochiBillId
			});
			if(result.status !== 200) {
				this.props.showError(true, this.props.t("pages.mypage.Update memo failed"), "");
			} else {
				this.setState({
					redirectMypage: true
				})
			}
			return result;
		} catch (error) { 
			log('update memo error');
			this.props.showError(true, this.props.t("pages.login.There is something wrong"), "");
		}
	}

	componentDidMount(){
		let url = window.location.search;
		let params = queryString.parse(url);
		var gochiBillId = params.gochiBillId;

		if (gochiBillId) {
			this.fetchData(gochiBillId)
		}

		this.setState({
			gochiBillId: gochiBillId
		});
	}

    render() {
		const { t } = this.props;
		var imgMenu = this.state.gochiBill && this.state.gochiBill.menuId ? require("../../../assets/img/icons/gochi_noimg-ver4.jpg") : require("../../../assets/img/icons/gochi_price.jpg");
		if (this.state.gochiBill && this.state.gochiBill.gochiBillImage && (this.state.gochiBill.gochiBillImage.length > 0) && this.state.gochiBill.gochiBillImage[0].image) {
			imgMenu = this.state.gochiBill.gochiBillImage[0].image
		}

		let isUsed = true;
		let numberCounpon = 0;
		let numberCounponUsed = 0;
		let listDateUsed = [];
		let listCouponUsedByDay = [];

		const getListCouponUsedByDay = (listDateUsed) => {
			let counts = {};
			let list = [];
			listDateUsed.forEach(function (x) {
			  counts[x] = (counts[x] || 0) + 1;
			});
			let i = 0;
			console.log(counts);
			for (const [key, value] of Object.entries(counts)) {
			  list[i] = {};
			  list[i].date = key;
			  list[i].number = value;
			  list[i].price = !this.state.gochiBill.menuId ? this.state.gochiBill.amount/this.state.gochiBill.couponMessage.length + '円' : '';
			  i++;
			}
			return list;
		};

		// get list date used of counpon
		const couponM = this.state.gochiBill ? this.state.gochiBill.couponMessage : null;
		if (couponM) {
		  numberCounpon = couponM.length;
		  for (let index = 0; index < couponM.length; index++) {
			if (couponM[index].useDate === null) {
			  isUsed = false;
			} else {
			  numberCounponUsed++;
			  if (couponM[index].useDate) {
				listDateUsed.push(
				  moment(couponM[index].useDate).format('YYYY.MM.DD'),
				);
			  }
			}
		  }
		  listCouponUsedByDay = getListCouponUsedByDay (listDateUsed);
		}

		const redirectMypage = this.state.redirectMypage;
        if (redirectMypage === true) {
        	var link = "/mypage";
            window.history.pushState(null, null, '/mypage');
            return <Redirect to={link} />
        }

        var userInfo = this.props.userInfo;
        var linkLogin = "/login";
        let isLoggedIn = false; 
        if (userInfo) {
            if (userInfo.userId) {
                isLoggedIn = true;
            } 
            if (userInfo.isLogined === false || userInfo.isGuest) { 
                window.history.pushState(null, null, linkLogin);
                return <Redirect to={linkLogin} />
            } 
        } else {
            window.history.pushState(null, null, linkLogin);
            return <Redirect to={linkLogin} />
        } 

        return (
        	<div className="content">
				<Header title=
					{t('pages.mypage.title detail menu')}
					isLoggedIn={isLoggedIn}
					t={t}
					hasBack={true}
				/>

				{this.state.gochiBill ?

			    <div className="detail-gochi-content">
					<div className="dish-img-new text-center mt-2 ex-detal">
						{this.state.gochiBill.status === constants.PRE_REGISTER ? 
							<i className="text-pre-shop"></i>
						:null}
			    		<img src={imgMenu} 
							alt={this.state.gochiBill.menu ? this.state.gochiBill.menu.menuName : null} width="340"  />

                        {/* {this.state.gochiBill.menu && this.state.gochiBill.menu.menuCombo === 1 && (
							<div>
								<div className="triangle-label-mypage">
										<span>回数券</span>
								</div>
								<div className="bonus-label-mypage"> 
                                    {this.state.gochiBill.menu.menuComboDetail && 
                                        this.state.gochiBill.menu.menuComboDetail.quantityFree && 
                                            (<span>+{this.state.gochiBill.menu.menuComboDetail.quantityFree.toLocaleString()}枚無料</span>)
                                    }
								</div>
							</div>
						)} */}
			    	</div>

					<div className="tag-list">
						{this.state.tagList ? this.state.tagList.map(function(tag, index){
							return <span key={index.toString()} className="tag-box">{`#${tag}`}</span>
						}) : null}
					</div>

					{this.state.gochiBill.gochiUserType !== constants.GOCHI_TYPE_YOURSELF ?
					<div className="user-info">
						<div className="user-info-item">
							<div className="user-info-avatar">
								{this.state.gochiBill.gochiRegister.userImage ? (
										<img className="avatar-icon" src={this.state.gochiBill.gochiRegister && this.state.gochiBill.gochiRegister.userImage[0] ? this.state.gochiBill.gochiRegister.userImage[0].image : require("../../../assets/img/icons/avatar.png")}  alt={this.state.gochiBill.gochiRegister ? this.state.gochiBill.gochiRegister.displayName : ''} width="18" height="18" />
								) : (
										<div className="no-avatar">{t('pages.mypage.You')}</div>
								)}
								<span className="user-info-name">{this.state.gochiBill.gochiRegister ? this.state.gochiBill.gochiRegister.displayName : ''}</span>
							</div>
							<div className="user-info-text space-pre-line">
									{this.state.gochiBill.gochiBillMessage[0].message}
							</div>
						</div>
					</div>
					: null}

					{this.state.gochiBill.gochiShopMessage ? (
						<div className="gochi-shop-message space-pre-line">{this.state.gochiBill.gochiShopMessage[0] ? this.state.gochiBill.gochiShopMessage[0].shopMessage : ''}</div>
					) : null}

					<div className="box-share">
						<div className="mt-3 text-center mb-0 fs-14 label-share justify-content-center"><span className="title">{t('pages.mypage.share')}</span></div>
						<div className="box-share-sns mt-2 ex-custom mb-1">
							<div className="share-sns">
								<div className="item-share-sns">
									<FacebookShareButton
										url={constants.LINK_SHARE + (this.state.gochiBill.shopId ? this.state.gochiBill.shopId : '')}
										quote={t('pages.mypage.FacebookShareQuote')}
										className="button-facebook button-share-sns"
									>
										{/* <FacebookIcon size={52} round iconFillColor="white" bgStyle={{fill:'#22345d'}} /> */}
										<FontAwesomeIcon icon={faFacebookSquare} className="icon-share"/>
									</FacebookShareButton>
								</div>
								<div className="item-share-sns">
									<LineShareButton
										url={constants.LINK_SHARE + (this.state.gochiBill.shopId ? this.state.gochiBill.shopId : '')}
										title={t('pages.mypage.LineShareQuote')}
										className="button-line  button-share-sns"
									>
										{/* <LineIcon size={52} round iconFillColor="white" bgStyle={{fill:'#22345d'}} /> */}
										<FontAwesomeIcon icon={faLine} className="icon-share"/>
									</LineShareButton>
								</div>
								<div className="item-share-sns">
									<TwitterShareButton
										url={constants.LINK_SHARE + (this.state.gochiBill.shopId ? this.state.gochiBill.shopId : '')}
										title={t('pages.mypage.TwitterShareQuote')}
										className="button-twitter button-share-sns"
									>
										{/* <TwitterIcon size={52} round iconFillColor="white" bgStyle={{fill:'#22345d'}} /> */}
										<FontAwesomeIcon icon={faTwitter} className="icon-share"/>
									</TwitterShareButton>
								</div>
							</div>
						</div>
					</div>

					<div className="divider-dashed"></div>

					<div>
						<label className="ttl-label fs-14 clr-000000 font-weight-bold">
                            {t('pages.mypage.title gochi information')}
                        </label>
						<table className="table table-infor">
							<tbody className="detail-gochi">
								<tr>
									<th>{this.state.gochiBill.shop ? t('pages.mypage.title shop name') : t('pages.mypage.Group')}</th>
									<td>
                                        {this.state.gochiBill.shop && (
                                            <Link to={{ pathname:`https://www.sakimeshi.com/detail/`+ this.state.gochiBill.shopId}} 
                                                className="link-shop" 
                                                target="_blank"
                                            >
                                                {this.state.gochiBill.shop.shopName || ''}
                                            </Link>
                                        )}

                                        {this.state.gochiBill.groupInfo && (
                                            <div 
                                                // to={{ pathname:`https://www.sakimeshi.com/detail/`+ this.state.gochiBill.shopId}} 
                                                className="font-weight-bold" 
                                                // target="_blank"
                                            >
                                                {this.state.gochiBill.groupInfo.groupName || ''}
                                            </div>
                                        )}
                                    </td>
								</tr>
								{this.state.gochiBill.menuId ?
								<tr>
									<th>{t('pages.mypage.title menu name')}</th>
									<td>{this.state.gochiBill.menu ? this.state.gochiBill.menu.menuName : ''}</td>
								</tr>
								: null}
								<tr>
									<th>{t('pages.mypage.title number coupon used')}</th>
									{this.state.gochiBill.menuId ? (
										<td><span className="bold">{numberCounpon}{t('pages.invite.pieces')}</span> ({numberCounponUsed}/{numberCounpon}{t('pages.mypage.Used')})</td>
									) : ( 
										<td>{this.state.gochiBill.amount/this.state.gochiBill.couponMessage.length + '円'} x {numberCounpon}{t('pages.invite.pieces')}</td>
									)}
								</tr>
								<tr>
									<th>{t('pages.mypage.title feast day')}</th>
									<td>{this.state.gochiBill.createdAt ? moment(this.state.gochiBill.createdAt).format("YYYY.MM.DD"): ''}</td>
								</tr>
								<tr>
									<th>{t('pages.mypage.title waste use used')}</th>
									<td>
									{listCouponUsedByDay ? listCouponUsedByDay.map(function(item, index){
										return <div key={index.toString()}>{item.date} ({item.price ? item.price + ' x ' + item.number : item.number})</div>
									}) : null}
									</td>
								</tr>
								<tr>
									<th>{t('pages.share.gochiCode')}</th>
									<td>
										{this.state.gochiCode}
									</td>
								</tr>
								<tr>
									<th>{this.state.userType}</th>
									<td>
										<GochiUserType gochiBill={this.state.gochiBill} />
									</td>
								</tr>
								<tr>
									<th>{t('pages.mypage.title missing amount')}</th>
									<td>
										{this.state.amount}円{this.state.gochiBill.menu && this.state.gochiBill.menu.taxType === 'DONATION' ? t('pages.mypage.title including tax fees donate') : t('pages.mypage.title including tax fees')}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className="date-gochi">
						{!isUsed ?
							<div className="date-expired-gochi fs-13 font-weight-bold text-red">
								<FontAwesomeIcon icon={faExclamationCircle} className="mr-2 fs-14"/>
								{t('pages.mypage.The expiration date is x days', {gochiExpiredDay: this.state.gochiBill.gochiExpiredDay})}
							</div> 
						: null}
					</div>

					<div className="divider-dashed"></div>

					<div>
						<form onSubmit={this.handleSaveMemo}>
							<div className="mt-4 text-center d-flex justify-content-between align-items-center">
								<span className="font-weight-bold fs-14">{t('pages.mypage.memo')}</span>
								<button type="submit" className="btn button-memo" >
									{t('pages.mypage.title button save memo')}   
								</button>
							</div>
							<div className="mt-2">
								<div className="form-group">
									<textarea className="input-memo form-control h-80"
										placeholder={t('pages.mypage.placeholder memo')} 
										onChange={this.handleSetMemo} 
										name="memo"
										defaultValue={this.state.memo}
									/>
								</div>
							</div>
						</form>
					</div>
			    </div>
				: null}
			</div>
        ); 
    }
} 

const mapStateToProps = state => ({
	...state
}); 

const mapDispatchToProps = dispatch => ({
    showLoading: (showing) => dispatch(showLoading(showing)),
    showError: (showing, title, content) => dispatch(showError(showing, title, content)),
});
 
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DetailGochi)); 

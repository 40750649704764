const initialState = {
    messageText: '',
    publicMessage: false
};

export default (state = initialState, action) => { 
  switch (action.type){
    case 'MESSAGE_SHOP':  
      return  {       		
          messageText: action.messageText,
         publicMessage: action.publicMessage
      };
    default:
      return state;
  }
};
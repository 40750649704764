import React, { useState } from "react";

export function PolicyGochiKidCampaign(props) {
  return (
    <div
      id={5342}
      className={`modal modal-profile modal-profile-01 modal-01 modal-lg history-modal policy-gochi-kid-modal policy-gochi-kid-campaign-modal ${
        props.showPolicyGochiKidCampaignModal ? "is-show" : ""
      }`}
      tabIndex={-1}
    >
      <div className="modal-header-history">
        <button
          className="modal-btn modal-btn-close"
          id="modalClose"
          onClick={props.handleClosePolicyGochiKidCampaignModal}
          type="button"
        >
          <svg className="icon">
            <title>閉じる</title>
            <use xlinkHref="/assets/img/common/icon.svg#times" />
          </svg>
        </button>
      </div>
      <div className={`modal-body`}>
        <div className="title">
          <i className="icon-poor-note"/>
          ルール変更のお知らせ
        </div>
        <div className="content-container">
          <div className="header">2024年8月1日より、サービスのご利用ルールが一部変更となります。</div>
          <div className="title-content">変更内容</div>
          <div className="body-content">
            これまで：1日 1,000円、週1回まで（※生活保護対象家庭は毎日）
            <br />
            今後：1日 1,000円、月1回まで（※生活保護対象家庭は毎日）
            <br />
            変更後は、毎月1日に利用回数が1回となります。
            <br />
            <br />
            利用する際は、店舗や支援元の利用可能食数をご確認ください。
            <br />
            残数がない場合はご利用いただけません。どうぞ、よろしくお願いいたします。
          </div>
        </div>
      </div>
    </div>
  );
}

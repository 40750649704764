import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import GiftService from "../../../api/services/GiftService";
import {
  useScript,
} from "../../../utils";
import { CustomPagination } from "../common/Pagination";
import Header from "../layout/Header";
import queryString from "query-string";
import { connect } from "react-redux";
import { showLoading } from "../../../actions/action_loading";
import { showError } from "../../../actions/action_error_alert";

const NO_IMAGE = require("../../../assets/img/icons/square_blank.png");

function MenuSearch(props) {
  useScript("/assets/js/app.js");

  useEffect(() => {
    document.body.className = "mogugori productss";
  }, []);

  const params = queryString.parse(window.location.search);

  const [orderBy, setOrderBy] = useState(params.orderBy || 4);
  const [page, setPage] = useState(1);
  const LIMIT = 24;
  const [searchResults, setSearchResults] = useState([]);
  const [totalResult, setTotalResults] = useState(0);
  const [loading, setLoading] = useState(false);
  const shopId = sessionStorage.getItem("mogugoriShopId");

  function fetchData(newParams) {
    console.log("Fetch list menus", newParams);
    getMenus(newParams);
  }

  useEffect(() => {
    // Run on search conditions change
    window.scrollTo(0, 0);
    fetchData({
      keyword: params.keyword || '',
      orderBy: orderBy,
      page: 1
    });
  }, []);

  function getMenus(newParams) {
    setLoading(true);
    const getParams = newParams || params;
    console.log("getMenus: ", getParams);
    const parameters = {
      shopId: shopId,
      page: getParams.page,
      perPage: LIMIT,
      keyword: getParams.keyword,
      orderBy: getParams.orderBy,
    };
    GiftService.getMenus(parameters)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          setSearchResults(response.data.list);
          setTotalResults(response.data.total);
        } else if (response.status === 404) {
          setSearchResults([]);
          setTotalResults(0);
        } else {
          alert("エラーが発生しました。");
        }
      })
      .catch((e) => {
        setLoading(false);
        console.error("SEARCH MENU ERROR: ", e);
      });
  }

  function handleChangePage(toPage) {
    console.log("HANDLE CHANGE PAGE:", toPage);
    setPage(toPage);
    fetchData({
      keyword: params.keyword || '',
      orderBy: orderBy,
      page: toPage
    });
    window.scrollTo(0, 0);
  }

  function handleChangeOrder(by) {
    console.log("HANDLE CHANGE ORDER:", by);
    setOrderBy(by);
    fetchData({
      keyword: params.keyword || '',
      orderBy: by,
      page: 1
    });
    setPage(1);
  }

  const searchParamsArr = [];
  if (params.keyword) {
    searchParamsArr.push(params.keyword);
  }
  const searchStr = searchParamsArr.join("・");


  return (
    <>
      <MetaTags>
        <title>お店一覧｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content="お店一覧です。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
      </MetaTags>
      <Header />

      <main>
        <div className="container container-mogugori">
          {/* 店舗一覧のHTML */}
          <section
            className={
              (loading && searchResults.length === 0)
                ? "section list-menu-mogugori loading"
                : "section list-menu-mogugori"
            }
          >
            <div className="section-header">
              <div className="heading-lg">
                <h1 className="title">
                  {searchStr}
                  {searchStr ? `で見つかった商品・お食事券: ` : `見つかった商品・お食事券: `}
                  {totalResult.toLocaleString()}件
                </h1>
              </div>
            </div>
            <div className="section-body">
              {searchResults.length > 0 && (
                <nav className="nav">
                  <div className="fieldset mogugoru-block-select-order-by">
                    <div className="field field-pill field-md">
                      <select
                        className="select-order-by"
                        onChange={(e) => {
                          handleChangeOrder(e.target.value);}
                        }
                        defaultValue={orderBy}
                      >
                        {/* <option value="すべて">すべて</option> */}
                        <option value={1}>おすすめ順</option>
                        <option value={3}>価格の高い順</option>
                        <option value={4}>価格の安い順</option>
                        <option value={2}>新着順</option>
                      </select>
                    </div>
                  </div>
                </nav>
              )}
              <div className="products-group mt-20">
                {searchResults.map((menu) => (
                  <article
                    key={menu.menuId}
                    className={
                      loading
                        ? "products bg-white loading"
                        : "products bg-white"
                    }
                  >
                    <a
                      className="products-link"
                      href={`/menu?id=${menu.menuId}&isSite=mogugori`} target="_self"
                    />
                    <div className="products-thumbnail">
                      <div className="thumbnail image-thumbnail-mogugori">
                        <img
                          src={
                            menu.menuImage
                              ? menu.menuImage[0] && menu.menuImage[0].image
                              : NO_IMAGE
                          }
                          alt={menu.menuName}
                          loading="lazy"
                        />
                      </div>
                      <div className="badge-group">
                        {menu.menuComboDetail?.quantityFree > 0 && (
                          <div className="badge badge-black">無料</div>
                        )}
                        {menu.menuComboDetail?.quantityFreeDes && (
                          <div className="badge">お得</div>
                        )}
                      </div>
                    </div>
                    <div className="products-header">
                      <div className="text-sm text-gray title-custom">
                        {menu.menuChildType === 1 || menu.menuChildType === 2
                          ? menu.shopName
                          : menu.groupName}
                      </div>
                      <h2 className="title">{menu.menuName}</h2>
                    </div>
                    <div className="price-area">
                      <span className="unit">¥</span>
                      <span className="num">
                        {menu.chargeAmount?.toLocaleString()}
                      </span>
                      {/* <span className="dash">〜</span> */}
                    </div>
                    {menu.menuCombo === 1 && (
                      <div className="badge badge-lg badge-red">回数券</div>
                    )}
                  </article>
                ))}
              </div>
              {searchResults.length > 0 && (
                <CustomPagination
                  count={Math.ceil(totalResult / LIMIT)}
                  page={page}
                  onChange={handleChangePage}
                />
              )}
            </div>
          </section>
        </div>
      </main>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content, buttonName, gotoUrl) =>
    dispatch(showError(showing, title, content, buttonName, gotoUrl)),
});

export default connect(null, mapDispatchToProps)(MenuSearch);

import React, { Component } from "react";   
import {Redirect} from 'react-router-dom'; 
import {log, addParamUrl} from '../../../utils'; 
import DishInfor from './DishInfor';
import GuestInfo from './GuestInfo';
import InviteQuantity from './InviteQuantity';
import SelectFriends from './SelectFriends';
import MessageShop from './MessageShop';
import Payment from './Payment';
import PaymentGuest from './PaymentGuest';
import {connect} from 'react-redux';
import {saveInitTotalAmount} from "../../../actions/action_menu";
import {saveInitTotalAmountMenuPrice, saveInitMenuPrice, saveInitAmount} from "../../../actions/action_shop"; 
import {saveGuestInfo} from "../../../actions/action_guest";
import {saveUserInfo} from "../../../actions/action_users";
import {selectFriend} from "../../../actions/action_select_friend";
import {addMessageCard} from "../../../actions/action_message_card";
import { withTranslation } from 'react-i18next';
import queryString from 'query-string'; 
import * as constants from '../../../constants';  
import Header from "../../layout/header/Header";
import { showError } from "../../../actions/action_error_alert";
import NoPayment from "./NoPayment";
import PaypayAd from "../../common/PaypayAd";

class InputInvite extends Component { 
	constructor(){
	    super(); 
	    this.state = { 
            redirectToReferrer: false,
            selectedFriend: [],
            inviteContent: [],
            redirectToLogin: false,
            menuId: null,
            quantityError: "",
            selectFriendError: "",
            selectPaymentError: "",
			messageError: "",
			src: "",
            userName: '',
            email: '',
            paramUsername: '',
            paramEmail: '',
            paramShashoku: '',
			inputPriceErr: '',
			feeRateErr: '',
			gochiType: null,
			shopId: null,
			inputPrice: 100,
			isMogugori: false,
			isSite: ''
        }
		this.handleSaveInvite = this.handleSaveInvite.bind(this);
		// this.emailChangeHandler = this.emailChangeHandler().bind(this);
	}

	handleSaveInvite(e){
	    e.preventDefault();    
        const { t } = this.props;  

	    var validated = true;
		const totalAmountMenu = this.props.menuInfo.updatedMenuInfo 
			? this.props.menuInfo.updatedMenuInfo && this.props.menuInfo.updatedMenuInfo.totalAmount 
			: (this.props.menuInfo.initTotalAmount || this.props.menuInfo.chargeAmount);
		const selectedPaymentType = parseInt(sessionStorage.getItem('selectPayment'));
        const isCampaign = this.props.menuInfo?.campaignId ? true : false;
    const isMenuEventHiddenGochiUserType = constants.MENU_EVENT_HIDDEN_GOCHI_USER_TYPE.includes(this.props.menuInfo?.menuEventId);
		if (selectedPaymentType === constants.PAYMENT_TYPE_STRIPE && totalAmountMenu < 50) {
			this.props.showError(true, t('pages.invite.error create gochi using stripe incase amount to small'));
			validated = false;
		}
	    if (e.target.elements.quantity.value === "") {
	    	this.setState({
	    		quantityError: t('pages.invite.Please enter a number')
	    	})
	    	validated = false;
	    } else {
	    	if (parseInt(e.target.elements.quantity.value) < 1 || parseInt(e.target.elements.quantity.value) > 100) {
	    		this.setState({
		    		quantityError: t('pages.invite.Please enter a number between 1 and 100')
		    	})
		    	validated = false;
	    	}else{
	    		this.setState({
		    		quantityError: ""
		    	}) 
	    	}
	    }

		if (this.state.menuId && !this.state.shopId) {
			if (this.props.menuInfo && !this.props.menuInfo.feeRate) {
				this.setState({
					feeRateErr: t('pages.invite.Can not get feeRate')
				});
				validated = false;
			}
		}

		if(!this.state.menuId && this.state.shopId) {
			if(e.target.elements.inputPrice.value === "") {
				this.setState({
					inputPriceErr: t('pages.invite.Please enter a number')
				});
				validated = false;
			} else {
				if (parseInt(e.target.elements.inputPrice.value) < 50 && selectedPaymentType === constants.PAYMENT_TYPE_STRIPE) {
					this.setState({
						inputPriceErr: t('pages.invite.You can enter the price from 50 yen or more')
					})
					validated = false;
				}else{
					this.setState({
						inputPriceErr: ""
					}) 
				}
			}

			if (this.props.shopInfo && !this.props.shopInfo.feeRate) {
				this.setState({
					feeRateErr: t('pages.invite.Can not get feeRate')
				});
				validated = false;
			}
		}

	    var userInfo = this.props.userInfo; 
		var selectFriend = {};
		if (this.props.menuInfo.taxType !== 'DONATION' && this.props.menuInfo.menuChildType !== 3 && !this.state.isMogugori && !isCampaign && !isMenuEventHiddenGochiUserType) {
			if (parseInt(e.target.elements.friendType.value) === parseInt(constants.FRIEND_TYPE_USER) ){ 
				if (!userInfo.isGuest) {
					if (this.props.friend.gochiUserType !== constants.GOCHI_TYPE_NON_USER) { 
						if (this.props.friend.selectedFriendId === null) { 
							this.setState({
								selectFriendError: t('pages.invite.Please choose a friend')
							})

							validated = false;
						}else{
							this.setState({
								selectFriendError: ""
							})
						} 
					} 
				}else{
					this.setState({
						selectFriendError: ""
					}) 

					this.props.selectFriend(null, constants.FRIEND_IN_SYS, null, constants.GOCHI_TYPE_NON_USER);
				
					selectFriend = {
						selectedFriendId: null,
						friendType: constants.FRIEND_IN_SYS,
						friendInfo: null,
						gochiUserType: constants.GOCHI_TYPE_NON_USER
					}

					sessionStorage.setItem('selectFriend', JSON.stringify(selectFriend)); 
				}
			}else{
				this.setState({
					selectFriendError: ""
				}) 
			}
		} 

	    if (e.target.elements.messageInvite && e.target.elements.messageInvite.value.length > 200) {
	    	this.setState({
	    		messageError: t('pages.invite.Please enter a message within 200 characters')
	    	})
	    	validated = false;
	    }else{
	    	this.setState({
	    		messageError: ""
	    	})
		}

		if (e.target.elements.situation && e.target.elements.situation.value.length > 200) {
	    	this.setState({
	    		situationError: t('pages.invite.Please enter a message within 200 characters')
	    	})
	    	validated = false;
	    }else{
	    	this.setState({
	    		situationError: ""
	    	})
		}
		
    if (this.state.isMogugori && e.target.elements.messageShop && (!e.target.elements.messageShop.value || e.target.elements.messageShop.value.trim() === '')) {
      this.setState({
        messageShopError: "必須項目です。"
      })
      validated = false;
    } else if (e.target.elements.messageShop && e.target.elements.messageShop.value.length > 200) {
	    	this.setState({
	    		messageShopError: t('pages.invite.Please enter a message shop within 200 characters')
	    	})
	    	validated = false;
	    }else{
	    	this.setState({
	    		messageShopError: ""
	    	})
	    }

		if(this.props.friend && this.props.friend.gochiUserType !== constants.GOCHI_TYPE_YOURSELF) {
			if(e.target.elements.messageCard && e.target.elements.messageCard.value.length > 300) {
				this.setState({
					messageCardError: t('pages.invite.Please enter the message within 300 characters')
				});
				validated = false;
			} else {
				this.setState({
					messageCardError: ""
				});
			}
		}

		const paypaySupport = (
				this.props.shopInfo 
				&& this.props.shopInfo.paymentTyptes 
				&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_PAYPAY)
				&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_PAYPAY).status === 1
			) || this.props.menuInfo.groupId
			? true : false;
		
		const alipaySupport = (
				this.props.shopInfo
				&& this.props.shopInfo.paymentTyptes 
				&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_ONEPAY_ALIPAY)
				&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_ONEPAY_ALIPAY).status === 1
			) || this.props.menuInfo.groupId
			? true : false;
		
		const wechatPaySupport = this.props.shopInfo 
			&& this.props.shopInfo.paymentTyptes 
			&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_WECHATPAY)
			&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_WECHATPAY).status === 1 
			? true : false;

		const linepaySupport = this.props.shopInfo 
			&& this.props.shopInfo.paymentTyptes 
			&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_ONEPAY_LINEPAY)
			&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_ONEPAY_LINEPAY).status === 1 
			? true : false;

		const merupaySupport = this.props.shopInfo 
			&& this.props.shopInfo.paymentTyptes 
			&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_ONEPAY_MERUPAY)
			&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_ONEPAY_MERUPAY).status === 1 
			? true : false;

		const atoneSupport = true;

		if (!userInfo.isGuest) {
			let isCreditCard = (!paypaySupport && !alipaySupport && !wechatPaySupport && !linepaySupport && !merupaySupport && !atoneSupport) ||
					parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_STRIPE ||
					parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_VERITRANS;
			if ((this.props.shopInfo || this.props.groupInfo) && isCreditCard && this.props.menuInfo?.noPayment !== 1) {
				if (!this.props.paymentMethod || this.props.paymentMethod.length <= 0) {
					this.setState({
						selectPaymentError: t('pages.invite.Please select a payment method')
					})
					validated = false;
				}else{
					this.setState({
						selectPaymentError: ""
					}) 
				}
			}
		} else {
			if (e.target.elements.customerEmail.value) {
				if (this.validateEmail(e.target.elements.customerEmail.value)) {
					this.setState({
						customerEmailError: ""
					})
				}else {
					this.setState({
						customerEmailError: t('pages.invite.Invalid email format')
					})
					validated = false;
				}
			}else{
				this.setState({
					customerEmailError: t('pages.invite.Please enter your e-mail address')
				})
				validated = false;
			}

			if (e.target.elements.customerName.value){
				if (e.target.elements.customerName.value.length > 50) {
					this.setState({
						customerNameError: t('pages.invite.Please enter customer name within 50 characters')
					})
					validated = false;
				}else{
					this.setState({
						customerNameError: ""
					})
				}
			}else {
				this.setState({
					customerNameError: t('pages.invite.Please enter your name')
				})
				validated = false;
			}
			var guestInfo = {
				customerEmail: e.target.elements.customerEmail.value,
				customerName: e.target.elements.customerName.value
			}
			this.props.saveGuestInfo(guestInfo);
			sessionStorage.setItem('guestInfo', JSON.stringify(guestInfo));
		}

	    if (validated) {
			if (!this.state.menuId && this.state.shopId) {
				this.props.saveInitTotalAmountMenuPrice(parseInt(e.target.elements.totalAmount.value));
				this.props.saveInitMenuPrice(parseInt(e.target.elements.includedTaxMenuPrice.value));
				this.props.saveInitAmount(parseInt(e.target.elements.initAmount.value));
			} else {
				this.props.saveInitTotalAmount(parseInt(e.target.elements.totalAmount.value));
			}
	    	
	    	var friendInfo = {};
			var gochiUserType = null;
			var selectedFriendId = null;
			var friendType = null;
			var messageCard = {};
			if (this.props.menuInfo.taxType !== 'DONATION' && this.props.menuInfo.menuChildType !== 3 && !this.state.isMogugori && !isCampaign && !isMenuEventHiddenGochiUserType) {
				if (parseInt(e.target.elements.friendType.value) === constants.FRIEND_TYPE_ANYONE) { 
					gochiUserType = constants.GOCHI_TYPE_ANYONE; 
					friendInfo.situation = e.target.elements.situation.value;
				}else if (parseInt(e.target.elements.friendType.value) === constants.FRIEND_TYPE_YOURSELF) { 
					gochiUserType = constants.GOCHI_TYPE_YOURSELF; 
					friendInfo = this.props.friend.friendInfo;
					selectedFriendId = parseInt(this.props.friend.selectedFriendId);
					friendType = this.props.friend.friendType;
				}else{ 
					gochiUserType = this.props.friend.gochiUserType;
					if (gochiUserType === constants.GOCHI_TYPE_USER) {
						friendInfo = this.props.friend.friendInfo;
						selectedFriendId = parseInt(this.props.friend.selectedFriendId);
						friendType = this.props.friend.friendType;
					}

					if (this.props.userInfo.isGuest) {
						if (!this.props.friend.gochiUserType && parseInt(e.target.elements.friendType.value) === parseInt(constants.FRIEND_IN_SYS)) { 
							gochiUserType = constants.GOCHI_TYPE_NON_USER;
						}
					}
				}
			} else if ((this.props.menuInfo.menuChildType === 3 || this.props.menuInfo.taxType === 'DONATION' || this.state.isMogugori) && (!isCampaign) && !isMenuEventHiddenGochiUserType) {
				gochiUserType = constants.GOCHI_TYPE_ANYONE; 
				friendInfo = this.props.friend.friendInfo;
				selectedFriendId = parseInt(this.props.friend.selectedFriendId);
				friendType = this.props.friend.friendType;
			} else if (isCampaign || isMenuEventHiddenGochiUserType) {
                gochiUserType = constants.GOCHI_TYPE_YOURSELF;
            }

			this.props.selectFriend(selectedFriendId, friendType, friendInfo, gochiUserType);   
		   	selectFriend = {
		   		selectedFriendId: selectedFriendId,
		   		friendType: friendType,
		   		friendInfo: friendInfo,
		   		gochiUserType: gochiUserType
		   	}

		   	sessionStorage.setItem('selectFriend', JSON.stringify(selectFriend));
			
			if (!this.state.menuInfo && this.state.shopId) {
				var shopInfo = this.props.shopInfo;

				shopInfo.initTotalAmount = parseInt(e.target.elements.totalAmount.value);
				shopInfo.includedTaxMenuPrice = parseInt(e.target.elements.includedTaxMenuPrice.value);
				shopInfo.initAmount = parseInt(e.target.elements.initAmount.value);
				sessionStorage.setItem('shopInfo', JSON.stringify(shopInfo));
			} else {
				var menuInfo = this.props.menuInfo;

				menuInfo.initTotalAmount = parseInt(e.target.elements.totalAmount.value);
				sessionStorage.setItem('menuInfo', JSON.stringify(menuInfo));
			}

			if(this.props.menuInfo.taxType !== 'DONATION' && this.props.menuInfo.menuChildType !== 3 && !this.state.isMogugori && !isCampaign && !isMenuEventHiddenGochiUserType) {
				if(parseInt(e.target.elements.friendType.value) !== constants.FRIEND_TYPE_YOURSELF) {
					messageCard.thankYouText = e.target.elements.messageCard.value;
					messageCard.thankYouImage = this.props.messageCard.thankYouImage ? this.props.messageCard.thankYouImage : sessionStorage.getItem('giftCardDefault') && JSON.parse(sessionStorage.getItem('giftCardDefault')).thankYouImage;
					messageCard.giftCardId = this.props.messageCard.giftCardId ? this.props.messageCard.giftCardId : sessionStorage.getItem('giftCardDefault') && JSON.parse(sessionStorage.getItem('giftCardDefault')).giftCardId;
					// messageCard.thankYouImage = sessionStorage.getItem('messageCard') && JSON.parse(sessionStorage.getItem('messageCard')).thankYouImage ? JSON.parse(sessionStorage.getItem('messageCard')).thankYouImage : this.props.messageCard.thankYouImage;
					// messageCard.giftCardId = sessionStorage.getItem('messageCard') && JSON.parse(sessionStorage.getItem('messageCard')).giftCardId ? JSON.parse(sessionStorage.getItem('messageCard')).giftCardId : this.props.messageCard.giftCardId;
					this.props.addMessageCard(messageCard.thankYouText, messageCard.thankYouImage, messageCard.giftCardId);
					sessionStorage.setItem('messageCard', JSON.stringify(messageCard));
				}
			}

	    	this.setState({ 
	            redirectToReferrer: true
	        })
	    }
	}

	validateEmail(email) {
		// eslint-disable-next-line no-useless-escape
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
    }
    
    queryStringToJSON(queryString) {
		if(queryString.indexOf('?') > -1){
		  queryString = queryString.split('?')[1];
		}
		var pairs = queryString.split('&');
		var result = {};
		pairs.forEach(function(pair) {
		  pair = pair.split('=');
		  result[pair[0]] = decodeURIComponent(pair[1] || '');
		});
		return result;
	}

	UNSAFE_componentWillMount() {
		let url = window.location.search;
		let params = queryString.parse(url);
		var menuId = params.id;

		const shopId = params.shopId;
		const gochiType = params.gochiType;

        // const userName = params.userName;
        // const email = params.email;
        // const isShashoku = params.isShashoku;
        const fromShashoku = params.fromShashoku;
        const fromShashokuDecode = decodeURIComponent(fromShashoku);

        const fromShashokuParams = this.queryStringToJSON(fromShashokuDecode);
        const userName = fromShashokuParams.userName;
        const email = fromShashokuParams.email;
		var src = params.src;
		var isSite = params.isSite;

		if (src) {
			this.setState({
				src: src
			});
		}

		if (isSite) {
			this.setState({
				isSite: isSite
			});
		}

		if (isSite === 'mogugori') {
			this.setState({
				isMogugori: true
			});
		}

		this.setState({
			menuId: menuId,
            userName: userName,
            email: email
        });
        
        if (userName) {
            this.setState({
				paramUsername: `&userName=${userName}`
			});
        }

        if (email) {
            this.setState({
				paramEmail: `&email=${email}`
			});
        }
        
        if (fromShashoku) {
            this.setState({
				paramShashoku: `&fromShashoku=${encodeURIComponent(fromShashoku)}`
			});
        }

		if(shopId) {
			this.setState({
				shopId: shopId
			});
		}

		if(gochiType) {
			this.setState({
				gochiType: gochiType
			});
		}

		// Only for case guest access to buy menu PRICE
		if(shopId) {
			var userInfo = this.props.userInfo;
			if(!userInfo || !userInfo.userId) {
				var tmp = {
					isLogined: true,
					isGuest: true
				};
				localStorage.setItem('userInfo', JSON.stringify(tmp));
				this.props.saveUserInfo(tmp.isLogined, tmp.isGuest, tmp);
			}
		}
	}

    render() {   
    	log(['props : ',this.props]); 
		document.body.className = "";
		const { t } = this.props;
        const isCampaign = this.props.menuInfo.campaignId ? true : false;
    const isMenuEventHiddenGochiUserType = constants.MENU_EVENT_HIDDEN_GOCHI_USER_TYPE.includes(this.props.menuInfo?.menuEventId);
		const isSiteParam = this.state.isSite ? {name: 'isSite', value: this.state.isSite} : {};
		// set selectPayment when menu is group
		// if (Object.keys(this.props.shopInfo).length === 0) {
		// 	sessionStorage.setItem("selectPayment", constants.PAYMENT_TYPE_CREDIT_CARD);
		// }

		var userInfo = JSON.parse(localStorage.getItem('userInfo'));
		var srcParam = this.state.src ? "&src=" + this.state.src : "";
        var link = addParamUrl("/menu?id=" + this.state.menuId + srcParam + this.state.paramShashoku, [isSiteParam]);
        let isLoggedIn = false;
    	if (userInfo) { 
    		if (userInfo.isLogined === false) {
				if (this.props.menuInfo?.menuChildType === 5) {
					window.history.pushState(null, null, '/menu?id=' + this.state.menuId + srcParam + this.state.paramShashoku);
					let link = '/menu?id=' + this.state.menuId + srcParam + this.state.paramShashoku;
					window.location.href = link;
				}
				if(!this.state.menuId && this.state.shopId) {
					window.history.pushState(null, null, addParamUrl('/invite?shopId=' + this.state.shopId + srcParam + this.state.paramShashoku, [isSiteParam]));
					let link2 = addParamUrl('/invite?shopId=' + this.state.shopId + srcParam + this.state.paramShashoku, [isSiteParam]);
					// return <Redirect to={'/login'} />
					return <Redirect to={link2} />
				} else {
					window.history.pushState(null, null, addParamUrl('/invite?id=' + this.state.menuId + srcParam + this.state.paramShashoku, [isSiteParam]));
					return <Redirect to={link} />
				}
            }

            if (userInfo.userId) {
                isLoggedIn = true;
            } else {
				if (this.props.menuInfo?.menuChildType === 5) {
					window.history.pushState(null, null, '/menu?id=' + this.state.menuId + srcParam + this.state.paramShashoku);
					let link = '/menu?id=' + this.state.menuId + srcParam + this.state.paramShashoku;
					window.location.href = link;
				}
                isLoggedIn = false;

            }
    	} else {
            isLoggedIn = false;
			if (this.props.menuInfo?.menuChildType === 5) {
				window.history.pushState(null, null, '/menu?id=' + this.state.menuId + srcParam + this.state.paramShashoku);
				let link = '/menu?id=' + this.state.menuId + srcParam + this.state.paramShashoku;
				window.location.href = link;
			}
			if(!this.state.menuId && this.state.shopId) {
				window.history.pushState(null, null, addParamUrl('/invite?shopId=' + this.state.shopId + srcParam + this.state.paramShashoku, [isSiteParam]));
				let link2 = addParamUrl('/invite?shopId=' + this.state.shopId + srcParam + this.state.paramShashoku, [isSiteParam]);
				// return <Redirect to={'/login'} />
				return <Redirect to={link2} />
			} else {
				window.history.pushState(null, null, addParamUrl('/invite?id=' + this.state.menuId + srcParam + this.state.paramShashoku, [isSiteParam]));
				return <Redirect to={link} />
			}
    	}
		// Check if key on URL not in (id, shopId)
		if (!this.state.menuId && !this.state.shopId) {
			window.history.pushState(null, null, addParamUrl('/menu?id=' + srcParam, [isSiteParam]));
			let linkToLogin = addParamUrl("/menu?id=" + srcParam + this.state.paramShashoku, [isSiteParam]);
			return <Redirect to={linkToLogin} />
		}
    	const redirectToReferrer = this.state.redirectToReferrer;
        if (redirectToReferrer === true) {
			if (!this.state.menuId && this.state.shopId) {
				window.history.pushState(null, null, addParamUrl('/invite?shopId=' + this.state.shopId + srcParam + this.state.paramShashoku, [isSiteParam]));
				let link_confirm_menu_price = addParamUrl("/invite-confirm?shopId=" + this.state.shopId + srcParam + this.state.paramShashoku, [isSiteParam]);
				return <Redirect to={link_confirm_menu_price} />
			} else {
				window.history.pushState(null, null, addParamUrl('/invite?id=' + this.state.menuId + srcParam + this.state.paramShashoku, [isSiteParam]));
				var link_confirm = addParamUrl("/invite-confirm?id=" + this.state.menuId + srcParam + this.state.paramShashoku, [isSiteParam]);
				return <Redirect to={link_confirm} />
			}
		}

		const isNoPayment2 = this.props.menuInfo?.noPayment === 2;
		const isGochiCharge = this.props.menuInfo?.menuChildType === 5;
		const chargeAmountZero = this.props.menuInfo?.chargeAmount === 0;

		if (isNoPayment2 || (isGochiCharge && chargeAmountZero)) {
			// Must set 'selectPayment' because component select payment will not render in case noPayment = 2
			sessionStorage.setItem('selectPayment', constants.PAYMENT_TYPE_SELF_CHECKOUT);
		}

		if (this.props.menuInfo?.menuChildType === 3) {
			sessionStorage.removeItem('messageCard');
		}

		// const paypaySupport = (this.props.shopInfo 
		// 	&& this.props.shopInfo.paymentTyptes 
		// 	&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_PAYPAY)
		// 	&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_PAYPAY).status === 1) || this.props.menuInfo.groupId 
		// 	? true : false;
		
        return (
        	<div className="content">
                <Header 
                    // title={(this.props.shopInfo.shopName || (this.props.groupInfo ? this.props.groupInfo.groupName : ''))} 
					title={this.state.shopId ? t('pages.invite.Buy a menu price') : t('pages.login.inviteWithMenu')}
					t={t} isLoggedIn={isLoggedIn}>
				</Header>
			    {/* <div className="heading-page">
			      	<div className="heading-img text-center">
			        	<div className="heading-ttl ex-ttl-gochi">{t('pages.invite.Order this menu')}</div>
			      	</div> 
			    </div> */}

			    <div className="input-infor">
					<div className="mt-3">
						<ul className="progressbar align-center">
							<li className="step-choice"><strong>{t('steps.step_choice')}</strong></li>
							<li className="step-select active"><strong>{t('steps.step_select')}</strong></li>
							<li className="step-confirm"><strong>{t('steps.step_confirm')}</strong></li>
							<li className="step-complete"><strong>{t('steps.step_complete')}</strong></li>
						</ul>
					</div>
			    	<form onSubmit={this.handleSaveInvite}>
				    	<DishInfor />

				    	<InviteQuantity
							quantityError={this.state.quantityError}
							inputPriceErr={this.state.inputPriceErr}
							feeRateErr={this.state.feeRateErr}
						/>
						
						{this.props.menuInfo.taxType !== 'DONATION' && !(this.props.menuInfo.menuChildType === 3 && isNoPayment2 && isGochiCharge) && !this.state.isMogugori && !isCampaign && !isMenuEventHiddenGochiUserType && (
				    	<>
							{/* <div className="divider-dashed"></div> */}
							<SelectFriends
								selectFriendError={this.state.selectFriendError}
								messageError={this.state.messageError}
								situationError={this.state.situationError}
								messageCardError={this.state.messageCardError}
							/>
						</>
						)}
						{userInfo.isGuest &&
							<GuestInfo 
                                customerEmailError={this.state.customerEmailError} 
                                customerNameError={this.state.customerNameError} 
                                userName={(this.props.guestInfo && this.props.guestInfo.customerName) || this.state.userName}
                                email={(this.props.guestInfo && this.props.guestInfo.customerEmail) || this.state.email}
                                isShashoku={this.state.paramShashoku ? true : false}
                            />
						}

                        {this.props.menuInfo.menuChildType !== 3 && this.props.menuInfo.menuChildType !== 4 && this.props.menuInfo.menuChildType !== 5 && ( 
                            <>
                                {/* <div className="divider-dashed"></div> */}
                                <MessageShop messageShopError={this.state.messageShopError} isMogugori={this.state.isMogugori}/>
                            </>
                        )}

						{/* {paypaySupport &&
							<a 
								href="https://paypay.ne.jp/event/matsuri202107-paypay-jumbo-20210725/" 
								target="_blank" 
								rel="noopener noreferrer"
								className="ml-auto banner-paypay"
							>
								<img src={require("../../../assets/img/icons/7PJ_fin_640x240.png")} alt="register" width="100%" />
							</a>
						} */}

						{!isNoPayment2 && !(isGochiCharge && chargeAmountZero) && <>
							{!userInfo.isGuest ? (
								<div className="content-dark">
								{!isGochiCharge && (
									<div className="d-flex justify-content-between fs-18 clr-000000 align-items-end" style={{borderBottom: "1px solid #000000", margin: "0 20px 20px"}}>
										<div className="mr-2" style={{lineHeight: "36px"}}>{t('pages.invite.Bill amount')}</div>
										<div className="fs-30 font-weight-bold">
											{Object.entries(this.props.menuInfo).length > 0 ? 
												this.props.menuInfo.updatedMenuInfo ? this.props.menuInfo.updatedMenuInfo && this.props.menuInfo.updatedMenuInfo.totalAmount.toLocaleString() : 
													(Object.entries(this.props.menuInfo).length !== 0 && this.props.menuInfo.initTotalAmount ? this.props.menuInfo.initTotalAmount.toLocaleString() : this.props.menuInfo.chargeAmount.toLocaleString())
												: 
												this.props.shopInfo.updatedMenuPriceInfo ? this.props.shopInfo.updatedMenuPriceInfo.totalAmount.toLocaleString() : 
													(this.props.shopInfo.initTotalAmount ? this.props.shopInfo.initTotalAmount.toLocaleString() : Math.round(this.state.inputPrice * (1 + this.props.shopInfo.taxRate)).toLocaleString())
											}
											<span className="fs-16">円</span>
										</div>
									</div>
								)}
								{/*<PaypayAd className="logo-paypay-ad" />*/}
								{this.props.menuInfo?.noPayment && this.props.menuInfo?.noPayment > 0
									? <NoPayment methodType={this.props.menuInfo?.noPayment} />
									: <Payment selectPaymentError={this.state.selectPaymentError} enable3dSecure />
								}
								</div>
							) : (
								this.props.shopInfo && (
									<div className="content-dark">
										<div className="d-flex justify-content-between fs-18 clr-000000 align-items-end" style={{borderBottom: "1px solid #000000", margin: "0 20px 20px"}}>
											<div className="mr-2" style={{lineHeight: "36px"}}>{t('pages.invite.Bill amount')}</div>
											<div className="fs-30 font-weight-bold">
												{Object.entries(this.props.menuInfo).length > 0 ? 
													this.props.menuInfo.updatedMenuInfo ? this.props.menuInfo.updatedMenuInfo.totalAmount.toLocaleString() : 
														(Object.entries(this.props.menuInfo).length !== 0 && this.props.menuInfo.initTotalAmount ? this.props.menuInfo.initTotalAmount.toLocaleString() : this.props.menuInfo.chargeAmount.toLocaleString())
													: 
													this.props.shopInfo.updatedMenuPriceInfo ? this.props.shopInfo.updatedMenuPriceInfo.totalAmount.toLocaleString() : 
														(this.props.shopInfo.initTotalAmount ? this.props.shopInfo.initTotalAmount.toLocaleString() : Math.round(this.state.inputPrice * (1 + this.props.shopInfo.taxRate)).toLocaleString())
												}
												<span className="fs-16">円</span>
											</div>
										</div>
										{/*<PaypayAd className="logo-paypay-ad" />*/}
										{this.props.menuInfo?.noPayment && this.props.menuInfo?.noPayment > 0
											? <NoPayment methodType={this.props.menuInfo?.noPayment} />
											: <PaymentGuest selectPaymentError={this.state.selectPaymentError} enable3dSecure />
										}
									</div>
								)
							)}
						</>}

				    	<div className="mt-4 text-center">
				    		<button type="submit" className="btn btn-green-38" >
				           		{t('pages.invite.To confirmation screen')}   
				          	</button>
				    	</div>
				    </form>
			    </div>
			</div>
        ); 
    }
}
 

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
	showError: (showing, title, content, buttonName, gotoUrl) =>dispatch(showError(showing, title, content, buttonName, gotoUrl)),
	selectFriend: (selectedFriendId, friendType, friendInfo, gochiUserType) => dispatch(selectFriend(selectedFriendId, friendType, friendInfo, gochiUserType)),
	saveInitTotalAmount: (totalAmount) => dispatch(saveInitTotalAmount(totalAmount)),
	saveInitTotalAmountMenuPrice: (totalAmount) => dispatch(saveInitTotalAmountMenuPrice(totalAmount)),
	saveInitMenuPrice: (initMenuPrice) => dispatch(saveInitMenuPrice(initMenuPrice)),
	saveInitAmount: (valueInput) => dispatch(saveInitAmount(valueInput)),
	saveGuestInfo: (guestInfo) => dispatch(saveGuestInfo(guestInfo)),
	saveUserInfo: (isLogined, isGuest, userInfo) => dispatch(saveUserInfo(isLogined, isGuest, userInfo)),
	addMessageCard: (thankYouText, thankYouImage, giftCardId) => dispatch(addMessageCard(thankYouText, thankYouImage, giftCardId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InputInvite));
import React, { Component } from "react";
import {connect} from "react-redux";
import {Redirect} from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import 'firebase/auth'; 
import UserService from "../../../api/services/UserService";
import {showError} from "../../../actions/action_error_alert";  
import {showLoading} from "../../../actions/action_loading"; 
import {saveUserInfo} from "../../../actions/action_users";
import queryString from 'query-string';
import {addParamUrl} from '../../../utils';

//Login by cognito
import { Auth } from 'aws-amplify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'; 
import Header from "../../layout/header/Header";

// Amplify.configure({
//     Auth: {
//     	identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
//     	region: process.env.REACT_APP_REGION,
//     	userPoolId: process.env.REACT_APP_USER_POOL_ID,
//       userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
// 	}
// });

class LoginConfirm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirectTo: false,
			errorMessage: null,
      menuId: null,
      password: '',
      confirmCode: '',
      src: '',
      registerInput: '',
      isValid: false,
      showPassword: false,
      email: '',
      usernameError: "",
      passwordError: "",
      hasCodeError: "",
      username: "",
      isSignIn: false,
      isSite: ''
		};
		this.onConfirmCodeChange = this.onConfirmCodeChange.bind(this); 
		this.submitConfirm = this.submitConfirm.bind(this); 
    this.onResendCode = this.onResendCode.bind(this); 
    this.onUsernameChange = this.onUsernameChange.bind(this);
    this.onShowHidePass = this.onShowHidePass.bind(this);
    this.valid = this.valid.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
	}  

	async submitConfirm(e){
    e.preventDefault();
    const validForm = this.valid();
    this.setState({
      isValid: validForm
    });
    console.log('validForm', validForm);

    if (validForm) {
      this.props.showLoading(true);
      try {
          if (!this.state.isSignIn){
            await Auth.confirmSignUp(this.state.registerInput, this.state.confirmCode);
          }

          const user = await Auth.signIn(
              this.state.registerInput,
              this.state.password,
          ); 

          const createUserResponse = await UserService.createUser(
              this.state.registerInput,
              this.state.username,
              user.getUsername(),
          );
          // get tokens from api create user
          if (createUserResponse.status === 200) {
            const { accessToken, refreshToken } = createUserResponse.data;
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
          }
          
          // Code get user info and save local storage, if redirect to confirm sms then remove this code
          const { data } = await UserService.getUserList({
              cognitoUserId: user.username,
              sort: "createdAtDesc",
              page: 1,
              limit: 1,
          });

          this.props.saveUserInfo(true, false, data.list[0]); 

          var userInfo = {
              isLogined: true,
              isGuest: false,
              userId: data.list[0].userId,
              accountId: data.list[0].accountId,
              displayName: data.list[0].displayName,
              email: data.list[0].email,
              userImage: data.list[0].userImage && data.list[0].userImage.length > 0 && data.list[0].userImage[0].image
          };

          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          // end code

          this.setState({
              redirectTo: true
          });

      } catch (error) {
        console.log(error);
        if (error.code === "CodeMismatchException") { 
            this.props.showError(true, this.props.t("pages.register.code mismatch exception"), '');
        } else if (error.code === "ExpiredCodeException") { 
            this.props.showError(true, this.props.t("pages.register.expired code exception"), '');
        } else if (error.code === "NotAuthorizedException") { 
          this.props.showError(true, this.props.t("pages.register.Incorrect username or password"), '');
          this.setState({
            isSignIn: true
          });
        } else { 
            this.props.showError(true, this.props.t("pages.register.user not confirmed exception"), '');
        } 
      }
    }
    this.props.showLoading(false);
  }

  valid() {
    this.setState({
      usernameError: "",
      passwordError: "",
      codeError: "",
    });
    let hasUsernameError = false;
    let hasPasswordError = false;
    let hasCodeError = false;
    const valueUsername = this.state.username.trim();
    if (valueUsername.length <= 0) {
      this.setState({
        usernameError: this.props.t("pages.register.message sure enter username")
      });
      hasUsernameError = true;
    } else if (valueUsername.length > 200) {
      this.setState({
        usernameError: this.props.t("pages.register.message username up to 200")
      });
      hasUsernameError = true;
    }

    const valueCode = this.state.confirmCode.trim();
    if (valueCode.length <= 0) {
      this.setState({
        codeError: this.props.t("pages.register.message please enter code")
      });
      hasCodeError = true;
    } else if (valueCode.length > 6) {
      this.setState({
        codeError: this.props.t("pages.register.message code within 6")
      });
      hasCodeError = true;
    }

    const valuePassword = this.state.password.trim();
    if (valuePassword.length <= 0) {
      this.setState({
        passwordError: this.props.t("pages.register.message please sure your password")
      });
      hasPasswordError = true;
    } else if (valuePassword.length < 8 || valuePassword.length > 99) {
      this.setState({
        passwordError: this.props.t("pages.register.message password 8 99")
      });
      hasPasswordError = true;
    } else if (
      !/[A-Z]/.test(valuePassword) ||
      !/[a-z]/.test(valuePassword) ||
      !/[0-9]/.test(valuePassword)
    ) {
      this.setState({
        passwordError: this.props.t("pages.register.message suggest password")
      });
      hasPasswordError = true;
    }
    
    if (hasUsernameError || hasPasswordError || hasCodeError) {
      return false
    }
    return true
  }

  onUsernameChange(e) {
      this.setState({username: e.target.value});
  }

  onShowHidePass(e){
		this.setState({
			showPassword: !this.state.showPassword
		})
  }

  async onResendCode(){
      try {
      await Auth.resendSignUp(this.state.registerInput);
      this.props.showError(true, this.props.t("pages.register.Resent the verification code"), '');
      } catch (error) {
        console.log(error);
        if (error.__type === "InvalidParameterException") { 
          this.props.showError(true, this.props.t("pages.register.user not confirmed exception signup"), '');
        } else { 
          this.props.showError(true, this.props.t("pages.register.has error"), '');
        }
      }
  }

	onConfirmCodeChange(e) {
		this.setState({confirmCode: e.target.value});
  }

  onPasswordChange(e) {
		this.setState({password: e.target.value});
  }

	UNSAFE_componentWillMount() {
		sessionStorage.removeItem("fullInviteContent");
		sessionStorage.removeItem("selectFriend");
		sessionStorage.removeItem("inviteContent");
		sessionStorage.removeItem("paymentMethod");
		sessionStorage.removeItem("menuInfo");
		sessionStorage.removeItem("comment");
		sessionStorage.removeItem("guestInfo");
		sessionStorage.removeItem("messageShop");
		var userInfo = this.props.userInfo;
		if (userInfo) {
			if(userInfo.isGuest === true) {
				localStorage.removeItem("userInfo");
				this.props.saveUserInfo(false, userInfo.isGuest, []);
			}
		}
	}

	componentDidMount() {
		let url = window.location.search;
		let params = queryString.parse(url);
    var menuId = params.id;
    var src = params.src;
    var isSite = params.isSite;
    var registerInput = params.registerInput;
    if (registerInput) {
			this.setState({
				registerInput: registerInput
			});
		}

		if (src) {
			this.setState({
				src: src
			});
		}

    if (isSite) {
			this.setState({
				isSite: isSite
			});
		}

		this.setState({
			menuId: menuId
		});
	}

	render() {
    const { t } = this.props; 
    document.body.className = "auth signup";
    var srcParam = this.state.src ? '&src=' + this.state.src : '';
    var isSiteParam = this.state.isSite ? {name: 'isSite', value: this.state.isSite} : {};

    var registerInputParam = this.state.registerInput ? '?registerInput=' + this.state.registerInput : '';

    const redirectTo = this.state.redirectTo;
    // var isRegister = sessionStorage.getItem('isRegister');
    let url = window.location.search; 
		let params = queryString.parse(url);
    var menuId = params.id;
    if (redirectTo === true) {
      window.history.pushState(null, null, addParamUrl('/signup/confirm' + registerInputParam, [isSiteParam]));
      let link;
      if (menuId) {
        link = addParamUrl("/invite?id=" + this.state.menuId + srcParam, [isSiteParam]);
      } else {
        link = addParamUrl("/mypage", [isSiteParam]);
      }
      return <Redirect to={link} />
    }  
    console.log('props', this.props);

      return (
        <div>
          <header className="global-login-header">
              <div className="logo-nav">
                  <h1 className="logo">
                    <span>
                      <a href={this.state.isSite ? '/' + this.state.isSite : '/'} target="_blank">
                        <img src="/assets/img/common/logo-login1.png" alt="さきめし ごちめし" loading="lazy" />
                      </a>
                    </span>
                    <span>
                      <a href="https://www.sakimeshi.com/" target="_blank">
                        <img src="/assets/img/common/logo-login2.png" alt="さきめし ごちめし" loading="lazy" />
                      </a>
                    </span>
                  </h1>
              </div>
          </header>

          <main>
            <div className="container">
                <div className="heading-lg">
                    <div className="title">
                        新規アカウント登録
                    </div>
                </div>

                {process.env.REACT_APP_MFA !== 'off' && (
                  <nav className="step-group signup-step-group">
                      <div className="step is-success">
                          <div className="step-btn">
                              <div className="step-outer-circle">
                                  <div className="step-inner-circle"></div>
                              </div>
                          </div>
                      </div>
                      <div className="step  is-success">
                          <div className="step-btn">
                              <div className="step-outer-circle">
                                  <div className="step-inner-circle"></div>
                              </div>
                          </div>
                      </div>
                      <div className="step is-disabled">
                          <div className="step-btn">
                              <div className="step-outer-circle">
                                  <div className="step-inner-circle"></div>
                              </div>
                          </div>
                      </div>
                      <div className="step is-disabled">
                          <div className="step-btn">
                              <div className="step-outer-circle">
                                  <div className="step-inner-circle"></div>
                              </div>
                          </div>
                      </div>
                  </nav>
                )}

                <form onSubmit={this.handleSubmitForm}>
                    <div className="signup-input">
                        <div className="signup-input-group">
                            <div className="signup-input-header">
                                <div className="heading-md">
                                    <div className="title">認証コードの入力</div>
                                </div>
                            </div>
                            <div className="signup-input-body">
                                <div className="text">
                                    メールアドレスに届いた認証コードをご入力ください。<br />
                                    認証コードが届くまでお時間がかかる場合がございます。
                                </div>
                                <div className="fieldset">
                                    <div className="fieldset-header">
                                        <div className="title">お名前</div>
                                        <em className="fieldset-mark">必須</em>
                                    </div>
                                    <div className="fieldset-body">
                                        <div className="field-group field-group-column">
                                            <div className="field field-fluid">
                                                <input className={"form-control " + (!this.state.isValid && this.state.usernameError ? 'input-error' : '')}
                                                  placeholder="例) ごち めし子"
                                                  type="text"
                                                  onChange={this.onUsernameChange}
                                                  value={this.state.username}
                                                  name="username"
                                                />
                                            </div>
                                            {!this.state.isValid && this.state.usernameError &&
                                              <div className="text-danger fs-14 mb-2 fs-12">{this.state.usernameError}</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="fieldset">
                                    <div className="fieldset-header">
                                        <div className="title">メールアドレス</div>
                                        <em className="fieldset-mark">必須</em>
                                    </div>
                                    <div className="fieldset-body">
                                        <div className="field-group field-group-column">
                                            <div className="field field-fluid">
                                                <input className={"form-control " + (!this.state.isValid && this.state.emailError ? 'input-error' : '')}
                                                  placeholder="例) mail@gochi.online"
                                                  type="text"
                                                  disabled
                                                  value={this.state.registerInput}
                                                  name="email"
                                                />
                                            </div>
                                          {!this.state.isValid && this.state.emailError &&
                                            <div className="text-danger fs-14 mb-2 fs-12">{this.state.emailError}</div>
                                          }
                                        </div>
                                    </div>
                                </div>
                                <div className="fieldset">
                                    <div className="fieldset-header">
                                        <div className="title">パスワード</div>
                                        <em className="fieldset-mark">必須</em>
                                    </div>
                                    <div className="fieldset-body">
                                        <div className="field-group field-group-column">
                                            <div className="field field-fluid field-password">
                                                <input
                                                  id="password"
                                                  type={this.state.showPassword ? "text" : "password"}
                                                  name="password"
                                                  placeholder={t('pages.register.placeholder password')}
                                                  className={"form-control " + (!this.state.isValid && this.state.passwordError ? 'input-error' : '')}
                                                  onChange={this.onPasswordChange}
                                                  defaultValue={this.state.password}
                                                />
                                                <button className="btn" type="button" onClick={this.onShowHidePass}>
                                                    <span className={this.state.showPassword ? "icon icon-eye open" : "icon icon-eye close"}></span>
                                                </button>
                                            </div>
                                            {!this.state.isValid && this.state.passwordError &&
                                              <div className="text-danger fs-14 mb-2 fs-12">{this.state.passwordError}</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="fieldset">
                                    <div className="fieldset-header">
                                        <div className="title">認証コードの入力</div>
                                        <em className="fieldset-mark">必須</em>
                                    </div>
                                    <div className="fieldset-body">
                                        <div className="field-group field-group-column">
                                            <div className="field field-fluid">
                                                <input className="form-control"
                                                  placeholder="認証コードの入力"
                                                  type="text"
                                                  onChange={this.onConfirmCodeChange}
                                                  value={this.state.confirmCode}
                                                  name="username"
                                                />
                                            </div>
                                            {this.state.codeError &&
                                              <div className="text-danger fs-14 mb-2 fs-12">{this.state.codeError}</div>
                                            }
                                            <div className="mt-20">
                                                <a className="link text-underline" onClick={this.onResendCode}>認証コードを再送する</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="signup-input-footer">
                            <div className="btn-group signup-input-btn-group">
                                {
                                  this.state.menuId ? (
                                    <a href={addParamUrl(`/login?id=` + this.state.menuId + srcParam, [isSiteParam])} className="btn btn-rect btn-cancel signup-input-btn"><div>戻る</div></a>
                                  ) : (
                                    <a href={addParamUrl(`/login`, [isSiteParam])} className="btn btn-rect btn-cancel signup-input-btn"><div>戻る</div></a>
                                  )
                                }
                                <button className="btn btn-rect signup-input-btn" onClick={this.submitConfirm}><div>次へ</div></button>
                            </div>
                            {this.state.errorMessage &&
                              <div className="text-danger mb-2 fs-14 font-weight-bold">{t('pages.register.'+this.state.errorMessage)}</div>
                            }
                        </div>
                    </div>
                </form>
            </div>
          </main>


          <footer className="global-footer">
              <section className="global-footer-nav-area">
                  <div className="container">
                      <div className="section-body footer-nav">
                          <ul className="footer-link-area">
                            <li><a href="https://gochimeshi.com/policy/">各種規約</a></li>
                            <li><a href="https://gochimeshi.com/policy/privacy/">プライバシーポリシー</a></li>
                            <li><a href="https://gochimeshi.com/policy/legal/">特定商取引法に基づく表記</a></li>
                            <li><a href="https://user-guide.gochimeshi.com/">よくある質問</a></li>
                            <li><a href="https://gochimeshi.com/info/">お問い合わせ</a></li>
                            <li><a href="https://www.gigi.tokyo/">運営会社（Gigi株式会社）</a></li>
                          </ul>
                          <ul className="footer-share-area">
                            <li>
                              <a href="https://twitter.com/gochi_meshi" rel="nofollow" target="_blank">
                                <img src="/assets/img/common/icon-twitter-white.svg" loading="lazy" alt="" />
                              </a>
                            </li>
                            <li>
                              <a href="https://www.facebook.com/gochimeshi.official/" rel="nofollow" target="_blank">
                                <img src="/assets/img/common/icon-facebook-white.svg" loading="lazy" alt="" />
                              </a>
                            </li>
                            <li>
                              <a href="https://www.instagram.com/gochimeshi_staff/?hl=ja">
                                <img src="/assets/img/common/icon-instagram-white.svg" loading="lazy" alt="" />
                              </a>
                            </li>
                          </ul>
                      </div>
                  </div>
              </section>

              <div className="copyright">
                  <small>
                    GOCHI®︎ ごちめし®︎ こどもごちめし® びずめし®︎ ︎ さきめし®︎ は、
                    <br />
                    Gigi株式会社の登録商標です
                    <br />
                    GOCHIプラットフォームの機能は、
                    <br />
                    特許取得済み（特許番号：特許第7572763号）
                    <br />
                    © Gigi Inc.
                  </small>
              </div>
          </footer>
        </div>
      );
    }
}

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({ 
  showError: (showing, title, content) =>dispatch(showError(showing, title, content)), 
  saveUserInfo: (isLogined, isGuest, userInfo) => dispatch(saveUserInfo(isLogined, isGuest, userInfo)),
  showLoading: (showing) => dispatch(showLoading(showing)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LoginConfirm));

import React, { useState } from "react";
import { showError } from "../../../actions/action_error_alert";
import { showLoading } from "../../../actions/action_loading";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import CampaignService from "../../../api/services/CampaignService";
import { HTTP_API } from "../../../api/HttpClient";

export function CheckActiveCode(props) {
  const { t } = props;
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [numberEmailError, setNumberEmailError] = useState(1);
  const currentDate = new Date().toISOString().split("T")[0];
  const currentMonth =
    currentDate.split("-")[0] + "-" + currentDate.split("-")[1];

  function validEmail() {
    let value = String(email).toLowerCase().trim();
    if (value === "") {
      setEmailError("必須項目です。");
      return setNumberEmailError(1);
    }
    if (
      !/^(("[\w+-\s]+")|([\w+-]+(?:\.[\w+-]+)*)|("[\w+-\s]+")([\w+-]+(?:\.[\w+-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(
        value
      )
    ) {
      setEmailError("メールアドレスの形式が正しくありません。");
      return setNumberEmailError(1);
    }
    setEmailError("");
    setEmail(value);
    return setNumberEmailError(0);
  }

  async function buyMenu(menuId, secretCode, campaignId) {
    if (numberEmailError !== 0) {
      return;
    }

    let data = await CampaignService.checkMenu(campaignId, secretCode, email, currentMonth, menuId);
    if (data.status === HTTP_API.SUCCESS) {
      sessionStorage.setItem("emailBookingCampaign", email);
      sessionStorage.setItem("secretCode", secretCode);
      sessionStorage.setItem("monthBookingCampaign", currentMonth);
      sessionStorage.setItem("campaignId", campaignId);
      window.location.href = `/menu?id=${menuId}`;
    } else {
      let messageErr;
      if (data.status === 400 && data.data.code === 'exception.errors.gochi.campaign.not.found') {
        messageErr = props.t('pages.bookingCamaign.campaign_not_found');
        props.showError(messageErr, "");
      } else if (data.status === 400 && data.data.code === 'exception.errors.gochi.campaign.total.limited') {
        messageErr = props.t('pages.bookingCamaign.campaign_total_limited');
        props.showError(messageErr, "");
      } else if (data.status === 400 && data.data.code === 'exception.errors.gochi.campaign.booking.duplicate') {
        messageErr = t('pages.bookingCamaign.campaign_booking_duplicate');
        props.showError(messageErr, "");
      } else if (data.status === 400 && data.data.code === 'exception.errors.gochi.campaign.params.invalid') {
        messageErr = t('pages.bookingCamaign.campaign_params_invalid');
        props.showError(messageErr, "");
      } else if (data.status === 400 && data.data.code === 'exception.errors.gochi.campaign.activecode.invalid') {
        messageErr = t('pages.bookingCamaign.campaign_activecode_invalid');
        props.showError(messageErr, "");
      } else if (data.status === 400 && data.data.code === 'exception.errors.gochi.campaign.booking.not.found') {
        messageErr = t('pages.bookingCamaign.campaign_booking_not_found');
        props.showError(messageErr, "");
      } else {
        props.showError(t('other.Unexpected error'), "");
      }
    }
  }

  return (
    <>
      <div className="header">
        <div
          className={props.usingFlow ? "header-row below-line" : "header-row"}
        >
          <div className="d-flex w-100">
            <div className="d-flex justify-content-center align-items-center">
              <i className="icon-service" />
            </div>
            <div className="header-ttl pr-5 fs-12 ml-2 clr-000000">
              {props.shop.shopName}
            </div>
          </div>
        </div>
      </div>
      <main>
        <div className="input-infor">
          <div className="dish-infor mt-3">
            <div className="required-dot mb-3">
                <div className="dot-red"></div>
                <div className="pl-2 fs-18">{t("pages.bookingCamaign.email_title")}</div>
            </div>
            <input
              className="form-control"
              placeholder={t("pages.bookingCamaign.place_holder_email")}
              type="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              name="customerEmail"
              onBlur={validEmail}
            />

            <div className="text-validate-email">
              {emailError && (
                <div className="text-danger fs-14 mb-2 fs-12 box-warning-message">
                  {emailError}
                </div>
              )}
            </div>
          </div>
          {props.menus &&
            (props.menus.length !== 0 ? (
              props.menus.map((item, index) => (
                <>
                  <div key={item.menuId}>
                    <div className={index === 0 ? "mt-2" : "mt-4"}>
                      <div className="dish-infor mb-2">
                        <div className="media">
                          <div className="dish-image">
                            <figure className="mb-0 dish-fig">
                              <img
                                src={
                                  item.menuImage
                                    ? item.menuImage[0].image
                                    : require("../../../assets/img/icons/gochi_noimg-ver4.jpg")
                                }
                                alt={item.menuName}
                                width="100%"
                              />
                            </figure>
                          </div>
                          <div className="media-body dish-body pl-2">
                            <div className="mt-0 dish-link fs-18">
                              {item.menuName}
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        className={`btn btn-green-38 ${
                          email ? "" : "disabled-btn"
                        }`}
                        onClick={() =>
                          buyMenu(
                            item.menuId,
                            props.secretCode,
                            props.campaignId
                          )
                        }
                      >
                        {t("pages.bookingCamaign.button_buy_menu")}
                      </button>
                    </div>
                  </div>
                  {props.menus.length > 1 && (
                    <div className="line-horizontal mt-4"></div>
                  )}
                </>
              ))
            ) : (
              <div className="mt-4 text-center">
                <label className="ttl-label">{t("pages.bookingCamaign.no_list_menu")}</label>
              </div>
            ))}
        </div>
      </main>
    </>
  );
}

const mapStateToProps = () => ({
  showLoading,
  showError,
});

const mapDispatchToProps = (dispatch) => ({
  showError: (title, content) => dispatch(showError(true, title, content)),
  showLoading: (showing) => dispatch(showLoading(showing)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CheckActiveCode));

import React, { Component } from "react";    
import {connect} from 'react-redux';
import {addMessageShop} from "../../../actions/action_message_shop";
import { withTranslation } from 'react-i18next';
import { PlaceHolderMessageShop } from "./PlaceHolderMessageShop";

class MessageShop extends Component { 
	constructor(props) { 
	  	super(props); 
	  	this.state = {
			messageText: '',
			publicMessage: 0,
      arrayPlaceHolderMessageShop: [],
	  	};

	  	this.handlePublicMessage = this.handlePublicMessage.bind(this); 
	}

	handlePublicMessage = (e) => {   
        let checked = 0;
        if (parseInt(e.target.value) !== 0) {
            checked = 0;
        } else {
            checked = 1;
        }
		this.setState({
			publicMessage: checked 
        });
        this.props.addMessageShop(this.state.messageText || this.props.messageShop.messageText, checked);
        var messageShop = {};
        messageShop = {
            messageText: this.state.messageText || this.props.messageShop.messageText,
            publicMessage: checked
        }
		sessionStorage.setItem('messageShop', JSON.stringify(messageShop));
	};

	handleChange = (e) => {
        this.setState({messageText: e.target.value});
        this.props.addMessageShop(e.target.value, this.state.publicMessage || this.props.messageShop.publicMessage);
        var messageShop = {};
        messageShop = {
            messageText: e.target.value,
            publicMessage: this.state.publicMessage || this.props.messageShop.publicMessage
        }
		sessionStorage.setItem('messageShop', JSON.stringify(messageShop));
	};

	componentDidMount(){
    const { t } = this.props;
    if (this.props.isMogugori === true) {
      this.setState({
        arrayPlaceHolderMessageShop: [t('pages.invite.messageShopMogugoriPlaceholder1'), t('pages.invite.messageShopMogugoriPlaceholder2'), t('pages.invite.messageShopMogugoriPlaceholder3')]
      })
    } else {
      this.setState({
        arrayPlaceHolderMessageShop: [t('pages.invite.messageShopPlaceholder1'), t('pages.invite.messageShopPlaceholder2')]
      })
    }
	}

    render() {
		const { t } = this.props;
        return (
        	<div className="dish-infor mt-5">   

			    <label className="ttl-label fs-18 clr-000000">
					{this.props.isMogugori ? t('pages.invite.publicMessageMogugori') : t('pages.invite.publicMessage')}
                    {/* <span className="fs-12 font-weight-normal">{t('pages.invite.Optional')}</span> */}
				</label>
                <div className="line-horizontal label-ln mb-2"></div>

          {!this.props.isMogugori && (
            <div className="box-option">
                <div className="d-flex">
                    <div className="">
                        <label className="checkbox-custom fs-14">
                            <input type="checkbox" name="publicMessage" value={ parseInt(this.props.messageShop.publicMessage) || 0 }
                                onChange={this.handlePublicMessage}
                                checked={this.publicMessage || this.props.messageShop.publicMessage}
                            />
                            <span className="checkmark"></span>
                            <span className="fs-13 font-weight-normal d-block">{t('pages.invite.publicMessageCheck')}</span>
                        </label>
                        <span className="fs-13 clr-ec4a56">{t('pages.invite.publicMessageNote')}</span>
                    </div>
                </div>
            </div>
          )}
 				
        <div className="textarea-placeholder">
          <textarea 
            className={this.props.messageShopError ? "form-control mt-1 textarea-message ex-form-required" : "form-control mt-1 textarea-message" } 
            //placeholder={this.props.isMogugori ? t('pages.invite.messageShopMogugoriPlaceholder') : t('pages.invite.messageShopPlaceholder')}  
            value={this.state.messageText || this.props.messageShop.messageText} 
            onChange={this.handleChange}
            name="messageShop"
          />
          {!(this.props.messageShop.messageText || this.state.messageText) && (
            <PlaceHolderMessageShop
              isShow={this.state.messageText.length <= 0}
              listPlaceHolder={this.state.arrayPlaceHolderMessageShop}
            />
          )}
        </div>

 				{this.props.messageShopError &&
	    			<div className="text-danger fs-14 mb-2 fs-12 box-warning-message">{this.props.messageShopError}</div>
	    		}
			</div>
        ); 
    }
} 

const mapStateToProps = state => ({
	...state
}); 
 
export default connect(mapStateToProps, {addMessageShop})(withTranslation()(MessageShop)); 

import React, { Component } from "react";
import {connect} from 'react-redux';
import * as constants from '../../../constants';

import { withTranslation } from 'react-i18next';
import {updateQuantityMenu} from "../../../actions/action_menu";
import {updateMenuPriceInfo} from "../../../actions/action_shop";
import {saveInitTotalAmount} from "../../../actions/action_menu";
import {saveInitTotalAmountMenuPrice} from "../../../actions/action_shop"; 

class InviteQuantity extends Component {
	constructor(props){
		super(props);
		this.state = {
			priceIncludedTax: 0,
			saleCommission: 0,
			totalAmount: 0,
			quantity: Object.entries(this.props.menuInfo).length > 0 ? (this.props.menuInfo.menuNCount && this.props.menuInfo.menuNCount.count ? this.props.menuInfo.menuNCount.count : 1) : (this.props.shopInfo.updatedMenuPriceInfo ? this.props.shopInfo.updatedMenuPriceInfo.quantity : 1),
			feeRate: 0,
			feeSystem: this.props.menuInfo.feeSystem ? this.props.menuInfo.feeSystem : 0,
			discountRate: 0,
			emptyInput: false,
			seeMorePriceDetail: false,
			taxAmountType: this.props.shopInfo.updatedMenuPriceInfo ? this.props.shopInfo.updatedMenuPriceInfo.currentTaxType : constants.TAX_AMOUNT_TYPE.TAX_INCLUDED_AMOUNT,
			inputPrice: this.props.shopInfo.updatedMenuPriceInfo && this.props.shopInfo.updatedMenuPriceInfo.inputedPrice !== null && this.props.shopInfo.updatedMenuPriceInfo.inputedPrice !== undefined && !isNaN(this.props.shopInfo.updatedMenuPriceInfo.inputedPrice) ? this.props.shopInfo.updatedMenuPriceInfo.inputedPrice : 100,
			feePaidByShop: this.props.menuInfo.feePaidByShop ? this.props.menuInfo.feePaidByShop : false,
			feeShopRate: this.props.menuInfo.feeShopRate ? this.props.menuInfo.feeShopRate : 0,
			feeShopAmount: 0,
		}
		this.handleCalculatePrice = this.handleCalculatePrice.bind(this);
		this.increment = this.increment.bind(this);
		this.decrement = this.decrement.bind(this);
		this.handleSeeMorePriceDetail = this.handleSeeMorePriceDetail.bind(this);
		this.handleInputedPrice = this.handleInputedPrice.bind(this);
	}

	increment(){
		let value = 0;
		if (Object.entries(this.props.menuInfo).length > 0) {
			value = this.props.menuInfo.updatedMenuInfo ? this.props.menuInfo.updatedMenuInfo.quantity : this.state.quantity;
		} else {
			value = this.props.shopInfo.updatedMenuPriceInfo ? this.props.shopInfo.updatedMenuPriceInfo.quantity : this.state.quantity;
		}
		
		value = value + 1;
		this.setState({
			quantity: value
		});
		if (Object.entries(this.props.menuInfo).length > 0) {
			this.handleCalculatePrice(value);
		} else {
			this.handleInputedPrice(this.state.inputPrice, this.state.taxAmountType, value);
		}
		
	}

	decrement(){
		let value = 0;
		if (Object.entries(this.props.menuInfo).length > 0) {
			value = this.props.menuInfo.updatedMenuInfo ? this.props.menuInfo.updatedMenuInfo.quantity : this.state.quantity;
		} else {
			value = this.props.shopInfo.updatedMenuPriceInfo ? this.props.shopInfo.updatedMenuPriceInfo.quantity : this.state.quantity;
		}

		value = value - 1;
		if (value > 0) {
			this.setState({
				quantity: value
			});
			if (Object.entries(this.props.menuInfo).length > 0) {
				this.handleCalculatePrice(value);
			} else {
				this.handleInputedPrice(this.state.inputPrice, this.state.taxAmountType, value);
			}
		}
	}

	handleSeeMorePriceDetail() {
		if (this.state.seeMorePriceDetail) {
			this.setState({
				seeMorePriceDetail: false
			});
		} else {
			this.setState({
				seeMorePriceDetail: true
			});
		}
	}

	handleInputedPrice(value, taxType, quantity) {
		// e.preventDefault();
		// var valueInput = e.target.value.replace(/[^\d]/,'');
		var valueInput = value;
		if(value === '') {
			valueInput = '';
		}
		if(value === 0) {
			valueInput = 0;
		}
		this.setState({
			inputPrice: valueInput
		});

		var updatedMenuPriceInfo = {};
		var tmpPriceIncludedTax = valueInput;
		if (taxType === constants.TAX_AMOUNT_TYPE.TAX_EXCLUDED_AMOUNT) {
			if (this.props.shopInfo.taxRate) {
				var taxRate = this.props.shopInfo.taxRate;
				tmpPriceIncludedTax = Math.round(valueInput * taxRate) + Math.round(valueInput);
			}
		}
		if(this.state.feePaidByShop && typeof this.state.feePaidByShop !== 'undefined'){
			updatedMenuPriceInfo = {
				amountWithTax: tmpPriceIncludedTax,
				priceIncludedTax: tmpPriceIncludedTax * quantity,
				saleCommission: this.props.shopInfo.feeRate ? Math.round(tmpPriceIncludedTax * this.props.shopInfo.feeRate) * quantity : 0,
				feeBurden: this.props.shopInfo.feeSystem ? Math.round(tmpPriceIncludedTax * this.props.shopInfo.feeSystem) * quantity : 0,
				discount: this.props.shopInfo.discountRate ? Math.round(tmpPriceIncludedTax * this.props.shopInfo.discountRate) * quantity : 0,
				quantity: quantity,
				totalAmount: (tmpPriceIncludedTax * quantity) + (this.props.shopInfo.feeRate ? Math.round(tmpPriceIncludedTax * this.props.shopInfo.feeRate) * quantity : 0 ) - (this.props.shopInfo.feeSystem ? Math.round(tmpPriceIncludedTax * this.props.shopInfo.feeSystem) * quantity : 0) - (this.props.shopInfo.discountRate ? Math.round(tmpPriceIncludedTax * this.props.shopInfo.discountRate) * quantity : 0),
				feeShopAmount: this.props.shopInfo.feeShopRate ? Math.round(tmpPriceIncludedTax * this.props.shopInfo.feeShopRate) * quantity : 0,
			};
		} else {
			updatedMenuPriceInfo = {
				amountWithTax: tmpPriceIncludedTax,
				priceIncludedTax: tmpPriceIncludedTax * quantity,
				saleCommission: this.props.shopInfo.feeRate ? Math.round(tmpPriceIncludedTax * this.props.shopInfo.feeRate) * quantity : 0,
				feeBurden: this.props.shopInfo.feeSystem ? Math.round(tmpPriceIncludedTax * this.props.shopInfo.feeSystem) * quantity : 0,
				discount: this.props.shopInfo.discountRate ? Math.round(tmpPriceIncludedTax * this.props.shopInfo.discountRate) * quantity : 0,
				quantity: quantity,
				totalAmount: (tmpPriceIncludedTax * quantity) + (this.props.shopInfo.feeRate ? Math.round(tmpPriceIncludedTax * this.props.shopInfo.feeRate) * quantity : 0 ) - (this.props.shopInfo.feeSystem ? Math.round(tmpPriceIncludedTax * this.props.shopInfo.feeSystem) * quantity : 0) - (this.props.shopInfo.discountRate ? Math.round(tmpPriceIncludedTax * this.props.shopInfo.discountRate) * quantity : 0)
			};
		}

		// if (this.props.shopInfo.shopFoodApproved === constants.SHOP_FOOD_APPROVED) {
		// 	updatedMenuPriceInfo = {
		// 		priceIncludedTax: tmpPriceIncludedTax * quantity,
		// 		saleCommission: this.props.shopInfo.feeRate ? Math.round(tmpPriceIncludedTax * this.props.shopInfo.feeRate) * quantity : 0,
		// 		feeBurden: this.props.shopInfo.feeSystem ? Math.round(tmpPriceIncludedTax * this.props.shopInfo.feeSystem) * quantity : 0,
		// 		discount: this.props.shopInfo.discountRate ? Math.round(tmpPriceIncludedTax * this.props.shopInfo.discountRate) * quantity : 0,
		// 		quantity: quantity,
		// 		totalAmount: (tmpPriceIncludedTax * quantity) + (this.props.shopInfo.feeRate ? Math.round(tmpPriceIncludedTax * this.props.shopInfo.feeRate) * quantity : 0 ) - (this.props.shopInfo.feeSystem ? Math.round(tmpPriceIncludedTax * this.props.shopInfo.feeSystem) * quantity : 0) - (this.props.shopInfo.discountRate ? Math.round(tmpPriceIncludedTax * this.props.shopInfo.discountRate) * quantity : 0)
		// 	};
		// } else {
		// 	updatedMenuPriceInfo = {
		// 		priceIncludedTax: Math.round(tmpPriceIncludedTax * quantity),
		// 		saleCommission: this.props.shopInfo.feeRate ? Math.round(tmpPriceIncludedTax * this.props.shopInfo.feeRate) * quantity : 0,
		// 		feeBurden: this.props.shopInfo.feeSystem ? Math.round(tmpPriceIncludedTax * this.props.shopInfo.feeSystem) * quantity : 0,
		// 		quantity: quantity,
		// 		totalAmount: (tmpPriceIncludedTax * quantity) + (this.props.shopInfo.feeRate ? Math.round(tmpPriceIncludedTax * this.props.shopInfo.feeRate) * quantity : 0 ) - (this.props.shopInfo.feeSystem ? Math.round(tmpPriceIncludedTax * this.props.shopInfo.feeSystem) * quantity : 0)
		// 	};
		// }
		updatedMenuPriceInfo.inputedPrice = valueInput;
		updatedMenuPriceInfo.currentTaxType = taxType;

		this.setState(updatedMenuPriceInfo);

        this.props.updateMenuPriceInfo(updatedMenuPriceInfo);

        sessionStorage.setItem('updatedMenuPriceInfo', JSON.stringify(updatedMenuPriceInfo));
	}

	handleCalculatePrice(v){
	    // e.preventDefault();   
	    // var valueInput = e.target.value.replace(/[^\d]/,'');;    
		var valueInput = v;
		var updatedMenuInfo = {};

		if(this.state.feePaidByShop && typeof this.state.feePaidByShop !== 'undefined'){		
			updatedMenuInfo = { 
				priceIncludedTax: valueInput * Math.round(this.props.menuInfo.includedTaxPrice),
				saleCommission: valueInput * Math.round(this.state.feeRate * this.props.menuInfo.includedTaxPrice), 
				feeBurden: this.state.feeSystem ? valueInput * Math.round(this.state.feeSystem * this.props.menuInfo.includedTaxPrice) : 0, 
				discount: valueInput * Math.round(this.state.discountRate * this.props.menuInfo.includedTaxPrice), 
				totalAmount: valueInput * Math.round(this.props.menuInfo.includedTaxPrice) + valueInput * Math.round(this.state.feeRate * this.props.menuInfo.includedTaxPrice) - valueInput * Math.round(this.state.feeSystem * this.props.menuInfo.includedTaxPrice) - valueInput * Math.round(this.state.discountRate * this.props.menuInfo.includedTaxPrice),
				quantity: valueInput,
				feeShopAmount: this.state.feeShopRate ? valueInput * Math.round(this.state.feeShopRate * this.props.menuInfo.includedTaxPrice) : 0, 
			};
		} else {
			updatedMenuInfo = { 
				priceIncludedTax: valueInput * Math.round(this.props.menuInfo.includedTaxPrice),
				saleCommission: valueInput * Math.round(this.state.feeRate * this.props.menuInfo.includedTaxPrice), 
				feeBurden: this.state.feeSystem ? valueInput * Math.round(this.state.feeSystem * this.props.menuInfo.includedTaxPrice) : 0, 
				discount: valueInput * Math.round(this.state.discountRate * this.props.menuInfo.includedTaxPrice), 
				totalAmount: valueInput * Math.round(this.props.menuInfo.includedTaxPrice) + valueInput * Math.round(this.state.feeRate * this.props.menuInfo.includedTaxPrice) - valueInput * Math.round(this.state.feeSystem * this.props.menuInfo.includedTaxPrice) - valueInput * Math.round(this.state.discountRate * this.props.menuInfo.includedTaxPrice),
				quantity: valueInput
			};
		}

		// if (this.props.shopInfo.shopFoodApproved === 1) {
		// 	updatedMenuInfo = { 
		// 		priceIncludedTax: valueInput * Math.round(this.props.menuInfo.includedTaxPrice),
		// 		saleCommission: valueInput * Math.round(this.state.feeRate * this.props.menuInfo.includedTaxPrice), 
		// 		feeBurden: this.state.feeSystem ? valueInput * Math.round(this.state.feeSystem * this.props.menuInfo.includedTaxPrice) : 0, 
		// 		discount: valueInput * Math.round(this.state.discountRate * this.props.menuInfo.includedTaxPrice), 
		// 		totalAmount: valueInput * Math.round(this.props.menuInfo.includedTaxPrice) + valueInput * Math.round(this.state.feeRate * this.props.menuInfo.includedTaxPrice) - valueInput * Math.round(this.state.feeSystem * this.props.menuInfo.includedTaxPrice) - valueInput * Math.round(this.state.discountRate * this.props.menuInfo.includedTaxPrice),
		// 		quantity: valueInput
		// 	};
		// } else {
		// 	updatedMenuInfo = { 
		// 		priceIncludedTax: valueInput * Math.round(this.props.menuInfo.includedTaxPrice),
		// 		saleCommission: valueInput * Math.round(this.state.feeRate * this.props.menuInfo.includedTaxPrice), 
		// 		totalAmount: valueInput * Math.round(this.props.menuInfo.includedTaxPrice) + valueInput * Math.round(this.state.feeRate * this.props.menuInfo.includedTaxPrice) - valueInput * Math.round(this.state.feeSystem * this.props.menuInfo.includedTaxPrice),
		// 		quantity: valueInput,
		// 		feeBurden: this.state.feeSystem ? valueInput * Math.round(this.state.feeSystem * this.props.menuInfo.includedTaxPrice) : 0,
		// 	};
		// }

		this.setState(updatedMenuInfo);

        this.props.updateQuantityMenu(updatedMenuInfo); 

        sessionStorage.setItem('updatedMenuInfo', JSON.stringify(updatedMenuInfo));

        // if (e.target.value === "") {
        // 	this.setState({
        // 		emptyInput: true
        // 	})
        // }else{
        // 	this.setState({
        // 		emptyInput: false
        // 	})
        // }
	}

	async UNSAFE_componentWillReceiveProps(nextProps){
		if(Object.entries(nextProps.menuInfo).length > 0) {
			console.log('----Tinh toan menu thuong A----');
			const feeRate = nextProps.menuInfo.feeRate || 0;
			const feeSystem = nextProps.menuInfo.feeSystem;
			const discountRate = nextProps.menuInfo.discountRate;
			const feeShopRate = nextProps.menuInfo.feeShopRate;
			const feePaidByShop = nextProps.menuInfo.feePaidByShop;

			var feeSystemNum = !isNaN(parseFloat(feeSystem)) ?  feeSystem : 0;
			var discountNum = !isNaN(parseFloat(discountRate)) ?  discountRate : 0;
			const feeShopNum = !isNaN(parseFloat(feeShopRate)) ?  feeShopRate : 0;

			this.setState({
				feeRate: feeRate || 0,
				feeSystem: feeSystemNum,
				discountRate: discountNum,
				saleCommission: this.state.quantity * Math.round(feeRate * nextProps.menuInfo.includedTaxPrice),
				feeBurden: feeSystem ? this.state.quantity * Math.round(feeSystem * nextProps.menuInfo.includedTaxPrice) : 0,
				discount: this.state.quantity * Math.round(discountRate * nextProps.menuInfo.includedTaxPrice),
				priceIncludedTax: this.state.quantity * Math.round(nextProps.menuInfo.includedTaxPrice),
				totalAmount: this.state.quantity * Math.round(feeRate * nextProps.menuInfo.includedTaxPrice) + this.state.quantity * Math.round(nextProps.menuInfo.includedTaxPrice) - this.state.quantity * Math.round(feeSystemNum * nextProps.menuInfo.includedTaxPrice) - this.state.quantity * Math.round(discountNum * nextProps.menuInfo.includedTaxPrice),
				feeShopRate: feeShopNum,
				feePaidByShop: feePaidByShop,
				feeShopAmount: feeShopNum ? this.state.quantity * Math.round(feeShopNum * nextProps.menuInfo.includedTaxPrice) : 0,
			});
			// if (this.props.shopInfo.shopFoodApproved === 1) {
			// 	if (feeRate || feeSystem || discountRate) {
			// 		var feeSystemNum = !isNaN(parseFloat(feeSystem)) ?  feeSystem : 0;
			// 		var discountNum = !isNaN(parseFloat(discountRate)) ?  discountRate : 0;
			// 		this.setState({
			// 			feeRate: feeRate || 0,
			// 			feeSystem: feeSystemNum,
			// 			discountRate: discountNum,
			// 			saleCommission: this.state.quantity * Math.round(feeRate * nextProps.menuInfo.includedTaxPrice),
			// 			feeBurden: feeSystem ? this.state.quantity * Math.round(feeSystem * nextProps.menuInfo.includedTaxPrice) : 0,
			// 			discount: this.state.quantity * Math.round(discountRate * nextProps.menuInfo.includedTaxPrice),
			// 			priceIncludedTax: this.state.quantity * Math.round(nextProps.menuInfo.includedTaxPrice),
			// 			totalAmount: this.state.quantity * Math.round(feeRate * nextProps.menuInfo.includedTaxPrice) + this.state.quantity * Math.round(nextProps.menuInfo.includedTaxPrice) - this.state.quantity * Math.round(feeSystemNum * nextProps.menuInfo.includedTaxPrice) - this.state.quantity * Math.round(discountNum * nextProps.menuInfo.includedTaxPrice)
			// 		});
			// 	}
			// } else {
			// 	if (feeRate) {
			// 		feeSystemNum = !isNaN(parseFloat(feeSystem)) ?  feeSystem : 0;
			// 		this.setState({
			// 			feeRate: feeRate,
			// 			feeSystem: feeSystemNum,
			// 			saleCommission: this.state.quantity * Math.round(feeRate * nextProps.menuInfo.includedTaxPrice),
			// 			priceIncludedTax: this.state.quantity * Math.round(nextProps.menuInfo.includedTaxPrice),
			// 			totalAmount: this.state.quantity * Math.round(feeRate * nextProps.menuInfo.includedTaxPrice) + this.state.quantity * Math.round(nextProps.menuInfo.includedTaxPrice) - this.state.quantity * Math.round(feeSystemNum * nextProps.menuInfo.includedTaxPrice),
			// 			feeBurden: feeSystem ? this.state.quantity * Math.round(feeSystem * nextProps.menuInfo.includedTaxPrice) : 0,
			// 		})
			// 	}
			// }
		}
		// Menu PRICE
		else {
			console.log('----Tinh toan menu Price A----');
			const feeRate = nextProps.shopInfo.feeRate || 0;
			const feeSystem = nextProps.shopInfo.feeSystem;
			const discountRate = nextProps.shopInfo.discountRate;
			const feeShopRate = nextProps.shopInfo.feeShopRate;
			const feePaidByShop = nextProps.shopInfo.feePaidByShop;

			const feeShopNum = !isNaN(parseFloat(feeShopRate)) ?  feeShopRate : 0;

			var tmpPriceIncludedTax = this.state.inputPrice;
			if (this.state.taxAmountType === constants.TAX_AMOUNT_TYPE.TAX_EXCLUDED_AMOUNT) {
				if (nextProps.shopInfo.taxRate) {
					var taxRate = nextProps.shopInfo.taxRate;
					tmpPriceIncludedTax = Math.round(this.state.inputPrice * taxRate) + Math.round(this.state.inputPrice);
				}
			}

			this.setState({
				feeRate: feeRate || 0,
				feeSystem: !isNaN(parseFloat(feeSystem)) ?  feeSystem : 0,
				discountRate: !isNaN(parseFloat(discountRate)) ?  discountRate : 0,
				priceIncludedTax: this.state.quantity * tmpPriceIncludedTax,
				saleCommission: feeRate?  Math.round(feeRate * tmpPriceIncludedTax) * this.state.quantity : 0,
				feeBurden: feeSystem ? Math.round(feeSystem * tmpPriceIncludedTax) * this.state.quantity: 0,
				discount: discountRate ? Math.round(discountRate * tmpPriceIncludedTax) * this.state.quantity : 0,
				totalAmount: (tmpPriceIncludedTax * this.state.quantity) + (feeRate ?  Math.round(feeRate * tmpPriceIncludedTax) * this.state.quantity : 0) - (feeSystem ? Math.round(feeSystem * tmpPriceIncludedTax) * this.state.quantity: 0) - (discountRate ? Math.round(discountRate * tmpPriceIncludedTax) * this.state.quantity : 0),
				feeShopRate: feeShopNum,
				feePaidByShop: feePaidByShop,
				feeShopAmount: feeShopNum ? Math.round(feeShopNum * tmpPriceIncludedTax) * this.state.quantity: 0,
			});

			// if (this.props.shopInfo.shopFoodApproved === constants.SHOP_FOOD_APPROVED) {
			// 	if (feeRate || feeSystem || discountRate) {
			// 		this.setState({
			// 			feeRate: feeRate || 0,
			// 			feeSystem: !isNaN(parseFloat(feeSystem)) ?  feeSystem : 0,
			// 			discountRate: !isNaN(parseFloat(discountRate)) ?  discountRate : 0,
			// 			priceIncludedTax: this.state.quantity * tmpPriceIncludedTax,
			// 			saleCommission: feeRate?  Math.round(feeRate * tmpPriceIncludedTax) * this.state.quantity : 0,
			// 			feeBurden: feeSystem ? Math.round(feeSystem * tmpPriceIncludedTax) * this.state.quantity: 0,
			// 			discount: discountRate ? Math.round(discountRate * tmpPriceIncludedTax) * this.state.quantity : 0,
			// 			totalAmount: (tmpPriceIncludedTax * this.state.quantity) + (feeRate ?  Math.round(feeRate * tmpPriceIncludedTax) * this.state.quantity : 0) - (feeSystem ? Math.round(feeSystem * tmpPriceIncludedTax) * this.state.quantity: 0) - (discountRate ? Math.round(discountRate * tmpPriceIncludedTax) * this.state.quantity : 0)
			// 		});
			// 	}
			// } else {
			// 	if(feeRate) {
			// 		this.setState({
			// 			feeRate: feeRate || 0,
			// 			feeSystem: !isNaN(parseFloat(feeSystem)) ?  feeSystem : 0,
			// 			priceIncludedTax: this.state.quantity * tmpPriceIncludedTax,
			// 			saleCommission: feeRate?  Math.round(feeRate * tmpPriceIncludedTax) * this.state.quantity : 0,
			// 			feeBurden: feeSystem ? Math.round(feeSystem * tmpPriceIncludedTax) * this.state.quantity: 0,
			// 			totalAmount: (tmpPriceIncludedTax * this.state.quantity) + (feeRate ?  Math.round(feeRate * tmpPriceIncludedTax) * this.state.quantity : 0) - (feeSystem ? Math.round(feeSystem * tmpPriceIncludedTax) * this.state.quantity: 0)
			// 		});
			// 	}
			// }
		}
    }

	UNSAFE_componentWillMount() {
		if (Object.entries(this.props.menuInfo).length > 0) {
			console.log('----Tinh toan menu thuong B----');
			const feeRate = this.props.menuInfo.feeRate || 0;
			const feeSystem = this.props.menuInfo.feeSystem;
			const discountRate = this.props.menuInfo.discountRate;
			const feeShopRate = this.props.menuInfo.feeShopRate;
			const feePaidByShop = this.props.menuInfo.feePaidByShop;
			
			var feeSystemNum = !isNaN(parseFloat(feeSystem)) ?  feeSystem : 0;
			var discountNum = !isNaN(parseFloat(discountRate)) ?  discountRate : 0;
			const feeShopNum = !isNaN(parseFloat(feeShopRate)) ?  feeShopRate : 0;
			this.setState({
				feeRate: feeRate || 0,
				feeSystem: feeSystemNum,
				discountRate: discountNum,
				saleCommission: Math.round(this.state.quantity * feeRate * this.props.menuInfo.includedTaxPrice),
				feeBurden: feeSystem ? Math.round(this.state.quantity * feeSystem * this.props.menuInfo.includedTaxPrice) : 0,
				discount: Math.round(this.state.quantity * discountRate * this.props.menuInfo.includedTaxPrice),
				priceIncludedTax: Math.round(this.state.quantity * this.props.menuInfo.includedTaxPrice),
				totalAmount: Math.round(this.state.quantity * feeRate * this.props.menuInfo.includedTaxPrice) + Math.round(this.state.quantity * this.props.menuInfo.includedTaxPrice) - Math.round(this.state.quantity * feeSystemNum * this.props.menuInfo.includedTaxPrice) - Math.round(this.state.quantity * discountNum * this.props.menuInfo.includedTaxPrice),
				feeShopRate: feeShopNum,
				feePaidByShop: feePaidByShop,
				feeShopAmount: feeShopNum ? Math.round(this.state.quantity * feeShopNum * this.props.menuInfo.includedTaxPrice) : 0,
			});

			// if (this.props.shopInfo.shopFoodApproved === 1) {
			// 	if (feeRate || feeSystem || discountRate) {
			// 		var feeSystemNum = !isNaN(parseFloat(feeSystem)) ?  feeSystem : 0;
			// 		var discountNum = !isNaN(parseFloat(discountRate)) ?  discountRate : 0;
			// 		this.setState({
			// 			feeRate: feeRate || 0,
			// 			feeSystem: feeSystemNum,
			// 			discountRate: discountNum,
			// 			saleCommission: Math.round(this.state.quantity * feeRate * this.props.menuInfo.includedTaxPrice),
			// 			feeBurden: feeSystem ? Math.round(this.state.quantity * feeSystem * this.props.menuInfo.includedTaxPrice) : 0,
			// 			discount: Math.round(this.state.quantity * discountRate * this.props.menuInfo.includedTaxPrice),
			// 			priceIncludedTax: Math.round(this.state.quantity * this.props.menuInfo.includedTaxPrice),
			// 			totalAmount: Math.round(this.state.quantity * feeRate * this.props.menuInfo.includedTaxPrice) + Math.round(this.state.quantity * this.props.menuInfo.includedTaxPrice) - Math.round(this.state.quantity * feeSystemNum * this.props.menuInfo.includedTaxPrice) - Math.round(this.state.quantity * discountNum * this.props.menuInfo.includedTaxPrice)
			// 		});
			// 	}	
			// } else {
			// 	if (feeRate) {
			// 		feeSystemNum = !isNaN(parseFloat(feeSystem)) ?  feeSystem : 0;
			// 		this.setState({
			// 			feeRate: feeRate,
			// 			feeSystem: feeSystemNum,
			// 			saleCommission: Math.round(this.state.quantity * feeRate * this.props.menuInfo.includedTaxPrice),
			// 			priceIncludedTax: Math.round(this.state.quantity * this.props.menuInfo.includedTaxPrice),
			// 			totalAmount: Math.round(this.state.quantity * feeRate * this.props.menuInfo.includedTaxPrice) + Math.round(this.state.quantity * this.props.menuInfo.includedTaxPrice) - Math.round(this.state.quantity * feeSystemNum * this.props.menuInfo.includedTaxPrice),
			// 			feeBurden: feeSystem ? Math.round(this.state.quantity * feeSystem * this.props.menuInfo.includedTaxPrice) : 0,
			// 		})
			// 	}
			// }
			this.props.saveInitTotalAmount(Math.round(this.state.quantity * feeRate * this.props.menuInfo.includedTaxPrice) + Math.round(this.state.quantity * this.props.menuInfo.includedTaxPrice) - Math.round(this.state.quantity * feeSystemNum * this.props.menuInfo.includedTaxPrice) - Math.round(this.state.quantity * discountNum * this.props.menuInfo.includedTaxPrice));
		}
		// Menu PRICE
		else {
			console.log('----Tinh toan menu Price B----');
			const feeRate = this.props.shopInfo.feeRate || 0;
			const feeSystem = this.props.shopInfo.feeSystem;
			const discountRate = this.props.shopInfo.discountRate;
			const feeShopRate = this.props.menuInfo.feeShopRate;
			const feePaidByShop = this.props.menuInfo.feePaidByShop;

			var tmpPriceIncludedTax = this.state.inputPrice;
			if (this.state.taxAmountType === constants.TAX_AMOUNT_TYPE.TAX_EXCLUDED_AMOUNT) {
				if (this.props.shopInfo.taxRate) {
					var taxRate = this.props.shopInfo.taxRate;
					tmpPriceIncludedTax = Math.round(this.state.inputPrice * taxRate) + Math.round(this.state.inputPrice);
				}
			}

			this.setState({
				feeRate: feeRate || 0,
				feeSystem: !isNaN(parseFloat(feeSystem)) ?  feeSystem : 0,
				discountRate: !isNaN(parseFloat(discountRate)) ?  discountRate : 0,
				priceIncludedTax: this.state.quantity * tmpPriceIncludedTax,
				saleCommission: feeRate?  Math.round(feeRate * tmpPriceIncludedTax) * this.state.quantity : 0,
				feeBurden: feeSystem ? Math.round(feeSystem * tmpPriceIncludedTax) * this.state.quantity: 0,
				discount: discountRate ? Math.round(discountRate * tmpPriceIncludedTax) * this.state.quantity : 0,
				totalAmount: (tmpPriceIncludedTax * this.state.quantity) + (feeRate ?  Math.round(feeRate * tmpPriceIncludedTax) * this.state.quantity : 0) - (feeSystem ? Math.round(feeSystem * tmpPriceIncludedTax) * this.state.quantity: 0) - (discountRate ? Math.round(discountRate * tmpPriceIncludedTax) * this.state.quantity : 0),
				feeShopRate: feeShopRate,
				feePaidByShop: feePaidByShop,
				feeShopAmount: feeShopRate ? Math.round(this.state.quantity * feeShopRate * this.props.menuInfo.includedTaxPrice) : 0,
			});

			// if (this.props.shopInfo.shopFoodApproved === constants.SHOP_FOOD_APPROVED) {
			// 	if (feeRate || feeSystem || discountRate) {
			// 		this.setState({
			// 			feeRate: feeRate || 0,
			// 			feeSystem: !isNaN(parseFloat(feeSystem)) ?  feeSystem : 0,
			// 			discountRate: !isNaN(parseFloat(discountRate)) ?  discountRate : 0,
			// 			priceIncludedTax: this.state.quantity * tmpPriceIncludedTax,
			// 			saleCommission: feeRate?  Math.round(feeRate * tmpPriceIncludedTax) * this.state.quantity : 0,
			// 			feeBurden: feeSystem ? Math.round(feeSystem * tmpPriceIncludedTax) * this.state.quantity: 0,
			// 			discount: discountRate ? Math.round(discountRate * tmpPriceIncludedTax) * this.state.quantity : 0,
			// 			totalAmount: (tmpPriceIncludedTax * this.state.quantity) + (feeRate ?  Math.round(feeRate * tmpPriceIncludedTax) * this.state.quantity : 0) - (feeSystem ? Math.round(feeSystem * tmpPriceIncludedTax) * this.state.quantity: 0) - (discountRate ? Math.round(discountRate * tmpPriceIncludedTax) * this.state.quantity : 0)
			// 		});
			// 	}	
			// } else {
			// 	if (feeRate) {
			// 		this.setState({
			// 			feeRate: feeRate,
			// 			feeSystem: !isNaN(parseFloat(feeSystem)) ?  feeSystem : 0,
			// 			priceIncludedTax: this.state.quantity * tmpPriceIncludedTax,
			// 			saleCommission: feeRate?  Math.round(feeRate * tmpPriceIncludedTax) * this.state.quantity : 0,
			// 			feeBurden: feeSystem ? Math.round(feeSystem * tmpPriceIncludedTax) * this.state.quantity: 0,
			// 			totalAmount: (tmpPriceIncludedTax * this.state.quantity) + (feeRate ?  Math.round(feeRate * tmpPriceIncludedTax) * this.state.quantity : 0) - (feeSystem ? Math.round(feeSystem * tmpPriceIncludedTax) * this.state.quantity: 0)
			// 		});
			// 	}
			// }
			this.props.saveInitTotalAmountMenuPrice((tmpPriceIncludedTax * this.state.quantity) + (feeRate ?  Math.round(feeRate * tmpPriceIncludedTax) * this.state.quantity : 0) - (feeSystem ? Math.round(feeSystem * tmpPriceIncludedTax) * this.state.quantity: 0) - (discountRate ? Math.round(discountRate * tmpPriceIncludedTax) * this.state.quantity : 0));
		}
	}

	render() {
		const { t } = this.props;
		var totalAmount = 0;
		var includedTaxMenuPrice = 0;
		if(Object.entries(this.props.menuInfo).length > 0) {
			let feeSystemNum = !isNaN(parseFloat(this.props.menuInfo.feeSystem)) ?  this.props.menuInfo.feeSystem : 0;
			let feeRate = !isNaN(parseFloat(this.props.menuInfo.feeRate)) ?  this.props.menuInfo.feeRate : 0;
			let quantity = this.props.menuInfo.menuNCount && this.props.menuInfo.menuNCount.count ? this.props.menuInfo.menuNCount.count : 1;
			let includedTaxPrice = quantity * this.props.menuInfo.includedTaxPrice;
			let saleComission = quantity * Math.round(feeRate * this.props.menuInfo.includedTaxPrice);
			let gigiComission = quantity * Math.round(feeSystemNum * this.props.menuInfo.includedTaxPrice);
			let discountComission = this.props.menuInfo.discountRate
													? quantity * Math.round(this.props.menuInfo.discountRate * this.props.menuInfo.includedTaxPrice)
													: 0;
			// let discountComission = (this.props.shopInfo && this.props.shopInfo.shopFoodApproved === constants.SHOP_FOOD_APPROVED)
			// 										? Math.round(quantity * this.props.menuInfo.discountRate * this.props.menuInfo.includedTaxPrice)
			// 										: 0
			// ;

			totalAmount = includedTaxPrice + saleComission - gigiComission - discountComission;
		} else {
			var tmpPriceIncludedTax = this.state.inputPrice;
			if(this.state.taxAmountType === constants.TAX_AMOUNT_TYPE.TAX_EXCLUDED_AMOUNT) {
				if(this.props.shopInfo.taxRate) {
					var taxRate = this.props.shopInfo.taxRate;
					tmpPriceIncludedTax = Math.round(this.state.inputPrice * taxRate) + Math.round(this.state.inputPrice);
				}
			}
			let feeSystemNum = !isNaN(parseFloat(this.props.shopInfo.feeSystem)) ?  this.props.shopInfo.feeSystem : 0;
			let feeRate = !isNaN(parseFloat(this.props.shopInfo.feeRate)) ?  this.props.shopInfo.feeRate : 0;
			let discountRate = !isNaN(parseFloat(this.props.shopInfo.discountRate)) ?  this.props.shopInfo.discountRate : 0;
			let quantity = 1;
			let includedTaxPrice = quantity * tmpPriceIncludedTax;
			let saleComission = quantity * Math.round(feeRate * tmpPriceIncludedTax);
			let gigiComission = quantity * Math.round(feeSystemNum * tmpPriceIncludedTax);
			let discountComission = quantity * Math.round(discountRate * tmpPriceIncludedTax);
			// let discountComission = (this.props.shopInfo && this.props.shopInfo.shopFoodApproved === constants.SHOP_FOOD_APPROVED)
			// 										? quantity * Math.round(discountRate * tmpPriceIncludedTax)
			// 										: 0
			// ;
			totalAmount = includedTaxPrice + saleComission - gigiComission - discountComission;
			includedTaxMenuPrice = includedTaxPrice;
		}

		var initTotalAmount = totalAmount.toString();
		var initIncludedTaxMenuPrice = includedTaxMenuPrice.toLocaleString();

		var shopInfoTaxRate = this.props.shopInfo && this.props.shopInfo.taxRate ? this.props.shopInfo.taxRate : 0; 

		const isFixedQuantity =
			this.props.menuInfo?.menuCombo === 1 ||
			this.props.menuInfo?.menuLimitTotal === 1 ||
			this.props.menuInfo?.submenus?.length ||
			this.props.menuInfo?.menuChildType === 5;

		const isNoPayment2 = this.props.menuInfo?.noPayment === 2;

		return (
			<div className="dish-infor mt-4">
				{this.props.menuInfo &&
				<>
					{Object.entries(this.props.menuInfo).length > 0 ? (
						<>
							<div className="media" hidden={this.props.menuInfo.menuCombo === 1}>
								<div className="media-left">
									<div className="required-dot">
										<div className="dot-red"></div>
										<div className="dot-text-label fs-18">{t('pages.invite.quantityLabel')}</div>
									</div>
								</div>
								<div className="form-group d-inline-flex ml-auto justify-content-end">
									<button 
										type="button" 
										className="btn btn-outlined-light btn-quantity-change" 
										onClick={this.increment} 
										hidden={isFixedQuantity}>
											+
									</button>
									<input 
										className="col-form-label border-0 text-center w-80 bgclr-ffffff mr-1 ml-1" 
										readOnly={isFixedQuantity}
										// disabled
										type="number"
										// min={1}
										name="quantity" 
										value={this.props.menuInfo.updatedMenuInfo ? this.props.menuInfo.updatedMenuInfo.quantity : this.state.quantity}
										onChange={e => {
											let value = e.target.value;
											if (!value || value === '') {
												this.setState({quantity: 0});
												this.handleCalculatePrice(0);
											} else {
												this.setState({quantity: parseInt(value)});
												this.handleCalculatePrice(parseInt(value));
											}
										}}
									></input>
									<button 
										type="button" 
										className="btn btn-outlined-light btn-quantity-change" 
										onClick={this.decrement} 
										hidden={isFixedQuantity}>
											-
									</button>
									{/* <input className={(this.state.emptyInput || this.props.quantityError) ? "form-control ex-form-required fs-18 text-right" : "form-control fs-18 text-right" }
											placeholder={t('pages.invite.quantityPlaceholder')} 
											type="number"
											min='1' 
											onChange={this.handleCalculatePrice} 
											value={this.props.menuInfo.updatedMenuInfo ? this.props.menuInfo.updatedMenuInfo.quantity : this.state.quantity } 
											name="quantity"
											readOnly={this.props.menuInfo && 
														this.props.menuInfo.menuCombo === 1
													}
									/> */}

									{/* {this.props.quantityError &&
										<div className="text-danger fs-14 mb-2 fs-12">{this.props.quantityError}</div>
									} */}
								</div>
							</div>
							{this.props.quantityError &&
								<div className="text-danger fs-14 mb-2 fs-12 box-warning-message">{this.props.quantityError}</div>
							}
						</>
					) : (
						<div className="menu-price">
							<div className="required-dot">
								<div className="dot-red"></div>
								<div className="dot-text-label fs-18">利用する金額（税込）</div>
							</div>
							<div className="line-horizontal"></div>

							{/*<div className="tax-amount-options">
								<div className="box-option mt-3">
									<div className="d-flex">
										<div className="">
											<label className="radio-custom">
												<input type="radio" name="taxAmountType" value={constants.TAX_AMOUNT_TYPE.TAX_INCLUDED_AMOUNT}
													onChange = {(e) => {
														this.setState({
															taxAmountType: parseInt(e.target.value)
														});
														this.handleInputedPrice(this.state.inputPrice, parseInt(e.target.value), this.state.quantity);
													}}
													checked={this.state.taxAmountType === constants.TAX_AMOUNT_TYPE.TAX_INCLUDED_AMOUNT}
												/>
												<span className="checkmark"></span>
												<span>{t('pages.invite.Tax included amount')}</span>
											</label>
										</div>
									</div>
								</div>
								<div className="box-option mt-1">
									<div className="d-flex">
										<div className="">
											<label className="radio-custom">
												<input type="radio" name="taxAmountType" value={constants.TAX_AMOUNT_TYPE.TAX_EXCLUDED_AMOUNT}
													onChange = {(e) => {
														this.setState({
															taxAmountType: parseInt(e.target.value)
														});
														this.handleInputedPrice(this.state.inputPrice, parseInt(e.target.value), this.state.quantity);
													}}
													checked={this.state.taxAmountType === constants.TAX_AMOUNT_TYPE.TAX_EXCLUDED_AMOUNT}
												/>
												<span className="checkmark"></span>
												<span>{t('pages.invite.Tax excluded amount')}</span>
											</label>
										</div>
									</div>
								</div>
							</div>*/}

							<div className="input-menu-price-new">
								<div className="mt-3 input-menu-price-container">
									<div style={{ width: "100%"}}>
										<input
											className={(this.state.emptyInput || this.props.inputPriceErr) ? "input-menu-price form-control ex-form-required text-right" : "input-menu-price form-control text-right"}
											style={{ backgroundColor: "#ffffff"}}
											placeholder="100円"
											type="number"
											// min={50}
											onChange={(e) => {
												this.handleInputedPrice(parseInt(e.target.value), this.state.taxAmountType, this.state.quantity);
											}}
											value={this.state.inputPrice}
											name="inputPrice"
											inputMode="numeric"
										/>
									</div>
									<div className="unit-txt fs-18">
										<span>{t('pages.invite.Yen Unit')}</span>
									</div>
								</div>
								{this.props.inputPriceErr &&
									<div className="text-danger fs-14 mb-2 fs-12 box-warning-message">{this.props.inputPriceErr}</div>
								}
								<div className="my-3 input-quantity-container">
									<div className="required-dot">
										<div className="dot-red"></div>
										<div className="dot-text-label fs-18">個数</div>
									</div>
									<div className="d-inline-flex ml-auto justify-content-end">
										<input 
											className={`col-form-label text-right input-quantity`}
											type="number"
											name="quantity" 
											value={this.props.shopInfo.updatedMenuPriceInfo ? this.props.shopInfo.updatedMenuPriceInfo.quantity : this.state.quantity}
											onChange={e => {
												let value = e.target.value;
												if (!value || value === '') {
													this.setState({quantity: 0});
													this.handleInputedPrice(this.state.inputPrice, this.state.taxAmountType, 0);
												} else {
													this.setState({quantity: parseInt(value)});
													this.handleInputedPrice(this.state.inputPrice, this.state.taxAmountType, parseInt(value));
												}
											}}
											inputMode="numeric"
											min="1"
										></input>
									</div>
								</div>
								{this.props.quantityError &&
									<div className="text-danger fs-14 mb-2 fs-12 box-warning-message mb-3">{this.props.quantityError}</div>
								}
							</div>

							{/*<div className="media mt-3" style={{padding: 10}}>
								<div style={{width: "80px"}}>
									<input
										className={(this.state.emptyInput || this.props.inputPriceErr) ? "form-control ex-form-required fs-14 text-left font-weight-bold" : "form-control fs-14 text-left font-weight-bold"}
										style={{width: 80, height: 35, backgroundColor: "#ffffff"}}
										placeholder="100円"
										type="number"
										// min={50}
										onChange={(e) => {
											this.handleInputedPrice(parseInt(e.target.value), this.state.taxAmountType, this.state.quantity);
										}}
										value={this.state.inputPrice}
										name="inputPrice"
									/>
								</div>
								<div className="unit-txt">
									<span>{t('pages.invite.Yen Unit')}</span>
									<span className="ml-4">×</span>
								</div>
								<div className="form-group d-inline-flex ml-auto justify-content-end">
									<button type="button" className="btn btn-outlined-light btn-quantity-change" onClick={this.increment}>+</button>
									<input 
										className={`col-form-label border-0 text-center w-60`} 
										// readOnly 
										// disabled
										// min={1}
										type="number"
										name="quantity" 
										value={this.props.shopInfo.updatedMenuPriceInfo ? this.props.shopInfo.updatedMenuPriceInfo.quantity : this.state.quantity}
										onChange={e => {
											let value = e.target.value;
											if (!value || value === '') {
												this.setState({quantity: 0});
												this.handleInputedPrice(this.state.inputPrice, this.state.taxAmountType, 0);
											} else {
												this.setState({quantity: parseInt(value)});
												this.handleInputedPrice(this.state.inputPrice, this.state.taxAmountType, parseInt(value));
											}
										}}
									></input>
									<button type="button" className="btn btn-outlined-light btn-quantity-change" onClick={this.decrement}>-</button>
									<span className="unit-txt">{t('pages.invite.pieces')}</span>
								</div>
							</div>
							<div style={{padding: 10}}>
								{this.props.inputPriceErr &&
									<div className="text-danger fs-14 mb-2 fs-12 box-warning-message">{this.props.inputPriceErr}</div>
								}
								{this.props.quantityError &&
									<div className="text-danger fs-14 mb-2 fs-12 box-warning-message">{this.props.quantityError}</div>
								}
							</div>*/}

						</div>
					)}

					{this.props.menuInfo.subscription === 1 && (
						<div className="form-group mt-2 mb-2">
						<div className="d-flex justify-content-between">
							<span className="text-dark font-weight-bold fs-18">{t('pages.invite.subscription interval')}</span>
							<span className="text-dark font-weight-bold fs-18">{`${t('pages.invite.per')}${this.props.menuInfo.subscriptionIntervalCount}${
                      this.props.menuInfo.subscriptionInterval === 'WEEK' ? t('pages.invite.week') : t('pages.invite.month')
                    }${t('pages.invite.one time')}`}</span>
						</div></div>
					)}
					
					<div className="line-horizontal" hidden={this.props.menuInfo && this.props.menuInfo.menuCombo === 1}></div>
					<div className="form-group mt-2 mb-2">
						<div className="d-flex justify-content-between fs-18 font-weight-bold clr-000000">
							<div className="mr-2">{isNoPayment2 ? '申込額' : t('pages.invite.Bill amount')}</div>
							<div className="fs-18">
								{Object.entries(this.props.menuInfo).length > 0 ? 
									(this.props.menuInfo.updatedMenuInfo ? this.props.menuInfo.updatedMenuInfo.totalAmount.toLocaleString() : this.state.totalAmount.toLocaleString()) 
									: 
									(this.props.shopInfo.updatedMenuPriceInfo ? 
										(Number.isNaN(this.props.shopInfo.updatedMenuPriceInfo.totalAmount) ? '– ' : this.props.shopInfo.updatedMenuPriceInfo.totalAmount.toLocaleString()) 
										: 
										this.state.totalAmount.toLocaleString())
								}
								<span className="fs-12">{t('pages.invite.Yen Unit')}</span>
							</div>
							<input type="hidden" name="totalAmount" defaultValue={initTotalAmount}/>
							<input type="hidden" name="includedTaxMenuPrice" defaultValue={initIncludedTaxMenuPrice}/>
							<input type="hidden" name="initAmount" defaultValue={this.state.taxAmountType === constants.TAX_AMOUNT_TYPE.TAX_INCLUDED_AMOUNT ? Math.round(this.state.inputPrice/(1 + shopInfoTaxRate)) : this.state.inputPrice}/>
						</div>
						{this.props.feeRateErr &&
							<div className="text-danger fs-14 mb-2 fs-12 box-warning-message">{this.props.feeRateErr}</div>
						}
					</div>
					<div className="line-horizontal"></div>

					{this.state.seeMorePriceDetail && (
						<>
							<div className="form-group mt-2 mb-1">
								<div className="d-flex justify-content-between fs-16 font-weight-bold clr-000000">
									<div className="mr-2">{t('pages.invite.Subtotal')}</div>
									<div className="fs-17">
										{this.props.menuInfo.updatedMenuInfo ? this.props.menuInfo.updatedMenuInfo.priceIncludedTax.toLocaleString() : this.state.priceIncludedTax.toLocaleString()}
										<span className="fs-10">{t('pages.invite.Yen Unit')}</span>
									</div>
								</div>
							</div>
							<div className="form-group mt-2">
								<div className="d-flex justify-content-between fs-16 font-weight-bold clr-000000">
									<div className="mr-2">{t('pages.invite.Sales commission')}</div>
									<div className="fs-17">
										{this.props.menuInfo.updatedMenuInfo ? this.props.menuInfo.updatedMenuInfo.saleCommission.toLocaleString() : this.state.saleCommission.toLocaleString()}
										<span className="fs-10">{t('pages.invite.Yen Unit')}</span>
									</div>
								</div>
							</div>
							{this.state.feeSystem !== 0 && (!this.state.feePaidByShop || typeof this.state.feePaidByShop === 'undefined') && (
								<div className="form-group mt-2">
									<div className="d-flex justify-content-between fs-16 font-weight-bold clr-000000">
										<div className="mr-2">{t('pages.invite.Fee burden')}</div>
										<div className="fs-17">
											-{(this.props.menuInfo.updatedMenuInfo && this.props.menuInfo.updatedMenuInfo.feeBurden) ? this.props.menuInfo.updatedMenuInfo.feeBurden.toLocaleString() : (this.state.feeBurden ? this.state.feeBurden.toLocaleString() : 0) }
											<span className="fs-10">{t('pages.invite.Yen Unit')}</span>
										</div>
									</div>
								</div>
							)}
							{this.state.discountRate !== 0 && (
								<div className="form-group mt-2">
									<div className="d-flex justify-content-between fs-16 font-weight-bold clr-000000">
										<div className="mr-2">{t('pages.invite.discount')}</div>
										<div className="fs-17">
										-{(this.props.menuInfo.updatedMenuInfo && this.props.menuInfo.updatedMenuInfo.discount) ? this.props.menuInfo.updatedMenuInfo.discount.toLocaleString() : (this.state.discount ? this.state.discount.toLocaleString() : 0) }
											<span className="fs-10">{t('pages.invite.Yen Unit')}</span>
										</div>
									</div>
								</div>
							)}
							{this.state.feePaidByShop && typeof this.state.feePaidByShop !== 'undefined' && (
								<div className="form-group mt-2">
									<div className="d-flex justify-content-between fs-16 font-weight-bold clr-000000">
										<div className="mr-2 text-red">{t('pages.invite.feePaidByShop')}</div>
										<div className="fs-17 text-red">
										-{(this.props.menuInfo.updatedMenuInfo && this.props.menuInfo.updatedMenuInfo.feeShopAmount) ? this.props.menuInfo.updatedMenuInfo.feeShopAmount.toLocaleString() : (this.state.feeShopAmount ? this.state.feeShopAmount.toLocaleString() : 0) }<span className="fs-10">{t('pages.invite.Yen Unit')}</span>
										</div>
									</div>
								</div>
							)}
						</>
					)}

					{this.props.menuInfo && this.props.menuInfo.menuCombo === 1 && (
						<div className="text-red fs-13 font-weight-bold mt-2">
							{t('pages.invite.Only one coupon ticket or common coupon ticket can be purchased at one time')}
						</div>
					)}

					<div className="align-center mt-1">
						<button type="button" className="btn btn-see-price-detail" onClick={this.handleSeeMorePriceDetail}>
							{!this.state.seeMorePriceDetail ? t('pages.invite.See the price detail'): t('pages.invite.Close the price detail')}
						</button>
					</div>


					{/* <div className="media mt-2">
						<div className="media-left mr-3"></div>
						<div className="media-body">
							<div className="form-block mt-0">
								<div className="form-group">
									<div className="d-flex justify-content-between fs-16 font-weight-bold">
										<div className="mr-2">{this.props.menuInfo.taxType === 'DONATION' ? t('pages.invite.priceIncludedTaxDonate') : t('pages.invite.priceIncludedTax')}:</div>
										{t('pages.invite.Yen')}
										{this.props.menuInfo.updatedMenuInfo ? this.props.menuInfo.updatedMenuInfo.priceIncludedTax.toLocaleString() : this.state.priceIncludedTax.toLocaleString()}
									</div>
								</div>

								<div className="form-group">
									<div className="d-flex justify-content-between fs-16 font-weight-bold">
										<div className="mr-2 fs-16">{t('pages.invite.Sales commission')}:</div>
										{t('pages.invite.Yen')}
										{this.props.menuInfo.updatedMenuInfo ? this.props.menuInfo.updatedMenuInfo.saleCommission.toLocaleString() : this.state.saleCommission.toLocaleString()}
									</div>
								</div>

								{ this.state.feeSystem !== 0 &&
									<div className="form-group">
										<div className="d-flex justify-content-between fs-16 font-weight-bold">
											<div className="mr-2 fs-16">{t('pages.invite.Fee burden')}:</div>
											-{t('pages.invite.Yen')}
											{(this.props.menuInfo.updatedMenuInfo && this.props.menuInfo.updatedMenuInfo.feeBurden) ? this.props.menuInfo.updatedMenuInfo.feeBurden.toLocaleString() : (this.state.feeBurden ? this.state.feeBurden.toLocaleString() : 0) }
										</div>
									</div>
								}

								{this.state.discountRate !== 0 &&
									<div className="form-group">
										<div className="d-flex justify-content-between fs-16 font-weight-bold">
											<div className="mr-2 fs-16">{t('pages.invite.discount')}:</div>
											-{t('pages.invite.Yen')}
											{(this.props.menuInfo.updatedMenuInfo && this.props.menuInfo.updatedMenuInfo.discount) ? this.props.menuInfo.updatedMenuInfo.discount.toLocaleString() : (this.state.discount ? this.state.discount.toLocaleString() : 0) }
										</div>
									</div>
								}

								<div className="form-group">
									<div className="d-flex justify-content-between fs-16 font-weight-bold">
										<div className="mr-2">{t('pages.invite.Total amount')}:</div>
										{t('pages.invite.Yen')}
										{this.props.menuInfo.updatedMenuInfo ? this.props.menuInfo.updatedMenuInfo.totalAmount.toLocaleString() : this.state.totalAmount.toLocaleString()}

										<input type="hidden" name="totalAmount"
														defaultValue={initTotalAmount}
										/>
									</div>
								</div>

                                {this.props.menuInfo && this.props.menuInfo.menuCombo === 1 && (
                                    <div className="text-red fs-14 font-weight-bold">
                                        {t('pages.invite.Only one coupon ticket or common coupon ticket can be purchased at one time')}
                                    </div>
                                )}
							</div>
						</div>
					</div> */}
				</>
				}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
	updateQuantityMenu: (updatedMenuInfo) => dispatch(updateQuantityMenu(updatedMenuInfo)),
	updateMenuPriceInfo: (updatedMenuPriceInfo) => dispatch(updateMenuPriceInfo(updatedMenuPriceInfo)),
	saveInitTotalAmount: (totalAmount) => dispatch(saveInitTotalAmount(totalAmount)),
	saveInitTotalAmountMenuPrice: (totalAmount) => dispatch(saveInitTotalAmountMenuPrice(totalAmount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InviteQuantity)); 

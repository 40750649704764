import {HTTP_API, makeRequest} from "../HttpClient";

const API_BASE_URL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : '';

export default class GiftCardService {
  static async getListCardCategory(page, limit) {
    var url = `${API_BASE_URL}/giftcards/categories?page=${page}&limit=${limit}`;
    return makeRequest(HTTP_API.GET, url);
  }

  static async getListCards(cateId, page, limit) {
    var url = `${API_BASE_URL}/giftcards/${cateId}?page=${page}&limit=${limit}`;
    return makeRequest(HTTP_API.GET, url);
  }

  static async getDefaultCard() {
    var url = `${API_BASE_URL}/giftcards/default`;
    return makeRequest(HTTP_API.GET, url);
  }
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { log } from "../../../utils";
import * as constants from "../../../constants";
import queryString from 'query-string';
import { savePaymentMethod } from "../../../actions/action_payment";
import { showLoading } from "../../../actions/action_loading";
import { showError } from "../../../actions/action_error_alert";
import MenuService from "../../../api/services/MenuService";

class ChargeConfirmModal extends Component {
  constructor() {
    super();
    this.state = {
      headerTitle: "カード情報登録",
      redirectToReferrer: false,
      buttonDisabled: false,
      paymentType: "",
      redirectUrl: "",
      redirectUrlStripe: "",
      paramShashoku: "",
      src: "",
      seeMorePriceDetail: false,
      showListShopPopup: false,
      shopId: null,
      initMenuPrice: 100,
      crfsToken: "",
    };
    this.submitChargeRequest = this.submitChargeRequest.bind(this);
    this.startAtoneCheckout = this.startAtoneCheckout.bind(this);
    this.handleSeeMorePriceDetail = this.handleSeeMorePriceDetail.bind(this);
    this.generateRandomString = this.generateRandomString.bind(this);
  }

  static contextTypes = {
    router: () => true, // replace with PropTypes.object if you use them
  };

  handleSeeMorePriceDetail() {
    if (this.state.seeMorePriceDetail) {
      this.setState({
        seeMorePriceDetail: false,
      });
    } else {
      this.setState({
        seeMorePriceDetail: true,
      });
    }
  }

  async startAtoneCheckout(atoneJson, redirectUrl) {
    console.log("Atone JSON: " + JSON.stringify(atoneJson));
    const options = {
      payment: atoneJson,
      succeeded: function (response) {
        console.log("response succeeded " + JSON.stringify(response));
        const successUrl = redirectUrl + `&checkoutId=${response.id}`;
        this.setState({ redirectUrl: successUrl });
        window.location.href = successUrl;
      }.bind(this),
    };
    if (localStorage.getItem("atoneToken")) {
      options.pre_token = localStorage.getItem("atoneToken");
    }
    if (window.Atone) {
      window.Atone.merge(options);
      window.Atone.sync();
      // Sleep to wait sync done
      await new Promise((resolve) => setTimeout(resolve, 1000));
      window.Atone.start();
    } else if (!window.Atone && parseInt(this.props.selectPayment) === constants.PAYMENT_TYPE_ATONE) {
			this.props.showError(true, "※広告ブロック等の理由により、atone決済が表示されません。atone決済をご利用ご希望の方は、ご利用のブラウザを変更してお試しください。", "");
		}
  }

  UNSAFE_componentWillMount() {
    //
    this.generateRandomString();

    // initialize Atone
    if (window.Atone) {
      window.Atone.config({
        pub_key: process.env.REACT_APP_ATONE_PUB_KEY,
        payment: {},
        // This is called upon finishing the authentication or user registration.
        authenticated: function (authenticationToken, userNo) {
          console.log("authentication token " + authenticationToken);
          console.log("user no " + userNo);
          localStorage.setItem("atoneToken", authenticationToken);
        },
        // Call upon closing the modal.
        cancelled: function () {
          console.log("cancelled");
        },
        // Call after pressing the button to close the form after the payment has returned as NG.
        failed: function (response) {
          console.log("response failed " + response);
        },
        // Call after the form has been automatically closed when the payment has returned as OK.
        succeeded: function (response) {
          console.log("response succeeded " + JSON.stringify(response));
        },
        // Call when an error occurs.
        error: function (name, message, errors) {
          console.log("response error name " + name);
          console.log("response error message " + message);
          console.log("response error errors " + JSON.stringify(errors));
        },
      });
    } else if (!window.Atone && parseInt(this.props.selectPayment) === constants.PAYMENT_TYPE_ATONE) {
			this.props.showError(true, "※広告ブロック等の理由により、atone決済が表示されません。atone決済をご利用ご希望の方は、ご利用のブラウザを変更してお試しください。", "");
		}
  }

  submitChargeRequest(e) {
    e.preventDefault();
    console.log("chargeInfo", this.props.chargeInfo);
    console.log(
      "this.props.chargeInfo.paymentMethod",
      this.props.chargeInfo.paymentMethod
    );
    const url = window.location.href;
    const arr = url.split("/");
    const domain = arr[0] + "//" + arr[2];
    var chargeContent = {
      gochiUserType: constants.GOCHI_TYPE_YOURSELF,
      gochiPlatformType: constants.GOCHI_PLATFORM_WEB,
      domain: domain.replace(/http:\/\/|https:\/\//g, ""),
      menuId: this.props.chargeInfo.menuId,
      gochiRegisterId: this.props.userInfo.userId
        ? this.props.userInfo.userId
        : null,
      paymentSource:
        (!this.props.chargeInfo.paymentType ||
          parseInt(this.props.chargeInfo.paymentType) ===
            constants.PAYMENT_TYPE_STRIPE ||
          parseInt(this.props.chargeInfo.paymentType) ===
            constants.PAYMENT_TYPE_VERITRANS) &&
        this.props.chargeInfo.paymentMethod
          ? this.props.chargeInfo.paymentMethod.token
          : "",
      paymentCapture: "CAPTURE",
      userId: this.props.userInfo.userId,
      paymentType:
        this.props.chargeInfo.paymentType || constants.PAYMENT_TYPE_CREDIT_CARD,
      premiumAmount: this.props.chargeInfo.premiumAmount,
      gochiBillId: this.props.chargeInfo.gochiBillId,
    };
    this.onCreateInvite(chargeContent);
  }

  generateRandomString(length = 32) {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    this.setState({
      crfsToken: text,
    });
    console.log("crfs token", text);
    return text;
  }

  async onCreateInvite(chargeContent) {
    const { t } = this.props;
    this.props.showLoading(true);
    this.setState({ buttonDisabled: true });
    let windowReference;
    let titleNotification = t("pages.gochiCharge.title_charge_notification");
    try {
      if (chargeContent) {
        let result = null;
        this.setState({
          redirectUrl: "",
        });
        this.setState({
          redirectUrlStripe: "",
        });
        // create invite with payment Credit Card (Stripe/Veritrans)
        if (
          parseInt(this.props.chargeInfo.paymentType) ===
          constants.PAYMENT_TYPE_VERITRANS
        ) {
          result = await MenuService.createCharge(
            chargeContent,
            this.state.crfsToken
          );

          log(result);
          if (result.status === 200) {
            let url = window.location.search;
            let params = queryString.parse(url);
            this.props.onHide();
            this.props.fetchDataGochiChargeDetail(params.menuId, params.gochiUserId, window.location.pathname.split('/').length > 0 && window.location.pathname.split('/')[3] ? window.location.pathname.split('/')[3] : '');
            if (result.data?.premiumAmount === (result.data?.amount + result.data?.taxAmount)) {
              this.props.openConfirmModal(
                titleNotification,
                t("pages.gochiCharge.charge_method_success", {
                  paymentType: "クレジットカード",
                  priceIncludedTax: (result.data?.amount + result.data?.taxAmount).toLocaleString()
                })
              );
            } else {
              this.props.openConfirmModal(
                titleNotification,
                t("pages.gochiCharge.charge_method_success_premium", {
                  paymentType: "クレジットカード",
                  totalAmount: (result.data?.amount + result.data?.taxAmount).toLocaleString(),
                  priceIncludedTax:result.data?.premiumAmount?.toLocaleString(),
                })
              );
            }
            // Not save data to props.menuInfo incase buy menu Price
            if (this.props.chargeInfo.menuId && !this.state.shopId) {
              //this.props.saveInvite(result.data);
            }

            var fullchargeContent = result.data;
            fullchargeContent.menuName = this.props.menuInfo.menuName;
            fullchargeContent.description = this.props.menuInfo.description;
            fullchargeContent.surveySubmissionId =
              this.state.surveySubmissionId;
            sessionStorage.setItem(
              "fullchargeContent",
              JSON.stringify(fullchargeContent)
            );
            sessionStorage.removeItem("messageCard");
            this.setState({
              redirectToReferrer: true,
            });
            this.props.showLoading(false);

            // set flag bookingCampaignFinish when gen gochi success
            const bookingCampaignFinish =
              sessionStorage.getItem("emailBookingCampaign") &&
              sessionStorage.getItem("secretCode") &&
              sessionStorage.getItem("monthBookingCampaign") &&
              sessionStorage.getItem("campaignId")
                ? 1
                : 0;
            sessionStorage.setItem(
              "bookingCampaignFinish",
              bookingCampaignFinish
            );
          } else if (result.status === 406) {
            this.generateRandomString();
            this.props.onHide();
            this.props.openErrorPopup(t("pages.invite.Error create gochi"));
            this.props.showLoading(false);
            return this.setState({
              buttonDisabled: false,
            });
          } else if (result.status === 402) {
            this.generateRandomString();
            let messageErr = t(
              "pages.invite.error create gochi using stripe incase other charging"
            );
            if (result.data) {
              if (result.data.stripe_code === "expired_card") {
                messageErr = t(
                  "pages.invite.error create gochi using stripe incase expired card"
                );
              } else if (result.data.stripe_code === "amount_too_small") {
                messageErr = t(
                  "pages.invite.error create gochi using stripe incase amount to small"
                );
              } else if (result.data.stripe_code === "insufficient_funds") {
                messageErr = t(
                  "pages.invite.error create gochi using stripe incase insufficient funds"
                );
              } else if (result.data.stripe_code === "card_velocity_exceeded") {
                messageErr = t(
                  "pages.invite.error create gochi using stripe incase card velocity exceeded"
                );
              }
              // subscription error
              else if (this.props.menuInfo.subscription === 1) {
                messageErr = t("pages.invite.error subsription failed");
              }
              // Veritrans errors
              else if (
                result.data.veritrans_error_code === "AG64000000000000"
              ) {
                messageErr = t(
                  "pages.invite.error create gochi using stripe incase expired card"
                );
              } else if (
                result.data.veritrans_error_code === "AG44000000000000"
              ) {
                messageErr = t(
                  "pages.invite.error create gochi using stripe incase insufficient funds"
                );
              } else if (
                result.data.veritrans_error_code === "AG45000000000000"
              ) {
                messageErr = t(
                  "pages.invite.error create gochi using stripe incase card velocity exceeded"
                );
              } else if (
                result.data.veritrans_error_code === "AG70000000000000" ||
                result.data.veritrans_error_code === "AG49000000000000" ||
                result.data.veritrans_error_code === "AG33000000000000"
              ) {
                messageErr = t(
                  "pages.invite.error create gochi using veritrans incase request denied"
                );
              }
              // other cases
              else {
                messageErr = t("pages.invite.error create gochi user");
              }
            }
            this.props.onHide();
            this.props.openErrorPopup(messageErr);
            this.props.showLoading(false);
            return this.setState({
              buttonDisabled: false,
            });
          } else if (
            result.status === 404 ||
            result.status === 400 ||
            result.status === 500
          ) {
            this.generateRandomString();
            this.props.onHide();
            this.props.openErrorPopup(t("pages.invite.error create gochi user"));
            this.props.showLoading(false);
            return this.setState({
              buttonDisabled: false,
            });
          } else if (result.status === 409) {
            this.generateRandomString();
            this.props.onHide();
            this.props.openErrorPopup(t("pages.invite.error create gochi menu hidden"));
            this.props.showLoading(false);
            return this.setState({
              buttonDisabled: false,
            });
          } else if (result.status === 504) {
            this.generateRandomString();
            this.props.onHide();
            this.props.openErrorPopup(t("pages.invite.error create gochi with user"));
            this.props.showLoading(false);
            return this.setState({
              buttonDisabled: false,
            });
          } else {
            this.generateRandomString();
            this.props.onHide();
            this.props.openErrorPopup(t("pages.invite.error create gochi user"));
            this.props.showLoading(false);
            return this.setState({
              buttonDisabled: false,
            });
          }
          // create invite with payment not Stripe
        } else {
          let chargeContentInfo = { ...chargeContent };
          if (
            parseInt(this.props.chargeInfo.paymentType) ===
              constants.PAYMENT_TYPE_ONEPAY_ALIPAY ||
            parseInt(this.props.chargeInfo.paymentType) ===
              constants.PAYMENT_TYPE_ONEPAY_LINEPAY ||
            parseInt(this.props.chargeInfo.paymentType) ===
              constants.PAYMENT_TYPE_ONEPAY_MERUPAY ||
            parseInt(this.props.chargeInfo.paymentType) ===
              constants.PAYMENT_TYPE_WECHATPAY
          ) {
            windowReference = window.open();
            chargeContentInfo = {
              ...chargeContent,
              requestCode: this.props.chargeInfo.menuId,
              gochiType: "MENU",
              couponCount: this.props.gochiData?.couponCount,
            };
          }
          result = await MenuService.createChargeSession(chargeContentInfo);
          log(result);
          if (result.status === 200) {
            var paypayResponse = result.data;
            paypayResponse.menuName = this.props.menuInfo.menuName;
            paypayResponse.description = this.props.menuInfo.description;
            sessionStorage.setItem(
              "paypayResponse",
              JSON.stringify(paypayResponse)
            );

            if (
              parseInt(this.props.chargeInfo.paymentType) ===
              constants.PAYMENT_TYPE_PAYPAY
            ) {
              this.props.showLoading(false);
              this.setState({
                redirectUrl: `/mypage/mygochi_charge_detail/${this.props.chargeInfo.menuId}?redirectFromPaypay=true&merchantPaymentId=${result.data.merchantPaymentId}`,
              });
              window.location.href = result.data.redirectUrl;
            } else if (
              parseInt(this.props.chargeInfo.paymentType) ===
              constants.PAYMENT_TYPE_ATONE
            ) {
              const redirectUrl = `/mypage/mygochi_charge_detail/${this.props.chargeInfo.gochiBillId}?menuId=${this.props.chargeInfo.menuId}&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserId=${this.props.chargeInfo.gochiUserId}`;
              await this.startAtoneCheckout(result.data.atoneJson, redirectUrl);
              this.setState({ buttonDisabled: false });
              this.props.onHide();
              this.props.showLoading(false);
            } else {
              this.props.showLoading(false);
              const redirectUrl = `/mypage/mygochi_charge_detail/${this.props.chargeInfo.gochiBillId}?menuId=${this.props.chargeInfo.menuId}&merchantPaymentId=${result.data.merchantPaymentId}&paymentGateway=onepay`;

              if (
                parseInt(this.props.chargeInfo.paymentType) ===
                  constants.PAYMENT_TYPE_ONEPAY_ALIPAY &&
                !isMobile
              ) {
                // Show QR code
                windowReference.location = `${
                  process.env.REACT_APP_DOMAIN_URL
                }/alipay-qr-code?value=${encodeURIComponent(
                  result.data.redirectUrl
                )}&ts=${Date.now()}`;
                window.location.href = redirectUrl;
              } else {
                windowReference.location = result.data.redirectUrl;
                setTimeout(() => {
                  try {
                    const refHostname = windowReference.location.hostname;
                    if (refHostname !== "mobile.alipay.com")
                      windowReference.close();
                  } catch (e) {}
                  window.location.href = redirectUrl;
                }, 5000);
              }
              // Payment type = WechatPay
              if (
                parseInt(this.props.chargeInfo.paymentType) ===
                constants.PAYMENT_TYPE_WECHATPAY
              ) {
                if (!isMobile) {
                  // Show QR code
                  windowReference.location = `${
                    process.env.REACT_APP_DOMAIN_URL
                  }/wechatpay-qr-code?value=${encodeURIComponent(
                    result.data.redirectUrl
                  )}&ts=${Date.now()}`;
                  window.location.href = redirectUrl;
                } else {
                  window.location.href = result.data.redirectUrl;
                }
              }
            }
            return null;
          } else if (result.status === 406) {
            this.props.onHide();
            this.props.openErrorPopup(t("pages.invite.Error create gochi"));
            this.props.showLoading(false);
            if (windowReference) {
              windowReference.close();
            }
            return this.setState({
              buttonDisabled: false,
            });
          } else if (result.status === 409) {
            this.props.onHide();
            this.props.openErrorPopup(t("pages.invite.error create gochi menu hidden"));
            this.props.showLoading(false);
            if (windowReference) {
              windowReference.close();
            }
            return this.setState({
              buttonDisabled: false,
            });
          } else if (
            result.status === 404 ||
            result.status === 400 ||
            result.status === 500
          ) {
            this.props.onHide();
            this.props.openErrorPopup(t("pages.invite.error create gochi user"));
            this.props.showLoading(false);
            if (windowReference) {
              windowReference.close();
            }
            return this.setState({
              buttonDisabled: false,
            });
          } else {
            this.props.onHide();
            this.props.openErrorPopup(t("pages.invite.error create gochi with guest"));
            this.props.showLoading(false);
            if (windowReference) {
              windowReference.close();
            }
            return this.setState({
              buttonDisabled: false,
            });
          }
        }
      } else {
        this.props.onHide();
        this.props.openErrorPopup("There is something wrong 1");
        this.props.showLoading(false);
        this.setState({
          buttonDisabled: false,
        });
      }
    } catch (error) {
      log(error);
      this.props.onHide();
      this.props.openErrorPopup("There is something wrong 2");
      this.props.showLoading(false);
      if (windowReference) {
        windowReference.close();
      }
      this.setState({
        buttonDisabled: false,
      });
    }
  }

  render() {
    var srcParam = this.state.src ? "&src=" + this.state.src : "";
    const refererParam = this.state.referer
      ? `&referer=${encodeURIComponent(this.state.referer)}`
      : "";
    srcParam += refererParam;

    const { t } = this.props;

    let paymentType;
    if (parseInt(this.props.selectPayment) === constants.PAYMENT_TYPE_CREDIT_CARD) {
        paymentType = "クレジットカード";
    } else if (parseInt(this.props.selectPayment) === constants.PAYMENT_TYPE_PAYPAY) {
        paymentType = "PayPay";
    } else if (parseInt(this.props.selectPayment) === constants.PAYMENT_TYPE_ONEPAY_ALIPAY) {
        paymentType = "Alipay";
    } else if (parseInt(this.props.selectPayment) === constants.PAYMENT_TYPE_ONEPAY_LINEPAY) {
        paymentType = "LINE Pay";
    } else if (parseInt(this.props.selectPayment) === constants.PAYMENT_TYPE_ONEPAY_MERUPAY) {
        paymentType = "Meru Pay";
    } else if (parseInt(this.props.selectPayment) === constants.PAYMENT_TYPE_WECHATPAY) {
        paymentType = "Wechat Pay";
    } else if (parseInt(this.props.selectPayment) === constants.PAYMENT_TYPE_ATONE) {
        paymentType = "Atone";
    }

    return (
      <div
        className={
          this.props.isShow
            ? "custom-modal-container show-modal"
            : "custom-modal-container"
        }
        size="sm"
      >
        <div
          className={
            this.props.isShow
              ? "modal modal-action modal-dialog is-show border-radius-4"
              : "modal modal-action modal-dialog border-radius-4"
          }
        >
          <div className="d-flex w-100 modal-header-charge">
            <div className="d-flex justify-content-center align-items-center">
              <i className="icon-service" />
            </div>
            <div className="header-ttl pr-5 fs-12 ml-2 clr-000000 font-weight-bold">
              {t("pages.gochiCharge.check_charge_amount")}
            </div>
          </div>
          <div className="modal-body modal-body-charge">
            <div className="pm-confirm-div">
              <span className="font-weight-bold modal-content-charge">
                {(this.props.chargeInforAmount?.priceIncludedTax * this.props.premiumRate) !== 0 ? (t("pages.gochiCharge.charge_method_comfirm_premium", {
                  paymentType: paymentType,
                  priceIncludedTax: this.props.chargeInforAmount?.priceIncludedTax?.toLocaleString(),
                  totalAmount: Math.round(this.props.chargeInforAmount?.priceIncludedTax + (this.props.chargeInforAmount?.priceIncludedTax * this.props.premiumRate)).toLocaleString(),
                })) : (t("pages.gochiCharge.charge_method_comfirm", {
                  paymentType: paymentType,
                  priceIncludedTax: this.props.chargeInforAmount?.priceIncludedTax?.toLocaleString(),
                }))}
              </span>
            </div>
            <div className="text-center btn-submit-box width-100">
              <button
                onClick={this.submitChargeRequest}
                className="btn btn-green-38 width-100 border-radius-4 fs-16"
                disabled={this.state.buttonDisabled}
              >
                {t("pages.gochiCharge.button_charge")}
              </button>
            </div>

            <div className="text-center btn-back">
              <span
                className="cursor-pointer fs-15 align-center font-weight-normal text-decoration-underline"
                onClick={this.props.onHide}
              >
                {t("pages.gochiCharge.button_close_modal_charge")}
              </span>
            </div>
          </div>
        </div>
        <div onClick={this.props.onHide} className="back-drop" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  savePaymentMethod: (paymentMethod) =>
    dispatch(savePaymentMethod(paymentMethod)),
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content) =>
    dispatch(showError(showing, title, content)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ChargeConfirmModal));

import React, { Component } from "react";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import * as constants from '../../../constants';
import {log, addParamUrl} from '../../../utils';
import { withTranslation } from 'react-i18next';
// import Message from './Message';
import ThankYouCard from './ThankYouCard';
import {commentInvite} from "../../../actions/action_comment";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {selectFriend} from "../../../actions/action_select_friend";
import queryString from 'query-string';
import GiftCardService from "../../../api/services/GiftCardService";
import {addMessageCard} from "../../../actions/action_message_card";
import {showLoading} from "../../../actions/action_loading";

class SelectFriend extends Component {
	constructor(props){
	    super(props);
        const isCampaign = this.props.menuInfo?.campaignId ? true : false;
      const isMenuEventHiddenGochiUserType = constants.MENU_EVENT_HIDDEN_GOCHI_USER_TYPE.includes(this.props.menuInfo?.menuEventId);
	    this.state = {
            selected: '1',
		    showComponent: false,
		    textShowHideButton: this.props.t('pages.invite.addCondition'),
		    buttonActive: false,
		    menuId: null,
		    selectedFriend: [],
		    situation: "",
		    gochiUserType: (isCampaign || isMenuEventHiddenGochiUserType) ? constants.GOCHI_TYPE_YOURSELF : (props.menuInfo.menuChildType !== 3 ? 
                (props.friend ? props.friend.gochiUserType : constants.GOCHI_TYPE_YOURSELF) : constants.GOCHI_TYPE_ANYONE),
		    buttonSelectActive: true,
			buttonAddActive: false,
			inputMessage: false,
			src: "",
            paramUsername: '',
            paramEmail: '',
            paramShashoku: '',
			shopId: null,
			isSite: ''
        }
		this.onSituationChange = this.onSituationChange.bind(this);
		this.handleSelectOutSysUser = this.handleSelectOutSysUser.bind(this);
		this.handleRemoveFriend = this.handleRemoveFriend.bind(this);
		this.getCardDefault = this.getCardDefault.bind(this);
	}

	getCardDefault() {
		this.props.showLoading(true);
		GiftCardService.getDefaultCard().then(response => {
			console.log('defaultCard', response);
			this.setState({
				thankYouImage: response.data.image,
				giftCardId: response.data.giftCardId,
				giftCardName: response.data.giftCardName
			});
			var messageCard = {};
			messageCard = {
				thankYouText: '',
				thankYouImage: response.data.image,
				giftCardId: response.data.giftCardId
			};
			this.props.showLoading(false);
			// sessionStorage.setItem('messageCard', JSON.stringify(messageCard));
			sessionStorage.setItem('giftCardDefault', JSON.stringify(messageCard));
			// this.props.addMessageCard(messageCard.thankYouText, messageCard.thankYouImage, messageCard.giftCardId);
		});
	}

	onSituationChange(e) {
	    this.setState({situation: e.target.value})
	    var friendInfo = {
			situation: e.target.value
		}

		var gochiUserType = this.state.gochiUserType;
	   	this.props.selectFriend(null, null, friendInfo, gochiUserType);
	   	var selectFriend = {
	   		selectedFriendId: null,
	   		friendType: null,
	   		friendInfo: friendInfo,
			gochiUserType: gochiUserType,
			situation: e.target.value
	   	}

	   	sessionStorage.setItem('selectFriend', JSON.stringify(selectFriend));
	}

	handleActiveButton = (e) => {
		sessionStorage.removeItem('messageCard');
    	this.setState({ buttonActive: !this.state.buttonActive });
    	var friendInfo = {
			situation: this.state.situation
		}
    	this.props.selectFriend(null, null, friendInfo, null);
    	var selectFriend = {};
    	selectFriend = {
	   		selectedFriendId: null,
	   		friendType: null,
	   		friendInfo: friendInfo,
			gochiUserType: null,
			situation: this.state.situation
	   	}

	   	sessionStorage.setItem('selectFriend', JSON.stringify(selectFriend));
    	if (parseInt(e.target.value) === constants.FRIEND_TYPE_ANYONE) {
    		this.setState({
    			gochiUserType: constants.GOCHI_TYPE_ANYONE
    		})

    		this.props.selectFriend(null, null, friendInfo, constants.GOCHI_TYPE_ANYONE);
    		selectFriend = {
		   		selectedFriendId: null,
		   		friendType: null,
		   		friendInfo: friendInfo,
		   		gochiUserType: constants.GOCHI_TYPE_ANYONE
		   	}

		   	sessionStorage.setItem('selectFriend', JSON.stringify(selectFriend));
    		this.setState({
    			buttonSelectActive: false,
    			buttonAddActive: true,
    			inputMessage: false
			});

			this.props.commentInvite(''); 
			sessionStorage.setItem('comment', '');

			// this.getCardDefault();

    	} else if (parseInt(e.target.value) === constants.FRIEND_TYPE_YOURSELF) {
    		this.setState({
    			gochiUserType: constants.GOCHI_TYPE_YOURSELF
    		})

    		this.props.selectFriend(null, null, null, constants.GOCHI_TYPE_YOURSELF);
    		selectFriend = {
		   		selectedFriendId: null,
		   		friendType: null,
		   		friendInfo: this.props.userInfo.userId,
				gochiUserType: constants.GOCHI_TYPE_YOURSELF,
				situation: null
		   	}

		   	sessionStorage.setItem('selectFriend', JSON.stringify(selectFriend));
    		this.setState({
    			buttonSelectActive: true,
    			buttonAddActive: false,
    			inputMessage: false
			});
			this.props.commentInvite(''); 
			sessionStorage.setItem('comment', '');
    	} else {
			this.setState({
    			gochiUserType: null
    		})
    		if (this.props.userInfo.isGuest) {
    			this.props.selectFriend(null, null, friendInfo, constants.GOCHI_TYPE_NON_USER);

		    	selectFriend = {
			   		selectedFriendId: null,
			   		friendType: constants.GOCHI_TYPE_NON_USER,
			   		friendInfo: friendInfo,
					gochiUserType: null,
					situation: null
			   	}

			   	sessionStorage.setItem('selectFriend', JSON.stringify(selectFriend));
    		}
    		this.setState({
    			buttonSelectActive: true,
				buttonAddActive: false,
				inputMessage: true
    		});

			// this.getCardDefault();
    	}
	}

    handleShowCmtInput = (e) => {
    	e.preventDefault();
    	this.setState({
    		showComponent: true
    	});
	};
	
	handleSelectOutSysUser() {  
		var gochiUserType = constants.GOCHI_TYPE_NON_USER;
		var friendInfo = {};
		this.props.selectFriend(null, constants.FRIEND_OUT_SYS, friendInfo, gochiUserType);  

		var selectFriend = {
	   		selectedFriendId: null,
	   		friendType: constants.FRIEND_OUT_SYS,
	   		friendInfo: friendInfo,
	   		gochiUserType: gochiUserType
	   	}

	   	sessionStorage.setItem('selectFriend', JSON.stringify(selectFriend));
	}

	handleRemoveFriend() {  
		this.props.selectFriend(null, null, null, null);  

		var selectFriend = {
	   		selectedFriendId: null,
	   		friendType: null,
	   		friendInfo: null,
	   		gochiUserType: null
	   	}

	   	sessionStorage.setItem('selectFriend', JSON.stringify(selectFriend));
	}

    UNSAFE_componentWillMount(){
		let url = window.location.search;
		let params = queryString.parse(url);
		var menuId = params.id;
		var shopId = params.shopId;
        var src = params.src;
        const fromShashoku = params.fromShashoku;
		const isSite = params.isSite;

		if (shopId) {
			this.setState({
				shopId: shopId
			});
		}

		if (src) {
			this.setState({
				src: src
			});
		}

		if (isSite) {
			this.setState({
				isSite: isSite
			});
		}

		this.setState({
			menuId: menuId
        });
        
        if (fromShashoku) {
            this.setState({
				paramShashoku: `&fromShashoku=${encodeURIComponent(fromShashoku)}`
			});
        }

		if (this.props.friend.gochiUserType === constants.GOCHI_TYPE_ANYONE) {
			this.setState({
				buttonSelectActive: true,
				buttonAddActive: true,
				inputMessage: false
			});
		}else if (this.props.friend.gochiUserType === constants.GOCHI_TYPE_YOURSELF) {
			this.setState({
				buttonSelectActive: false,
				buttonAddActive: false,
				inputMessage: false
			});
		}else{
			this.setState({
				buttonSelectActive: false,
				buttonAddActive: false,
				inputMessage: true
			});
		}

		if (this.props.friend.gochiUserType === constants.GOCHI_TYPE_ANYONE && this.props.friend.friendInfo.situation !== "") {
			this.setState({
				showComponent: true
			})
		}

		this.getCardDefault();
	}

    render() {
    	log(["this state SelectFriends", this.state]);
		var friend = this.props.friend.friendInfo;

		const guest = this.props.userInfo.isGuest;
    	const { t } = this.props;
        const srcParam = this.state.src ? '&src=' + this.state.src : '';
		var isSiteParam =  this.state.isSite ? {name: 'isSite', value: this.state.isSite} : {};

		const isNoPayment2 = this.props.menuInfo?.noPayment === 2;
		const isGochiCharge = this.props.menuInfo?.menuChildType === 5;

        return (
			<>
			{/* hide payment confirm when menu is gochi charge menu_child_type = 5 */}
			{!isGochiCharge ? (
        	<div className="dish-infor mt-5">
			    {/* <label className="ttl-label fs-16">
					{t('pages.invite.chooseInviteType')}
					<span className="required-tag">{t('other.requiredTag')}</span>
				</label> */}
				<div className="required-dot">
					<div className="dot-red"></div>
					<div className="dot-text-label fs-18">{t('pages.invite.chooseInviteType')}</div>
				</div>
				<div className="line-horizontal label-ln mb-2"></div>
				<div>
					<div className="friend-option">
						{this.props.menuInfo.menuChildType !== 3 && (
						<>
							<div className="box-option">
								<div className="d-flex">
									<div className="">
										<label className="radio-custom">
											<input type="radio" name="friendType" value={constants.FRIEND_TYPE_YOURSELF}
												onChange={this.handleActiveButton}
												checked={this.props.friend.gochiUserType === constants.GOCHI_TYPE_YOURSELF}
											/>
											<span className="checkmark"></span>
											<span>{t('pages.invite.chooseYourSelf')}</span>
										</label>
									</div>
								</div>
								{guest ?
									<div className="fs-13 mb-3 pl-32">{t('pages.invite.guest desciption choose your self')}</div>
								:
									<div className="fs-13 mb-3 pl-32">{t('pages.invite.desciption choose your self')}</div>
								}
							</div>
							{!isNoPayment2 && <div className="box-option">
								<div className="d-flex">
									<div className="">
										<label className="radio-custom">
											<input type="radio" name="friendType" value={constants.FRIEND_TYPE_USER}
												onChange={this.handleActiveButton}
												checked={!this.props.friend.gochiUserType || this.props.friend.gochiUserType === constants.GOCHI_TYPE_USER || this.props.friend.gochiUserType === constants.GOCHI_TYPE_NON_USER}
											/>
											<span className="checkmark"></span>
											<span>{t('pages.invite.chooseSysFriend')}</span>
										</label>
									</div>
								</div>

								{!guest && this.props.selectFriendError &&
									<div className="text-danger fs-14 mb-2 fs-12 ml-32 box-warning-message">{this.props.selectFriendError}</div>
								}

								{guest ?
									<div className="fs-13 mb-3 pl-32">{t('pages.invite.giftLinkWillBeIssued')}</div>
								:
									<div className="fs-13 mb-3 pl-32">{t('pages.invite.textInviteFriendUser')}</div>
								}

								{(!guest && (!this.props.friend.gochiUserType || this.props.friend.gochiUserType === constants.GOCHI_TYPE_USER || this.props.friend.gochiUserType === constants.GOCHI_TYPE_NON_USER)) &&
									<div className="pl-32">
										<Link 
											to={!this.state.menuId && this.state.shopId ? addParamUrl(`/select-friends?shopId=` + this.state.shopId+ srcParam + this.state.paramShashoku, [isSiteParam]) : addParamUrl(`/select-friends?id=` + this.state.menuId+ srcParam + this.state.paramShashoku, [isSiteParam])} 
											className={`btn btn-outline-green-38 fs-16 align-center ${friend && friend.userId ? 'active' : ''}`}
											disabled={guest || false}>
											{/* <FontAwesomeIcon icon={faUser} className="mr-1"/> */}
											{t('pages.invite.userSelection')}
										</Link>
									</div>
								}
								{(!guest && (!this.props.friend.gochiUserType || this.props.friend.gochiUserType === constants.GOCHI_TYPE_USER || this.props.friend.gochiUserType === constants.GOCHI_TYPE_NON_USER)) && (friend && this.props.friend.gochiUserType === constants.GOCHI_TYPE_USER) &&
									<div className="selected-friend mt-3">
										<div className="media">
											<img 
												className="mr-1 friend-ava" 
												src={ friend.userImage ? 
														friend.userImage[0].image : 
															require("../../../assets/img/icons/gochi_noimg-ver4.jpg") } 
												alt={ friend.displayName } 
												width="20" 
												height="20" 
											/>
											<div className="media-body d-flex">
												<div className="link-friend">{ friend.displayName }</div>
												<span className="font-weight-bold">{t('pages.invite.Feast on')}</span>
											</div>
											<i className="button-close" onClick={this.handleRemoveFriend}></i>
										</div>
									</div>
								}
								{(!guest && (!this.props.friend.gochiUserType || this.props.friend.gochiUserType === constants.GOCHI_TYPE_USER || this.props.friend.gochiUserType === constants.GOCHI_TYPE_NON_USER)) &&
									<div className="pl-32 mt-3">
										<div className="fs-12">{t('pages.invite.description line sms')}</div>
										{this.props.friend.gochiUserType === constants.GOCHI_TYPE_NON_USER ? 
										<span onClick={this.handleSelectOutSysUser} className="btn btn-line-sns active align-center"
												disabled={guest || false}>
											{/* <FontAwesomeIcon icon={faEnvelope} className="mr-1"/> */}
											{t('pages.invite.selectNoneUser')}
										</span>
										:
										<span onClick={this.handleSelectOutSysUser} className="btn btn-line-sns align-center"
												disabled={guest || false}>
											{/* <FontAwesomeIcon icon={faEnvelope} className="mr-1"/> */}
											{t('pages.invite.selectNoneUser')}
										</span>
										}
									</div>
								}
								{/* {this.state.inputMessage && (
									<div className="pl-32">
										<Message messageError={this.props.messageError} />
									</div>
								)} */}
							</div>}
						</>
						)}
						{!this.state.shopId && !isNoPayment2 && (
							<div className="box-option mt-4">
								<div className="d-flex">
									<div className="">
										<label className="radio-custom mb-0">
											<input type="radio" name="friendType" value={constants.FRIEND_TYPE_ANYONE}
													onChange={this.handleActiveButton}
													checked={this.props.friend.gochiUserType === constants.GOCHI_TYPE_ANYONE || this.props.menuInfo.menuChildType === 3}
													disabled={(this.props.shopInfo.gochiRestriction === constants.RESTRICTION_MENTION_ONLY) || this.state.shopId}
											/>
											<span className="checkmark"></span>
											<span>{t('pages.invite.noChooseSysFriend')}</span>
										</label>
									</div>
								</div>

								<div className="fs-13 mt-2 space-pre-line pl-32">
									{t('pages.invite.Anyone can enjoy at the shop')}
								</div>
								{(this.state.buttonAddActive || this.props.menuInfo.menuChildType === 3) && (
									<div className="pl-32">
										<div className="fs-12 mt-2 space-pre-line">
											<span className="font-weight-bold">{t('pages.invite.Who do you want to eat')}</span><span>{t('pages.invite.Optional')}</span>
										</div>
										<textarea placeholder={t('pages.invite.conditionPlaceholder')}
												className={this.props.situationError ? "form-control textarea-message ex-form-required mt-1" : "form-control textarea-message mt-1" }
												defaultValue={(this.state.situation) ? this.state.situation : (this.props.friend.friendInfo && this.props.friend.friendInfo.situation)}
												onChange={this.onSituationChange}
												name="situation"
										>
										</textarea>
										{this.props.situationError &&
											<div className="text-danger fs-14 mb-2 fs-12 box-warning-message">{this.props.situationError}</div>
										}
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
			) : (
				<input type="hidden" name="friendType" value={constants.FRIEND_TYPE_YOURSELF} />
			)}
			{/* {((this.props.friend.gochiUserType && this.props.friend.gochiUserType !== constants.GOCHI_TYPE_YOURSELF) || (!this.props.friend.gochiUserType)) && (
				<ThankYouCard messageCardError={this.props.messageCardError} />
			)} */}
			{this.state.gochiUserType !== constants.GOCHI_TYPE_YOURSELF && 
					this.props.menuInfo && this.props.menuInfo.menuChildType !== 3 && (
				<ThankYouCard messageCardError={this.props.messageCardError} />
			)}
			</>
        );
    }
}


const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
	selectFriend: (selectedFriendId, friendType, friendInfo, gochiUserType) => dispatch(selectFriend(selectedFriendId, friendType, friendInfo, gochiUserType)),
	commentInvite: (comment) => dispatch(commentInvite(comment)),
	addMessageCard: (thankYouText, thankYouImage, giftCardId) => dispatch(addMessageCard(thankYouText, thankYouImage, giftCardId)),
	showLoading: (showing) => dispatch(showLoading(showing))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SelectFriend));

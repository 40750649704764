import React, { useState } from "react";

export function DeleteAccountConfirmModal(props) {
  const [policy, setPolicy] = useState(false);

  return (
    <div
      id="deleteAccount"
      className={`modal modal-profile modal-profile-01 modal-01 modal-lg history-modal policy-gochi-kid-modal delete-account-confirm-modal ${
        props.show ? "is-show" : ""
      }`}
      tabIndex={-1}
    >
      <div className="modal-header-history">
        <button
          className="modal-btn modal-btn-close"
          id="modalClose"
          type="button"
          onClick={props.onClose}
        >
          <svg className="icon">
            <title>閉じる</title>
            <use xlinkHref="/assets/img/common/icon.svg#times" />
          </svg>
        </button>
      </div>
      <div className="modal-body">
        <div className="title">本当にアカウントを削除しますか？</div>
        <div className="content">
          <ul className="note">
            <li>ご購入済みの未使用チケットは、アカウント削除後も有効期限内はご利用いただけます。ご自身で「ごちのURL」を保存してご利用ください。</li>
            <li>アカウントに関連するサービスや配信メールがキャンセルされます。※メール配信停止手続きには数日お時間をいただきます。手続き完了まではメールが届く場合がございますのでご了承ください。</li>
            <li>こどもごちめしの継続支援は事前に中止ください。</li>
            <li>再度ログインすることができなくなります。</li>
          </ul>
        </div>
        <div className="field field-checkbox policy-text-box">
          <label>
            <input
              className="before-icon"
              type="checkbox"
              checked={policy}
              onChange={(e) => {
                setPolicy(e.target.checked);
              }}
            />
            <i className="icon" />
          </label>
          <div className="policy-text-description">
            「個人情報の取り扱いについて」に同意する
            <div>
              <a href="https://gochimeshi.com/policy/privacy/" target="_blank">個人情報の取り扱いについて</a>
            </div>
          </div>
        </div>
        <div className="btn-container">
          <button
            className="btn btn-rect btn-delete-account"
            onClick={props.handleDeleteAccount}
            disabled={!policy}
          >
            削除する
          </button>

          <button
            className="btn btn-rect btn-close"
            onClick={props.onClose}
          >
            キャンセル
          </button>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import { get } from 'lodash';
import Button from '@material-ui/core/Button';
const NO_IMAGE_USER_KID = require("../../../assets/img/icons/no_image_user_kid.png");

function UploadImageInputGochiKid(props) {
    const { label, setFile, previewImage, setPreviewImage, isOnlyImage, indexImg, removeImage, disabled, isNotShowAddBtn, isNotShowDeleteBtn, setHasChangeImgPoor, checkChange, isUserKid } = props;

    const imagePreview = (preview, indexImg) => {
      const reader = new FileReader();
      reader.readAsDataURL(preview);
      reader.onloadend = () => {
        setPreviewImage(reader.result, indexImg);
      };
    };

    const handleOnChange = e => {
      const newFile = e.target.files[0];
      console.log('newFile', newFile);
      if (isOnlyImage) {
        const mineFile = get(newFile, 'type', '');
        if (mineFile.search('image/') >= 0) {
          setFile(newFile, indexImg);
          imagePreview(newFile, indexImg);
        } else {
          alert('画像の形式が正しくありま線。');
        }
      } else {
        setFile(newFile, indexImg);
        imagePreview(newFile, indexImg);
      }
      if (checkChange) {
        setHasChangeImgPoor(true);
      }
      e.target.value = null;
    };

    return ( 
      <div className={`uploadFile upload-image-register thank-thumbnail-upload ${isUserKid ? 'mt-4px' : ''}`}>
        <div className='preview-image-box'>
          <img
            id="image-result"
            src={previewImage ? previewImage : NO_IMAGE_USER_KID}
            className={`preview-image ${previewImage ? "" : "no-border"}`}
          />
        </div>

        
        {!isNotShowAddBtn && (
          <InputLabel
            htmlFor={label}
            margin="dense"
            className="inputLabel"
          >
              <Button
                variant="outlined"
                color="primary"
                component="span"
                className="button thank-thumbnail-btn add-image-btn"
                disabled={disabled}
              >
                <i className='icon-user-kid'/>
              </Button>
          </InputLabel>
        )}

        {!isNotShowDeleteBtn && (
          <Button 
            className='thank-thumbnail-btn remove-image-btn'
            onClick={() => removeImage(indexImg)}
            disabled={disabled}
            color="secondary">
            <i className='icon-user-kid'/>
          </Button>
        )}

        <input
          id={label}
          type="file"
          accept="image/*"
          className="inputImage"
          disabled={disabled}
          style={{ display: "none" }}
          onChange={(e) => {
            const fileName =  e.target.files[0].name;
            const re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
            if (!re.exec(fileName)) {
            alert("画像の形式が正しくありません。");
            return;
            }
            handleOnChange(e);
          }}
        />
      </div>
    );
  }

export default UploadImageInputGochiKid;
import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import {
  useScript, 
  useStyleSheet,
  useCategories,
  useTags 
} from "../../../../utils";
import { Footer } from "../../layout/Footer";
import Header from "../../layout/Header";
import GiftService from "../../../../api/services/GiftService";
import { CustomPagination } from "../../common/Pagination";
import { useRouteMatch } from "react-router";
import { connect } from "react-redux";
import { showLoading } from "../../../../actions/action_loading";
import { showError } from "../../../../actions/action_error_alert";
import { ModalGroup } from "../../common/ModalGroup";
import { SituationModal } from "../../common/SituationModal";
import { GiftToModal } from "../../common/GiftToModal";
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { Explanation } from "../../layout/Explanation";

export function MagazinesCategory(props) {
  useStyleSheet("https://unpkg.com/swiper/swiper-bundle.min.css",
    null,
    {
      integrity:
        "sha384-HUXJxzkMpCxEdXkgl8BSjN5SUcUODepsOLIYIWOtKva+Qo2teMmV1mh6ZVwnthaV",
      crossorigin: "anonymous",
      referrerpolicy: "no-referrer",
    }
  );
  useScript("https://unpkg.com/swiper/swiper-bundle.min.js",
    null,
    { integrity: "sha384-XVTmRHMO2XUTzy8IerJVEy8ddg47Yz5pZrLc7nEWipaTowqT1xNb8qTLaw64Bd4S",
      crossorigin: "anonymous"
    }
  );
  useScript("/assets/js/app.js");

  const NO_IMAGE = require("../../../../assets/img/icons/noimage.png");

  const match = useRouteMatch();
  const categoryId = match.params.categoryId;
  const subCategoryId = '';
  const [page, setPage] = useState(1);
  const LIMIT = 20;
  const categories = useCategories();
  const tags = useTags();
  const [showMagazines, setShowMagazines] = useState([]);
  const [totalResult, setTotalResult] = useState(0);
  const categoryName = getCategoryName(categoryId);
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [total, setTotal] = useState(0);

  const history = useHistory();

  const { t } = props;

  useEffect(() =>{
    window.scrollTo(0, 0);
    getCategoryMagazines(categoryId, subCategoryId, page, LIMIT);
    getTotal();
  }, [categoryId, subCategoryId, page, LIMIT]);

  function getTitle() {
    return (categoryName || "") + "｜ごちめしマガジン｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス";
  }

  function getMetaDescription(){
    return `ごちめしマガジンの${categoryName || ""}のページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。`;
  }

  async function getCategoryMagazines(categoryId,subCategoryId, page, perPage) {
    setLoading(true);
    GiftService.getCategoryMagazines(categoryId, subCategoryId, page, perPage).then((response) => {
      setLoading(false);
      if(response.status === 200){
        setShowMagazines(response.data.contents);
        setTotalResult(response.data.totalCount);
      } else if (response.status === 404) {
        history.push({
          pathname: '/404'
        });
      } else {
        setShowMagazines([]);
        setTotalResult(0);
      }
    })
    .catch((e) => {
      setLoading(false);
      console.error("GET CATEGORY MAGAZINE ERROR: ", e);
    });
  }

  function handleChangePage(toPage) {
    console.log("HANDLE CHANGE PAGE:", toPage);
    setPage(toPage);
  }
  
  function formatDate(value) {
    return value && value !== ""
      ? moment(value).format('YYYY.MM.DD')
      : value;
  }

  function getCategoryName(id) {
    if (id) {
      const category = categories.find(
        (item) => item.term_id.toString() === id.toString()
      );
      if (category) return category.name;
    }
    return '';
  }

  function onClickShowMore() {
    setShowMore(!showMore);
  }

  function getTotal() {
    GiftService.getMagazines(1, 1).then((response) => {
      if (response.status === 200) {
        setTotal(response.data.totalCount);
      }
    });
  }

  return (
    <>
      <MetaTags>
        <title>{getTitle()}</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content={getMetaDescription()} />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content={getTitle()} />
        <meta name="twitter:description" content={getMetaDescription()} />
        <meta name="twitter:image" content={window.location.origin + "/assets/img/common/ogp.png"}  />
        <meta name="twitter:site" content="@gochi_meshi" />
        <meta name="twitter:creator" content="@gochi_meshi" />
        <meta name="twitter:image:width" content="300" />
        <meta name="twitter:image:height" content="300" />
        <meta property="og:site_name" content={getTitle()} />
        <meta property="og:title" content={getTitle()} />
        <meta property="og:type" content="blog" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" ccontent={window.location.origin + "/assets/img/common/ogp.png"} />
        <meta property="og:description" content={getMetaDescription()} />
      </MetaTags>
      <Header />

      <main>
        <div className={loading ? "breadcrumb loading" : "breadcrumb"}>
          <div className="container">
            <ol className="breadcrumb-list">
              <li>
                <a href="/">{t('pages.magazine.topPage')}</a>
              </li>
              <li>
                <a href="/magazine">{t('pages.magazine.title')}</a>
              </li>
              <li>
                <span>{categoryName}</span>
              </li>
            </ol>
          </div>
        </div>

        <div className="heading-lg">
          <div className="container">
            <h1 className="title">{t('pages.magazine.list.title')}</h1>
          </div>
        </div>

        <section className="section-sm mt-20 category-area bg-gray-light-2">
          <div className="container">
            <div className="section-header">
              <svg className="icon">
                <use xlinkHref="/assets/img/common/icon.svg#tile-2"></use>
              </svg>
              <div className="title">{t('pages.magazine.list.category')}</div>
            </div>
            <div className={
              loading
                ? "section-body loading"
                : "section-body"}>
              <div className="btn-group category-btn-group">
                {categories && categories.length > 0 && (
                  <>
                    <a 
                      href="/magazine"
                      className="btn btn-sm btn-outline btn-black category-btn"
                    >
                      {t('pages.magazine.list.all')} {t('other.leftBracket')}{total}{t('other.rightBracket')}
                    </a>
                    {categories.map((item,key) => {
                      let index = `category-${key}`;
                      return (
                        <a
                          href={`/magazine/categories/${item.term_id}`}
                          className={`btn btn-sm btn-black category-btn 
                                    ${(item.term_id.toString()) !== categoryId.toString() 
                                      ? 'btn-outline' :''}`}
                          key={index}
                        >
                          {item.name} {t('other.leftBracket')}{item.count}{t('other.rightBracket')}
                        </a>
                      )
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </section>

        <div className="container">
          <section 
            className={
              loading && showMagazines.length === 0
                ? "section loading"
                : "section"
            }
          >
            <div className="section-header">
              <div className="heading-lg">
                <h2 className="title">
                  {t('other.jpLeftBracket')}{categoryName}{t('other.jpRightBracket')}
                  {t('pages.magazine.list.kiji')}{t('other.colon')} {totalResult}{t('other.item')}
                  </h2>
              </div>
            </div>
            <div className="section-body">
              <div className="magazine-group mt-20">
                {showMagazines && showMagazines.length > 0 && (
                  showMagazines.map((magazine, key) => {
                    let index = `magazine-${key}`;
                    return(
                      <a
                        className={
                          loading
                            ? "card-magazine loading"
                            : "card-magazine"}
                        href={`/magazine/articles/${magazine.post_id}`}
                        key={index}
                      >
                        <div className="card-magazine-thumbnail">
                          <img
                            src={magazine.thumbnails?.large?.url 
                              ? magazine.thumbnails.large.url 
                              : NO_IMAGE}
                            className="img"
                            alt={magazine.post_id}
                            loading="lazy"
                          />
                        </div>
                        <div className="card-magazine-header">
                          {magazine.categories && magazine.categories[0].name && (
                            <div className="badge">
                             {magazine.categories[0].name}
                            </div>
                          )}
                          <div className="date">{formatDate(magazine.modified)}</div>
                          <div className="heading-sm">
                            <div className="title">{magazine.title}</div>
                          </div>
                        </div>
                      </a>
                    )
                  })
                )}
              </div>
              {showMagazines && showMagazines.length > 0 && (
                <CustomPagination
                count={Math.ceil(totalResult / LIMIT)}
                page={page}
                onChange={handleChangePage}
                />
              )}
            </div>
            
            <div className="section-footer">
              <div className="box box-lg tag-area">
                <div className={`section-hide ${showMore ? 'is-open' : 'is-close'}`}>
                  <div className={`section-body ${showMore ? 'is-open' : 'is-close'}`}>
                    <section className="section-sm">
                      <div className="section-header">
                        <svg className="icon">
                          <use xlinkHref="/assets/img/common/icon.svg#tag"></use>
                        </svg>
                        <div className="title">{t('pages.magazine.list.tag')}</div>
                      </div>
                      <div className="section-body">
                        <div className="btn-group tag-btn-group">
                          {tags && tags.length > 0 && (
                            tags.map((item, key) => {
                              let index = `tag-${key}`;
                              return (
                                <a
                                  className="btn btn-sm btn-outline btn-black tag-btn"
                                  href={`/magazine/tags/${item.term_id}`}
                                  key={index}
                                >
                                  {item.name}
                                </a>
                              )
                            })
                          )}
                        </div>
                      </div>
                    </section>
                  </div>
                </div>

                <div 
                  className={`btn-group section-btn-group magazine-tag-btn-group ${showMore ? 'is-open' : 'is-close'}`} 
                  id="isArrowIcon">
                  <button
                    id="switchAccordion"
                    className="section-toggle tag-section-toggl"
                    onClick={onClickShowMore}
                  >
                    <svg className="icon">
                      <use xlinkHref="/assets/img/common/icon.svg#angle-down"></use>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Explanation />
      </main>

      <Footer />

      <ModalGroup>
        <SituationModal />
        <GiftToModal />
      </ModalGroup>
    </>
  )
}

const mapStateToProps = (state) => ({ props: state.props });
const mapDispatchToProps = (dispatch) => ({
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content, buttonName, gotoUrl) =>
    dispatch(showError(showing, title, content, buttonName, gotoUrl)),
});

export default connect(mapDispatchToProps, mapStateToProps)(withTranslation()(MagazinesCategory));
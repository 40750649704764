const stateDefault = {
  isLogined: false,
};

export default (state = stateDefault, action) => { 
  switch (action.type){
    case 'USER_INFO_SAVE':
      return {
        ...state,
        isLogined: action.isLogined,
        isGuest: action.isGuest,
        ...action.userInfo
      };
    default:
      return state;
  }
};

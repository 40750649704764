import React, { useState, useEffect } from "react";
import { Payment } from './Payment';
import queryString from 'query-string';
import {log, addParamUrl} from '../../../../utils';
import AddCardModal from "../../common/AddCardModal";
import MenuService from "../../../../api/services/MenuService";
import RegisterCardModal from "../../common/RegisterCardModal";
import ChargeConfirmModal from "../../common/ChargeConfirmModal";
import ConfirmModal from "../../common/ConfirmModal";
import PaymentService from "../../../../api/services/PaymentService";
import {saveInvite} from "../../../../actions/action_menu";
import * as constants from '../../../../constants';

export function ChargeToGochi(props) {
  const paramsUrl = queryString.parse(window.location.search);
  const isSite = paramsUrl.isSite;
  const isSiteParam = isSite ? {name: 'isSite', value: isSite} : {};

  const [seeMorePriceDetail, setSeeMorePriceDetail] = useState(false);
  const [inputPrice, setInputPrice] = useState(0);
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [showRegisterCardModal, setShowRegisterCardModal] = useState(false);
  const [showConfirmChargeModal, setShowConfirmChargeModal] = useState(false);
  const [chargeInforAmount, setChargeInforAmount] = useState([]);
  const [selectPayment, setSelectPayment] = useState(0);
  const [chargeInfo, setChargeInfo] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [paymentGateway, setPaymentGateway] = useState([]);
  const [popupShowing, setPopupShowing] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [titleConfirmPopup, setTitleConfirmPopup] = useState("");
  const [contentConfirmPopup, setContentConfirmPopup] = useState("");
  const [paymentList, setPaymentList] = useState([]);
  const [havePaymentMethod, setHavePaymentMethod] = useState(true);
  const [chargeAmountNotZero, setChargeAmountNotZero] = useState(true);
  const [flagResetRegisterCard, setFlagResetRegisterCard] = useState(false);
  const [isOnepay, setIsOnepay] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);

  function handleSeeMorePriceDetail () {
		if (seeMorePriceDetail) {
			setSeeMorePriceDetail(false);
		} else {
			setSeeMorePriceDetail(true);
		}
	}

  function handleInputedPrice (v) {   
		var valueInput = v;
    var quantity = 1;
    setChargeAmountNotZero(valueInput !== "" && !isNaN(valueInput) && valueInput !== 0);
    var chargeAmount = {
				amountWithTax: valueInput,
				priceIncludedTax: valueInput * quantity,
				saleCommission: props.dataFeeGochi?.feeRate ? Math.round(valueInput * props.dataFeeGochi?.feeRate) * quantity : 0,
				feeWalletPremium: props.dataFeeGochi?.premiumFeeRate ? Math.round(valueInput * props.dataFeeGochi?.premiumFeeRate) * quantity : 0,
				totalAmount: (valueInput * quantity) + (props.dataFeeGochi?.feeRate ? Math.round(valueInput * props.dataFeeGochi?.feeRate) * quantity : 0 ) - (props.dataFeeGochi?.premiumFeeRate ? Math.round(valueInput * props.dataFeeGochi?.premiumFeeRate) * quantity : 0)
			};
    setInputPrice(valueInput);
		setChargeInforAmount(chargeAmount);
	}

  function handleActiveButton (e) {
    setSelectPayment(e.target.value);
  }

  function handleConfirmCharge () {
    let chargeIn = {
      menuId: props.gochiData.menuId,
      paymentType: selectPayment,
      premiumAmount: inputPrice,
      paymentMethod: paymentMethod,
      gochiBillId: props.gochiData?.gochiBillId,
      gochiData,
      gochiUserId: props.gochiData?.gochiUserId,
    }
    setChargeInfo(chargeIn);

    let notHavePaymentMethod = parseInt(selectPayment) === constants.PAYMENT_TYPE_CREDIT_CARD && paymentMethod.length <= 0;
    let chargeAmountNotZero = Object.entries(chargeInforAmount).length !== 0 && !isNaN(chargeInforAmount?.priceIncludedTax) && chargeInforAmount?.priceIncludedTax !== 0;

    if (notHavePaymentMethod) {
      setHavePaymentMethod(false);
    } else {
      setHavePaymentMethod(true);
    }

    if (chargeAmountNotZero) {
      setChargeAmountNotZero(true);
    } else {
      setChargeAmountNotZero(false);
    }

    if (!notHavePaymentMethod && chargeAmountNotZero) {
      setShowConfirmChargeModal(true);
    }
  }

  useEffect(() => {
    let url = window.location.search;
		let params = queryString.parse(url);
		let merchantPaymentId = params.merchantPaymentId;

		setPaymentGateway(params.paymentGateway);
    const completeInviteParams = {
			checkoutId: params.checkoutId
		};
    if (merchantPaymentId) {
			setTimeout(() => {
				completeInvite(merchantPaymentId, completeInviteParams);
			}, 300);
		}
  }, [])

  function handlePopupConfirm () {
		let url = window.location.search;
		let params = queryString.parse(url);
		var merchantPaymentId = params.merchantPaymentId;

		const completeInviteParams = {
			checkoutId: params.checkoutId
		};
		this.completeInvite(merchantPaymentId, completeInviteParams);
	}

  function handlePopupClose () {
    window.location.href = addParamUrl("/mypage/mygochi_charge", [isSiteParam]);
    setPopupShowing(false);
  }

  function retryInviteWithMerchantPayment() {
    let url = window.location.search;
    let params = queryString.parse(url);
    var merchantPaymentId = params.merchantPaymentId;
    const completeInviteParams = {
      checkoutId: params.checkoutId
    };
    if (merchantPaymentId) {
      setErrorPopup(false);
      completeInvite(merchantPaymentId, completeInviteParams);
    }
  }

  async function completeInvite (merchantPaymentId, completeInviteParams) {  
      let url = window.location.search;
		  let params = queryString.parse(url);
      props.setLoading(true);
	    try {
	      	if (merchantPaymentId) {
            // If payment gateway is Onepay, re-call API complete until response status is not 404 or timeout (5 * 10 = 50 seconds)
            let result;
            for (let i = 0; i < 5; i++) {
              result = await MenuService.completeChargeCheckout(merchantPaymentId, completeInviteParams);
              if (result.status !== 404) break;
              await new Promise(r => setTimeout(r, 10000));
            }
            if (result.status === 200) {
              saveInvite(result.data);
              await props.fetchDataGochiChargeDetail(params.menuId, props.userInfo.userId, window.location.pathname.split('/').length > 0 && window.location.pathname.split('/')[3] ? window.location.pathname.split('/')[3] : '');
              let payment;
              if (parseInt(result.data.paymentType) === constants.PAYMENT_TYPE_CREDIT_CARD) {
                payment = "クレジットカード";
              } else if (parseInt(result.data.paymentType) === constants.PAYMENT_TYPE_PAYPAY) {
                  payment = "PayPay";
              } else if (parseInt(result.data.paymentType) === constants.PAYMENT_TYPE_ONEPAY_ALIPAY) {
                  payment = "Alipay";
              } else if (parseInt(result.data.paymentType) === constants.PAYMENT_TYPE_ONEPAY_LINEPAY) {
                  payment = "LINE Pay";
              } else if (parseInt(result.data.paymentType) === constants.PAYMENT_TYPE_ONEPAY_MERUPAY) {
                  payment = "Meru Pay";
              } else if (parseInt(result.data.paymentType) === constants.PAYMENT_TYPE_WECHATPAY) {
                  payment = "Wechat Pay";
              } else if (parseInt(result.data.paymentType) === constants.PAYMENT_TYPE_ATONE) {
                  payment = "Atone";
              }
              if (result.data?.premiumAmount !== (result.data?.amount + result.data?.taxAmount)) {
                openConfirmPopup("チャージ完了", `${payment}で${(result.data?.amount + result.data?.taxAmount).toLocaleString()}円（プレミアムを付けた${result.data?.premiumAmount?.toLocaleString()}円）をチャージ完了しました！`);
              } else {
                openConfirmPopup("チャージ完了", `${payment}で${(result.data?.amount + result.data?.taxAmount).toLocaleString()}円をチャージ完了しました。`);
              }
            } else if (result.status === 402) {
              let messageErr;
              if (result.data) {
                if (result.data.stripe_code === 'expired_card') {
                  messageErr = "有効期限切れのカードです。";
                } else if (result.data.stripe_code === 'amount_too_small') {
                  messageErr = "最低ご利用金額に達していません。";
                } else if (result.data.stripe_code === 'insufficient_funds') {
                  messageErr = "カードの残高が不足しています。";
                } else if (result.data.stripe_code === 'card_velocity_exceeded') {
                  messageErr = "カードの利用限度額を超えています。";
                } else {
                  messageErr = this.props.menuInfo.subscription === 1 
                  ? "決済失敗しましたため、サブスクリプション登録を失敗しました。\nカード情報をご確認してから、再度ご購入してください。"
                  : errorMessage;
                }
              }
              openErrorPopup(messageErr);
            } else if (paymentGateway === 'onepay' || result.status === 404) {
              setIsOnepay(true);
              openErrorPopup("システム内にごち登録がまだないので、支払った場合リトライボタンで再読み込みをお試しください。\nこのメニューをまだ支払わない場合、キャンセルボタンを押してください。");
            } else {
              if (result.status === 404 || result.status === 400 || result.status === 500) {
                openErrorPopup("ごち登録に失敗しました。");
              }
              if (result.status === 504) {
                openErrorPopup("エラーが発生しました。ご登録のメールアドレスに領収書メールが届いたかご確認ください。40分以上経ってもメールの受信が確認できない場合は、お手数お掛けしますが、再購入をお願いいたします。");
              }
            }
	      	} else { 
            openErrorPopup("ごち登録に失敗しました。");
          }
	    } catch (error) {
        log(error);
			  openErrorPopup("Fail");
	    }  
      props.setLoading(false);
	}

  function openConfirmPopup(title, content) {
    setTitleConfirmPopup(title);
    setContentConfirmPopup(content);
    setPopupShowing(true);
  }

  function openErrorPopup(content) {
    setErrorMessage(content);
    setErrorPopup(true);
  }

  async function fetchPaymentList(userId) { 
		try { 
			if (userId) {
				var list = await getPaymentList(userId);
			    setPaymentList(list); 
        if (list.count > 0) {
          setHavePaymentMethod(true);
        }
			} 
		} catch (e) {
			log(e); 
		}		
	}

	async function getPaymentList (userId) { 
		try {
			const { data } = await PaymentService.getPaymentList(userId);
      console.log('get list payment', data);
			return data;
		} catch (error) { 
			log('getPaymentList error'); 
			this.props.showError(true, this.props.t("There is something wrong"), "");
		}
	};
  
  const gochiData = props.gochiData;
  const userInfo = props.userInfo;

  return (
    <>
      <hr className="mt-30" />
      <div className={`profile-charge ${props.loadingCharge ? 'loading': ''}`} id="scrollToElementId">
        <div className="profile-charge-header">
          <div>
            <div className="required-dot input-title">
              <div className="dot-red"></div>
              <div className="dot-text-label">チャージする金額</div>
            </div>
            <div className="content-dark">
              <div className="box-option input-charge-box">
                <div className="d-flex">
                  <div className="input-price-gochi-charge">
                    <input
                      className="form-control ex-form-required fs-14 text-left font-weight-bold input-charge"
                      type="number"
                      min={0}
                      inputMode="numeric"
                      pattern="[0-9]*" 
                      onWheel={event => event.currentTarget.blur()}
                      onChange={(e) => {
                        const value = parseInt(e.target.value.replace(/[^\d]/, '')) || '';
                        handleInputedPrice(value);
                      }}
                      value={inputPrice}
                      name="inputPrice"
                    />
                    <span className="unit font-weight-bold">円</span>
                  </div>
                </div>
              </div>
              {!chargeAmountNotZero && (<div className="validate-price-input">チャージしたい金額をご入力ください。</div>)}

              <div className="form-group-gochi-charge">
                <div className="d-flex justify-content-between fs-18 font-weight-bold clr-000000">
                  <div className="mr-2">ご請求額</div>
                  <div className="charge-infor-amount">
                    {chargeInforAmount?.totalAmount ? chargeInforAmount?.totalAmount.toLocaleString() : 0}
                    <span className="fs-12">円</span>
                  </div>
                  <input type="hidden" name="totalAmount" defaultValue="100"/>
                  <input type="hidden" name="includedTaxMenuPrice" defaultValue="100"/>
                  <input type="hidden" name="initAmount" defaultValue="1000"/>
                </div>
              </div>
            </div>

            {seeMorePriceDetail && (
              <>
                <div className="form-group mb-7px">
                  <div className="d-flex justify-content-between fs-16 font-weight-bold clr-000000">
                    <div className="mr-2 font-weight-normal">小計</div>
                    <div className="fs-17">
                      {chargeInforAmount.priceIncludedTax ? chargeInforAmount.priceIncludedTax?.toLocaleString() : 0}
                      <span className="fs-10">円</span>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-7px">
                  <div className="d-flex justify-content-between fs-16 font-weight-bold clr-000000">
                    <div className="mr-2 font-weight-normal">販売手数料</div>
                    <div className="fs-17">
                      {chargeInforAmount.saleCommission ? chargeInforAmount.saleCommission?.toLocaleString() : 0}
                      <span className="fs-10">円</span>
                    </div>
                  </div>
                </div>
                {chargeInforAmount?.feeWalletPremium > 0 && (
                  <div className="form-group mb-7px">
                    <div className="d-flex justify-content-between fs-16 font-weight-bold clr-000000">
                      <div className="mr-2 font-weight-normal">手数料負担</div>
                      <div className="fs-17">
                        {(chargeInforAmount && chargeInforAmount.feeWalletPremium) ? '-' + chargeInforAmount.feeWalletPremium?.toLocaleString() : 0 }
                        <span className="fs-10">円</span>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            <div className="align-center mt-1">
              <button type="button" className="btn btn-see-price-detail-charge" onClick={handleSeeMorePriceDetail}>
                {!seeMorePriceDetail ? "※料金内訳を見る" : "※料金内訳を閉じる"}
              </button>
            </div>

            <Payment
              openAddCard={setShowAddCardModal}
              shopInfo={null}
              gochiData={gochiData}
              userInfo={userInfo}
              selectPayment={selectPayment}
              handleActiveButton={handleActiveButton}
              setSelectPayment={setSelectPayment}
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              fetchPaymentList={fetchPaymentList}
              paymentList={paymentList}
              havePaymentMethod={havePaymentMethod}
            />
          </div>
          <div className="align-center mt-1">
            <button type="button" className="btn btn-charge-gochi" onClick={handleConfirmCharge}>
              確認する
            </button>
          </div>
          <div className="align-center">
            <a href={addParamUrl("/mypage/mygochi_charge", [isSiteParam])} className="align-center back-step-btn">
              前の画面に戻る
            </a>
          </div>
        </div>
      </div>
      <AddCardModal 
        isShow={showAddCardModal}
        onHide={() => setShowAddCardModal(false)}
        showRegCard={setShowRegisterCardModal}
        paymentList={paymentList}
        setPaymentMethod={setPaymentMethod}
        setFlagResetRegisterCard={setFlagResetRegisterCard}
      />
      <RegisterCardModal 
        isShow={showRegisterCardModal}
        onHide={() => setShowRegisterCardModal(false)}
        fetchPaymentList={fetchPaymentList}
        flagResetRegisterCard={flagResetRegisterCard}
        setFlagResetRegisterCard={setFlagResetRegisterCard}
      />
      <ChargeConfirmModal
        isShow={showConfirmChargeModal}
        chargeInfo={chargeInfo}
        chargeInforAmount={chargeInforAmount}
        onHide={() => setShowConfirmChargeModal(false)} 
        openConfirmModal={openConfirmPopup}
        fetchDataGochiChargeDetail={props.fetchDataGochiChargeDetail}
        selectPayment={selectPayment}
        premiumRate = {gochiData?.menuChildType5?.premiumRate}
        openErrorPopup={openErrorPopup}
      />
      <ConfirmModal
					showing={popupShowing}
					title={titleConfirmPopup}
					content={contentConfirmPopup}
					handleConfirm={handlePopupConfirm}
					handleClose={handlePopupClose}
					confirmation="retry"
					cancelation="cancel"
          retryInviteWithMerchantPayment={retryInviteWithMerchantPayment}
          isOnepay={isOnepay}
				/>
      <div
        className={errorPopup ? "custom-modal-container show-modal" : "custom-modal-container"}
        size="sm"
      >
        <div className={errorPopup ? "modal modal-action modal-dialog is-show border-radius-4" : "modal modal-action modal-dialog border-radius-4"}>
          <div className="modal-body modal-error-charge">
            <div className="form-group modal-message">{errorMessage}</div> 
            <div className="form-group text-center btn-submit-box">
              {isOnepay && (
                <button className="btn btn-green-38 border-radius-4 btn-retry" onClick={retryInviteWithMerchantPayment}>
                  リトライ
                </button>
              )}
              <div className="text-center btn-back pt-0">
                <span
                  className="btn btn-green-38 border-radius-4 btn-close"
                  onClick={() => setErrorPopup(false)}
                >
                  キャンセル
                </span>
              </div>
            </div>
          </div>
        </div>
        <div onClick={() => setErrorPopup(false)} className="back-drop" />
    </div>
    </>
  );
}

import React, { useState } from "react";

export default function ActivityInfo({
  className,
  info,
  hideContactInfo
}) {
  const [showErrorImage, setShowErrorImage] = useState(false);

  let wrapperClassName = 'activity-info';
  if (className) {
    wrapperClassName += ' ' + className;
  }
  let activityImage = null;
  if (info.shopImage?.length > 0) {
    activityImage = info.shopImage[0].image;
  } else if (info.groupImages?.length > 0) {
    activityImage = info.groupImages[0].image;
  } else if (info.groupImage?.length > 0) {
    activityImage = info.groupImage[0].image;
  }
  const fullAddress = (info.prefectures || '') + (info.city || '') + (info.address || '') + (info.building || '');
  const description = info.description || info.introduction;

  return (
    <div className={wrapperClassName}>
      <div className="d-flex">
        <div className="activity-image-wrapper">
          {activityImage && !showErrorImage
            ? (
              <img
                src={activityImage}
                alt={info.shopName || info.groupName}
                onError={() => setShowErrorImage(true)}
              />
            )
            : (
              <div className="default-image">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.40833 30.9958H31.6458C32.0069 30.9958 32.2597 30.8514 32.4042 30.5625C32.5486 30.2736 32.5125 29.9847 32.2958 29.6958L25.9583 21.2458C25.7778 21.0292 25.5611 20.9208 25.3083 20.9208C25.0556 20.9208 24.8389 21.0292 24.6583 21.2458L18.1583 29.6417L13.7708 23.6292C13.5903 23.4125 13.3736 23.3042 13.1208 23.3042C12.8681 23.3042 12.6514 23.4125 12.4708 23.6292L7.8125 29.6958C7.63194 29.9847 7.60486 30.2736 7.73125 30.5625C7.85764 30.8514 8.08333 30.9958 8.40833 30.9958ZM3.75 39.5C2.88333 39.5 2.125 39.175 1.475 38.525C0.825 37.875 0.5 37.1167 0.5 36.25V3.75C0.5 2.88333 0.825 2.125 1.475 1.475C2.125 0.825 2.88333 0.5 3.75 0.5H36.25C37.1167 0.5 37.875 0.825 38.525 1.475C39.175 2.125 39.5 2.88333 39.5 3.75V36.25C39.5 37.1167 39.175 37.875 38.525 38.525C37.875 39.175 37.1167 39.5 36.25 39.5H3.75ZM3.75 36.25H36.25V3.75H3.75V36.25ZM3.75 3.75V36.25V3.75Z" fill="#ED808C"/>
                </svg>
              </div>
            )}
        </div>

        <div className={'activity-contact-wrapper' + (hideContactInfo ? ' d-flex align-items-center': '')}>
          <div className="activity-name">{info.shopName || info.groupName}</div>
          {!hideContactInfo && <>
            <hr className="info-divider my-2" />
            {fullAddress && <div className="activity-address">
              <div className="activity-address-icon">
                <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.99996 6.83337C6.32218 6.83337 6.59718 6.71949 6.82496 6.49171C7.05274 6.26393 7.16663 5.98893 7.16663 5.66671C7.16663 5.34449 7.05274 5.06949 6.82496 4.84171C6.59718 4.61393 6.32218 4.50004 5.99996 4.50004C5.67774 4.50004 5.40274 4.61393 5.17496 4.84171C4.94718 5.06949 4.83329 5.34449 4.83329 5.66671C4.83329 5.98893 4.94718 6.26393 5.17496 6.49171C5.40274 6.71949 5.67774 6.83337 5.99996 6.83337ZM6.01663 13.4667C5.93885 13.4667 5.86663 13.4556 5.79996 13.4334C5.73329 13.4112 5.67218 13.3778 5.61663 13.3334C3.96107 11.8778 2.72218 10.5278 1.89996 9.28337C1.07774 8.03893 0.666626 6.87782 0.666626 5.80004C0.666626 4.13337 1.20274 2.8056 2.27496 1.81671C3.34718 0.827818 4.58885 0.333374 5.99996 0.333374C7.41107 0.333374 8.65274 0.827818 9.72496 1.81671C10.7972 2.8056 11.3333 4.13337 11.3333 5.80004C11.3333 6.87782 10.9222 8.03893 10.1 9.28337C9.27774 10.5278 8.03885 11.8778 6.38329 13.3334C6.32774 13.3778 6.2694 13.4112 6.20829 13.4334C6.14718 13.4556 6.08329 13.4667 6.01663 13.4667Z" fill="#ED808C"/>
                </svg>
              </div>
              <span>{fullAddress}</span>
            </div>}
            {info.phone && <div className="activity-phone">
              <div className="activity-phone-icon">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.25 12C9.89444 12 8.54722 11.6667 7.20833 11C5.86944 10.3333 4.66667 9.46667 3.6 8.4C2.53333 7.33333 1.66667 6.13056 1 4.79167C0.333333 3.45278 0 2.10556 0 0.75C0 0.538889 0.0722223 0.361111 0.216667 0.216667C0.361111 0.0722223 0.538889 0 0.75 0H3.08333C3.23889 0 3.375 0.0527777 3.49167 0.158333C3.60833 0.263889 3.68333 0.405556 3.71667 0.583333L4.16667 2.68333C4.18889 2.83889 4.18611 2.98056 4.15833 3.10833C4.13056 3.23611 4.07222 3.34444 3.98333 3.43333L2.31667 5.11667C2.93889 6.15 3.63611 7.05 4.40833 7.81667C5.18056 8.58333 6.05556 9.23333 7.03333 9.76667L8.61667 8.13333C8.72778 8.01111 8.85556 7.925 9 7.875C9.14444 7.825 9.28889 7.81667 9.43333 7.85L11.4167 8.28333C11.5833 8.31667 11.7222 8.4 11.8333 8.53333C11.9444 8.66667 12 8.82222 12 9V11.25C12 11.4611 11.9278 11.6389 11.7833 11.7833C11.6389 11.9278 11.4611 12 11.25 12Z" fill="#ED808C"/>
                </svg>
              </div>
              <span>{info.phone}</span>
            </div>}
          </>}
        </div>
      </div>

      {description && <div className="activity-description mt-2">{description}</div>}
    </div>
  );
}

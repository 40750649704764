import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { showError } from "../../../actions/action_error_alert";
import { showLoading } from "../../../actions/action_loading";
import DonateService from "../../../api/services/GochiKid/DonateService";
import { log } from "../../../utils";
import ActivityInfo from "./ActivityInfo";
import DonateInfo from "./DonateInfo";
import DonateRankLabel from "./DonateRankLabel";
import ModalCloseButton from "./ModalCloseButton";

const LIST_ITEM_PER_PAGE = 5;
const LIST_RANK_INIT_SHOW = 5;
const LIST_RANK_INIT_LIMIT = 100;

function DonateActivityModal({
  show,
  nextStep,
  handleClose,
  showLoading,
  showError,
  prefectures,
  cities
}) {
  const [prefectureId, setPrefectureId] = useState('');
  const [prefectureName, setPrefectureName] = useState('');
  const [cityName, setCityName] = useState('');
  const [keyword, setKeyword] = useState('');
  const [list, setList] = useState([]);
  const [listTotalCount, setListTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loadingList, setLoadingList] = useState(false);
  const [rankList, setRankList] = useState([]);
  const [rankTotalCount, setRankTotalCount] = useState(0);
  const [rankLoadedCount, setRankLoadedCount] = useState(0);
  const [rankShowedCount, setRankShowedCount] = useState(0);
  const [loadingRank, setLoadingRank] = useState(false);

  const chooseActivity = (activity) => {
    nextStep({
      shopId: activity.shopId,
      groupId: activity.groupId
    });
  }

  const getList = useCallback(async ({ page, prefectureName, cityName, keyword }) => {
    setLoadingList(true);
    const response = await DonateService.searchActivity({
      page,
      limit: LIST_ITEM_PER_PAGE,
      prefectures: prefectureName,
      city: cityName,
      keyword: keyword?.trim()
    });
    if (response.status === 200) {
      if (page > 1) {
        setList([
          ...list,
          ...response.data.list
        ]);
      } else {
        setList(response.data.list);
      }
      setListTotalCount(response.data.count);
      setCurrentPage(page);
    } else {
      showError(true, "インターネット接続には問題が発生しました。後でもう一度試してください。", "");
    }
    setLoadingList(false);
  }, [showError, list]);

  const searchList = () => {
    getList({
      prefectureName,
      cityName,
      keyword: keyword?.trim(),
      page: 1
    });
  };

  const showMoreList = () => {
    getList({
      prefectureName,
      cityName,
      keyword: keyword?.trim(),
      page: currentPage + 1
    });
  };

  const getListRank = useCallback(async (limit) => {
    setLoadingRank(true);
    const response = await DonateService.getListRankActivity({ page: 1, limit });
    if (response.status === 200) {
      setRankList(response.data.list);
      setRankTotalCount(response.data.count);
      setRankLoadedCount(limit);
    } else {
      showError(true, "インターネット接続には問題が発生しました。後でもう一度試してください。", "");
    }
    setLoadingRank(false);
  }, [showError]);

  const showMoreRank = async () => {
    if (rankShowedCount >= rankTotalCount) {
      return;
    }
    let count = rankShowedCount + 10;
    if (count > rankLoadedCount) {
      await getListRank(count);
    }
    setRankShowedCount(count);
  };

  const onShow = useCallback(async () => {
    log('DonateActivityModal onShow');

    // Reset filter
    setPrefectureId('');
    setPrefectureName('');
    setCityName('');
    setKeyword('');

    // First load
    await Promise.all([
      getListRank(LIST_RANK_INIT_LIMIT),
      getList({
        prefectureName: '',
        cityName: '',
        keyword: '',
        page: 1
      })
    ]);
    setRankShowedCount(LIST_RANK_INIT_SHOW);
  }, [getListRank, getList]);

  useEffect(() => {
    if (!show) {
      return;
    }
    showLoading(loadingRank || loadingList || prefectures.length === 0 || cities.length === 0);
  }, [show, prefectures, cities, showLoading, loadingRank, loadingList]);

  const filteredCities = cities.filter(city => city.prefCode === prefectureId);

  const showRankingDonate = false;

  return (
    <Modal
      size="sm"
      className="no-header-modal"
      scrollable={false}
      animation={false}
      show={show}
      onShow={onShow}
      onHide={handleClose}
    >
      <Modal.Body className="pb-5">
        <ModalCloseButton onClick={handleClose} />

        <div className="block-title-lg font-family-rounded mt-2">自治体/活動の選択 <span className="color-ED808C">*</span></div>

        {rankList?.length > 0 && showRankingDonate && <div className="mt-4 pb-4">
          <div className="fs-20px fw-800 color-ED808C font-family-rounded">支援累計金額ランキング</div>

          <div className="mt-3">
            {rankList.filter((item, index) => index < rankShowedCount).map((item, index) => 
              <DonateRankLabel
                className="mt-2"
                key={item.shopId ? `shop-${item.shopId}` : `group-${item.groupId}`}
                index={index + 1}
                label={item.shopName ? item.shopName : item.groupName}
                onClick={() => chooseActivity(item)}
              />
            )}
            {rankShowedCount < rankTotalCount && <div className="mt-4 align-center">
              <button className="btn-text color-ED808C fs-16px fw-700" onClick={showMoreRank}>▼ もっと見る</button>
            </div>}
          </div>
        </div>}

        <div className="fs-20px fw-800 color-ED808C font-family-rounded mt-4">自治体/活動一覧</div>

        <div className="search-activity">
          <div className="d-flex mt-3">
            <select
              className={'select-round' + (!prefectureName ? ' empty-value-selected' : '') + ' prefecture-filter mr-2'}
              onChange={(e) => {
                setPrefectureId(e.target.value);
                if (e.target.value) {
                  const index = e.nativeEvent.target.selectedIndex;
                  const label = e.nativeEvent.target[index].text;
                  setPrefectureName(label);
                } else {
                  setPrefectureName('');
                }
              }}
            >
              <option value="">都道府県</option>
              {prefectures.map(item =>
                <option key={item.prefectureId} value={item.prefectureId}>{item.prefectureName}</option>
              )}
            </select>
            <select
              className={'select-round' + (!prefectureName ? ' empty-value-selected' : '') + ' city-filter'}
              onChange={(e) => {
                if (e.target.value) {
                  const index = e.nativeEvent.target.selectedIndex;
                  const label = e.nativeEvent.target[index].text;
                  setCityName(label);
                } else {
                  setCityName('');
                }
              }}
            >
              <option value="">市区町村</option>
              {filteredCities.map(item =>
                <option key={item.cityId} value={item.cityId}>{item.cityName}</option>
              )}
            </select>
          </div>

          <div className="d-flex">
            <input
              className="keyword-input w-100 mr-2"
              type="text"
              placeholder="キーワード"
              value={keyword}
              onChange={(event) => setKeyword(event.target.value)}
            />

            <button className="btn-primary btn-search" onClick={searchList}>検索</button>
          </div>
        </div>

        {list.length > 0
          ? (
            <div className="mt-5">
              {list.map((item, index) => (
                <div key={item.shopId ? `shop-${item.shopId}` : `group-${item.groupId}`} className={index > 0 ? 'my-4' : 'mb-4'}>
                  {index > 0 && <hr className="list-divider" />}
                  <ActivityInfo info={item} />
                  <DonateInfo className="mt-2" info={item} />
                  <div className="mt-4">
                    <button className="btn-primary btn-size-md w-100" onClick={() => chooseActivity(item)}>
                      選択する
                      <i className="icon-next-destination" />
                    </button>
                  </div>
                </div>
              ))}
              {currentPage * LIST_ITEM_PER_PAGE < listTotalCount && <>
                <hr className="list-divider" />
                <div className="mt-4 align-center">
                  <button className="btn-text color-ED808C fs-16px fw-700" onClick={showMoreList}>▼ もっと見る</button>
                </div>
              </>}
            </div>
          ) : (
            <div className="mt-4">
              <div>検索結果がありません。</div>
            </div>
          )}
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = state => ({
  prefectures: state.prefectures,
  cities: state.cities
});

const mapDispatchToProps = dispatch => ({
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content, buttonName, gotoUrl) => dispatch(showError(showing, title, content, buttonName, gotoUrl))
});

export default connect(mapStateToProps, mapDispatchToProps)(DonateActivityModal);

export const saveMenuInfo = (menuInfo) => {
  return {
    type : 'MENU_USER_SAVE',
    menuInfo
  }
};

export const updateQuantityMenu = (updatedMenuInfo) => {
  return {
    type : 'UPDATE_QUANTITY_MENU',
    updatedMenuInfo
  }
};

export const saveInitTotalAmount = (totalAmount) => {
  return {
    type : 'SAVE_INIT_AMOUNT',
    totalAmount
  }
};

export const saveInvite = (inviteContent) => {
  return {
    type : 'SAVE_INVITE',
    inviteContent
  }
};

export const saveFeeRate = (feeRate) => {
  return {
    type : 'SAVE_FEE_RATE',
    feeRate
  }
};

export const saveFeeGiGiRate = (feeGiGiRate) => {
  return {
    type : 'SAVE_FEE_GIGI_RATE',
    feeGiGiRate
  }
};

export const saveDiscountRate = (discountRate) => {
  return {
    type : 'SAVE_DISCOUNT_RATE',
    discountRate
  }
};

export const saveMenuNCount = (menuNCount) => {
  return {
    type : 'SAVE_MENU_N_COUNT',
    menuNCount
  }
};
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import queryString from 'query-string'
import { showError } from "../../../actions/action_error_alert";

class StripeCheckout extends Component {
  constructor (props) {
    super(props)
    let url = window.location.search
    let params = queryString.parse(url)
    this.state = {
      sessionId: params.sessionId
    }
  }

  componentDidMount () {
    this.interval = setInterval(() => {
      if (window.Stripe) {
      this.setState({stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)});
      } else {
        document.querySelector('#stripe-js').addEventListener('load', () => {
          // Create Stripe instance once Stripe.js loads
          this.setState({stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)});
        });
      }
      // setTimeout(() => {
      //     this.state.stripe.redirectToCheckout({
      //         sessionId: this.state.sessionId
      //     }).then(function (data) {
      //         if(data.error) {
      //             this.props.showError(true, data.error.message, "");
      //             return this.setState({
      //                 errorMessage: data.error.message
      //             })
      //         }
      //     });
      // }, 300);
      this.state.stripe.redirectToCheckout({
            sessionId: this.state.sessionId
        }).then(function (data) {
            if(data.error) {
                this.props.showError(true, data.error.message, "");
                return this.setState({
                    errorMessage: data.error.message
                })
            }
        });
    }, 300)
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  render () {
    return null
  }
}

const mapStateToProps = state => ({
  ...state,
})

const mapDispatchToProps = dispatch => ({
	showError: (showing, title, content, buttonName, gotoUrl) =>dispatch(showError(showing, title, content, buttonName, gotoUrl)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(StripeCheckout))
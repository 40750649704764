/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import GiftService from "../../../api/services/GiftService";
import ShopService from "../../../api/services/ShopService";
import { Link } from "react-router-dom";
import { MenuInfoShop } from "./MenuInfoShop";
import { Swiper, SwiperSlide } from "swiper/react";
import { getSlidePerView, swiperConfigs } from "./SwiperConfigs";
import { SliderPagination } from "./SliderPagination";
import { InfoBox } from "./InfoBox";
import queryString from "query-string";
import { useWindowSize } from "../../../utils";

export function ShopDetails(props) {
  const shopId = props.shopId;
  const [listMenus, setListMenus] = useState(null);
  const [priceRanges, setPriceRanges] = useState([]);
  const [menuSituations, setMenuSituations] = useState([]);
  const [menuUserTargets, setMenuUserTargets] = useState([]);
  const [gochiBillShopMessages, setGochiBillShopMessages] = useState([]);
  const [couponShopMessages, setCouponShopMessages] = useState([]);

  useEffect(() => {
    getListMenus();
    props.showGochiBillShopMessage && getGochiBillShopMessages();
    !props.hideShopInfo && getCouponShopMessages();
  }, [shopId]);

  const windowSize = useWindowSize();
  const innerWidth = windowSize[0];

  function getListMenus() {
    GiftService.getListMenuOfShop({ shopId })
      .then((response) => {
        if (response.status === 200) {
          setListMenus(response.data.list);
          setMenuSituations(response.data.menuSituations);
          setMenuUserTargets(response.data.menuUserTargets);
          setPriceRanges(response.data.priceRanges);
        } else if (response.status === 404) {
          // TODO
        }
      })
      .catch((e) => {
        console.error("GET LIST MENUS ERROR: ", e);
      });
  }

  function getGochiBillShopMessages() {
    ShopService.getGochiBillShopMessages(shopId, 1, 15)
      .then((response) => {
        if (response.status === 200) {
          setGochiBillShopMessages(response.data.list);
        } else if (response.status === 404) {
          // TODO
        }
      })
      .catch((e) => {
        console.error("GET LIST GOCHI BILL SHOP MESSAGES ERROR: ", e);
      });
  }

  function getCouponShopMessages() {
    GiftService.getListCouponMessagesOfShop({
      page: 1,
      perPage: 3,
      shopId,
    })
      .then((response) => {
        if (response.status === 200) {
          setCouponShopMessages((old) => response.data.list);
        }
      })
      .catch((e) => {
        console.error("GET LIST COUPON MESSAGES ERROR: ", e);
      });
  }

  const shopDetails = props.shopDetails;

  useEffect(() => {
    const googleMapFrame = document.getElementById("google-map");
    const url = `https://maps.google.co.jp/maps?output=embed&q=${
      shopDetails.prefectures ? shopDetails.prefectures + " " : ""
    }${shopDetails.city ? shopDetails.city + " " : ""}${
      shopDetails.address ? shopDetails.address + " " : ""
    }${shopDetails.building || ""}`;
    console.log("Google maps url:", url);
    (
      googleMapFrame?.contentWindow || googleMapFrame?.documentWindow
    ).location.replace(url);
  }, [shopDetails]);

  return (
    <>
      {!props.hideShopInfo && couponShopMessages?.length > 0 && (
        <section className="section">
          <div className="section-header">
            <div className="heading-lg">
              <h2 className="title">最近のコメント</h2>
            </div>
            <Link
              to={`/shopdetails/${shopId}/comments`}
              className="link only-pc"
            >
              最近のコメントもっと見る
            </Link>
          </div>
          <div className="section-body">
            <div className="card-group card-group-comment">
              {couponShopMessages.map((item) => (
                <div
                  key={item.shopMessageId}
                  className="card card-comment card-elipsis"
                  // href="#"
                >
                  <p className="comment pre-line">
                    {/* <span className="caution">最大120文字</span> */}
                    {item.shopMessage?.length >= 95
                      ? item.shopMessage?.slice(0, 94) + "…"
                      : item.shopMessage}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="section-footer text-right under-tab">
            <Link to={`/shopdetails/${shopId}/comments`} className="link">
              最近のコメントもっと見る
            </Link>
          </div>
        </section>
      )}
      {props.showListMenu && (
        <section className={listMenus !== null ? "section" : "section loading"}>
          <div className="section-header">
            <div className="heading-lg">
              <h2 className="title">{shopDetails.shopName}でプレゼント・ギフトで贈れる商品・お食事券</h2>
            </div>
            <a
              href={`/invite?shopId=${shopId}`}
              className="link"
              target="_blank"
              rel="noopener noreferrer"
            >
              金額を指定してギフト（お値段ごち）を贈る
            </a>
          </div>
          <div className="section-body">
            <div className="products-group mt-20">
              {listMenus?.map((item) => (
                <MenuInfoShop key={item.menuId} menu={item} />
              ))}
            </div>
          </div>
        </section>
      )}
      {!props.hideShopInfo && (
        <InfoBox
          loading={!!shopDetails.shopId}
          prefectures={shopDetails.prefectures}
          city={shopDetails.city}
          genre={shopDetails.genre}
          shopName={shopDetails.shopName}
          priceRanges={priceRanges}
          menuSituations={menuSituations}
          menuUserTargets={menuUserTargets}
        />
      )}
      {gochiBillShopMessages.length > 0 && (
        <section className="section">
          <div className="section-header">
            <div className="heading-lg">
              <h2 className="title">「{shopDetails.shopName}」への応援メッセージ</h2>
            </div>
          </div>
          <div className="section-body">
            <div className="slider-coment" id="slider-coment">
              {gochiBillShopMessages.filter(
                (item) => item.showShopMessage === 1
              ).length <= getSlidePerView(innerWidth, swiperConfigs.comment) ? (
                <>
                  <div
                    className="slider-coment-container disabled"
                  >
                    <div className="swiper-wrapper">
                      {gochiBillShopMessages
                        .filter((item) => item.showShopMessage === 1)
                        .map((item) => (
                          <div
                            className="swiper-slide"
                            key={item.shopMessageId}
                          >
                            <div className="card card-comment card-elipsis">
                              <p className="comment pre-line">
                                {/* <span className="caution">最大120文字</span> */}
                                {item.shopMessage?.length >= 120
                                  ? item.shopMessage?.slice(0, 119) + "…"
                                  : item.shopMessage}
                              </p>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Swiper
                    className="slider-coment-container"
                    {...swiperConfigs.comment}
                  >
                    <div className="swiper-wrapper">
                      {gochiBillShopMessages
                        .filter((item) => item.showShopMessage === 1)
                        .map((item) => (
                          <SwiperSlide
                            className="swiper-slide"
                            key={item.shopMessageId}
                          >
                            <div className="card card-comment card-elipsis">
                              <p className="comment pre-line">
                                {/* <span className="caution">最大120文字</span> */}
                                {item.shopMessage?.length >= 120
                                  ? item.shopMessage?.slice(0, 119) + "…"
                                  : item.shopMessage}
                              </p>
                            </div>
                          </SwiperSlide>
                        ))}
                    </div>
                  </Swiper>
                  <SliderPagination />
                </>
              )}
            </div>
          </div>
        </section>
      )}

      {/* {props.isShopDetailScreen && shopDetails.gochiTipUrl && (
        <section className="gochi-tip mt-20">
          <div className="d-flex flex-direction-row-reverse">
            <div className="mr-15">
              <img src="/assets/img/icon/external-link-icon.svg" loading="lazy" alt="" />
              <a className="fs-11 font-weight-bold link ml-5" href="https://gochi-tip.com/" target="_blank" rel="noopener noreferrer">ごちっぷとは</a>
            </div>
          </div>
          <a className="btn-group btn-gochi-tip-div" href={shopDetails.gochiTipUrl} target="_blank" rel="noopener noreferrer">
            <div className="btn-gochi-tip">
              <img src="/assets/img/shop-detail/gochi-tip-heart.svg" loading="lazy" alt="" />
              <div className="fs-14 text-black ml-5">でお店にチップを贈る</div>
            </div>
          </a>
        </section>
      )} */}
      
      <section className={shopDetails.shopId ? "section" : "section loading"}>
        <div className="section-header">
          <div className="heading-lg">
            <h2 className="title">店舗詳細</h2>
          </div>
        </div>
        <div className="section-body">
          <dl className="list-information">
            <div className="list-information-item">
              <dt className="title">店舗名</dt>
              <dd className="text">
                {shopDetails.shopName}
                {shopDetails.shopNameKana && `（${shopDetails.shopNameKana}）`}
              </dd>
            </div>
            <div className="list-information-item">
              <dt className="title">住所</dt>
              <dd className="text">
                {`${shopDetails.prefectures || ""} ${shopDetails.city || ""} ${
                  shopDetails.address || ""
                } ${shopDetails.building || ""}`}
              </dd>
            </div>
            <div className="list-information-item">
              <dt className="title">TEL</dt>
              <dd className="text">
                <a href={`tel:${shopDetails.phone}`}>{shopDetails.phone}</a>
              </dd>
            </div>
            <div className="list-information-item">
              <dt className="title">最寄り駅</dt>
              <dd className="text">{shopDetails.nearestStation}</dd>
            </div>
            <div className="list-information-item">
              <dt className="title">アクセス</dt>
              <dd className="text">{shopDetails.access}</dd>
            </div>
            <div className="list-information-item">
              <dt className="title">ジャンル</dt>
              <dd className="text">{shopDetails.genre?.genreName}</dd>
            </div>
            <div className="list-information-item">
              <dt className="title">定休日</dt>
              <dd className="text">{shopDetails.holiday}</dd>
            </div>
            <div className="list-information-item">
              <dt className="title">価格帯</dt>
              <dd className="text">
                <dd className="text">
                  <ul className="list-inline">
                    <li>{shopDetails.priceRange}</li>
                  </ul>
                </dd>
              </dd>
            </div>
            <div className="list-information-item">
              <dt className="title">禁煙/喫煙</dt>
              <dd className="text">{shopDetails.smoking}</dd>
            </div>
            <div className="list-information-item">
              <dt className="title">座席</dt>
              <dd className="text">{shopDetails.numberSit}</dd>
            </div>
            <div className="list-information-item">
              <dt className="title">駐車場</dt>
              <dd className="text">{shopDetails.parking}</dd>
            </div>
            <div className="list-information-item">
              <dt className="title">タグ</dt>
              <dd className="text">
                <ul className="list-inline">
                  {shopDetails.shopTag?.map((item) => (
                    <li key={item.shopTagId}>
                      <Link
                        to={`/products?${queryString.stringify({
                          keyword: item.tagName && (item.tagName.indexOf("#") === 0 || item.tagName.indexOf("＃") === 0) ? (item.tagName.indexOf("#") === 0 ? item.tagName.replace("#", '') : item.tagName.replace("＃", '') ) : item.tagName,
                        })}`}
                      >
                        {item.tagName}
                      </Link>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
            <div className="list-information-item">
              <dt className="title">SNS</dt>
              <dd className="text">
                <ul className="list-inline">
                  {shopDetails.snsTwitter && (
                    <li>
                      <a
                        href={shopDetails.snsTwitter}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Twitter
                      </a>
                    </li>
                  )}
                  {shopDetails.snsFacebook && (
                    <li>
                      <a
                        href={shopDetails.snsFacebook}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Facebook
                      </a>
                    </li>
                  )}
                  {shopDetails.snsInstagram && (
                    <li>
                      <a
                        href={shopDetails.snsInstagram}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Instagram
                      </a>
                    </li>
                  )}
                </ul>
              </dd>
            </div>
            <div className="list-information-item">
              <dt className="title">公式サイト</dt>
              <dd className="text">
                {shopDetails.webPage && (
                  <a
                    href={shopDetails.webPage}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {shopDetails.webPage}
                  </a>
                )}
              </dd>
            </div>
          </dl>
        </div>
        <figure className="map-area">
          {/* GoogleMap */}
          <iframe
            id="google-map"
            title={shopDetails.shopName}
            src={""}
            width="100%"
            height="400px"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            allow="geolocation"
          />
        </figure>
      </section>
    </>
  );
}

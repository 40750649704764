import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import {
  replaceString,
  useGenres,
  usePriceRanges,
  useScript,
  useWindowSize
} from "../../../../utils";
import { GiftToModal } from "../../common/GiftToModal";
import { ModalGroup } from "../../common/ModalGroup";
import { SituationModal } from "../../common/SituationModal";
import { Swiper, SwiperSlide } from "swiper/react";
import { getSlidePerView, swiperConfigs } from "../../common/SwiperConfigs";
import { SliderPagination } from "../../common/SliderPagination";
import { Footer } from "../../layout/Footer";
import Header from "../../layout/Header";
import { setModalSituationAction } from "../../../../actions/action_situations";
import { setModalUserTargetAction } from "../../../../actions/action_user_targets";
import queryString from 'query-string';
import { Explanation } from "../../layout/Explanation";

function About(props) {
  useScript("/assets/js/app.js");

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.className = "about";
  }, []);

  const history = useHistory();
  const dispatch = useDispatch();

  const situation = useSelector((state) => state.situation.situation);
  const setModalSituation = (payload) =>
    dispatch(setModalSituationAction(payload));
  const situations = useSelector((state) => state.listSituations);

  const userTarget = useSelector((state) => state.userTarget.userTarget);
  const setModalUserTarget = (payload) =>
    dispatch(setModalUserTargetAction(payload));

  const [prefecture, setPrefecture] = useState("");
  const [city, setCity] = useState("");
  const listPrefectures = useSelector((state) => state.prefectures);
  const listCities = useSelector((state) => state.cities);
  const prefectureId = getPrefectureId(prefecture);
  const [genre, setGenre] = useState("");
  const genres = useGenres();
  const priceRanges = usePriceRanges();
  const [priceRange, setPriceRange] = useState("");
  const [keyword, setKeyword] = useState("");

  const filteredCities = listCities.filter(
    (city) => Number(city.prefCode) === prefectureId
  );

  function handleSearchMenuBySituation(situation) {
    history.push({
      pathname: "/products",
      search: `?situation=${situation}`,
    });
  }

  function getPrefectureId(name) {
    if (name) {
      const prefectureObj = listPrefectures.find(
        (item) => item.prefectureName === name
      );
      if (prefectureObj) return prefectureObj.prefectureId;
    }
    return "";
  }

  function getPriceRangeStrFromObj(range) {
    return `${range.name && range.name.toLocaleString()}`;
  }

  function handleSearch() {
    const params = [];
    if (prefecture) params.push(`prefecture=${prefecture}`);
    if (city) params.push(`city=${city}`);
    if (situation) params.push(`situation=${situation}`);
    if (genre) params.push(`genre=${genre}`);
    if (priceRange) params.push(`prices=${priceRange}`);
    if (userTarget) params.push(`who=${userTarget}`);
    if (keyword) params.push(queryString.stringify({keyword}));

    const query = params.join("&");
    history.push({
      pathname: "/products",
      search: `?${query}`,
    });
  }

  const windowSize = useWindowSize();
  const innerWidth = windowSize[0];

  return (
    <>
      <MetaTags>
        <title>ごちめしとは｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content="ごちめしとはページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content="ごちめしとは｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta name="twitter:description" content="ごちめしとはページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:image" content={window.location.origin + "/assets/img/common/ogp.png"}  />
        <meta name="twitter:site" content="@gochi_meshi" />
        <meta name="twitter:creator" content="@gochi_meshi" />
        <meta name="twitter:image:width" content="300" />
        <meta name="twitter:image:height" content="300" />
        <meta property="og:site_name" content="ごちめしとは｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:title" content="ごちめしとは｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={window.location.origin + "/assets/img/common/ogp.png"} />
        <meta property="og:description" content="ごちめしとはページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
      </MetaTags>
      <Header />

      <main>
        <div className="breadcrumb">
          <div className="container">
            <ol className="breadcrumb-list">
              <li>
                <a href="/">ごちめし</a>
              </li>
              <li>
                <span>ごちめしとは</span>
              </li>
            </ol>
          </div>
        </div>
        <section className="jumbotron">
          <img
            className="bg"
            src="/assets/img/about/bg-about.jpg"
            loading="lazy"
            alt=""
          />
          <div className="container">
            <div className="jumbotron-header">
              <h2 className="heading">
                <img
                  src="/assets/img/about/about-heading.png"
                  className="img"
                  width="521.84"
                  height="431.61"
                  alt="ごちめしとは gift for you"
                  loading="lazy"
                />
              </h2>
              <p className="lead">
                  飲食店のメニューを<mark className="text-mark">プレゼント</mark><br />
                  できるサービス「<mark className="text-mark">ごちめし</mark>」
              </p>
            </div>
            <div className="jumbotron-body">
                <p>
                    「ごちめし」は、人から人へ「ありがとう」「がんばってね」「応援しているよ」<br />
                    などの様々な想いを、スマートかつスピーディーに「ごちる」という形に<br />
                    変えて届けることができるサービスです
                </p>
            </div>
          </div>
          {/* /.container */}
        </section>
        <section className="section">
          <div className="hr-dot-illust" role="separator">
            <img
              src="/assets/img/about/bg-about-example-heading.png"
              className="img"
              loading="lazy"
              alt=""
            />
          </div>
          <div className="container">
            <div className="section-header section-header-column text-center">
              <div className="heading-lg">
                <h2 className="title">
                  例えば…こんなプレゼントやギフトを贈ることができます
                </h2>
              </div>
              <p className="text-bold">ごちめしで贈ることのできる商品</p>
            </div>
            <div className="section-body" id="situation">
              {situations.length <= getSlidePerView(innerWidth, swiperConfigs.situation) ? (
              <div className="situation disabled" id="sliderProducts">
              <div className="swiper-wrapper">
                {situations &&
                  situations.length > 0 &&
                  situations.map((item, key) => {
                    let index = `situation-${item.menuSituationId}-${key}`;
                    return (
                      <a
                        href
                        className="card swiper-slide"
                        key={index}
                        onClick={(e) =>{
                          e.preventDefault();
                          handleSearchMenuBySituation(item.menuSituationName)
                        }

                        }
                      >
                        <div className="card-thumbnail">
                          <div className="thumbnail">
                            <img
                              src={item.menuSituationImage}
                              className="img"
                              loadin="lazy"
                              alt={item.menuSituationName}
                            />
                          </div>
                        </div>
                        <div className="card-header heading-xs">
                          <h3 className="title">{item.menuSituationName}</h3>
                        </div>
                        <div className="card-body over-pc">
                          <p>
                            {replaceString(item.menuSituationDescription, 40)}
                          </p>
                        </div>
                      </a>
                    );
                  })}
              </div>
            </div>
              ) : (
                <Swiper className="situation" {...swiperConfigs.situation}>
                <div className="swiper-wrapper">
                  {situations &&
                    situations.length > 0 &&
                    situations.map((item, key) => {
                      let index = `situation-${item.menuSituationId}-${key}`;
                      return (
                        <SwiperSlide
                          className="card swiper-slide"
                          key={index}
                          onClick={() =>
                            handleSearchMenuBySituation(item.menuSituationName)
                          }
                        >
                          <div className="card-thumbnail">
                            <div className="thumbnail">
                              <img
                                src={item.menuSituationImage}
                                className="img"
                                loadin="lazy"
                                alt={item.menuSituationName}
                              />
                            </div>
                          </div>
                          <div className="card-header heading-xs">
                            <h3 className="title">{item.menuSituationName}</h3>
                          </div>
                          <div className="card-body over-pc">
                            <p>
                              {replaceString(item.menuSituationDescription, 40)}
                            </p>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </div>
                <SliderPagination />
              </Swiper>
              )}

              {/* /.swiper-container */}
            </div>
            {/* /.section-body */}
            <div className="section-footer text-right">
              <Link to="/products-purpose/scenes" className="link">
                <span className="only-pc">シチュエーションを</span>もっと見る
              </Link>
            </div>
          </div>
          {/* /.container */}
        </section>
        <div className="section">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSearch();
            }}
            method="POST"
            className="search-area"
          >
            <div className="container">
              <div className="search-header text-center">
                <div className="heading-xl heading-slash search-header-heading">
                  <h1 className="title search-header-heading-title">
                    <span className="search-header-heading-title-first">
                      <em className="text-xxl">70,000</em>点以上の商品から
                    </span>
                    <span className="search-header-heading-title-second">
                      プレゼント・ギフトを探してみる
                    </span>
                  </h1>
                </div>
              </div>
              <div className="search-body">
                <div className="fieldset fieldset-column fieldset-lg">
                  <div className="fieldset-header">
                    <svg className="icon">
                      <use xlinkHref="/assets/img/common/icon.svg#map-marker" />
                    </svg>
                    <div className="title">エリア</div>
                  </div>
                  <div className="fieldset-body">
                    <div className="field">
                      <div className="field field-pill field-sm">
                        <select
                          value={prefecture}
                          onChange={(e) => {
                            setPrefecture(e.target.value);
                            setCity("");
                          }}
                        >
                          <option value="">選択なし</option>
                          {listPrefectures.length > 0 &&
                            listPrefectures.map((item, key) => {
                              return (
                                <option key={key} value={item.prefectureName}>
                                  {item.prefectureName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="field field-pill field-sm">
                        <select
                          value={city}
                          onChange={(e) => {
                            setCity(e.target.value);
                          }}
                        >
                          <option value="">選択なし</option>
                          {filteredCities.length > 0 &&
                            filteredCities.map((item, key) => {
                              return (
                                <option key={key} value={item.cityName}>
                                  {item.cityName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="fieldset fieldset-lg">
                  <div className="fieldset-header">
                    <svg className="icon">
                      <use xlinkHref="/assets/img/common/icon.svg#popper" />
                    </svg>
                    <div className="title">シチュエーション</div>
                  </div>
                  <div className="fieldset-body">
                    <div className="field">
                      <button
                        className="btn btn-black btn-elipsis"
                        id="openSearchSituation"
                        // data-target="modal01"
                        type="button"
                        onClick={() => {
                          setModalSituation(situation);
                        }}
                      >
                        <div id="searchselectedSituation">
                          {situation || "選択"}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="fieldset fieldset-lg">
                  <div className="fieldset-header">
                    <svg className="icon">
                      <use xlinkHref="/assets/img/common/icon.svg#user" />
                    </svg>
                    <div className="title">贈り相手</div>
                  </div>
                  <div className="fieldset-body">
                    <div className="field">
                      <button
                        className="btn btn-black btn-elipsis"
                        id="openSearchGiftTo"
                        // data-target="modal01"
                        type="button"
                        onClick={() => {
                          setModalUserTarget(userTarget);
                        }}
                      >
                        <div id="searchselectedGiftTo">
                          {userTarget || "選択"}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="fieldset fieldset-lg">
                  <div className="fieldset-header">
                    <svg className="icon">
                      <use xlinkHref="/assets/img/common/icon.svg#utensils" />
                    </svg>
                    <div className="title">ジャンル</div>
                  </div>
                  <div className="fieldset-body">
                    <div className="field field-pill field-md">
                      <select
                        onChange={(e) => setGenre(e.target.value)}
                        value={genre}
                      >
                        <option value="">選択なし</option>
                        {genres.map((item) => (
                          <option key={item.genreId} value={item.menuGenreName}>
                            {item.menuGenreName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="fieldset fieldset-lg">
                  <div className="fieldset-header">
                    <svg className="icon">
                      <use xlinkHref="/assets/img/common/icon.svg#yen-sign" />
                    </svg>
                    <div className="title">予算</div>
                  </div>
                  <div className="fieldset-body">
                    <div className="field field-pill field-md">
                      <select
                        onChange={(e) => setPriceRange(e.target.value)}
                        value={priceRange}
                      >
                        <option value="">選択してください</option>
                        {priceRanges.map((item, key) => (
                          <option key={key} value={item.name}>
                            {getPriceRangeStrFromObj(item)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="fieldset fieldset-lg">
                  <div className="fieldset-header">
                    <svg className="icon">
                      <use xlinkHref="/assets/img/common/icon.svg#search" />
                    </svg>
                    <div className="title">キーワード</div>
                  </div>
                  <div className="fieldset-body">
                    <div className="field field-pill field-lg">
                      <input
                        value={keyword}
                        type="text"
                        placeholder="キーワード"
                        onChange={(e) => setKeyword(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="fieldset fieldset-submit under-tab">
                  <button type="button"  onClick={handleSearch} className="btn btn-submit search-btn">
                    検索する
                  </button>
                </div>
                {/* /.fieldset */}
              </div>
              {/* /.search-body */}
              <div className="search-footer over-pc">
                <button className="btn" type="button" onClick={handleSearch}>
                  検索する
                </button>
              </div>
              {/* /.search-footer */}
            </div>
            {/* /.container */}
          </form>
        </div>
        {/* /.section */}
        <section className="section">
          <div className="container">
            <div className="section-body">
              <div className="layout-group">
                <div className="layout">
                  <figure className="figure">
                    <picture>
                      <source
                        media="(max-width: 660px)"
                        srcSet="/assets/img/about/img-about-howto-01-sp.png"
                        width={320}
                      />
                      <img
                        src="/assets/img/about/img-about-howto-01-pc.png"
                        className="img"
                        width={465}
                        height={600}
                        alt=""
                      />
                    </picture>
                  </figure>
                </div>
                {/* /.layout */}
                <div className="layout d-flex flex-direction-column justify-content-between">
                  <figure className="figure">
                    <picture>
                      <source
                        media="(max-width: 660px)"
                        srcSet="/assets/img/about/img-about-howto-02-sp.png"
                        width={320}
                      />
                      <img
                        src="/assets/img/about/img-about-howto-02-pc.png"
                        className="img"
                        width={465}
                        height={500}
                        loadin="lazy"
                        alt=""
                      />
                    </picture>
                  </figure>
                  <Link
                    to="/howto"
                    className="btn btn-rect btn-xl btn-black btn-fluid mt-20"
                  >
                    ごちめしの使い方
                  </Link>
                </div>
                {/* /.layout */}
              </div>
              {/* /.layout-group */}
            </div>
            {/* /.section-body */}
          </div>
          {/* /.container */}
        </section>
        {/* /.section */}
        <section className="section text-center">
          <div className="container">
            <div className="section-header section-header-column">
              <div className="heading-lg">
                <div className="title">
                  気持ちが連鎖する「ごちめし」の仕組み
                </div>
              </div>
              <p>ギフト・プレゼントを<em className="text-bold">贈る人</em>も<em className="text-bold">贈られる人</em>にも「気持ち」が届きます。</p>
            </div>
            {/* /.section-header */}
            <div className="section-body">
              <figure className="figure">
                <picture>
                  <source
                    media="(max-width: 660px)"
                    srcSet="/assets/img/about/img-about-signup-01-sp.png"
                    width={320}
                  />
                  <img
                    src="/assets/img/about/img-about-signup-01-pc.png"
                    className="img"
                    width={940}
                    loadin="lazy"
                    alt="気持ちの連鎖"
                  />
                </picture>
              </figure>
            </div>
            {/* /.section-body */}
            <div className="section-footer">
              <Link to="/signup" className="btn btn-rect btn-black btn-xl">
                会員登録してギフトを贈る
              </Link>
            </div>
            {/* /.section-footer */}
          </div>
          {/* /.container */}
        </section>
        {/* /.section */}
        <section className="section">
          <div className="container">
            <div className="section-header section-header-column">
              <div className="heading-lg">
                <div className="title">プレゼント・ギフトの選び方も豊富</div>
              </div>
              <p>
                ごちめしは様々な選択肢でギフト・プレゼントを選ぶことが可能です。
              </p>
            </div>
            <div className="section-body slider-choice">
              <Swiper {...swiperConfigs.choice}>
                <div className="swiper-wrapper">
                  <SwiperSlide
                    className="card card-choice swiper-slide"
                    href="/products-purpose/prices"
                    tag="a"
                  >
                    <div className="card-thumbnail">
                      <div className="thumbnail">
                        <img
                          src="/assets/img/about/img-scene-budget.png"
                          className="img"
                          loadin="lazy"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="card-header heading-xs card-header-center">
                      <h3 className="title">
                        一人5,000円くらいの予算で
                        <br />
                        プレゼントを探したい！
                      </h3>
                    </div>
                    <div className="card-footer">
                      <div className="btn-group">
                        <div className="btn btn-rect btn-fluid">
                          予算から探す
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide
                    className="card card-choice swiper-slide"
                    href="/products-purpose/genres"
                    tag="a"
                  >
                    <div className="card-thumbnail">
                      <div className="thumbnail">
                        <img
                          src="/assets/img/about/img-scene-genre.png"
                          className="img"
                          loadin="lazy"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="card-header heading-xs card-header-center">
                      <h3 className="title">
                        美味しいイタリアンのコースを
                        <br />
                        贈りたい！
                      </h3>
                    </div>
                    <div className="card-footer">
                      <div className="btn-group">
                        <div className="btn btn-rect btn-fluid">
                          ジャンルから探す
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide
                    className="card card-choice swiper-slide"
                    href="/products-purpose/scenes"
                    tag="a"
                  >
                    <div className="card-thumbnail">
                      <div className="thumbnail">
                        <img
                          src="/assets/img/about/img-scene-situation.png"
                          className="img"
                          loadin="lazy"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="card-header heading-xs card-header-center">
                      <h3 className="title">
                        結婚記念日にぴったりのお店
                        <br />
                        でプレゼントを探したい！
                      </h3>
                    </div>
                    <div className="card-footer">
                      <div className="btn-group">
                        <div className="btn btn-rect btn-fluid">
                          シチュエーションから探す
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide
                    className="card card-choice swiper-slide"
                    href="/products-purpose/who"
                    tag="a"
                  >
                    <div className="card-thumbnail">
                      <div className="thumbnail">
                        <img
                          src="/assets/img/about/img-scene-person.png"
                          className="img"
                          loadin="lazy"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="card-header heading-xs card-header-center">
                      <h3 className="title">
                        上司に贈るギフトはどんな
                        <br />
                        お店がいいんだろう？
                      </h3>
                    </div>
                    <div className="card-footer">
                      <div className="btn-group">
                        <div className="btn btn-rect btn-fluid">
                          送り相手から探す
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </div>
                <SliderPagination />
              </Swiper>
            </div>
          </div>
        </section>

        <Explanation />
      </main>

      <Footer />

      <ModalGroup>
        <SituationModal />
        <GiftToModal />
      </ModalGroup>
    </>
  );
}

export default About;

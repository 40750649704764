import React from "react";
import { Link } from "react-router-dom";

const NO_IMAGE = require("../../../assets/img/icons/square_blank_gray.png");

export function GenreInfoShop(props) {
  const genreImage = props.genre?.genreImageUrl ? props.genre?.genreImageUrl : NO_IMAGE;

  return (
    <article className="genru-item">
      <a
        className="genru-link"
        href={`/mogugori/menuSearch?keyword=${props.genre.genreName}`}
      >
        <div className="genru-thumbnail">
          <img src={genreImage} alt="" loading="lazy" />
        </div>
        <div className="btn-sm btn-rect btn-fluid btn-genres">
          <p className="text-name-genres">{props.genre.genreName}</p>
          
        </div>
      </a>
    </article>
  );
}

import React from "react";
import { Card } from "react-bootstrap";
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const parseDate = (date) => {
    if (date === null) {return {
        day: 0,
        month: 0,
        year: 0
    };}
    else {
        const ret = new Date(date);
        let day = ret.getDate();
        let month = ret.getMonth() + 1;
        if (month < 10) {
          month = `0${month}`;
        }
        if (day < 10) {
          day = `0${day}`;
        }
        return {
            day: day,
            month: month,
            year: ret.getYear()
        };
    }
} 

const Coupons = (props) => {
    const totalQuantity = props.coupons && props.coupons.length;
    let usedCouponCount = 0;
    let i = totalQuantity + 1;
    const usedCoupons = props.coupons && props.coupons.map((coupon) => {
        if (coupon.useDate) {
            i--;
            usedCouponCount++;
            const useDate = parseDate(coupon.useDate);
            return (
                <div className="div-coupon" key={coupon.couponId}>
                    <i className="icon-used-coupon"></i>
                    <div className="text-green fs-12 text-center">{useDate.month + '/' + useDate.day}</div>
                </div>
            );
        } else
            return null;
    });
    const coupons = props.coupons && props.coupons.map((coupon) => {
        if ((!(coupon.useDate)) && coupon.amount) {
            i--;
            return (            
            <div className="div-coupon" key={coupon.couponId}>
                <div className="normal-coupon" />
                <span className="fs-14 clr-666666 coupon-id">{i}</span>
            </div>);
        } else
            return null;
    });
    const freeCoupons = props.coupons && props.coupons.map((coupon) => {
        if (!(coupon.useDate || coupon.amount)) {
            i--;
        return (
            <div className="div-coupon" key={coupon.couponId}>
                <div className="free-coupon"></div>
                <span className="fs-14 clr-666666 coupon-id">{i}</span>
                <span className="free-coupon-txt">{props.t('pages.menu.Free')}</span>
            </div>
        )} else
            return null;
    });


    const gochiExpired = props.gochiExpired && props.gochiExpired;
    return (
        <Card body className="ex-border-none border-radius-5">
            <div className="tickets-container">
                {usedCoupons}
                {coupons}
                {freeCoupons}
            </div>
            <div className="div-remain-coupon ml-auto mr-auto">
                <i className="svg-ticket"></i>
                <span className="remain-coupon-txt">{props.t('pages.menu.Remaining')}
                    <span className="text-green"><span className="fs-44">{totalQuantity - usedCouponCount}</span>{props.t('pages.login.sheet')}</span>
                     / {totalQuantity}{props.t('pages.login.sheet')}
                </span>
            </div>

            <div className="mt-4">
              {/* Expired date */}
              <div className="font-weight-bold clr-000000">{props.t('pages.tome.Ticket expiration date menuCombo')}</div>
              <Card className="fs-18 align-center mt-1" style={{height:53}}>
                <span className="clr-53a79f font-weight-bold">
                    {moment(gochiExpired).format(`YYYY${props.t('other.year')}MM${props.t('other.month')}DD${props.t('other.day')}${props.t('other.until')}`)}
                </span>
              </Card>
            </div>

            <div className="fs-13 mt-2 text-justify">
            ※使用期限が過ぎたものは無効となります。規約上、返金等はできませんのでご注意ください。
            <br />
            {props.t('pages.menu.Some tickets have an expiration date of 6 months or less')}
            <br/>
            {props.t('pages.menu.Check this screen until the state of emergency and refrain from going out are lifted')}
            </div>
        </Card>
    );
}

export default (withTranslation()(Coupons));
import React from "react";
import MetaTags from 'react-meta-tags';
import moment from 'moment';
import { Link } from "react-router-dom";
import { CustomPagination } from "../../common/Pagination";
import { DishMenu } from "./DishMenu";

export function ReceiveGochi(props) {

  // console.log('props.total', props.totalResult);
  // console.log('props.searchResults', props.searchResults);

  function handleBuyMenu(menuId, shopId) {
    if (menuId) {
      window.open("/menu?id=" + menuId, '_blank');
    } else if (shopId) {
      window.open("/invite?shopId=" + shopId, '_blank');
    }
  }

  function handleGotoInviteURL(inviteURL) {
    window.open(`${inviteURL}`, '_blank');
  }

  return (
    <>
      <MetaTags>
        <title>自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content="自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta name="twitter:description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:image" content="/assets/img/common/ogp.png" />
        <meta property="og:site_name" content="自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:title" content="自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content="/assets/img/common/ogp.png" />
        <meta property="og:description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
      </MetaTags>

      <hr className="mt-30" />
      <div className="profile-history-area" id="scrollToElementId">
        <div className={`profile-history ${props.loadingGochiTome ? 'loading': ''}`}>
          <nav className="nav">
            <div className="fieldset">
              <div className="field field-pill field-md">
                <select
                  value={props.filterGochi}
                  onChange={(e) => {
                    props.onFilterListGochis(e);
                  }}
                >
                  <option value="all">全てのチケット</option>
                  <option value="usable">利用可能チケット</option>
                  <option value="unusable">利用済みチケット</option>
                </select>
              </div>
            </div>
          </nav>
          <div className="products-group products-group-column">
            {props.searchResults.length > 0 && props.searchResults.map((item, key) => {
              let index = `${item.gochiBillId}-${key}`;
              var menuId = item.menuId ? item.menuId : null;
              var isWishList = item.menu && item.menu.isWishList;
              var isFavourite = item.menu && item.menu.isFavourite;
              var ticketFree = item.couponFree ? item.couponFree : 0;
              var comboDescription = item.menu?.menuComboDetail?.quantityFreeDes;
              var image = item.gochiBillImage && item.gochiBillImage.length > 0 && item.gochiBillImage[0].image ? 
                              item.gochiBillImage[0].image : props.NO_IMAGE_MENU;
              var shopName = item.shop?.shopName ? item.shop.shopName : null;
              var groupName = item.groupInfo?.groupName ? item.groupInfo.groupName : null;
              var menuName = item.menu?.menuName ? item.menu.menuName : null;
              var menuPriceName = "お値段ごち";
              var isMenuPrice = item.menuId ? false : true;
              var gochiRegisterName = item.gochiRegister?.displayName ? item.gochiRegister.displayName : '';
              var gochiCount = item.gochiCount ? item.gochiCount : 0;
              var includedTaxPrice = typeof item.menu?.includedTaxPrice !== 'undefined' ? item.menu.includedTaxPrice : null;
              var amount = item.amount ? item.amount : 0;
              var taxAmount = item.taxAmount ? item.taxAmount : 0;
              var couponCount = item.couponCount ? item.couponCount : 0;
              var includedTaxPriceOfMenuPrice = Math.round((amount + taxAmount)/item.couponCount);
              var isMenuCombo = item.menu && item.menu.menuCombo === 1 ? true : false;
              var includedTaxPriceCombo = item.menu?.menuComboDetail?.includedTaxPriceCombo ? item.menu.menuComboDetail.includedTaxPriceCombo : null;
              const menuLimitTotal = item?.menu?.menuLimitTotal === 1;

              var today = new Date();
              var gochiExpired =  moment(item.gochiExpired, 'YYYY-MM-DD').toDate();
              today.setHours(0,0,0,0);
              gochiExpired.setHours(0,0,0,0);
              var isExpiredGochi = gochiExpired < today ? true : false;
              var usableGochi = (gochiCount > 0 && gochiExpired >= today) ? true : false;
              var isAllCouponsUsed = item.isAllCouponsUsed;

              var dishInfo = {
                menuId,
                isWishList,
                isFavourite,
                image,
                menuName,
                menuPriceName,
                isMenuCombo,
                ticketFree,
                comboDescription,
                isMenuPrice
              };

              return (
                <article className="products" key={index}>
                  <div className="products-digest">
                    <DishMenu
                      dishInfo={dishInfo}
                      handleUpdate={props.handleUpdate}
                    />
                    <div className="shop-header-group">
                      <div className="shop-header">
                        <div className="text-sm text-gray">
                          {shopName || groupName}
                        </div>
                        <h2 className={`title ${gochiCount === 0 ? '' : 'text-bold'}`}>
                          {menuName || menuPriceName}
                          {isAllCouponsUsed === true ? (
                            <span className="text-red text-bold text-sm ml-5">
                              利用済み
                            </span>
                          ) : (
                            <>
                              {isExpiredGochi && (
                                <span className="text-red text-bold text-sm ml-5">
                                  期限切れ
                                </span>
                              )}
                            </>
                          )}
                        </h2>
                      </div>
                      <hr />
                      <div className="price-area">
                        <span className="unit">¥</span>
                        <span className="num">
                          {isMenuCombo && !menuLimitTotal && (
                            <>
                              {includedTaxPriceCombo && includedTaxPriceCombo.toLocaleString()} x {couponCount}枚
                              {ticketFree !== 0 ? ` (おまけ${ticketFree}枚含)` : ''}
                            </>
                          )}
                          {!isMenuCombo && !menuLimitTotal && (
                            <>
                              {includedTaxPrice ? includedTaxPrice.toLocaleString() : includedTaxPriceOfMenuPrice.toLocaleString()}{` x `}{couponCount}
                            </>
                          )}
                          {menuLimitTotal && `${includedTaxPrice?.toLocaleString()} x ${1}`}
                        </span>
                        {/* <span className="dash">〜</span> */}
                      </div>
                      {isMenuCombo && (
                        <div className="badge badge-lg badge-red">回数券</div>
                      )}
                    </div>
                  </div>
                  <div className="products-info">
                    <dl className="list-description text-sm">
                      <div className="list-description-item">
                        <dt className="title">購入日</dt>
                        <dd className="text">{item.createdAt ? props.formatDate(item.createdAt) : ''}</dd>
                      </div>
                      <div className="list-description-item">
                        <dt className="title">送り先</dt>
                        <dd className="text">{gochiRegisterName}</dd>
                      </div>
                      <div className="list-description-item">
                        <dt className="title">詳細</dt>
                        <dd className="text">
                          <span className="btn-open-modal-green"
                            // onclick="openMenuModal('5342')"
                            onClick={() => props.handleShowDetailGochi(item.gochiBillId, 'gochiTome')}
                          >
                            詳細
                          </span>
                        </dd>
                      </div>
                    </dl>
                    <div className="products-btn">
                      <div className="btn-group">
                        <button
                          className="btn btn-rect btn-sm"
                          type="button"
                          onClick={() => {
                            if (usableGochi === false) {
                              handleBuyMenu(item.menuId, item.shopId);
                            } else {
                              handleGotoInviteURL(item.inviteUrl);
                            }
                          }}
                        >
                          {usableGochi ? '利用する' : '購入する'}
                        </button>
                      </div>
                    </div>
                  </div>
                </article>
              );
            })}
          </div>
          {props.searchResults.length > 0 && (
            <CustomPagination
              count={Math.ceil(props.totalResult / props.limit)}
              page={props.page}
              onChange={props.handleChangePage}
            />
          )}
        </div>
      </div>
    </>
  );
}

import React from "react";
import MetaTags from 'react-meta-tags';
// import { setMetaDescriptionAndKeywords } from "../../../../utils";
import { CustomPagination } from "../../common/Pagination";

export function Receipt(props) {

  return (
    <>
      <MetaTags>
        <title>領収書一覧｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content="領収書一覧ページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content="領収書一覧｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta name="twitter:description" content="領収書一覧ページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:image" content="/assets/img/common/ogp.png" />
        <meta property="og:site_name" content="領収書一覧｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:title" content="領収書一覧｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content="/assets/img/common/ogp.png" />
        <meta property="og:description" content="領収書一覧ページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
      </MetaTags>

      <hr className="mt-30" />
      <div className={`profile-receipt ${props.loadingReceipt ? 'loading': ''}`} id="scrollToElementId">
        <div className="profile-receipt-header">
          <div>
            <p>
              チェックした商品の領収書をまとめて発行できます。
            </p>
          </div>
          <div className="field field-pill field-md">
            <select
              onChange={(e) => {
                props.onSortListPastOrders(e);
              }}
            >
              <option value="lastAddTimeDesc">最新順</option>
              <option value="lastAddTimeAsc">日付が古い順</option>
            </select>
          </div>
        </div>
        {props.searchResults.length > 0 && (
          <>
            <ul className="profile-receipt-list">
              {props.searchResults.map((item, key) => {
                let index = `${item.gochiBillId}-${key}`;
                var shopName = item.shop && item.shop.shopName ? item.shop.shopName : null;
                var groupName = item.groupInfo && item.groupInfo.groupName ? item.groupInfo.groupName : null;
                var menuName = item.menu && item.menu.menuName ? item.menu.menuName : null;
                var menuPriceName = "お値段ごち";
                var chargeAmount = item.chargeAmount ? item.chargeAmount : 0;

                return (
                  <li className="profile-receipt-item" key={index}>
                    <div className="fieldset">
                      <div className="field field-checkbox field-column">
                        <label>
                          <input
                            className="before-icon"
                            name="listBill"
                            type="checkbox"
                            value={item.gochiBillId}
                            onChange={(e) => props.handleInputChange(e)}
                          />
                          <i className="icon" />
                          領収書を発行
                        </label>
                      </div>
                    </div>
                    <dl className="list-description">
                      <div className="list-description-item">
                        <dt className="title">購入日</dt>
                        <dd className="text">{item.createdAt ? props.formatDate(item.createdAt) : ''}</dd>
                      </div>
                      <div className="list-description-item receipt-info">
                        <dt className="title">メニュー</dt>
                        <dd className="text">
                          {shopName || groupName} / {menuName || menuPriceName} / {chargeAmount.toLocaleString()}円
                        </dd>
                      </div>
                      {item.menu?.menuChildType === 5 && (
                        <div className="list-description-item text-red">※初回購入以外のチャージ履歴は記載されません。チャージごとの領収書はご登録のメールアドレスに送付されておりますのでメールボックスをご確認ください</div>
                      )}
                    </dl>
                  </li>
                );
              })}
            </ul>
            <div className="btn-group receipt-btn-group">
              <button
                className="btn btn-rect receipt-btn"
                data-target="modal01"
                type="button"
                onClick={() => {
                  props.handleShowPopupEmail();
                  // openModal('sendEmail');
                }}
              >
                領収書を発行する
              </button>
            </div>
          </>
        )}
        
      </div>

      {props.searchResults.length > 0 && (
        <CustomPagination
          count={Math.ceil(props.totalResult / props.limit)}
          page={props.page}
          onChange={props.handleChangePage}
        />
      )}
    </>
  );
}

import React, { Component } from "react";    
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next';

class GochiComment extends Component { 
	constructor(props) { 
	  	super(props); 
	  	this.state = {
	    	gochiBill: {},  
	  	};
	}  

	componentDidMount(){

	}

    render() {
        const { t } = this.props;

        return (
        	<div className="list-comment">
                {this.props.gochiBill.gochiBillMessage && this.props.gochiBill.gochiBillMessage[0].message ? (
                    <div className="gochi-comment">
                        <div className="gochi-comment-item">
                            <div className="gochi-comment-avatar">
                                {this.props.gochiBill.gochiRegister.userImage ? (
                                    <img className="avatar-icon" src={this.props.gochiBill.gochiRegister && this.props.gochiBill.gochiRegister.userImage[0] && this.props.gochiBill.gochiRegister.userImage[0].image ? this.props.gochiBill.gochiRegister.userImage[0].image : require("../../../assets/img/icons/avatar.png")}  alt={this.props.gochiBill.gochiRegister ? this.props.gochiBill.gochiRegister.displayName : ''} width="18" height="18" />
                                ) : (
                                    <div className="no-avatar">{t('pages.mypage.You')}</div>
                                )}
                            </div>
                            <div className="gochi-comment-text space-pre-line">
                                {this.props.gochiBill.gochiBillMessage[0].message}
                            </div>
                        </div>
                    </div>
                ) : null}
                {this.props.gochiBill.couponMessage && this.props.gochiBill.couponMessage[0].couponMessageId ? (
                    this.props.gochiBill.couponMessage.map(function(item, key){
                        return item.message ? 
                        <div className="gochi-comment" key={key}>
                            <div className="gochi-comment-item">
                                <div className="gochi-comment-avatar">
                                    {item.userInvited && item.userInvited.userImage ? (
                                        <img className="avatar-icon" src={item.userInvited && item.userInvited.userImage ? item.userInvited.userImage : require("../../../assets/img/icons/avatar.png")}  alt={item.userInvited && item.userInvited.displayName ? item.userInvited.displayName : ''} width="18" height="18" />
                                    ) : (
                                        <div className="avatar-text">{item.userInvited && item.userInvited.displayName ? item.userInvited.displayName.substring(0, 1) : ''}</div>
                                    )}
                                </div>
                                <div className="gochi-comment-text space-pre-line">
                                    {item.message}
                                </div>
                            </div>
                        </div>
                        : null
                    })
                ) : null}
            </div>
        ); 
    }
} 

const mapStateToProps = state => ({
	...state
}); 
 
export default connect(mapStateToProps)(withTranslation()(GochiComment)); 

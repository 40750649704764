import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import queryString from "query-string";
import { showError } from "../../../actions/action_error_alert";
import { showLoading } from "../../../actions/action_loading";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import CampaignService from "../../../api/services/CampaignService";
import ShopService from "../../../api/services/ShopService";
import { HTTP_API } from "../../../api/HttpClient";
import CheckActiveCodeSuccess from "./CheckActiveCodeSuccess";

export function CheckActiveCode(props) {
  const { t } = props;
  const match = useRouteMatch();
  const campaignId = match.params.campaignId;
  const queryParam = queryString.parse(window.location.search);
  const activeCode = queryParam.activeCode;
  const [menus, setMenus] = useState([]);
  const [secretCode, setSecretCode] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [shop, setShop] = useState({});

  useEffect(() => {
    sessionStorage.removeItem("emailBookingCampaign");
    sessionStorage.removeItem("secretCode");
    sessionStorage.removeItem("monthBookingCampaign");
    sessionStorage.removeItem("campaignId");
    sessionStorage.removeItem("bookingCampaignFinish");
    checkActiveCode();
  }, []);

  async function checkActiveCode() {
    props.showLoading(true);
    let param = {
      activeCode: activeCode,
    };
    const data = await CampaignService.checkActiveCode(campaignId, param);
    if (data.status === HTTP_API.SUCCESS) {
      const shopData = await ShopService.getDetailShop(data.data.shopId);
      if (shopData.status === HTTP_API.SUCCESS) {
        setShop(shopData.data);
        props.showLoading(false);
        setIsSuccess(true);
        if (data.data.menus === null) {
          setMenus([]);
        } else {
          setMenus(data.data.menus);
        }
        setSecretCode(data.data.secretCode);
      } else {
        props.showLoading(false);
        props.showError(null, shopData.message);
      }
    } else {
      if (data.data.code === "exception.errors.gochi.campaign.not.found") {
        window.location.href = "/404";
      } else if (data.data.code === "exception.errors.gochi.campaign.activecode.invalid") {
        props.showLoading(false);
        props.showError(t('pages.bookingCamaign.campaign_activecode_invalid'), "");
      } else if (data.status === 400 && data.data.code === 'exception.errors.gochi.campaign.total.limited') {
        props.showLoading(false);
        props.showError(t('pages.bookingCamaign.campaign_total_limited', ""));
      } else {
        props.showLoading(false);
        props.showError(t('other.Unexpected error'), "");
      }
    }
  }

  return (
    <>
      {isSuccess ? (
        <CheckActiveCodeSuccess
          menus={menus}
          secretCode={secretCode}
          campaignId={campaignId}
          shop={shop}
        />
      ) : (
        <div className="mt-0">
          <div className="shop-not-avai text-center">
            <img
              src={require("../../../assets/img/icons/gochi_logo_print.png")}
              alt={props.t(
                "other.This feast button cannot be used because the store is not open"
              )}
              width="100%"
              className="image-not-available"
            />
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = () => ({
  showLoading,
  showError,
});

const mapDispatchToProps = (dispatch) => ({
  showError: (title, content) => dispatch(showError(true, title, content)),
  showLoading: (showing) => dispatch(showLoading(showing)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CheckActiveCode));

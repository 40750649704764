import React, { Component } from 'react';
import { connect } from "react-redux";

import { withTranslation } from 'react-i18next';


class PopupRegisConfirm extends Component { 

  render() { 

    return (
        <>
            {this.props.showing && (
                <div className="modal-group" style={{visibility: "visible", opacity: 1, transition: "opacity .15s"}}>
                    <div id="modalMask" className="modal-mask"></div>
                    <div id="appleAccountCaution" className="modal modal-01 is-show">
                        <button className="modal-btn modal-btn-close" onClick={this.props.handleClose} type="button">
                            <svg className="icon">
                                <title>閉じる</title>
                                <use xlinkHref="/assets/img/common/icon.svg#times"></use>
                            </svg>
                        </button>
                        <div className="modal-body">
                            {this.props.content}
                        </div>
                        <div className="modal-footer">
                            <div className="btn-group apple-modal-btn-group">
                                <button id="cancelCreateAccount" className="btn btn-rect btn-cancel" onClick={this.props.handleClose}>{this.props.cancelation ? this.props.cancelation : 'キャンセル'}</button>
                                <button id="agreeCreateAccount" className="btn btn-rect" onClick={this.props.handleConfirm}>{ this.props.confirmation ? this.props.confirmation : '同意して登録' }</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

export default connect(mapStateToProps)(withTranslation()(PopupRegisConfirm));

import React from 'react'; 
import Login from './../components/pages/Login/Login';
import LoginMenu from './../components/pages/Login/LoginMenu';
import LoginConfirm from './../components/pages/Login/LoginConfirm';
import Register from './../components/pages/Register/Register';
import RegisterForm from './../components/pages/Register/RegisterForm';
import RegisterConfirm from './../components/pages/Register/RegisterConfirm';
import RegisterConfirmSMS from './../components/pages/Register/RegisterConfirmSMS';
import RegisterFinish from './../components/pages/Register/RegisterFinish';
import Logout from './../components/pages/Login/Logout';
import NotFound404 from './../components/pages/404/NotFound404';
import Maintain from './../components/pages/Maintain/Maintain';
import InputInvite from './../components/pages/Invite/index';
import InviteFinish from './../components/pages/Invite/InviteFinish';
import InviteConfirm from './../components/pages/Invite/InviteConfirm';
import AlipayQRCode from './../components/pages/Invite/AlipayQRCode';
import WechatpayQRCode from './../components/pages/Invite/WechatpayQRCode';
import PaymentList from './../components/pages/Invite/PaymentList';
import PaymentRegisterInput from './../components/pages/Invite/PaymentRegisterInput';
import SelectFriends from './../components/pages/SelectFriends/index';
import Mypage from './../components/pages/Mypage/index';
import DetailGochi from './../components/pages/Mypage/DetailGochi';
import ToMe from './../components/pages/ToMe/index';
import ToMeDetail from './../components/pages/ToMe/ToMeDetail';
import GochiraeruDetail from './../components/pages/Gochiraeru/GochiraeruDetail';
import QRCode from './../components/pages/QRCode/index';
import QRCodeDetail from './../components/pages/QRCode/QRCodeDetail';
import MenuComboDetail from './../components/pages/Menu/MenuComboDetail';
import GochiUseFinish from './../components/pages/Gochi/GochiUseFinish';
import ForgotPassword from '../components/pages/ForgotPassword/ForgotPassword';
import ForgotPasswordSuccess from '../components/pages/ForgotPassword/ForgotPasswordSuccess';
import GochiUseStaff from './../components/common/GochiUse/GochiUseStaff';
import GochiUseConfirm from './../components/common/GochiUse/GochiUseConfirm';
import { TopPage } from '../components/GiftSite/pages/TopPage/TopPage';
import MenuSearch from '../components/GiftSite/pages/MenuSearch/MenuSearch';
import { ShopSearch } from '../components/GiftSite/pages/ShopSearch/ShopSearch';
import { ShopDetail } from '../components/GiftSite/pages/ShopSearch/ShopDetail';
import { MenuPurposeScenes } from '../components/GiftSite/pages/MenuSearch/MenuPurposeScenes';
import { MenuPurposeGenres } from '../components/GiftSite/pages/MenuSearch/MenuPurposeGenres';
import { MenuPurposePrices } from '../components/GiftSite/pages/MenuSearch/MenuPurposePrices';
import { MenuPurposeWho } from '../components/GiftSite/pages/MenuSearch/MenuPurposeWho';
import { MyPage } from '../components/GiftSite/pages/MyPage/MyPage';
import { ShopComments } from '../components/GiftSite/pages/ShopSearch/ShopComments';
import { MenuDetails } from '../components/GiftSite/pages/MenuDetails/MenuDetails';
import { SpecialList } from '../components/GiftSite/pages/Special/Special.js'
import { SpecialDetail } from '../components/GiftSite/pages/Special/SpecialDetail.js'
import { OtherUser } from '../components/GiftSite/pages/MyPage/OtherUser';
import { Edit } from '../components/GiftSite/pages/MyPage/Edit';
import { AllMagazines } from '../components/GiftSite/pages/Magazine/AllMagazines';
import { MagazinesCategory } from '../components/GiftSite/pages/Magazine/MagazinesCategory';
import { MagazinesTag } from '../components/GiftSite/pages/Magazine/MagazinesTag';
import { MagazineDetail } from '../components/GiftSite/pages/Magazine/MagazineDetail';
import About from '../components/GiftSite/pages/About/About';
import HowTo from '../components/GiftSite/pages/HowTo/HowTo';
import { Giftsite404 } from '../components/GiftSite/pages/404/404';
import { ListNews } from '../components/GiftSite/pages/News/ListNews';
import { NewsDetail } from '../components/GiftSite/pages/News/NewsDetail';
import { Sitemap } from '../components/GiftSite/pages/Sitemap/Sitemap';
import ServerError from '../components/pages/Maintain/ServerError';
import StripeCheckout from './../components/pages/Invite/StripeCheckout';
import GochiBillDetails from '../components/pages/QRCode/GochiBillDetails';
import BookingTicket from '../components/pages/BookingTicket/BookingTicket';
import CheckActiveCode from '../components/pages/BookingTicket/CheckActiveCode';
import HomePageMogugori from '../components/Mogugori/pages/HomePage';
import ShopDetailMogugori from '../components/Mogugori/pages/ShopDetail';
import MenuSearchMogugori from '../components/Mogugori/pages/MenuSearch';
import { GOCHI_KID_PATH } from '../constants';
import HomePageGochiKid from '../components/GochiKid/pages/HomePage';
import GochiKidUse from './../components/common/GochiUse/GochiKidUse';
import DonateDestination from '../components/GochiKid/pages/DonateDestination';
import DonateInput from '../components/GochiKid/pages/DonateInput';
import DonateConfirm from '../components/GochiKid/pages/DonateConfirm';
import DonateComplete from '../components/GochiKid/pages/DonateComplete';
import CampaignRedirect from '../components/GochiKid/pages/CampaignRedirect.js';
import ShopInGroup from './../components/pages/ShopInGroup/index';
import ShopGochiKid from '../components/pages/ShopGochiKid/index.js';
import UserRank from '../components/Mogugori/pages/UserRank.js';

const routes = [
    {
        path : '/gochimeshi-404',
        exact : true,
        main : NotFound404
    },
    {
        path : '/maintain',
        exact : true,
        main : Maintain,
    },
    {
        path : '/server-error',
        exact : true,
        main : ServerError,
    },
    {
        path : '/menu',
        exact : true,
        main : LoginMenu
    }, 
    {
        path : '/signup',
        exact : true,
        main : Register,
        isGiftSite: true
    },  
    {
        path : '/signup/form',
        exact : true,
        main : RegisterForm,
        isGiftSite: true
    },  
    {
        path : '/signup/confirm',
        exact : true,
        main : RegisterConfirm,
        isGiftSite: true
    },    
    {
        path : '/signup/confirm-sms',
        exact : true,
        main : RegisterConfirmSMS,
        isGiftSite: true
    },
    {
        path : '/signup/complete',
        exact : true,
        main : RegisterFinish,
        isGiftSite: true
    },
    {
        path : '/login',
        exact : true,
        main : Login,
        isGiftSite: true
    },
    {
        path : '/login/confirm',
        exact : true,
        main : LoginConfirm,
        isGiftSite: true
    },  
    {
        path : '/logout',
        exact : false,
        main : Logout
    },  
    {
        path : '/invite',
        exact : false,
        main : InputInvite
    }, 
    {
        path : '/select-friends',
        exact : false,
        main : SelectFriends
    }, 
    {
        path : '/invite-confirm',
        exact : false,
        main : InviteConfirm
    },
    {
        path : '/invite-finish',
        exact : false,
        main : InviteFinish
    }, 
    {
        path : '/alipay-qr-code',
        exact : false,
        main : AlipayQRCode
    },
    {
        path : '/wechatpay-qr-code',
        exact : false,
        main : () => <WechatpayQRCode />
    },
    {
        path : '/payment-list',
        exact : false,
        main : PaymentList
    },
    {
        path : '/payment-input',
        exact : false,
        main : PaymentRegisterInput
    },
    {
        path : '/list-shop-group/nagoyameshi',
        exact : false,
        main : ShopInGroup
    },
    ,
    {
        path : '/list-shop-group/:groupId(\\d+)',
        exact : false,
        main : ShopInGroup
    },
    // {
    //     path : '/mypage/gochi',
    //     exact : false,
    //     main : DetailGochi
    // },
    // {
    //     path : '/mypage',
    //     exact : false,
    //     main : Mypage,
    //     isPrivateRoute: true
    // },
    // {
    //     path : '/tome/detail',
    //     exact : false,
    //     main : ToMeDetail,
    //     isPrivateRoute: true
    // },
    // {
    //     path : '/tome',
    //     exact : false,
    //     main : ToMe,
    //     isPrivateRoute: true
    // },
    {
        path : '/gochiuse/staff',
        exact : false,
        main : GochiUseStaff
    },
    {
        path : '/gochiuse/confirm',
        exact : false,
        main : GochiUseConfirm
    },
    {
        path : '/gochiraeru/detail',
        exact : false,
        main : GochiraeruDetail
    },
    {
        path : '/qrCode/detail',
        exact : false,
        main : QRCodeDetail
    },
    {
        path : '/qrCode',
        exact : true,
        main : QRCode
    },
    {
        path : '/menuCombo/detail',
        exact : false,
        main : MenuComboDetail
    },
    {
        path : '/gochiBillDetail',
        exact : false,
        main : GochiBillDetails
    },
    {
        path : '/gochiUse/finish',
        exact : false,
        main : GochiUseFinish
    },
    {
        path : '/ticket-use-complete',
        exact : false,
        main : GochiUseFinish
    },

    {
        path: '/password_reset/complete',
        exact: false,
        main: ForgotPasswordSuccess,
        isGiftSite: true
    },

    {
        path: '/password_reset',
        exact: false,
        main: ForgotPassword,
        isGiftSite: true
    },

    // Gift Site routes

    {
        path: '/',
        exact: true,
        main: TopPage,
        isGiftSite: true
    },
    
    {
        path: '/products',
        exact: false,
        main: MenuSearch,
        isGiftSite: true
    },

    {
        path: '/shops',
        exact: false,
        main: ShopSearch,
        isGiftSite: true
    },

    {
        path: '/shopdetails/:shopId(\\d{1,9})',
        exact: true,
        main: ShopDetail,
        isGiftSite: true
    },

    {
        path: '/products-purpose/scenes',
        exact: false,
        main: MenuPurposeScenes,
        isGiftSite: true
    },

    {
        path: '/products-purpose/genres',
        exact: false,
        main: MenuPurposeGenres,
        isGiftSite: true
    },

    {
        path: '/products-purpose/prices',
        exact: false,
        main: MenuPurposePrices,
        isGiftSite: true
    },

    {
        path: '/products-purpose/who',
        exact: false,
        main: MenuPurposeWho,
        isGiftSite: true
    },

    {
        path: '/mypage',
        exact: true,
        main: MyPage,
        isGiftSite: true,
        isPrivateRoute: true
    },

    {
        path: '/mypage/user/:userId(\\d{1,9})',
        exact: true,
        main: OtherUser,
        isGiftSite: true
    },

    {
        path: '/mypage/edit',
        exact: true,
        main: Edit,
        isGiftSite: true,
        isPrivateRoute: true
    },

    {
        path: '/mypage/follow',
        exact: false,
        main: MyPage,
        isGiftSite: true,
        isPrivateRoute: true
    },

    {
        path: '/mypage/purchase_histories',
        exact: false,
        main: MyPage,
        isGiftSite: true,
        isPrivateRoute: true
    },

    {
        path: '/mypage/payment_informations',
        exact: false,
        main: MyPage,
        isGiftSite: true,
        isPrivateRoute: true
    },

    {
        path: '/mypage/receipt',
        exact: false,
        main: MyPage,
        isGiftSite: true,
        isPrivateRoute: true
    },

    {
        path: '/mypage/mygochi',
        exact: false,
        main: MyPage,
        isGiftSite: true,
        isPrivateRoute: true
    },

    {
        path: '/mypage/mygochi_charge',
        exact: false,
        main: MyPage,
        isGiftSite: true,
        isPrivateRoute: true
    },

    {
        path: '/mypage/mygochi_charge_detail/:gochiChargeId(\\d{1,9})',
        exact: false,
        main: MyPage,
        isGiftSite: true,
        isPrivateRoute: true
    },

    {
        path: '/mypage/mygochi_charge_detail/:gochiChargeId(\\d{1,9})/qrCode',
        exact: false,
        main: MyPage,
        isGiftSite: true,
        isPrivateRoute: true
    },

    {
        path: '/mypage/kodomo_use',
        exact: false,
        main: MyPage,
        isGiftSite: true,
        isPrivateRoute: true,
        hideGochiKid: process.env.REACT_APP_SHOW_GOCHI_KID === "false" || process.env.REACT_APP_SHOW_GOCHI_KID === undefined
    },

    {
        path: '/mypage/kodomo_use/search_shop',
        exact: false,
        main: MyPage,
        isGiftSite: true,
        isPrivateRoute: true,
        hideGochiKid: process.env.REACT_APP_SHOW_GOCHI_KID === "false" || process.env.REACT_APP_SHOW_GOCHI_KID === undefined
    },

    {
        path: '/mypage/kodomo_use/search_shop/:shopId(\\d{1,9})',
        exact: false,
        main: MyPage,
        isGiftSite: true,
        isPrivateRoute: true,
        hideGochiKid: process.env.REACT_APP_SHOW_GOCHI_KID === "false" || process.env.REACT_APP_SHOW_GOCHI_KID === undefined
    },

    {
        path: '/mypage/kodomo_support',
        exact: false,
        main: MyPage,
        isGiftSite: true,
        isPrivateRoute: true,
        hideGochiKid: process.env.REACT_APP_SHOW_GOCHI_KID_DONATE === "false" || process.env.REACT_APP_SHOW_GOCHI_KID_DONATE === undefined
    },

    {
        path: '/mypage/kodomo_support/continue',
        exact: false,
        main: MyPage,
        isGiftSite: true,
        isPrivateRoute: true,
        hideGochiKid: process.env.REACT_APP_SHOW_GOCHI_KID_DONATE === "false" || process.env.REACT_APP_SHOW_GOCHI_KID_DONATE === undefined
    },

    {
        path: '/mypage/kodomo_support/message',
        exact: false,
        main: MyPage,
        isGiftSite: true,
        isPrivateRoute: true,
        hideGochiKid: process.env.REACT_APP_SHOW_GOCHI_KID_DONATE === "false" || process.env.REACT_APP_SHOW_GOCHI_KID_DONATE === undefined
    },

    {
        path : '/kodomo/use',
        exact : false,
        main : GochiKidUse,
        isNoMultiLang: true,
        hideGochiKid: process.env.REACT_APP_SHOW_GOCHI_KID === "false" || process.env.REACT_APP_SHOW_GOCHI_KID === undefined
    },

    {
        path: '/mypage/gochi_subscription',
        exact: false,
        main: MyPage,
        isGiftSite: true,
        isPrivateRoute: true
    },

    {
        path: '/shopdetails/:shopId(\\d{1,9})/comments',
        exact: true,
        main: ShopComments,
        isGiftSite: true
    },

    {
        path: '/shopdetails/:shopId(\\d{1,9}|group-menu)/menu/:menuId(\\d{1,9})',
        exact: true,
        main: MenuDetails,
        isGiftSite: true
    },

    {
      path: '/specials',
      exact: true,
      main: SpecialList,
      isGiftSite: true
    },

    {
      path: '/specials/:slug',
      exact: true,
      main: SpecialDetail,
      isGiftSite: true
    },

    {
      path: '/magazine',
      exact: true,
      main: AllMagazines,
      isGiftSite: true
    },

    {
      path: '/magazine/categories/:categoryId(\\d{1,9})',
      exact: true,
      main: MagazinesCategory,
      isGiftSite: true
    },

    {
      path: '/magazine/tags/:tagId(\\d{1,9})',
      exact: true,
      main: MagazinesTag,
      isGiftSite: true
    },

    {
      path: '/magazine/articles/:postId(\\d{1,9})',
      exact: true,
      main: MagazineDetail,
      isGiftSite: true
    },

    {
        path: '/about',
        exact: true,
        main: About,
        isGiftSite: true
    },

    {
        path: '/howto',
        exact: true,
        main: HowTo,
        isGiftSite: true
    },

    {
      path: '/404',
      exact: true,
      main: Giftsite404,
      isGiftSite: true
  },

  {
    path: '/news',
    exact: true,
    main: ListNews,
    isGiftSite: true
  },

  {
    path: '/news/:postId(\\d{1,9})',
    exact: true,
    main: NewsDetail,
    isGiftSite: true
  },

  {
    path: '/sitemap',
    exact: true,
    main: Sitemap,
    isGiftSite: true
  },
  {
    path : '/stripeCheckout',
    exact : true,
    main : StripeCheckout
  },

  {
    path: '/campaign/:campaignId(\\d{1,9})/booking',
    exact: true,
    main: BookingTicket
  },

  {
    path: '/campaign/:campaignId(\\d{1,9})/active',
    exact: true,
    main: CheckActiveCode
  },

  // Mogugori
  {
    path: '/mogugori',
    exact: true,
    main: HomePageMogugori,
    isMogugori: true
  },
  {
    path: '/mogugori/shopDetail/:shopId(\\d{1,9})',
    exact: true,
    main: ShopDetailMogugori,
    isMogugori: true
  },
  {
    path: '/mogugori/menuSearch',
    exact: true,
    main: MenuSearchMogugori,
    isMogugori: true
  },
  {
    path: '/mogugori/user/:shopId(\\d{1,9})',
    exact: true,
    main: UserRank,
    isMogugori: true
  },

  // Gochi Kids
  {
    path: GOCHI_KID_PATH,
    exact: true,
    main: HomePageGochiKid,
    isGochiKid: true,
    hideGochiKid: process.env.REACT_APP_SHOW_GOCHI_KID_DONATE === "false" || process.env.REACT_APP_SHOW_GOCHI_KID_DONATE === undefined
  },
  {
    path: GOCHI_KID_PATH + "/support/choice",
    exact: true,
    main: DonateDestination,
    isGochiKid: true,
    hideGochiKid: process.env.REACT_APP_SHOW_GOCHI_KID_DONATE === "false" || process.env.REACT_APP_SHOW_GOCHI_KID_DONATE === undefined
  },
  {
    path: GOCHI_KID_PATH + '/support/choice/:destination/input',
    exact: true,
    main: DonateInput,
    isGochiKid: true,
    hideGochiKid: process.env.REACT_APP_SHOW_GOCHI_KID_DONATE === "false" || process.env.REACT_APP_SHOW_GOCHI_KID_DONATE === undefined
  },
  {
    path: GOCHI_KID_PATH + '/support/choice/:destination/confirm',
    exact: true,
    main: DonateConfirm,
    isGochiKid: true,
    hideGochiKid: process.env.REACT_APP_SHOW_GOCHI_KID_DONATE === "false" || process.env.REACT_APP_SHOW_GOCHI_KID_DONATE === undefined
  },
  {
    path: GOCHI_KID_PATH + '/support/choice/:destination/complete',
    exact: true,
    main: DonateComplete,
    isGochiKid: true,
    hideGochiKid: process.env.REACT_APP_SHOW_GOCHI_KID_DONATE === "false" || process.env.REACT_APP_SHOW_GOCHI_KID_DONATE === undefined
  },
  {
    path: GOCHI_KID_PATH + '/support/summer-campaign',
    exact: true,
    main: CampaignRedirect,
    isGochiKid: true,
    hideGochiKid: process.env.REACT_APP_SHOW_GOCHI_KID_DONATE === "false" || process.env.REACT_APP_SHOW_GOCHI_KID_DONATE === undefined
  },
  {
    path: '/list-shop-gochikid',
    exact: false,
    main: ShopGochiKid
  }
];

export default routes;
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { showError } from "../../../actions/action_error_alert";
import { showLoading } from "../../../actions/action_loading";
import DonateService from "../../../api/services/GochiKid/DonateService";
import * as constants from "../../../constants";
import { log } from "../../../utils";
import ActivityInfo from "../common/ActivityInfo";
import Payment from "../common/Payment";
import RadioInput from "../common/RadioInput";
import Header from "../layout/Header";

const Atone = window.Atone;

function DonateConfirm(props) {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const destination = match.params.destination;
  const previousPath = location.state.previousPath;
  const inputPath = `${constants.GOCHI_KID_PATH}/support/choice/${destination}/input${window.location.search}`;
  const successPath = `${constants.GOCHI_KID_PATH}/support/choice/${destination}/complete`;
  const confirmPath = `${constants.GOCHI_KID_PATH}/support/choice/${destination}/confirm`;

  const [crfsToken, setCrfsToken] = useState('');

  const generateRandomString = (length = 32) => {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++) { text += possible.charAt(Math.floor(Math.random() * possible.length)); }
    setCrfsToken(text);
    console.log('crfs token', text);
    return text;
  }

  const getDestinationText = () => {
    switch (destination) {
      case 'region':
        return '都道府県';
      case 'activity':
        return '自治体/活動';
      default:
        return '全国';
    }
  };

  const getCompletePath = (params) => {
    let path = successPath;
    if (params && Object.keys(params).length > 0) {
      let count = 0;
      for (const property in params) {
        path += count === 0 ? '?' : '&';
        path += property + '=' + params[property];
        count++;
      }
    }
    return path;
  }

  const initializeAtone = () => {
    if (Atone) {
      Atone.config({
        pub_key: process.env.REACT_APP_ATONE_PUB_KEY,
        payment: {},
        // This is called upon finishing the authentication or user registration.
        authenticated: function (authenticationToken, userNo) {
          console.log('authentication token ' + authenticationToken);
          console.log('user no ' + userNo);
          localStorage.setItem('atoneToken', authenticationToken);
        },
        // Call upon closing the modal.
        cancelled: function () {
          console.log("cancelled");
        },
        // Call after pressing the button to close the form after the payment has returned as NG.
        failed: function (response) {
          console.log('response failed ' + response);
        },
        // Call after the form has been automatically closed when the payment has returned as OK.
        succeeded: function (response) {
          console.log('response succeeded ' + JSON.stringify(response));
        },
        // Call when an error occurs.
        error: function (name, message, errors) {
          console.log('response error name ' + name);
          console.log('response error message ' + message);
          console.log('response error errors ' + JSON.stringify(errors));
        }
      });
    } else if (!window.Atone && props.paymentType === constants.PAYMENT_TYPE_ATONE) {
			props.showError(true, "※広告ブロック等の理由により、atone決済が表示されません。atone決済をご利用ご希望の方は、ご利用のブラウザを変更してお試しください。", "");
		}
  };

  const startAtoneCheckout = async (atoneJson, merchantPaymentId) => {
    log('Atone JSON: ' + JSON.stringify(atoneJson));
    const options = {
      payment: atoneJson,
      succeeded: function (response) {
        log('response succeeded ' + JSON.stringify(response));
        window.location.replace(getCompletePath({ merchantPaymentId, checkoutId: response.id }));
      }
    };
    if (localStorage.getItem('atoneToken')) {
      options.pre_token = localStorage.getItem('atoneToken');
    }
    if (Atone) {
      Atone.merge(options);
      Atone.sync();
      // Sleep to wait sync done
      await new Promise(resolve => setTimeout(resolve, 1000));
      Atone.start();
    } else if (!window.Atone && props.paymentType === constants.PAYMENT_TYPE_ATONE) {
			props.showError(true, "※広告ブロック等の理由により、atone決済が表示されません。atone決済をご利用ご希望の方は、ご利用のブラウザを変更してお試しください。", "");
		}
  };

  const donateParams = {
    paymentType: props.paymentType,
    donateAmount: props.donateAmount
  };
  if (props.donateMessage && props.donateMessage.length > 0) {
    donateParams.donateMessage = props.donateMessage;
  }
  if (props.shopId) {
    donateParams.shopId = props.shopId;
  } else if (props.groupId) {
    donateParams.groupId = props.groupId;
  }

  const submitDonateSubscription = async () => {
    log('submitDonateSubscription');
    props.showLoading(true);
    const params = {
      ...donateParams,
      paymentSource: props.paymentMethod?.token,
      billingGroupId: props.billingGroupId
    }
    const response = await DonateService.createDonateSubscription(params, crfsToken);
    if (response.status === 200) {
      log('submitDonateSubscription success');
      props.showLoading(false);
      history.replace(getCompletePath({ merchantPaymentId: response.data.merchantPaymentId }));
    } else {
      generateRandomString();
      if (response.status === 402 && response.data) {
        if (response.data.stripe_code === 'expired_card') {
          props.showError(true, '有効期限切れのカードです。', '');
        } else if (response.data.stripe_code === 'amount_too_small') {
          props.showError(true, '最低ご利用金額に達していません。', '');
        } else if (response.data.stripe_code === 'insufficient_funds') {
          props.showError(true, 'カードの残高が不足しています。', '');
        } else if (response.data.stripe_code === 'card_velocity_exceeded') {
          props.showError(true, 'カードの利用限度額を超えています。', '');
        } else if (response.data.veritrans_error_code === 'AG64000000000000') {
          props.showError(true, '有効期限切れのカードです。', '');
        } else if (response.data.veritrans_error_code === 'AG44000000000000') {
          props.showError(true, 'カードの残高が不足しています。', '');
        } else if (response.data.veritrans_error_code === 'AG45000000000000') {
          props.showError(true, 'カードの利用限度額を超えています。', '');
        } else if (
          response.data.veritrans_error_code === 'AG70000000000000' ||
          response.data.veritrans_error_code === 'AG49000000000000' ||
          response.data.veritrans_error_code === 'AG33000000000000'
        ) {
          props.showError(true, 'ご入力いただいたクレジットカードをご利用できません。以下の内容をご確認お願いします。\n・カード番号、有効期限、セキュリティーコードに誤りがある可能性がございますので、ご確認ください。\n・それ以外でご利用できない場合、お使いのクレジットカード会社様にお問合せください。', '');
        } else {
          props.showError(true, 'エラーが発生しました。', '');
        }
      } else {
        props.showError(true, 'エラーが発生しました。', '');
      }
      props.showLoading(false);
    }
  };

  const submitDonate = async () => {
    if (props.isSubscription) {
      submitDonateSubscription();
      return;
    }
    props.showLoading(true);
    const params = {
      ...donateParams,
      successUrl: window.location.origin + successPath,
      cancelUrl: window.location.origin + confirmPath,
      domain: window.location.origin,
      userAgent: navigator.userAgent
    };
    if (!props.userInfo.userId) {
      params.userEmail = props.userEmail;
      params.userName = props.userName;
    }

    if (props.userInfo.userId && (props.paymentType === constants.PAYMENT_TYPE_STRIPE || props.paymentType === constants.PAYMENT_TYPE_VERITRANS)) {
      // Charge Stripe/Veritrans
      params.paymentSource = props.paymentMethod?.token;
      const response = await DonateService.charge(params, crfsToken);
      log(['charge response', response]);
      if (response.status === 200) {
        props.showLoading(false);
        history.replace(getCompletePath({ merchantPaymentId: response.data.merchantPaymentId }));
      } else {
        // TODO: error message
        generateRandomString();
        props.showError(true, "エラーが発生しました。", "");
        props.showLoading(false);
      }
    } else {
      // Checkout
      let windowReference = null;
      if (props.paymentType === constants.PAYMENT_TYPE_ONEPAY_ALIPAY) {
        windowReference = window.open();
      }
      if (props.paymentType === constants.PAYMENT_TYPE_VERITRANS) {
        // Show loading of Veritrans popup
        props.showLoading(false);
        window.pop.show();
      }
      const response = await DonateService.createCheckoutSession(params);
      if (response.status === 200) {
        if (props.paymentType === constants.PAYMENT_TYPE_STRIPE) {
          const stripeCheckoutPath = '/stripeCheckout?sessionId=' + response.data.stripeSessionId;
          props.showLoading(false);
          history.replace(stripeCheckoutPath);
        } else if (props.paymentType === constants.PAYMENT_TYPE_VERITRANS) {
          const options = {
            autoReturn: true,
            autoReturnDelay: 5,
            onSuccess: function () {
              log('success pop');
              history.replace(getCompletePath({ merchantPaymentId: response.data.merchantPaymentId }));
            },
            onFailure: function () {
              log('failure pop');
            },
            onIncomplete: function () {
              log('incomplete pop');
            }
          };
          window.pop.pay(response.data.checkoutToken, options);
        } else if (props.paymentType === constants.PAYMENT_TYPE_ONEPAY_ALIPAY) {
          if (isMobile) {
            windowReference.location = response.data.redirectUrl;
            setTimeout(() => {
              try {
                const refHostname = windowReference.location.hostname;
                if (refHostname !== 'mobile.alipay.com')
                  windowReference.close();
              } catch (e) {}
              props.showLoading(false);
              window.location.replace(getCompletePath({ merchantPaymentId: response.data.merchantPaymentId, retry: 5 }));
            }, 5000);
          } else {
            windowReference.location = `${window.location.origin}/alipay-qr-code?value=${encodeURIComponent(response.data.redirectUrl)}&ts=${Date.now()}`;
            props.showLoading(false);
            window.location.replace(getCompletePath({ merchantPaymentId: response.data.merchantPaymentId, retry: 5 }));
          }
        } else if (props.paymentType === constants.PAYMENT_TYPE_ATONE) {
          await startAtoneCheckout(response.data.atoneJson, response.data.merchantPaymentId);
          props.showLoading(false);
        } else {
          // PayPay
          window.location.replace(response.data.redirectUrl);
        }
      } else {
        if (windowReference) {
          windowReference.close();
        }
        if (props.paymentType === constants.PAYMENT_TYPE_VERITRANS) {
          window.pop.hide();
        }
        // TODO: error message
        props.showError(true, "エラーが発生しました。", "");
        props.showLoading(false);
      }
    }
  };

  useEffect(() => {
    log('DonateConfirm componentDidMount');

    if (!props.donateAmount) {
      history.replace(inputPath);
      return;
    }

    initializeAtone();

    generateRandomString();

    // getActivityInfo().then(() => {
      // Scroll to top after first load
    window.scrollTo(0, 0);
    // });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header />

      <main className="page-wrapper">
        <div className="page-content donate-payment">

          <div className="font-family-rounded title-donate text-center color-2F1A1C mb-3">支援内容の確認</div>
          <ul className="payment-progress-bar align-center">
            <li>入力</li>
            <li className="active">確認</li>
            <li>完了</li>
          </ul>

          <div className="block-title-lg font-family-rounded mt-5">
            支援先 <span className="color-ED808C">*</span>：{getDestinationText()}
          </div>

          {props.activityInfo && <div className="mt-3">
            <ActivityInfo
              className="activity-info-confirm"
              info={props.activityInfo}
              hideContactInfo={destination === 'region'}
            />
          </div>}

          <div className="block-title-lg font-family-rounded mt-5">
            金額（税込） <span className="color-ED808C">*</span>
          </div>
          <div className="fs-20px fw-700 mt-3">{(props.donateAmount || 0).toLocaleString()}<span className="fs-16px">円</span></div>

          <div className="block-title-lg font-family-rounded mt-5">
            回数 <span className="color-ED808C">*</span>
          </div>
          <div className="d-flex mt-3">
            <RadioInput
              disabled={!props.isSubscription}
              checked={props.isSubscription}
              readOnly
              className="mr-2"
              inputName="isSubscription"
              inputValue="true"
            >継続（1ヶ月1回）</RadioInput>
            <RadioInput
              disabled={props.isSubscription}
              checked={!props.isSubscription}
              readOnly
              className="ml-4"
              inputName="isSubscription"
              inputValue="false"
            >一度だけ</RadioInput>
          </div>

          <div className="block-title-lg font-family-rounded mt-5">
            お支払い方法 <span className="color-ED808C">*</span>
          </div>
          <Payment
            className="mt-3"
            readOnly
            paymentType={props.paymentType}
          />

          <div className="fs-12px fw-700 mt-3">
            ※ 申込撤回については
            <a className="color-47ACFF" href="https://gochimeshi.com/policy/legal/" target="_blank" rel="noopener noreferrer">
              特定商取引法に基づく表記
            </a>
            をご参照ください
          </div>

          { props.userEmail && props.userName && <>
            <div className="block-title-lg font-family-rounded mt-5">
              支援者情報 <span className="color-ED808C">*</span>
            </div>
            <div className="fs-20px fw-800 font-family-rounded mt-3">メールアドレス</div>
            <div className="overflow-wrap-anywhere fw-400 mt-2">{props.userEmail}</div>
            <div className="fs-20px fw-800 font-family-rounded mt-3">氏名</div>
            <div className="overflow-wrap-anywhere fw-400 mt-2">{props.userName}</div>
          </>}

          {props.donateMessage && <>
            <div className="block-title-lg block-title-optional font-family-rounded mt-5">
              こども達へのメッセージ
            </div>
            <div className="overflow-wrap-anywhere white-space-pre-wrap fw-400 p-3">
              {props.donateMessage}
            </div>
          </>}

          <div className="mt-5">
            <button className="btn-primary btn-size-md w-100" onClick={submitDonate}>
              支援を完了する
              <i className="icon-next-destination" />
            </button>
          </div>
          <div className="mt-4 text-center">
            <button className="btn-link fw-400 border-0 p-0" onClick={() => history.replace(previousPath)}>
              前の画面に戻る
            </button>
          </div>

        </div>
      </main>
    </>
  );
}

const mapStateToProps = state => ({
    userInfo: state.userInfo,
    paymentMethod: state.paymentMethod,
    paymentType: state.gochiKid.donate.paymentType,
    isSubscription: state.gochiKid.donate.isSubscription,
    billingGroupId: state.gochiKid.donate.billingGroupId,
    activityInfo: state.gochiKid.donate.activityInfo,
    shopId: state.gochiKid.donate.shopId,
    groupId: state.gochiKid.donate.groupId,
    donateAmount: state.gochiKid.donate.donateAmount,
    userEmail: state.gochiKid.donate.userEmail,
    userName: state.gochiKid.donate.userName,
    donateMessage: state.gochiKid.donate.donateMessage
});

const mapDispatchToProps = dispatch => ({
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content, buttonName, gotoUrl) => dispatch(showError(showing, title, content, buttonName, gotoUrl))
});

export default connect(mapStateToProps, mapDispatchToProps)(DonateConfirm);

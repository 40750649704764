import React, { Component } from 'react';
import { connect } from "react-redux";
import {Link} from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import {showError} from "../../actions/action_error_alert";
import {Modal, Button} from 'react-bootstrap'


class PopupError extends Component { 
  render() { 
    const { t } = this.props;

    return ( 
      <Modal show={this.props.error.showing} onHide={this.props.hideError} className="modal-error"> 
        <div className="modal-body"> 
          <Button variant="secondary" className="close" onClick={this.props.hideError}>&times;</Button>

          <div className="input-infor">   
            <div >
              {this.props.error.title !== '' &&
                <h5 className="space-pre-line">{t(this.props.error.title)}</h5>
              }

              {this.props.error.content &&
                <p className="space-pre-line">{t(this.props.error.content)}</p>
              }
              {this.props.error.gotoUrl && 
                <a href={this.props.error.gotoUrl} className="btn btn-bg-corner w-240 m-auto mt-3" onClick={this.props.hideError}>
                  {this.props.error.buttonName}
                </a>
              }
            </div>
          </div>
        </div> 
      </Modal>

    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  hideError: () => dispatch(showError(false))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PopupError));

import React from "react";

export default function CheckboxInput({
  children,
  className,
  inputName,
  inputValue,
  checked,
  disabled,
  readOnly,
  onChange
}) {
  let wrapperClassName = 'checkbox-input';
  if (className) {
    wrapperClassName += ' ' + className
  }
  return (
    <label className={wrapperClassName}>
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        readOnly={readOnly}
        name={inputName}
        value={inputValue}
        onChange={onChange} />
      <span className="checkbox-button"></span>
      <span className="checkbox-label">{children}</span>
    </label>
  );
}

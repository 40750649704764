import React from 'react';
import { withTranslation } from 'react-i18next';
import * as constants from '../../../constants';

function NoPayment(props) {
  const { t } = props;

  sessionStorage.setItem('selectPayment', constants.PAYMENT_TYPE_SELF_CHECKOUT);

  const paymentTypeLabel = props.methodType === 1
    ? t('pages.invite.payment bank transfer')
    : t('pages.invite.payment form submit');

  return (
    <div className="dish-infor pm-info mt-3">
      <div className="required-dot mb-3">
        <div className="dot-red"></div>
        <div className="dot-text-label fs-18">{t('pages.invite.paymentMethod')}</div>
      </div>
      <div className="box-option">
        <div className="d-flex">
          <div className="">
            <label className="radio-custom fs-14">
              <input
                type="radio"
                name="paymentType"
                value={constants.PAYMENT_TYPE_SELF_CHECKOUT}
                checked={parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_SELF_CHECKOUT}
                onChange={e => sessionStorage.setItem('selectPayment', e.target.value)}
              />
              <span className="checkmark"></span>
              <span>{paymentTypeLabel}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(NoPayment);

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { showNotLoggedModal } from "../../../actions/action_show_not_logged_modal";
import { showWishListModal } from "../../../actions/action_show_wish_list_modal";

export function ModalGroup(props) {
  const showingNotLoggedModal = useSelector(
    (state) => state.showNotLoggedModal
  );
  const showingWishListModal = useSelector((state) => state.showWishListModal.showing);
  const dispatch = useDispatch();
  return (
    <div className="modal-group" id="modal01">
      <div
        className="modal-mask"
        id="modalMask"
        onClick={() => {
          if (showingNotLoggedModal) dispatch(showNotLoggedModal(false));
          if (showingWishListModal) dispatch(showWishListModal(false));
          window.disappearModal();
          props.handleClosePopupDetailGochi && props.handleClosePopupDetailGochi();
        }}
      />
      {props.children}
    </div>
  );
}

import React from "react";

export function CancelSubscriptionGochiKidSuccess(props) {
  let destination = '';
  if (props.item.subscriptionDestination) {
    destination = props.item.destinationName;
  } else {
    destination = props.item.destination;
  }
  return (
    <div
      className={`modal modal-profile modal-profile-01 modal-01 modal-lg history-modal cancel-subscription-success-modal ${props.showPopupCancelSubscriptionGochiKidSuccess ? 'is-show' : ''}`}
      tabIndex={-1}
    >
      <div className="title-modal-charge">
        <div className="title"></div>
      </div>
      <div className={`modal-body`}>
        {`${destination}の継続支援を中止しました。`}
      </div>
      <div className="modal-footer">
        <div className="btn-group">
          <button
            onClick={() => props.handleClosePopupCancelSubscriptionGochiKidSuccess()}
            className={'btn btn-rect'}
          >
            マイページに戻る
          </button>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import ReadMoreText from "./ReadMoreText";

export default function DonateInfo({
  className,
  info
}) {
  let wrapperClassName = 'donate-info';
  if (className) {
    wrapperClassName += ' ' + className;
  }

  return (<div className={wrapperClassName}>
    <div>
      <span className="donate-info-label mr-2">累計寄付額</span>
      <span className="donate-info-value">{(info.totalDonateAmount || 0).toLocaleString()}<span className="fs-12px">円</span></span>
    </div>

    <div className="mt-2">
      <span className="donate-info-label mr-2">累計利用額</span>
      <span className="donate-info-value">{(info.totalDonateUse || 0).toLocaleString()}<span className="fs-12px">円</span></span>
    </div>

    {info.newestThankMessage && <>
      <div className="mt-2">
        <span className="donate-info-label">最新の感謝メッセージ</span>
      </div>
      <div className="mt-2">
        <ReadMoreText
          className="fs-14px fw-700 white-space-pre-wrap"
          maxLines={3}
          lineHeight={20}
        >
          {info.newestThankMessage}
        </ReadMoreText>
      </div>
    </>}

    {info.newestDonateMessage && <>
      <div className="mt-2">
        <span className="donate-info-label">最新の応援メッセージ</span>
      </div>
      <div className="mt-2">
        <ReadMoreText
          className="fs-14px fw-700 white-space-pre-wrap"
          maxLines={3}
          lineHeight={20}
        >
          {info.newestDonateMessage}
        </ReadMoreText>
      </div>
    </>}
  </div>);
};

import React from "react";

export function SendMessageGochiKidSuccess(props) {

  return (
    <div
      className={`modal modal-profile modal-profile-01 modal-01 modal-lg history-modal thank-message-success-moadal ${props.showPopupSendMessageGochiKidSuccess ? 'is-show' : ''}`}
      tabIndex={-1}
    >
      <div className="title-modal-charge">
        <div className="title">感謝メッセージを投稿完了しました！</div>
      </div>
      <div className={`modal-body`}>
        投稿して頂いた感謝メッセージは、ご支援者の「GOCHIマイページ {">"} こどもごちめし食堂支援 {">"} 感謝メッセージ」ページに追って表示されます。
      </div>
      <div className="modal-footer">
        <div className="btn-group">
          <button
            onClick={() => props.handleClosePopupSendMessageGochiKidSuccess()}
            className={'btn btn-rect'}
          >
            マイページに戻る
          </button>
        </div>
      </div>
    </div>
  );
}

import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

const SHOP_NO_IMAGE = require("../../../assets/img/icons/shop01_blank.png");

export function ShopSearchGochiKidItem(props) {
  const { item } = props;
  const { introduction } = item;
  const display =
    introduction?.length >= 120
      ? introduction.slice(0, 119) + "…"
      : introduction;
  const gochiKidGroupIdParam = props.gochiKidGroupId ? `?gochiKidGroupId=${props.gochiKidGroupId}` : '';

  let numberSlotCanUse = item.numberSlotCanUse;
  if (numberSlotCanUse === null) {
    numberSlotCanUse = 0;
  } else if (numberSlotCanUse > 100) {
    numberSlotCanUse = '100+';
  }

  const dayUseWeek = item.shopSetting?.dayUseWeek && JSON.parse(item.shopSetting?.dayUseWeek).sort() || [];

  const dayInWeek = [
    {id: 1, name: '月'},
    {id: 2, name: '火'},
    {id: 3, name: '水'},
    {id: 4, name: '木'},
    {id: 5, name: '金'},
    {id: 6, name: '土'},
    {id: 7, name: '日'},
  ];

  let dayUseWeekStr = '';
  if (dayUseWeek) {
    for (let i = 0; i < dayInWeek.length; i++) {
      if (dayUseWeek.includes(dayInWeek[i].id)) {
        dayUseWeekStr += dayInWeek[i].name + ((dayInWeek[i].id === dayUseWeek[dayUseWeek.length - 1]) ? '' : ',');
      }
    }
    if (dayUseWeek.length > 0) {
      dayUseWeekStr += '/';
    }
  }
  if (item.shopSetting?.validHoliday === 1) {
    dayUseWeekStr += '祝日利用可';
  } else if (item.shopSetting?.validHoliday === 0) {
    dayUseWeekStr += '祝日利用不可';
  } else if (!item.shopSetting) {
    dayUseWeekStr = '制限なし';
  }

  function handleClickErrorMenu() {
    if (numberSlotCanUse === 0) {
      const errorText = (
        <div>
          以下いずれかの理由により、ご利用できません。
          <br />
          ・利用可能残数がない
          <br />
          ・利用可能曜日（時間）ではない
          <br />
          ・支援金が不足している
        </div>
      );
      props.handShowErrorUseGochiKidPopup(errorText);
    } else if (item.gochiKidUserUseType === 2 && props.userDetails?.flagPoor === 0) {
      props.handShowErrorUseGochiKidPopup("こちらの店舗は特定の方のみ対象となっているため、ご利用いただけません。");
    }
  }

  return (
    <article
      className={props.loading ? "shop loading" : "shop"}
      key={item.shopId}
    >
      {((numberSlotCanUse === 0 && props.gochiKidGroupId) || (numberSlotCanUse === 0 && !props.gochiKidGroupId && item.hasMenuFreeFlag === 0) || 
          (item.gochiKidUserUseType === 2 && props.userDetails?.flagPoor === 0) || item.gochiKidFlag !== 1)
        ? <div className="shop-link" onClick={handleClickErrorMenu}></div>
        : <a className="shop-link" href={`/mypage/kodomo_use/search_shop/${item.shopId}${gochiKidGroupIdParam}`}></a>}
      <div className="shop-thumbnail">
        {((numberSlotCanUse === 0 && props.gochiKidGroupId) || (numberSlotCanUse === 0 && !props.gochiKidGroupId && item.hasMenuFreeFlag === 0) ||
            (item.gochiKidUserUseType === 2 && props.userDetails?.flagPoor === 0) || item.gochiKidFlag !== 1) ? (
          <div className="link-image-shop" onClick={handleClickErrorMenu}>
            <img
              src={
                item.shopImage
                  ? item.shopImage[0] && item.shopImage[0].image
                  : SHOP_NO_IMAGE
              }
              loading="lazy"
              alt=""
            />
          </div>
        ) : (
          <a href={`/mypage/kodomo_use/search_shop/${item.shopId}${gochiKidGroupIdParam}`} className="link-image-shop">
            <img
              src={
                item.shopImage
                  ? item.shopImage[0] && item.shopImage[0].image
                  : SHOP_NO_IMAGE
              }
              loading="lazy"
              alt=""
            />
          </a>
        )}
      </div>
      <div className="gochi-kid-status-box">
        <div className={`gochi-kid-status ${numberSlotCanUse === 0 ? 'gochi-kid-status-disable' : ''}`}><span>利用可能数：{numberSlotCanUse}人/日</span></div>
        {item.hasMenuFreeFlag === 1 && (
          <div className={`gochi-kid-status gochi-kid-menu-free-status`}><span>店舗提供無料メニュー有り</span></div>
        )}
        <div className={`gochi-kid-status ${item.gochiKidQrFlag === 0 ? 'gochi-kid-status-disable' : ''}`}><span>{item.gochiKidQrFlag === 0 ? 'カード型会員証対応不可' : 'カード型会員証対応可'}</span></div>
      </div>
      <div className="shop-header">
        <div className="header-box">
          <div className="badge-box mb-8px">
            {item.genreName && (
              <div className="badge-group">
                <div className="badge badge-outline badge-category badge-gray">
                  {item.genreName}
                </div>
              </div>
            )}
            <div className="action-group shop-action-group">
              <div
                className={
                  (
                    props.tempShopWishList[item.shopId] !== undefined
                      ? props.tempShopWishList[item.shopId]
                      : item.isWishList
                  )
                    ? "action action-list is-active"
                    : "action action-list is-inactive"
                }
              >
                <button
                  type="button"
                  aria-label="記事を保存"
                  onClick={() =>
                    props.onToggleShopWishList(item.shopId, item.isWishList)
                  }
                >
                  <svg className="icon">
                    <use xlinkHref="/assets/img/common/icon.svg#list" />
                  </svg>
                </button>
              </div>
              <div
                className={
                  (
                    props.tempShopFavorite[item.shopId] !== undefined
                      ? props.tempShopFavorite[item.shopId]
                      : item.isFavourite
                  )
                    ? "action action-like is-active"
                    : "action action-like is-inactive"
                }
              >
                <button
                  type="button"
                  aria-label="記事を保存"
                  onClick={() =>
                    props.onToggleShopFavorite(item.shopId, item.isFavourite)
                  }
                >
                  <svg className="icon">
                    <use xlinkHref="/assets/img/common/icon.svg#heart" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="title-shop-gochi-kid">
          <h3 className="shop-name text-bold mb-8px">{item.shopName}</h3>
        </div>
      </div>
      <div className="shop-body">
        <dl className="list-place">
          <div className="list-place-item">
            <dt className="title">
              <svg className="icon">
                <use xlinkHref="/assets/img/common/icon.svg#map-marker" />
              </svg>
              <span className="title-text">場所</span>
            </dt>
            <dd className="text">
              {`${item.prefectures ? item.prefectures : ''} ${item.city ? item.city : ''} ${item.address ? item.address : ''} ${item.building ? item.building : ''}`}
            </dd>
          </div>
          <div className="list-place-item">
            <dt className="title">
              <svg className="icon">
                <use xlinkHref="/assets/img/common/icon.svg#map" />
              </svg>
              <span className="title-text">アクセス</span>
            </dt>
            <dd className="text">{item.access}</dd>
          </div>
          <div className="list-place-item">
            <dt className="title">
              <svg className="icon">
                <use xlinkHref="/assets/img/common/icon.svg#train" />
              </svg>
              <span className="title-text">最寄駅</span>
            </dt>
            <dd className="text">
              <ul className="list-block">
                <li>{item.nearestStation}</li>
              </ul>
            </dd>
          </div>
          <div className="list-place-item day-use-week">
            <dt className="title">
              <img className="icon" src="/assets/img/common/icon-calendar.svg" alt="" />
              <span className="title-text">利用可能曜日</span>
            </dt>
            <dd className="text">
              <ul className="list-block">
                <li>{dayUseWeekStr.length === 0 ? '制限なし' : dayUseWeekStr}</li>
              </ul>
            </dd>
          </div>
          <div className="list-place-item shop-holiday">
            <dt className="title">
              <img className="icon" src="/assets/img/common/icon-calendar-minus.svg" alt="" />
              <span className="title-text">不定休</span>
            </dt>
            <dd className="text">
              <ul className="list-block">
                <li>
                  {item.shopSetting?.shopHolidays && item.shopSetting?.shopHolidays.map((holiday, index) => {
                    return (
                      <span className="shop-setting-holiday" key={holiday.shopHolidayId}>{`${moment(holiday.holiday).format('YYYY年MM月DD日')}${item.shopSetting?.shopHolidays.length - 1 === index ? '' : ', '}`}</span>
                    )
                  })}
                </li>
              </ul>
            </dd>
          </div>
          <div className="list-place-item">
            <dt className="title">
              <img className="icon" src="/assets/img/common/icon-watch.svg" alt="" />
              <span className="title-text">利用可能時間</span>
            </dt>
            <dd className="text">
              <ul className="list-block">
                <li>{(item.shopSetting?.useStartTime && item.shopSetting?.useEndTime) ? (moment(item.shopSetting.useStartTime, 'HH:mm:ss').format('HH:mm') + ' ~ ' + (moment(item.shopSetting.useEndTime, 'HH:mm:ss').format('HH:mm'))) : '制限なし'}</li>
              </ul>
            </dd>
          </div>
          <div className="list-place-item">
            <dt className="title">
              <img className="icon" src="/assets/img/common/icon-sort-arrow-down.svg" alt="" />
              <span className="title-text">利用上限数</span>
            </dt>
            <dd className="text">
              <ul className="list-block">
                <li>{item.shopSetting?.limitNumUsedDay ? item.shopSetting.limitNumUsedDay + '人' : "制限なし"}</li>
              </ul>
            </dd>
          </div>
        </dl>
      </div>
      <div className="clear-both"></div>
      {display && (
        <div className="shop-footer pre-line">{display}</div>
      )}
    </article>
  );
}

import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import {
  useScript,
} from "../../../utils";
import Header from "../layout/Header";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { NotLoggedModal } from "../common/NotLoggedModal";
import { ModalGroup } from "../common/ModalGroup";
import { GiftToModal } from "../common/GiftToModal";
import { ShopSearchItem } from "../common/ShopSearchItem";
import { showLoading } from "../../../actions/action_loading";
import { showError } from "../../../actions/action_error_alert";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import MogugoriService from "../../../api/services/MogugoriService";
const LOGO_HOMEPAGE = require("../../../assets/Mogugori/img/mogugori-logo.png");

export function HomePage(props) {
  useScript("/assets/js/app.js");

  const params = queryString.parse(window.location.search);
  const location = useLocation();
  const history = useHistory();

  const [prefecture, setPrefecture] = useState(params.prefecture || "");
  const listPrefectures = useSelector((state) => state.prefectures);

  const prefectureId = getPrefectureId(prefecture);

  const [page, setPage] = useState(1);
  const LIMIT = 10;
  const [searchResults, setSearchResults] = useState([]);
  const [totalResult, setTotalResults] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const userInfo = useSelector((state) => state.userInfo);
  const isLoggedIn = userInfo && userInfo.userId;
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.className = "mogugori home";
    window.scrollTo(0, 0);
  }, []);

  function fetchData(newParams) {
    console.log("Fetch list menus", newParams);
    getShops(newParams);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  function getPrefectureId(name) {
    if (name) {
      const prefectureObj = listPrefectures.find(
        (item) => item.prefectureName === name
      );
      if (prefectureObj) return prefectureObj.prefectureId;
    }
    return "";
  }

  function handleSearchPrefecture(e) {
    const newPrefecture = e.target.value;
    const params = [];
    setPrefecture(newPrefecture);
    if (newPrefecture) params.push(`prefecture=${newPrefecture}`);

    const query = params.join("&");
    history.replace({
      search: `?${query}`,
    });
    setPage(1);
  }

  function getShops(newParams) {
    const getParams = newParams || params;
    console.log("getShops: ", getParams);
    const isLoadMore = newParams?.isLoadMore ? newParams?.isLoadMore : false;
    const parameters = {
      page: getParams.page || page,
      perPage: LIMIT,
      prefectures: getParams.prefecture,
      mogugoriFlag: 1
    };
    setLoading(true);
    MogugoriService.getMogugoriShops(parameters)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          setSearchResults(isLoadMore ? [...searchResults, ...response.data.list] : response.data.list);
          setTotalResults(response.data.total);
          setPage(parameters.page);
        } else if (response.status === 404) {
          setSearchResults([]);
          setTotalResults(0);
        } else if (response.status === 500) {
          alert("エラーが発生しました。");
        } 
        setShowLoadMore(true);
      })
      .catch((e) => {
        setShowLoadMore(true);
        setLoading(false);
        console.error("GET LIST SHOPS ERROR: ", e);
      });
  }

  function loadMoreShop() {
    if (!showLoadMore) {
      return;
    }
    const nextPage = page + 1;
    const maxPage = Math.ceil(totalResult / LIMIT);
    if (nextPage <= maxPage) {
      const newParams = {
        page: nextPage,
        isLoadMore: true,
        prefecture: prefecture
      }
      setShowLoadMore(false);
      fetchData(newParams);
    }
  }

  return (
    <>
      <MetaTags>
        <title>お店一覧｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content="お店一覧です。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
      </MetaTags>
      <Header />

      <main>
        <div className="container container-mogugori">
          <div className="logo-homepage">
            <img
            src={LOGO_HOMEPAGE}
            loading="lazy"
            alt=""
          />
          </div>
          <div className="search-body">
            <div className="fieldset fieldset-column fieldset-lg">
              <div className="fieldset-body">
                <div className="field">
                  <div className="field field-pill select-homepage">
                    <select
                      className="select-prefecture-mogugori"
                      value={prefecture}
                      onChange={(e) => {
                        handleSearchPrefecture(e);
                      }}
                    >
                      <option value="">選択なし</option>
                      {listPrefectures.length > 0 &&
                        listPrefectures.map((item, index) => {
                          return (
                            <option value={item.prefectureName} key={index}>
                              {item.prefectureName}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /.search-body */}
        </div>
        {/* /.container */}
        <div className="container container-mogugori">
          <section
            className={
              (loading && searchResults.length === 0)
                ? "section loading"
                : "section"
            }
          >
            {searchResults.length === 0 ? 
              (<div className="section-header">
                <div className="heading-lg">
                  <h1 className="title">
                    見つかった店舗：0件
                  </h1>
                </div>
              </div>) : 
              (<div className="section-body">
                <div className="shop-group mt-20">
                  <InfiniteScroll
                    hasMore={(page + 1 <= Math.ceil(totalResult / LIMIT)) && showLoadMore}
                    loadMore={() => {loadMoreShop()}}
                    initialLoad={false}
                  >
                    {searchResults.map((item, index) => (
                      <ShopSearchItem
                          item={item}
                          key={index}
                          //loading={loading}
                      />
                    ))}
                  </InfiniteScroll>
                </div>
              </div>)
            }
          </section>
          {/* 店舗一覧のHTML */}
        </div>
      </main>
      <ModalGroup>
        <GiftToModal />
        <NotLoggedModal />
      </ModalGroup>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content, buttonName, gotoUrl) =>
    dispatch(showError(showing, title, content, buttonName, gotoUrl)),
});

export default connect(null, mapDispatchToProps)(HomePage);

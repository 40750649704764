import React from 'react';

export function Explanation(props) {
  return (
    <section className="section">
      <div className="container">
        <div className="card card-md mt-50">
          <p className="text-lg">
            「ごちめし」は飲食店のメニューをお食事券でプレゼントできるサービスです。ジャンルやシチュエーションに合ったお食事券を見つけて、大切なあの人にプレゼントしましょう。
          </p>
        </div>
      </div>
    </section>
    
  );
}
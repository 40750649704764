import React from "react";
import MetaTags from 'react-meta-tags';
import { 
  useScript, 
  useStyleSheet
} from "../../../../utils";
import { Footer } from "../../layout/Footer";
import Header from "../../layout/Header";
import { connect } from "react-redux";
import { ModalGroup } from "../../common/ModalGroup";
import { SituationModal } from "../../common/SituationModal";
import { GiftToModal } from "../../common/GiftToModal";
import { withTranslation } from 'react-i18next';
import { Explanation } from "../../layout/Explanation";

export function Sitemap(props) {
  useStyleSheet("https://unpkg.com/swiper/swiper-bundle.min.css",
    null,
    {
      integrity:
        "sha384-HUXJxzkMpCxEdXkgl8BSjN5SUcUODepsOLIYIWOtKva+Qo2teMmV1mh6ZVwnthaV",
      crossorigin: "anonymous",
      referrerpolicy: "no-referrer",
    }
  );
  useScript("https://unpkg.com/swiper/swiper-bundle.min.js",
    null,
    { integrity: "sha384-XVTmRHMO2XUTzy8IerJVEy8ddg47Yz5pZrLc7nEWipaTowqT1xNb8qTLaw64Bd4S",
      crossorigin: "anonymous"
    }
  );
  useScript("/assets/js/app.js");

  function getTitle() {
    return "サイトマップ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス";
  }

  function getMetaDescription() {
    return `サイトマップです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。`;
  }

  const { t } = props;

  return (
    <>
      <MetaTags>
        <title>{getTitle()}</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content={getMetaDescription()} />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content={getTitle()} />
        <meta name="twitter:description" content={getMetaDescription()} />
        <meta name="twitter:image" content={window.location.origin + "/assets/img/common/ogp.png"}  />
        <meta name="twitter:site" content="@gochi_meshi" />
        <meta name="twitter:creator" content="@gochi_meshi" />
        <meta name="twitter:image:width" content="300" />
        <meta name="twitter:image:height" content="300" />
        <meta property="og:site_name" content={getTitle()} />
        <meta property="og:title" content={getTitle()} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={window.location.origin + "/assets/img/common/ogp.png"}  />
        <meta property="og:description" content={getMetaDescription()} />
      </MetaTags>
      <Header />

      <main>
        <section className="section">
          <div className="container">
            <div className="section-header justify-content-center">
              <div className="heading-lg">
                <h2 className="title">{t('pages.sitemap.title')}</h2>
              </div>
            </div>
            <div className="section-body">
              <div className="sidebar-group">
                <div className="box box-lg box-rounded bg-main-light-1 layout-group">
                  <div className="layout">
                    <ul className="sitemap-list list-block text-bold">
                      <li><a href="/">{t('pages.sitemap.topPage')}</a></li>
                      <li><a href="/about" target="_blank">{t('pages.sitemap.about')}</a></li>
                      <li><a href="/howto" target="_blank">{t('pages.sitemap.howTo')}</a></li>

                      <li>
                        <a href="/shops" target="_blank">{t('pages.sitemap.shops')}</a>
                      </li>

                      <li>
                        <a href="/products" target="_blank">{t('pages.sitemap.products')}</a>
                        <ul className="text-sm">
                          <li><a href="/products-purpose/scenes" target="_blank">{t('pages.sitemap.scenes')}</a></li>
                          <li><a href="/products-purpose/genres" target="_blank">{t('pages.sitemap.genres')}</a></li>
                          <li><a href="/products-purpose/prices" target="_blank">{t('pages.sitemap.prices')}</a></li>
                          <li><a href="/products-purpose/who" target="_blank">{t('pages.sitemap.who')}</a></li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="layout">
                    <ul className="sitemap-list list-block text-bold">
                      <li>
                        <a href="/specials" target="_blank">{t('pages.sitemap.specials')}</a>
                      </li>
                      <li>
                        <a href="/magazine" target="_blank">{t('pages.sitemap.magazine')}</a>
                      </li>

                      <li>
                        <a href="/news" target="_blank">{t('pages.sitemap.news')}</a>
                      </li>
                    </ul>

                    <ul className="sitemap-list list-block text-bold mt-70">
                      <li>
                        <a href="https://business.gochi.online/" target="blank">
                          {t('pages.sitemap.business')}
                          <svg className="icon sitemap-icon">
                              <use xlinkHref="/assets/img/common/icon.svg#external-link"></use>
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a href="https://gochimeshi.com/service/" target="blank">
                          {t('pages.sitemap.service')}
                          <svg className="icon sitemap-icon">
                              <use xlinkHref="/assets/img/common/icon.svg#external-link"></use>
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a href="https://user-guide.gochimeshi.com/" target="blank">
                          {t('pages.sitemap.support')}
                          <svg className="icon sitemap-icon">
                              <use xlinkHref="/assets/img/common/icon.svg#external-link"></use>
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a href="https://gochimeshi.com/info/" target="blank">
                          {t('pages.sitemap.info')}
                          <svg className="icon sitemap-icon">
                              <use xlinkHref="/assets/img/common/icon.svg#external-link"></use>
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="sidebar sitemap-sidebar">
                  <div className="box box-lg box-rounded bg-main-light-1">
                    <div className="box-header box-header-rect">
                      <div className="heading-md text-center">
                        <h2 className="title text-main">{t('pages.sitemap.user')}</h2>
                      </div>
                    </div>
                    <div className="box-body">
                      <ul className="sitemap-list list-block text-bold">
                        <li>
                          <a href="/mypage" target="_blank">{t('pages.sitemap.mypage')}</a>
                          <ul className="text-sm">
                            <li><a href="/mypage/mygochi" target="_blank">{t('pages.sitemap.mygochi')}</a></li>
                            <li><a href="/mypage/purchase_histories" target="_blank">{t('pages.sitemap.histories')}</a></li>
                            <li><a href="/mypage/follow" target="_blank">{t('pages.sitemap.follow')}</a></li>
                            <li><a href="/mypage/payment_informations" target="_blank">{t('pages.sitemap.informations')}</a></li>
                            <li><a href="/mypage/receipt" target="_blank">{t('pages.sitemap.receipt')}</a></li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-footer text-center">
              <a href="/signup" className="banner">
                <picture>
                  <source media="(max-width: 660px)" srcset="/assets/img/sitemap/banner-signup-sp.png" />
                  <img src="/assets/img/sitemap/banner-signup-pc.png" alt="ごちめしは登録無料！利用料無料！新規会員登録してGOCHIを利用する（仮）" loading="lazy" />
                </picture>
              </a>
            </div>
          </div>
        </section>

        <Explanation />
      </main>

      <Footer />

      <ModalGroup>
        <SituationModal />
        <GiftToModal />
      </ModalGroup>
    </>
  )
}

const mapStateToProps = (state) => ({ props: state.props });

export default connect(mapStateToProps)(withTranslation()(Sitemap));
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import ModalCloseButton from "../common/ModalCloseButton";

function Header(props) {
  const userInfo = useSelector((state) => state.userInfo);

  const [showFullscreenNav, setShowFullscreenNav] = useState(false);

  const redirectToLogin = () => {
    sessionStorage.setItem('loginCallbackUrl', '/gochikid/support/choice');
    window.location.href = '/login';
  };

  return (
    <header className="gochi-kid-header">
      <div className="header-nav">
        <div className="logo-block">
          <a href="http://kodomo-gochimeshi.org/">
            <img src="/assets/img/gochi-kid/logo_gochi_kid.png" alt="Logo"/>
          </a>
        </div>

        <div className="header-right-block">
          <div className="redict-link-block">
            <a className="redict-link" href="http://kodomo-gochimeshi.org/support.html">支援について</a>
            <a className="redict-link" href="http://kodomo-gochimeshi.org/use.html">利用について</a>
          </div>

          {userInfo && userInfo.userId ? (
            <button
              className="btn-primary login-btn"
              onClick={() => window.location.href = '/mypage'}
            >
              <span>マイページ</span>
              <i className="icon-vector" />
            </button>
          ) : (
            <button
              className="btn-primary login-btn"
              onClick={redirectToLogin}
            >
              <span>ログイン</span>
              <i className="icon-vector" />
            </button>
          )}

          <button className="btn-hamburger" onClick={() => setShowFullscreenNav(true)}>
            <i className="icon-humburger" />
          </button>
        </div>
      </div>

      <nav className={'no-header-modal fullscreen-nav' + (showFullscreenNav ? ' fullscreen-nav-active' : '')}>
        <ModalCloseButton onClick={() => setShowFullscreenNav(false)} />
        <ul>
          <li><a href="http://kodomo-gochimeshi.org/support.html">支援について</a></li>
          <li><a href="http://kodomo-gochimeshi.org/use.html">利用について</a></li>
        </ul>
      </nav>
    </header>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

export default connect(mapStateToProps)(Header);
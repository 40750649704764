import React from "react";
import { MenuInfo } from "./MenuInfo";
import { Swiper, SwiperSlide } from "swiper/react";
import { getSlidePerView, swiperConfigs } from "./SwiperConfigs";
import { SliderPagination } from "./SliderPagination";
import { useWindowSize } from "../../../utils";

export function ListMenus(props) {
  const windowSize = useWindowSize();
  const innerWidth = windowSize[0];
  return props.data && props.data.length > 0 ? (
    <>
      <section className="section">
        <div className="container">
          <div className="section-header">
            <div className="heading-lg">
              <h2 className="title">{props.title}</h2>
            </div>
          </div>
          <div className="section-body slider-products">
            {props.data.length <=
            getSlidePerView(innerWidth, swiperConfigs.products) ? (
              <div className="swiper-container disabled" id="sliderProducts">
                <div
                  className={`swiper-wrapper ${
                    props.loadingWishList ? "loading" : ""
                  }`}
                >
                  {props.data.map((item, key) => (
                    // <div key={key}>
                      <MenuInfo menu={item} key={key} isShopDetailScreen={props.isShopDetailScreen}/>
                    // </div>
                  ))}
                </div>
              </div>
            ) : (
              <Swiper {...swiperConfigs.products}>
                <div
                  className={`swiper-wrapper ${
                    props.loadingWishList ? "loading" : ""
                  }`}
                >
                  {props.data.map((item, key) => (
                    <SwiperSlide key={key}>
                      <MenuInfo menu={item} key={key} isShopDetailScreen={props.isShopDetailScreen}/>
                    </SwiperSlide>
                  ))}
                </div>
                <SliderPagination />
              </Swiper>
            )}
          </div>
        </div>
      </section>
    </>
  ) : (
    <></>
  );
}

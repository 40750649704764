/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { MenuInfo } from "../../common/MenuInfo";
import { showLoading } from "../../../../actions/action_loading";
import { showError } from "../../../../actions/action_error_alert";
import { withTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { swiperConfigs } from "../../common/SwiperConfigs";
import { SliderPagination } from "../../common/SliderPagination";

export function RecommendedMenu(props) {
  const recommend = props.recommend;
  const index = props.index;
  const t = props.t;
  console.log("props", props);
  return (
    <section className={props.loading ? "section loading" : "section"}>
      <div className="container" key={index}>
        <div className="section-header">
          <div className="heading-lg">
            <h2 className="title">{recommend.title}</h2>
          </div>
          <a
            href={`/products?${recommend.urlParams}`}
            className="link text-underline only-pc ml-auto"
          >
            {t("other.showMore")}
          </a>
        </div>
        <div className="before-after-des">{ReactHtmlParser(recommend.beforeDescription)}</div>
        <div className="section-body only-pc">
          <div className="article-swiper-container">
            <div className="swiper-wrapper">
              {recommend.recommendedMenu &&
                recommend.recommendedMenu.length > 0 &&
                recommend.recommendedMenu.map((item, key) => (
                  <MenuInfo menu={item} key={key} />
                ))}
            </div>
          </div>
        </div>
        <div className="section-body slider-products under-tab">
          <Swiper {...swiperConfigs.products}>
            <div className="swiper-wrapper">
              {recommend.recommendedMenu &&
                recommend.recommendedMenu.length > 0 &&
                recommend.recommendedMenu.map((item, key) => (
                  <SwiperSlide key={key}>
                    <MenuInfo menu={item} key={key} />
                  </SwiperSlide>
                ))}
            </div>
            <SliderPagination />
          </Swiper>
        </div>
        <div className="before-after-des">{ReactHtmlParser(recommend.afterDescription)}</div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({ props: state.props });
const mapDispatchToProps = (dispatch) => ({
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content, buttonName, gotoUrl) =>
    dispatch(showError(showing, title, content, buttonName, gotoUrl)),
});

export default connect(
  mapDispatchToProps,
  mapStateToProps
)(withTranslation()(RecommendedMenu));

import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import {connect} from "react-redux";
import {Redirect} from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import 'firebase/auth'; 
import AuthService from "../../../api/services/AuthService";
import UserService from "../../../api/services/UserService";
import {showError} from "../../../actions/action_error_alert";  
import {showLoading} from "../../../actions/action_loading"; 
import {saveUserInfo} from "../../../actions/action_users";
import queryString from 'query-string';
import {addParamUrl} from '../../../utils';

//Login by cognito
import { Auth } from 'aws-amplify';

// import Header from "../../layout/header/Header";

// Amplify.configure({
//     Auth: {
//     	identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
//     	region: process.env.REACT_APP_REGION,
//     	userPoolId: process.env.REACT_APP_USER_POOL_ID,
//         userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
// 	}
// });

class RegisterConfirm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirectTo: false,
			errorMessage: null,
      menuId: null,
      confirmCode: '',
      src: '',
      registerInput: '',
      isSite: ''
		};
		this.onConfirmCodeChange = this.onConfirmCodeChange.bind(this); 
		this.submitConfirm = this.submitConfirm.bind(this); 
		this.onResendCode = this.onResendCode.bind(this); 
	}  

	async submitConfirm(e){
		e.preventDefault();
    this.props.showLoading(true);
    if (this.state.confirmCode) { 
      try {
        await Auth.confirmSignUp(this.props.registerInput.email, this.state.confirmCode);
        const user = await Auth.signIn(
          this.props.registerInput.email,
          this.props.registerInput.password,
        ); 

        const createUserResponse = await UserService.createUser(
          this.props.registerInput.email,
          this.props.registerInput.username,
          user.getUsername(),
        );
        // get tokens from api create user
        if (createUserResponse.status === 200) {
          const { accessToken, refreshToken } = createUserResponse.data;
          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('refreshToken', refreshToken);
        }
        
        // Code get user info and save local storage, if redirect to confirm sms then remove this code
        const { data } = await UserService.getUserList({
          cognitoUserId: user.username,
          sort: "createdAtDesc",
          page: 1,
          limit: 1,
        });

        // TODO: remove
        /*
        const resAuth = await AuthService.authenticateByCognitoToken(user.signInUserSession.idToken.jwtToken);
        if (resAuth.status !== 200) {
          throw new Error("API error");
        }
        */

        this.props.saveUserInfo(true, false, data.list[0]); 

        var userInfo = {
          isLogined: true,
          isGuest: false,
          userId: data.list[0].userId,
          accountId: data.list[0].accountId,
          displayName: data.list[0].displayName,
          email: data.list[0].email,
          userImage: data.list[0].userImage && data.list[0].userImage.length > 0 && data.list[0].userImage[0].image
        };

        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        // end code

        this.setState({
          redirectTo: true
        });

      } catch (error) {
        console.log(error);
        if (error.code === "CodeMismatchException") { 
          this.props.showError(true, this.props.t("pages.register.code mismatch exception"), '');
        } else if (error.code === "ExpiredCodeException") { 
          this.props.showError(true, this.props.t("pages.register.expired code exception"), '');
        } else { 
          this.props.showError(true, this.props.t("pages.register.user not confirmed exception signup"), '');
        } 
      } 
    } else {
      this.props.showError(true, this.props.t("pages.register.message please enter code"), '');
    }
		this.props.showLoading(false);
  }
  
  async onResendCode(){
    try {
      await Auth.resendSignUp(this.props.registerInput.email);
      this.props.showError(true, this.props.t('pages.register.Resent the verification code'), '');
    } catch (error) {
      console.log(error);
      if (error.__type === "InvalidParameterException") { 
        this.props.showError(true, this.props.t("pages.register.user not confirmed exception signup"), '');
      } else { 
        this.props.showError(true, this.props.t("pages.register.has error"), '');
      }
    }
  };

	onConfirmCodeChange(e) {
		this.setState({confirmCode: e.target.value});
  }

	UNSAFE_componentWillMount() {
		sessionStorage.removeItem("fullInviteContent");
		sessionStorage.removeItem("selectFriend");
		sessionStorage.removeItem("inviteContent");
		sessionStorage.removeItem("paymentMethod");
		sessionStorage.removeItem("menuInfo");
		sessionStorage.removeItem("comment");
		sessionStorage.removeItem("guestInfo");
		sessionStorage.removeItem("messageShop");
		var userInfo = this.props.userInfo;
		if (userInfo) {
			if(userInfo.isGuest === true) {
				localStorage.removeItem("userInfo");
				this.props.saveUserInfo(false, userInfo.isGuest, []);
			}
		}
	}

	componentDidMount() {
		let url = window.location.search;
		let params = queryString.parse(url);
    var menuId = params.id;
    var src = params.src;
    var registerInput = params.registerInput;
    var isSite = params.isSite;

    if (registerInput) {
			this.setState({
				registerInput: registerInput
			});
		}

		if (src) {
			this.setState({
				src: src
			});
		}

    if (isSite) {
			this.setState({
				isSite: isSite
			});
		}

		this.setState({
			menuId: menuId
		});
	}

	render() {
    const { t } = this.props; 
    document.body.className = "auth signup";

    var srcParam = this.state.src ? '&src=' + this.state.src : '';
    var registerInputParam = this.state.registerInput ? '&registerInput=' + this.state.registerInput : '';
    var isSiteParam = this.state.isSite ? {name: 'isSite', value: this.state.isSite} : {};

    const redirectTo = this.state.redirectTo;
    // var isRegister = sessionStorage.getItem('isRegister');
    let url = window.location.search; 
		let params = queryString.parse(url);
    var menuId = params.id;
    if (redirectTo === true) {
      window.history.pushState(null, null, addParamUrl('/signup/confirm' + registerInputParam, [isSiteParam]));
      let link;
      if (menuId) {
        link = addParamUrl("/invite?id=" + this.state.menuId + srcParam, [isSiteParam]);
      } else {
        link = addParamUrl("/mypage", [isSiteParam]);
      }
      return <Redirect to={link} />
    }  
    console.log('props', this.props);

      return (
        <div>
          <MetaTags>
            <title>登録完了｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス</title>
            <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
            <meta name="description" content="登録完了ページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
            <meta name="twitter:card swiper-slide" content="summary_large_image" />
            <meta name="twitter:title" content="登録完了｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
            <meta name="twitter:description" content="登録完了ページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
            <meta name="twitter:image" content={window.location.origin + "/assets/img/common/ogp.png"}  />
            <meta name="twitter:site" content="@gochi_meshi" />
            <meta name="twitter:creator" content="@gochi_meshi" />
            <meta name="twitter:image:width" content="300" />
            <meta name="twitter:image:height" content="300" />
            <meta property="og:site_name" content="登録完了｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
            <meta property="og:title" content="登録完了｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:image" content={window.location.origin + "/assets/img/common/ogp.png"} />
            <meta property="og:description" content="登録完了ページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
          </MetaTags>
          <header className="global-login-header-new forgot-password-header">
            <div className="logo-nav">
              <h1 className="logo">
                <a href="/">
                  <div className="new-logo-login">
                    <source
                      media="(max-width: 180px)"
                      srcSet="/assets/img/common/logo_gochi_header_mypage.svg"
                    />
                    <img
                      src="/assets/img/common/logo_gochi_header_mypage.svg "
                      alt="気持ちを「ごちそう」にして贈れるアプリ ごちめし"
                      loading="lazy"
                    />
                  </div>
                  <span className="title-page">GOCHIアカウントを新規登録</span>
                </a>
              </h1>
            </div>
          </header>

          <main>
            <div className="container">
                <div className="heading-lg">
                    <div className="title">
                        新規アカウント登録
                    </div>
                </div>

                {process.env.REACT_APP_MFA !== 'off' && (
                  <nav className="step-group signup-step-group">
                      <div className="step is-success">
                          <div className="step-btn">
                              <div className="step-outer-circle">
                                  <div className="step-inner-circle"></div>
                              </div>
                          </div>
                      </div>
                      <div className="step  is-success">
                          <div className="step-btn">
                              <div className="step-outer-circle">
                                  <div className="step-inner-circle"></div>
                              </div>
                          </div>
                      </div>
                      <div className="step is-disabled">
                          <div className="step-btn">
                              <div className="step-outer-circle">
                                  <div className="step-inner-circle"></div>
                              </div>
                          </div>
                      </div>
                      <div className="step is-disabled">
                          <div className="step-btn">
                              <div className="step-outer-circle">
                                  <div className="step-inner-circle"></div>
                              </div>
                          </div>
                      </div>
                  </nav>
                )}

                <form onSubmit={this.handleSubmitForm}>
                    <div className="signup-input">
                        <div className="signup-input-group">
                            <div className="signup-input-header">
                                <div className="heading-md">
                                    <div className="title">認証コードの入力</div>
                                </div>
                            </div>
                            <div className="signup-input-body">
                                <div className="text">
                                    メールアドレスに届いた認証コードをご入力ください。<br />
                                    認証コードが届くまでお時間がかかる場合がございます。
                                </div>
                                <div className="fieldset">
                                    <div className="fieldset-header">
                                    </div>
                                    <div className="fieldset-body mt-40">
                                        <div className="field-group field-group-column">
                                            <div className="field field-fluid">
                                                <input className="form-control"
                                                  placeholder="認証コードの入力"
                                                  type="text"
                                                  onChange={this.onConfirmCodeChange}
                                                  value={this.state.confirmCode}
                                                  name="username"
                                                />
                                            </div>
                                            <div className="">
                                                <a className="link text-underline" onClick={this.onResendCode}>認証コードを再送する</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="signup-input-footer">
                            <div className="btn-group signup-input-btn-group">
                                <button className="btn btn-rect signup-input-btn button-reset-pass" onClick={this.submitConfirm}><div>次へ</div></button>
                            </div>
                            {this.state.errorMessage &&
                              <div className="text-danger mb-2 fs-14 font-weight-bold">{t('pages.register.'+this.state.errorMessage)}</div>
                            }
                        </div>
                    </div>
                </form>
            </div>
          </main>


          <footer className="global-footer footer-mypage new-footer-login">
            <section className="global-footer-nav-area">
              <div className="container">
                <div className="section-header">
                  <div className="heading-xs">
                    <div className="title">
                      <h1>
                        <img
                          src="/assets/img/common/logo_gochi_footer.svg"
                          alt="ごちめし"
                          loading="lazy"
                        />
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="section-body footer-nav">
                  <ul className="footer-link-area">
                    <li>
                      <a href="https://gochimeshi.com/policy/">各種規約</a>
                    </li>
                    <li>
                      <a href="https://gochimeshi.com/policy/privacy/">
                        プライバシーポリシー
                      </a>
                    </li>
                    <li>
                      <a href="https://gochimeshi.com/policy/legal/">
                        特定商取引法に基づく表記
                      </a>
                    </li>
                    <li>
                      <a href="https://user-guide.gochimeshi.com/">よくある質問</a>
                    </li>
                    <li>
                      <a href="https://gochimeshi.com/info/">お問い合わせ</a>
                    </li>
                    <li>
                      <a href="https://www.gigi.tokyo/">運営会社（Gigi株式会社）</a>
                    </li>
                  </ul>
                </div>
              </div>
            </section>

              <div className="copyright">
                  <small>
                    GOCHI®︎ ごちめし®︎ こどもごちめし® びずめし®︎ ︎ さきめし®︎ は、
                    <br />
                    Gigi株式会社の登録商標です
                    <br />
                    GOCHIプラットフォームの機能は、
                    <br />
                    特許取得済み（特許番号：特許第7572763号）
                    <br />
                    © Gigi Inc.
                  </small>
              </div>
          </footer>
        </div>
      );
    }
}

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({ 
  showError: (showing, title, content) =>dispatch(showError(showing, title, content)), 
  saveUserInfo: (isLogined, isGuest, userInfo) => dispatch(saveUserInfo(isLogined, isGuest, userInfo)),
  showLoading: (showing) => dispatch(showLoading(showing)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RegisterConfirm));

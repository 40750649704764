import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import {connect} from "react-redux";
import {Redirect} from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import firebase from 'firebase/app';
import { Modal } from "react-bootstrap";
import 'firebase/auth';

//Login by cognito
import { Auth } from 'aws-amplify';
 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faApple, faGoogle } from '@fortawesome/free-brands-svg-icons';

// import DishInfor from './../Invite/DishInfor';
import {log, addParamUrl} from '../../../utils';
import AuthService from "../../../api/services/AuthService";
import UserService from "../../../api/services/UserService";
import {showError} from "../../../actions/action_error_alert";
import {saveUserInfo} from "../../../actions/action_users";
import {showLoading} from "../../../actions/action_loading";
import PopupConfirm from "../../../components/common/PopupConfirm";  
import Header from "../../layout/header/Header";
import ListShop from '../../common/ListShop';
import moment from "moment";
import LogService from "../../../api/services/LogService";

// Amplify.configure({
//     Auth: {
//     	identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
//     	region: process.env.REACT_APP_REGION,
//     	userPoolId: process.env.REACT_APP_USER_POOL_ID,
//         userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
// 	}
// });

class LoginMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirectTo: false,
			errorMessage: null,
			menuId: null,
			shopId: null,
			showPassword: false,
			goLogin: false,
			popupShowing: false,
			popupTitle: this.props.t('pages.mypage.title popup apple'),
			popupContent: this.props.t('pages.mypage.content popup apple'),
            src: "",
			paramShashoku: '',
			userNotConfirmed: false,
			confirmCode: '',
			cognitoUser: {},
			showListShopPopup: false,
			policyCheck: false,
            showNotificationPopup: false,
			showOtpInput: false,
			isSite: '',
		};
		this.onEmailChange = this.onEmailChange.bind(this);
		this.onPasswordChange = this.onPasswordChange.bind(this);
		this.onConfirmCodeChange = this.onConfirmCodeChange.bind(this);
		this.onShowHidePass = this.onShowHidePass.bind(this);
		this.loginByGochiAccount = this.loginByGochiAccount.bind(this);
		this.loginByFacebook = this.loginByFacebook.bind(this);
		this.loginByApple = this.loginByApple.bind(this);
		this.loginByGoogle = this.loginByGoogle.bind(this);
		this.loginAsGuestAccount = this.loginAsGuestAccount.bind(this);
		this.confirmLogin = this.confirmLogin.bind(this);
		this.getUserAttributes = this.getUserAttributes.bind(this);
		this.requestAuthentication = this.requestAuthentication.bind(this);
		this.changeUserLogin = this.changeUserLogin.bind(this);
		this.goToRegister = this.goToRegister.bind(this);
		this.changeGuestLogin = this.changeGuestLogin.bind(this);
		this.handlePopupConfirm = this.handlePopupConfirm.bind(this);
		this.showAppleConfirm = this.showAppleConfirm.bind(this);
		this.handlePopupClose = this.handlePopupClose.bind(this);
        // this.loadMoreShop = this.loadMoreShop.bind(this); 
		// this.getListShops = this.getListShops.bind(this); 
		this.handleShowListShops = this.handleShowListShops.bind(this);
		this.handleCloseListShopPopup= this.handleCloseListShopPopup.bind(this);
        this.handleShowNotificationPopup = this.handleShowNotificationPopup.bind(this);
        this.handleCloseNotificationPopup = this.handleCloseNotificationPopup.bind(this);
	}

	async loginByGoogle() {
		var provider = new firebase.auth.GoogleAuthProvider();
		firebase.auth().useDeviceLanguage();

		try {
			var result = await firebase.auth().signInWithPopup(provider);
			var userGoogle = result.user;
			log(['LOGIN USER GOOGLE: ', userGoogle]);
			const googleUserProvider = userGoogle.providerData.find( googleProvider => googleProvider.providerId === 'google.com' );
			var cognitoUserId = 'Google_' + googleUserProvider.uid;

			//update user to gochimeshi server
			await this.getUserAttributes(cognitoUserId, "GOOGLE", {
				username: googleUserProvider.displayName || (userGoogle.displayName || ""),
				email: googleUserProvider.email || (userGoogle.email || ""),
			});
		} catch (e) {
			log(e);
			if (e.code === "auth/account-exists-with-different-credential")
			{
				this.setState({
					errorMessage: "An account already exists with the same email address"
				})
			}else {
				this.setState({
					errorMessage: "Login has been canceled"
				})
			}
			LogService.sendLog({ loginType: 'loginByGoogle', 'code': e.code });
		}
	}

	async loginByApple() {
		this.setState({
			popupShowing: false,
		})
		var provider = new firebase.auth.OAuthProvider('apple.com');
		firebase.auth().useDeviceLanguage();

		try {
			var result = await firebase.auth().signInWithPopup(provider);
			var userApple = result.user;
			log(['LOGIN USER APPLE: ', userApple]);
			const appleUserProvider = userApple.providerData.find( appleProvider => appleProvider.providerId === 'apple.com' );
			var cognitoUserId = 'SignInWithApple_' + appleUserProvider.uid;

			//update user to gochimeshi server
			await this.getUserAttributes(cognitoUserId, "APPLE", {
		      username: appleUserProvider.displayName || (userApple.displayName || ""),
		      email: appleUserProvider.email || (userApple.email || ""),
		    });
	  } catch (e) {
	  		log(e);
			if (e.code === "auth/account-exists-with-different-credential")
			{
				this.setState({
					errorMessage: "An account already exists with the same email address"
				})
			}else {
				this.setState({
					errorMessage: "Login has been canceled"
				})
			}
			LogService.sendLog({ loginType: 'loginByApple', 'code': e.code });
	  }
	}

	async loginByFacebook() {
		var provider = new firebase.auth.FacebookAuthProvider();
		// provider.addScope('user_birthday');

		firebase.auth().useDeviceLanguage();

		try {
			var result = await firebase.auth().signInWithPopup(provider);
			var userFacebook = result.user;
			const fbUserProvider = userFacebook.providerData.find( fbProvider => fbProvider.providerId === 'facebook.com' );
			var facebookUserId = 'Facebook_' + fbUserProvider.uid;

			log(['userFacebook', userFacebook]); 
			if (!(fbUserProvider.email || userFacebook.email || "")) {
				LogService.sendLog({
					loginType: 'loginByFacebook',
					'code': 'no_email',
					facebookUserId
				});
			}
			//update user to gochimeshi server
			await this.getUserAttributes(facebookUserId, "FACEBOOK", {
		      username: fbUserProvider.displayName || (userFacebook.displayName || ""),
		      email: fbUserProvider.email || (userFacebook.email || ""),
		    });
		} catch (e) {
			log(e);
			if (e.code === "auth/account-exists-with-different-credential")
			{
				this.setState({
					errorMessage: "An account already exists with the same email address"
				})
			}else {
				this.setState({
					errorMessage: "Login has been canceled"
				})
			}
			LogService.sendLog({ loginType: 'loginByFacebook', 'code': e.code });
		}
	}

	getUserAttributes = async (
		cognitoUserId: string,
		providerName: SignInProvider,
		metadata?: { username: string; email: string },
		) => {
			var props = this.props;
			var thisView = this;

			let idToken = '';
			if (providerName === 'GOOGLE' || providerName === 'APPLE' || providerName === 'FACEBOOK') {
				idToken = await firebase.auth().currentUser.getIdToken(true);
			}

			try {
			  const { data } = await UserService.getUserList({
			    cognitoUserId,
			    sort: "createdAtDesc",
			    page: 1,
			    limit: 1,
			  });

			  if (data.count <= 0) {
					props.showLoading(false);
			    if (metadata) {
			      // データがなければ作る
			      const response = await UserService.createUser(
			        metadata.email,
			        metadata.username,
					cognitoUserId
			      );
					log(["log login ", response]);
			      if (response.status !== 200) {
			      	if (response.data.code === "validation.errors.Duplicate"){
						return thisView.setState({
							errorMessage: "Duplicate value is set_Item name_Email address"
						})
					}else {
						return thisView.setState({
							errorMessage: "Login has been canceled"
						})
					}
			      }else{
				      data.list.push(response.data);
				      data.count = 1;
				  }
			    } else {
			    	return thisView.setState({
						errorMessage: "Login has been canceled"
					})
					}
			  }

				const resAuth = await this.requestAuthentication(providerName, idToken);
				if (resAuth.status !== 200) {
					thisView.props.showLoading(false);
					return thisView.props.showError(true, "There is something wrong", "");
				}

				props.saveUserInfo(true, false, data.list[0]);

				var userInfo = {
					isLogined: true,
					isGuest: false,
					userId: data.list[0].userId,
					accountId: data.list[0].accountId,
					displayName: data.list[0].displayName,
					email: data.list[0].email,
					userImage: data.list[0].userImage && data.list[0].userImage.length > 0 && data.list[0].userImage[0].image
				};

				localStorage.setItem('userInfo', JSON.stringify(userInfo));
				this.props.showLoading(false);
				thisView.setState({
					redirectTo: true
				});
			} catch (error) {
			  	log('getUserAttributes error');
				this.props.showLoading(false);
				return thisView.setState({
					errorMessage: "Login has been canceled"
				})
			}
	};

	async requestAuthentication(providerName, idToken) {
		switch (providerName) {
			case "GOOGLE":
			case "APPLE":
			case "FACEBOOK":
				// const idToken = await firebase.auth().currentUser.getIdToken(true);
				return await AuthService.authenticateByFirebaseToken(idToken);
			// case "COGNITO":
			default:
				const cognitoSession = await Auth.currentSession();
				return await AuthService.authenticateByCognitoToken(cognitoSession.idToken.jwtToken, this.state.confirmCode);
		}
	}

	async loginByGochiAccount(e){
		e.preventDefault();
		//login by cognito provider
		log("login by cognito: " + this.state.email);
		log("config = " + Auth.configure());
		this.props.showLoading(true);
		if (!this.state.email) {
			this.props.showLoading(false);
			return this.setState({
				errorMessage: "Login has been canceled"
			})
		}

		if (!this.state.password) {
			this.props.showLoading(false);
			return this.setState({
				errorMessage: "Login has been canceled"
			})
		}
		try {
			const cognitoUser = await Auth.signIn(this.state.email, this.state.password);
			log('successful sign in: ' + cognitoUser.username);
			this.setState({cognitoUser: cognitoUser});
			log('Cognito challenge: ' + cognitoUser.challengeName);

			// Request MFA challenge from GOCHI platform after login Cognito success
			const loginMfaResponse = await AuthService.loginMfa({ cognitoUserId: cognitoUser.username });
			if (loginMfaResponse.status === 200) {
				this.setState({
					errorMessage: ''
				});
				if (loginMfaResponse.data.enableMfa === 1) {
					this.setState({
						showOtpInput: true
					});
				} else {
					//update user to gochimeshi server
					await this.getUserAttributes(cognitoUser.username, "COGNITO");
				}
			} else {
				this.setState({
					errorMessage: "Login has been canceled"
				});
			}
			this.props.showLoading(false);
		} catch (error) {
			console.log('error', error.code);
			if (error.code === 'UserNotConfirmedException'){
				this.setState({
					userNotConfirmed: true,
					redirectTo: true
				})
			}
			this.setState({
				errorMessage: "user not confirmed exception"
			})
			this.props.showLoading(false);
		}
	}

	onEmailChange(e) {
		this.setState({email: e.target.value});
	}

	onPasswordChange(e) {
		this.setState({password: e.target.value});
	}

	async confirmLogin(e) {
		e.preventDefault();

		this.props.showLoading(true);
		try {
			//update user to gochimeshi server
			await this.getUserAttributes(this.state.cognitoUser.username, "COGNITO"); 
		} catch (error) {
			console.log('error', error.code);
			if (error.code === 'CodeMismatchException') {
				this.setState({
					errorMessage: "Invalid code or auth state for the user"
				})
			} else {
				this.setState({
					errorMessage: "user not confirmed exception"
				})
			}
			this.props.showLoading(false);
		}
	}

	onConfirmCodeChange(e) {
		this.setState({confirmCode: e.target.value});
	}

	onShowHidePass(e){
		this.setState({
			showPassword: !this.state.showPassword
		})
	}

	loginAsGuestAccount(e){
        if (!this.state.policyCheck) {
            this.setState({
                showNotificationPopup: true
            })
            return;
        }
	    e.preventDefault();
	    var user = {};
	    this.props.saveUserInfo(true, true, user);
	    this.setState({
            redirectTo: true
        });

        var userInfo = {
			isLogined: true,
			isGuest: true
		};

		sessionStorage.removeItem("isRegister")
		localStorage.setItem('userInfo', JSON.stringify(userInfo));
	}

	UNSAFE_componentWillMount() {
		sessionStorage.removeItem("fullInviteContent");
		sessionStorage.removeItem("selectFriend");
		sessionStorage.removeItem("inviteContent");
		sessionStorage.removeItem("paymentMethod");
		sessionStorage.removeItem("menuInfo");
		sessionStorage.removeItem("comment");
		sessionStorage.removeItem("guestInfo");
		sessionStorage.removeItem("messageShop");
		sessionStorage.removeItem("selectPayment");
		sessionStorage.removeItem("paypayResponse");
		sessionStorage.removeItem("isRegister");
		var userInfo = this.props.userInfo;
		if (userInfo) {
			if(userInfo.isGuest === true) {
				localStorage.removeItem("userInfo");
				this.props.saveUserInfo(false, userInfo.isGuest, []);
			}
		}
    }

	async componentDidMount() {
		let url = window.location.search;
		let params = queryString.parse(url);
		var menuId = params.id;
		var shopId = params.shopId;
        var src = params.src;
        const fromShashoku = params.fromShashoku;
		var isSite = params.isSite;
        // var userInfo = this.props.userInfo;

        // let loginUserId = null;

        // if (userInfo) { 
        //     loginUserId = userInfo.userId;
		// } 

		if (src) {
			this.setState({
				src: src
			});
		}

		if (isSite) {
			this.setState({
				isSite: isSite
			});
		}

		this.setState({
			menuId: menuId
        });

		this.setState({
			shopId: shopId
		});
        
        if (fromShashoku) {
            localStorage.removeItem("userInfo");
            this.setState({
				paramShashoku: `&fromShashoku=${encodeURIComponent(fromShashoku)}`
			});
        }
	}

	showAppleConfirm() {
		this.setState({
			popupShowing: true,
		})
	}

	handlePopupClose() {
		this.setState({
			popupShowing: false,
		})
	}

	handlePopupConfirm() {
		this.loginByApple();
	}

	changeUserLogin(e) {
		localStorage.removeItem("userInfo")
		this.setState({
			goLogin: true
		})
	}
	
	goToRegister(e) {
		log(["state goToRegister"]);
		sessionStorage.setItem("isRegister", true)
		this.setState({
			redirectTo: true
		})
	}

	changeGuestLogin(e) {
		this.setState({
			goLogin: false
		})
	}

	handleShowListShops() {
		this.setState({
			showListShopPopup: true
		});
	};

	handleCloseListShopPopup() {
		this.setState({
			showListShopPopup: false
		});
	};

    handleShowNotificationPopup() {
        if (!this.state.policyCheck) {
            this.setState({
                showNotificationPopup: true
            })
        }
    }

    handleCloseNotificationPopup() {
        this.setState({
            showNotificationPopup: false
        })
    }

	render() {
		const { t } = this.props;

		let url = window.location.search;
		let params = queryString.parse(url);
		var menuId = params.id;
		var shopId = params.shopId;

		if (!menuId) {
			menuId = this.state.menuId;
		}

		if (!shopId) {
			shopId = this.state.shopId;
		}

		log(["state login", this.state]);
		var srcParam = this.state.src ? '&src=' + this.state.src : '';
		var isSiteParam = this.state.isSite ? {name: 'isSite', value: this.state.isSite} : {};

		const redirectTo = this.state.redirectTo;
		var notHasMenu = menuId ? false : true;
		var notHasMenuPrice = shopId ? false : true;
		var isRegister = sessionStorage.getItem('isRegister');

        if (redirectTo === true) {
			if (notHasMenu && notHasMenuPrice) {
				if (this.state.userNotConfirmed){
					window.history.pushState(null, null, addParamUrl('/login-confirm?registerInput=' + encodeURIComponent(this.state.email), [isSiteParam]));
					return <Redirect to={addParamUrl('/login-confirm?registerInput=' + encodeURIComponent(this.state.email), [isSiteParam])} />
				}
				window.history.pushState(null, null, addParamUrl('/mypage', [isSiteParam]));
				let link = addParamUrl("/mypage", [isSiteParam]);
				return <Redirect to={link} />
			} else if(isRegister){
				if (this.state.userNotConfirmed){
					if (!notHasMenuPrice) {
						window.history.pushState(null, null, addParamUrl('/login-confirm?shopId='  + this.state.shopId + '&registerInput=' + encodeURIComponent(this.state.email) + srcParam, [isSiteParam]));
						return <Redirect to={addParamUrl('/login-confirm?shopId='  + this.state.shopId + '&registerInput=' + encodeURIComponent(this.state.email) + srcParam, [isSiteParam])} />
					} else {
						window.history.pushState(null, null, addParamUrl('/login-confirm?id='  + this.state.menuId + '&registerInput=' + encodeURIComponent(this.state.email) + srcParam, [isSiteParam]));
						return <Redirect to={addParamUrl('/login-confirm?id='  + this.state.menuId + '&registerInput=' + encodeURIComponent(this.state.email) + srcParam, [isSiteParam])} />
					}
				}
				if (!notHasMenuPrice) {
					window.history.pushState(null, null, addParamUrl('/signup?shopId='  + this.state.shopId + srcParam, [isSiteParam]));
					let link = addParamUrl("/signup?shopId=" + this.state.shopId + srcParam + this.state.paramShashoku, [isSiteParam]);
					return <Redirect to={link} />
				} else {
					window.history.pushState(null, null, addParamUrl('/signup?id='  + this.state.menuId + srcParam, [isSiteParam]));
					let link = addParamUrl("/signup?id=" + this.state.menuId + srcParam + this.state.paramShashoku, [isSiteParam]);
					return <Redirect to={link} />
				}
			} else {
				if (this.state.userNotConfirmed){
					if (!notHasMenuPrice) {
						window.history.pushState(null, null, addParamUrl('/login-confirm?shopId='  + this.state.shopId + '&registerInput=' + encodeURIComponent(this.state.email) + srcParam, [isSiteParam]));
						return <Redirect to={addParamUrl('/login-confirm?shopId='  + this.state.shopId + '&registerInput=' + encodeURIComponent(this.state.email) + srcParam, [isSiteParam])} />
					} else {
						window.history.pushState(null, null, addParamUrl('/login-confirm?id='  + this.state.menuId + '&registerInput=' + encodeURIComponent(this.state.email) + srcParam, [isSiteParam]));
						return <Redirect to={addParamUrl('/login-confirm?id='  + this.state.menuId + '&registerInput=' + encodeURIComponent(this.state.email) + srcParam, [isSiteParam])} />
					}
				}
                if (this.state.menuId) {
                    window.history.pushState(null, null, addParamUrl('/menu?id=' + this.state.menuId + srcParam, [isSiteParam]));
                    let link = addParamUrl("/invite?id=" + this.state.menuId + srcParam + this.state.paramShashoku, [isSiteParam]);
                    return <Redirect to={link} />
                } else if (this.state.shopId) {
					window.history.pushState(null, null, addParamUrl('/menu?shopId=' + this.state.shopId + srcParam, [isSiteParam]));
                    let link = addParamUrl("/invite?shopId=" + this.state.shopId + srcParam + this.state.paramShashoku, [isSiteParam]);
                    return <Redirect to={link} />
				} else { 
					window.history.pushState(null, null, addParamUrl('/mypage', [isSiteParam]));
                    let link = addParamUrl("/mypage", [isSiteParam]);
                    return <Redirect to={link} />
                }
			}
		}
		

        var isLoggedIn = false;
        var isLoggedInUser = false;
        var userInfo = JSON.parse(localStorage.getItem('userInfo'));

        if (userInfo) {  
        	if (userInfo.userId) {
                isLoggedInUser = true; 
            } 
            isLoggedIn = userInfo.isLogined; 
		}
		if (isLoggedIn === true && notHasMenu) {
			// window.history.pushState(null, null, '/mypage');
			// let link = "/mypage";
			// return <Redirect to={link} />
		}

        const colorGreen = {
        	color: "#2AAA9F"
        };
        
        const expirationEndDate = this.props.menuInfo.menuComboDetail && this.props.menuInfo.menuComboDetail.expirationEndDate ? new Date(this.props.menuInfo.menuComboDetail.expirationEndDate) : null;
		const ticketFree = this.props.menuInfo.menuComboDetail && this.props.menuInfo.menuComboDetail.quantityFree && this.props.menuInfo.menuComboDetail.quantityFree !== 0 ? this.props.menuInfo.menuComboDetail.quantityFree : 0;
		const feePaidByShop = (Object.entries(this.props.menuInfo).length > 0 && this.props.menuInfo.feePaidByShop) ? this.props.menuInfo.feePaidByShop : false;
		console.log('isLoggedIn:', isLoggedIn, notHasMenu);
		
		const menuChildType = this.props.menuInfo.menuChildType;
		const isNoPayment2 = this.props.menuInfo?.noPayment === 2;

        return (
			<>
				<MetaTags>
					<title>ログイン｜飲食店のメニューを贈れる「ごちめし」</title>
					<meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
					<meta name="description" content="ログインページです。「ごちめし」は飲食店のメニューをプレゼントできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
				</MetaTags>

				<PopupConfirm 
					title={this.state.popupTitle} 
					showing={this.state.popupShowing} 
					content={this.state.popupContent} 
					handleConfirm={this.handlePopupConfirm} 
					handleClose={this.handlePopupClose}
				/>
				<div className={isLoggedIn ? 'content' : 'content not-logined'}>
					<Header title=
						{notHasMenu ? 
							t('pages.mypage.login')
						: 
							t('pages.login.inviteWithMenu')
						}
						isLoggedIn={isLoggedInUser}
						t={this.props.t} 
                    /> 
					{isLoggedIn &&
						<div className="user-name fs-12 font-weight-bold text-center mb-4">
							{t('pages.login.now')}
							<span className="text-green" style={colorGreen}>
								{this.props.userInfo.displayName}
							</span>
							{t('pages.login.Logined in with')}
						</div>
					}

					{!notHasMenu ?
					<div className="dish-infor mt-4 mt-4-custome">
						<ul className="progressbar align-center custome-process">
							<li className="step-choice active"><strong>{t('steps.step_choice')}</strong></li>
							<li className="step-select"><strong>{t('steps.step_select')}</strong></li>
							<li className="step-confirm"><strong>{t('steps.step_confirm')}</strong></li>
							<li className="step-complete"><strong>{t('steps.step_complete')}</strong></li>
						</ul>
						<div className="media">
							<div className="dish-image">
								<figure className="mb-0 dish-fig">
									<img 
										src={this.props.menuInfo.menuImage ? 
													(this.props.menuInfo.menuImage.length > 0) ? 
													this.props.menuInfo.menuImage[0].image : 
													require("../../../assets/img/icons/gochi_noimg-ver4.jpg") : 
												require("../../../assets/img/icons/gochi_noimg-ver4.jpg")} 
										alt={this.props.menuInfo.menuName} 
										width="100%"
									/>
								</figure>
								{this.props.menuInfo && this.props.menuInfo.menuCombo === 1 && (
									<>
                                        <div className="triangle-label">
                                            <span>{t('pages.invite.Coupon')}</span>
                                        </div>

										{ticketFree !== 0 && 
                                            <div className="bonus-label">
                                                <span className="clr-fff">
													{`+`}
													{t('pages.invite.Advantageous (total)', { 
														freeTicketCount: ticketFree, 
														totalCoupon: ticketFree + this.props.menuInfo.menuComboDetail.quantity})}
												</span>
                                            </div>
                                        }
                                    </>
								)}
							</div>
							<div className="media-body dish-body pl-2 pl-2-custome">
								<div className="mt-0 dish-link fs-19" >{this.props.menuInfo.menuName}</div>
								{this.props.menuInfo && this.props.menuInfo.menuCombo === 1 && (
									<div className="mt-1 dish-link fs-19">
										{this.props.menuInfo.menuComboDetail ? this.props.menuInfo.menuComboDetail.includedTaxPriceCombo : 0}円ｘ
										{this.props.menuInfo.menuComboDetail ? this.props.menuInfo.menuComboDetail.quantity + ticketFree : 0}{t('pages.login.sheet')}
										{ticketFree !== 0 ? `(${t('pages.invite.Include N ticket free', {number: ticketFree})})` : null}
									</div>
								)}
								<div className="line-horizontal menu-name-ln pd-bold"></div>
								{/* <div className="dish-price fs-14">
									¥{this.props.menuInfo.includedTaxPrice && 
										this.props.menuInfo.includedTaxPrice.toLocaleString()}円
										（{this.props.menuInfo.taxType === 'DONATION' ? t('pages.invite.TaxDonation') : t('pages.invite.Tax')}{this.props.menuInfo.excludedTaxPrice && this.props.menuInfo.excludedTaxPrice.toLocaleString()}円）
								</div> */}
								<div className="mt-2 fs-12 mt-2-custome">
									<p className="mb-0 font-weight-bold one-line fs-13 local-shop"><i className="icon-location"></i> {this.props.shopInfo.shopName || this.props.groupInfo.groupName}</p>
									<p className="mb-0 fs-13">
										{this.props.shopInfo.prefectures ? this.props.shopInfo.prefectures : ''}
										{this.props.shopInfo.city ? this.props.shopInfo.city : ''}
										{this.props.shopInfo.address ? this.props.shopInfo.address: ''}
										{this.props.shopInfo.building ? this.props.shopInfo.building : ''}
									</p>
									{this.props.shopInfo.showPhone === 1 &&
										<p className="mb-0 fs-14 text-green">{this.props.shopInfo.phone}</p>
									}
								</div>
							</div>
						</div>
						{this.props.menuInfo.description && (
							<div className="fs-13 des-menu mt-2 break-line mt-2-custome">
								{/* <ShowMoreText
									lines={3}
									more={t('pages.mypage.showmore')}
									less={t('pages.mypage.showless')}
									anchorClass='showmore'
									onClick={this.executeOnClick}
									expanded={false}
									width={0}
								>
									<span className='break-all'>
										{this.props.menuInfo.description}
									</span>
								</ShowMoreText> */}
								{this.props.menuInfo.description}
							</div>
						)}
						

						{this.props.menuInfo.menuComboDetail && 
							this.props.menuInfo.menuComboDetail.quantityFreeDes && (
								<>
									<div className="arrow-up"></div>
									<div className="div-free-ticket-desc break-line">
										<div className="font-weight-bold">{t('pages.invite.Bonus')}</div>
										<div>{this.props.menuInfo.menuComboDetail.quantityFreeDes}</div>
									</div>
								</>
						)}
						{ticketFree !== 0 && (
							<div className={(feePaidByShop && typeof feePaidByShop !== 'undefined') ? "mt-2 has-feePaidByShop mt-2-custome" : "mt-2 mt-2-custome"}>
								<span className="menu-combo-total-coupons">
									{`+`}
									{t('pages.invite.Advantageous (total)', { 
										freeTicketCount: ticketFree, 
										totalCoupon: ticketFree + this.props.menuInfo.menuComboDetail.quantity})}
								</span>
							</div>
						)}
						{feePaidByShop && typeof feePaidByShop !== 'undefined' && (
							<div className="mt-2 mb-3 mt-2-custome">
								<span className="menu-combo-total-coupons">
									{t('pages.invite.Fee paid by shop')}
								</span>
							</div>
						)}

						{this.props.menuInfo.menuExpiration && (
							<div className="mt-2">
								<span className="fs-12 clr-000000">使用期限</span>
									<span className="text-red fs-13 mt-2 font-weight-bold ml-2 mt-2-custome">
										{moment(this.props.menuInfo?.menuExpiration).format('YYYY年MM月DD日まで')}
									</span>
							</div>
						)}

						{/*{this.props.menuInfo.menuComboDetail && 
							this.props.menuInfo.menuComboDetail.expiration && (
							<div>
								<span className="fs-12 clr-000000">{t('pages.login.usage period')}</span>
								<span className="text-red fs-13 mt-2 font-weight-bold ml-2 mt-2-custome">{t('pages.login.available days', {days: this.props.menuInfo.menuComboDetail.expiration})}</span>
								
							</div>
						)}

						{expirationEndDate && (
							<div>
								<span className="fs-12 clr-000000">{t('pages.login.usage period')}</span>
								<span className="text-red fs-13 mt-2 font-weight-bold ml-2 mt-2-custome">
									{t('pages.login.available to date', {date: `${expirationEndDate.getFullYear()}/${expirationEndDate.getMonth() +
										1 < 10 ? '0' : ''}${expirationEndDate.getMonth() +
										1}/${expirationEndDate.getDate()}`})}
								</span>
						</div>
						)}*/}

						{this.props.menuInfo.menuComboDetail && 
							this.props.menuInfo.menuComboDetail.numberUsePerDay === 1 && (
								<div>
									<span className="fs-12 clr-000000">{t('pages.login.usage limit')}</span>
									<span className="text-red fs-13 font-weight-bold ml-2">{t('pages.login.1 sheet per day')}</span>
								</div>
						)}

						{this.props.menuInfo && (this.props.menuInfo.menuChildType === 3 || this.props.menuInfo.menuChildType === 4 || this.props.menuInfo.menuChildType === 5) && !this.state.goLogin && (
							<button type="button" onClick={this.handleShowListShops} className="btn btn-outline-green-38 mt-2 align-center fs-13 mt-2-custome line-height-4" style={{"height": "30px"}}>
								{t('pages.login.List of stores where this meal voucher can be used')}
							</button>
						)}
						<div className="line-horizontal mt-3 mt-3-custome"></div>
						<div className="form-group mt-2 mb-2 mt-2-custome">
							<div className="d-flex justify-content-between fs-18 font-weight-bold clr-000000">
								<div className="mr-2">
									{isNoPayment2 ? '申込額' : t('pages.invite.Bill amount')}
									</div>
								<div className="fs-19">
									{this.props.menuInfo && this.props.menuInfo.chargeAmount ? this.props.menuInfo.chargeAmount.toLocaleString() : 0}
									<span className="fs-13">{t('pages.invite.Yen Unit')}</span>
								</div>
							</div>
						</div>
						<div className="line-horizontal"></div>
					</div>
					: null}

					{isLoggedIn ? (
                        !notHasMenu ? (
                            <div className="form-group text-center mt-4">
                                <div className="mt-3 gochi-policy-check custom-checkbox mt-3-custome">
                                    <label className="font-weight-bold">
                                        <a href='https://gochimeshi.com/policy/terms/' target='_blank' rel='noopener noreferrer' className="link-green-99E">
                                            {t('pages.login.Gochi terms')}
                                        </a>
                                        ・
                                        <a href='https://gochimeshi.com/policy/privacy/' target='_blank' rel='noopener noreferrer' className="link-green-99E">
                                            {t('pages.login.Gochi privacy policy')}
                                        </a>
                                    </label>
                                </div>
                                <div className={`mt-3 mb-5 gochi-policy-check custom-control custom-checkbox custom-checkbox-inline custome-checkbox mt-3-custome ${menuChildType === 5 ? `mb-4-custome` : `mb-5-custome`}`}>
                                    <input checked={this.state.policyCheck} onChange={e => this.setState({policyCheck: e.target.checked})} id="policy-check" type="checkbox" className="custom-control-input" />
                                    <label htmlFor="policy-check" className="custom-control-label text-left fs-16 font-weight-bold text-black">
                                        {t('pages.login.Agree to the terms')} 
                                    </label>
                                </div>
                                    
                                {menuChildType === 5 && (
                                  <div className="text-red fs-13 text-left mb-3-custome">
                                    ※本チケットは1アカウントにつき1度しか購入することができません。
                                    <br/>
                                    ※購入済みチケットはマイページよりチャージして利用が可能です。
                                  </div>
                                )}
                                <a 
                                    href={this.state.policyCheck && (addParamUrl(`/invite?id=`+this.state.menuId+srcParam + this.state.paramShashoku, [isSiteParam]))} 
                                    className={`btn btn-green-38 align-center ${this.state.policyCheck ? '' : 'disabled-btn disabled-action-btn'}`}
                                    onClick={this.handleShowNotificationPopup}
                                >
                                    <span className="white-btn">{isNoPayment2 ? 'このメニューを申込する' : t('pages.login.inviteWithMenu')} <i className="icon-next"></i></span>
                                </a>

                                <a 
                                    href={addParamUrl(`/logout?id=`+this.state.menuId+srcParam + this.state.paramShashoku, [isSiteParam])} 
                                    className="btn btn-outline-green-38 mt-3 align-center btn-outline-green-99E mt-3-custome"
                                >
                                    {t('pages.login.Login with another user')}
                                </a>
                            </div>
                        ) : (
                            <>
                                <figure className="mt-5 dish-fig">
									<img src={require("../../../assets/img/icons/header_login.png")} alt="register" width="100%" />
								</figure>

                                <a
									href={this.state.menuId ? addParamUrl(`/login?id=`+this.state.menuId+srcParam + this.state.paramShashoku, [isSiteParam]) : (this.state.shopId ? addParamUrl(`/login?shopId=`+this.state.shopId+srcParam + this.state.paramShashoku, [isSiteParam]) : addParamUrl('/login', [isSiteParam]))}
									className="btn btn-outline-green-38 mt-4 align-center"
								>
                                    {t('pages.login.loginUser')}
                                </a>

                                <a href={addParamUrl(`/signup`, [isSiteParam])} className="btn btn-green-38 mt-4 align-center">
                                    <FontAwesomeIcon icon={faUserPlus} className="mr-1" />
                                    {t('pages.mypage.register button')}
                                </a>

                                <div>
									<div className="mt-3 link-policy mt-3-custome"> 
                                        {t('pages.login.By continuing to purchase')}
                                        <a href='https://gochimeshi.com/policy/terms/' target='_blank' rel='noopener noreferrer'>
                                            {t('pages.login.Terms of Service')}
                                        </a>
                                        {t('pages.login.You agree to')}
                                        <a href='https://gochimeshi.com/policy/privacy/' target='_blank' rel='noopener noreferrer'>
                                            {t('pages.login.Privacy Policy')}
                                        </a>
                                        {t('pages.login.Is considered to agree with')} 
                                        {/* 購入を続行することで、さきめし/ごちめしの
                                        <a href="https://gochimeshi.com/policy/terms/" target="_blank" rel="noopener noreferrer">利用規約</a>
                                        及び<a href="https://gochimeshi.com/policy/privacy/" target="_blank" rel="noopener noreferrer">プライバシーポリシー</a>に同意するものとみなされます。 */}
                                    </div>
                                    <div className="mt-3 link-note mt-3-custome">
                                        {t('pages.login.If you do not receive the receipt email')}<br />
                                        <a href="https://support.gigi.tokyo/contact/receipt/" target="_blank" rel="noopener noreferrer">{t('pages.invite.link detail')}</a>
                                    </div>
                                </div>
                            </>
                        )
					) : (
						this.state.showOtpInput ? (
							<div>
								<figure className="mt-5 dish-fig">
									<img src={require("../../../assets/img/icons/heading_authen@2x.png")} alt="Authentication" width="100%" />
								</figure>

								<div className="form-block mt-3 mt-3-custome">
									<form onSubmit={this.confirmLogin} method="post">
										<div className="form-group">
											<input
												type="text"
												name="code"
												placeholder="123456"
												className="form-control"
												onChange={this.onConfirmCodeChange}
												defaultValue={this.state.confirmCode}
											/>
										</div>
										{this.state.errorMessage &&
											<div className="text-danger mb-2 fs-14 font-weight-bold">{t('pages.login.'+this.state.errorMessage)}</div>
										}
										<div className="form-group text-center">
											<button type="submit" className="btn btn-green-38">{t('pages.login.loginNormal')}</button>
										</div>
									</form>
								</div>
							</div>
						) : (
						<div>
							{notHasMenu ?
								<figure className="mt-5 dish-fig">
									<img src={require("../../../assets/img/icons/header_login.png")} alt="register" width="100%" />
								</figure>
							: null}
							{!this.state.goLogin ? (
							<>	
								<div className="mt-3 mt-3-custome">
                                    <div className="text-center">
                                        <div className="mt-3 gochi-policy-check custom-checkbox mt-3-custome">
                                            <label className="font-weight-bold">
                                                <a href='https://gochimeshi.com/policy/terms/' target='_blank' rel='noopener noreferrer' className="link-green-99E">
                                                    {t('pages.login.Gochi terms')}
                                                </a>
                                                ・
                                                <a href='https://gochimeshi.com/policy/privacy/' target='_blank' rel='noopener noreferrer' className="link-green-99E">
                                                    {t('pages.login.Gochi privacy policy')}
                                                </a>
                                            </label>
                                        </div>
                                        <div className={`mt-3 mb-5 gochi-policy-check custom-control custom-checkbox custom-checkbox-inline custome-checkbox mt-3-custome ${menuChildType === 5 ? `mb-4-custome` : `mb-5-custome`}`}>
                                            <input checked={this.state.policyCheck} onChange={e => this.setState({policyCheck: e.target.checked})} id="policy-check" type="checkbox" className="custom-control-input" />
                                            <label htmlFor="policy-check" className="custom-control-label text-left fs-16 font-weight-bold text-black">
                                                {t('pages.login.Agree to the terms')} 
                                            </label>
                                        </div>
                                    </div>
                                    {(!notHasMenu && menuChildType === 5) && (
                                      <div className="text-red fs-13">
                                        ※本チケットは、地域通貨チケットに該当します。地域通貨チケットメニューの購入・利用は、会員登録とログインが必要です。
                                        <br/>
                                        ※本チケットは1アカウントにつき1度しか購入することができません。
                                        <br/>
                                        ※購入済みチケットはマイページよりチャージして利用が可能です。
                                      </div>
                                    )}
									{(!notHasMenu && menuChildType !== 5) ? 
										<button onClick={this.loginAsGuestAccount} className={`btn btn-green-38 ${this.state.policyCheck ? '' : 'disabled-btn disabled-action-btn'}`}>
											{isNoPayment2 ? 'このメニューを申込する' : t('pages.login.loginAsGuest')}
										</button>
									: null}
									<a
										href={(menuChildType !== 5 || (this.state.policyCheck && menuChildType === 5)) && (this.state.menuId ? addParamUrl(`/login?id=`+this.state.menuId+srcParam + this.state.paramShashoku, [isSiteParam]) : (this.state.shopId ? addParamUrl(`/login?shopId=`+this.state.shopId+srcParam + this.state.paramShashoku, [isSiteParam]) : addParamUrl('/login', [isSiteParam])))}
										onClick={menuChildType === 5 && this.handleShowNotificationPopup}
										className={`btn btn-transition-none mt-3 align-center ${menuChildType === 5 ? `btn-green-38 ${this.state.policyCheck ? '' : 'disabled-btn disabled-action-btn'}` : 'btn-outline-green-38 btn-outline-green-99E'} mt-3-custome`} style={{lineHeight: "52px"}}
									>
										{isNoPayment2 ? 'ログインして申込する' : t('pages.login.loginUser')}
									</a>
									{
									  !notHasMenu ? 
										<a href={this.state.menuId ? addParamUrl(`/signup?id=`+this.state.menuId+srcParam + this.state.paramShashoku, [isSiteParam]) : (this.state.shopId ? addParamUrl(`/signup?shopId=`+this.state.shopId+srcParam + this.state.paramShashoku, [isSiteParam]) : addParamUrl('/signup', [isSiteParam]))}
											onClick={this.goToRegister} 
											className={`btn btn-link ${menuChildType === 5 ? 'mt-40' : 'mt-4'}`}
											style={{height: 'auto', lineHeight: '26px'}}
										>
											<span className="btn-back fs-13 font-weight-bold">
												{t('pages.mypage.register user button')}
											</span>
									  </a> : null 
									}

                  {menuChildType === 5 && (
                    <div className="mt-3 link-note mt-4-custome align-center font-weight-bold">
                      {t('pages.login.If you do not receive the receipt email')}
                      <a href="https://support.gigi.tokyo/contact/receipt/" target="_blank" rel="noopener noreferrer">{t('pages.invite.link detail')}</a>
                    </div>
                  )}
									
									{notHasMenu ? 
									<a href={addParamUrl(`/signup`, [isSiteParam])} className="btn btn-green-38 mt-4 align-center">
										<FontAwesomeIcon icon={faUserPlus} className="mr-1" />
										{t('pages.mypage.register button')}
									</a>
									: null}
								</div>
							</>
							) : (
							<div className="form-block mt-3 mt-3-custome">
								<div className="fs-16 font-weight-bold mb-2">{t('pages.login.text login')}</div>
								<div className="fs-14 mb-3">{t('pages.login.text des login')}</div>
								<div className="form-group position-relative">
									<button className="btn btn-login-apple" onClick={this.showAppleConfirm}>
										<FontAwesomeIcon icon={faApple} className={"mr-2 fs-24 icon-position-left"}/>
										<span className="fs-16">{t('pages.login.loginApple')}</span>
									</button>
								</div>

								<div className="form-group position-relative">
									<button className="btn btn-login-fb" onClick={this.loginByFacebook}>
										<FontAwesomeIcon icon={faFacebook} className={"mr-2 fs-24 icon-position-left"}/>
										{t('pages.login.loginFB')}
									</button>
								</div>

								<div className="form-group position-relative">
									<button className="btn btn-login-gg" onClick={this.loginByGoogle}>
										<FontAwesomeIcon icon={faGoogle} className={"mr-2 fs-24 icon-position-left"}/>
										<span className="fs-16">{t('pages.login.loginGoogle')}</span>
									</button>
								</div>

								{this.state.errorMessage &&
									<div className="text-danger mb-2 fs-14 font-weight-bold">{t('pages.login.'+this.state.errorMessage)}</div>
								}

								<form onSubmit={this.loginByGochiAccount} method="post" className="mt-4">
									<div className="form-group">
										<input
											id="email"
											type="text"
											name="email"
											placeholder={t('pages.login.Email address')}
											className="form-control"
											onChange={this.onEmailChange}
											defaultValue={this.state.email}
										/>
									</div>

									<div className="form-group ex-has-icon">
										<input
											id="password"
											type={this.state.showPassword ? "text" : "password"}
											name="password"
											placeholder={t('pages.login.Password')}
											className="form-control"
											onChange={this.onPasswordChange}
											defaultValue={this.state.password}
										/>

										<span className="btn btn-show-hide-pass" onClick={this.onShowHidePass}>
											<FontAwesomeIcon icon={this.state.showPassword ? faEyeSlash : faEye} className="icon-position-right" />
										</span>
									</div>

									<div className="form-group text-center">
										<button className="btn btn-green-38">{t('buttons.login')}</button>
									</div>

								</form>
								<div className="form-group text-center">
									<a href={addParamUrl(`/password_reset`, [isSiteParam])} className="fs-13 clr-636363 link-forgot-txt">
                                    	{/* <FontAwesomeIcon icon={faQuestion} className="mr-1" /> */}
										{t('pages.forgotPassword.title_forgot_password')}
                                	</a>
								</div>

							</div>
							)}
							{!this.state.goLogin && menuChildType !== 5 && (
							<div>
								<div className="mt-3 link-note text-center font-weight-bold mt-3-custome">
									{t('pages.login.If you do not receive the receipt email')}
									<a href="https://support.gigi.tokyo/contact/receipt/" target="_blank" rel="noopener noreferrer">{t('pages.invite.link detail')}</a>
								</div>
							</div>
							)}
							{this.state.goLogin && (
							<div>
								<div 
									className="mt-3 link-policy mt-3-custome" >
                                    {t('pages.login.By continuing to log in')}
                                    <a href="https://gochimeshi.com/policy/terms/" target="_blank" rel="noopener noreferrer">{t('pages.login.Terms of Service')}</a>
                                    {t('pages.login.You agree to')}
                                    <a href="https://gochimeshi.com/policy/privacy/" target="_blank" rel="noopener noreferrer">{t('pages.login.Privacy Policy')}</a>
                                    {t('pages.login.Is considered to agree with')}
								</div>
								<div className="form-group text-center">
									<button className="btn btn-link mt-2 mt-2-custome" onClick={this.changeGuestLogin}>
										<span className="btn-back">
											<i className="icon-back"></i>
											{t('pages.login.back')}
										</span>
									</button>
								</div>
							</div>
							)}
						</div>
						)
					)}
				</div>
				{this.state.showListShopPopup && 
					<ListShop
						showListShopPopup={this.state.showListShopPopup}
						handleCloseListShopPopup={this.handleCloseListShopPopup}
					/>
				}
                {this.state.showNotificationPopup && (
                    <Modal
                        className="modal-dialog-centereds custom-border-modal modal-custom"
                        size="sm"
                        show={this.state.showNotificationPopup}
                        onHide={this.handleCloseNotificationPopup}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        // backdrop="static"
                    >
                        <Modal.Body>
                            <div className="text-center font-weight-bold text-red mb-3-custome fs-20 pt-3-custome">
                                {t(
                                    "other.Title notification popup policy above"
                                )}
                                <br/>
                                {t(
                                    "other.Title notification popup policy below"
                                )}
                            </div>
                            <div className="text-left text-black fs-16">
                              {menuChildType === 5 ? t("other.Content notification popup policy gochi charge") : t("other.Content notification popup policy")}
                            </div>
                        </Modal.Body>
                        <div className="mb-3-custome p-custome">
                            <button
                                type="button"
                                onClick={this.handleCloseNotificationPopup}
                                className="btn btn-green-38"
                            >
                                {t("other.Close notification popup")}
                            </button>
                        </div>
                    </Modal>
                )}
			</>
        );
    }
}

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
	showError: (title, content) => dispatch(showError(true, title, content)),
	saveUserInfo: (isLogined, isGuest, userInfo) => dispatch(saveUserInfo(isLogined, isGuest, userInfo)),
	showLoading: (showing) => dispatch(showLoading(showing)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LoginMenu));

// // the translations
import translationEN from './en.json';
import translationJP from './ja.json';
import translationZH from './zh.json';
import translationJPGS from './ja_gs.json';

export const resources = {
  en: {
    translation: translationEN
  },
  ja: {
    translation: translationJP
  },
  zh: {
    translation: translationZH
  },
  ja_gs: {
    translation: translationJPGS
  }
};

const initialState = [];

export default (state = initialState, action) => { 
  switch (action.type){
    case 'SAVE_PAYMENT_METHOD':  
      return  action.paymentMethod;
    default:
      return state;
  }
};


import React, { useEffect, useState } from "react";
import { SelectUserKidModal } from "../../common/SelectUserKidModal";
import { ModalGroup } from "../../common/ModalGroup";
import { InputGochiKidPriceModal } from "../../common/InputGochiKidPriceModal";

export function GochiKidUsedDetail(props) {
  const item = props.itemGochiKidUsed;
  const [showSelectUserKid, setShowSelectUserKid] = useState(false);
  const [showInputGochiKidPrice, setShowInputGochiKidPrice] = useState(false);
  const [choiceShopId, setChoiceShopId] = useState(false);
  const [choiceMenuId, setChoiceMenuId] = useState(false);

  useEffect(() => {
    props.getUserKidCanUses(item.gochiKidGroupId)
  }, [item]);

  function handleOpenSelectUserKid(shopId, menuId) {
    setShowSelectUserKid(true);
    setChoiceShopId(shopId);
    setChoiceMenuId(menuId);
  }

  function handleOpenInputGochiKidPriceModal(shopId) {
    setShowInputGochiKidPrice(true);
    setChoiceShopId(shopId);
    setChoiceMenuId(null);
    props.setUseAmount(null);
  }

  function handleCloseInputGochiKidPriceModal() {
    setShowInputGochiKidPrice(false);
    if (props.numUserKidUse > 1) {
      setShowSelectUserKid(true);
    }
  }

  function handleCloseModalGroup() {
    setShowSelectUserKid(false);
    setShowInputGochiKidPrice(false);
    props.handleClosePopupGochiKidUsedDetail(true);
    document.getElementsByTagName("html")[0].classList.remove("active-modal");
  }
  
  var image = item.menuImage && item.menuImage.length > 0 && item.menuImage[0].image ? 
                  item.menuImage[0].image : props.NO_IMAGE_MENU;
  var shopName = item?.shopName ? item.shopName : null;
  var menuName = item?.menuName ? item.menuName : null;
  var userKidNumUse = item?.userKidNumUse ? item.userKidNumUse : 0;
  var usedAmountPerKid = item.usedAmount ? (item.usedAmount / (userKidNumUse || 1)) : 0;
  var imageThank = item.gochiKidThanksMessage && item.gochiKidThanksMessage.length > 0 && item.gochiKidThanksMessage[0].thanksImageUrl ? 
                  item.gochiKidThanksMessage[0].thanksImageUrl : props.NO_IMAGE_MENU;
  const gochiKidGroupId = item.gochiKidGroupId ? "&gochiKidGroupId=" + item.gochiKidGroupId : "";

  return (
    <div
      className={`modal modal-profile modal-profile-01 modal-01 modal-lg history-modal gochi-kid-used-modal ${props.showPopupGochiKidUsedDetail ? 'is-show' : ''}`}
      tabIndex={-1}
    >
      <div className="modal-header-history">
        <button
          className="modal-btn modal-btn-close"
          id="modalClose"
          onClick={props.handleClosePopupGochiKidUsedDetail}
          type="button"
        >
          <svg className="icon">
            <title>閉じる</title>
            <use xlinkHref="/assets/img/common/icon.svg#times" />
          </svg>
        </button>
      </div>
      <div className="body-gochi-kid-used-modal">
        <div className="gochi-kid-support-item">
          <dl className="list-description text-sm">
            <div className="gochi-kid-digest">
              <div className="gochi-kid-thumbnail">
                <div className="thumbnail">
                  <img
                    src={image}
                    alt="menu image"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="shop-header-group">
                <div className="shop-header">
                  <div className="shop-name">
                    {shopName}
                  </div>
                  <h2 className='menu-name'>
                    {menuName}
                  </h2>
                </div>
                <hr />
                {item.menuId
                  ?
                    <div className="price-area">
                      <span className="unit">¥</span>
                      <span className="num">
                        {`${usedAmountPerKid?.toLocaleString()} x ${userKidNumUse}`}
                      </span>
                    </div>
                  :
                    <div className="price-area">
                      <div>
                        <div>
                          <span>利用人数: </span>
                          <span className="num">{userKidNumUse}</span>
                          <span className="unit">人</span>
                        </div>
                        <div>
                          <span>合計金額: </span>
                          <span className="num">{item.usedAmount?.toLocaleString()}</span>
                          <span className="unit">円</span>
                        </div>
                      </div>
                    </div>
                }
                {/*<div className="box-btn">
                  <button
                    className="btn btn-use-gochi"
                    disabled={props.thanksMessageIsNull}
                    onClick={() => {
                      if (props.numUserKidUse === 1) {
                        if (item.menuId) {
                          window.location.href = `/kodomo/use?shopId=${item.shopId}&menuId=${item.menuId}&userKidIds=${props.choiceUserKidId}${gochiKidGroupId}`;
                        } else {
                          // menu price
                          handleOpenInputGochiKidPriceModal(item.shopId);
                        }
                      } else if (props.numUserKidUse > 1) {
                        handleOpenSelectUserKid(item.shopId, item.menuId)
                      } else if (!props.numUserKidUse) {
                        props.handShowErrorUseGochiKidPopup("本日の食堂利用回数を超えました。");
                      }
                    }}
                  >
                    もう一度利用する
                  </button>
                </div>*/}
              </div>
            </div>
          </dl>
        </div>
        <div className="detail-box">
          <div className="detail-line">
            <div className="title">利用した日</div>
            <div className="text">{item.usedAt ? props.formatDate(item.usedAt) : ''}</div>
          </div>
          <div className="detail-line">
            <div className="title">店舗住所</div>
            <div className="text">{`${item.prefectures ? item.prefectures : ''} ${item.city ? item.city : ''} ${item.address ? item.address : ''} ${item.building ? item.building : ''}`}</div>
          </div>
          <div className="detail-line">
            <div className="title">投稿した感謝メッセージ</div>
            <div className="text">{item.thanksMessage}</div>
          </div>
          
          {item.gochiKidThanksMessage && item.gochiKidThanksMessage.length > 0 && item.gochiKidThanksMessage[0].thanksImageUrl && (
            <div className="detail-line thank-image-box">
              <div className="title">投稿した写真</div>
              <img
                className="image-thank"
                src={imageThank}
                alt="gochi kid used image"
              />
            </div>
          )}
        </div>
      </div>
      {(showSelectUserKid || showInputGochiKidPrice ) && (
        <ModalGroup handleClosePopupDetailGochi={handleCloseModalGroup}>
          <SelectUserKidModal 
            isShow={showSelectUserKid}
            onHide={() => setShowSelectUserKid(false)}
            handleOpenInputGochiKidPriceModal={handleOpenInputGochiKidPriceModal}
            listUserKids={props.listUserKids}
            setChoiceUserKidIds={props.setChoiceUserKidIds}
            choiceShopId={choiceShopId}
            choiceMenuId={choiceMenuId}
            gochiKidGroupId={item.gochiKidGroupId}
          />

          <InputGochiKidPriceModal
            isShow={showInputGochiKidPrice}
            onHide={handleCloseInputGochiKidPriceModal}
            choiceShopId={choiceShopId}
            choiceUserKidIds={props.choiceUserKidIds}
            useAmount={props.useAmount}
            setUseAmount={props.setUseAmount}
            gochiKidGroupId={item.gochiKidGroupId}
          />
        </ModalGroup>
        )}
    </div>
  );
}

import React, { Component } from "react"; 
import { withTranslation } from 'react-i18next';
import { slide as Menu } from 'react-burger-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Media from 'react-bootstrap/Media';
import queryString from 'query-string';
import i18n from '../../../i18n';
import {addParamUrl} from '../../../utils';

class HamburgerMenu extends Component {

    constructor(props) {
        super(props);
        this.onItemClicked = this.onItemClicked.bind(this);
        this.onStateChange = this.onStateChange.bind(this);
        this.changeLanguage = this.changeLanguage.bind(this);
    }

    state = {
        isOpen: false
    }
    
    onItemClicked() {
        this.setState({isOpen: false})
    }

    onStateChange(state) {
        this.setState({
            isOpen: state.isOpen
        });
    }

    changeLanguage(lng) {
        i18n.changeLanguage(lng);
        localStorage.setItem('lang', lng);
        window.location.reload();
    }

    render () {
        const { t } = this.props;
        let url = window.location.search;
		let params = queryString.parse(url);
        const mId = params.id;
        const src = params.src;
        const isSite = params.isSite;
        const menuId = mId ? '?id=' + mId : '';
        const srcParam = src ? '&src=' + src : '';
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        const isSiteParam = isSite ? {name: 'isSite', value: isSite} : {};

        // if (this.props.isLoggedIn) {
        //     const link = '/login';
        //     if (userInfo) {
        //         if (!userInfo.userId) {
        //             return <Redirect to={link} />
        //         }
        //     } else {
        //         return <Redirect to={link} />
        //     }
        // }

        // const isLoggedIn = this.props.isLoggedIn;
        let isLoggedIn;

        if (userInfo) {
            if (userInfo.userId) {
                isLoggedIn = true;
            }
        } 

        return (
            <Menu 
                right
                className='burger-menu'
                isOpen = {this.state.isOpen}
                onStateChange={this.onStateChange}>
                {isLoggedIn?
                    <div>
                        <a href={addParamUrl(`/mypage` + menuId + srcParam, [isSiteParam])}
                            onClick={this.onItemClicked}
                            className="link-avatar"
                        >
                            <Media className="user-info-container align-items-center">
                                <img 
                                    className="burger-menu-avatar-icon" 
                                    src={userInfo && userInfo.userImage ? userInfo.userImage : require("../../../assets/img/icons/avatar.png")}  
                                    alt={userInfo && userInfo.displayName} 
                                    width="18" 
                                    height="18" 
                                />
                                <Media.Body className="pl-2">
                                    <div className="burger-menu-link-bold">
                                        {userInfo? userInfo.displayName: "DisplayName"}
                                    </div> 
                                    <div className="burger-menu-link">
                                        @{userInfo?userInfo.accountId: "AccountID"}
                                    </div>
                                </Media.Body>
                            </Media> 
                        </a>
                        <a 
                            href={addParamUrl('/mypage', [isSiteParam])}
                            onClick={this.onItemClicked}
                        > 
                            <span>{t('pages.invite.Mypage')}</span>
                            <FontAwesomeIcon icon={faChevronRight} className="arrow-right" /> 
                        </a>
                        <a 
                            href={addParamUrl('/mypage/mygochi', [isSiteParam])}
                            onClick={this.onItemClicked}
                        > 
                            <span>{t('pages.tome.Address to yourself')}</span>
                            <FontAwesomeIcon icon={faChevronRight} className="arrow-right" /> 
                        </a>
                        <a 
                            href= {addParamUrl('/qrCode', [isSiteParam])}
                            // onClick={this.onItemClicked}
                            onClick={() => {window.location.href=addParamUrl("/qrCode", [isSiteParam])}}
                        >  
                            <span>{t('pages.qrcode.QR code reading')}</span>
                            <FontAwesomeIcon icon={faChevronRight} className="arrow-right"/> 
                        </a>
                        <a href= {addParamUrl('/logout' + menuId + srcParam, [isSiteParam])}
                            onClick={this.onItemClicked}
                        > 
                            <span>{t('buttons.logout')}</span>
                            <FontAwesomeIcon icon={faChevronRight} className="arrow-right"/> 
                        </a>
                    </div>
                :
                    <div>
                        <a href= {addParamUrl('/login' + menuId + srcParam, [isSiteParam])}
                            onClick={this.onItemClicked}> 
                            <span>{t('pages.mypage.login')}</span>
                            <FontAwesomeIcon icon={faChevronRight} className="arrow-right"/>  
                        </a>
                    </div>
                }

                <div className="select-lang-box">
                    <div className="select-lang select-custom">
                        <FontAwesomeIcon icon={faGlobe} className="icon-lang-global"/>  
                        <select className="form-control" 
                            defaultValue={i18n.language} 
                            onChange={(e) => this.changeLanguage(e.target.value)}
                        >
                            <option value="">Select language</option>
                            <option value="en">English</option>
                            <option value="zh">Chinese</option>
                            <option value="ja">Japanese</option>
                        </select>
                    </div>
                </div>
        </Menu>
        );
    }
}

export default (withTranslation()(HamburgerMenu));
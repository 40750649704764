import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { createBrowserHistory as useHistory } from "history";
import { withTranslation } from "react-i18next";
import Moment from "react-moment";
import Header from "../../layout/header/Header";
import { log } from "../../../utils";
import queryString from "query-string";
import { isMobile } from "react-device-detect";
import { showLoading } from "../../../actions/action_loading";
import { showError } from "../../../actions/action_error_alert";
import * as constants from "../../../constants";
import GochiService from "../../../api/services/GochiService";
import GochiUseInput from "./GochiUseInput";
import SurveyForm from "../SurveyForm";
import GochiUseConfirmModal from "./GochiUseConfirmModal";
import MenuService from "../../../api/services/MenuService";
import { savePaymentMethod } from "../../../actions/action_payment";

class GochiUseStaff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showGochiUseConfirm: false,
      gochiInfo: {},
      quantity: sessionStorage.getItem("quantityWillBeUsed")
        ? sessionStorage.getItem("quantityWillBeUsed")
        : 1,
      gochiBillId: null,
      menuId: null,
      gochiUserId: null,
      shopId: null,
      inputAmount: null,
      taxType: "INCLUDED",
      // limit total gochi
      addedAmount: 0,
      useAmount: 0,
      inputErrorMessage: "",
      paymentType: sessionStorage.getItem("selectPayment")
        ? parseInt(sessionStorage.getItem("selectPayment"))
        : constants.PAYMENT_TYPE_CREDIT_CARD,
      crfsToken: "",
      redirect: false,
      redirectUrl: "",
      couponId: null,
      gochiTipUrl: null,
      locationKeys: [],
      userInfo: queryString.parse(window.location.search).accessToken ? {isGuest: false, isLogined: true, userId: JSON.parse(atob(queryString.parse(window.location.search).accessToken.split(".")[1])).uid} : (this.props.userInfo ? this.props.userInfo : JSON.parse(localStorage.getItem('userInfo'))),
      isLoggedIn: this.props.userInfo?.userId ? true : false,
      accessToken: queryString.parse(window.location.search).accessToken,
      taxRate: constants.TAX_RATE.TAX_RATE_10_PERCENT
    };
    // this.onOpenGochiConfirm = this.onOpenGochiConfirm.bind(this);
    this.onCloseGochiConfirm = this.onCloseGochiConfirm.bind(this);
    this.getGochi = this.getGochi.bind(this);
    this.setInputAmount = this.setInputAmount.bind(this);
    this.setTaxType = this.setTaxType.bind(this);
    this.onSubmitInput = this.onSubmitInput.bind(this);
    this.generateRandomString = this.generateRandomString.bind(this);
    this.onSubmitConfirm = this.onSubmitConfirm.bind(this);
    this.checkAccessScreen = this.checkAccessScreen.bind(this);
    this.getDataDidMount = this.getDataDidMount.bind(this);
  }

  generateRandomString(length = 32) {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    this.setState({
      crfsToken: text,
    });
    console.log("crfs token", text);
    return text;
  }

  /*
  onOpenGochiConfirm() {
    this.setState({
      showGochiUseConfirm: true,
    });
    var gochiConfirmUrl = "/gochiuse/confirm";
    const confirmState = {
      useAmount: this.state.useAmount,
      addedAmount: this.state.addedAmount,
      remainAmount: this.state.gochiInfo.remainAmount,
    };
    if (this.state.shopId) {
      this.props.history.push({
        pathname: gochiConfirmUrl,
        search: queryString.stringify({ shopId: this.state.shopId }),
        state: confirmState,
      });
      // window.location.href = gochiConfirmUrl + "?shopId=" + this.state.shopId;
    } else {
      if (this.state.gochiUserId) {
        window.location.href =
          gochiConfirmUrl +
          "?gochiBillId=" +
          this.state.gochiBillId +
          "&menuId=" +
          this.state.menuId +
          "&gochiUserId=" +
          this.state.gochiUserId;
      } else {
        window.location.href =
          gochiConfirmUrl + "?gochiBillId=" + this.state.gochiBillId;
      }
    }
  }
  */

  onCloseGochiConfirm() {
    this.props.onCloseGochiStaff();
    this.setState({
      showGochiUseConfirm: false,
    });
  }

  getGochi = async (gochiBillId) => {
    this.props.showLoading(true);
    try {
      const { data } = await GochiService.getGochi({ gochiBillId });
      this.props.showLoading(false);
      return data;
    } catch (error) {
      this.props.showLoading(false);
      this.props.showError(
        true,
        this.props.t("pages.login.There is something wrong"),
        ""
      );
    }
  };

  setInputAmount(amount) {
    this.setState({ inputAmount: amount });
  }

  setTaxType(type) {
    this.setState({ taxType: type });
  }

  onSubmitInput(addedAmount, useAmount) {
    let url = window.location.search;
    let params = queryString.parse(url);
    const isEducateSystem = params.siteType === "fukuoka" || params.siteType === "suita" || params.siteType === "nishiwaki" || params.siteType === "hamamatsu";
    // Disable charge added amount
    if (
      addedAmount > 0 &&
      process.env.REACT_APP_ENABLED_EXCEEDED_AMOUNT_PAYMENT === "false"
    ) {
      this.setState({
        inputErrorMessage: isEducateSystem ? "利用可能額の範囲内で入力してください" : this.props.t('pages.gochiuse.please re-enter the amount'),
      });
      return;
    }
    if (this.state.inputAmount <= 0) {
      this.setState({
        inputErrorMessage: isEducateSystem ? "金額を入力してください" : this.props.t('pages.gochiuse.please enter the amount correctly'),
      });
      return;
    }
    this.setState({
      inputErrorMessage: "",
    });
    this.setState({ addedAmount, useAmount });
    const confirmState = {
      useAmount,
      addedAmount,
    };
    console.log("confirm state: ", confirmState);
    this.props.history.push({
      pathname: "/gochiuse/staff",
      search: this.props.location.search,
      state: confirmState,
    });
  }

  checkAccessScreen = async () => {
      let url = window.location.search;
      let params = queryString.parse(url);
      let accessToken = params.accessToken;
      let gochiBillId = params.gochiBillId;
      let shopId = params.shopId;
      if (!accessToken) {
          var link = "/login";
          if (!this.state.isLoggedIn){
              sessionStorage.setItem('loginCallbackUrl', window.location.pathname);
              return window.location.href = link;
          } else {
              if (this.state.userInfo) { 
                  if (this.state.userInfo.isLogined === false || this.state.userInfo.isGuest) { 
                      window.history.pushState(null, null, '/login');
                      return <Redirect to={link} />
                  }
                  this.getDataDidMount(this.state.userInfo, gochiBillId, shopId);
              }else{
                  window.history.pushState(null, null, '/login');
                  return <Redirect to={link} />
              }
          }
      } else {
          if (this.state.isLoggedIn){
              sessionStorage.clear();
              localStorage.clear();
          }
          this.getDataDidMount(this.state.userInfo, gochiBillId, shopId);
      }
  }

  getFooterSystem = (gochiInfo) => {
    let url = window.location.search;
    let params = queryString.parse(url);

    if (params.siteType === "suita") {
        return (
          <div>
            {gochiInfo.gochiBillNo && <div className="clr-000000 fs-18 font-weight-bold">紙クーポンNo： {gochiInfo.gochiBillNo}</div>}
            <div className="fs-12 clr-000000">吹田市子供の習い事費用助成事業 運営事務局</div>
            <div><span className="fs-12 clr-000000">TEL：</span><a href="tel:0120-065-067" className="fs-12 clr-F44A4A text-decoration-underline">0120-065-067</a></div>
            <div className="fs-12 clr-000000">営業時間：平日 11:00〜20:00</div>
            <div className="fs-12 clr-000000">（土日祝休み）</div>
          </div>
        );
    } else if (params.siteType === "nishiwaki") {
        return (
          <div>
            {gochiInfo.gochiBillNo && <div className="clr-000000 fs-18 font-weight-bold">紙クーポンNo： {gochiInfo.gochiBillNo}</div>}
            <div className="fs-12 clr-000000">お問い合わせ先：西脇市こども未来応援事業 運営事務局</div>
            <div className="fs-12 clr-000000">営業時間：9:30~17:30 (土・日・祝休業)</div>
            <div><span className="fs-12 clr-000000">TEL：</span><a href="tel:078-371-8781" className="fs-12 clr-F44A4A text-decoration-underline">078-371-8781</a></div>
          </div>
        );
    } else if (params.siteType === "hamamatsu") {
        return (
          <div>
            {gochiInfo.gochiBillNo && <div className="clr-000000 fs-18 font-weight-bold">紙クーポンNo： {gochiInfo.gochiBillNo}</div>}
            <div className="fs-12 clr-000000">【お問い合わせ先】</div>
            <div className="fs-12 clr-000000">浜松市こども習い事応援事業 コールセンター</div>
            <div><span className="fs-12 clr-000000">TEL:</span><a href="tel:050-5536-3317" className="fs-12 clr-F44A4A text-decoration-underline">050-5536-3317</a></div>
            <div className="fs-12 clr-000000">営業時間:</div>
            <div className="fs-12 clr-000000">月曜~金曜 10:00~20:00</div>
            <div className="fs-12 clr-000000">土曜日 10:00~15:00(日祝休み)</div>
          </div>
        );
    } else {
        return (
          <div>
            {gochiInfo.gochiBillNo && <div className="clr-000000 fs-18 font-weight-bold">紙クーポンNo： {gochiInfo.gochiBillNo}</div>}
            <div className="fs-12 clr-000000">お問い合わせ先：福岡子ども習い事応援事業 運営事務局</div>
            <div className="fs-12 clr-000000">営業時間：年末年始（12/29~1/3）除く</div>
            <div className="fs-12 clr-000000">9:00~18:00</div>
            <div><span className="fs-12 clr-000000">電話番号：</span><a href="tel:092-406-3108" className="fs-12 clr-F44A4A text-decoration-underline">092-406-3108</a></div>
          </div>
        );
    }
  }

  UNSAFE_componentWillMount(){ 
        this.checkAccessScreen();
  }

  async getDataDidMount(userInfo, gochiBillId, shopId){
    if (userInfo) {
      if (userInfo.isLogined && !userInfo.isGuest) {
        if (gochiBillId) {
          var dataGochi = sessionStorage.getItem("usingFlowGochiInfo")
            ? JSON.parse(sessionStorage.getItem("usingFlowGochiInfo"))
            : {};
          this.setState({
            gochiInfo: {
              gochiBillId: dataGochi.gochiBillId,
              gochiBillNo: dataGochi.gochiBillNo,
              shopId: dataGochi.shopId,
              shopName: dataGochi.shopName,
              menuName: dataGochi.menuName,
              menuId: dataGochi.menuId,
              menuImage: dataGochi.menuImage
                ? dataGochi.menuImage
                : require("../../../assets/img/icons/gochi_noimg-ver4.jpg"),
              includedTaxPrice: dataGochi.includedTaxPrice,
              excludedTaxPrice: dataGochi.excludedTaxPrice,
              displayAmount: dataGochi.displayAmount,
              gochiCount: dataGochi.gochiCount,
              gochiExpiredDay: dataGochi.gochiExpiredDay,
              menuChildType: dataGochi.menu && dataGochi.menu.menuChildType,
              // childFoods: childFoods,
              groupId: dataGochi && dataGochi.groupId,
              groupName: dataGochi.groupInfo && dataGochi.groupInfo.groupName,
              shopStatus: dataGochi.shop && dataGochi.shop.status,
              menuCombo: dataGochi.menuCombo,
              comboOutQuantity: dataGochi.comboOutQuantity,
              quantityFree: dataGochi.quantityFree ? dataGochi.quantityFree : 0,
              quantityFreeDes: dataGochi.quantityFreeDes,
              priceCombo: dataGochi.priceCombo,
              includedTaxPriceCombo: dataGochi.includedTaxPriceCombo,
              displayAmountOneTicket: dataGochi.displayAmountOneTicket,
              couponCount: dataGochi.couponCount,
              isETicket: dataGochi.isETicket,
              showPrice: dataGochi.showPrice,
              usageType: dataGochi.usageType,
              reducedTaxType: dataGochi.reducedTaxType
            },
            showInput: dataGochi.menuLimitTotal === 1,
          });
          if (dataGochi.reducedTaxType === "REDUCED" && dataGochi.usageType === constants.MENU_USAGE_TYPE.USAGE_TYPE_MENU) {
            this.setState({
              taxRate: constants.TAX_RATE.TAX_RATE_8_PERCENT
            });
          }
        } else if (shopId) {
          var gochiDetails = sessionStorage.getItem("usingFlowGochiInfo")
            ? JSON.parse(sessionStorage.getItem("usingFlowGochiInfo"))
            : {};
          this.setState({
            gochiInfo: gochiDetails,
            showInput: gochiDetails.menuLimitTotal === 1,
          });
          if (gochiDetails.reducedTaxType === "REDUCED" && gochiDetails.usageType === constants.MENU_USAGE_TYPE.USAGE_TYPE_MENU) {
            this.setState({
              taxRate: constants.TAX_RATE.TAX_RATE_8_PERCENT
            });
          }
        }
      }
    }
  }

  async componentDidMount() {
    this.generateRandomString();

    const history = useHistory();
    history.listen(location => {
      console.log('history', history.action);
      const urlSiteType = queryString.parse(window.location.search).siteType ? "&siteType=" + queryString.parse(window.location.search).siteType : "";
      const urlAccessToken = this.state.accessToken ? "&accessToken=" + this.state.accessToken : "";
      const urlAccessCode = sessionStorage.getItem("accessCode") ? "&accessCode=" + sessionStorage.getItem("accessCode") : "";
      if (history.action === 'PUSH') {
        this.setState({ locationKeys: [ location.key ] });
      }
      if (history.action === 'POP') {
        if (this.state.locationKeys[1] === location.key) {
          this.setState({ locationKeys: ([ _, ...keys ]) => keys });
          console.log('location forward', location);
          if (location.pathname === '/gochiuse/staff'){
            window.location.href = '/qrCode?shopId=' + shopId + urlAccessToken + urlAccessCode + urlSiteType;
          }
          // Handle forward event
        } else {
          this.setState({locationKeys: (keys) => [ location.key, ...keys ] });
          console.log('location back', location);
          // Handle back event
          if (location.pathname === '/gochiuse/staff'){
            window.location.href = '/qrCode?shopId=' + shopId + urlAccessToken + urlAccessCode + urlSiteType;
          }
        }
      }
    })

    const url = window.location.search;
    let params = queryString.parse(url);
    const gochiBillId = params.gochiBillId;
    const gochiUserId = params.gochiUserId;
    const menuId = params.menuId;
    const shopId = params.shopId;

    if (
      !this.props.paymentMethod ||
      Object.entries(this.props.paymentMethod).length === 0
    ) {
      var payment = JSON.parse(sessionStorage.getItem("paymentMethod"));
      this.props.savePaymentMethod(payment);
    }

    if (shopId) {
      this.setState({
        shopId: shopId,
      });
    }

    if (gochiBillId) {
      this.setState({
        gochiBillId: gochiBillId,
      });
    }

    if (gochiUserId) {
      this.setState({
        gochiUserId: gochiUserId,
      });
    }

    if (menuId) {
      this.setState({
        menuId: menuId,
      });
    }

    console.log("location:", this.props.location?.state);
    this.setState({
      useAmount: this.props.location?.state?.useAmount || 0,
      addedAmount: this.props.location?.state?.addedAmount || 0,
    });
  }

  getTotalUseAmount() {
    const { gochiInfo, quantity } = this.state;
    let totalUseAmount = 0;
    if (gochiInfo.menuLimitTotal === 1) {
      totalUseAmount = this.state.useAmount;
    } else {
      if (gochiInfo.menuCombo === 1) {
        totalUseAmount = gochiInfo.displayAmountOneTicket ? gochiInfo.displayAmountOneTicket : 0;
      } else {
        totalUseAmount = quantity * (gochiInfo.displayAmount || gochiInfo.includedTaxPrice);
      }
    }
    return totalUseAmount;
  }

  async submitUseWithAddedAmount(params) {
    const { t } = this.props;
    this.props.showLoading(true);
    let windowReference;
    try {
      if (params) {
        let result = null;
        this.setState({
          redirectUrl: "",
        });

        if (
          !this.state.paymentType ||
          this.state.paymentType === constants.PAYMENT_TYPE_VERITRANS ||
          this.state.paymentType === constants.PAYMENT_TYPE_STRIPE
        ) {
          // charge added amount with payment Credit Card (Stripe/Veritrans)
          result = await MenuService.chargeAddedAmount(
            params,
            this.state.crfsToken
          );
          log(result);
          if (result.status === 200) {
            var chargeAddedAmountResponse = result.data;
            sessionStorage.removeItem("messageCard");
            let siteType = queryString.parse(window.location.search).siteType ? "&siteType=" + queryString.parse(window.location.search).siteType : '';
            let accessToken = queryString.parse(window.location.search).accessToken ? "&accessToken=" + queryString.parse(window.location.search).accessToken : '';
            this.props.history.push({
              pathname: "/gochiUse/finish",
              search: `?gochiBillId=${
                this.state.gochiInfo.gochiBillId
              }&gochiUsedNo=${1}&couponId=${
                this.state.gochiInfo?.coupons[0]?.couponId
              }&shopId=${this.state.shopId}${accessToken}${siteType}`,
              state: {
                useAmount: chargeAddedAmountResponse?.amount,
              },
            });
            this.props.showLoading(false);
          } else if (result.status === 406) {
            this.props.showError(
              true,
              t("pages.invite.Error create gochi"),
              ""
            );
            this.props.showLoading(false);
          } else if (result.status === 402) {
            let messageErr = t(
              "pages.invite.error create gochi using stripe incase other charging"
            );
            if (result.data) {
              if (result.data.stripe_code === "expired_card") {
                messageErr = t(
                  "pages.invite.error create gochi using stripe incase expired card"
                );
              } else if (result.data.stripe_code === "amount_too_small") {
                messageErr = t(
                  "pages.invite.error create gochi using stripe incase amount to small"
                );
              } else if (result.data.stripe_code === "insufficient_funds") {
                messageErr = t(
                  "pages.invite.error create gochi using stripe incase insufficient funds"
                );
              } else if (result.data.stripe_code === "card_velocity_exceeded") {
                messageErr = t(
                  "pages.invite.error create gochi using stripe incase card velocity exceeded"
                );
              }
              // subscription error
              else if (this.props.menuInfo.subscription === 1) {
                messageErr = t("pages.invite.error subsription failed");
              }
              // other cases
              else {
                messageErr = t("pages.invite.error create gochi user");
              }
            }
            this.props.showError(true, messageErr, "");
            this.props.showLoading(false);
          } else if (
            result.status === 404 ||
            result.status === 400 ||
            result.status === 500
          ) {
            this.props.showError(
              true,
              t("pages.invite.error create gochi user"),
              ""
            );
            this.props.showLoading(false);
          } else if (result.status === 504) {
            this.props.showError(
              true,
              t("pages.invite.error create gochi with user"),
              "",
              t("pages.invite.go to mypage"),
              "/mypage?id=" + this.state.menuId
            );
            this.props.showLoading(false);
          } else {
            this.props.showError(
              true,
              t("pages.invite.error create gochi user"),
              ""
            );
            this.props.showLoading(false);
          }
        } else {
          // charge added amount with payment not Stripe
          if (this.state.paymentType !== constants.PAYMENT_TYPE_PAYPAY) {
            windowReference = window.open();
          }
          result = await MenuService.createSessionAddedAmount(params);
          log(result);
          if (result.status === 200) {
            var paypayResponse = result.data;
            paypayResponse.menuName = this.props.menuInfo.menuName;
            paypayResponse.description = this.props.menuInfo.description;
            sessionStorage.setItem(
              "paypayResponse",
              JSON.stringify(paypayResponse)
            );
            this.props.showLoading(false);
            if (this.state.paymentType === constants.PAYMENT_TYPE_PAYPAY) {
              this.setState({
                redirectUrl:
                  !this.state.menuId && this.state.shopId
                    ? `/ticket-use-complete?shopId=${this.state.shopId}&redirectFromPaypay=true&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}`
                    : `/ticket-use-complete?id=${this.state.menuId}&redirectFromPaypay=true&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}`,
              });
              if (isMobile) {
                window.location.href = result.data.redirectUrl;
              } else {
                window.open(result.data.redirectUrl, "_blank");
              }
            } else {
              // Payment type is Alipay or Linepay or Merupay
              // this.setState({
              // 	redirectUrl: `/invite-finish?id=${this.state.menuId}&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}&paymentGateway=onepay`
              // });
              const redirectUrl =
                !this.state.menuId && this.state.shopId
                  ? `/ticket-use-complete?shopId=${this.state.shopId}&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}&paymentGateway=onepay`
                  : `/ticket-use-complete?id=${this.state.menuId}&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}&paymentGateway=onepay`;
              if (
                this.state.paymentType ===
                  constants.PAYMENT_TYPE_ONEPAY_ALIPAY &&
                !isMobile
              ) {
                // Show QR code
                windowReference.location = `${
                  process.env.REACT_APP_DOMAIN_URL
                }/alipay-qr-code?value=${encodeURIComponent(
                  result.data.redirectUrl
                )}&ts=${Date.now()}`;
                window.location.href = redirectUrl;
              } else {
                windowReference.location = result.data.redirectUrl;
                setTimeout(() => {
                  try {
                    const refHostname = windowReference.location.hostname;
                    if (refHostname !== "mobile.alipay.com")
                      windowReference.close();
                  } catch (e) {}
                  window.location.href = redirectUrl;
                }, 5000);
              }

              // Payment type = WechatPay
              if (this.state.paymentType === constants.PAYMENT_TYPE_WECHATPAY) {
                if (!isMobile) {
                  // Show QR code
                  windowReference.location = `${
                    process.env.REACT_APP_DOMAIN_URL
                  }/wechatpay-qr-code?value=${encodeURIComponent(
                    result.data.redirectUrl
                  )}&ts=${Date.now()}`;
                  window.location.href = redirectUrl;
                } else {
                  window.location.href = result.data.redirectUrl;
                }
              }
            }
          } else if (result.status === 406) {
            this.props.showError(
              true,
              t("pages.invite.Error create gochi"),
              ""
            );
            this.props.showLoading(false);
            if (windowReference) {
              windowReference.close();
            }
          } else if (
            result.status === 404 ||
            result.status === 400 ||
            result.status === 500
          ) {
            this.props.showError(
              true,
              t("pages.invite.error create gochi user"),
              ""
            );
            this.props.showLoading(false);
            if (windowReference) {
              windowReference.close();
            }
          } else {
            this.props.showError(
              true,
              t("pages.invite.error create gochi with guest"),
              ""
            );
            this.props.showLoading(false);
            if (windowReference) {
              windowReference.close();
            }
          }
        }
      } else {
        this.props.showError(true, "There is something wrong", "");
        this.props.showLoading(false);
      }
    } catch (error) {
      log(error);
      this.props.showError(true, "There is something wrong", "");
      this.props.showLoading(false);
      if (windowReference) {
        windowReference.close();
      }
    }
  }

  async onSubmitConfirm() {
    this.setState({
      submitConfirm: true,
    });

    const shopId = this.state.gochiInfo.shopId;
    const menuId = this.state.gochiInfo.menuId;
    const gochiUserId = this.state.userInfo && this.state.userInfo.userId;
    const gochiBillId = this.state.gochiInfo.gochiBillId;
    const quantity = this.state.quantity;
    const groupId = this.state.gochiInfo.groupId;
    const childFoodId = this.state.gochiInfo.childFoodId;
    const menuLimitTotal = this.state.gochiInfo.menuLimitTotal === 1;
    const useAmount = this.state.useAmount;
    const addedAmount = this.state.addedAmount;
    const paymentSource =
      (!this.state.paymentType ||
        this.state.paymentType === constants.PAYMENT_TYPE_VERITRANS ||
        this.state.paymentType === constants.PAYMENT_TYPE_STRIPE) &&
      this.props.paymentMethod
        ? this.props.paymentMethod.token
        : "";

    const url = window.location.search;
    let params = queryString.parse(url);
    const shopIdP = params.shopId && !isNaN(parseInt(params.shopId)) ? parseInt(params.shopId) : null;

    if (menuLimitTotal && addedAmount > 0) {
      const chargeAddedAmountParams = {
        gochiUserId,
        premiumAmount: this.state.gochiInfo.remainAmount,
        couponId:
          this.state.gochiInfo.coupons &&
          this.state.gochiInfo.coupons[0] &&
          this.state.gochiInfo.coupons[0].couponId,
        useAmount: useAmount,
        paymentType: this.state.paymentType,
        platformType: "WEB",
        source: paymentSource,
        domainUrl: window.location.hostname,
        userAgent: navigator.userAgent,
        useForShopId: this.state.shopId,
      };
      this.submitUseWithAddedAmount(chargeAddedAmountParams);
      return;
    }

    try {
      this.props.showLoading(true); 
      const response = await GochiService.useGochi(
        {
          gochiBillId,
          shopId: shopId ? shopId : shopIdP,
          menuId,
          gochiUserId,
          quantity,
          groupId,
          childFoodId,
          // new gochi
          useAmount: menuLimitTotal ? useAmount : undefined,
          memo: { shopId: null },
          checkTokenExpired: this.state.accessToken ? 1 : null,
          taxRate: this.state.taxRate
        },
        this.state.crfsToken,
        this.state.accessToken,
      );
      this.props.showLoading(false);

      if (response.status !== 200) {
        if (response.data) {
          if (
            response.data.code === "validation.errors.menuCombo.used.over.limit"
          ) {
            this.props.onCloseGochiConfirm();
            this.props.showError(
              true,
              "",
              this.props.t("other.This ticket can only be used once a day")
            );
          } else 
          if (response.data.code === "exception.errors.external.coupon.exception") {
            this.generateRandomString();
            this.props.showError(
              true,
              "",
              this.props.t("other.external coupon exception")
            );
          } else
          if (response.data.code === "exception.errors.security.has.csrf.token") {
            this.generateRandomString();
            this.props.showError(
              true,
              "",
              this.props.t("other.Please wait for a while as it is being processed")
            );
          } else {
            this.props.showError(true, this.props.t("other.Unexpected error"), "");
            // this.props.showError(
            //   true,
            //   "",
            //   this.props.t("other.Your login has been cancelled")
            // );
          }
        }
        else 
        this.props.showError(true, this.props.t("other.Unexpected error"), "");
      } else {
        const gochiBillId =
          response.data &&
          response.data.list &&
          response.data.list.length > 0 &&
          response.data.list[0].gochiBillId;
        const gochiUsedNo = response.data && response.data.count;
        const gochiTipUrl = response.data && response.data.gochiTipUrl;
        let siteType = queryString.parse(window.location.search).siteType ? "&siteType=" + queryString.parse(window.location.search).siteType : '';
        let accessToken = queryString.parse(window.location.search).accessToken ? "&accessToken=" + queryString.parse(window.location.search).accessToken : '';
        var couponId =
          response.data &&
          response.data.list &&
          response.data.list.length > 0 &&
          response.data.list[0].couponId;
        const barCodeYoshinoya = response.data && response.data.barCodeYoshinoya && !accessToken ? "&barCodeYoshinoya=" + response.data.barCodeYoshinoya : '';
        if (menuLimitTotal) {
          this.props.history.push({
            pathname: "/gochiUse/finish",
            search: `?gochiBillId=${
              this.state.gochiInfo.gochiBillId
            }&gochiUsedNo=${1}&couponId=${
              this.state.gochiInfo?.coupons[0]?.couponId
            }&shopId=${this.state.shopId}${accessToken}${siteType}${barCodeYoshinoya}`,
            state: {
              useAmount: useAmount,
              useCouponId: couponId,
            },
          });
          return;
        }
        let link = `/gochiUse/finish?gochiBillId=${gochiBillId}&gochiUsedNo=${gochiUsedNo}&couponId=${couponId}&gochiTipUrl=${
          gochiTipUrl || ""
        }${accessToken}${siteType}${barCodeYoshinoya}`;
        if (this.state.shopId) {
          link = `/gochiUse/finish?gochiBillId=${gochiBillId}&gochiUsedNo=${gochiUsedNo}&shopId=${
            this.state.shopId
          }&couponId=${couponId}&gochiTipUrl=${gochiTipUrl || ""}${accessToken}${siteType}${barCodeYoshinoya}`;
        } else if (this.state.gochiUserId) {
          link = `/gochiUse/finish?gochiBillId=${gochiBillId}&gochiUsedNo=${gochiUsedNo}&gochiUserId=${
            this.state.gochiUserId
          }&menuId=${this.state.menuId}&couponId=${couponId}&gochiTipUrl=${
            gochiTipUrl || ""
          }${accessToken}${siteType}${barCodeYoshinoya}`;
        }
        this.setState({
          redirect: true,
          redirectUrl: link,
          couponId: couponId,
          gochiTipUrl: gochiTipUrl,
        });
      }
    } catch (error) {
      console.log("error in gochiusse", error);
      if (error.code === "exception.errors.security.has.csrf.token") {
        this.props.showError(
          true,
          this.props.t("other.Please wait for a while as it is being processed"),
          ""
        );
      } else {
        this.props.showError(
          true,
          this.props.t("There is something wrong"),
          ""
        );
      }
    }

    this.setState({
      submitConfirm: false,
    });
  }

  showGochiConfirm(gochiInfo) {
	if (gochiInfo.isETicket === 0) {
		this.props.showError(
			true,
			this.props.t('other.Prevent paper ticket'),
			""
		);
	} else {
		this.setState({ showGochiUseConfirm: true });
	}
  }

  render() {
    log(["props gochiUseStaff", this.props]);
    const { t } = this.props;
    const url = window.location.search; 
    let params = queryString.parse(url);
    const gochiInfo =
      { ...this.state.gochiInfo, childFoodId: this.props.childFoodId } || {};
    let optionNums = [];
    const shopName = this.state.gochiInfo.shopName || this.props.shopInfo.shopName;
    const menuLimitTotal = this.state.gochiInfo.menuLimitTotal === 1;
    const remainAmount = gochiInfo.menuChildType === 5 ? gochiInfo?.menuChildType5?.amountCanUse : gochiInfo.remainAmount;
    const showInput = this.props.location.pathname === "/gochiuse/staff/input";
    const  isEducateSystem = params.siteType === "fukuoka" || params.siteType === "suita" || params.siteType === "nishiwaki" || params.siteType === "hamamatsu";
    console.log("menuLimitTotal: ", menuLimitTotal);
    // const remainAmountAfterUse = this.state.addedAmount > 0 ? 0 : remainAmount - this.state.useAmount;
    const totalUseAmount = this.getTotalUseAmount();

    for (var i = 1; i <= gochiInfo.gochiCount; i++) {
      optionNums.push(<option key={i}>{i}</option>);
    }

    if (this.state.redirect) {
      window.history.pushState(
        {
          couponId: this.state.couponId,
          gochiTipUrl: this.state.gochiTipUrl,
        },
        null,
        this.state.redirectUrl
      );
      return <Redirect to={this.state.redirectUrl} />;
    }
    const isShowPrice = gochiInfo.showPrice !== 0;

    return (
      <>
        <div className="content font-gothic clr-000000">
          <Header
            title={isEducateSystem ? "" : (showInput ? t("pages.tome.input amount to use") : t("pages.tome.Use this ticket"))}
            isLoggedIn={this.state.isLoggedIn}
            t={t}
            hasBack={true}
            usingFlow={true}
            // backAction={() => {
            //   if (!showInput && menuLimitTotal) {
            //     this.setState({ showInput: true });
            //   } else {
            //     window.history.back();
            //   }
            // }}
            siteType={params.siteType}
          />


          {!!this.state.gochiInfo.menuId && !!this.state.gochiInfo.groupId && menuLimitTotal && !showInput &&(
            <SurveyForm surveyType={2} shopId={this.state.gochiInfo.shopId} menuId={this.state.gochiInfo.menuId} 
              gochiBillId={this.state.gochiInfo.gochiBillId}
              onInitSuccess={() => this.setState({showSurvey: true})} 
              onSubmitSuccess={() => this.setState({showSurvey: false})} 
              onInitFailed={() => this.setState({showSurvey: false})}/>
					)}

          {(!this.state.showSurvey || showInput) && <div className="input-infor">
            {gochiInfo.shopStatus === "CLOSED" && (
              <div className="shop-closed">
                {t("pages.menu.This shop has closed")}
              </div>
            )}

            {!showInput && (
              <>
                <div className="fs-22 font-weight-bold my-5 text-center">
                  {isEducateSystem? "利用金額の確認" : "ご注文内容の確認"}
                </div>
                <div className="used-price-box mt-3">
                  <div className="top">
                    {shopName}
                  </div>
                  <div className="mid mt-3 mb-2">
                    {isShowPrice ? (
                      <>
                        <span
                          className={
                            totalUseAmount < 1000000
                              ? 'fs-72 line-height-72'
                              : 'fs-48 line-height-48'
                          }
                        >
                          {totalUseAmount.toLocaleString()}
                        </span>
                        <span className="fs-20 line-height-20">円</span>
                      </>
                    ) : (
                      <div className="fs-20 fw-700">
                        {gochiInfo.menuName}
                      </div>
                    )}
                  </div>
                  <Moment
                    className="bot"
                    interval={1000}
                    format="YYYY.MM.DD HH:mm:ss"
                    local
                  />
                </div>

                <div className="px-2">
                  {isShowPrice ? (
                    <>
                      <div className="mt-4 fs-18 font-weight-bold text-break">
                        {gochiInfo.menuName}
                      </div>
    
                      {!menuLimitTotal && <div className="fs-18 font-weight-medium">
                        <span>単価: </span>
                        <span className="font-weight-bold">
                          {(gochiInfo.menuCombo === 1
                            ? gochiInfo.displayAmountOneTicket || 0
                            : gochiInfo.displayAmount || gochiInfo.includedTaxPrice || 0
                          ).toLocaleString()}
                        </span>
                        <span className="fs-14">円</span>
                        <span className="ml-4">個数: </span>
                        <span className="font-weight-bold">{this.state.quantity}</span>
                      </div>}
                    </>
                  ) : (
                    !menuLimitTotal && this.state.quantity > 1 && (
                      <div className="fs-18 font-weight-medium mt-4">
                        <span>個数: </span>
                        <span className="font-weight-bold">{this.state.quantity}</span>
                      </div>
                  ))}

                  {(!isEducateSystem && 
                    (this.state.gochiInfo.usageType === constants.MENU_USAGE_TYPE.USAGE_TYPE_VOUCHER ||
                    this.state.gochiInfo.usageType === constants.MENU_USAGE_TYPE.USAGE_TYPE_COMBO)) && (
                    <div className="mt-40">
                      <div className="required-dot mb-3-custome">
                        <div className="dot-red"></div>
                        <div className="dot-text-label fs-16 font-weight-bold">
                          消費税区分
                        </div>
                      </div>
                      <div className="d-flex">
                        <label className="radio-custom">
                          <input
                            type="radio"
                            name="taxRate"
                            value={constants.TAX_RATE.TAX_RATE_10_PERCENT}
                            onChange = {(e) => {
                              this.setState({
                                taxRate: constants.TAX_RATE.TAX_RATE_10_PERCENT
                              });
                            }}
                            checked={this.state.taxRate === constants.TAX_RATE.TAX_RATE_10_PERCENT}
                          />
                          <span className="checkmark"></span>
                          <span className="fs-16">10%対象</span>
                        </label>
                        <label className="radio-custom ml-5">
                          <input
                            type="radio"
                            name="taxRate"
                            value={constants.TAX_RATE.TAX_RATE_8_PERCENT}
                            onChange = {(e) => {
                              this.setState({
                                taxRate: constants.TAX_RATE.TAX_RATE_8_PERCENT
                              });
                            }}
                            checked={this.state.taxRate === constants.TAX_RATE.TAX_RATE_8_PERCENT}
                          />
                          <span className="checkmark"></span>
                          <span className="fs-16">8%対象</span>
                        </label>
                      </div>
                    </div>
                  )}

                  {!isEducateSystem && (
                    <div className="mt-40 clr-F44A4A font-weight-bold">
                      ※オーダー確認ボタンはお店の方の指示に従うか、お客様ご自身でタップ下さい。
                    </div>
                  )}
                </div>

                <div
                  className="btn btn-use-55 bg-red mt-3"
                  onClick={() => this.showGochiConfirm(gochiInfo)}
                >{isEducateSystem ? "利用金額の確定に進む" : "オーダー確認"}</div>

                <div
                  className="btn border-btn mt-3"
                  onClick={() => this.props.history.goBack()}
                >戻る</div>
              </>
            )}

            {showInput && (
              <>
                <div className="gochi-info dish-infor mt-3">
                  <div className="media">
                    <div className="dish-image">
                      <figure className="mb-0 dish-fig">
                        <img
                          src={gochiInfo.menuImage}
                          alt={gochiInfo.menuName}
                          width="100%"
                        />
                      </figure>
                      {gochiInfo.menuCombo === 1 && (
                        <div>
                          <div className="triangle-label">
                            <span>{t("pages.invite.Coupon")}</span>
                          </div>
                          {gochiInfo.quantityFree !== 0 ? (
                            <div className="bonus-label">
                              <span className="clr-fff">
                                {`+`}{t('pages.invite.Advantageous (total)', { 
                                    freeTicketCount: gochiInfo.quantityFree, 
                                    totalCoupon: gochiInfo.quantityFree +
                                    gochiInfo.comboOutQuantity})}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>

                    <div className="media-body dish-body pl-2">
                      <div className="mt-0 dish-link fs-19">
                        <span className="fs-16 clr-636363 font-weight-normal">
                          {gochiInfo.shopName || gochiInfo.groupName}
                        </span>
                        <br />
                        <span>{gochiInfo.menuName}</span>
                        {gochiInfo.menuCombo === 1 && (
                          <>
                            <br />
                            <span>
                              {gochiInfo.displayAmountOneTicket
                                ? gochiInfo.displayAmountOneTicket.toLocaleString()
                                : 0}
                              円ｘ
                            </span>
                            <span>
                              {gochiInfo.comboOutQuantity
                                ? gochiInfo.comboOutQuantity +
                                  gochiInfo.quantityFree
                                : 0}
                              {t("pages.login.sheet")}
                            </span>
                            <span>
                              {gochiInfo.quantityFree !== 0
                                ? `(${t("pages.invite.Include N ticket free", {
                                    number: gochiInfo.quantityFree,
                                  })})`
                                : null}
                            </span>
                          </>
                        )}
                        {menuLimitTotal && (
                          isEducateSystem ? (
                          <>
                            <div className="text-price-can-use-fukuoka">利用可能額</div>
                            <div className="d-flex justify-content-start align-items-baseline">
                              <span className="fs-47 text-dark font-weight-bolder">
                                {remainAmount?.toLocaleString()}
                              </span>
                              <span className="fs-16 text-dark font-weight-bolder line-height-47">
                                円
                              </span>
                            </div>
                          </>
                          ) : (
                            <div className="d-flex justify-content-around align-items-baseline">
                              <span className="fs-16 text-dark font-weight-bolder line-height-47">
                                {this.props.t("pages.gochiuse.total")}
                              </span>
                              <span className="fs-47 text-dark font-weight-bolder">
                                {remainAmount?.toLocaleString()}
                              </span>
                              <span className="fs-16 text-dark font-weight-bolder line-height-47">
                                円
                              </span>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>

                  {!menuLimitTotal &&
                    (gochiInfo.menuCombo === 1 ? (
                      <div className="using-statistic-green">
                        {t("pages.invite.Coupon")}
                        <span className="fs-21">
                          {gochiInfo.couponCount - gochiInfo.gochiCount + 1}/
                          {gochiInfo.couponCount}
                        </span>
                        {t("pages.gochiuse.used")}
                        <br />
                        <span className="fs-44">1</span>
                        {t("pages.login.sheet")}
                        <span className="pl-2">{t("pages.gochiuse.total")}</span>
                        <span className="fs-44">
                          {gochiInfo.displayAmountOneTicket
                            ? gochiInfo.displayAmountOneTicket.toLocaleString()
                            : 0}
                        </span>
                        {t("pages.invite.Yen Unit")}
                      </div>
                    ) : (
                      <div className="using-statistic-green">
                        <span className="fs-44">{this.state.quantity}</span>
                        {t("pages.invite.pieces")}
                        <span> {t("pages.gochiuse.total")}</span>
                        <span className="fs-44">
                          {(
                            this.state.quantity *
                            (gochiInfo.displayAmount || gochiInfo.includedTaxPrice)
                          ).toLocaleString()}
                        </span>
                        {t("pages.invite.Yen Unit")}
                      </div>
                    ))}

                  {gochiInfo.menuCombo === 1 && gochiInfo.quantityFreeDes && (
                    <div className="mt-2">
                      <div className="arrow-up"></div>
                      <div className="div-free-ticket-desc break-line break-word">
                        <div className="font-weight-bold">
                          {t("pages.invite.Bonus")}
                        </div>
                        <div>{gochiInfo.quantityFreeDes}</div>
                      </div>
                    </div>
                  )}
                </div>

                <GochiUseInput
                  inputAmount={this.state.inputAmount}
                  setInputAmount={(amount) => this.setInputAmount(amount)}
                  gochiInfo={gochiInfo}
                  taxType={this.state.taxType}
                  setTaxType={(type) => this.setTaxType(type)}
                  remainAmount={remainAmount}
                  onSubmitInput={this.onSubmitInput}
                  inputErrorMessage={this.state.inputErrorMessage}
                  siteType={params.siteType}
                />
              </>
            )}
            <div className="gochi-stamp-combo mt-4">
              <div className="gochi-stamp-combo-info border-radius-0">
                {isEducateSystem ? (
                  this.getFooterSystem(gochiInfo)
                ) : (
                  <div>
                    {gochiInfo.gochiBillNo && <div className="clr-000000 fs-18 font-weight-bold">領収書番号 : {gochiInfo.gochiBillNo}</div>}
                    <div><span className="fs-12 clr-000000">チケット利用問合先 : </span><a href="tel:050-5536-3317" className="fs-12 clr-F44A4A text-decoration-underline">050-5536-3317</a></div>
                    <div className="fs-12 text-red">※サポートセンターへの問い合わせ電話です。ご予約は、直接店舗様にお願いいたします。</div>
                    <div className="fs-12 clr-000000">平日（月～金） 10:00～18:00／土曜日 10:00～15:00</div>
                    <div className="fs-12 clr-000000">※お休み：日曜日・祝日 ・長期休暇・お盆休み・年末年始</div>
                  </div>
                )}
              </div>
            </div>
          </div>}
        </div>

        {this.state.showGochiUseConfirm && (
          <GochiUseConfirmModal
            show={this.state.showGochiUseConfirm} 
            onCancel={() => this.setState({ showGochiUseConfirm: false })}
            disabledSubmitButton={this.state.submitConfirm}
            shopName={shopName}
            totalUseAmount={totalUseAmount}
            onSubmit={this.onSubmitConfirm}
            siteType={params.siteType}
            menuName={gochiInfo.menuName}
            showPrice={gochiInfo.showPrice}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content) =>
    dispatch(showError(showing, title, content)),
  savePaymentMethod: (paymentMethod) =>
    dispatch(savePaymentMethod(paymentMethod)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GochiUseStaff))
);

import { Alert, InputGroup } from 'react-bootstrap';
import React, { Component } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import PopupConfirm from '../../common/PopupConfirm';
import MenuService from '../../../api/services/MenuService';
const queryString = require('query-string');

const validatePhoneNumber = (phoneNumber) => {
    return /([0-9]{9,18})\b/.test(phoneNumber);
    // return true;
}

class SmsInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneNumber: '',
            message: '',
            showErrorMessage: false,
            showSuccessMessage: false,
            popupShowing: false,
            popupTitle: '',
            countryCode: process.env.REACT_APP_MODE_DEVELOP === "true" ? '84' : '81',
            popupContent: this.props.t('pages.invite.sms pop up confirm message'),
            disableSendButton: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

    }

    handleSubmit = (e) => {
        e.preventDefault();
         if (!validatePhoneNumber(this.state.phoneNumber)) {
             this.setState(state => ({
                 ...state,
                 showErrorMessage: true,
                 showSuccessMessage: false,
                message: this.props.t('pages.invite.enter a valid phone number')
             }))
             return;
         }

         this.setState(state => ({
             ...state,
             showErrorMessage: false,
             popupShowing: true,
         }))
    }

    handlePopupConfirm = async () => {
        const query = queryString.extract(this.props.inviteUrl);
        const param = queryString.parse(query);
        const result = await MenuService.sendSms(this.props.gochiBillId, 
            this.state.countryCode + this.state.phoneNumber, param.code);
        console.log('SEND SMS RESULT: ', result);
        if (result.status === 200) {
            this.setState(state => ({
                ...state,
                popupShowing: false,
                showSuccessMessage: true,
                message: this.props.t('pages.invite.send sms success')
            }))
        } else if (result.status === 400) {
            this.setState(state => ({
                ...state,
                popupShowing: false,
                showSuccessMessage: false,
                showErrorMessage: true,
                message: result.message,
                // message: this.props.t('pages.login.An error has occurred'),
                disableSendButton: true
            }))
        } else {
            this.setState(state => ({
                ...state,
                popupShowing: false,
                showSuccessMessage: false,
                showErrorMessage: true,
                // message: result.message
                message: this.props.t('pages.login.An error has occurred')
            }))
        }
    }

    handlePopupClose = () => {
        this.setState(state => ({
            ...state,
            popupShowing: false,
        }))
    }

    render() {
        const email = this.props.email ? this.props.email : '';
        const show = email.includes('@ezweb.ne.jp') 
            || email.includes('@au.com') 
            || email.includes('@docomo.ne.jp') 
            || email.includes('@softbank.ne.jp') 
            || email.includes('@i.softbank.jp') 
            || email.includes('@vodafone.ne.jp') 
            || email.includes('@icloud.com')
            || email.includes('@ai-t.vn');

        const disableSendBtn = this.props.inviteUrl === null || this.state.disableSendButton ? true : false;

        return show && (<div className="sms-input-container">
            <div>
                {this.props.t('pages.invite.mail domain warning')}
            </div>
            <div className="font-weight-bold text-red">
                {this.props.t('pages.invite.mail domain warning 2')}
            </div>
            <div className="mt-3">
                {this.props.t('pages.invite.send via sms message')}
            </div>
            <Form onSubmit={this.handleSubmit} style={{padding: "0 10px"}}>
                <InputGroup>
                    {/* <InputGroup.Prepend className="mt-2">
                        <InputGroup.Text>+</InputGroup.Text>
                    </InputGroup.Prepend> */}
                <FormControl 
                    type="text" 
                    placeholder="0123456789"
                    value={this.state.phoneNumber}
                    disabled={this.state.showSuccessMessage}
                    className="mt-2"
                    onChange={(e) => {
                        const value = e.target.value.replace(/[^\d]/, '');
                        this.setState({
                            phoneNumber: value
                        })
                    }}
                    maxLength={20}>
                </FormControl>
                </InputGroup>

                {this.state.showErrorMessage && 
                <Alert variant="danger" className="mt-2">{this.state.message}</Alert>}

                {this.state.showSuccessMessage ?
                (<Alert variant="success" className="mt-2">{this.state.message}</Alert>) : (
                    <button
                    type="submit"
                    className="btn-submit-sms mt-2"
                    disabled={disableSendBtn}
                >
                    {this.props.t('pages.invite.send sms button')}
                </button>
                )}
            </Form> 
            <PopupConfirm 
                title={this.state.popupTitle} 
                showing={this.state.popupShowing} 
                content={this.state.popupContent} 
                handleConfirm={this.handlePopupConfirm}
                handleClose={this.handlePopupClose}
                confirmation={this.props.t('pages.invite.send sms')}
                cancelation={this.props.t('pages.invite.change phone number')}
				/>
        </div>)
    }
}

export default SmsInput;
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { IntlProvider } from "react-intl";
import { BrowserRouter as Router } from "react-router-dom";

// Libs
import "./lib";

// Styles
import "./index.css";

// Components
import App from "./components/layout/App";
import * as serviceWorker from "./serviceWorker";

// Config redux
import { rootreducer } from "./reducers";

// import i18n (needs to be bundled ;))
import "./i18n";

import firebase from "firebase/app";
import "firebase/auth";

import firebaseConfigDev from "./firebase_dev.config";
import firebaseConfigPro from "./firebase_pro.config";

import Amplify from "aws-amplify";

const url = window.location.href;
const arr = url.split("/");
const domain = arr[0] + "//" + arr[2];

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      // scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      // redirectSignIn: process.env.REACT_APP_DOMAIN_URL,
      // redirectSignOut: process.env.REACT_APP_DOMAIN_URL,
      redirectSignIn: domain,
      redirectSignOut: domain,
      responseType: "code",
    },
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
      region: process.env.REACT_APP_REGION,
    },
  },
});
var AWS = require("aws-sdk");
AWS.config.update({
  region: process.env.REACT_APP_REGION,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  }),
});

switch (process.env.REACT_APP_ENV) {
  case "production":
    firebase.initializeApp(firebaseConfigPro);
    break;
  default:
    firebase.initializeApp(firebaseConfigDev);
    break;
}

if (!process.env.REACT_APP_DEBUG) {
  console.log = () => {};
}

export const AuthContext = React.createContext(null);

const store = createStore(rootreducer, applyMiddleware(thunk));

if (!process.env.REACT_APP_DEBUG) {
  console.log = () => {};
}

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider locale="en">
      <Router>
        <App />
      </Router>
    </IntlProvider>
  </Provider>,

  document.getElementById("root")
);

serviceWorker.unregister();

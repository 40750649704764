import React from "react";
import MetaTags from 'react-meta-tags';
import moment from 'moment';
import { CustomPagination } from "../../common/Pagination";

export function GochiKidSupportMessage(props) {
  return (
    <>
      <MetaTags>
        <title>自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content="自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta name="twitter:description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:image" content="/assets/img/common/ogp.png" />
        <meta property="og:site_name" content="自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:title" content="自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content="/assets/img/common/ogp.png" />
        <meta property="og:description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
      </MetaTags>

      <hr className="mt-30" />
      <div className="komodo-support-donated-massage" id="scrollToElementId">
        <div className={`profile-history ${props.loadingGochiCharge ? 'loading': ''}`}>
          <div className="products-group products-group-column">
            <div className="section-header">
              <div className="heading-lg">
                <h1 className="title">
                  ご支援先からの感謝メッセージ・運営報告
                </h1>
              </div>
            </div>
            <div className="message-donator-tab">
              <div className="tab-link-container">
                <button
                  className={`tab-link message-donator-tab-link ${props.numberTabThankMessageDonator === 0 ? "tab-link-enable" : ""}`}
                  onClick={() => props.handleChangeTabMessageDonatorGochiKid(0)}
                >
                  利用者から
                </button>
                <button
                  className={`tab-link ${props.numberTabThankMessageDonator === 1 ? "tab-link-enable" : ""}`}
                  onClick={() => props.handleChangeTabMessageDonatorGochiKid(1)}
                >
                  店舗から
                </button>
              </div>
              <div className={`message-donator-container ${props.numberTabThankMessageDonator === 0 ? "showing" : ""}`}>
                <div className="message-box">
                  {props.listMessageDonatorGochiKid.length > 0 ? props.listMessageDonatorGochiKid.map((item, key) => {
                    let index = `${item.shopId}-${key}`;
                    return (
                      <article className="products gochi-used-item" key={index}>
                        <dl className="list-description">
                          <div className="list-description-item">
                            <dt className="title">投稿日時</dt>
                            <dd className="text font-weight-normal">{item.createdAt ? moment(item.createdAt).format('YYYY年MM月DD日') : ''}</dd>
                          </div>
                          <div className="list-description-item">
                            <dt className="title description-gochi-charge">店舗情報</dt>
                            <dd className="text font-weight-normal">
                              <span className="message-shop-name">{item.shopName}</span>
                              <span className="message-shop-address">{(item.prefectures || item.city) && ("(" + (item.prefectures ? item.prefectures + ' ' : '') + (item.city ? item.city : '') +")")}</span>
                            </dd>
                          </div>
                          <div className="list-description-item">
                            <dt className="title">感謝メッセージ</dt>
                          </div>
                          <div className="font-weight-normal text-massage">{item.message}</div>
                        </dl>
                      </article>
                    )
                  }) : (
                    <div className="no-gochi-kid-used">現在までの感謝メッセージはありません。</div>
                  )}
                </div>
                {props.listMessageDonatorGochiKid.length > 0 && (
                  <CustomPagination
                    count={Math.ceil(props.totalMessageDonatorGochiKid / props.limit)}
                    page={props.pageMessageDonatorGochiKid}
                    onChange={props.handleChangePage}
                  />
                )}
              </div>
              <div className={`report-shop-container ${props.numberTabThankMessageDonator === 1 ? "showing" : ""}`}>
                <div className="message-box">
                  {props.listReportShopGochiKid.length > 0 ? props.listReportShopGochiKid.map((item, key) => {
                    let index = `${item.shopId}-${key}`;
                    return (
                      <article className="products gochi-used-item" key={index}>
                        <dl className="list-description">
                          <div className="list-description-item">
                            <dt className="title">投稿日時</dt>
                            <dd className="text font-weight-normal">{item.createdAt ? moment(item.createdAt).format('YYYY年MM月DD日') : ''}</dd>
                          </div>
                          <div className="list-description-item">
                            <dt className="title description-gochi-charge">店舗情報</dt>
                            <dd className="text font-weight-normal">
                              <span className="message-shop-name">{item.shopName}</span>
                              <span className="message-shop-address">{(item.prefectures || item.city) && ("(" + (item.prefectures ? item.prefectures + ' ' : '') + (item.city ? item.city : '') +")")}</span>
                            </dd>
                          </div>
                          <div className="list-description-item">
                            <dt className="title">感謝メッセージ</dt>
                          </div>
                          <div className="font-weight-normal text-massage">{item.message}</div>
                          <div className="list-description-item description-img-report-shop">
                            <dt className="title">写真</dt>
                          </div>
                          <div className="img-report-shop">
                            <img src={item.shopReportImageUrl || props.NO_IMAGE_MENU}/>
                          </div>
                        </dl>
                      </article>
                    )
                  }) : (
                    <div className="no-gochi-kid-used">現在までの感謝メッセージはありません。</div>
                  )}
                </div>
                {props.listReportShopGochiKid.length > 0 && (
                  <CustomPagination
                    count={Math.ceil(props.totalReportShopGochiKid / props.limit)}
                    page={props.pageReportShopGochiKid}
                    onChange={props.handleChangePage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

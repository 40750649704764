/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  useScript,
  useStyleSheet,
  useGenres,
  usePriceRanges,
  useSituations,
  useUserTargets,
} from "../../../../utils";
import GiftService from "../../../../api/services/GiftService";
import { Footer } from "../../layout/Footer";
import Header from "../../layout/Header";
import { showLoading } from "../../../../actions/action_loading";
import { showError } from "../../../../actions/action_error_alert";
import { ModalGroup } from "../../common/ModalGroup";
import { SituationModal } from "../../common/SituationModal";
import { GiftToModal } from "../../common/GiftToModal";
import RecommendedMenu from "./RecommendedMenu";
import { withTranslation } from "react-i18next";
import { NotLoggedModal } from "../../common/NotLoggedModal";
import { WishListModal } from "../../common/WishListModal";
import { Explanation } from "../../layout/Explanation";

export function SpecialDetail(props) {
  const NO_IMAGE = require("../../../../assets/img/icons/noimage.png");

  useStyleSheet("https://unpkg.com/swiper/swiper-bundle.min.css",
    null,
    {
      integrity:
        "sha384-HUXJxzkMpCxEdXkgl8BSjN5SUcUODepsOLIYIWOtKva+Qo2teMmV1mh6ZVwnthaV",
      crossorigin: "anonymous",
      referrerpolicy: "no-referrer",
    }
  );
  useScript("https://unpkg.com/swiper/swiper-bundle.min.js",
    null,
    { integrity: "sha384-XVTmRHMO2XUTzy8IerJVEy8ddg47Yz5pZrLc7nEWipaTowqT1xNb8qTLaw64Bd4S",
      crossorigin: "anonymous"
    }
  );
  useScript("/assets/js/app.js");

  const match = useRouteMatch();
  const slug = match.params.slug;
  const genres = useGenres();
  const situations = useSituations();
  const priceRanges = usePriceRanges();
  const userTargets = useUserTargets();
  const [specialDetail, setSpecialDetail] = useState({});
  const [listRecommendedMenu, setListRecommendedMenu] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingRecommended, setLoadingRecommended] = useState(false);

  const history = useHistory();
  const { t } = props;

  const fetchJsFromCDN = (href, externals = []) => {
    new Promise((resolve, reject) => {
      const link = document.createElement('link')
      link.setAttribute('href', href)
      link.setAttribute('rel', 'stylesheet')
      link.addEventListener('load', () => {
        resolve(externals.map(key => {
          const ext = window[key]
          typeof ext === 'undefined' && console.warn(`No external named '${key}' in window`)
          return ext
        }))
      })
      link.addEventListener('error', reject)
      document.body.appendChild(link)
    })
  }

  useEffect(() => {
    fetchJsFromCDN('https://gochimeshi.com/wordpress/wp-content/themes/gochimeshi-wp-theme-legacy/gift-site-style.css', ['giftsite']);
  }, []);

  useEffect(() => {
    if (
      genres.length === 0 ||
      situations.length === 0 ||
      priceRanges.length === 0 ||
      userTargets.length === 0
    )
      return;
    getSpecialDetail(slug);
  }, [genres, situations, priceRanges, userTargets, slug]);

  function getTitle() {
    return (specialDetail.title || "") +
    "｜特集一覧｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス";
  }

  function getMetaDescription() {
    return `${
      specialDetail.title || ""
    }のページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。`;
  }

  async function getSpecialDetail(slug) {
    GiftService.getSpecialCampaignsDetail(slug)
      .then((response) => {
        if (response.status === 200) {
          setSpecialDetail(response.data);
          const recommendedMenu = response.data.recommended_menu_sections;
          if (
            response.data.recommended_menu_sections &&
            response.data.recommended_menu_sections.length
          ) {
            getRecommendedMenu(recommendedMenu);
          }
          setLoading(false);
          console.log("success");
        } else if (response.status === 404) {
          setLoading(false);
          history.push({
            pathname: "/404",
          });
        } else {
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.error("GET DETAIL SPECIAL ERROR: ", e);
      });
  }

  function getSituationId(name) {
    if (name) {
      const situationObj = situations.find(
        (item) => item.menuSituationName === name
      );
      if (situationObj) return situationObj.menuSituationId;
    }
    return "";
  }

  function getGenreId(name) {
    if (name) {
      const genreObj = genres.find((item) => item.menuGenreName === name);
      if (genreObj) return genreObj.menuGenreId;
    }
    return "";
  }

  function getPriceRangeFromTo(name) {
    if (name) {
      const priceRangeObj = priceRanges.find((item) => item.name === name);
      console.log("price range obj:", priceRangeObj);
      if (priceRangeObj) return [priceRangeObj.from, priceRangeObj.to];
    }
    return ["", ""];
  }

  function getUserTargetId(name) {
    if (name) {
      const userTargetObj = userTargets.find((item) => item.name === name);
      if (userTargetObj) return userTargetObj.menuUserTargetId;
    }
    return "";
  }

  function getUrlParam(item) {
    const params = [];
    if (item.conditions.prefecture && item.conditions.prefecture.label) {
      params.push(`prefecture=${item.conditions.prefecture.label}`);
    }
    if (item.conditions.city && item.conditions.city.label) {
      params.push(`city=${item.conditions.city.label}`);
    }
    if (item.conditions.situation && item.conditions.situation.name) {
      params.push(`situation=${item.conditions.situation.name}`);
    }
    if (item.conditions.recipient && item.conditions.recipient.name) {
      params.push(`who=${item.conditions.recipient.name}`);
    }
    if (item.conditions.genre && item.conditions.genre.name) {
      params.push(`genre=${item.conditions.genre.name}`);
    }
    if (item.conditions.budget && item.conditions.budget.name) {
      const budgetName = item.conditions.budget.name;
      params.push(`prices=${budgetName}`);
    }
    return params.join("&");
  }

  async function getRecommendedMenu(recommendedMenu) {
    setLoadingRecommended(true);
    const recommended = [];
    for await (const item of recommendedMenu) {
      let data = {
        title: item.title,
        insertSectionId: item.insert_section_id,
        urlParams: getUrlParam(item),
        afterDescription: item.after_description,
        beforeDescription: item.before_description,
      };
      const [from, to] = getPriceRangeFromTo(item.conditions.budget.name);
      const parameters = {
        page: 1,
        perPage: 5,
        prefectures: item.conditions.prefecture.label,
        city: item.conditions.city.label,
        situationId: getSituationId(item.conditions.situation.name),
        menuUserTargetId: getUserTargetId(item.conditions.recipient.name),
        genreId: getGenreId(item.conditions.genre.name),
        keyword: item.conditions.keyword,
        priceFrom: from,
        priceTo: to,
        orderBy: 1,
      };
      console.log("parameters", parameters);
      const response = await GiftService.getMenus(parameters);
      data.recommendedMenu = response.data?.list || [];
      console.log("PUSHDATA: ", data);
      recommended.push(data);
    }
    setLoadingRecommended(false);
    console.log("FINAL RESULT: ", recommended);
    setListRecommendedMenu([...recommended]);
  }

  function getSrc(item) {
    if (!loading) {
      if (item.thumbnails?.full?.url) {
        return item.thumbnails.full.url;
      } else return NO_IMAGE;
    }
    return "";
  }

  function getSrcMedium(item) {
    if (!loading) {
      if (item.thumbnails?.medium?.url) {
        return item.thumbnails.medium.url;
      } else return NO_IMAGE;
    }
    return "";
  }
  let arr = (specialDetail.content + "").split(
    /<div className=\"wp-block-gochimeshi-block-single-insert-rms-box gochimeshi-block-single-insert-rms-box\" data-insert-recommended-menu-section=\"\w+\"><\/div>/
  );
  let arrPos = (specialDetail.content + "").match(
    /<div className=\"wp-block-gochimeshi-block-single-insert-rms-box gochimeshi-block-single-insert-rms-box\" data-insert-recommended-menu-section=\"\w+\"><\/div>/g
  );
  
  useEffect(() => {
  const script = document.createElement("script");

  script.src = "https://platform.twitter.com/widgets.js";
  script.async = true;
  document.body.appendChild(script);
  }, [])

  return (
    <>
      <MetaTags>
        <title>{getTitle()}</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content={getMetaDescription()} />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content={getTitle()} />
        <meta name="twitter:description" content={getMetaDescription()} />
        <meta name="twitter:image" content={getSrc(specialDetail)} />
        <meta name="twitter:site" content="@gochi_meshi" />
        <meta name="twitter:creator" content="@gochi_meshi" />
        <meta name="twitter:image:width" content="300" />
        <meta name="twitter:image:height" content="300" />
        <meta property="og:site_name" content={getTitle()} />
        <meta property="og:title" content={getTitle()} />
        <meta property="og:type" content="blog" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={getSrc(specialDetail)} />
        <meta property="og:description" content={getMetaDescription()} />
      </MetaTags>
      <Header />

      <main className={loading ? "loading" : ""}>
        <div className="breadcrumb">
          <div className="container">
            <ol className="breadcrumb-list">
              <li>
                <a href="/">{t("pages.specials.topPage")}</a>
              </li>
              <li>
                <li>
                  <a href="/specials">{t("pages.specials.list.breadcrum")}</a>
                </li>
                <span>{specialDetail.title}</span>
              </li>
            </ol>
          </div>
        </div>

        <div className="container">
          <article className="specials-article">
            <div className="specials-article-thumbnail">
              {Object.keys(specialDetail).length > 0 && specialDetail.constructor === Object && (
                <img
                  src={getSrc(specialDetail)}
                  className="img"
                  alt={specialDetail.slug}
                  loading="lazy"
                />
              )}
            </div>
            <header className="specials-article-header">
              <div className="heading">
                <h2 className="title">{specialDetail.title}</h2>
              </div>
            </header>
            <div className="specials-article-body">
              {arr.length > 1 &&
                arr.map((item, key) => {
                  return (
                    <>
                      {ReactHtmlParser(item)}
                      {key <= arrPos.length &&
                        listRecommendedMenu.map((recommend, k) => {
                          let index = `recommend-${k}`;
                          return arrPos &&
                            typeof arrPos[key] !== "undefined" &&
                            arrPos[key].includes(
                              'data-insert-recommended-menu-section="' +
                                recommend.insertSectionId +
                                ""
                            ) ? (
                            <>
                              <RecommendedMenu
                                loading={loadingRecommended}
                                recommend={recommend}
                                index={index}
                              />
                            </>
                          ) : null;
                        })}
                    </>
                  );
                })}
              {arr.length <= 1 && ReactHtmlParser(specialDetail.content)}
            </div>
          </article>
        </div>

        <aside>
          {listRecommendedMenu && listRecommendedMenu.length > 0 && (
            <>
              {listRecommendedMenu.map((recommend, key) => {
                let index = `recommend-${key}`;
                return (
                  !specialDetail.content.includes(
                    'data-insert-recommended-menu-section="' +
                      recommend.insertSectionId +
                      '"'
                  ) && (
                    <RecommendedMenu
                      loading={loadingRecommended}
                      recommend={recommend}
                      index={index}
                    />
                  )
                );
              })}
            </>
          )}
        </aside>

        <Explanation />
      </main>

      <Footer />

      <ModalGroup>
        <SituationModal />
        <GiftToModal />
        <NotLoggedModal />
        <WishListModal />
      </ModalGroup>
    </>
  );
}

const mapStateToProps = (state) => ({ props: state.props });
const mapDispatchToProps = (dispatch) => ({
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content, buttonName, gotoUrl) =>
    dispatch(showError(showing, title, content, buttonName, gotoUrl)),
});

export default connect(
  mapDispatchToProps,
  mapStateToProps
)(withTranslation()(SpecialDetail));

import React, { useState, useEffect } from "react";
import MetaTags from 'react-meta-tags';
import queryString from 'query-string';
import moment from 'moment';
import { CustomPagination } from "../../common/Pagination";
import {
  addParamUrl
} from "../../../../utils";

export function GochiKidUsed(props) {
  const paramsUrl = queryString.parse(window.location.search);
  const isSite = paramsUrl.isSite;
  const isSiteParam = isSite ? {name: 'isSite', value: isSite} : {};
  const [isLoadMoreBoxStatus, setIsLoadMoreBoxStatus] = useState(false);

  useEffect(() => {
    //
  }, []);

  return (
    <div className="list-menu-used-gochi-kid">
      <MetaTags>
        <title>自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content="自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta name="twitter:description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:image" content="/assets/img/common/ogp.png" />
        <meta property="og:site_name" content="自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:title" content="自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content="/assets/img/common/ogp.png" />
        <meta property="og:description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
      </MetaTags>

      <hr className="mt-48px" />
      {props.userKidData && props.userKidData.userKids && props.userKidData.userKids.length !== 0 && (
        <div className="box-status-user-kid">
          <div className="box-content">
            <div className="header-box">【審査結果】</div>
            <div className={`body-box ${!isLoadMoreBoxStatus ? 'display-none' : ''}`}>
              {(props.userKidData && props.userKidData.poorStatus !== "INIT") && (
                <div className="poor-box">
                  <p className="title">生活保護受給：</p>
                  <p className="text">{props.userKidData?.poorStatus ? (props.userKidData?.poorStatus === "RESUBMIT" ? "再提出依頼中" : ('審査' + (props.userKidData?.poorStatus === "REVIEW" ? "中" : props.userKidData?.poorStatus))) : ''}</p>
                </div>
              )}
              <div className="user-kid-box">
                <p className="title">生年月日：</p>
                <p className="text">審査中 {props.userKidData?.sumChildrenReview || 0}人</p>
                <p className="text">審査OK {props.userKidData?.sumChildrenOk || 0}人</p>
                <p className="text">審査NG {props.userKidData?.sumChildrenNg || 0}人</p>
                <p className="text">再提出依頼中 {props.userKidData?.sumChildrenResubmit || 0}人</p>
              </div>
            </div>
            <div className="load-more">
              <span onClick={() => setIsLoadMoreBoxStatus(!isLoadMoreBoxStatus)}>{isLoadMoreBoxStatus ? "閉じる" : "開く"}</span>
            </div>
          </div>
        </div>
      )}
      <div className="header-list-menu">
        {Object.keys(props.userKidData).length !== 0 && (
          <div
            onClick={props.handleShowPopupRegisterUserKidGochiKid}
            className={`btn-regis-user-kid`}
          >
            <i className="icon-add-user-kid"/>
            {props.userKidData.userKids?.length === 0 ? "利用者登録" : "利用者情報を編集"}
          </div>
        )}
        <div className="btn-policy">
          <span onClick={props.handleShowPolicyGochiKidModal}>
            カード型会員証発行申請
            <br />
            （一部店舗でのみ利用可能）
          </span>
        </div>
        {props.userKidData?.sumChildrenOk > 0 && (
          <>
            <div
              //href={addParamUrl("/mypage/kodomo_use/search_group_shop", [isSiteParam])}
              onClick={() => {
                props.handleShowListGroupSpecialGochiKidModal();
                document.getElementsByTagName('html')[0].classList.add('hide-scroll-background-gochi-kid');
              }}
              className={`btn-go-shop ${props.thanksMessageIsNull ? 'disable-btn-go-shop' : ''}`}
            >
              <i className="icon-go-shop"/>
              店舗・メニューを探す
            </div>
            {props.thanksMessageIsNull && (
              <div className="warning">
                <i className="icon-warning-go-shop"/>
                すべての利用済みメニューに感謝メッセージを投稿後、新たにこどもごちめしのメニューがご利用可能になります。利用履歴をご確認頂き、感謝メッセージを投稿してください。
              </div>
            )}
          </>
        )}
      </div>
      {props.userKidData?.sumChildrenOk > 0 && (
        <div className="gochi-gochi-used-list mt-24px" id="scrollToElementId">
          <div className={`profile-history ${props.loadingGochiKidUsed ? 'loading': ''}`}>
            <div className="products-group products-group-column">
              <div className="title-gochi-used">利用履歴</div>
              <div className="gochi-kid-used-box">
                {props.searchResults.length > 0 ? (props.searchResults.map((item, key) => {
                  let index = `${item.gochiKidUseId}-${key}`;
                  var image = item.menuImage && item.menuImage.length > 0 && item.menuImage[0].image ? 
                                  item.menuImage[0].image : props.NO_IMAGE_MENU;
                  var shopName = item?.shopName ? item.shopName : null;
                  var menuName = item?.menuName ? item.menuName : null;
                  var userKidNumUse = item?.userKidNumUse ? item.userKidNumUse : 0;
                  var usedAmountPerKid = item.usedAmount ? (item.usedAmount / (userKidNumUse || 1)) : 0;

                  return (
                    <article className="products gochi-kid-item gochi-kid-used" key={index}>
                      <div className="gochi-kid-support-item">
                        <dl className="list-description text-sm">
                          <div className="gochi-kid-digest">
                            <div className="gochi-kid-thumbnail">
                              <div className="thumbnail">
                                <img
                                  src={image}
                                  alt="menu image"
                                  loading="lazy"
                                />
                              </div>
                            </div>
                            <div className="shop-header-group">
                              <div className="shop-header">
                                <div className="shop-name">
                                  {shopName}
                                </div>
                                <h2 className='menu-name'>
                                  {menuName}
                                </h2>
                              </div>
                              <hr />
                              {item.menuId
                                ? 
                                  <div className="price-area">
                                    <span className="unit">¥</span>
                                    <span className="num">
                                      {`${usedAmountPerKid?.toLocaleString()} x ${userKidNumUse}`}
                                    </span>
                                  </div>
                                :
                                  <div className="price-area">
                                    <div>
                                      <div>
                                        <span>利用人数: </span>
                                        <span className="num">{userKidNumUse}</span>
                                        <span className="unit">人</span>
                                      </div>
                                      <div>
                                        <span>合計金額: </span>
                                        <span className="num">{item.usedAmount?.toLocaleString()}</span>
                                        <span className="unit">円</span>
                                      </div>
                                    </div>
                                  </div>
                              }
                            </div>
                          </div>
                          <div className="list-description-item">
                            <dt className="title font-weight-normal">利用日</dt>
                            <dd className="text font-weight-normal">
                              {item.usedAt ? props.formatDate(item.usedAt) : ''}
                            </dd>
                          </div>
                          <div className="list-description-item gochi-detail">
                            <dt className="title font-weight-normal">詳細</dt>
                            <dd className="text font-weight-normal">
                              <button onClick={() => props.handleShowPopupGochiKidUsedDetail(item)}>
                                詳細を確認する
                              </button>
                            </dd>
                          </div>
                        </dl>
                        <div className="btn-gochi-kid-use">
                          <button
                            className="btn btn-rect btn-sm btn-use-gochi-charge"
                            type="button"
                            onClick={() => {
                              props.handleShowPopupSendMessageGochiKid(item);
                            }}
                            disabled={item.thanksMessage}
                          >
                            {item.thanksMessage ? '感謝メッセージを投稿済み' : '感謝メッセージを投稿する'}
                          </button>
                        </div>
                      </div>
                    </article>
                  );
                })) : (
                  <p className="no-gochi-kid-used">現在までの利用履歴はありません。</p>
                )}
              </div>
              
            </div>
            {props.searchResults.length > 0 && (
              <CustomPagination
                count={Math.ceil(props.totalResult / props.limit)}
                page={props.page}
                onChange={props.handleChangePage}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import CommonModal from "../common/CommonModal";


function NavBar(props) {
  const userInfo = useSelector((state) => state.userInfo);
  const isLoggedIn = userInfo && userInfo.userId;
  const history = useHistory();
  const location = useLocation();
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);

  function goHome() {
    window.open('/mogugori', '_self');
  }
  function goShop() {
    const ShopId = sessionStorage.getItem('mogugoriShopId');
    if (ShopId) {
      window.open('/mogugori/shopDetail/' + ShopId, '_self');
    } else {
      setShowNotificationPopup(true);
    }
  }

  function handleCloseNotificationPopup() {
    if (window.location.pathname !== "/mogugori") {
      goHome();
    }
    setShowNotificationPopup(false);
  }

  function login() {
    window.open('/login?isSite=mogugori', '_self');
  }
  function logout() {
    window.open('/logout?isSite=mogugori', '_self');
  }
  function mypage() {
    window.open('/mypage?isSite=mogugori', '_self');
  }
  return (
    <>
      <div className="nav-bar nav-bar-mogugori">
        <div onClick={goHome} className="nav-bar-item">
          <i className="icon-home" />
          {/* <div className="title">ホーム</div> */}
        </div>
        <div onClick={goShop} className="nav-bar-item">
          <i className="icon-shop" />
          {/* <div className="title">検索</div> */}
        </div>
        {isLoggedIn ? (
          <div onClick={mypage} className="nav-bar-item">
            <i className="icon-user" />
            {/* <div className="title">マイページ</div> */}
          </div>
        ) : (
          <div onClick={login} className="nav-bar-item">
            <i className="icon-user" />
            {/* <div className="title">マイページ</div> */}
          </div>
        )}
        <div onClick={logout} className="nav-bar-item">
          <i className="icon-logout" />
          {/* <div className="title">特集記事</div> */}
        </div>
      </div>
      <CommonModal
        show={showNotificationPopup}
        closePopup={handleCloseNotificationPopup}
        content={"店舗をご選択ください。"}
        textButtonClose={"店舗一覧へ"}
      />
      <div id="global-nav-btn" className="hide-item"></div>
      <div id="header-search-nav" className="hide-item"></div>
    </>
  );
}

export default NavBar;

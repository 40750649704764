import React, { Component } from "react";   
import {connect} from 'react-redux'; 
import * as constants from '../../../constants';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string'
import PaymentCardBrands from "../../common/PaymentCardBrands";
import logoAtone from "../../../assets/img/icons/logo_atone.png";
import PopupAtoneDescription from "../../common/PopupAtoneDescription";

class Payment extends Component {
	constructor(){
	    super(); 
	    this.state = { 
	    	menuId: null,
            redirectToReferrer: false,
            buttonActive: false,
            showAtoneDescription: false,
			atoneHide: false
        } 
	}
	UNSAFE_componentWillMount(){ 
		let url = window.location.search;
		let params = queryString.parse(url);
		var menuId = params.id; 

		this.setState({
			menuId: menuId
		});
    }

    componentDidMount(){
		if (!window.Atone) {
			this.setState({
				atoneHide: true
			});
		} else {
			this.setState({
				atoneHide: false
			});
		}
	}

    handleActiveButton = (e) => {
        this.setState({ buttonActive: !this.state.buttonActive });
        console.log('e.target.value', e.target.value);
        sessionStorage.setItem('selectPayment', e.target.value);
    }
    render() {
        const isMenuSubscription = this.props.menuInfo.subscription === 1;
		const paypaySupport = ((this.props.shopInfo
			&& this.props.shopInfo.paymentTyptes
			&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_PAYPAY)
			&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_PAYPAY).status === 1) || this.props.menuInfo.groupId)
			&& !isMenuSubscription
			? true : false;
        
		const alipaySupport = ((this.props.shopInfo
			&& this.props.shopInfo.paymentTyptes
			&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_ONEPAY_ALIPAY)
			&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_ONEPAY_ALIPAY).status === 1) || this.props.menuInfo.groupId)
			&& !isMenuSubscription
			? true : false;
        
        const wechatPaySupport = this.props.shopInfo 
			&& this.props.shopInfo.paymentTyptes 
			&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_WECHATPAY)
			&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_WECHATPAY).status === 1 
            && !isMenuSubscription
			? true : false;

		const linepaySupport = this.props.shopInfo 
            && this.props.shopInfo.paymentTyptes 
            && this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_ONEPAY_LINEPAY)
            && this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_ONEPAY_LINEPAY).status === 1 
            && !isMenuSubscription
            ? true : false;

		const merupaySupport = this.props.shopInfo 
            && this.props.shopInfo.paymentTyptes 
            && this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_ONEPAY_MERUPAY)
            && this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_ONEPAY_MERUPAY).status === 1 
            && !isMenuSubscription
            ? true : false;

        // const atoneSupport = this.props.shopInfo 
        //     && this.props.shopInfo.paymentTyptes 
        //     && this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_ATONE)
        //     && this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_ATONE).status === 1 
        //     && !isMenuSubscription
        //     ? true : false;
        const atoneSupport = !isMenuSubscription;

        const validPaymentTypes = [];
		validPaymentTypes.push(constants.PAYMENT_TYPE_CREDIT_CARD);
		if (paypaySupport) validPaymentTypes.push(constants.PAYMENT_TYPE_PAYPAY);
		if (alipaySupport) validPaymentTypes.push(constants.PAYMENT_TYPE_ONEPAY_ALIPAY);
		if (wechatPaySupport) validPaymentTypes.push(constants.PAYMENT_TYPE_WECHATPAY);
		if (linepaySupport) validPaymentTypes.push(constants.PAYMENT_TYPE_ONEPAY_LINEPAY);
		if (merupaySupport) validPaymentTypes.push(constants.PAYMENT_TYPE_ONEPAY_MERUPAY);
		if (atoneSupport) validPaymentTypes.push(constants.PAYMENT_TYPE_ATONE);

        const onlyCreditCardSupport = !paypaySupport && !alipaySupport && !wechatPaySupport && !linepaySupport && !merupaySupport && !atoneSupport;

        const { t } = this.props; 

        if (this.props.shopInfo) {
            if (sessionStorage.getItem('selectPayment')) {
				if (!validPaymentTypes.includes(parseInt(sessionStorage.getItem('selectPayment')))) {
					sessionStorage.removeItem('selectPayment');
				}
			}
			if (!sessionStorage.getItem('selectPayment')) {
				if (paypaySupport) {
					sessionStorage.setItem('selectPayment', constants.PAYMENT_TYPE_PAYPAY);
				} else if (alipaySupport) {
					sessionStorage.setItem('selectPayment', constants.PAYMENT_TYPE_ONEPAY_ALIPAY);
				} else if (wechatPaySupport) {
					sessionStorage.setItem('selectPayment', constants.PAYMENT_TYPE_WECHATPAY);
				} else if (linepaySupport) {
					sessionStorage.setItem('selectPayment', constants.PAYMENT_TYPE_ONEPAY_LINEPAY);
				} else if (merupaySupport) {
					sessionStorage.setItem('selectPayment', constants.PAYMENT_TYPE_ONEPAY_MERUPAY);
				} else {
					sessionStorage.setItem('selectPayment', constants.PAYMENT_TYPE_CREDIT_CARD);
				}
			}
		}

        return (
        	<>
                {(!onlyCreditCardSupport) && 
                    <div className="dish-infor pm-info mt-3">
                        <div className="required-dot mb-3">
                            <div className="dot-red"></div>
                            <div className="dot-text-label fs-18">{t('pages.invite.paymentMethod')}</div>
                        </div>
        
                        <div className="box-option">
                            {paypaySupport && 
                                <div className="d-flex">
                                    <div className="">
                                        <label className="radio-custom fs-14">
                                            <input type="radio" name="paymentType" value={constants.PAYMENT_TYPE_PAYPAY}
                                                onChange={this.handleActiveButton}
                                                checked={parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_PAYPAY}
                                            />
                                            <span className="checkmark"></span>
                                            <span>{t('pages.invite.payment paypay')}</span>
                                        </label>
                                    </div>
                                </div>
                            }
                            {alipaySupport &&
                                <div className="d-flex">
                                    <div className="">
                                        <label className="radio-custom fs-14">
                                            <input type="radio" name="paymentType" value={constants.PAYMENT_TYPE_ONEPAY_ALIPAY}
                                                onChange={this.handleActiveButton}
                                                checked={parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_ONEPAY_ALIPAY}
                                            />
                                            <span className="checkmark"></span>
                                            <span>{t('pages.invite.payment alipay')}</span>
                                        </label>
                                        {parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_ONEPAY_ALIPAY &&
                                            <div className="payment-warning-message">{t('pages.invite.alipay warning')}</div>}
                                    </div>
                                </div>
                            }
                            {wechatPaySupport && 
                                <div className="d-flex">
                                    <div className="">
                                        <label className="radio-custom fs-14">
                                            <input type="radio" name="paymentType" value={constants.PAYMENT_TYPE_WECHATPAY}
                                                onChange={this.handleActiveButton}
                                                checked={parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_WECHATPAY}
                                            />
                                            <span className="checkmark"></span>
                                            <span>{t('pages.invite.payment wechatpay')}</span>
                                        </label>
                                        {parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_WECHATPAY &&
                                            <div className="payment-warning-message">{t('pages.invite.wechatpay warning')}</div>}
                                    </div>
                                </div>
                            }
                            {linepaySupport &&
                                <div className="d-flex">
                                    <div className="">
                                        <label className="radio-custom fs-14">
                                            <input type="radio" name="paymentType" value={constants.PAYMENT_TYPE_ONEPAY_LINEPAY}
                                                onChange={this.handleActiveButton}
                                                checked={parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_ONEPAY_LINEPAY}
                                            />
                                            <span className="checkmark"></span>
                                            <span>{t('pages.invite.payment linepay')}</span>
                                        </label>
                                        {parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_ONEPAY_LINEPAY &&
                                            <div className="payment-warning-message">{t('pages.invite.linepay warning')}</div>}
                                    </div>
                                </div>
                            }
                            {merupaySupport &&
                                <div className="d-flex">
                                    <div className="">
                                        <label className="radio-custom fs-14">
                                            <input type="radio" name="paymentType" value={constants.PAYMENT_TYPE_ONEPAY_MERUPAY}
                                                onChange={this.handleActiveButton}
                                                checked={parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_ONEPAY_MERUPAY}
                                            />
                                            <span className="checkmark"></span>
                                            <span>{t('pages.invite.payment merupay')}</span>
                                        </label>
                                        {parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_ONEPAY_MERUPAY &&
                                            <div className="payment-warning-message">{t('pages.invite.merpay warning')}</div>}
                                    </div>
                                </div>
                            }
                            <div className="d-flex">
                                <div className="">
                                    <label className="radio-custom fs-14">
                                        <input type="radio" name="paymentType" value={constants.PAYMENT_TYPE_CREDIT_CARD}
                                            onChange={this.handleActiveButton}
                                            checked={parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_CREDIT_CARD}
                                        />
                                        <span className="checkmark"></span>
                                        <span>{t('pages.invite.payment other')}</span>
                                    </label>
                                    {this.props.enable3dSecure && (
                                      <div className="text-red mb-2 fs-12 text-note-veritrans">※3Dセキュア対応のカードのみ</div>
                                    )}
                                    {parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_CREDIT_CARD &&
                                        <div className="mb-4">
                                            <PaymentCardBrands />
                                        </div>}
                                </div>
                            </div>
                            {atoneSupport &&
                                <div className="d-flex">
                                    <div className="">
                                        {!this.state.atoneHide &&
                                        <label className="radio-custom fs-14">
                                            <input type="radio" name="paymentType" value={constants.PAYMENT_TYPE_ATONE}
                                                onChange={this.handleActiveButton}
                                                checked={parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_ATONE}
                                            />
                                            <span className="checkmark"></span>
                                            <span>{t('pages.invite.payment atone')}</span>
                                        </label>
                                        }
                                        {/* {parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_ATONE && */}
                                            <div className="atone-description">
                                                {!this.state.atoneHide ? (
										        <>
                                                    <div className="text-red mb-2 fs-12">※atoneアカウントをお持ちではない方は、会員登録が必要です</div>
                                                    <div>
                                                        <a
                                                            href="https://atone.be/"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img src={logoAtone} alt="Atone" />
                                                        </a>
                                                    </div>
                                                </>
                                                ) : (
                                                    <div className="fs-12">
                                                        ※広告ブロック等の理由により、atone決済が表示されません。<br />
                                                        atone決済をご利用ご希望の方は、ご利用のブラウザを変更してお試しください。
                                                    </div>
                                                )}
                                                <span onClick={() => this.setState({ showAtoneDescription: true })}>atone説明文</span>
                                            </div>
                                        {/* } */}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
                <PopupAtoneDescription show={this.state.showAtoneDescription} handleClose={() => this.setState({ showAtoneDescription: false })} />
            </>
        ); 
    }
}
 

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({   
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Payment)); 


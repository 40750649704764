import React, { useEffect } from "react";
import MetaTags from 'react-meta-tags';
import { 
  useScript, 
  useStyleSheet
} from "../../../../utils";
import { Footer } from "../../layout/Footer";
import Header from "../../layout/Header";
import { connect } from "react-redux";
import { ModalGroup } from "../../common/ModalGroup";
import { SituationModal } from "../../common/SituationModal";
import { GiftToModal } from "../../common/GiftToModal";
import { withTranslation } from 'react-i18next';
import { Explanation } from "../../layout/Explanation";


export function Giftsite404(props) {
  useStyleSheet("https://unpkg.com/swiper/swiper-bundle.min.css",
    null,
    {
      integrity:
        "sha384-HUXJxzkMpCxEdXkgl8BSjN5SUcUODepsOLIYIWOtKva+Qo2teMmV1mh6ZVwnthaV",
      crossorigin: "anonymous",
      referrerpolicy: "no-referrer",
    }
  );
  useScript("https://unpkg.com/swiper/swiper-bundle.min.js", null, 
            { integrity: "sha384-XVTmRHMO2XUTzy8IerJVEy8ddg47Yz5pZrLc7nEWipaTowqT1xNb8qTLaw64Bd4S", 
            crossorigin: "anonymous" }
          );
  useScript("/assets/js/app.js");

  useEffect(() => {

  }, [])

  const { t } = props;

  return (
    <>
      <MetaTags>
        <title>「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content="「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content="「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta name="twitter:description" content="「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:image" content={window.location.origin + "/assets/img/common/ogp.png"}  />
        <meta name="twitter:site" content="@gochi_meshi" />
        <meta name="twitter:creator" content="@gochi_meshi" />
        <meta name="twitter:image:width" content="300" />
        <meta name="twitter:image:height" content="300" />
        <meta property="og:site_name" content="「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:title" content="「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={window.location.origin + "/assets/img/common/ogp.png"}  />
        <meta property="og:description" content="「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
      </MetaTags>
      <Header />

      <main>
        <div className="breadcrumb">
          <div className="container">
            <ol className="breadcrumb-list">
              <li>
                <a href="/">{t('pages.404.topPage')}</a>
              </li>
              <li>
                <span>{t('pages.404.title')}</span>
              </li>
            </ol>
          </div>
        </div>

        <section className="error-item">
          <div className="container">
            <div className="error-header">
              <h2 className="error-header-prepend">
                <span className="num">{t('pages.404.404')}</span>
              </h2>
              <div className="error-header-section">
                <p className="text">
                {t('pages.404.titleText1')}<br className="only-sp"/>{t('pages.404.titleText2')}
                </p>
              </div>
            </div>
            <div className="error-body">
              <img className="bg" src="/assets/img/error/bg-error.jpg" loading="lazy" alt="404" />
              <img className="bg-frame" src="/assets/img/error/ribon-pc.png" loading="lazy" alt="ribon-pc-404" />
              <img className="bg-frame sp" src="/assets/img/error/ribon-sp.png" loading="lazy" alt="ribon-sp-404" />
              <div className="error-body-box">
                <div className="error-body-box-body">
                  <p className="text-bold text-center">
                    {t('pages.404.content1')}<br className="only-pc"/>
                    {t('pages.404.content2')}<em className="text-main">{t('pages.404.content3')}</em>{t('pages.404.content4')}
                  </p>
                </div>
                <div className="error-body-box-footer">
                  <div className="error-body-box-footer-btn-group btn-group">
                    <a href="/" className="btn btn-rect">{t('pages.404.button')}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Explanation />
      </main>

      <Footer />

      <ModalGroup>
        <SituationModal />
        <GiftToModal />
      </ModalGroup>
    </>
  )
}

const mapStateToProps = (state) => ({ props: state.props });

export default connect(mapStateToProps)(withTranslation()(Giftsite404));
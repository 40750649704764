import {HTTP_API, makeRequest} from "../HttpClient";

const PUBLIC_CHANNEL_URL = process.env.REACT_APP_CHANNEL_PUBLIC_URL || '';

export default class PublicService {
    static async getPrefectures() {
        const url = PUBLIC_CHANNEL_URL + '/lexus/prefecture/list';
        return makeRequest(HTTP_API.GET, url);
    }

    static async getCities() {
        const url = PUBLIC_CHANNEL_URL + '/lexus/city/list';
        return makeRequest(HTTP_API.GET, url)
    }
}

export const saveShopInfo = (shopInfo) => {
  return {
    type : 'SHOP_INFO_SAVE',
    shopInfo
  }
};

export const saveFeeRateMenuPrice = (feeRate) => {
  return {
    type : 'SAVE_FEE_RATE_MENU_PRICE',
    feeRate
  }
};

export const updateMenuPriceInfo = (updatedMenuPriceInfo) => {
  return {
    type: 'UPDATE_MENU_PRICE_INFO',
    updatedMenuPriceInfo
  }
};

export const saveTaxRate = (taxRate) => {
  return {
    type : 'SAVE_TAX_RATE',
    taxRate
  }
};

export const saveInitTotalAmountMenuPrice = (totalAmount) => {
  return {
    type : 'SAVE_INIT_AMOUNT_MENU_PRICE',
    totalAmount
  }
};

export const saveInitMenuPrice = (initMenuPrice) => {
  return {
    type : 'SAVE_INIT_MENU_PRICE',
    initMenuPrice
  }
};

export const saveInitAmount = (initAmount) => {
  return {
    type : 'SAVE_INIT_VALUE_INPUT',
    initAmount
  }
};
import React from "react";

class LoadingOverlay extends React.Component {
  constructor(props) {
    super(props);
    };
  render() {
    return(
      <>
        {this.props.loading && (
          <div className="loading-overlay">
            <i className="icon-loading"></i>
          </div>
        )}
      </>
    );
  }
}
export default LoadingOverlay;
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle,
  Row,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { withTranslation } from "react-i18next";

const LIMIT = 5;
function LimitTotalTicketUseHistoryModal(props) {
  const [page, setPage] = useState(1);

  function onLoadMoreCoupon() {
    if (props.menuChildType === 5) {
      props.loadMoreHistoryUsedGochiCharge(props.gochiBillId);
    } else {
      setPage(page + 1);
    }
  }

  const listCoupons = props.listCoupons.filter(
    (item, index) => index < (props.menuChildType === 5 ? props.pageCouponsGochiCharge : page) * LIMIT
  );
  const hasMore = props.menuChildType === 5 ? (props.pageCouponsGochiCharge + 1) <= Math.ceil(props.countCouponsGochiCharge/props.limitCouponsGochiCharge) : (props.listCoupons.length > page * LIMIT);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="sm"
      centered
      dialogClassName="history-modal-container"
      scrollable
    >
      <ModalHeader closeButton className="history-modal-header"></ModalHeader>
      <ModalTitle className="text-center mb-1 fs-20 font-weight-bold">
        利用履歴
      </ModalTitle>
      
      {listCoupons.length > 0 ? (
      <>
        <ModalBody className="py-0 modal-history">
        {listCoupons.map((item) => (
          <div className="history-box" key={item.couponId}>
            <div className="p-3">
              <div className="history-line">
                <div className="title-lst-history">日付・日時</div>
                <div className="content-lst-history">{moment(item.useDate).format('YYYY年MM月DD日 H時mm分')}</div>
              </div>
              <div className="my-8px history-line">
                <div className="title-lst-history extra-small-title">利用店舗</div>
                <div className="content-lst-history extra-large-content">{item.shopName}</div>
              </div>
              <div className="history-line">
                <div className="title-lst-history">利用金額</div>
                <div className="content-lst-history">
                  <span>{item.displayAmount.toLocaleString()}</span>
                  <span>円</span>
                </div>
              </div>
            </div>
          </div>
        ))}
        </ModalBody>
        <ModalFooter className="history-modal-footer">
          <div className="history-load-more">
            {hasMore && (
              props.loadingLoadMore ? (<i className="icon-loader-new"></i>) :
              (<span className="cursor-pointer fs-15 history-load-more" onClick={onLoadMoreCoupon}>
                もっと見る
              </span>)
            )}
          </div>
        </ModalFooter>
      </>
      ) : (<div className="no-line-history">利用履歴がございません</div>)}
    </Modal>
  );
}

export default withTranslation()(LimitTotalTicketUseHistoryModal);

import {combineReducers} from 'redux'
import select_friend from './select_friend' 
import comment_invite from './comment_invite'
import error from './error'
import show_loading from './show_loading'
import userInfo from "./userInfo";
import guestInfo from "./guestInfo";
import menuInfo from "./menuInfo";
import shopInfo from "./shopInfo";
import groupInfo from "./groupInfo";
import paymentMethod from "./paymentMethod";
import messageShop from "./message_shop";
import registerInput from "./registerInput";
import messageCard from "./message_card";
import prefectures from './prefectures'
import cities from './cities'
import footer_banners from './footer_banners'
import show_not_logged_modal from './show_not_logged_modal'
import situations from './situations'
import modal_situation from './modal_situation'
import user_targets from './user_targets'
import modal_user_target from './modal_user_target'
import favorite_menu from './favorite_menu'
import wishlist_menu from './wishlist_menu'
import show_wish_list_modal from './show_wish_list_modal'
import favorite_shop from './favorite_shop'
import wishlist_shop from './wishlist_shop'
import GochiKid from './GochiKid'

export const rootreducer = combineReducers({
							gochiKid: GochiKid,
							friend:select_friend,
							messageCard: messageCard,
							comment:comment_invite, 
							error:error,
							loading:show_loading,
							userInfo: userInfo,
							menuInfo: menuInfo,
							shopInfo: shopInfo,
							groupInfo: groupInfo,
							paymentMethod: paymentMethod,
							guestInfo: guestInfo,
							messageShop: messageShop,
							registerInput: registerInput,
							prefectures: prefectures,
							cities: cities,
							footerBanners: footer_banners,
							showNotLoggedModal: show_not_logged_modal,
							showWishListModal: show_wish_list_modal,
							listSituations: situations,
							situation: modal_situation,
							listUserTargets: user_targets,
							userTarget: modal_user_target,
							favoriteMenu: favorite_menu,
							wishListMenu: wishlist_menu,
							favoriteShop: favorite_shop,
							wishListShop: wishlist_shop
						});

const stateDefault = {
};

export default (state = stateDefault, action) => { 
  switch (action.type){
    case 'GUEST_INFO_SAVE':
      return action.guestInfo;
    default:
      return state;
  }
};

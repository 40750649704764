import React from "react";

export default function ModalCloseButton({
  className,
  onClick
}) {
  return (
    <div className={className}>
      <button className="btn-close" onClick={onClick}>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.4 0.613295C16.88 0.0932946 16.04 0.0932946 15.52 0.613295L9.00002 7.11996L2.48002 0.599961C1.96002 0.0799609 1.12002 0.0799609 0.600022 0.599961C0.080022 1.11996 0.080022 1.95996 0.600022 2.47996L7.12002 8.99996L0.600022 15.52C0.080022 16.04 0.080022 16.88 0.600022 17.4C1.12002 17.92 1.96002 17.92 2.48002 17.4L9.00002 10.88L15.52 17.4C16.04 17.92 16.88 17.92 17.4 17.4C17.92 16.88 17.92 16.04 17.4 15.52L10.88 8.99996L17.4 2.47996C17.9067 1.97329 17.9067 1.11996 17.4 0.613295Z" fill="#ED808C"/>
        </svg>
      </button>
    </div>
  );
}

const stateDefault = {
  showing: false,
  title: '',
  content: '',
  buttonName: '',
  gotoUrl: ''
};

export default (state = stateDefault, action) => {
  switch (action.type){
    case 'ERROR_SHOW':
      return {
        ...state,
        showing: action.showing,
        title: action.title,
        content: action.content,
        buttonName: action.buttonName,
        gotoUrl: action.gotoUrl
      };
    default:
      return state;
  }
};

const stateDefault = {
  username: '',
  email: '',
  password: '',
  signUpResult: []
};

export default (state = stateDefault, action) => { 
  switch (action.type){
    case 'REGISTER_INPUT':
      return {
        ...state,
        username: action.username,
        email: action.email,
        password: action.password,
        signUpResult: action.signUpResult
      };
    default:
      return state;
  }
};

import React, { useState } from "react";
import moment from 'moment';
import DatePicker, { registerLocale } from "react-datepicker";
import loadImage from 'blueimp-load-image';
import GiftService from "../../../../api/services/GiftService";
import MenuService from "../../../../api/services/MenuService";
import { GOCHI_KID_FOLDER_IMAGE } from '../../../../constants';
import UploadImageInputGochiKid from "../../common/UploadImageInputGochiKid";
import { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";

registerLocale("ja", ja);

export function RegisterUserKidGochiKid(props) {
  const tmpUserKidObj = {
    "userKidId": null,
    "birthday": "",
    "displayName": "",
    "nickName": "",
    "prefectureCode": "",
    "prefectures": "",
    "city": "",
    "status":"REVIEW",
    "listUserKidImage": [],
    "birthdayNewFlag": 1,
  };
  const tmpPoorImageObj = {
    "image": ""
  }

  const statusPoor = {
    OK: "OK", // status approved, not editable
    NG: "NG", // status admin refused
    REVIEW: "REVIEW", // status post new regis
    INIT: "INIT", // status not join gochi kid
    RESUBMIT: "RESUBMIT" // status admin resubmit
  }

  const statusUserKid = {
    OK: "OK", // status approved, not editable
    NG: "NG", // status admin refused
    REVIEW: "REVIEW", // status post new regis
    RESUBMIT: "RESUBMIT" // status admin resubmit
  }

  const listPoorUserImage = [];
  const listPoorUserImagePreview = [];
  if (props.userKidData.listPoorUserImage && props.userKidData.listPoorUserImage.length > 0) {
    props.userKidData.listPoorUserImage.map(item => {
      listPoorUserImage.push(item.image);
      listPoorUserImagePreview.push(item.imagePresigned);
    })
  }

  const [uploading, setUploading] = useState(null);
  const [listUserKid, setListUserKid] = useState(props.userKidData?.userKids && props.userKidData?.userKids.length > 0 ? props.userKidData?.userKids : [tmpUserKidObj]);
  const [poorStatus, setPoorStatus] = useState(props.userKidData?.poorStatus || statusPoor.INIT);
  const [arrImage, setArrImage] = useState(() => {
    let newArr = [];
      props.userKidData.userKids.forEach(element => {
        newArr.push(element.listUserKidImage && element.listUserKidImage.length > 0 ? element.listUserKidImage[0] : '');
      });
      return newArr;
    }
  );
  const [arrImage1, setArrImage1] = useState(() => {
    let newArr = [];
      props.userKidData.userKids.forEach(element => {
        newArr.push(element.listUserKidImage && element.listUserKidImage.length > 0 && element.listUserKidImage[1] ? element.listUserKidImage[1] : '');
      });
      return newArr;
    }
  );
  const [previewImageArray, setPreviewImageArray] = useState(() => {
    let newArr = [];
      props.userKidData.userKids.forEach(element => {
        newArr.push(element.listUserKidImagePresigned && element.listUserKidImagePresigned.length > 0 ? element.listUserKidImagePresigned[0] : '');
      });
      return newArr;
    }
  );
  const [previewImageArray1, setPreviewImageArray1] = useState(() => {
    let newArr = [];
      props.userKidData.userKids.forEach(element => {
        newArr.push(element.listUserKidImagePresigned && element.listUserKidImagePresigned.length > 0 && element.listUserKidImagePresigned[1] ? element.listUserKidImagePresigned[1] : '');
      });
      return newArr;
    }
  );
  const [errorBag, setErrorBag] = useState([]);
  const [errorPoor, setErrorPoor] = useState([]);
  const [imagePoor, setImagePoor] = useState(props.userKidData?.listPoorUserImage && props.userKidData?.listPoorUserImage.length > 0 ? props.userKidData?.listPoorUserImage : [tmpPoorImageObj]);
  const [previewImage, setPreviewImage] = useState(listPoorUserImagePreview && listPoorUserImagePreview.length > 0 ? listPoorUserImagePreview : []);
  const [hasChangeImgPoor, setHasChangeImgPoor] = useState(false);
  const [policyUserKidRegister1, setPolicyUserKidRegister1] = useState(false);
  const [policyUserKidRegister2, setPolicyUserKidRegister2] = useState(false);
  const [policyUserKidRegister3, setPolicyUserKidRegister3] = useState(false);

  useEffect(() => {
    if (listUserKid.length > 0 && errorBag.length === 0) {
      let tempError = [];
      for (let i = 0; i < listUserKid.length; i++) {
        tempError.push({birthday: null, displayName: null, nickName: null, prefectures: null, city: null, listUserKidImage: null});
      }
      setErrorBag(tempError);
    }
    if (listUserKid && listUserKid.length > 0) {
      let tempImg = [];
      let tempImg1 = [];
      listUserKid.forEach((element, index) => {
          tempImg.push(arrImage[index] ? arrImage[index] : (element.listUserKidImage && element.listUserKidImage.length > 0 ? element.listUserKidImage[0] : ''));
          tempImg1.push(arrImage1[index] ? arrImage1[index] : (element.listUserKidImage && element.listUserKidImage.length > 0 && element.listUserKidImage[1] ? element.listUserKidImage[1] : ''));
      });
      setArrImage(tempImg);
      setArrImage1(tempImg1);
    }
  }, [listUserKid]);

  useEffect(() => {
    if (imagePoor.length > 0 || poorStatus !== statusPoor.OK) {
      let tempError = [];
      for (let i = 0; i < imagePoor.length; i++) {
        tempError.push({error: null});
      }
      setErrorPoor(tempError);
    }
  }, [poorStatus]);

  const updateImage = (file, index) => {
      const updateImage = [...imagePoor];
      updateImage[index].image = file;
      setImagePoor(updateImage);
  }

  const updateImagePreview = (file, index) => {
      const updateImagePreview = [...previewImage];
      updateImagePreview[index] = file;
      setPreviewImage(updateImagePreview);
  }

  const removeImagePoor = (index) => {
    const newi = [...imagePoor];
    if (newi[index].image) {
      setHasChangeImgPoor(true);
    }
    newi[index].image = '';
    setImagePoor(newi);
    const newPreview = [...previewImage];
    newPreview[index] = '';
    setPreviewImage(newPreview);
  }

  const updateArrImage = (file, index) => {
      const updateArrImages = [...arrImage];
      updateArrImages[index] = file;
      setArrImage(updateArrImages);
  }

  const updateArrImage1 = (file, index) => {
    const updateArrImages = [...arrImage1];
    updateArrImages[index] = file;
    setArrImage1(updateArrImages);
  }

  const updateArrImagePreview = (file, index) => {
      const updateArrImagePreviews = [...previewImageArray];
      updateArrImagePreviews[index] = file;
      setPreviewImageArray(updateArrImagePreviews);
  }

  const updateArrImagePreview1 = (file, index) => {
      const updateArrImagePreviews = [...previewImageArray1];
      updateArrImagePreviews[index] = file;
      setPreviewImageArray1(updateArrImagePreviews);
  }

  const removeImage = (index) => {
    const arrUserKid = [...listUserKid];
    //arrUserKid[index].listUserKidImageStr = "";
    arrUserKid[index].listUserKidImage[0] = '';
    setListUserKid(arrUserKid);
    const newArrPreview = [...previewImageArray];
    newArrPreview[index] = '';
    setPreviewImageArray(newArrPreview);
    const newArrImage = [...arrImage];
    newArrImage[index] = '';
    setArrImage(newArrImage);
  }

  const removeImage1 = (index) => {
    const arrUserKid = [...listUserKid];
    //arrUserKid[index].listUserKidImageStr = "";
    arrUserKid[index].listUserKidImage[1] = '';
    setListUserKid(arrUserKid);
    const newArrPreview = [...previewImageArray1];
    newArrPreview[index] = '';
    setPreviewImageArray1(newArrPreview);
    const newArrImage = [...arrImage1];
    newArrImage[index] = '';
    setArrImage1(newArrImage);
  }

  const toBlob = (base64, fileName, reject) => {
      const bin = atob(base64.replace(/^.*,/, ''));
      const buffer = new Uint8Array(bin.length);
      for (let i = 0; i < bin.length; i += 1) {
          buffer[i] = bin.charCodeAt(i);
      }

      try {
          const blob = new Blob([buffer.buffer], {
          type: 'image/*'
          });
          blob.name = fileName;
          return blob;
      } catch (e) {
          reject();
          return false;
      }
  };

  const resizeImage = image => {
    return new Promise((resolve, reject) => {
        loadImage.parseMetaData(image, data => {
        const options = {
            maxWidth: process.env.REACT_APP_RESIZE_MAX_WIDTH,
            canvas: true
        };
        if (data.exif) {
            options.orientation = data.exif.get('Orientation');
        }
        loadImage(
            image,
            canvas => {
            const imageUri = canvas.toDataURL('image/*');
            const resizeImageFile = toBlob(imageUri, image.name, reject);
            resolve({
                resizeImageFile
            });
            },
            options
        );
        });
    });
  };

  function handleChangeBirthday(value, index) {
    let tmp = [...listUserKid];
    for (let i = 0; i < tmp.length; i++) {
      if (i === parseInt(index)) {
        tmp[i].birthday = value ? `${value.getFullYear()}-${('0' + (value.getMonth() + 1)).slice(-2)}-01` : null;
        break;
      }
    }
    setListUserKid(tmp);
  }

  function handleChangeBirthdayNew(value, index) {
    let tmp = [...listUserKid];
    for (let i = 0; i < tmp.length; i++) {
      if (i === parseInt(index)) {
        tmp[i].birthday = value && value.trim() !== "" ? value : null;
        break;
      }
    }
    setListUserKid(tmp);
  }

  function handleChangeName(value, index) {
    let tmp = [...listUserKid];
    for (let i = 0; i < tmp.length; i++) {
      if (i === parseInt(index)) {
        tmp[i].displayName = value && value.trim() !== "" ? value : null;
        break;
      }
    }
    setListUserKid(tmp);
  }

  function handleChangeNickName(value, index) {
    let tmp = [...listUserKid];
    for (let i = 0; i < tmp.length; i++) {
      if (i === parseInt(index)) {
        tmp[i].nickName = value && value.trim() !== "" ? value : null;
        break;
      }
    }
    setListUserKid(tmp);
  }

  function handleChangePrefecture(e, indexUserKid) {
    let tmp = [...listUserKid];
    for (let i = 0; i < tmp.length; i++) {
      if (i === parseInt(indexUserKid)) {
        tmp[i].prefectureCode = e.target.value;
        let index = e.nativeEvent.target.selectedIndex;
        let label = e.nativeEvent.target[index].text;
        tmp[i].prefectures = label && label.trim() !== "" ? label : null;
        tmp[i].city = null;
        break;
      }
    }
    setListUserKid(tmp);
  }

  function handleChangeCity(e, indexUserKid) {
    let tmp = [...listUserKid];
    for (let i = 0; i < tmp.length; i++) {
      if (i === parseInt(indexUserKid)) {
        let index = e.nativeEvent.target.selectedIndex;
        let label = e.nativeEvent.target[index].text;
        tmp[i].city = label && label.trim() !== "" ? label : null;
        break;
      }
    }
    setListUserKid(tmp);
  }

  function handleRemoveUserKid(index) {
    if (listUserKid.length === 0) {
      alert("エラーが発生しました");
    } else {
      let tmp = [...listUserKid];
      tmp.splice(index, 1);
      setListUserKid(tmp);

      let error = [...errorBag];
      error.splice(index, 1);
      setErrorBag(error);

      const newArrPreview = [...previewImageArray];
      newArrPreview.splice(index, 1);
      setPreviewImageArray(newArrPreview);

      const newArrPreview1 = [...previewImageArray1];
      newArrPreview1.splice(index, 1);
      setPreviewImageArray1(newArrPreview1);

      const newArrImage = [...arrImage];
      newArrImage.splice(index, 1);
      setArrImage(newArrImage);

      const newArrImage1 = [...arrImage1];
      newArrImage1.splice(index, 1);
      setArrImage1(newArrImage1);
    }
  }

  function handleAddUserKid() {
    let tmp = [...listUserKid];
    let obj = JSON.parse(JSON.stringify(tmpUserKidObj));
    tmp.push(obj);
    setListUserKid(tmp);

    let error = [...errorBag];
    error.push({birthday: null, displayName: null, nickName: null, prefectures: null, city: null, listUserKidImage: null});
    setErrorBag(error);
  }

  function handleRemovePoorImage(index) {
    if (imagePoor.length === 0) {
      alert("エラーが発生しました");
    } else {
      let tmp = [...imagePoor];
      if (tmp[index].image) {
        setHasChangeImgPoor(true);
      }
      tmp.splice(index, 1);
      setImagePoor(tmp);

      const newPreview = [...previewImage];
      newPreview.splice(index, 1);
      setPreviewImage(newPreview);

      let error = [...errorPoor];
      error.splice(index, 1);
      setErrorPoor(error);
    }
  }

  function handleAddPoorImage() {
    let tmp = [...imagePoor];
    let obj = JSON.parse(JSON.stringify(tmpPoorImageObj));
    tmp.push(obj);
    setImagePoor(tmp);

    let error = [...errorPoor];
    error.push({error: null});
    setErrorPoor(error);
  }

  function onChangePoorStatus(value) {
    setPoorStatus(value);
  }

  async function validateData() {
    props.setLoading(true);
    const uploadImageResult = await uploadImages();
    if (uploadImageResult[0]) {
      uploadImageResult[1].map((item, i) => {
        listUserKid[i].listUserKidImage[0] = item;
      });
      uploadImageResult[2].map((item, i) => {
        imagePoor[i].image = item;
      });
      uploadImageResult[3].map((item, i) => {
        listUserKid[i].listUserKidImage[1] = item;
      });

      let error = [...errorBag];
      let errorPoorTemp = [...errorPoor];
      let isValid = true;

      if (poorStatus !== statusPoor.OK && poorStatus !== statusPoor.INIT && poorStatus !== statusPoor.NG) {
        if (imagePoor.length === 0) {
          errorPoorTemp[0].error = "生活保護受給証明書を登録してください。";
        } else {
          let hasImg = false;
          imagePoor.forEach((item, index) => {
            if (item.image) {
              hasImg = true;
            }
          });
          if (!hasImg) {
            errorPoorTemp[0].error = "生活保護受給証明書を登録してください。";
            isValid = false;
          }
          setErrorPoor(errorPoorTemp);
        }
      }

      if (listUserKid && listUserKid.length === 0) {
        alert('エラーが発生しました');
      } else if (listUserKid && listUserKid.length > 0) {
        listUserKid.forEach((item, index) => {
          if (!item.birthday) {
            error[index].birthday = "お子さまの生年月日を入力してください。";
            isValid = false;
          } else {
            error[index].birthday = null;
          }
          if (!item.displayName) {
            error[index].displayName = "お子さまの氏名を入力してください。";
            isValid = false;
          } else {
            error[index].displayName = null;
          }
          if (!item.nickName) {
            error[index].nickName = "お子さまのニックネームを入力してください。";
            isValid = false;
          } else {
            error[index].nickName = null;
          }
          if (!item.prefectureCode) {
            error[index].prefectures = "必須項目です。";
            isValid = false;
          } else {
            error[index].prefectures = null;
          }
          if (!item.city || item.city === "市区町村") {
            error[index].city = "必須項目です。";
            isValid = false;
          } else {
            error[index].city = null;
          }
          if (item.status !== statusUserKid.OK && item.status !== statusUserKid.NG) {
            if (item.listUserKidImage && item.listUserKidImage.length === 0 || (item.listUserKidImage && !item.listUserKidImage[0])) {
              error[index].listUserKidImage = "お子さま証明書を登録してください。";
              isValid = false;
            } else {
              error[index].listUserKidImage = null;
            }
          }
        });
        setErrorBag(error);
      }

      if (isValid) {
        await sumitRegisterUserKid();
      } else {
        document.getElementById("body-modal-register").scrollIntoView({behavior: 'smooth'}, true);
      }
    } else {
      alert('こどもごちめし対象者情報の登録に失敗しました。');
    };
    props.setLoading(false);
  }

  async function uploadImages() {
        let isSuccess = true;
        let arrImageP = [];
        let arrImageP1 = [];
        let imagePoorP = [];
        for (let index = 0; index < arrImage.length; index++) {
          if (typeof arrImage[index] === 'string' || arrImage[index] instanceof String) {
            arrImageP = [...arrImageP, arrImage[index]];
          } else {
            if (arrImage[index].size > Number(process.env.REACT_APP_RESIZE_BORDER)) {
              const { resizeImageFile } = await resizeImage(arrImage[index]);
              arrImage[index] = new File([resizeImageFile], arrImage[index].name, {type: arrImage[index].type});
            }
            await MenuService.postImage(arrImage[index], null, GOCHI_KID_FOLDER_IMAGE, props.userId)
            .then((response) => {
              arrImageP = [...arrImageP, response.data];
              return arrImageP;
            })
            .catch(e => {
              alert('画像のアップロードにエラーが発生しました。');
              isSuccess = false;
            });
          }
        }

        for (let index = 0; index < arrImage1.length; index++) {
          if (typeof arrImage1[index] === 'string' || arrImage1[index] instanceof String) {
            arrImageP1 = [...arrImageP1, arrImage1[index]];
          } else {
            if (arrImage1[index].size > Number(process.env.REACT_APP_RESIZE_BORDER)) {
              const { resizeImageFile } = await resizeImage(arrImage1[index]);
              arrImage1[index] = new File([resizeImageFile], arrImage1[index].name, {type: arrImage1[index].type});
            }
            await MenuService.postImage(arrImage1[index], null, GOCHI_KID_FOLDER_IMAGE, props.userId)
            .then((response) => {
              arrImageP1 = [...arrImageP1, response.data];
              return arrImageP1;
            })
            .catch(e => {
              alert('画像のアップロードにエラーが発生しました。');
              isSuccess = false;
            });
          }
        }

        for (let index = 0; index < imagePoor.length; index++) {
          if (imagePoor[index] && typeof imagePoor[index].image === 'string' || imagePoor[index] && imagePoor[index].image instanceof String) {
            imagePoorP = [...imagePoorP, imagePoor[index].image];
          } else if (imagePoor[index] && imagePoor[index].image) {
              if (imagePoor[index].image.size > Number(process.env.REACT_APP_RESIZE_BORDER)) {
                  const { resizeImageFile } = await resizeImage(imagePoor[index].image);
                  imagePoor[index].image = new File([resizeImageFile], imagePoor[index].image.name, {type: imagePoor[index].image.type});
              }
              await MenuService.postImage(imagePoor[index].image, null, GOCHI_KID_FOLDER_IMAGE, props.userId)
              .then((response) => {
                imagePoorP = [...imagePoorP, response.data];
                return imagePoorP;
              })
              .catch(e => {
                alert('画像のアップロードにエラーが発生しました。');
                isSuccess = false;
              });
          } else {
            imagePoorP = [...imagePoorP, ''];
          }
        }

        return [isSuccess, arrImageP, imagePoorP, arrImageP1];
    }

    async function sumitRegisterUserKid() {
      const submission = {
        poorStatus: poorStatus && poorStatus !== statusPoor.INIT ? (poorStatus === statusPoor.OK ? statusPoor.OK : (hasChangeImgPoor ? statusPoor.REVIEW : props.userKidData?.poorStatus)) : statusPoor.INIT,
        userKids: [],
        listPoorUserImage: []
      };
      
      let listUK = [];
      listUserKid.forEach(item => {
        let tempItem = {...item}
        let tempBirthday = tempItem.birthday && tempItem.birthday.split('-')[2] ? tempItem.birthday : `${tempItem.birthday}-01`;
        tempItem.birthday = tempBirthday;
        tempItem.displayName = tempItem.displayName.trim();
        tempItem.nickName = tempItem.nickName.trim();
        if (tempItem.status === statusUserKid.OK) {
          tempItem.listUserKidImage = [];
        } else if (tempItem.status === statusUserKid.NG && tempItem.listUserKidImage && tempItem.listUserKidImage[0] === "" && tempItem.listUserKidImage[1] === "") {
          tempItem.listUserKidImage = [];
        }
        listUK.push(tempItem);
      });
      submission.userKids = listUK;

      let newArr = []
      imagePoor.forEach(item => {
        if (item.image) {
          newArr.push(item);
        }
      });

      submission.listPoorUserImage = newArr;

      try {
          setUploading(true)
          const res = await GiftService.postRegisterUserKid(submission);
          if (res.status === 200) {
              props.setNumberUserKidInit(props.userKidData?.userKids.length);
              props.getUserKidData(props.userId);
              props.handleShowPopupRegisterUserKidGochiKidSuccess(false, '');
          } else {
              if (res.data.code === "exception.errors.conflict.exception") {
                props.getUserKidData(props.userId);
                props.handleShowPopupRegisterUserKidGochiKidSuccess(true, 'こどもごちめし対象者の審査がすでに完了したため、編集できません。');
              }
              else {
                props.handleShowPopupRegisterUserKidGochiKidSuccess(true, 'こどもごちめし対象者情報の登録に失敗しました。');
              }
          }
      } catch (e) {
          console.error('Submit error', e);
          // alert('こどもごちめし対象者情報の登録に失敗しました。');
          props.handleShowPopupRegisterUserKidGochiKidSuccess(true, 'こどもごちめし対象者情報の登録に失敗しました。');
      } finally {
          setUploading(false)
      }
    }

  return (
    <div
      className={`modal modal-profile modal-profile-02 modal-02 modal-lg register-user-kid-modal ${props.showPopupRegisterUserKidGochiKid ? 'is-show' : ''}`}
      tabIndex={-1}
    >
      <div className="header-register-user-kid">
        <button
          className="modal-btn modal-btn-close"
          id="modalClose"
          onClick={props.handleClosePopupRegisterUserKidGochiKid}
          type="button"
        >
          <svg className="icon">
            <title>閉じる</title>
            <use xlinkHref="/assets/img/common/icon.svg#times" />
          </svg>
        </button>
      </div>
      <div className="title-modal-register">
        <div className="title">{props.userKidData.userKids?.length === 0 ? "利用者登録" : "利用者情報の編集"}</div>
      </div>
      <div className="modal-body body-modal-register" id="body-modal-register">
        <div className="title-warning">
          <div className="dot-red"></div>
          必須入力ですので、必ずご入力お願い致します
        </div>
        <div className="register-poor">
          <div className="register-title mt-16px">
            <div className="dot-red"></div>
            生活保護受給の有無
            {(props.userKidData.poorStatus === statusPoor.NG || props.userKidData.poorStatus === statusPoor.OK || props.userKidData.poorStatus === statusPoor.REVIEW || props.userKidData.poorStatus === statusPoor.RESUBMIT) && (
              <>
                ：<span className="color-EC4A56">{props.userKidData.poorStatus === statusPoor.RESUBMIT ? "再提出依頼中" : `審査${props.userKidData.poorStatus === statusPoor.REVIEW ? '中' : props.userKidData.poorStatus}`}</span>
              </>
            )}
          </div>
          <div className="radio-poor-body">
            {props.userKidData.reasonComment && (
              <div className="reason-comment-box">
                <div><i className="icon-reason-comment"/></div>
                <div className="reason-comment-text">{props.userKidData.reasonComment}</div>
              </div>
            )}
            <div className="field field-radio radio-box">
              <label className="radio-poor">
                <input
                  className="before-icon"
                  name="poorStatus"
                  type="radio"
                  checked={poorStatus === statusPoor.INIT ? true : false}
                  value={statusPoor.INIT}
                  onChange={(e) => onChangePoorStatus(e.target.value)}
                  disabled={props.userKidData.poorStatus === statusPoor.OK}
                />
                <i className="icon" />
                受給していない
              </label>
              <label className="radio-poor">
                <input
                  className="before-icon"
                  name="poorStatus"
                  type="radio"
                  checked={poorStatus !== statusPoor.INIT ? true : false}
                  value={statusPoor.REVIEW}
                  onChange={(e) => onChangePoorStatus(e.target.value)}
                  disabled={props.userKidData.poorStatus === statusPoor.OK}
                />
                <i className="icon" />
                受給中
              </label>
            </div>
            <div className="poor-note">
              <i className="icon-poor-note"/>
              ご使用いただく飲食店の方には情報は開示されません
            </div>
            {(poorStatus !== statusPoor.INIT && poorStatus !== statusPoor.OK) ? (
              <div className="poor-image">
                <div className="register-title mt-24px">
                  <div className="dot-red"></div>
                  生活保護受給証明書の登録
                </div>
                {imagePoor &&
                imagePoor.length > 0 &&
                imagePoor.map((item, index) => {
                  const tempImage = previewImage && previewImage.length > 0 ? previewImage[index] : item.imagePresigned;
                  return (
                  <div key={index} className="mb-3">
                    <UploadImageInputGochiKid
                      label={"imagePoor" + index}
                      setFile={updateImage}
                      previewImage={tempImage}
                      setPreviewImage={updateImagePreview}
                      isOnlyImage
                      indexImg={index}
                      removeImage={removeImagePoor}
                      disabled={props.userKidData.poorStatus === statusPoor.OK}
                      isNotShowAddBtn={props.userKidData.poorStatus === statusPoor.OK || tempImage}
                      isNotShowDeleteBtn={props.userKidData.poorStatus === statusPoor.OK || !tempImage}
                      setHasChangeImgPoor={setHasChangeImgPoor}
                      checkChange
                    />
                    {index > 0 && poorStatus !== statusPoor.OK && (
                      <button
                        className="field-delete-btn"
                        type="button"
                        onClick={() => {
                            handleRemovePoorImage(index);
                        }}
                      >
                      <svg className="icon">
                          <title>削除</title>
                          <use xlinkHref="/assets/img/common/icon.svg#times-sm" />
                      </svg>
                      </button>
                    )}
                    <div className="validate-text validate-image">{errorPoor[index]?.error}</div>
                  </div>
                  )}
                )}
                {(imagePoor && imagePoor.length < 2) && poorStatus !== statusPoor.OK && (
                  <button
                      className="field-add-btn add-user-kid mt-24px mb-3"
                      type="button"
                      onClick={() => {
                      handleAddPoorImage();
                      }}
                  >
                    <span className="icon-area">
                    <svg className="icon">
                        <use xlinkHref="/assets/img/common/icon.svg#plus-sm" />
                    </svg>
                    </span>
                    <span className="text">書類を追加する</span>
                  </button>
                )}
              </div>
            ) : ('')}
          </div>
        </div>
        <div className="register-title mt-8px">
          <div className="dot-red"></div>
          お子さま情報の登録
        </div>
        <div className="register-user-kid">
          {listUserKid &&
          listUserKid.length > 0 &&
          listUserKid.map((item, index) => {
            const tempImage = previewImageArray && previewImageArray.length > 0 ? previewImageArray[index] : (item.listUserKidImagePresigned && item.listUserKidImagePresigned.length ? item.listUserKidImagePresigned[0] : '');
            const tempImage1 = previewImageArray1 && previewImageArray1.length > 0 ? previewImageArray1[index] : (item.listUserKidImagePresigned && item.listUserKidImagePresigned.length && item.listUserKidImagePresigned[1] ? item.listUserKidImagePresigned[1] : '');
            const filteredCities = props.listCities.filter(
              (city) => city.prefCode === String(Number(item.prefectureCode))
            );
            const cityChoose = props.listCities.filter(
              (city) => city.prefCode === String(Number(item.prefectureCode)) && city.cityName === item.city
            );
            const cityId = cityChoose.length > 0 ? cityChoose[0].cityId : '';
            return (
              <div key={index}>
                <div className="box-input-regis-user-kid">
                  <div className="header-box-input">
                    <span>
                      ※<span className="color-35A99E">{index + 1}</span>人目の登録
                      {(item.status === statusUserKid.NG || item.status === statusUserKid.OK || (item.status === statusUserKid.REVIEW && item.userKidId) || item.status === statusUserKid.RESUBMIT) && (
                        <>
                          ：<span className="color-EC4A56">{item.status === statusUserKid.RESUBMIT ? "再提出依頼中" : `審査${item.status === statusUserKid.REVIEW ? '中' : item.status}`}</span>
                        </>
                      )}
                    </span>
                    {index > 0 && item.status !== statusUserKid.OK && (
                      <button
                        className="field-delete-btn delete-user-kid"
                        type="button"
                        onClick={() => {
                          handleRemoveUserKid(index);
                        }}
                      >
                      <svg className="icon">
                        <title>削除</title>
                        <use xlinkHref="/assets/img/common/icon.svg#times-sm" />
                      </svg>
                      </button>
                    )}
                  </div>
                  <div className="body-box-input">
                    {item.reasonComment && (
                      <div className="reason-comment-box">
                        <div><i className="icon-reason-comment"/></div>
                        <div className="reason-comment-text">{item.reasonComment}</div>
                      </div>
                    )}
                    <div>
                      <p className="title-input mt-16px">{item.status !== statusUserKid.OK ? '氏名' : 'ニックネーム'}<span className="required-text">*</span></p>
                      <input
                        type="text"
                        id={'displayName' + index}
                        placeholder="お子さまの氏名をご入力ください"
                        maxLength={255}
                        value={item.displayName}
                        onChange={(e) =>
                          handleChangeName(
                            e.target.value,
                            index
                        )}
                        className="body-input"
                      />
                    </div>
                    <div className="validate-text">{errorBag[index]?.displayName}</div>
                    {item.status !== statusUserKid.OK && (
                      <>
                        <div>
                          <p className="title-input mt-16px">ニックネーム<span className="required-text">*</span></p>
                          <input
                            type="text"
                            id={'nickName' + index}
                            placeholder="お子さまのニックネームをご入力ください"
                            maxLength={255}
                            value={item.nickName}
                            onChange={(e) =>
                              handleChangeNickName(
                                e.target.value,
                                index
                            )}
                            disabled={item.status === statusUserKid.OK}
                            className="body-input"
                          />
                        </div>
                        <div className="validate-text">{errorBag[index]?.nickName}</div>
                      </>
                    )}
                    {item.birthdayNewFlag === 0 ? (
                      <div>
                        <p className="title-input mt-24px">生年月<span className="required-text">*</span></p>
                        {/*<input type="month" 
                          className="form-control body-input" 
                          id={'birthday' + index}
                          name="birthday"
                          onChange={(e) =>
                            handleChangeBirthday(
                              e.target.value,
                              index
                          )}
                          value={birthday}
                          max={moment().format("YYYY-MM")}
                          placeholder="yyyy/mm"
                          disabled={item.status === statusUserKid.OK}
                        />*/}
                        <DatePicker
                          className="form-control body-input"
                          id={'birthday' + index}
                          name="birthday"
                          showMonthYearPicker
                          dateFormat="yyyy/MM"
                          selected={item.birthday ? new Date(item.birthday) : null}
                          disabled={item.status === statusUserKid.OK}
                          maxDate={new Date()}
                          placeholderText="yyyy/mm"
                          wrapperClassName="user-kid-date-picker"
                          onChange={(value) =>
                            handleChangeBirthday(
                              value,
                              index
                          )}
                          locale='ja'
                        />
                      </div>
                    ) : (
                      <div>
                        <p className="title-input mt-24px">生年月日<span className="required-text">*</span></p>
                         <input
                          type="date" 
                          className="form-control body-input" 
                          id={'birthday' + index}
                          name="birthday"
                          onChange={(e) =>
                            handleChangeBirthdayNew(
                              e.target.value,
                              index
                          )}
                          value={item.birthday}
                          max={moment().format("YYYY-MM-DD")}
                          placeholder="yyyy/mm/dd"
                          disabled={item.status === statusUserKid.OK}
                        />
                        {/*<DatePicker
                          className="form-control body-input"
                          id={'birthday' + index}
                          name="birthday"
                          dateFormat="yyyy/MM/dd"
                          selected={item.birthday ? new Date(item.birthday) : null}
                          disabled={item.status === statusUserKid.OK}
                          maxDate={new Date()}
                          placeholderText="yyyy/mm/dd"
                          wrapperClassName="user-kid-date-picker"
                          onChange={(value) =>
                            handleChangeBirthdayNew(
                              value,
                              index
                          )}
                          locale='ja'
                        />*/}
                      </div>
                    )}
                    <div className="validate-text">{errorBag[index]?.birthday}</div>
                    <div>
                      <p className="title-input mt-24px">都道府県<span className="required-text">*</span></p>
                      <select
                        onChange={(e) => 
                          handleChangePrefecture(e, index)
                        }
                        disabled={item.status === statusUserKid.OK}
                        className="form-control body-input" 
                        id={'prefectures' + index}
                        value={+item.prefectureCode}
                      >
                        <option value="">都道府県</option>
                        {props.listPrefectures &&
                          props.listPrefectures.length > 0 &&
                          props.listPrefectures.map((item, key) => {
                            let index = `prefecture-${item.prefectureId}-${key}`;
                            return (
                              <option
                                value={item.prefectureId}
                                key={index}
                              >
                                {item.prefectureName}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="validate-text">{errorBag[index]?.prefectures}</div>
                    <div>
                      <p className="title-input mt-24px">市区町村<span className="required-text">*</span></p>
                      <select
                        onChange={(e) => {
                          handleChangeCity(e, index)
                        }}
                        disabled={item.status === statusUserKid.OK}
                        className="form-control body-input" 
                        id={'city' + index}
                        value={cityId}
                      >
                        <option value="">市区町村</option>
                        {filteredCities &&
                          filteredCities.length > 0 &&
                          filteredCities.map((item, key) => {
                            let index = `city-${key}`;
                            return (
                              <option value={item.cityId} key={index}>
                                {item.cityName}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="validate-text">{errorBag[index]?.city}</div>
                    {item.status !== statusUserKid.OK && (
                      <>
                        <div className="input-image-user-kid">
                          <p className="title-input mt-24px">お子さま証明書の登録<span className="required-text">*</span></p>
                          <div className="warning-image-user-kid">
                            <div>
                              <i className="icon-warning-image-user-kid"/>
                            </div>
                            お子さまの年齢、住所を確認できる証明書をご登録ください。個人番号カード（マイナンバーカード）【表面】を推奨します。※裏面には個人番号が記載されているため、表面のみアップロードしてください。
                          </div>
                          <UploadImageInputGochiKid
                            label={"image" + index + "0"}
                            setFile={updateArrImage}
                            previewImage={tempImage}
                            setPreviewImage={updateArrImagePreview}
                            indexImg={index}
                            isOnlyImage
                            removeImage={removeImage}
                            disabled={item.status === statusUserKid.OK}
                            isNotShowAddBtn={item.status === statusUserKid.OK || tempImage}
                            isNotShowDeleteBtn={item.status === statusUserKid.OK || !tempImage}
                            isUserKid
                          />
                          <div className="validate-text validate-image">{errorBag[index]?.listUserKidImage}</div>
                        </div>
                        <div className="input-image-user-kid">
                          <p className="title-input mt-24px">現住所（市区町村）が分かる証明書（1枚目に記載の場合は不要）</p>
                          <div className="warning-image-user-kid">
                            <div>
                              <i className="icon-warning-image-user-kid"/>
                            </div>
                            健康保険証の表面等、住所の記載がない場合は2枚目に住所が分かる証明書（健康保険証の場合は裏面）をご登録ください。
                          </div>
                          <UploadImageInputGochiKid
                            label={"image" + index + "1"}
                            setFile={updateArrImage1}
                            previewImage={tempImage1}
                            setPreviewImage={updateArrImagePreview1}
                            indexImg={index}
                            isOnlyImage
                            removeImage={removeImage1}
                            disabled={item.status === statusUserKid.OK}
                            isNotShowAddBtn={item.status === statusUserKid.OK || tempImage1}
                            isNotShowDeleteBtn={item.status === statusUserKid.OK || !tempImage1}
                            isUserKid
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          {(listUserKid && listUserKid.length < 10) && (
          <button
            className="field-add-btn add-user-kid"
            type="button"
            onClick={() => {
              handleAddUserKid();
            }}
          >
            <span className="icon-area">
              <svg className="icon">
                  <use xlinkHref="/assets/img/common/icon.svg#plus-sm" />
              </svg>
            </span>
            <span className="text">登録するお子さまを追加</span>
          </button>
          )}
        </div>
        <div className="policy-note">
          こどもごちめしサービスは、個人や企業からの寄付によって運営しています。下記事項をご確認いただき、同意していただける場合はチェックをしてください。
        </div>
        <div className="field field-checkbox policy-user-kid-register">
          <label className="policy-icon">
            <input
              className="before-icon"
              type="checkbox"
              checked={policyUserKidRegister1}
              onChange={(e) => {
                setPolicyUserKidRegister1(e.target.checked);
              }}
            />
            <i className="icon" />
          </label>
          <div className="policy-text-description">
            <div className="text">
              お申し込みいただくお子様が「こどもごちめし」に登録されていない事をご確認ください。※自治体や商店街のキャンペーン等で「こどもごちめし」に登録されている場合、同じアカウントで「全国版こどもごちめし」もご利用いただけます。
            </div>
          </div>
        </div>
        <div className="field field-checkbox policy-user-kid-register">
          <label className="policy-icon">
            <input
              className="before-icon"
              type="checkbox"
              checked={policyUserKidRegister2}
              onChange={(e) => {
                setPolicyUserKidRegister2(e.target.checked);
              }}
            />
            <i className="icon" />
          </label>
          <div className="policy-text-description">
            <div className="text">
              同じお子様を重複して登録・既定回数を超えて利用した事が発覚した場合、アカウントの停止や利用金額の返還請求などの措置を取らせていただきますのでご了承ください。
            </div>
          </div>
        </div>
        <div className="field field-checkbox policy-user-kid-register">
          <label className="policy-icon">
            <input
              className="before-icon"
              type="checkbox"
              checked={policyUserKidRegister3}
              onChange={(e) => {
                setPolicyUserKidRegister3(e.target.checked);
              }}
            />
            <i className="icon" />
          </label>
          <div className="policy-text-description">
            <div className="text">
              「こどもごちめし」個人情報取り扱いについて下記URLよりご確認ください。
            </div>
            <a href="https://www.gigi.tokyo/handle/" target="_blank">https://www.gigi.tokyo/handle/</a>
          </div>
        </div>
        <div className="policy-note">
          ※生活保護の受給を証明する書類について、ご不明な点は、お住まいの自治体さまにお問い合わせください。
          <br />
          ※ご提出いただいた書類については、弊社のプライバシーポリシーに基づき適切に管理し、情報を確認した後に廃棄します。
        </div>
        <div className="policy-note">
          ご質問やご不明点がございましたら、弊社<span><a href="https://kids-future-passport.org/contact/user" target="_blank">お問い合わせ窓口</a></span>までお問い合わせください。
        </div>
      </div>
      <div className="modal-footer">
        <div>
          <button
            onClick={() => validateData()}
            id="submitConfirm"
            className='btn btn-rect btn-confirm'
            disabled={!(policyUserKidRegister1 && policyUserKidRegister2 && policyUserKidRegister3)}
          >
            更新する
          </button>
        </div>
        <div>
          <button
            onClick={props.handleClosePopupRegisterUserKidGochiKid}
            id="submitConfirm"
            className={'btn btn-outline-rect btn-outline-cancel'}
          >
            戻る
          </button>
        </div>
      </div>
    </div>
  );
}

import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import queryString from "query-string";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faMapMarkerAlt, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Modal } from "react-bootstrap";
// import { Card, Button, Media, ListGroup } from 'react-bootstrap';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import { showLoading } from "../../../actions/action_loading";
import { showError } from "../../../actions/action_error_alert";
import { withTranslation } from "react-i18next";
import ShopService from "../../../api/services/ShopService";
import MenuService from "../../../api/services/MenuService";
import Header from "../../layout/header/Header";
// import GochiUseInfor from "../../common/GochiUse/GochiUseInfor";
import GochiUseStaff from "../../common/GochiUse/GochiUseStaff";
import ListShop from "../../common/ListShop";
import * as constants from "../../../constants";
import CouponService from "../../../api/services/CouponService";
import Coupons from "../Menu/Coupons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import LimitTotalTicketUseHistoryModal from "../../common/LimitTotalTicketUseHistoryModal";
import ChargeHistoryModal from "../../common/ChargeHistoryModal";
import moment from "moment";
import GiftService from "../../../api/services/GiftService";

class GochiBillDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textButtonSort: "現在地からの距離",
      gochiDetail: {},
      listShop: [],
      shopCount: 0,
      hasMore: false,
      page: 1,
      menuId: 0,
      sort: "distance",
      showGochiUse: false,
      gochiInfo: {},
      latitude: 0,
      longitude: 0,
      childFoods: null,
      showGochiUseStaff: false,
      quantity: 1,
      includedTaxPrice: 0,
      showListShopPopup: false,
      messageText: null,
      messageTextError: null,
      disabledBtnSendMessage: true,
      disabledBtnUseTicket: true,
      showConfirmMessagePopup: false,
      sentMessage: null,
      sentMessageToGiver: false,
      openHistoryModal: false,
      openHistoryChargeModal: false,
      userInfo: queryString.parse(window.location.search).accessToken ? {isGuest: false, isLogined: true, userId: JSON.parse(atob(queryString.parse(window.location.search).accessToken.split(".")[1])).uid} : (this.props.userInfo ? this.props.userInfo : JSON.parse(localStorage.getItem('userInfo'))),
      isLoggedIn: this.props.userInfo?.userId ? true : false,
      accessToken: queryString.parse(window.location.search).accessToken,
      isError: false,
      listHistoryCharge: [],
      pageHistoryCharge: 1,
      countHistoryCharge: 0,
      limitCharge: 5,
      listHistoryUsedGochiCharge: [],
      pageHistoryUsedGochiCharge: 1,
      countHistoryUsedGochiCharge: 0,
      limitHistoryUsedGochiCharge: 5,
      loadingLoadMore: false,
      gochiErrorNew: false,
      gochiErrorTitleNew: "",
      gochiErrorContentNew: ""
    };
    this.getMenu = this.getMenu.bind(this);
    this.loadMoreShop = this.loadMoreShop.bind(this);
    this.getListShops = this.getListShops.bind(this);
    this.onCopy = this.onCopy.bind(this);
    this.onFavouriteShop = this.onFavouriteShop.bind(this);
    this.onOpenGochiUse = this.onOpenGochiUse.bind(this);
    this.onCloseGochiInfo = this.onCloseGochiInfo.bind(this);
    this.onCloseGochiStaff = this.onCloseGochiStaff.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOpenConfirmPopup = this.handleOpenConfirmPopup.bind(this);
    this.handleSendMessage = this.handleSendMessage.bind(this);
    this.onCloseConfirmMessagePopup =
    this.onCloseConfirmMessagePopup.bind(this);
    this.handleShowListShops = this.handleShowListShops.bind(this);
    this.handleCloseListShopPopup = this.handleCloseListShopPopup.bind(this);
    this.openMap = this.openMap.bind(this);
    this.hideGochiCountError = this.hideGochiCountError.bind(this);
    this.setOpenHistoryModal = this.setOpenHistoryModal.bind(this);
    this.setOpenHistoryChargeModal = this.setOpenHistoryChargeModal.bind(this);
    this.checkAccessScreen = this.checkAccessScreen.bind(this);
    this.getDataDidMount = this.getDataDidMount.bind(this);
    this.getHistoryChargeData = this.getHistoryChargeData.bind(this);
    this.getHistoryCharge = this.getHistoryCharge.bind(this);
    this.loadMoreHistoryCharge = this.loadMoreHistoryCharge.bind(this);
    this.getHistoryUsedGochiChargeData = this.getHistoryUsedGochiChargeData.bind(this);
    this.getHistoryUsedGochiCharge = this.getHistoryUsedGochiCharge.bind(this);
    this.loadMoreHistoryUsedGochiCharge = this.loadMoreHistoryUsedGochiCharge.bind(this);
    this.closeErrorNewPopup = this.closeErrorNewPopup.bind(this);
  }

  handleShowListShops() {
    this.setState({
      showListShopPopup: true,
    });
  }

  handleCloseListShopPopup() {
    this.setState({
      showListShopPopup: false,
    });
  }

  handleChange = (e) => {
    let mess = e.target.value;
    console.log("message to buyer ===", mess);
    if (mess.trim() !== "") {
      this.setState({
        disabledBtnSendMessage: false,
      });
    } else {
      this.setState({
        disabledBtnSendMessage: true,
      });
    }
    this.setState({
      messageText: mess,
    });
  };

  handleOpenConfirmPopup() {
    const { t } = this.props;

    if (
      this.state.messageText.length < 1 ||
      this.state.messageText.length > 200
    ) {
      this.setState({
        showConfirmMessagePopup: false,
        messageTextError: t(
          "pages.invite.Please enter a message within 200 characters"
        ),
      });
    } else {
      this.setState({
        showConfirmMessagePopup: true,
        messageTextError: null,
      });
    }
  }

  async handleSendMessage() {
    let gochiBillId = this.state.gochiDetail.gochiBillId;
    let couponId = null;
    this.props.showLoading(true);
    try {
      const { data } = await CouponService.sendReviewMessageToReceivedUser(
        this.state.messageText,
        couponId,
        gochiBillId
      );
      console.log("data ===", data);
      this.props.showLoading(false);
      this.setState({
        showConfirmMessagePopup: false,
        sentMessageToGiver: true,
        sentMessage: this.state.messageText,
      });
    } catch (error) {
      console.log(error);
      this.props.showLoading(false);
      this.props.showError(true, this.props.t("There is something wrong"), "");
    }
  }

  onCloseConfirmMessagePopup() {
    this.setState({
      showConfirmMessagePopup: false,
    });
  }

  getMenu = async (menuId, gochiUserId, gochiBillId, accessToken) => {
    this.props.showLoading(true);
    const checkTokenExpired = accessToken ? 1 : null;
    try {
      const { data, status } = await MenuService.getMenuLimitTotal(
        menuId,
        gochiUserId,
        gochiBillId,
        accessToken,
        checkTokenExpired
      );
      this.props.showLoading(false);
      if (status !== 200) {
        this.props.showError(true, this.props.t("other.Unexpected error"), "");
        this.setState({
          isError: true
        });
      }
      return data;
    } catch (error) {
      console.log(error);
      this.setState({
        isError: true
      });
      this.props.showLoading(false);
      this.props.showError(true, this.props.t("other.Unexpected error"), "");
    }
  };

  getShopDetail = async (shopId) => {
    this.props.showLoading(true);
    try {
      const { data } = await ShopService.getDetailShop(shopId);
      this.props.showLoading(false);
      return data;
    } catch (error) {
      console.log(error);
      this.props.showLoading(false);
      this.props.showError(
        true,
        this.props.t("pages.login.There is something wrong"),
        ""
      );
    }
  };

  getGroupDetail = async (groupId) => {
    this.props.showLoading(true);
    try {
      const { data } = await ShopService.getDetailGroup(groupId);
      this.props.showLoading(false);
      return data;
    } catch (error) {
      console.log(error);
      this.props.showLoading(false);
      this.props.showError(
        true,
        this.props.t("pages.login.There is something wrong"),
        ""
      );
    }
  };

  getCoords() {
    var options = {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    };

    this.props.showLoading(true);

    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(
        (success) => {
          this.props.showLoading(false);
          resolve(success);
        },
        (error) => {
          this.props.showLoading(false);
          if (error.code === 1) {
            resolve(null);
          } else {
            reject(error);
            this.setState({
              isError: true
            });
          }
        },
        options
      )
    );
  }

  getListShops = async (
    menuId,
    loginUserId,
    sort,
    page,
    latitude,
    longitude
  ) => {
    this.props.showLoading(true);
    try {
      const accessToken = queryString.parse(window.location.search).accessToken;
      const checkTokenExpired = accessToken ? 1 : null;
      const { data } = await ShopService.getListlShopsCmm(
        menuId,
        loginUserId,
        sort,
        page,
        latitude,
        longitude,
        null,
        accessToken,
        checkTokenExpired
      );
      const listShop = this.state.listShop;
      data.list &&
        data.list.map((shop) => {
          return listShop.push(shop);
        });

      this.setState({
        listShop: listShop,
        childFoods: data.childFoods,
        shopCount: data.count,
      });
      if (listShop.length < data.count) {
        this.setState({
          hasMore: true,
        });
      } else {
        this.setState({
          hasMore: false,
        });
      }
      this.props.showLoading(false);
      // return listShop;
    } catch (error) {
      console.log("error getListShops", error);
      this.props.showLoading(false);
      this.props.showError(
        true,
        this.props.t("pages.login.There is something wrong"),
        ""
      );
      this.setState({
        isError: true
      });
    }
  };

  async getDataDidMount(userInfo, menuId, gochiUserId, gochiBillId, shopIdParam, shopName){
      if (userInfo) {
        if (userInfo.isLogined && !userInfo.isGuest) {
          const accessToken = queryString.parse(window.location.search).accessToken;
          const dataGochi = await this.getMenu(menuId, gochiUserId, gochiBillId, accessToken);
          const shopDetail = dataGochi.shop;
          const groupDetail = dataGochi.group;
          const shopId = shopDetail ? shopDetail.shopId : null;
          const groupId = groupDetail ? groupDetail.groupId : null;

          const loginUserId = userInfo.userId;

          const isMultiUsedMenu =
            dataGochi.menu &&
            (dataGochi.menu.menuChildType === 3 ||
              dataGochi.menu.menuChildType === 4 ||
              dataGochi.menu.menuChildType === 5);
          if (groupId) {
            this.props.showLoading(true);
            let latitude = null;
            let longitude = null;

            try {
              const position = await this.getCoords();
              console.log("ggg=====", position);

              if (position && position.coords) {
                latitude = position.coords.latitude;
                longitude = position.coords.longitude;
              }
              this.setState({
                loading: false,
              });
            } catch (e) {
              this.setState({
                loading: false,
              });
              console.log("======================", e);
            }

            this.setState({
              latitude: latitude,
              longitude: longitude,
            });
            this.props.showLoading(false);
            await this.getListShops(
              dataGochi.menuId,
              loginUserId,
              this.state.sort,
              this.state.page,
              latitude,
              longitude
            );
          }

          this.setState({
            gochiDetail: dataGochi,
            isMultiUsedMenu: isMultiUsedMenu,
            shopId: shopId,
            shopDetail: shopDetail,
            groupId: groupId,
            groupDetail: groupDetail,
            isFromQr: gochiBillId ? false : true,
            shopIdParam: shopIdParam,
            menuId: menuId,
            shopName: shopName,
            sentMessageToGiver:
              dataGochi.couponMessageDto && dataGochi.couponMessageDto.message
                ? true
                : false,
            sentMessage:
              dataGochi.couponMessageDto && dataGochi.couponMessageDto.message
                ? dataGochi.couponMessageDto.message
                : "",
          });
          if (!this.state.isError) {
            this.setState({
              disabledBtnUseTicket: false
            });
          }
        }
      }
  }

  checkAccessScreen = async () => {
      let url = window.location.search;
      let params = queryString.parse(url);
      let accessToken = params.accessToken;
      let menuId = params.menuId;
      let gochiBillId = params.gochiBillId;
      let gochiUserId = params.gochiUserId;
      let shopIdParam = params.shopId;
      let shopName = params.shopName;
      if (!accessToken) {
          var link = "/login";
          if (!this.state.isLoggedIn){
              sessionStorage.setItem('loginCallbackUrl', window.location.pathname);
              return window.location.href = link;
          } else {
              if (this.state.userInfo) { 
                  if (this.state.userInfo.isLogined === false || this.state.userInfo.isGuest) { 
                      window.history.pushState(null, null, '/login');
                      return <Redirect to={link} />
                  }
                  if (this.state.userInfo.isLogined && !this.state.userInfo.isGuest) {
                      this.getDataDidMount(this.state.userInfo, menuId, gochiUserId, gochiBillId, shopIdParam, shopName);
                  }
              }else{
                  window.history.pushState(null, null, '/login');
                  return <Redirect to={link} />
              }
          }
      } else {
          if (this.state.isLoggedIn){
              sessionStorage.clear();
              localStorage.clear();
          }
          this.getDataDidMount(this.state.userInfo, menuId, gochiUserId, gochiBillId, shopIdParam, shopName);
      }
  }
    
	UNSAFE_componentWillMount(){ 
      this.checkAccessScreen();
  }

  async componentDidMount() {
    //
  }

  async loadMoreShop() {
    const userInfo = this.props.userInfo;
    try {
      await this.getListShops(
        this.state.menuId,
        userInfo.userId,
        this.state.sort,
        this.state.page + 1,
        this.state.latitude,
        this.state.longitude
      );
      this.setState({
        page: this.state.page + 1,
      });
    } catch (error) {
      console.log("error loadMoreShop", error);
    }
  }

  onCopy(e) {
    this.setState({ copied: true });
  }

  async onFavouriteShop(shopId, isFavorite) {
    const userId = this.props.userInfo && this.props.userInfo.userId;
    this.props.showLoading(true);
    let data = null;
    try {
      if (isFavorite) {
        data = await ShopService.destroyFavoriteShop(userId, shopId);
      } else {
        data = await ShopService.createFavoriteShop(userId, shopId);
      }

      if (data.status === 200) {
        this.setState({
          listShop: [],
          shopCount: 0,
          page: 1,
        });
        await this.getListShops(
          this.state.menuId,
          userId,
          this.state.sort,
          1,
          this.state.latitude,
          this.state.longitude
        );
      } else {
        this.props.showError(true, data.message, "");
      }
      this.props.showLoading(false);
      // return data;
    } catch (error) {
      this.props.showLoading(false);
      this.props.showError(
        true,
        this.props.t("pages.login.There is something wrong"),
        ""
      );
    }
  }

  hideGochiCountError() {
    this.setState({ gochiCountError: "" });
  }

  setOpenHistoryModal(open) {
    document.body.className = "padding-right-0";
    this.setState({ openHistoryModal: open });
  }

  setOpenHistoryChargeModal(open) {
    document.body.className = "padding-right-0";
    this.setState({ openHistoryChargeModal: open });
  }

  onOpenGochiUse(gochiInfo) {
    const url = window.location.search;
    let params = queryString.parse(url);
    const isEducateSystem = params.siteType === "fukuoka" || params.siteType === "suita" || params.siteType === "nishiwaki" || params.siteType === "hamamatsu";
    console.log("onOpenGochiUse", gochiInfo.menuLimitTotal);
    if (gochiInfo.gochiCountAvailable < 1) {
      this.setState({
        gochiCountError: this.props.t("other.Ticket unavailable"),
      });
      return;
    }
    if (gochiInfo.isETicket === 0) {
      this.setState({
        gochiCountError: this.props.t("other.Prevent paper ticket"),
      });
      return;
    }
    if (gochiInfo.menuChildType === 5 && gochiInfo.menuChildType5?.amountCanUse === 0) {
      this.setState({
        gochiErrorNew: true,
        gochiErrorTitleNew: isEducateSystem ? "利用可能額が0円です。" : "任意の金額をチャージしてください。",
        gochiErrorContentNew: isEducateSystem ? "利用可能額が0円のため、今月はクーポン消込みができません。" : "現在、ご利用頂ける金額が0円のため、本チケットをお使い頂けません。マイページより、任意の金額をチャージしてください。",
      });
      return;
    }
    const shopId = params.shopId;
    const gochiUserId = params.gochiUserId;

    const gochiDetail = this.state.gochiDetail;
    if (gochiDetail.canUse === false) {
      this.props.showError(
        true,
        "",
        this.props.t("other.This ticket can only be used once a day")
      );
    } else {
      this.setState({
        // showGochiUseStaff: true,
        // showGochiUse: true,
        gochiInfo: gochiInfo,
      });

      var usingUrl = "/gochiuse/staff/input";
      let siteTypeP = params.siteType ? "&siteType=" + params.siteType : '';
      let accessTokenP = this.state.accessToken ? "&accessToken=" + this.state.accessToken : '';
      let moreParams = accessTokenP + siteTypeP;
      sessionStorage.setItem("quantityWillBeUsed", this.state.quantity);
      sessionStorage.setItem("usingFlowGochiInfo", JSON.stringify(gochiInfo));
      if (shopId) {
        this.props.history.push(
          usingUrl +
            "?shopId=" +
            shopId +
            "&menuId=" +
            gochiInfo.menuId +
            "&gochiUserId=" +
            gochiUserId + moreParams
        );
      } else {
        this.props.history.push(
          usingUrl +
            "?gochiBillId=" +
            gochiInfo.gochiBillId +
            "&menuId=" +
            gochiInfo.menuId +
            "&gochiUserId=" +
            gochiUserId + moreParams
        );
      }
    }
    this.handleCloseListShopPopup();
  }

  onCloseGochiInfo() {
    this.setState({
      showGochiUse: false,
    });
  }

  onCloseGochiStaff() {
    this.setState({
      showGochiUseStaff: false,
    });
  }

  openMap() {
    let lat =
      this.state.gochiDetail.shop && this.state.gochiDetail.shop.latitude
        ? this.state.gochiDetail.shop.latitude
        : "";
    let long =
      this.state.gochiDetail.shop && this.state.gochiDetail.shop.longitude
        ? this.state.gochiDetail.shop.longitude
        : "";
    window.open("https://maps.google.com?q=" + lat + "," + long, "_blank");
  }

  async getHistoryChargeData(gochiBillId, page, limit) {
    try {
      const response = await GiftService.getListHistoryChargeGochi(gochiBillId, page, limit);
      if (response.status === 200) {
        return response.data;
      }
    } catch(e) {
      console.log("GET LIST HISTORY CHARGE ERROR", e);
    }
  }

  async getHistoryCharge(gochiBillId, page, limit) {
    this.props.showLoading(true);
    const data = await this.getHistoryChargeData(gochiBillId, page, limit);
    this.setState({
      countHistoryCharge: data.count,
      listHistoryCharge: this.state.listHistoryCharge.concat(data.list)
    });
    this.props.showLoading(false);
  }

  async loadMoreHistoryCharge(gochiBillId) {
    const arrTemp = this.state.listHistoryCharge;
    this.setState({
      loadingLoadMore: true
    });
    const data = await this.getHistoryChargeData(gochiBillId, this.state.pageHistoryCharge + 1, this.state.limitCharge);
    this.setState({
      countHistoryCharge: data.count,
      listHistoryCharge: arrTemp.concat(data.list),
      pageHistoryCharge: this.state.pageHistoryCharge + 1,
      loadingLoadMore: false
    });
  }

  async getHistoryUsedGochiChargeData(gochiBillId, page, limit) {
    try {
      const response = await GiftService.getListHistoryUsedGochiCharge(gochiBillId, page, limit);
      if (response.status === 200) {
        return response.data;
      }
    } catch(e) {
      console.log("GET LIST HISTORY COUPONS ERROR", e);
    }
  }

  async getHistoryUsedGochiCharge(gochiBillId, page, limit) {
    this.props.showLoading(true);
    const data = await this.getHistoryUsedGochiChargeData(gochiBillId, page, limit);
    if (data.list) {
      this.setState({
        countHistoryUsedGochiCharge: data.count,
        listHistoryUsedGochiCharge: this.state.listHistoryUsedGochiCharge.concat(data.list)
      });
    }
    this.props.showLoading(false);
  }

  async loadMoreHistoryUsedGochiCharge(gochiBillId) {
    const arrTemp = this.state.listHistoryUsedGochiCharge;
    this.setState({
      loadingLoadMore: true
    });
    const data = await this.getHistoryUsedGochiChargeData(gochiBillId, this.state.pageHistoryUsedGochiCharge + 1, this.state.limitHistoryUsedGochiCharge);
    this.setState({
      countHistoryUsedGochiCharge: data.count,
      listHistoryUsedGochiCharge: arrTemp.concat(data.list),
      pageHistoryUsedGochiCharge: this.state.pageHistoryUsedGochiCharge + 1,
      loadingLoadMore: false
    });
  }

  closeErrorNewPopup() {
    this.setState({
      gochiErrorNew: false,
      gochiErrorTitleNew: "",
      gochiErrorContentNew: ""
    });
  }

  render() {
    const { t } = this.props;
    
    const url = window.location.search; 
    let params = queryString.parse(url);

    // const onFavouriteShop = this.onFavouriteShop;
    const onOpenGochiUse = this.onOpenGochiUse;
    const menuName =
      this.state.gochiDetail.gochiType === "PRICE"
        ? t("pages.menu.searchResult")
        : this.state.gochiDetail.menu && this.state.gochiDetail.menu.menuName;
    const menuId = this.state.gochiDetail.menuId;
    let menuImage = require("../../../assets/img/icons/gochi_noimg-ver4.jpg");
    if (this.state.gochiDetail.gochiType === "PRICE") {
      menuImage = require("../../../assets/img/icons/gochi_price.jpg");
    } else {
      menuImage =
        this.state.gochiDetail.menu &&
        this.state.gochiDetail.menu.menuImage &&
        this.state.gochiDetail.menu.menuImage.length > 0
          ? this.state.gochiDetail.menu.menuImage[0].image
          : null;
      if (!menuImage) {
        if (params.siteType==="suita") {
          menuImage = require("../../../assets/img/icons/icon_suita_no_image.jpg");
        } else if (params.siteType==="fukuoka") {
          menuImage = require("../../../assets/img/icons/icon_fukuoka_no_image.png");
        } else if (params.siteType==="nishiwaki" || params.siteType==="hamamatsu") {
          menuImage = require("../../../assets/img/icons/icon_nishiwaki_no_image.png");
        } else {
          menuImage = require("../../../assets/img/icons/gochi_noimg-ver4.jpg");
        }
      }
    }

    const userImage =
      this.state.gochiDetail.gochiRegister &&
      this.state.gochiDetail.gochiRegister.userImages &&
      this.state.gochiDetail.gochiRegister.userImages.length > 0
        ? this.state.gochiDetail.gochiRegister.userImages[0].image
        : require("../../../assets/img/icons/gochi_noimg-ver4.jpg");
    const userName =
      this.state.gochiDetail.gochiRegister &&
      this.state.gochiDetail.gochiRegister.displayName;
    const gochiBillMessage = this.state.gochiDetail.gochiBillMessage;

    // const includedTaxPrice = this.state.gochiDetail.menu && this.state.gochiDetail.menu.includedTaxPrice;
    const excludedTaxPrice =
      this.state.gochiDetail.menu &&
      this.state.gochiDetail.menu.excludedTaxPrice;
    const gochiCount = this.state.gochiDetail.gochiCount;
    const gochiExpiredDay = this.state.gochiDetail.gochiExpiredDay;
    const menuChildType =
      this.state.gochiDetail.menu && this.state.gochiDetail.menu.menuChildType;
    const childFoods = this.state.childFoods ? this.state.childFoods : [];
    const shopId = this.state.isFromQr
      ? this.state.shopIdParam
      : this.state.gochiDetail && this.state.gochiDetail.shopId;
    const shopName = (this.state.shopDetail && this.state.shopDetail.shopName) || this.state.shopName;
    const groupId = this.state.groupDetail && this.state.groupDetail.groupId;
    // const shopAddress = this.state.shopDetail && (this.state.shopDetail.prefectures + ' ' + this.state.shopDetail.city+ ' ' + this.state.shopDetail.address + ' ' + this.state.shopDetail.building);
    // const freeTicket = this.state.gochiDetail.coupons && this.state.gochiDetail.coupons.filter(coupon => (!coupon.taxAmount) && (!coupon.totalAmount) && (!coupon.useDate));
    const freeTicketCount =
      this.state.gochiDetail.menu &&
      this.state.gochiDetail.menu.menuComboDetail &&
      this.state.gochiDetail.menu.menuComboDetail.quantityFree
        ? this.state.gochiDetail.menu.menuComboDetail.quantityFree
        : 0;
    const description =
      this.state.gochiDetail.menu && this.state.gochiDetail.menu.description;
    const quantityFreeDes =
      this.state.gochiDetail.menu &&
      this.state.gochiDetail.menu.menuComboDetail &&
      this.state.gochiDetail.menu.menuComboDetail.quantityFreeDes;
    const menuQuantity =
      this.state.gochiDetail.menu &&
      this.state.gochiDetail.menu.menuComboDetail &&
      this.state.gochiDetail.menu.menuComboDetail.quantity;
    // const totalShopsBelongToGroup = this.state.groupDetail && this.state.groupDetail.shopCount;
    const priceCombo =
      this.state.gochiDetail.menu &&
      this.state.gochiDetail.menu.menuComboDetail &&
      this.state.gochiDetail.menu.menuComboDetail.priceCombo;
    const includedTaxPriceCombo =
      this.state.gochiDetail.menu &&
      this.state.gochiDetail.menu.menuComboDetail &&
      this.state.gochiDetail.menu.menuComboDetail.includedTaxPriceCombo;
    const menuComboExpiration =
      this.state.gochiDetail.menu &&
      this.state.gochiDetail.menu.menuComboDetail &&
      this.state.gochiDetail.menu.menuComboDetail.expiration;
    const menuComboExpirationEndDate =
      this.state.gochiDetail.menu &&
      this.state.gochiDetail.menu.menuComboDetail &&
      this.state.gochiDetail.menu.menuComboDetail.expirationEndDate
        ? new Date(
            this.state.gochiDetail.menu.menuComboDetail.expirationEndDate
          )
        : null;
    const numberUsePerDay =
      this.state.gochiDetail.menu &&
      this.state.gochiDetail.menu.menuComboDetail &&
      this.state.gochiDetail.menu.menuComboDetail.numberUsePerDay;

    const giftCardMessage =
      this.state.gochiDetail &&
      this.state.gochiDetail.gochiBillMessage &&
      this.state.gochiDetail.gochiBillMessage.message;
    const giftCardImage =
      this.state.gochiDetail &&
      this.state.gochiDetail.giftCard &&
      this.state.gochiDetail.giftCard.image;
    const feePaidByShop = this.state.gochiDetail?.menu?.feePaidByShop
      ? this.state.gochiDetail.menu.feePaidByShop
      : false;

    const includedTaxPrice =
      this.state.gochiDetail.menu &&
      this.state.gochiDetail.menu.includedTaxPrice;
    const remainAmount = this.state.gochiDetail.remainAmount;

    const gochiInfo = {
      shopId: shopId,
      gochiBillId: this.state.gochiDetail.gochiBillId,
      gochiBillNo: this.state.gochiDetail.gochiBillNo,
      shopName: shopName,
      menuName: menuName,
      menuId: menuId,
      menuImage: menuImage,
      excludedTaxPrice: excludedTaxPrice,
      gochiCount: gochiCount,
      gochiExpiredDay: gochiExpiredDay,
      menuChildType: menuChildType,
      childFoods: childFoods,
      groupId: groupId,
      groupName: this.state.groupDetail ? this.state.groupDetail.groupName : "",
      menuCombo:
        this.state.gochiDetail.menu && this.state.gochiDetail.menu.menuCombo,
      quantityFree: freeTicketCount,
      couponCount: this.state.gochiDetail.couponCount,
      comboOutQuantity:
        this.state.gochiDetail.menu &&
        this.state.gochiDetail.menu.menuComboDetail
          ? this.state.gochiDetail.menu.menuComboDetail.quantity
          : 0,
      quantityFreeDes:
        this.state.gochiDetail.menu &&
        this.state.gochiDetail.menu.menuComboDetail &&
        this.state.gochiDetail.menu.menuComboDetail.quantityFreeDes,
      priceCombo: priceCombo,
      includedTaxPriceCombo: includedTaxPriceCombo,
      includedTaxPrice,
      remainAmount,
      coupons: this.state.gochiDetail.coupons,
      shopStatus: this.state.shopDetail && this.state.shopDetail.status,
      gochiCountAvailable: this.state.gochiDetail.gochiCountAvailable,
      gochiExpired: this.state.gochiDetail.gochiExpired,
      gochiRegister: this.state.gochiDetail.gochiRegister,
      isETicket: this.state.gochiDetail.menu && this.state.gochiDetail.menu.isETicket,
      menuLimitTotal:
        this.state.gochiDetail.menu &&
        this.state.gochiDetail.menu.menuLimitTotal,
      menuChildType5: this.state.gochiDetail.menuChildType5,
      showPrice: this.state.gochiDetail.menu && this.state.gochiDetail.menu.showPrice,
      usageType: this.state.gochiDetail.menu && this.state.gochiDetail.menu.usageType,
      reducedTaxType: this.state.gochiDetail.menu && this.state.gochiDetail.menu.reducedTaxType
    };

    const isEducateSystem = params.siteType === "fukuoka" || params.siteType === "suita" || params.siteType === "nishiwaki" || params.siteType === "hamamatsu";
    return (
      <>
        <div className="content">
          <Header
            title={
              this.state.isMultiUsedMenu
                ? this.state.groupDetail.groupName
                : shopName
            }
            isLoggedIn={this.state.isLoggedIn}
            t={t}
            hasBack={true}
            usingFlow={true}
            siteType={params.siteType}
          />
          <div className="content-mypage">
            {this.state.gochiDetail.shop &&
              this.state.gochiDetail.shop.status === "CLOSED" && (
                <div className="shop-closed">
                  {t("pages.menu.This shop has closed")}
                </div>
              )}
            {this.state.gochiDetail.menu && this.state.gochiDetail.menu.isETicket === 0 && 
            (<div className="mb-3 paper-ticket-note">{t("pages.gochiuse.paper ticket note")}</div>)}

            {this.state.gochiDetail.gochiUserType !==
              constants.GOCHI_TYPE_YOURSELF &&
              this.state.gochiDetail.giftCard && (
                <>
                  <div
                    className="pb-2 fs-18 clr-000000 font-weight-bold gift-card-user-info"
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "pages.gochiuse.A feast from USERNAME has arrived",
                        { username: userName }
                      ),
                      interpolation: { escapeValue: false },
                    }}
                  ></div>

                  <div className="box-card mb-3 text-center">
                    <img src={giftCardImage} alt="" />
                  </div>
                  {giftCardMessage && (
                    <>
                      <div className="corner-border-top-left"></div>
                      <div
                        className="mt-1 space-pre-line break-word font-weight-bold"
                        style={{ padding: "0 15px" }}
                      >
                        {giftCardMessage}
                      </div>
                      <div className="corner-border-bottom-right float-right"></div>
                      <div className="clearfix mb-3"></div>
                    </>
                  )}
                </>
              )}

            {this.state.gochiDetail.gochiUserType !==
            constants.GOCHI_TYPE_YOURSELF ? (
              this.state.sentMessageToGiver ? (
                <>
                  <div className="fs-13 clr-000000 font-weight-bold">
                    {t("pages.gochiuse.I sent a thank you message")}
                  </div>
                  <div className="message-thank-you-to-giver space-pre-line mt-1 mb-4 break-word">
                    <span className="fs-35 font-weight-bold">
                      MESSAGE THANK YOU
                    </span>
                    <br />
                    {this.state.sentMessage}
                  </div>
                </>
              ) : (
                <>
                  <div className="fs-13 clr-000000 font-weight-bold">
                    {t(
                      "pages.gochiuse.Would you like to send thank you message"
                    )}
                  </div>
                  <textarea
                    className={
                      this.state.messageTextError
                        ? "form-control mt-1 textarea-message ex-form-required"
                        : "form-control mt-1 textarea-message"
                    }
                    placeholder={t("pages.gochiuse.messageToBuyerPlaceholder")}
                    value={this.state.messageText ? this.state.messageText : ""}
                    onChange={this.handleChange}
                    name="messageToBuyer"
                  />
                  {this.state.messageTextError && (
                    <div className="text-danger fs-14 mb-2 fs-12">
                      {this.state.messageTextError}
                    </div>
                  )}

                  <button
                    type="button"
                    className="btn btn-green-38 mt-2 mb-4"
                    onClick={this.handleOpenConfirmPopup}
                    disabled={this.state.disabledBtnSendMessage}
                  >
                    {t("pages.gochiuse.Send a message")}
                  </button>
                </>
              )
            ) : null}

            <div className="dish-infor">
              <div className="media mb-2">
                <div className="dish-image">
                  <figure className="mb-0 dish-fig">
                    <img src={menuImage} alt={menuName} width="100%" />
                  </figure>
                  <div></div>
                </div>
                <div className="media-body dish-body pl-2">
                  <div className="fs-19 dish-link mt-0">{menuName}</div>
                  {isEducateSystem ? (
                    <>
                      <div className="text-price-can-use-fukuoka">利用可能額</div>
                      <div className="d-flex justify-content-start align-items-end">
                        <span className="fs-47 text-dark font-weight-bolder">
                          {gochiInfo.menuChildType === 5 ? gochiInfo.menuChildType5?.amountCanUse?.toLocaleString() : remainAmount?.toLocaleString()}
                        </span>
                        <span className="fs-16 text-dark font-weight-bolder line-height-47">
                          円
                        </span>
                      </div>
                    </>
                  ) : (
                    <div className="d-flex justify-content-center align-items-end">
                      <span className="fs-16 text-dark font-weight-bolder line-height-47">
                        {this.props.t("pages.gochiuse.total")}
                      </span>
                      <span className="fs-47 text-dark font-weight-bolder">
                        {gochiInfo.menuChildType === 5 ? gochiInfo.menuChildType5?.amountCanUse?.toLocaleString() : remainAmount?.toLocaleString()}
                      </span>
                      <span className="fs-16 text-dark font-weight-bolder line-height-47">
                        円
                      </span>
                    </div>
                  )}
                </div>
              </div>

              {!isEducateSystem && (
                <div className="d-flex justify-content-end border-top pt-2 my-2">
                  <div />
                  <div
                    className="new-menu-name cursor-pointer font-weight-bold text-dark border-bottom-dark fs-14 ml-2"
                    onClick={() => {
                      this.setOpenHistoryModal(true);
                      if (gochiInfo.menuChildType === 5) {
                        this.getHistoryUsedGochiCharge(gochiInfo.gochiBillId, this.state.pageHistoryUsedGochiCharge, this.state.limitHistoryUsedGochiCharge);
                      }
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCaretRight}
                      size="lg"
                      style={{ marginRight: 5 }}
                    />
                    {this.props.t('pages.gochiuse.usage history')}
                  </div>
                  {gochiInfo.menuChildType === 5 && (
                    <div
                      className="new-menu-name cursor-pointer font-weight-bold text-dark border-bottom-dark fs-14 ml-54px"
                      onClick={
                        () => {
                          this.setOpenHistoryChargeModal(true);
                          this.getHistoryCharge(gochiInfo.gochiBillId, this.state.pageHistoryCharge, this.state.limitCharge);
                        }}
                    >
                      <FontAwesomeIcon
                        icon={faCaretRight}
                        size="lg"
                        style={{ marginRight: 5 }}
                      />
                      {this.props.t('pages.gochiuse.charge history')}
                    </div>
                  )}
                </div>
              )}

              {description && !isEducateSystem && (
                <div className="fs-13 break-line mb-2">{description}</div>
              )}

              {quantityFreeDes && (
                <>
                  <div className="arrow-up"></div>
                  <div className="div-free-ticket-desc break-line break-word">
                    <div className="font-weight-bold">
                      {t("pages.invite.Bonus")}
                    </div>
                    <div className="break-word">{quantityFreeDes}</div>
                  </div>
                </>
              )}

              {freeTicketCount !== 0 && (
                <div
                  className={
                    feePaidByShop && typeof feePaidByShop !== "undefined"
                      ? "mt-2 has-feePaidByShop"
                      : "mt-2"
                  }
                >
                  <span className="menu-combo-total-coupons">
                    {`+`}
                    {freeTicketCount}
                    {t("pages.invite.Free of charge")}
                    {t("pages.invite.Total combo coupons", {
                      totalCoupon: freeTicketCount + menuQuantity,
                    })}
                  </span>
                </div>
              )}

              {feePaidByShop && typeof feePaidByShop !== "undefined" && (
                <div className="mt-2 mb-3">
                  <span className="menu-combo-total-coupons">
                    {t("pages.invite.Fee paid by shop")}
                  </span>
                </div>
              )}

              {menuComboExpiration && (
                <div>
                  <span className="fs-12 clr-000000">
                    {t("pages.login.usage period")}
                  </span>
                  <span className="text-red fs-13 mt-2 font-weight-bold ml-2">
                    {t("pages.login.available days", {
                      days: menuComboExpiration,
                    })}
                  </span>
                </div>
              )}

              {menuComboExpirationEndDate && (
                <div>
                  <span className="fs-12 clr-000000">
                    {t("pages.login.usage period")}
                  </span>
                  <span className="text-red fs-13 mt-2 font-weight-bold ml-2">
                    {t("pages.login.available to date", {
                      date: `${menuComboExpirationEndDate.getFullYear()}/${
                        menuComboExpirationEndDate.getMonth() + 1 < 10
                          ? "0"
                          : ""
                      }${
                        menuComboExpirationEndDate.getMonth() + 1
                      }/${menuComboExpirationEndDate.getDate()}`,
                    })}
                  </span>
                </div>
              )}

              {numberUsePerDay === 1 && (
                <div>
                  <span className="fs-12 clr-000000">
                    {t("pages.login.usage limit")}
                  </span>
                  <span className="text-red fs-13 font-weight-bold ml-2">
                    {t("pages.login.1 sheet per day")}
                  </span>
                </div>
              )}

              {groupId && !this.state.isFromQr && (
                <button
                  type="button"
                  onClick={this.handleShowListShops}
                  className="btn btn-outline-red-38 mt-3 align-center fs-16 font-weight-bold mb-3"
                >
                  {t(
                    "pages.login.List of stores where this meal voucher can be used"
                  )}
                </button>
              )}
            </div>

            {this.state.gochiDetail.shopId && (
              <>
                <div className="line-horizontal menu-name-ln mb-2 mt-2"></div>

                <div className="mt-3">
                  <div className="fs-18 clr-000000 font-weight-bold">
                    {shopName}
                  </div>
                  {this.state.gochiDetail.shop &&
                    this.state.gochiDetail.shop.prefectures && (
                      <div className="fs-13">
                        {this.state.gochiDetail.shop.prefectures}
                        {this.state.gochiDetail.shop.city
                          ? this.state.gochiDetail.shop.city
                          : ""}
                        {this.state.gochiDetail.shop.address
                          ? this.state.gochiDetail.shop.address
                          : ""}
                        {this.state.gochiDetail.shop.building
                          ? this.state.gochiDetail.shop.building
                          : ""}
                      </div>
                    )}
                </div>

                <button
                  type="button"
                  onClick={this.openMap}
                  className="btn btn-green-38 mt-2 mb-3 align-center fs-16"
                >
                  {t("pages.tome.View map")}
                </button>
              </>
            )}

            <div className="line-horizontal menu-name-ln mt-3 mb-2"></div>

            {this.state.gochiDetail && this.state.gochiDetail.gochiCode && (
              <div className="fs-13 space-pre-line clr-000000 mt-4">
                <span className="font-weight-bold">
                  {isEducateSystem ? "精算コード" : t("pages.share.gochiCode")}:{" "}
                </span>
                <span>{this.state.gochiDetail.gochiCode}</span>
              </div>
            )}

            {/* Button copy  */}
            {this.state.gochiDetail.gochiUserType !== "ANYONE" && !isEducateSystem && (
              <div className="input-copy mt-2">
                <div className="w-100 position-relative">
                  {this.state.copied && (
                    <div className="copied-tag">{t("pages.mypage.Copied")}</div>
                  )}
                  <input
                    className="form-control text-center fs-14"
                    readOnly
                    defaultValue={
                      this.state.gochiDetail && this.state.gochiDetail.inviteUrl
                    }
                    style={{ height: 53 }}
                  />
                </div>
                <div className="mt-2">
                  <CopyToClipboard
                    onCopy={this.onCopy}
                    text={
                      this.state.gochiDetail && this.state.gochiDetail.inviteUrl
                    }
                  >
                    <button
                      className="btn btn-green-38 ml-auto mr-auto"
                      onCopy={this.onCopy}
                    >
                      {t("pages.menu.Copy usage link")}
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
            )}

            {!(gochiInfo.menuChildType === 5 && gochiInfo.menuChildType5?.amountCanUse === 0) && (
              <>
                <div className="fs-16 bold mt-3">
                  {isEducateSystem ? "クーポン使用期限日": t("pages.tome.Ticket expiration date")}
                </div>

                <div className="message-row d-flex align-items mt-2">
                  <div className="expired-date fs-24">
                    {gochiInfo.menuChildType5 && gochiInfo.menuChildType5.walletNearestExpired ? 
                    moment(gochiInfo.menuChildType5.walletNearestExpired).format(
                        `YYYY${t("pages.tome.year")}MM${t("pages.tome.month")}DD${t(
                          "pages.tome.day"
                        )}`)
                      :
                      gochiInfo.gochiExpired &&
                        moment(gochiInfo.gochiExpired).format(
                          `YYYY${t("pages.tome.year")}MM${t("pages.tome.month")}DD${t(
                            "pages.tome.day"
                          )}`
                        )}
                    {t("pages.tome.Until")}
                  </div>
                </div>
              </>
            )}
            <p className="fs-12">
              {!isEducateSystem && (
                <>※使用期限が過ぎたものは無効となります。規約上、返金等はできませんのでご注意ください。</>
              )}
            </p>

            <button
              className="btn btn-red-38 mb-3 mt-3"
              disabled={this.state.disabledBtnUseTicket}
              onClick={() => onOpenGochiUse(gochiInfo)}
            >
              {isEducateSystem ? "このクーポンを利用する" : t("pages.tome.Use this ticket")}
            </button>

            {!isEducateSystem && (
              <>
                <div className="fs-13">{t("pages.tome.Use ticket note")}</div>
                {gochiInfo.menuChildType === 5 && gochiInfo.menuChildType5?.amountCanUse !== 0 && (
                  <div className="text-menu-type-5 mb-2 fs-13">
                    ※チケット使用期限日は最も早く期限日を迎える日時を表示しています。詳しくは<a href="https://user-guide.gochimeshi.com/communitycurrency_deadline/ " target="_blank" rel="noopener noreferrer">こちら</a>
                  </div>
                )}
                <div className="fs-13">{t("pages.tome.Use ticket note")}</div>
                <div className="mt-2 font-weight-bold">
                  ※
                  <span className="user-name">
                    {(gochiInfo.gochiRegister &&
                      gochiInfo.gochiRegister.displayName) ||
                      "GUEST"}
                  </span>
                  {t("pages.tome.We have received the payment")}
                </div>

                <div className="mb-3">
                  <div className="explain-icon ic-1 align-center">
                    <div className="explain-note">
                      {t("pages.gochiuse.Explain IC 1")}
                    </div>
                  </div>
                  <div className="explain-icon ic-2 align-center">
                    <div className="explain-note">
                      {t("pages.gochiuse.Explain IC 2")}
                    </div>
                  </div>
                  <div className="explain-icon ic-3 align-center">
                    <div className="explain-note">
                      {t("pages.gochiuse.Explain IC 3")}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {this.state.showListShopPopup && (
          <ListShop
            showListShopPopup={this.state.showListShopPopup}
            handleCloseListShopPopup={this.handleCloseListShopPopup}
            menuId={menuId}
            menuChildType={menuChildType}
            showUsingBtn={true}
            gochiInfo={gochiInfo}
            onOpenGochiUse={this.onOpenGochiUse}
          />
        )}

        {this.state.showGochiUseStaff ? (
          <GochiUseStaff
            showing={this.state.showGochiUseStaff}
            onCloseGochiStaff={this.onCloseGochiStaff}
            gochiInfo={this.state.gochiInfo}
            quantity={this.state.quantity}
            childFoodId={this.state.childFoodId}
          />
        ) : null}

        {this.state.gochiCountError && (
          <Modal
            size="sm"
            centered
            show={!!this.state.gochiCountError}
            onHide={this.hideGochiCountError}
          >
            <Modal.Header closeButton>
              {this.state.gochiCountError}
            </Modal.Header>
            {/* <ModalBody>
						{this.state.gochiCountError}
					</ModalBody> */}
          </Modal>
        )}

        <Modal
          size="sm"
          show={this.state.showConfirmMessagePopup}
          onHide={this.onCloseConfirmMessagePopup}
          animation={false}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
        >
          <Modal.Header className="header-close" closeButton />
          <Modal.Body className="gochi-content">
            <div className="dish-infor">
              <div className="gochi-infor-shop mb-2 mt-1">
                {t("pages.gochiuse.Message confirmation")}
              </div>
              <div className="fs-13 align-center">
                {t("pages.gochiuse.The following message will be sent")}
              </div>
              <div className="line-horizontal break-ln mt-1 mb-2"></div>
              <div className="space-pre-line break-word">
                {this.state.messageText}
              </div>
              <div className="line-horizontal break-ln mt-2 mb-3"></div>
              <button
                type="button"
                className="btn btn-green-38 mt-2"
                onClick={this.handleSendMessage}
              >
                {t("pages.gochiuse.Send a message review")}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <LimitTotalTicketUseHistoryModal
          show={this.state.openHistoryModal}
          onHide={() => {
            this.setOpenHistoryModal(false);
            if (gochiInfo.menuChildType === 5) {
              this.setState({
                listHistoryUsedGochiCharge: [],
                countHistoryUsedGochiCharge: 0,
                pageHistoryUsedGochiCharge: 1,
              })
            }
          }}
          listCoupons={gochiInfo.menuChildType === 5 ? (this.state.listHistoryUsedGochiCharge || []) : (this.state.gochiDetail.couponLimitTotal || [])}
          countCouponsGochiCharge={this.state.countHistoryUsedGochiCharge}
          limitCouponsGochiCharge={this.state.limitHistoryUsedGochiCharge}
          pageCouponsGochiCharge={this.state.pageHistoryUsedGochiCharge}
          loadMoreHistoryUsedGochiCharge={this.loadMoreHistoryUsedGochiCharge}
          gochiBillId={gochiInfo.gochiBillId}
          menuChildType={gochiInfo.menuChildType}
          loadingLoadMore={this.state.loadingLoadMore}
        />
        <ChargeHistoryModal
          show={this.state.openHistoryChargeModal}
          onHide={() => {
            this.setOpenHistoryChargeModal(false);
            this.setState({
              listHistoryCharge: [],
              countHistoryCharge: 0,
              pageHistoryCharge: 1
            });
          }}
          listCharges={this.state.listHistoryCharge || []}
          count={this.state.countHistoryCharge}
          limit={this.state.limitCharge}
          page={this.state.pageHistoryCharge}
          loadMoreHistoryCharge={this.loadMoreHistoryCharge}
          gochiBillId={gochiInfo.gochiBillId}
          loadingLoadMore={this.state.loadingLoadMore}
        />
        <Modal
          show={this.state.gochiErrorNew}
          onHide={this.closeErrorNewPopup}
          size="sm"
          centered
          dialogClassName="modal-error-new"
        >
          <Modal.Header
            className="rounded-bottom align-items-start modal-header-error-new"
            closeButton
          />
          <Modal.Body
            className="modal-body-error-new"
          >
            <div className="modal-title-error-new">
              <span>{this.state.gochiErrorTitleNew}</span>
            </div>
            <div className="modal-box-content-error-new">
              <div className="modal-content-error-new">{this.state.gochiErrorContentNew}</div>
              <div>
                {(gochiInfo.menuChildType === 5 && gochiInfo.menuChildType5?.amountCanUse === 0 && !isEducateSystem) && (
                  <button className="btn-close-modal-error-new" onClick={() => {window.location.href = "/mypage/mygochi_charge"}}>
                    マイページに戻る
                  </button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content) =>
    dispatch(showError(showing, title, content)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(GochiBillDetails))
);

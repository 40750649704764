/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import MetaTags from 'react-meta-tags';
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import UserService from "../../../../api/services/UserService";
import {
  useUserDetails,
  useScript,
  useStyleSheet,
  uploadImage,
  toBlob
} from "../../../../utils";
import { Footer } from "../../layout/Footer";
import Header from "../../layout/Header";
import { ModalGroup } from "../../common/ModalGroup";
import { SituationModal } from "../../common/SituationModal";
import { GiftToModal } from "../../common/GiftToModal";
import { ExplanationMypage } from "../../layout/ExplanationMypage";
import { DeleteAccountConfirmModal } from "./DeleteAccountConfirmModal";
import LoadingOverlay from "../../common/LoadingOverlay";
import AuthService from "../../../../api/services/AuthService";

export function Edit(props) {
  useStyleSheet(
    "https://cdnjs.cloudflare.com/ajax/libs/cropperjs/1.5.11/cropper.min.css",
    null,
    {
      integrity:
        "sha512-NCJ1O5tCMq4DK670CblvRiob3bb5PAxJ7MALAz2cV40T9RgNMrJSAwJKy0oz20Wu7TDn9Z2WnveirOeHmpaIlA==",
      crossorigin: "anonymous",
      referrerpolicy: "no-referrer",
    }
  );
  useScript(
    "https://cdnjs.cloudflare.com/ajax/libs/cropperjs/1.5.11/cropper.min.js",
    null,
    {
      integrity:
        "sha512-FHa4dxvEkSR0LOFH/iFH0iSqlYHf/iTwLc5Ws/1Su1W90X0qnxFxciJimoue/zyOA/+Qz/XQmmKqjbubAAzpkA==",
      crossorigin: "anonymous",
      referrerpolicy: "no-referrer",
    }
  );
  useScript("/assets/js/app.js");

  if (!props.userInfo || (props.userInfo && !props.userInfo.userId)) {
    window.location.href = "/login";
  }

  const NO_IMAGE_PROFILE = require("../../../../assets/img/icons/profile_blank.png");
  const GENDER = {
    MALE: 1,
    FEMALE: 0,
    SECRET: 2,
    DO_NOT_SHOW: 3,
  };

  var initDate = new Date("1900-01-01");
  initDate.setHours(0, 0, 0, 0);

  var today = new Date();

  let initYear = initDate.getFullYear();
  // let initMonth = initDate.getMonth() + 1;

  let curYear = today.getFullYear();

  let months = [];
  for (var m = 1; m < 13; m++) {
    months.push(
      <option key={m} value={m}>
        {("0" + m).slice(-2)}
      </option>
    );
  }

  let years = [];
  for (var y = initYear; y <= curYear; y++) {
    years.push(
      <option key={y} value={y}>
        {y}
      </option>
    );
  }

  let days = [];
  for (var d = 1; d <= 31; d++) {
    days.push(
      <option key={d} value={d}>
        {("0" + d).slice(-2)}
      </option>
    );
  }

  const tmpLocationObj = {
    prefectureId: "",
    cityId: "",
    filteredCities: [],
  };

  const [loadingUserDetail, setLoadingUserDetail] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);

  const userDetails = useUserDetails(
    props.userInfo.userId,
    props.userInfo.userId,
    () => setLoadingUserDetail(false)
  );

  const listPrefectures = useSelector((state) => state.prefectures);
  const [prefectureId, setPrefectureId] = useState("");
  const listCities = useSelector((state) => state.cities);
  // const filteredCities = listCities.filter(
  //   (city) => city.prefCode === prefectureId
  // );
  const [cityId, setCityId] = useState("");

  const [image, setImage] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const [accountId, setAccountId] = useState("");
  const [gender, setGender] = useState(GENDER.DO_NOT_SHOW);
  const [birthYear, setBirthYear] = useState(0);
  const [birthMonth, setBirthMonth] = useState(0);
  const [birthDate, setBirthDate] = useState(0);
  const [isCheckNotShowBirthYear, setIsCheckNotShowBirthYear] = useState(false);
  const [anniversaries, setAnniversaries] = useState([]);
  const [unFavoriteFood, setUnFavoriteFood] = useState("");
  const [userLocation, setLocation] = useState(tmpLocationObj);
  const [userFavoriteLocations, setFavoriteLocations] = useState([]);
  const [message, setMessage] = useState("");
  const [webPage, setWebPage] = useState("");
  const [loginViaSNS, setLoginViaSNS] = useState(false);

  const [openModalEditImg, setOpenModalEditImg] = useState(false);
  const [openModalChangeMail, setOpenModalChangeMail] = useState(false);
  const [openModalChangePass, setOpenModalChangePass] = useState(false);
  const [openModalChangePhone, setOpenModalChangePhone] = useState(false);
  const [openModalCompleteSetting, setOpenModalCompleteSetting] =
    useState(false);
  const [openModalAuthenCode, setOpenModalAuthenCode] = useState(false);
  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(false);
  const [openDeleteSuccessModal, setOpenDeleteSuccessModal] = useState(false);

  const [currentMail, setCurrentMail] = useState("");
  const [currentPhone, setCurrentPhone] = useState("");
  const [email, setEmail] = useState("");
  const [currPassword, setCurrPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [authenCode, setAuthenCode] = useState("");
  const [disableBtnResendCode, setDisableBtnResendCode] = useState(true);

  const [errorMessages, setErrorMessages] = useState([]);

  const [cropper, setCropper] = useState(null);
  const [cropRange, setCropRange] = useState(0.9);
  const [imageFile, setImageFile] = useState(null);
  // const [naturalImageWidth, setNaturalImageWidth] = useState(300);
  // const [naturalImageHeight, setNaturalImageHeight] = useState(300);
  const containerWidth = window.innerWidth > 1000 ? 600 : 320;
  const containerHeight = window.innerWidth > 1000 ? 505 : 270;

  useEffect(() => {

  }, []);

  useEffect(() => {
    if (!imageFile || !openModalEditImg) return;
    var image = document.getElementById("image-crop-preview");
    console.log("IMAGE FILE: ", imageFile);
    const reader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.onloadend = () => {
      image.setAttribute("src", reader.result);

      const cropper = new window.Cropper(image, {
        dragMode: "move",
        aspectRatio: 1 / 1,
        autoCropArea: 0.9,
        restore: false,
        guides: false,
        center: false,
        highlight: false,
        cropBoxMovable: false,
        cropBoxResizable: false,
        toggleDragModeOnDblclick: false,
        minContainerWidth: containerWidth,
        minContainerHeight: containerHeight,
      });
      setCropper(cropper);
    };
  }, [imageFile, openModalEditImg]);

  function onChangeCropRange(e) {
    // console.log('VALUE:', e.target.value);
    setCropRange(e.target.value);
    if (!cropper) return;
    const imageData = cropper.getImageData();
    const containerData = cropper.getContainerData();
    // console.log('IMG DATA: ', imageData, containerData);
    const zoomRatio = Math.min(
      imageData.naturalWidth / containerData.width,
      imageData.naturalHeight / containerData.height
    );
    // console.log('ZOOM TO:', e.target.value / zoomRatio);
    cropper.zoomTo(e.target.value / zoomRatio);
  }

  const tmpAnniversaryObj = {
    userAnniversaryId: null,
    title: "",
    anniversary: null,
    aYear: 0,
    aMonth: 0,
    aDay: 0,
  };

  const tmpFavLocationObj = {
    favoriteLocationId: null,
    prefectureId: "",
    cityId: "",
    filteredCities: [],
  };

  var editParams = {
    accountId: accountId,
    displayName: displayName,
    email: userDetails && userDetails.email,
    gender: gender,
    image: image,
    birthday:
      parseInt(birthYear) === 0 ||
      parseInt(birthMonth) === 0 ||
      parseInt(birthDate) === 0
        ? null
        : `${isCheckNotShowBirthYear ? parseInt(birthYear - 900) : birthYear}-${
            birthMonth < 10 ? "0" + birthMonth : birthMonth
          }-${birthDate < 10 ? "0" + birthDate : birthDate}`,
    userAnniversarys: anniversaries,
    unFavoriteFood: unFavoriteFood && unFavoriteFood.trim(),
    userLocation:
      prefectureId === "" || cityId === ""
        ? { prefectureId: null, cityId: null }
        : { prefectureId: parseInt(prefectureId), cityId: parseInt(cityId) },
    userFavoriteLocations: userFavoriteLocations,
    message: message,
    webPage: webPage,
  };

  function onValueChange(e) {
    var value = e.currentTarget.value;
    if (value) {
      setGender(parseInt(e.currentTarget.value));
    } else {
      setGender(null);
    }
  }

  function handleChangeTilte(value, index) {
    let tmp = [...anniversaries];
    for (let i = 0; i < tmp.length; i++) {
      if (i === parseInt(index)) {
        tmp[i].title = value && value.trim() !== "" ? value : null;
        break;
      }
    }
    setAnniversaries(tmp);
  }

  function handleUpdateAnniversary(value, index, option) {
    let tmp = [...anniversaries];
    let tmpValue = parseInt(value);
    for (let i = 0; i < tmp.length; i++) {
      if (i === parseInt(index)) {
        if (option === "aYear") {
          tmp[i].aYear = tmpValue;
        } else if (option === "aMonth") {
          tmp[i].aMonth = tmpValue;
        } else if (option === "aDay") {
          tmp[i].aDay = tmpValue;
        }
        tmp[i].anniversary = `${tmp[i].aYear}-${("0" + tmp[i].aMonth).slice(
          -2
        )}-${("0" + tmp[i].aDay).slice(-2)}`;
        break;
      }
    }
    setAnniversaries(tmp);
  }

  function handleRemoveAnniversary(index) {
    // var nodes = document.getElementById('mypageAnniversary').children;
    if (anniversaries.length === 0) {
      alert("Can not delete");
    } else {
      let tmp = [...anniversaries];
      tmp.splice(index, 1);
      setAnniversaries(tmp);
      console.log("tmp ==", tmp);
    }
  }

  function handleAddAnniversary() {
    let tmp = [...anniversaries];
    tmp.push(tmpAnniversaryObj);
    setAnniversaries(tmp);
    console.log("tmp ==", tmp);
  }

  // function standardizedData(item) {
  //   delete item.aYear;
  //   delete item.aMonth;
  //   delete item.aDay;
  //   return item.title !== null;
  // }

  // function standardizedDataFav(item) {
  //   delete item.filteredCities;
  //   return item.prefectureId !== "" && item.cityId !== "";
  // }

  function handleUpdateLocation(value, option) {
    let tmp = userLocation;
    let tmpValue = value !== "" ? parseInt(value) : "";
    if (option === "prefectureLocation") {
      tmp.prefectureId = tmpValue;
      tmp.filteredCities = listCities.filter(
        (city) => parseInt(city.prefCode) === tmpValue
      );
      setCityId("");
    } else if (option === "cityLocation") {
      tmp.cityId = tmpValue;
    }
    setLocation(tmp);
  }

  function handleUpdateFavLocation(value, index, option) {
    let tmp = [...userFavoriteLocations];
    console.log("tmpFav", tmp);
    let tmpValue = value !== "" ? parseInt(value) : "";
    for (let i = 0; i < tmp.length; i++) {
      if (i === parseInt(index)) {
        if (option === "favPrefecture") {
          tmp[i].prefectureId = tmpValue;
          tmp[i].filteredCities = listCities.filter(
            (city) => parseInt(city.prefCode) === tmpValue
          );
          tmp[i].cityId = "";
        } else if (option === "favCity") {
          tmp[i].cityId = tmpValue;
        }
        break;
      }
    }
    setFavoriteLocations(tmp);
  }

  function handleRemoveFavLocation(index) {
    if (userFavoriteLocations.length === 0) {
      alert("Can not delete");
    } else {
      let tmp = [...userFavoriteLocations];
      tmp.splice(index, 1);
      setFavoriteLocations(tmp);
      console.log("tmpFav ==", tmp);
    }
  }

  function handleAddFavLocation() {
    let tmp = [...userFavoriteLocations];
    tmp.push(tmpFavLocationObj);
    setFavoriteLocations(tmp);
    console.log("tmpFav ==", tmp);
  }

  async function handleSubmit() {
    console.log("editParams ==== ", editParams);
    let errors = [];

    if (!displayName || displayName.trim() === "") {
      errors.push("＜お名前＞は入力必須です");
    } else if (displayName.length > 100) {
      errors.push("お名前は100文字以内でご記入ください");
    }

    if (!accountId || accountId.trim() === "") {
      errors.push("＜ユーザー名＞は入力必須です");
    } else if (
      !/^[a-zA-Z0-9]*$/.test(accountId) ||
      accountId.length < 4 ||
      accountId.length > 20
    ) {
      errors.push("ユーザー名は半角英数字・4文字以上20文字以下です");
    }

    if (
      (parseInt(birthYear) === 0 &&
        (parseInt(birthMonth) !== 0 || parseInt(birthDate) !== 0)) ||
      (parseInt(birthMonth) === 0 &&
        (parseInt(birthYear) !== 0 || parseInt(birthDate) !== 0)) ||
      (parseInt(birthDate) === 0 &&
        (parseInt(birthYear) !== 0 || parseInt(birthMonth) !== 0))
    ) {
      errors.push("生年月日を正しくご入力ください。");
    }

    for (let i = 0; i < anniversaries.length; i++) {
      if (anniversaries[i].title && anniversaries[i].title.trim() !== "") {
        if (
          parseInt(anniversaries[i].aYear) === 0 ||
          parseInt(anniversaries[i].aMonth) === 0 ||
          parseInt(anniversaries[i].aDay) === 0
        ) {
          errors.push("記念日を正しくご入力ください。");
        }
      } else {
        if (
          parseInt(anniversaries[i].aYear) !== 0 ||
          parseInt(anniversaries[i].aMonth) !== 0 ||
          parseInt(anniversaries[i].aDay) !== 0
        ) {
          errors.push("記念日を正しくご入力ください。");
        }
      }
    }

    if (
      unFavoriteFood !== "" &&
      unFavoriteFood &&
      unFavoriteFood.length > 400
    ) {
      errors.push("苦手な食べ物は400文字以内でご記入ください");
    }

    if (
      (prefectureId !== "" && cityId === "") ||
      (prefectureId === "" && cityId !== "")
    ) {
      errors.push("居住エリアを正しくご入力ください。");
    }

    for (let i = 0; i < userFavoriteLocations.length; i++) {
      if (
        (userFavoriteLocations[i].prefectureId === "" &&
          userFavoriteLocations[i].cityId !== "") ||
        (userFavoriteLocations[i].prefectureId !== "" &&
          userFavoriteLocations[i].cityId === "")
      ) {
        errors.push("よく行くエリアを正しくご入力ください。");
      }
    }

    if (message && message.length > 400) {
      errors.push("プロフィールは400文字以内でご記入ください");
    }

    if (webPage && webPage !== "" && !isUrlValid(webPage)) {
      errors.push("URLの形式が正しくありません");
    }

    setErrorMessages(errors);

    // All params are valid
    if (errors.length === 0) {
      var data = editParams;

      // if (data.anniversaries.length > 0) {
      //   let results = data.anniversaries.filter(standardizedData);
      //   data.anniversaries = [...results];
      // }

      // if (data.userFavoriteLocations.length > 0) {
      //   let resultsFav = data.userFavoriteLocations.filter(standardizedDataFav);
      //   data.userFavoriteLocations = [...resultsFav];
      // }
      console.log("data ==== ", data);

      UserService.editUser(props.userInfo.userId, data).then((response) => {
        console.log("response ====", response);
        if (response.status === 200) {
          window.location.href = "/mypage";
        } else {
          setErrorMessages([
            "何かが間違っています。後でもう一度試してください",
          ]);
        }
      });
    }
  }

  function handleCancel() {
    // initialData(userDetails, listCities);
    window.location.href = "/mypage";
  }

  async function getUserPhoneNumber() {
    await Auth.currentAuthenticatedUser().then((user) => {
      if (user.attributes.phone_number) {
        let value = user.attributes.phone_number;
        value = "0" + value.slice(3);
        setCurrentPhone(value);
      }
    });
  }

  function initialData(value, listCities) {
    var userDetailInfo = value;
    var citiesList = listCities;

    if (userDetailInfo) {
      if (
        userDetailInfo.userImage &&
        userDetailInfo.userImage.length > 0 &&
        userDetailInfo.userImage[0].image
      ) {
        setImage(userDetailInfo.userImage[0].image);
      }

      setDisplayName(userDetailInfo.displayName);
      setAccountId(userDetailInfo.accountId);
      setGender(userDetailInfo.gender);

      if (userDetailInfo.birthday) {
        let birth = new Date(userDetailInfo.birthday);
        birth.setHours(0, 0, 0, 0);
        if (birth.getFullYear() < 1900) {
          birth.setFullYear(birth.getFullYear() + 900);
          setIsCheckNotShowBirthYear(true);
        }
        setBirthYear(birth.getFullYear());
        setBirthMonth(birth.getMonth() + 1);
        setBirthDate(birth.getDate());
      }

      if (
        userDetailInfo.userAnniversary &&
        userDetailInfo.userAnniversary.length > 0
      ) {
        let tmp = [...userDetailInfo.userAnniversary];
        setAnniversaries(tmp);
      } else {
        let tmp = [];
        tmp.push(tmpAnniversaryObj);
        setAnniversaries(tmp);
      }

      setUnFavoriteFood(userDetailInfo.unFavoriteFood ? userDetailInfo.unFavoriteFood : "");

      if (userDetailInfo.userLocation) {
        let tmpLocation = userDetailInfo.userLocation;
        tmpLocation.filteredCities = citiesList.filter(
          (city) =>
            parseInt(city.prefCode) === parseInt(tmpLocation.prefectureId)
        );
        setLocation(tmpLocation);
        setPrefectureId(userDetailInfo.userLocation.prefectureId);
        setCityId(userDetailInfo.userLocation.cityId);
      } else {
        setLocation(tmpLocationObj);
      }

      if (
        userDetailInfo.userFavoriteLocations &&
        userDetailInfo.userFavoriteLocations.length > 0
      ) {
        let tmp = [...userDetailInfo.userFavoriteLocations];
        for (let i = 0; i < tmp.length; i++) {
          tmp[i].filteredCities = citiesList.filter(
            (city) => parseInt(city.prefCode) === parseInt(tmp[i].prefectureId)
          );
        }
        setFavoriteLocations(tmp);
      } else {
        let tmp = [];
        tmp.push(tmpFavLocationObj);
        setFavoriteLocations(tmp);
      }

      setLoginViaSNS(
        userDetailInfo.loginViaSNS ? userDetailInfo.loginViaSNS : false
      );
      setWebPage(userDetailInfo.webPage ? userDetailInfo.webPage : "");

      if (userDetailInfo.userMessage && userDetailInfo.userMessage.length > 0) {
        setMessage(userDetailInfo.userMessage[0].message);
      }

      setCurrentMail(userDetailInfo.email);
    }
  }

  function isUrlValid(userInput) {
    var res = userInput.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (res == null) return false;
    else return true;
  }

  function handleOpenModalEditImg() {
    setOpenModalEditImg(true);
    document.getElementsByTagName("html")[0].classList.add("active-modal");
  }

  function handleCloseModalEditImg() {
    setOpenModalEditImg(false);
    setImageFile(null);
    if (cropper) cropper.destroy();
    setCropper(null);
    setCropRange(1);
    document.getElementsByTagName("html")[0].classList.remove("active-modal");
  }

  function handleOpenModalChangeMail() {
    setOpenModalChangeMail(true);
    document.getElementsByTagName("html")[0].classList.add("active-modal");
  }

  function handleCloseModalChangeMail() {
    setOpenModalChangeMail(false);
    setOpenModalAuthenCode(false);
    setOpenModalCompleteSetting(false);
    document.getElementsByTagName("html")[0].classList.remove("active-modal");
    setEmail("");
    setAuthenCode("");
    setDisableBtnResendCode(true);
  }

  function handleOpenModalChangePass() {
    setOpenModalChangePass(true);
    document.getElementsByTagName("html")[0].classList.add("active-modal");
  }

  function handleCloseModalChangePass() {
    setOpenModalChangePass(false);
    setOpenModalAuthenCode(false);
    setOpenModalCompleteSetting(false);
    document.getElementsByTagName("html")[0].classList.remove("active-modal");
    setEmail("");
    setAuthenCode("");
    setCurrPassword("");
    setPassword("");
    setConfirmPassword("");
  }

  function handleOpenModalChangePhone() {
    setOpenModalChangePhone(true);
    document.getElementsByTagName("html")[0].classList.add("active-modal");
  }

  function handleCloseModalChangePhone() {
    setOpenModalChangePhone(false);
    document.getElementsByTagName("html")[0].classList.remove("active-modal");
    setAuthenCode("");
    setPhone("");
  }

  function handleOpenModalCompleteSetting() {
    setOpenModalCompleteSetting(true);
    document.getElementsByTagName("html")[0].classList.add("active-modal");
  }

  function handleCloseModalCompleteSetting() {
    setOpenModalCompleteSetting(false);
    document.getElementsByTagName("html")[0].classList.remove("active-modal");
  }

  function handleOpenModalAuthenCode() {
    setOpenModalAuthenCode(true);
  }

  function handleCloseModalAuthenCode() {
    setOpenModalAuthenCode(false);
  }

  function handleOpenDeleteConfirmModal() {
    setOpenDeleteConfirmModal(true);
    document.getElementsByTagName("html")[0].classList.add("active-modal");
  }

  function handleCloseDeleteConfirmModal() {
    setOpenDeleteConfirmModal(true);
    document.getElementsByTagName("html")[0].classList.remove("active-modal");
  }

  async function handleCropImage() {
    const resultImgUrl = cropper
      .getCroppedCanvas({
        maxWidth: 4096,
        maxHeight: 4096,
      })
      .toDataURL();
    const blob = toBlob(resultImgUrl, imageFile.name, () => {
      console.log("Upload img error");
    });
    setUploading(true);
    uploadImage("user", userDetails.userId, blob, "public-read")
      .then((data) => {
        console.log("upload response: ", data);
        setImage(data);
        setUploading(false);
        handleCloseModalEditImg();
      })
      .catch(e => {
        console.log('Uploading error:', e);
        setUploading(false);
      });
  }

  // Not allow plus, example abc+100@gmail.com
  // function validEmail(email) {
  //   var pattern = new RegExp(
  //     /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  //   );
  //   return pattern.test(email);
  // }

  function validEmail(email) {
    var pattern = new RegExp(
      /^(("[\w+-\s]+")|([\w+-]+(?:\.[\w+-]+)*)|("[\w+-\s]+")([\w+-]+(?:\.[\w+-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    return pattern.test(email);
  }

  function isPhoneNumberValid(userInput) {
    var pattern = new RegExp(/[^0-9]+/);
    return !pattern.test(userInput);
  }

  async function handleSendVerificationCode() {
    if (validEmail(email)) {
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, { email: email });
        var editData = {
          accountId: accountId,
          displayName: displayName,
          email: email,
          image: image,
          message: message,
        };
        UserService.editUser(props.userInfo.userId, editData);
        setPopupMessage("認証コードが送信されました。");
        handleOpenModalAuthenCode();
        setCurrentMail(email);
      } catch (e) {
        console.log(e);
        if (e.code === 'AliasExistsException') {
          alert('メールアドレスが存在しました。');
        } else {
          alert('認証コードを送信できませんでした。時間をおいて再度お試しください。');
        }
      }
      setDisableBtnResendCode(false);
    } else {
      alert("メールアドレスの形式が正しくありません");
    }
  }

  async function handleSubmitChangeMail() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.verifyUserAttributeSubmit(user, "email", authenCode);
      handleCloseModalChangeMail();
      handleCloseModalAuthenCode();
      handleOpenModalCompleteSetting();
    } catch (e) {
      console.log(e);
      if (e.code === 'InvalidParameterException') {
        alert('メールアドレスの形式が不正です。');
      } else if (e.code === 'CodeMismatchException') {
        alert('入力した認証コードが一致しません');
      } else {
        alert('エラーが発生しました。');
      }
    }
  }

  async function handleResendVerificationCode() {
    if (validEmail(email)) {
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.verifyUserAttribute(user, "email");
        setPopupMessage("メールアドレスへ、認証コードが再送信されました。");
        handleOpenModalAuthenCode();
      } catch (e) {
        console.log(e);
        if (e.code === 'LimitExceededException') {
          alert('認証コードを送信できませんでした。時間をおいて再度お試しください。');
        } else {
          alert('エラーが発生しました。');
        }
      }
    } else {
      alert("メールアドレスの形式が正しくありません");
    }
  }

  async function handleSendVerificationCodeChangePhone() {

    if (isPhoneNumberValid(phone)) {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const countryCode = process.env.REACT_APP_MODE_DEVELOP === "true" ? '+84' : '+81';
        const phoneNumber =
          countryCode + phone.trim().replace(/\D/g, "").substr(1);
        await Auth.updateUserAttributes(user, {
          phone_number: phoneNumber,
        });
        setPopupMessage("認証コードが送信しました。");
        handleOpenModalAuthenCode();
        setCurrentPhone(phone);
      } catch (e) {
        console.log(e);
        alert('エラーが発生しました。');
      }
    } else {
      alert("電話番号の形式が正しくありません");
    }
  }

  async function handleSubmitChangePhone() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.verifyUserAttributeSubmit(user, "phone_number", authenCode);
      handleCloseModalChangePhone();
      handleCloseModalAuthenCode();
      handleOpenModalCompleteSetting();
    } catch (e) {
      console.log(e);
      if (e.code === 'CodeMismatchException') {
        alert('入力した認証コードが一致しません');
      } else {
        alert('エラーが発生しました。');
      }
    }
  }

  function isPasswordValid(value) {
    let errCnt = 0;
    if (value.length >= 8 && value.length <= 36) {
      if (
        !/[A-Z]/.test(value) ||
        !/[a-z]/.test(value) ||
        !/[0-9]/.test(value)
      ) {
        errCnt++;
      }
    } else {
      errCnt++;
    }

    return errCnt === 0 ? true : false;
  }

  async function handleSubmitChangePassword() {
    var currentPass = currPassword.trim();
    var newPass = password.trim();
    var confirmPass = confirmPassword.trim();
    try {
      if (
        isPasswordValid(currentPass) &&
        isPasswordValid(newPass) &&
        newPass === confirmPass
      ) {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, currentPass, newPass);
        handleCloseModalChangePass();
        handleOpenModalCompleteSetting();
      } else {
        if (newPass !== confirmPass) {
          alert("このパスワードは合いません");
        } else {
          alert("正しくご入力ください。");
        }
      }
    } catch (e) {
      console.log(e);
      if (e.code === 'NotAuthorizedException') {
        alert('現在のパスワードが間違っています');
      } else if (e.code === 'TooManyRequestsException') {
        alert('アクセスが集中しています。時間を置いてからお試しください。');
      } else {
        alert('更新に失敗しました。時間をおいて再度お試しください。');
      }
    }
  }

  async function handleDeleteAccount() {
    const userId = props.userInfo.userId;

    setLoading(true);
    try {
      const { status } = await UserService.deleteAccount(userId);

      if (status === 200) {
        setLoading(false);
        setOpenDeleteConfirmModal(false);
        setOpenDeleteSuccessModal(true);
      } else {
        setLoading(false);
        alert("エラーが発生しました。");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert("エラーが発生しました。");
    }
  }

  function handleBackToTopSite() {
    sessionStorage.clear();
    localStorage.clear();

    AuthService.logout().then(() => {
      window.location.href = "/";
    });
  }

  useEffect(() => {
    document.body.className = "mypage";
    initialData(userDetails, listCities);
    getUserPhoneNumber();
  }, [userDetails, listCities]);

  return (
    <>
      <MetaTags>
        <title>マイページ編集｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content="マイページ編集ページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content="マイページ編集｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta name="twitter:description" content="マイページ編集ページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:image" content={window.location.origin + "/assets/img/common/ogp.png"}  />
        <meta name="twitter:site" content="@gochi_meshi" />
        <meta name="twitter:creator" content="@gochi_meshi" />
        <meta name="twitter:image:width" content="300" />
        <meta name="twitter:image:height" content="300" />
        <meta property="og:site_name" content="マイページ編集｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:title" content="マイページ編集｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={window.location.origin + "/assets/img/common/ogp.png"} />
        <meta property="og:description" content="マイページ編集ページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
      </MetaTags>
      <Header />

      <main>
        <div className="breadcrumb">
          <div className="container">
            <ol className="breadcrumb-list">
              <li>
                <a href="/mypage">GOCHIマイページ</a>
              </li>
              <li>
                <span>マイページ編集</span>
              </li>
            </ol>
          </div>
        </div>
        <div className={`container ${loadingUserDetail ? "loading" : ""}`}>
          {/* プロフィール */}
          <section className="section">
            <div className="section-body">
              <div className="profile-group">
                <div className="profile is-edit">
                  <div className="profile-header">
                    <div className="profile-thumbnail">
                      <div className="profile-thumbnail-avatar">
                        <div className="avatar">
                          <img
                            id="image-result"
                            src={image ? image : NO_IMAGE_PROFILE}
                            loading="lazy"
                            alt={displayName}
                          />
                        </div>
                        <input
                          name="img-input"
                          id="img-input"
                          style={{ display: "none" }}
                          type="file"
                          accept="image/png, image/jpeg"
                          // data-target="modal01"
                          onChange={(e) => {
                            const fileName =  e.target.files[0].name;
                            const re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
                            if (!re.exec(fileName)) {
                              alert("画像の形式が正しくありません。");
                              return;
                            }
                            setImageFile(e.target.files[0]);
                            if (e.target.files[0]) {
                              handleOpenModalEditImg();
                            }
                            e.target.value = null;
                          }}
                        />
                        <label
                          className="profile-thumbnail-btn"
                          htmlFor="img-input"
                        >
                          <svg className="icon">
                            <title>変更する</title>
                            <use xlinkHref="/assets/img/common/icon.svg#camera" />
                          </svg>
                        </label>
                      </div>
                      {/*自分以外のユーザーにはみせない*/}
                      {/* <a href="" class="link profile-edit-link">編集する</a> */}
                      {/*自分以外のユーザーにはみせない*/}
                    </div>
                  </div>
                  {/* /.profile-header */}
                  <div className="profile-body">
                    <section className="box box-lg">
                      <div className="box-header">
                        <div className="heading-sm">
                          <div className="title">登録情報の変更</div>
                        </div>
                      </div>
                      <div className="box-body">
                        <div className="fieldset">
                          <div className="fieldset-header">
                            <div className="title">お名前</div>
                            <em className="fieldset-mark">必須</em>
                          </div>
                          <div className="fieldset-body">
                            <div className="field field-lg">
                              <input
                                type="text"
                                placeholder="テキストを入力してください"
                                maxLength={100}
                                value={displayName}
                                onChange={(e) => {
                                  setDisplayName(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {/* /.fieldset */}
                        <div className="fieldset">
                          <div className="fieldset-header">
                            <div className="title">ユーザー名</div>
                            <em className="fieldset-mark">必須</em>
                          </div>
                          <div className="fieldset-body">
                            <div className="field field-lg">
                              <div className="unit">@</div>
                              <input
                                type="text"
                                placeholder="テキストを入力してください"
                                value={accountId}
                                onChange={(e) => {
                                  setAccountId(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {/* /.fieldset */}
                        <div className="fieldset">
                          <div className="fieldset-header">
                            <div className="title">性別</div>
                          </div>
                          <div className="fieldset-body">
                            <div className="field field-radio">
                              <label>
                                <input
                                  className="before-icon"
                                  name="aaa"
                                  type="radio"
                                  checked={gender === GENDER.MALE}
                                  value={GENDER.MALE}
                                  onChange={(e) => onValueChange(e)}
                                />
                                <i className="icon" />
                                男性
                              </label>
                              <label>
                                <input
                                  className="before-icon"
                                  name="aaa"
                                  type="radio"
                                  checked={gender === GENDER.FEMALE}
                                  value={GENDER.FEMALE}
                                  onChange={(e) => onValueChange(e)}
                                />
                                <i className="icon" />
                                女性
                              </label>
                              <label>
                                <input
                                  className="before-icon"
                                  name="aaa"
                                  type="radio"
                                  checked={gender === GENDER.SECRET}
                                  value={GENDER.SECRET}
                                  onChange={(e) => onValueChange(e)}
                                />
                                <i className="icon" />
                                ひみつ
                              </label>
                              <label>
                                <input
                                  className="before-icon"
                                  name="aaa"
                                  type="radio"
                                  checked={gender === GENDER.DO_NOT_SHOW}
                                  value={GENDER.DO_NOT_SHOW}
                                  onChange={(e) => onValueChange(e)}
                                />
                                <i className="icon" />
                                表示しない
                              </label>
                            </div>
                          </div>
                        </div>
                        {/* /.fieldset */}
                        <div className="fieldset">
                          <div className="fieldset-header">
                            <div className="title">生年月日</div>
                          </div>
                          <div className="fieldset-body">
                            <div className="field">
                              <div className="field field-date">
                                <div className="field field-year">
                                  <select
                                    value={birthYear}
                                    onChange={(e) => {
                                      setBirthYear(e.target.value);
                                    }}
                                  >
                                    <option key="0" value="0">
                                      選択
                                    </option>
                                    {years}
                                  </select>
                                  <div className="unit">年</div>
                                </div>
                                <div className="field field-month">
                                  <select
                                    value={birthMonth}
                                    onChange={(e) => {
                                      setBirthMonth(e.target.value);
                                    }}
                                  >
                                    <option key="0" value="0">
                                      選択
                                    </option>
                                    {months}
                                  </select>
                                  <div className="unit">月</div>
                                </div>
                                <div className="field field-date">
                                  <select
                                    value={birthDate}
                                    onChange={(e) => {
                                      setBirthDate(e.target.value);
                                    }}
                                  >
                                    <option key="0" value="0">
                                      選択
                                    </option>
                                    {days}
                                  </select>
                                  <div className="unit">日</div>
                                </div>
                              </div>
                              <div className="field field-checkbox">
                                <label>
                                  <input
                                    className="before-icon"
                                    name="bbbb"
                                    type="checkbox"
                                    checked={isCheckNotShowBirthYear}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setIsCheckNotShowBirthYear(true);
                                      } else {
                                        setIsCheckNotShowBirthYear(false);
                                      }
                                    }}
                                  />
                                  <i className="icon" />
                                  誕生年を表示しない
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* /.fieldset */}
                        <div className="fieldset">
                          <div className="fieldset-header">
                            <div className="title">記念日</div>
                          </div>
                          <div className="fieldset-body">
                            <div
                              id="mypageAnniversary"
                              className="field-group field-group-column"
                            >
                              {anniversaries &&
                                anniversaries.length > 0 &&
                                anniversaries.map((item, index) => {
                                  let aYear = 0;
                                  let aMonth = 0;
                                  let aDay = 0;
                                  if (
                                    item.anniversary &&
                                    item.anniversary !== ""
                                  ) {
                                    let arr = item.anniversary.split("-");
                                    aYear = parseInt(arr[0]);
                                    aMonth = parseInt(arr[1]);
                                    aDay = parseInt(arr[2]);
                                  }
                                  return (
                                    <div
                                      className="field field-icon-right"
                                      key={index}
                                    >
                                      <div className="field field-sm">
                                        <input
                                          type="text"
                                          placeholder="例) 結婚記念日"
                                          maxLength={20}
                                          value={item.title}
                                          onChange={(e) =>
                                            handleChangeTilte(
                                              e.target.value,
                                              index
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="field field-date">
                                        <div className="field field-year">
                                          <select
                                            value={aYear || 0}
                                            onChange={(e) =>
                                              handleUpdateAnniversary(
                                                e.target.value,
                                                index,
                                                "aYear"
                                              )
                                            }
                                          >
                                            <option value="0">選択</option>
                                            {years}
                                          </select>
                                          <div className="unit">年</div>
                                        </div>
                                        <div className="field field-month">
                                          <select
                                            value={aMonth || 0}
                                            onChange={(e) =>
                                              handleUpdateAnniversary(
                                                e.target.value,
                                                index,
                                                "aMonth"
                                              )
                                            }
                                          >
                                            <option value="0">選択</option>
                                            {months}
                                          </select>
                                          <div className="unit">月</div>
                                        </div>
                                        <div className="field field-date">
                                          <select
                                            value={aDay || 0}
                                            onChange={(e) =>
                                              handleUpdateAnniversary(
                                                e.target.value,
                                                index,
                                                "aDay"
                                              )
                                            }
                                          >
                                            <option value="0">選択</option>
                                            {days}
                                          </select>
                                          <div className="unit">日</div>
                                        </div>
                                      </div>
                                      <button
                                        className="field-delete-btn"
                                        type="button"
                                        onClick={() => {
                                          handleRemoveAnniversary(index);
                                        }}
                                      >
                                        <svg className="icon">
                                          <title>削除</title>
                                          <use xlinkHref="/assets/img/common/icon.svg#times-sm" />
                                        </svg>
                                      </button>
                                    </div>
                                  );
                                })}
                            </div>
                            {/* /.field-group */}
                            <button
                              className="field-add-btn"
                              type="button"
                              onClick={() => {
                                handleAddAnniversary();
                              }}
                            >
                              <span className="icon-area">
                                <svg className="icon">
                                  <use xlinkHref="/assets/img/common/icon.svg#plus-sm" />
                                </svg>
                              </span>
                              <span className="text">追加する</span>
                            </button>
                          </div>
                        </div>
                        {/* /.fieldset */}
                        <div className="fieldset">
                          <div className="fieldset-header">
                            <div className="title">苦手な食べ物</div>
                          </div>
                          <div className="fieldset-body">
                            <div className="field">
                              <input
                                type="text"
                                placeholder="テキストを入力してください"
                                maxLength={400}
                                value={unFavoriteFood}
                                onChange={(e) => {
                                  let v = e.target.value;
                                  setUnFavoriteFood(v);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {/* /.fieldset */}
                        <div className="fieldset">
                          <div className="fieldset-header">
                            <div className="title">居住エリア</div>
                          </div>
                          <div className="fieldset-body">
                            <div className="field field-half">
                              <div className="field">
                                <select
                                  value={prefectureId}
                                  onChange={(e) => {
                                    setPrefectureId(e.target.value);
                                    handleUpdateLocation(
                                      e.target.value,
                                      "prefectureLocation"
                                    );
                                  }}
                                >
                                  <option value="">選択</option>
                                  {listPrefectures &&
                                    listPrefectures.length > 0 &&
                                    listPrefectures.map((item, index) => {
                                      return (
                                        <option
                                          value={item.prefectureId}
                                          key={index}
                                        >
                                          {item.prefectureName}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                              <div className="field">
                                <select
                                  value={cityId}
                                  onChange={(e) => {
                                    setCityId(e.target.value);
                                    handleUpdateLocation(
                                      e.target.value,
                                      "cityLocation"
                                    );
                                  }}
                                >
                                  <option value="">選択</option>
                                  {userLocation &&
                                    userLocation.filteredCities &&
                                    userLocation.filteredCities.length > 0 &&
                                    userLocation.filteredCities.map(
                                      (item, index) => {
                                        return (
                                          <option
                                            value={item.cityId}
                                            key={index}
                                          >
                                            {item.cityName}
                                          </option>
                                        );
                                      }
                                    )}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* /.fieldset */}
                        <div className="fieldset">
                          <div className="fieldset-header">
                            <div className="title">よく行くエリア</div>
                          </div>
                          <div className="fieldset-body">
                            <div
                              id="mypageArea"
                              className="field-group field-group-column"
                            >
                              {userFavoriteLocations &&
                                userFavoriteLocations.length > 0 &&
                                userFavoriteLocations.map((item, index) => {
                                  let prefectureId = item.prefectureId || "";
                                  let cityId = item.cityId || "";
                                  let filteredCities =
                                    item.filteredCities || [];
                                  return (
                                    <div
                                      className="field field-half field-icon-right"
                                      key={index}
                                    >
                                      <div className="field">
                                        <select
                                          value={prefectureId}
                                          onChange={(e) => {
                                            handleUpdateFavLocation(
                                              e.target.value,
                                              index,
                                              "favPrefecture"
                                            );
                                          }}
                                        >
                                          <option value="">選択</option>
                                          {listPrefectures &&
                                            listPrefectures.length > 0 &&
                                            listPrefectures.map((item, key) => {
                                              return (
                                                <option
                                                  value={item.prefectureId}
                                                  key={key}
                                                >
                                                  {item.prefectureName}
                                                </option>
                                              );
                                            })}
                                        </select>
                                      </div>
                                      <div className="field">
                                        <select
                                          value={cityId}
                                          onChange={(e) => {
                                            handleUpdateFavLocation(
                                              e.target.value,
                                              index,
                                              "favCity"
                                            );
                                          }}
                                        >
                                          <option value="">選択</option>
                                          {filteredCities &&
                                            filteredCities.length > 0 &&
                                            filteredCities.map((item, key) => {
                                              return (
                                                <option
                                                  value={item.cityId}
                                                  key={key}
                                                >
                                                  {item.cityName}
                                                </option>
                                              );
                                            })}
                                        </select>
                                      </div>
                                      <button
                                        className="field-delete-btn"
                                        type="button"
                                        onClick={() => {
                                          handleRemoveFavLocation(index);
                                        }}
                                      >
                                        <svg className="icon">
                                          <title>削除</title>
                                          <use xlinkHref="/assets/img/common/icon.svg#times-sm" />
                                        </svg>
                                      </button>
                                    </div>
                                  );
                                })}
                            </div>
                            <button
                              className="field-add-btn"
                              type="button"
                              onClick={() => {
                                handleAddFavLocation();
                              }}
                              // href
                            >
                              <span className="icon-area">
                                <svg className="icon">
                                  <use xlinkHref="/assets/img/common/icon.svg#plus-sm" />
                                </svg>
                              </span>
                              <span className="text">追加する</span>
                            </button>
                          </div>
                        </div>
                        {/* /.fieldset */}
                        <div className="fieldset">
                          <div className="fieldset-header">
                            <div className="title">プロフィール</div>
                          </div>
                          <div className="fieldset-body">
                            <div className="field">
                              <textarea
                                placeholder="テキストを入力してください"
                                value={message}
                                onChange={(e) => {
                                  setMessage(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {/* /.fieldset */}
                        <div className="fieldset">
                          <div className="fieldset-header">
                            <div className="title">URL</div>
                          </div>
                          <div className="fieldset-body">
                            <div className="field">
                              <input
                                placeholder="例) 自分のブログ、InstagramなどのURL"
                                value={webPage}
                                type="url"
                                maxLength={190}
                                onChange={(e) => {
                                  setWebPage(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {/* /.fieldset */}
                        {errorMessages.length > 0 &&
                          errorMessages.map((item, index) => {
                            return (
                              <div className="invalid-feedback" key={index}>
                                {item}
                              </div>
                            );
                          })}
                      </div>
                      {/* /.box-body */}
                      <div className="box-footer">
                        <div className="btn-group">
                          <button
                            className="btn btn-rect btn-form-cancel"
                            type="button"
                            onClick={handleCancel}
                          >
                            <div>キャンセル</div>
                          </button>
                          <button
                            className="btn btn-rect btn-form-submit"
                            type="button"
                            onClick={handleSubmit}
                          >
                            <div>保存する</div>
                          </button>
                        </div>
                      </div>
                      {/* /.box-footer */}
                    </section>
                    {/* /.box */}

                    {loginViaSNS === false && (
                      <section className="box box-lg">
                        <div className="box-header">
                          <div className="heading-sm">
                            <div className="title">プロフィールの編集</div>
                          </div>
                        </div>
                        <div className="box-body">
                          <div className="fieldset fieldset-confirm">
                            <div className="fieldset-header">
                              <div className="title">メールアドレス</div>
                            </div>
                            <div className="fieldset-body">
                              <div className="field">{currentMail}</div>
                              <Link
                                to="#"
                                className="fieldset-confirm-modify"
                                onClick={handleOpenModalChangeMail}
                              >
                                変更する
                              </Link>
                            </div>
                          </div>
                          {/* /.fieldset */}
                          <div className="fieldset fieldset-confirm">
                            <div className="fieldset-header">
                              <div className="title">パスワード</div>
                            </div>
                            <div className="fieldset-body">
                              <div className="field">**********</div>
                              <Link
                                to="#"
                                className="fieldset-confirm-modify"
                                onClick={handleOpenModalChangePass}
                              >
                                変更する
                              </Link>
                            </div>
                          </div>
                          {/* /.fieldset */}
                          <div className="fieldset fieldset-confirm">
                            <div className="fieldset-header">
                              <div className="title">電話番号</div>
                            </div>
                            <div className="fieldset-body">
                              <div className="field">{currentPhone}</div>
                              <Link
                                to="#"
                                className="fieldset-confirm-modify"
                                onClick={handleOpenModalChangePhone}
                              >
                                変更する
                              </Link>
                            </div>
                          </div>
                          {/* /.fieldset */}
                        </div>
                      </section>
                    )}

                    <div className="delete-account-box">
                      <p className="delete-account-btn" onClick={() => {
                        handleOpenDeleteConfirmModal()
                      }}>
                        <span>
                          <i className="icon-delete-account"/>
                        </span>
                        <span className="text-btn">アカウントを削除する</span>
                      </p>
                    </div>

                    {/* /.box */}
                  </div>
                  {/* /.profile-body */}
                </div>
                {/* /.profile */}
              </div>
              {/* /.profile-group */}
            </div>
            {/* /.section-body */}
          </section>
          {/* プロフィール */}
        </div>

        <ExplanationMypage />
      </main>

      <Footer />

      <ModalGroup>
        <SituationModal />
        <GiftToModal />
      </ModalGroup>

      <div className="modal-group">
        <div id="modalMask" className="modal-mask" />
        {openModalEditImg && (
          <div
            id="modalEditImg"
            className={`modal modal-profile modal-profile-01 modal-01 modal-avatar ${
              openModalEditImg ? "is-show" : ""
            }`}
            tabIndex={-1}
          >
            <button
              className="modal-btn modal-btn-close"
              type="button"
              onClick={() => {
                handleCloseModalEditImg();
              }}
            >
              <svg className="icon">
                <title>閉じる</title>
                <use xlinkHref="/assets/img/common/icon.svg#times" />
              </svg>
            </button>
            <label className="modal-modify" htmlFor="inputImage">
              <svg className="icon">
                <use xlinkHref="/assets/img/common/icon.svg#angle-left" />
              </svg>
              <div className="text">戻って写真を選び直す</div>
            </label>
            <div className="modal-body">
              {/* <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/cropperjs/1.5.11/cropper.min.css"
                integrity="sha512-NCJ1O5tCMq4DK670CblvRiob3bb5PAxJ7MALAz2cV40T9RgNMrJSAwJKy0oz20Wu7TDn9Z2WnveirOeHmpaIlA=="
                crossOrigin="anonymous"
                referrerPolicy="no-referrer"
              /> */}
              <div className="crop-area">
                <div className="crop">
                  <img
                    id="image-crop-preview"
                    src={image ? image : NO_IMAGE_PROFILE}
                    alt="anh"
                  />
                </div>
                <div className="crop-range">
                  <svg className="icon">
                    <title>拡大</title>
                    <use xlinkHref="/assets/img/common/icon.svg#search-minus" />
                  </svg>
                  <div className="field field-range field-range-has-progress">
                    <input
                      type="range"
                      id="cropRange"
                      className="field-range-range"
                      min={0.5}
                      max={1.5}
                      value={cropRange}
                      onChange={onChangeCropRange}
                      step="0.01"
                    />
                    <progress
                      id="cropRangeProgress"
                      className="field-range-progress"
                      max="100"
                      value={cropRange * 100 - 50}
                    ></progress>
                  </div>
                  <svg className="icon">
                    <title>縮小</title>
                    <use xlinkHref="/assets/img/common/icon.svg#search-plus" />
                  </svg>
                </div>
              </div>
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    "\n#image-crop-preview {\nwidth: 100%;\nheight: 100%;\n}\n\n.cropper-bg {\nbackground-repeat: repeat;\n}\n.cropper-modal {\nbackground-color: #fff;\nopacity: 0.75;\n}\n.cropper-view-box {\nborder-radius: 100% !important;\noutline: none !important;\n}\n",
                }}
              />
            </div>
            <div className="modal-footer">
              <div className="btn-group">
                <button
                  onClick={handleCropImage}
                  id="image-crop"
                  className={uploading ? 'btn btn-rect loading' : 'btn btn-rect'}
                >
                  決定する
                </button>
              </div>
            </div>
          </div>
        )}

        {openModalChangeMail && (
          <div
            id="changeEmail"
            className={`modal modal-profile modal-profile-02 modal-02 ${
              openModalChangeMail ? "is-show" : ""
            }`}
            tabIndex={-1}
          >
            <button
              className="modal-btn modal-btn-close"
              id="modalClose"
              type="button"
              onClick={handleCloseModalChangeMail}
            >
              <svg className="icon">
                <title>閉じる</title>
                <use xlinkHref="/assets/img/common/icon.svg#times" />
              </svg>
            </button>
            <div className="modal-header heading-lg">
              <div className="title">メールアドレスの変更</div>
            </div>
            <div className="modal-body">
              <div className="fieldset">
                <div className="fieldset-header">
                  <div className="title">変更後メールアドレス</div>
                </div>
                <div className="fieldset-body">
                  <div className="field field-btn-left">
                    <input
                      type="email"
                      placeholder="例) mail@gochi.online"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    <button
                      className="btn btn-rect"
                      type="button"
                      onClick={handleSendVerificationCode}
                    >
                      <div>認証コード送信</div>
                    </button>
                  </div>
                </div>
              </div>
              <div className="fieldset">
                <div className="fieldset-header">
                  <div className="title">認証コードの入力</div>
                </div>
                <div className="fieldset-body">
                  <div className="field field-btn-left">
                    <input
                      type="email"
                      placeholder="認証コードを入力"
                      onChange={(e) => {
                        setAuthenCode(e.target.value);
                      }}
                    />
                    <button
                      className="btn btn-rect"
                      type="button"
                      onClick={handleSubmitChangeMail}
                    >
                      <div>次へ</div>
                    </button>
                  </div>
                  {disableBtnResendCode === false && (
                    <div className="text-sm text-left">
                      <Link
                        to="#"
                        onClick={handleResendVerificationCode}
                      >
                        認証コードを再送する
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {openModalChangePass && (
          <div
            id="changePassword"
            className={`modal modal-profile modal-profile-03 modal-03 ${
              openModalChangePass ? "is-show" : ""
            }`}
            tabIndex={-1}
          >
            <button
              className="modal-btn modal-btn-close"
              id="modalClose"
              onClick={handleCloseModalChangePass}
              type="button"
            >
              <svg className="icon">
                <title>閉じる</title>
                <use xlinkHref="/assets/img/common/icon.svg#times" />
              </svg>
            </button>
            <div className="modal-header heading-lg">
              <div className="title">パスワードの変更</div>
            </div>
            <div className="modal-body">
              <div className="fieldset">
                <div className="fieldset-header">
                  <div className="title">現在のパスワード</div>
                </div>
                <div className="fieldset-body">
                  <div className="field field-btn-left">
                    <input
                      type="password"
                      placeholder="123456"
                      value={currPassword}
                      onChange={(e) => {
                        setCurrPassword(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="fieldset">
                <div className="fieldset">
                  <div className="title">新しいパスワード</div>
                </div>
                <div className="fieldset-body field-btn-left">
                  <div className="field">
                    <input
                      type="password"
                      placeholder={123456}
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="fieldset">
                <div className="fieldset-header">
                  <div className="title">パスワード確認用</div>
                </div>
                <div className="fieldset-body">
                  <div className="field field-btn-left">
                    <input
                      type="password"
                      placeholder={123456}
                      value={confirmPassword}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                    />
                    <button
                      className="btn btn-rect"
                      type="button"
                      onClick={handleSubmitChangePassword}
                    >
                      <div>次へ</div>
                    </button>
                  </div>
                </div>
              </div>
              <ul className="list-note text-red text-sm">
                <li>英大文字・小文字・数字の３種類全てを使ってください。</li>
                <li>８桁以上でご登録ください。</li>
              </ul>
            </div>
          </div>
        )}

        {openModalChangePhone && (
          <div
            id="changePhoneNumber"
            className={`modal modal-profile modal-profile-04 modal-04 ${
              openModalChangePhone ? "is-show" : ""
            }`}
            tabIndex={-1}
          >
            <button
              className="modal-btn modal-btn-close"
              id="modalClose"
              type="button"
              onClick={handleCloseModalChangePhone}
            >
              <svg className="icon">
                <title>閉じる</title>
                <use xlinkHref="/assets/img/common/icon.svg#times" />
              </svg>
            </button>
            <div className="modal-header heading-lg">
              <div className="title">電話番号の変更</div>
            </div>
            <div className="modal-body">
              <p className="text-sm">
                お使いの携帯電話端末にSMS（ショートメッセージサービス）を利用して認証コードを発行します。発行された「SMS認証コード」を入力して端末認証をしてください。
              </p>
              <div className="fieldset">
                <div className="fieldset-header">
                  <div className="title">国コード</div>
                </div>
                <div className="fieldset-body">
                  <div className="field field-btn-left">
                    <input type="text" placeholder="JP +81" disabled />
                  </div>
                </div>
              </div>
              <div className="fieldset">
                <div className="fieldset-header">
                  <div className="title">電話番号</div>
                </div>
                <div className="fieldset-body">
                  <div className="field field-btn-left">
                    <input
                      type="tel"
                      placeholder="000-0000-0000"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                    />
                    <button
                      className="btn btn-rect"
                      type="button"
                      onClick={handleSendVerificationCodeChangePhone}
                    >
                      <div>認証コードを送信</div>
                    </button>
                  </div>
                </div>
              </div>
              <div className="fieldset">
                <div className="fieldset-header">
                  <div className="title">認証コードの入力</div>
                </div>
                <div className="fieldset-body">
                  <div className="field field-btn-left">
                    <input
                      type="email"
                      placeholder="○桁の認証コードを入力"
                      value={authenCode}
                      onChange={(e) => {
                        setAuthenCode(e.target.value);
                      }}
                    />
                    <button
                      className="btn btn-rect"
                      type="button"
                      onClick={handleSubmitChangePhone}
                    >
                      <div>次へ</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {openModalCompleteSetting && (
          <div
            id="completeSetting"
            className={`modal modal-profile modal-profile-05 modal-05 modal-dialog ${
              openModalCompleteSetting ? "is-show" : ""
            }`}
            tabIndex={-1}
          >
            <button
              className="modal-btn modal-btn-close"
              id="modalClose"
              type="button"
              onClick={handleCloseModalCompleteSetting}
            >
              <svg className="icon">
                <title>閉じる</title>
                <use xlinkHref="/assets/img/common/icon.svg#times" />
              </svg>
            </button>
            <div className="modal-body">
              <p className="text-bold">設定が完了しました</p>
            </div>
          </div>
        )}

        {openModalAuthenCode && (
          <div
            id="completeSetting"
            className={`modal modal-profile modal-profile-05 modal-05 modal-dialog ${
              openModalAuthenCode ? "is-show" : ""
            }`}
            tabIndex={-1}
          >
            <button
              className="modal-btn modal-btn-close"
              id="modalClose"
              type="button"
              onClick={handleCloseModalAuthenCode}
            >
              <svg className="icon">
                <title>閉じる</title>
                <use xlinkHref="/assets/img/common/icon.svg#times" />
              </svg>
            </button>
            <div className="modal-body">
              <p className="text-bold">{popupMessage}</p>
            </div>
          </div>
        )}

        {openDeleteConfirmModal && (
          <DeleteAccountConfirmModal
            show={openDeleteConfirmModal}
            onClose={handleCloseDeleteConfirmModal}
            handleDeleteAccount={handleDeleteAccount}
          />
        )}

        {openDeleteSuccessModal && (
          <div
            id="deleteAccountSuccess"
            className={`modal modal-profile modal-profile-01 modal-01 modal-lg history-modal policy-gochi-kid-modal delete-account-success-modal ${
              openDeleteSuccessModal ? "is-show" : ""
            }`}
            tabIndex={-1}
          >
            <div className="modal-body">
              <div className="title">アカウント削除を完了しました。</div>
              <div className="btn-container">
                <button
                  className="btn btn-rect"
                  onClick={() => {
                    handleBackToTopSite()
                  }}
                >
                  TOPに戻る
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <LoadingOverlay loading={loading}/>
    </>
  );
}

const mapStateToProps = (state) => ({ props: state.props });

export default connect(mapStateToProps)(Edit);

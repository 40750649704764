import React, { useState, useRef } from "react";
import moment from 'moment';
import * as constants from '../../../../constants'; 
import CopyToClipboard from "react-copy-to-clipboard";
import { DishMenu } from "./DishMenu";


export function DetailGochi(props) {

  function handleBuyMenu(menuId, shopId) {
    if (menuId) {
      window.open("/menu?id=" + menuId, '_blank');
    } else if (shopId) {
      window.open("/invite?shopId=" + shopId, '_blank');
    }
  }

  const [copied, setCopied] = useState(false);

  var menuId = props.gochiData?.menuId ? props.gochiData.menuId : null;
  var shopId = props.gochiData?.shopId ? props.gochiData.shopId : null;
  var isWishList = props.gochiData?.menu && props.gochiData.menu.isWishList;
  var isFavourite = props.gochiData?.menu && props.gochiData.menu.isFavourite;
  var image = props.gochiData?.gochiBillImage && props.gochiData.gochiBillImage.length > 0 && props.gochiData.gochiBillImage[0].image ?
                  props.gochiData.gochiBillImage[0].image : props.NO_IMAGE_MENU;
  var ticketFree = props.gochiData?.couponFree ? props.gochiData.couponFree : 0;
  var comboDescription = props.gochiData?.menu?.menuComboDetail?.quantityFreeDes;
  var shopName = props.gochiData?.shop?.shopName ? props.gochiData.shop.shopName : null;
  var groupName = props.gochiData?.groupInfo?.groupName ? props.gochiData.groupInfo.groupName : null;
  var menuName = props.gochiData?.menu?.menuName ? props.gochiData.menu.menuName : null;
  var menuPriceName = "お値段ごち";
  var isMenuPrice = props.gochiData?.menuId ? false : true;
  var includedTaxPrice = props.gochiData?.menu?.includedTaxPrice ? props.gochiData.menu.includedTaxPrice : null;
  var amount = props.gochiData?.amount ? props.gochiData.amount : 0;
  var taxAmount = props.gochiData?.taxAmount ? props.gochiData.taxAmount : 0;
  var couponCount = props.gochiData && props.gochiData.couponCount;
  var includedTaxPriceOfMenuPrice = Math.round((amount + taxAmount)/couponCount);
  var gochiCount = props.gochiData && props.gochiData.gochiCount;
  var isMenuCombo = props.gochiData?.menu && props.gochiData.menu.menuCombo === 1 ? true : false;
  var includedTaxPriceCombo = props.gochiData?.menu?.menuComboDetail?.includedTaxPriceCombo ? props.gochiData.menu.menuComboDetail.includedTaxPriceCombo : null;
  var shopAddress = props.gochiData?.shop ? 
                      `${props.gochiData.shop.prefectures}${props.gochiData.shop.city}${props.gochiData.shop.address ? props.gochiData.shop.address : ''}${props.gochiData.shop.building ? props.gochiData.shop.building : ''}` : null;
  var groupAddress = props.gochiData?.groupInfo ? 
                      `${props.gochiData.groupInfo.prefectures}${props.gochiData.groupInfo.city}${props.gochiData.groupInfo.address ? props.gochiData.groupInfo.address : ''}${props.gochiData.groupInfo.building ? props.gochiData.groupInfo.building : ''}` : null;
  
  var gochiShopMessage = props.gochiData?.gochiShopMessage && props.gochiData.gochiShopMessage.length > 0 && props.gochiData.gochiShopMessage[0].shopMessage ? props.gochiData.gochiShopMessage[0].shopMessage : '';
  var gochiUserType = props.gochiData?.gochiUserType;
  var gochiUserName = props.gochiData?.gochiUser?.displayName ? props.gochiData.gochiUser.displayName + 'さん' : '';
  if (gochiUserType === constants.GOCHI_TYPE_ANYONE) {
    gochiUserName = "だれでもごち";
  } else if (gochiUserType === constants.GOCHI_TYPE_NON_USER) {
    gochiUserName = "LINE・SNSで贈る";
  }
  var gochiRegisterName = props.gochiData?.gochiRegister?.displayName ? props.gochiData.gochiRegister.displayName : '';
  var giftCard = props.gochiData?.giftCard;
  var giftCardMessage = props.gochiData?.gochiBillMessage && props.gochiData.gochiBillMessage.length > 0 && props.gochiData.gochiBillMessage[0].message ? props.gochiData.gochiBillMessage[0].message : '';
  var couponMessageObj = props.gochiData?.couponMessage && props.gochiData.couponMessage.length > 0 
                        && props.gochiData.couponMessage.find(({couponMessageId}) => couponMessageId);
  var couponMessage = couponMessageObj?.message;
  var inviteUrl = props.gochiData?.inviteUrl;
  var gochiCode = props.gochiData?.gochiCode;
  var chargeAmount = props.gochiData?.chargeAmount ? props.gochiData.chargeAmount : 0;
  var gochiExpiredDay = props.gochiData?.gochiExpiredDay;
  var today = new Date();
  var gochiExpired =  moment(props.gochiData?.gochiExpired, 'YYYY-MM-DD').toDate();
  today.setHours(0,0,0,0);
  gochiExpired.setHours(0,0,0,0);
  var usableGochi = (gochiCount > 0 && gochiExpired >= today) ? true : false;

  const getListCouponUsedByDay = (listDateUsed, isMenuPrice, includedTaxPriceOfMenuPrice) => {
    let counts = {};
    let list = [];
    listDateUsed.forEach(function (x) {
      counts[x] = (counts[x] || 0) + 1;
    });
    let i = 0;
    console.log(counts);
    for (const [key, value] of Object.entries(counts)) {
      list[i] = {};
      list[i].date = key;
      list[i].number = value;
      list[i].price = isMenuPrice ? includedTaxPriceOfMenuPrice + '円' : '';
      i++;
    }
    return list;
  };

  // get list date used of counpon

  // let isUsed = true;
  let numberCounponUsed = 0;
  let listDateUsed = [];
	let listCouponUsedByDay = [];

  const couponM = props.gochiData?.couponMessage ? props.gochiData.couponMessage : null;
  if (couponM) {
    for (let index = 0; index < couponM.length; index++) {
    if (couponM[index].useDate === null) {
      // isUsed = false;
    } else {
      numberCounponUsed++;
      if (couponM[index].useDate) {
      listDateUsed.push(
        moment(couponM[index].useDate).format('YYYY.MM.DD'),
      );
      }
    }
    }
    listCouponUsedByDay = getListCouponUsedByDay (listDateUsed, isMenuPrice, includedTaxPriceOfMenuPrice);
  }

  var dishInfo = {
    menuId,
    isWishList,
    isFavourite,
    image,
    menuName,
    menuPriceName,
    isMenuCombo,
    ticketFree,
    comboDescription,
    isMenuPrice
  };

  //Please do not format this lines of var text
  var text = `${gochiRegisterName}さんから${shopName || groupName}の${menuName || menuPriceName}のプレゼントが届きました！\rURLをクリックし詳細をご確認ください。\r\r${inviteUrl || ""}
\r\r
以下の手順でご利用ください。\r
\r
①${shopName || groupName}にご来店ください\r
②店内で上記URLを開き「商品お受け取り画面」を表示します\r
③店員さんに画面をタップしていただきます\r
④商品をお受け取りください\r
※お店のご予約は、お客様にてお願いいたします。`;

  const textAreaRef = useRef(null);

  function copyClipboard (e){
    textAreaRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
    return copied;
  }

  function copyClipboardR (){
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
    return copied;
  }

  return (
    <div
      id={5342}
      className={`modal modal-profile modal-profile-01 modal-01 modal-lg ${props.showPopupDetailGochi ? 'is-show' : ''}`}
      tabIndex={-1}
    >
      <button
        className="modal-btn modal-btn-close"
        id="modalClose"
        onClick={props.handleClosePopupDetailGochi}
        type="button"
      >
        <svg className="icon">
          <title>閉じる</title>
          <use xlinkHref="/assets/img/common/icon.svg#times" />
        </svg>
      </button>
      <div className={`modal-body ${props.loadingDetailGochi ? 'loading' : ''}`}>
        <article className="products products-row">
          <DishMenu
            dishInfo={dishInfo}
            handleUpdate={props.handleUpdate}
            loadingDetailGochi={props.loadingDetailGochi}
            fromDetailGochi={true}
          />
          <div className="products-section">
            <div className="products-header">
              <div className="text-sm text-gray">
                {shopName || groupName}
              </div>
              <h2 className="title text-bold">{menuName || menuPriceName}</h2>
            </div>
            <hr />
            <div className="price-area">
              <span className="unit">¥</span>
              <span className="num">
                {props.isFromPurchaseHistory ? (
                  isMenuCombo ? (
                    <>
                      {Math.round(chargeAmount / (couponCount - ticketFree)).toLocaleString()} x {couponCount}枚
                      {ticketFree !== 0 ? ` (おまけ${ticketFree}枚含)` : ''}
                    </>
                  ) : (
                    props.gochiData?.menuLimitTotal ? (
                    <>
                      {includedTaxPrice ? includedTaxPrice.toLocaleString() : includedTaxPriceOfMenuPrice.toLocaleString()}{` x `}{` 1 `}
                    </>) : (
                    <>
                      {Math.round(chargeAmount / couponCount).toLocaleString()}{` x `}{couponCount}
                    </>)
                  )
                ) : (
                  isMenuCombo ? (
                    <>
                      {includedTaxPriceCombo && includedTaxPriceCombo.toLocaleString()} x {couponCount}枚
                      {ticketFree !== 0 ? ` (おまけ${ticketFree}枚含)` : ''}
                    </>
                  ) : (
                    props.gochiData?.menuLimitTotal ? (
                    <>
                      {includedTaxPrice ? includedTaxPrice.toLocaleString() : includedTaxPriceOfMenuPrice.toLocaleString()}{` x `}{` 1 `}
                    </>) : (
                    <>
                      {includedTaxPrice ? includedTaxPrice.toLocaleString() : includedTaxPriceOfMenuPrice.toLocaleString()}{` x `}{couponCount}
                    </>)
                  )
                )}
              </span>
              {/* <span className="dash">〜</span> */}
            </div>
            {isMenuCombo && (
              <div className="badge badge-lg badge-red">回数券</div>
            )}
            {props.isFromPurchaseHistory && (
              <div className="products-btn">
                <div className="btn-group btn-group-right">
                    <button className="btn btn-rect btn-sm" type="button" onClick={() => handleBuyMenu(menuId, shopId)}>
                      もう一度購入する
                    </button>
                </div>
              </div>
            )}
          </div>
        </article>

        <dl className="list-description list-description-lg text-sm mt-40">
          <div className="list-description-item">
            <dt className="title">
              {props.isFromPurchaseHistory ? '購入日': '受け取った日'}
            </dt>
            <dd className="text">
              {props.gochiData?.createdAt ? moment(props.gochiData.createdAt).format('YYYY.MM.DD') : ''}
            </dd>
          </div>

          <div className="list-description-item">
            <dt className="title">店舗住所</dt>
            <dd className="text">
              {shopAddress || groupAddress}
            </dd>
          </div>

          {props.isFromPurchaseHistory && (
            <div className="list-description-item">
              <dt className="title">
                店舗に送った
                <br className="only-pc" />
                コメント
              </dt>
              <dd className="text pre-line">{gochiShopMessage}</dd>
            </div>
          )}

          {props.isFromPurchaseHistory ? (
            <div className="list-description-item">
              <dt className="title">贈り相手</dt>
              <dd className="text">{gochiUserName}</dd>
            </div>
          ) : (
            <div className="list-description-item">
              <dt className="title">贈り主</dt>
              <dd className="text">{gochiRegisterName}さん</dd>
            </div>
          )}
          
          <div className="list-description-item">
            <dt className="title">
              送ったギフト
              <br className="only-pc" />
              カードを表示
            </dt>
            {giftCard && (
              <dd className="text pre-line">
                <div className="thumbnail thumbnail-gift">
                  <img
                    src={giftCard.image}
                    alt="gift card"
                    loading="lazy"
                  />
                </div>
                {giftCardMessage}
              </dd>
            )}
          </div>

          <div className="list-description-item">
            {props.isFromPurchaseHistory ? (
              <dt className="title">
                相手からの返信
                <br className="only-pc" />
                メッセージ
            </dt>
            ) : (
              <dt className="title">
                返信した
                <br className="only-pc" />
                メッセージ
              </dt>
            )}
            <dd className="text pre-line">
              {couponMessage ? couponMessage : ''}
            </dd>
          </div>

          {!props.gochiData?.menuLimitTotal && (
            <>
              <div className="list-description-item">
                <dt className="title">個数</dt>
                <dd className="text">{couponCount}個 ({numberCounponUsed}/{couponCount}使用済)</dd>
              </div>

              <div className="list-description-item">
                <dt className="title">
                  {props.isFromPurchaseHistory ? '利用された日' : '利用した日'}
                </dt>
                <dd className="text">
                  {listCouponUsedByDay ? listCouponUsedByDay.map(function(item, index){
                    return <div key={index.toString()}>{item.date} ({item.price ? item.price + ' x ' + item.number : item.number})</div>
                  }) : null}
                </dd>
              </div>
            </>
          )}

          <div className="list-description-item">
            <dt className="title">ごちコード</dt>
            <dd className="text">
              {gochiCode} <span>（チケットの再発行に必要です）</span>
            </dd>
          </div>
          {props.gochiData?.menu?.menuChildType !== 5 &&
          <div className="list-description-item">
            <dt className="title">利用期限</dt>
            <dd className="text">
              {moment(props.gochiData?.gochiExpired).format('YYYY.MM.DD')} <br className="under-tab" />
              {usableGochi && (
                <em className="text-red">
                  使用期限があと{gochiExpiredDay}日です
                </em>
              )}
            </dd>
          </div>
          }
        </dl>

        {props.isFromPurchaseHistory && (
          <>
            <section className="box">
              <div className="box-header text-center">
                <div className="heading-sm text-center">
                  <div className="title">チケットを利用する</div>
                </div>
                <p>
                  贈り相手が利用できない時に
                  <br className="under-tab" />
                  チケットを譲ることができます
                </p>
                <svg className="icon">
                    <use xlinkHref="/assets/img/common/icon.svg#angle-down"></use>
                </svg>
              </div>

              <div className="box-body">
                <div className="clipboard">
                  <div className="clipboard-body">
                  {gochiRegisterName}さんから{shopName || groupName}の{menuName || menuPriceName}のプレゼントが届きました！<br />
                    URLをクリックし詳細をご確認ください。<br />
                    <br />
                    <a className="link" target="_blank" rel="noopener noreferrer" href={inviteUrl}>{inviteUrl}</a>
                    <br /><br />
                    以下の手順でご利用ください。<br />
                    <br />
                    ①{shopName || groupName}にご来店ください<br />
                    ②店内で上記URLを開き「商品お受け取り画面」を表示します<br />
                    ③店員さんに画面をタップしていただきます<br />
                    ④商品をお受け取りください<br />
                    ※お店のご予約は、お客様にてお願いいたします。
                  </div>
                  <div className="clipboard-footer">
                    <div className="btn-group">
                      {document.queryCommandSupported('copy') ? (
                      <>
                        <button className="btn btn-rect btn-outline btn-black btn-sm" type="button" onClick={copyClipboard}>
                          コピーする
                        </button>
                        <textarea
                          ref={textAreaRef}
                          value={text}
                          style={{position: 'absolute', left: '-5000px', top: '-5000px'}}
                        />
                      </>
                      ) : (
                      <CopyToClipboard text={text}>
                        <button className="btn btn-rect btn-outline btn-black btn-sm" type="button" onClick={copyClipboardR}>
                          コピーする
                        </button>
                      </CopyToClipboard>
                      )}
                    </div>
                    {copied && (
                      <div className="balloon">
                          <div className="text">
                              コピーしました
                          </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              
              <div className="box-footer text-center">
                  <p>LINEやSMSで譲りたい人に送りましょう！</p>
              </div>
            </section>
          </>
        )}

      </div>
    </div>
  );
}

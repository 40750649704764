import React from "react";

export function RegisterUserKidGochiKidSuccess(props) {

  return (
    <div
      className={`modal modal-profile modal-profile-01 modal-01 modal-lg history-modal register-user-kid-success-modal ${props.showPopupRegisterUserKidGochiKidSuccess ? 'is-show' : ''}`}
      tabIndex={-1}
    >
      <div>
        {props.isRegisterUserKidError === true ? props.messageRegisterUserKidError :
          props.numberUserKidInit === 0 ? (
            <>
              利用者登録を完了しました！
              <br />
              登録情報は、「利用者情報を編集」ボタンからご確認ください。
            </>
          ) : (
            <>
              利用者情報編集を完了しました！
              <br />
              登録情報は、「利用者情報を編集」ボタンからご確認ください。
            </>
          )}
      </div>
      <div className="modal-footer">
        <button
          onClick={props.handleClosePopupRegisterUserKidGochiKidSuccess}
          className={'btn btn-rect'}
        >
          マイページに戻る
        </button>
      </div>
    </div>
  );
}

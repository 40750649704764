import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Media from "react-bootstrap/Media";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import ShopService from "../../../api/services/ShopService";
import queryString from 'query-string'; 
import { showError } from "../../../actions/action_error_alert";
const SHOP_NO_IMAGE = require("../../../assets/img/icons/shop01_blank.png");

class ShopInGroup extends Component { 
	constructor(){
	    super(); 
	    this.state = { 
			listShop: [],
			groupId: null,
			latitude: null,
			longitude: null,
			sort: "distance",
			hasMore: false,
			shopCount: 0,
			page: 1,
			keyword: null,
			loading: false,
			page: 1,
			limit: 10,
			loadingLoadMore: false,
			disableBtnSearch: false
        }
		this.loadMoreShop = this.loadMoreShop.bind(this); 
	}

	getListShopInGroup = async (
		userId 
		) => { 
			try {
			  	const { data } = await ShopService.getShopInGroup(userId);
			  	return data;
			} catch (error) { 
			  	console.log('getShopInGroup error'); 
			  	this.props.showError(true, this.props.t("There is something wrong"), "");
			}
	};

	async getListShopsArr({groupId, latitude, longitude, sort, keyword, page, limit }) {
		this.setState({
		loading: true,
		});
		try {
			let params = {
				groupId,
				latitude,
				longitude,
				sort,
				keyword,
				page,
				limit
			};
			if (groupId) {
				let res = await this.getListShopInGroup(params);
				//console.log('res ', res);
				if (res.list) {
					const listShop = [...this.state.listShop, ...res.list];

					this.setState({
					listShop,
					shopCount: res.totalCount,
					});
					if (listShop.length < res.totalCount) {
					this.setState({
						hasMore: true,
					});
					} else {
					this.setState({
						hasMore: false,
					});
					}
				}
			} 
			this.setState({
				loading: false,
			});
		} catch (error) {
			console.log("error getListShops", error);
		this.setState({
			loading: false,
		});
		}
	};

	loadMoreShop() {
		try {
			this.getListShopsArr({
				groupId: this.state.groupId,
				latitude: this.state.latitude,
				longitude: this.state.longitude,
				sort: this.state.sort,
				keyword: this.state.keyword,
				page: this.state.page + 1,
				limit: this.state.limit
			});
		this.setState({
			page: this.state.page + 1,
		});
		} catch (error) {
			console.log("error loadMoreShop", error);
		}
	}

	async searchKeyword() {
		await this.setState({
			page: 1,
		});
		await this.setState({
			listShop: [],
			disableBtnSearch: true
		});
		try {
		await this.getListShopsArr({
			groupId: this.state.groupId,
			latitude: this.state.latitude,
			longitude: this.state.longitude,
			sort: this.state.sort,
			keyword: this.state.keyword,
			page: this.state.page,
			limit: this.state.limit
		});
		this.setState({
			disableBtnSearch: false
		});
		} catch (error) {
			console.log("error loadMoreShop", error);
		}
	}

	setKeyword(event) {
		var value = event.target.value;
		this.setState({ keyword: value });
	}

	async componentDidMount() {
		const pathname = window.location.pathname;
		const parts = pathname.split('/');
		//console.log('queryParam', parts.at(-1));
		const part = parts.at(-1);
		let groupId = null;
		if (part === 'nagoyameshi') {
			groupId = 826;
			this.setState({
				groupId: groupId,
			});
		} else if (part && part !== 'nagoyameshi') {
			groupId = part;
			this.setState({
				groupId: groupId,
			});
		} else {
			this.props.showError(true, this.props.t("There is something wrong"), "");
		}
		this.getListShopsArr({
			groupId: this.state.groupId || groupId,
			latitude: this.state.latitude,
			longitude: this.state.longitude,
			sort: this.state.sort,
			keyword: this.state.keyword,
			page: this.state.page,
			limit: this.state.limit
		})
	}

    render() {   
		const { t } = this.props;
		function goToShopDetailOnGiftSite(shopId) {
			window.open(`/shopdetails/${shopId}`);
		}

		function handleOpenMap(shop) {
			window.open(
				`https://www.google.com/maps/search/?api=1&query=${shop.prefectures ? shop.prefectures + " " : ""}${shop.city ? shop.city + " " : ""}${shop.address ? shop.address + " " : ""}${shop.building}`
			);
		}

        return (
        	<div className="content list-shop-content">
              <div className="lst-shop-cmm">
                <div className="title-lst-shop d-flex justify-content-center align-center fs-22 clr-000000">
                  <span>店舗一覧</span>
                </div>
                <div className="title fs-16">キーワード</div>
                <div className="searchShop">
                  <div className="inputField">
                    <div className="fieldset fieldset-lg">
                      <div className="fieldset-body">
                        <div className="field field-pill field-lg">
                          <input
                            className="fs-18"
                            type="text"
                            placeholder="地域名や店舗名"
                            value={this.state.keyword}
                            onChange={event => this.setKeyword(event)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btnSearchF">
                    <div className="fieldset fieldset-submit">
                      <button
                        type="button"
                        className="btn fs-20 btn-submit search-btn mt-0"
                        onClick={() => this.searchKeyword()}
                        disabled={this.state.disableBtnSearch}
                      >
                        検索
                      </button>
                    </div>
                  </div>
                </div>
                <div className="lst-shops">
                  <div className="d-flex align-items-center mt-4 mb-2">
                    <div className="font-weight-bold fs-18 clr-000000">
                      {t("pages.login.searchResult")}
                    </div>
                    <div className="ml-auto fs-18">
                      {t("pages.login.total", {
                        count: this.state.listShop && this.state.shopCount,
                      })}
                    </div>
                  </div>
                  <ListGroup className="list-items">
                    {this.state.listShop.map(function (shop, key) {
                      return (
                        <ListGroup.Item key={shop.shopId} className="shop-item border-none">
                          <Media>
                            <img width={105} 
                              height={105} 
                              src={(shop.shopImage && shop.shopImage[0] && shop.shopImage[0].image) || SHOP_NO_IMAGE} 
                              alt={shop.shopName} 
                              style={{objectFit: 'contain'}}
                            />
                            <Media.Body className="shop-body pl-3">
                              <h5 onClick={() => goToShopDetailOnGiftSite(shop.shopId)} className="shop-name cursor-pointer clr-2EC1B4 text-break fs-18">{shop.shopName}</h5>
                              <p className="shop-distance fs-16 pr-0 mb-2">
                                <FontAwesomeIcon
                                  icon={faMapMarkerAlt}
                                  className="mr-2 fs-16"
                                />
                                {shop.prefectures ? shop.prefectures + " " : ""}
                                {shop.city ? shop.city + " " : ""}
                                {shop.address ? shop.address + " " : ""}
                                {shop.building}
                              </p>
                              <div onClick={() => handleOpenMap(shop)} className="btn btn-cyan-outline">
                                <FontAwesomeIcon
                                  icon={faMapMarkerAlt}
                                  className="mr-2 fs-16"
                                />
                                <span>MAPはこちらから</span>
                              </div>
                            </Media.Body>
                          </Media>
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>

                  {this.state.hasMore && (
                    <div className="text-center mt-3">
                      <Button
                        className="btn btn-green-38 flex_center new-btn mb-2 btn-cyan"
                        onClick={this.loadMoreShop}
                      >
                        さらに表示する
                      </Button>
                    </div>
                  )}
                </div>
              </div>
			</div>
        ); 
    }
}
 

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
	showError: (showing, title, content, buttonName, gotoUrl) =>dispatch(showError(showing, title, content, buttonName, gotoUrl)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ShopInGroup));
import React from "react";
import { useState } from "react";
import { addParamUrl } from "../../../../utils";
import { GroupSpecialGochiKidDetailModal } from "./GroupSpecialGochiKidDetailModal";
import GochiService from "../../../../api/services/GochiService";

export function GroupSpecialGochiKidModal(props) {
  const [groupSpecialDetail, setGroupSpecialDetail] = useState(null);
  const [groupSpecialImage, setGroupSpecialImage] = useState(null);
  const [showGroupSpecialDetailModal, setShowGroupSpecialDetailModal] =
    useState(false);
  const accessTokenParam = props.accessToken
    ? { name: "accessToken", value: props.accessToken }
    : {};
  const accessCodeParam = props.accessCode
    ? { name: "accessCode", value: props.accessCode }
    : {};

  function handleSubmit() {
    document
      .getElementsByTagName("html")[0]
      .classList.remove("hide-scroll-background-gochi-kid");
    if (props.gochiKidGroupId) {
      if (props.shopIdGochiKidQr && props.accessToken && props.accessCode) {
        window.location.href = addParamUrl(
          `/mypage/kodomo_use/search_shop/${props.shopIdGochiKidQr}?gochiKidGroupId=${props.gochiKidGroupId}`,
          [props.isSiteParam, accessTokenParam, accessCodeParam]
        );
      } else {
        window.location.href = addParamUrl(
          `/mypage/kodomo_use/search_shop?gochiKidGroupId=${props.gochiKidGroupId}`,
          [props.isSiteParam]
        );
      }
    } else {
      if (props.shopIdGochiKidQr && props.accessToken && props.accessCode) {
        window.location.href = addParamUrl(
          `/mypage/kodomo_use/search_shop/${props.shopIdGochiKidQr}?accessToken=${props.accessToken}&accessCode=${props.accessCode}`,
          [props.isSiteParam]
        );
      } else {
        window.location.href = addParamUrl(`/mypage/kodomo_use/search_shop`, [
          props.isSiteParam,
        ]);
      }
    }
  }

  async function handleShowGroupSpecialDetail(group) {
    setGroupSpecialDetail(group);
    if (group) {
      let params = {
        isGroupInfo: 1
      }
      const data = await GochiService.getSponsorGochiKidGroupSpecial(
        group.gochiKidGroupId,
        props.accessToken,
        params
      );
      if (data.status === 200) {
        if (data.data.listRank1 && data.data.listRank1.length > 0) {
          setGroupSpecialImage(data.data.listRank1[0].logoImage);
        } else if (data.data.listRank2 && data.data.listRank2.length > 0) {
          setGroupSpecialImage(data.data.listRank2[0].logoImage);
        } else if (data.data.listRank3 && data.data.listRank3.length > 0) {
          setGroupSpecialImage(data.data.listRank3[0].logoImage);
        } else {
          setGroupSpecialImage(null);
        }
      }
    } else {
      setGroupSpecialImage(null);
    }
    setShowGroupSpecialDetailModal(true);
  }

  return (
    <>
      <div
        className={
          props.showing
            ? "custom-modal-container group-special-gochi-kid-modal show-modal"
            : "custom-modal-container group-special-gochi-kid-modal"
        }
        size="sm"
      >
        <div
          className={
            props.showing
              ? "modal modal-action modal-dialog is-show history-modal"
              : "modal modal-action modal-dialog history-modal"
          }
        >
          <div className="modal-header-history">
            {!(props.accessToken || props.accessCode) && (
              <button
                className="modal-btn modal-btn-close"
                id="modalClose"
                onClick={() => props.handleClose()}
                type="button"
              >
                <svg className="icon">
                  <title>閉じる</title>
                  <use xlinkHref="/assets/img/common/icon.svg#times" />
                </svg>
              </button>
            )}
          </div>
          <div className="title-modal title-modal-charge">
            <div className="title">支援元を選ぶ</div>
          </div>
          <div className={`modal-body`}>
            <div className="group-special-box mb-24px">
              <div className="field-radio radio-box">
                <label className="radio-group-special">
                  <input
                    className="before-icon"
                    name="poorStatus"
                    type="radio"
                    checked={props.gochiKidGroupId === 0}
                    value={0}
                    onChange={(e) =>
                      props.setGochiKidGroupId(parseInt(e.target.value))
                    }
                  />
                  <i className="icon" />
                </label>
              </div>
              <div className="group-special-info">
                <div className="group-special-title">
                  <span>「</span>
                  <span
                    className="group-special-name"
                    onClick={() => handleShowGroupSpecialDetail(null)}
                  >
                    こどもごちめし
                  </span>
                  <span>」</span>
                  <span>を利用する</span>
                </div>
                <div className="number-can-use">
                  利用可能食数{" "}
                  {props.numberSlotCanUseAll <= 1000
                    ? props.numberSlotCanUseAll
                    : "1000+"}
                  食
                </div>
              </div>
            </div>
            {props.listGroupSpecialGochiKid?.length > 0 &&
              props.listGroupSpecialGochiKid?.map((item, index) => {
                if (index !== 0) {
                  return (
                    <div
                      className={`group-special-box ${
                        index < props.listGroupSpecialGochiKid?.length - 1
                          ? "mb-24px"
                          : ""
                      }`}
                      key={item.gochiKidGroupId}
                    >
                      <div className="field-radio radio-box">
                        <label className="radio-group-special">
                          <input
                            className="before-icon"
                            name="poorStatus"
                            type="radio"
                            checked={props.gochiKidGroupId === item.gochiKidGroupId}
                            value={item.gochiKidGroupId}
                            onChange={(e) =>
                              props.setGochiKidGroupId(parseInt(e.target.value))
                            }
                          />
                          <i className="icon" />
                        </label>
                      </div>
                      <div className="group-special-info">
                        <div className="group-special-title">
                          <span>「</span>
                          <span
                            className="group-special-name"
                            onClick={() => handleShowGroupSpecialDetail(item)}
                          >
                            {item.gochiKidGroupName}
                          </span>
                          <span>」</span>
                          <span>を利用する</span>
                        </div>
                        <div className="number-can-use">
                          利用可能食数{" "}
                          {item.numberSlotCanUse <= 1000
                            ? item.numberSlotCanUse
                            : "1000+"}
                          食
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            <div
              className={`history-load-more ${
                props.loadingListGroupSpecialGochiKid
                  ? "text-decoration-none"
                  : ""
              }`}
            >
              {props.pageListGroupSpecialGochiKid + 1 <=
                Math.ceil(props.totalGroupSpecialGochiKid / props.limit) && (
                <span
                  className={`cursor-pointer fs-15 align-center ${
                    props.loadingListGroupSpecialGochiKid ? "loading" : ""
                  }`}
                  onClick={() => props.loadMore(props.shopIdGochiKidQr)}
                >
                  ▼ もっと見る
                </span>
              )}
            </div>
            <div className="modal-footer">
              <button
                onClick={() => handleSubmit()}
                id="submitConfirm"
                className="btn btn-rect btn-submit"
              >
                選択して次に進む
              </button>
            </div>
          </div>
        </div>
        <div
          onClick={() => props.handleClose()}
          className={`back-drop ${
            props.accessToken && props.accessCode ? "back-drop-bold" : ""
          }`}
        />
      </div>

      <GroupSpecialGochiKidDetailModal
        showing={showGroupSpecialDetailModal}
        handleClose={() => {
          setShowGroupSpecialDetailModal(false);
        }}
        groupSpecialDetail={groupSpecialDetail}
        groupSpecialImage={groupSpecialImage}
      />
    </>
  );
}

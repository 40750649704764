import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { showWishListModal } from "../../../actions/action_show_wish_list_modal";

export function WishListModal(props) {
  const showing = useSelector((state) => state.showWishListModal.showing);
  const isFavorite = useSelector((state) => state.showWishListModal.isFavorite);
  
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (showing) {
      window.openModal && window.openModal("actionModal");
    } else {
      window.disappearModal && window.disappearModal();
    }
  }, [showing]);

  return (
    <div
      id="actionModal"
      className="modal modal-action modal-dialog"
      tabIndex={-1}
    >
      <button
        className="modal-btn modal-btn-close"
        id="modalClose"
        onClick={() => dispatch(showWishListModal(false))}
        type="button"
      >
        <svg className="icon">
          <title>閉じる</title>
          <use xlinkHref="/assets/img/common/icon.svg#times" />
        </svg>
      </button>
      <div className="modal-body">
        {
          isFavorite ? (
            <p>
              お気に入りに登録しました！
              <br />
              マイページで確認できます。
            </p>
          ) :  (
            <p>
              欲しい物リストに登録しました！
              <br />
              マイページで確認できます。
            </p>
          )
        }
       
      </div>
      <div className="modal-footer">
        <div className="btn-group">
          <div
            onClick={() => {
              dispatch(showWishListModal(false));
              window.disappearModal && window.disappearModal();
              // history.push("/mypage");
              window.location.href="/mypage"
            }}
            className="btn btn-rect btn-outline"
          >
            マイページ
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { useHistory } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import GiftService from "../../../../api/services/GiftService";
import {
  getDate,
  getMonth,
  replaceString,
  useScript,
  useUserDetails,
} from "../../../../utils";
import { ListMenus } from "../../common/ListMenus";
import { ModalGroup } from "../../common/ModalGroup";
import { NotLoggedModal } from "../../common/NotLoggedModal";
import { Footer } from "../../layout/Footer";
import Header from "../../layout/Header";
import { WishListModal } from "../../common/WishListModal";
import { Explanation } from "../../layout/Explanation";

const NO_IMAGE_PROFILE = require("../../../../assets/img/icons/profile_blank.png");

export function OtherUser(props) {
  useScript("/assets/js/app.js");

  useEffect(() => {
    document.body.className = "mypage";
  }, []);

  const history = useHistory();
  const [loadingUserDetail, setLoadingUserDetail] = useState(true);
  const [loadingWishList, setLoadingWishList] = useState(false);
  const match = useRouteMatch();
  const userId = match.params.userId;
  const userInfo = useSelector((state) => state.userInfo);
  const isLoggedIn = userInfo && userInfo.userId;
  const listPrefectures = useSelector((state) => state.prefectures);
  const listCities = useSelector((state) => state.cities);
  const [seeMore, setSeeMore] = useState(true);
  const [wishList, setWishList] = useState([]);
  const [tempFollow, setTempFollow] = useState(null);

  const [countFollower, setCountFollower] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    getWishList(userId);
  }, [userId]);

  function scrollToWishList() {
    const wishListEl = document.getElementById("user-wishlist");
    wishListEl.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }

  const userDetails = useUserDetails(
    userId,
    userInfo.userId,
    () => {
      setLoadingUserDetail(false);
    },
    (e) => {
      history.push("/404");
    }
  );

  useEffect(() => {
    setCountFollower(userDetails && userDetails.followerCount);
  }, [userDetails]);

  function getTitle() {
    return `${userDetails?.displayName || ""}｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス`;
  }

  function getMetaDescription() {
    return `${userDetails?.displayName}のマイページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。`;
  }

  function getPrefectureName(prefectures, id) {
    const result = prefectures.find(({ prefectureId }) => prefectureId === id);
    return result && result.prefectureName ? result.prefectureName : "";
  }

  function getCityName(cities, id) {
    const result = cities.find(({ cityId }) => cityId === id);
    return result && result.cityName ? result.cityName : "";
  }

  function getWishList(userId) {
    setLoadingWishList(true);
    GiftService.getUserWishList(userId, 1, 10000).then((response) => {
      console.log("wishlist ==", response);
      if (response.status === 200) {
        setWishList(response.data.list);
      } else {
        setWishList([]);
      }
      setLoadingWishList(false);
    });
  }

  function onUnfollowUser(targetFollowId) {
    // if (!isLoggedIn) {
    //   dispatch(showNotLoggedModal(true));
    //   return;
    // }
    GiftService.unfollowUser(targetFollowId).then((response) => {
      if (response.status === 200) {
        setTempFollow(false);
        setCountFollower(countFollower - 1);
      }
    });
  }

  function onFollowUser(targetFollowId) {
    // if (!isLoggedIn) {
    //   dispatch(showNotLoggedModal(true));
    //   return;
    // }
    GiftService.followUser(targetFollowId).then((response) => {
      if (response.status === 200) {
        setTempFollow(true);
        setCountFollower(countFollower + 1);
      }
    });
  }

  function displayBirthDay(birthdayStr) {
    let birth = new Date(birthdayStr);
    let isCheckNotShowBirthYear = false;

    birth.setHours(0, 0, 0, 0);
    if (birth.getFullYear() < 1900) {
      birth.setFullYear(birth.getFullYear() + 900);
      isCheckNotShowBirthYear = true;
    }
    let bYear = birth.getFullYear();
    let bMonth = birth.getMonth() + 1;
    let bDay = birth.getDate();
    if (bMonth < 10) {
      bMonth = `0${bMonth}`;
    }
    if (bDay < 10) {
      bDay = `0${bDay}`;
    }

    return isCheckNotShowBirthYear
      ? `${bMonth}月${bDay}日`
      : `${bYear}年${bMonth}月${bDay}日`;
  }

  return (
    <>
      <MetaTags>
        <title>{getTitle()}</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content={getMetaDescription()} />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content={getTitle()} />
        <meta name="twitter:description" content={getMetaDescription()} />
        <meta name="twitter:image" content={window.location.origin + "/assets/img/common/ogp.png"}  />
        <meta name="twitter:site" content="@gochi_meshi" />
        <meta name="twitter:creator" content="@gochi_meshi" />
        <meta name="twitter:image:width" content="300" />
        <meta name="twitter:image:height" content="300" />
        <meta property="og:site_name" content={getTitle()} />
        <meta property="og:title" content={getTitle()} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={window.location.origin + "/assets/img/common/ogp.png"} />
        <meta property="og:description" content={getMetaDescription()} />
      </MetaTags>
      <Header />

      <main>
        <div className="breadcrumb">
          <div className="container">
            <ol className="breadcrumb-list">
              <li>
                <a href="/mypage">GOCHIマイページ</a>
              </li>
              <li>
                <span>{userDetails?.displayName}</span>
              </li>
              {/* {parseInt(userId) !== parseInt(userInfo.userId) && (
                <li>
                  <span>{userDetails?.displayName}</span>
                </li>
              )} */}
            </ol>
          </div>
        </div>
        <div className={`container ${loadingUserDetail ? "loading" : ""}`}>
          {/* プロフィール */}
          <section className="section">
            <div className="section-body">
              <div className="profile-group">
                <div className="profile">
                  <div className="profile-header">
                    <div className="profile-thumbnail">
                      <div className="profile-thumbnail-avatar">
                        <div className="avatar">
                          <img
                            id="image-result"
                            src={
                              userDetails &&
                              userDetails.userImage &&
                              userDetails.userImage.length > 0 &&
                              userDetails.userImage[0].image
                                ? userDetails.userImage[0].image
                                : NO_IMAGE_PROFILE
                            }
                            loading="lazy"
                            alt={
                              userDetails && userDetails.displayName
                                ? userDetails.displayName
                                : ""
                            }
                          />
                        </div>
                      </div>
                      {/*自分以外のユーザーにはみせない*/}
                      {/* <a href="" class="link profile-edit-link">編集する</a> */}
                      {/*自分以外のユーザーにはみせない*/}
                    </div>
                    <section className="profile-header-section">
                      <div className="heading-lg">
                        <h2 className="profile-name">
                          {userDetails?.displayName}
                        </h2>
                        <div className="only-pc">
                          <div className="profile-follow-btn-group">
                            {isLoggedIn &&
                              parseInt(userId) !== parseInt(userInfo.userId) &&
                              ((
                                tempFollow !== null
                                  ? tempFollow
                                  : userDetails?.follow
                              ) ? (
                                <button
                                  onClick={() => {
                                    onUnfollowUser(userDetails?.userId);
                                  }}
                                  className="btn btn-sm btn-rect profile-follow-btn"
                                >
                                  フォロー中
                                </button>
                              ) : (
                                <button
                                  onClick={() => {
                                    onFollowUser(userDetails?.userId);
                                  }}
                                  className="btn btn-sm btn-outline btn-rect profile-follow-btn"
                                >
                                  フォローする
                                </button>
                              ))}

                            <button
                              onClick={scrollToWishList}
                              className="btn btn-sm btn-rect profile-follow-btn"
                            >
                              欲しい物リストを見る
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="profile-id">
                        @{userDetails?.accountId}
                      </div>
                      <dl className="profile-info-list">
                        <div className="profile-info-item">
                          <dt className="mark">
                            <svg className="icon">
                              <use xlinkHref="/assets/img/common/icon.svg#map-marker" />
                            </svg>
                          </dt>
                          <dd className="text">
                            {userDetails &&
                            userDetails.userLocation &&
                            userDetails.userLocation.prefectureId
                              ? listPrefectures &&
                                listPrefectures.length > 0 &&
                                getPrefectureName(
                                  listPrefectures,
                                  userDetails.userLocation.prefectureId
                                )
                              : ""}
                          </dd>
                        </div>
                        <div className="profile-info-item">
                          <dt className="mark">
                            <svg className="icon">
                              <use xlinkHref="/assets/img/common/icon.svg#link" />
                            </svg>
                          </dt>
                          <dd className="text">
                            <a
                              href={
                                userDetails && userDetails.webPage
                                  ? userDetails.webPage
                                  : ""
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {userDetails && userDetails.webPage
                                ? userDetails.webPage
                                : ""}
                            </a>
                          </dd>
                        </div>
                      </dl>
                    </section>
                  </div>
                  <div className="profile-body">
                    <div className="profile-friends pc">
                      <span className="link">
                        フォロー
                        <span className="text-underline number">
                          {userDetails?.followCount || 0}
                        </span>
                      </span>
                      <span className="link">
                        フォロワー
                        <span className="text-underline number">
                          {countFollower}
                        </span>
                      </span>
                    </div>
                    {/*自分以外のユーザーにはみせない*/}
                    {/* <div class="mt-30 profile-nav-btn-group">
                              <a href="" class="bg-white btn btn-sm btn-outline btn-rect btn-black bg-white profile-nav-btn">購入履歴</a>
                              <a href="" class="bg-white btn btn-sm btn-outline btn-rect btn-black profile-nav-btn">お支払い情報</a>
                              <a href="" class="bg-white btn btn-sm btn-outline btn-rect btn-black profile-nav-btn">領収書一覧</a>
                          </div> */}
                    {/*自分以外のユーザーにはみせない*/}
                    <div className="mt-30 profile-follow-btn-group">
                      {isLoggedIn &&
                        parseInt(userId) !== parseInt(userInfo.userId) &&
                        ((
                          tempFollow !== null ? tempFollow : userDetails?.follow
                        ) ? (
                          <button
                            onClick={() => {
                              onUnfollowUser(userDetails?.userId);
                            }}
                            className="btn btn-sm btn-rect profile-follow-btn under-tab"
                          >
                            フォロー中
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              onFollowUser(userDetails?.userId);
                            }}
                            className="btn btn-sm btn-outline btn-rect profile-follow-btn under-tab"
                          >
                            フォローする
                          </button>
                        ))}
                      <button
                        onClick={scrollToWishList}
                        className="btn btn-sm btn-rect profile-follow-btn under-tab"
                      >
                        欲しい物リストを見る
                      </button>
                      <CopyToClipboard text={window.location.href}>
                        <button
                          className="btn btn-sm btn-rect profile-follow-btn"
                          data-clipboard-text="URLをコピー"
                          id="mypageCopy"
                        >
                          マイページのリンクをコピーして共有する
                        </button>
                      </CopyToClipboard>
                    </div>
                    <div className="profile-about-group">
                      <dl className="profile-about-list">
                        <div className="profile-about-item">
                          <dt className="title">誕生日</dt>
                          <dd className="text">
                            {userDetails &&
                              userDetails.birthday &&
                              userDetails.birthday !== "" &&
                              displayBirthDay(userDetails.birthday)}
                          </dd>
                        </div>
                        <div className="profile-about-item">
                          <dt className="title">記念日</dt>
                          <dd className="text">
                            {userDetails &&
                              userDetails.userAnniversary &&
                              userDetails.userAnniversary.length > 0 &&
                              userDetails.userAnniversary.map((item, index) => {
                                return (
                                  <>
                                    {item.anniversary !== ""
                                      ? `${getMonth(
                                          item.anniversary
                                        )}月${getDate(item.anniversary)}日`
                                      : ""}
                                    {item.title && item.title !== ""
                                      ? `(${item.title})`
                                      : ""}
                                    {`	`}
                                  </>
                                );
                              })}
                          </dd>
                        </div>
                        <div className="profile-about-item">
                          <dt className="title">苦手な食べ物</dt>
                          <dd className="text">
                            {userDetails && userDetails.unFavoriteFood}
                          </dd>
                        </div>
                        <div className="profile-about-item">
                          <dt className="title">よく行くエリア</dt>
                          <dd className="text">
                            {userDetails &&
                              userDetails.userFavoriteLocations &&
                              userDetails.userFavoriteLocations.length > 0 &&
                              userDetails.userFavoriteLocations.map(
                                (item, index) => {
                                  let prefName =
                                    listPrefectures &&
                                    listPrefectures.length > 0 &&
                                    getPrefectureName(
                                      listPrefectures,
                                      item.prefectureId
                                    );
                                  let cityName =
                                    listCities &&
                                    listCities.length > 0 &&
                                    getCityName(listCities, item.cityId);
                                  if (
                                    index ===
                                    userDetails.userFavoriteLocations.length - 1
                                  ) {
                                    return `${prefName} ${cityName}`;
                                  }
                                  return `${prefName} ${cityName}、`;
                                }
                              )}
                          </dd>
                        </div>
                      </dl>
                      {userDetails &&
                        userDetails.userMessage &&
                        userDetails.userMessage.length > 0 &&
                        userDetails.userMessage[0].message && (
                          <div
                            className="profile-comment"
                            style={{ whiteSpace: "pre-line" }}
                          >
                            {userDetails.userMessage[0].message.length <= 400 &&
                              userDetails.userMessage[0].message}
                            {userDetails.userMessage[0].message.length >
                              400 && (
                              <>
                                {seeMore
                                  ? replaceString(
                                      userDetails.userMessage[0].message,
                                      400
                                    )
                                  : userDetails.userMessage[0].message}
                                <a
                                  href
                                  type="button"
                                  onClick={() => {
                                    setSeeMore(!seeMore);
                                  }}
                                >
                                  {seeMore ? "全てを見る" : "少なく見る"}
                                </a>
                              </>
                            )}
                          </div>
                        )}
                    </div>
                    {/*自分宛てごち*/}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* プロフィール */}
        </div>
        <aside id="user-wishlist">
          {/*欲しいものリスト*/}
          <ListMenus
            title="欲しいものリスト"
            data={wishList}
            loadingWishList={loadingWishList}
          />
          {/*欲しいものリスト*/}
        </aside>

        <Explanation />
      </main>

      <Footer />

      <ModalGroup>
        <NotLoggedModal />
        <WishListModal />
      </ModalGroup>
    </>
  );
}

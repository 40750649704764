import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { Footer } from "../../layout/Footer";
import Header from "../../layout/Header";
import GiftService from "../../../../api/services/GiftService";
import InfiniteScroll from "react-infinite-scroller";
import { Comment } from "../../common/Comment";
import {
  useScript,
  useStyleSheet,
} from "../../../../utils";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { Explanation } from "../../layout/Explanation";

export function ShopComments(props) {
  const match = useRouteMatch();
  const shopId = match.params.shopId;
  const [listComments, setListComments] = useState([]);
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [shopDetails, setShopDetails] = useState({});
  const LIMIT = 10;

  useStyleSheet("https://unpkg.com/swiper/swiper-bundle.min.css",
    null,
    {
      integrity:
        "sha384-HUXJxzkMpCxEdXkgl8BSjN5SUcUODepsOLIYIWOtKva+Qo2teMmV1mh6ZVwnthaV",
      crossorigin: "anonymous",
      referrerpolicy: "no-referrer",
    }
  );
  useScript("https://unpkg.com/swiper/swiper-bundle.min.js",
    null,
    { integrity: "sha384-XVTmRHMO2XUTzy8IerJVEy8ddg47Yz5pZrLc7nEWipaTowqT1xNb8qTLaw64Bd4S",
      crossorigin: "anonymous"
    }
  );
  useScript("/assets/js/app.js");

  useEffect(() => {
    window.scrollTo(0, 0);
    getComments(1);
    getShopDetails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getTitle() {
    return "コメント一覧｜" + (shopDetails.shopName || "") + "｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス";
  }

  function getMetaDescription() {
    return `${shopDetails.shopName}のコメント一覧です。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。`;
  }

  function getShopDetails() {
    GiftService.getShopDetails(shopId)
      .then((response) => {
        if (response.status === 200) {
          setShopDetails(response.data);
        } else if (response.status === 404) {
          // TODO
        }
      })
      .catch((e) => {
        console.error("GET SHOP DETAILS ERROR: ", e);
      });
  }

  function getComments(page) {
    GiftService.getListCouponMessagesOfShop({
      page: page,
      perPage: LIMIT,
      shopId,
    })
      .then((response) => {
        if (response.status === 200) {
          setTotalResults(response.data.total);
          setListComments((old) => [...old, ...response.data.list]);
          setPage(page);
        }
      })
      .catch((e) => {
        console.error("GET LIST COUPON MESSAGES ERROR: ", e);
      });
  }

  function loadMore() {
    if (hasMore) {
      getComments(page + 1);
    }
  }

  const hasMore = listComments.length < totalResults;

  return (
    <>
      <MetaTags>
        <title>{getTitle()}</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content={getMetaDescription()} />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content={getTitle()} />
        <meta name="twitter:description" content={getMetaDescription()} />
        <meta name="twitter:image" content={window.location.origin + "/assets/img/common/ogp.png"}  />
        <meta name="twitter:site" content="@gochi_meshi" />
        <meta name="twitter:creator" content="@gochi_meshi" />
        <meta name="twitter:image:width" content="300" />
        <meta name="twitter:image:height" content="300" />
        <meta property="og:site_name" content={getTitle()} />
        <meta property="og:title" content={getTitle()} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={window.location.origin + "/assets/img/common/ogp.png"} />
        <meta property="og:description" content={getMetaDescription()} />
      </MetaTags>
      <Header />

      <main>
        <div className="breadcrumb">
          <div className="container">
            <ol className="breadcrumb-list">
              <li>
                <a href="/">ごちめし</a>
              </li>
              {shopDetails.prefectures && (
                <li>
                  <Link to={`/shops?prefecture=${shopDetails.prefectures}`}>
                    {shopDetails.prefectures}
                  </Link>
                </li>
              )}
              {shopDetails.city && (
                <li>
                  <Link
                    to={`/shops?prefecture=${shopDetails.prefectures}&city=${shopDetails.city}`}
                  >
                    {shopDetails.city}
                  </Link>
                </li>
              )}
              {shopDetails.shopName && (
                <li>
                  <Link to={`/shopdetails/${shopId}`}>
                    {shopDetails.shopName}
                  </Link>
                </li>
              )}
              <li>コメント一覧</li>
            </ol>
          </div>
        </div>
        <div className="container">
          <section className="section">
            <div className="section-header">
              <div className="heading-xl">
                <h1 className="title">{shopDetails.shopName}のコメント</h1>
              </div>
            </div>
            {listComments.length > 0 && (
              <div className="section-body">
                <InfiniteScroll
                  loadMore={loadMore}
                  hasMore={hasMore}
                  loading={<h2>Loading...</h2>}
                >
                  {listComments.map((item) => (
                    <Comment comment={item.shopMessage} key={item.couponId} />
                  ))}
                </InfiniteScroll>
              </div>
            )}
          </section>
        </div>

        <Explanation />
      </main>

      <Footer />
    </>
  );
}

import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import {connect} from "react-redux";
import {Redirect} from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import firebase from 'firebase/app';
import 'firebase/auth';

//Login by cognito
import { Auth } from 'aws-amplify';

import {log, addParamUrl} from '../../../utils';
import AuthService from "../../../api/services/AuthService";
import UserService from "../../../api/services/UserService";
import {showError} from "../../../actions/action_error_alert";
import {saveUserInfo} from "../../../actions/action_users";
import {showLoading} from "../../../actions/action_loading";
import PopupRegisConfirm from "../../../components/common/PopupRegisConfirm"; 
import LogService from "../../../api/services/LogService";
import LoadingOverlay from "../../GiftSite/common/LoadingOverlay";

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirectTo: false,
			errorMessage: null,
			menuId: null,
			shopId: null,
			showPassword: false,
			goLogin: false,
			popupShowing: false,
			popupTitle: this.props.t('pages.register.title popup apple'),
			popupContent: this.props.t('pages.register.content popup apple'),
            src: "",
			paramShashoku: '',
			userNotConfirmed: false,
			confirmCode: '',
			cognitoUser: {},
			showListShopPopup: false,
			loginCallbackUrl: null,
			showOtpInput: false,
			loading: false,
			isSite: '',
      showMoreText: false
		};
		this.onEmailChange = this.onEmailChange.bind(this);
		this.onPasswordChange = this.onPasswordChange.bind(this);
		this.onConfirmCodeChange = this.onConfirmCodeChange.bind(this);
		this.onShowHidePass = this.onShowHidePass.bind(this);
		this.loginByGochiAccount = this.loginByGochiAccount.bind(this);
		this.loginByFacebook = this.loginByFacebook.bind(this);
		this.loginByApple = this.loginByApple.bind(this);
		this.loginByGoogle = this.loginByGoogle.bind(this);
		this.loginAsGuestAccount = this.loginAsGuestAccount.bind(this);
		this.confirmLogin = this.confirmLogin.bind(this);
		this.getUserAttributes = this.getUserAttributes.bind(this);
		this.requestAuthentication = this.requestAuthentication.bind(this);
		this.changeUserLogin = this.changeUserLogin.bind(this);
		this.goToRegister = this.goToRegister.bind(this);
		this.changeGuestLogin = this.changeGuestLogin.bind(this);
		this.handlePopupConfirm = this.handlePopupConfirm.bind(this);
		this.showAppleConfirm = this.showAppleConfirm.bind(this);
		this.handlePopupClose = this.handlePopupClose.bind(this);
		this.redirectToCallbackUrl = this.redirectToCallbackUrl.bind(this);
	}

	async loginByGoogle() {
		this.setState({ loading: true });
		var provider = new firebase.auth.GoogleAuthProvider();
		firebase.auth().useDeviceLanguage();

		try {
			var result = await firebase.auth().signInWithPopup(provider);
			var userGoogle = result.user;
			log(['LOGIN USER GOOGLE: ', userGoogle]);
			const googleUserProvider = userGoogle.providerData.find( googleProvider => googleProvider.providerId === 'google.com' );
			var cognitoUserId = 'Google_' + googleUserProvider.uid;

			//update user to gochimeshi server
			await this.getUserAttributes(cognitoUserId, "GOOGLE", {
				username: googleUserProvider.displayName || (userGoogle.displayName || ""),
				email: googleUserProvider.email || (userGoogle.email || ""),
			});
		} catch (e) {
			log(e);
			if (e.code === "auth/account-exists-with-different-credential")
			{
				this.setState({
					errorMessage: this.props.t('pages.register.An account already exists with the same email address')
				})
			}else {
				this.setState({
					errorMessage: this.props.t('pages.register.Login has been canceled')
				})
			}
			LogService.sendLog({ loginType: 'loginByGoogle', 'code': e.code });
		}
		this.setState({ loading: false });
	}

	async loginByApple() {
		this.setState({ loading: true });
		this.setState({
			popupShowing: false,
		})
		var provider = new firebase.auth.OAuthProvider('apple.com');
		firebase.auth().useDeviceLanguage();

		try {
			var result = await firebase.auth().signInWithPopup(provider);
			var userApple = result.user;
			log(['LOGIN USER APPLE: ', userApple]);
			const appleUserProvider = userApple.providerData.find( appleProvider => appleProvider.providerId === 'apple.com' );
			var cognitoUserId = 'SignInWithApple_' + appleUserProvider.uid;

			//update user to gochimeshi server
			await this.getUserAttributes(cognitoUserId, "APPLE", {
		      username: appleUserProvider.displayName || (userApple.displayName || ""),
		      email: appleUserProvider.email || (userApple.email || ""),
		    });
		} catch (e) {
	  		log(e);
			if (e.code === "auth/account-exists-with-different-credential")
			{
				this.setState({
					errorMessage: this.props.t('pages.register.An account already exists with the same email address')
				})
			}else {
				this.setState({
					errorMessage: this.props.t('pages.register.Login has been canceled')
				})
			}
			LogService.sendLog({ loginType: 'loginByApple', 'code': e.code });
		}
		this.setState({ loading: false });
	}

	async loginByFacebook() {
		this.setState({ loading: true });
		var provider = new firebase.auth.FacebookAuthProvider();
		// provider.addScope('user_birthday');

		firebase.auth().useDeviceLanguage();

		try {
			var result = await firebase.auth().signInWithPopup(provider);
			var userFacebook = result.user;
			const fbUserProvider = userFacebook.providerData.find( fbProvider => fbProvider.providerId === 'facebook.com' );
			var facebookUserId = 'Facebook_' + fbUserProvider.uid;

			log(['userFacebook', userFacebook]); 
			if (!(fbUserProvider.email || userFacebook.email || "")) {
				LogService.sendLog({
					loginType: 'loginByFacebook',
					'code': 'no_email',
					facebookUserId
				});
			}
			//update user to gochimeshi server
			await this.getUserAttributes(facebookUserId, "FACEBOOK", {
		      username: fbUserProvider.displayName || (userFacebook.displayName || ""),
		      email: fbUserProvider.email || (userFacebook.email || ""),
		    });
		} catch (e) {
			log(e);
			if (e.code === "auth/account-exists-with-different-credential")
			{
				this.setState({
					errorMessage: this.props.t('pages.register.An account already exists with the same email address')
				})
			}else {
				this.setState({
					errorMessage: this.props.t('pages.register.Login has been canceled')
				})
			}
			LogService.sendLog({ loginType: 'loginByFacebook', 'code': e.code });
		}
		this.setState({ loading: false });
	}

	getUserAttributes = async (
		cognitoUserId: string,
		providerName: SignInProvider,
		metadata?: { username: string; email: string },
		) => {
			var props = this.props;
			var thisView = this;

			let idToken = '';
			if (providerName === 'GOOGLE' || providerName === 'APPLE' || providerName === 'FACEBOOK') {
				idToken = await firebase.auth().currentUser.getIdToken(true);
			}

			try {
			  const { data } = await UserService.getUserList({
			    cognitoUserId,
			    sort: "createdAtDesc",
			    page: 1,
			    limit: 1,
			  });

			  if (data.count <= 0) {
					props.showLoading(false);
			    if (metadata) {
			      // データがなければ作る
			      const response = await UserService.createUser(
			        metadata.email,
			        metadata.username,
					cognitoUserId
			      );
					log(["log login ", response]);
			      if (response.status !== 200) {
			      	if (response.data.code === "validation.errors.Duplicate"){
						return thisView.setState({
							errorMessage: this.props.t('pages.register.Duplicate value is set_Item name_Email address')
						})
					}else {
						return thisView.setState({
							errorMessage: this.props.t('pages.register.Login has been canceled')
						})
					}
			      }else{
				      data.list.push(response.data);
				      data.count = 1;
				  }
			    } else {
			    	return thisView.setState({
						errorMessage: this.props.t('pages.register.Login has been canceled')
					})
					}
			  }

				const resAuth = await this.requestAuthentication(providerName, idToken);
				if (resAuth.status !== 200) {
					thisView.props.showLoading(false);
					if (resAuth.status === 400 && resAuth.data?.code === 'exception.errors.not.found.resource') {
						return thisView.setState({
							errorMessage: this.props.t('pages.register.Invalid code or auth state for the user')
						});
					}
					return thisView.props.showError(this.props.t('There is something wrong'), "");
				}

				props.saveUserInfo(true, false, data.list[0]);

				var userInfo = {
					isLogined: true,
					isGuest: false,
					userId: data.list[0].userId,
					accountId: data.list[0].accountId,
					displayName: data.list[0].displayName,
					email: data.list[0].email,
					userImage: data.list[0].userImage && data.list[0].userImage.length > 0 && data.list[0].userImage[0].image
				};

				localStorage.setItem('userInfo', JSON.stringify(userInfo));
				this.props.showLoading(false);
				thisView.setState({
					redirectTo: true
				});
			} catch (error) {
			  	log('getUserAttributes error');
				this.props.showLoading(false);
				return thisView.setState({
					errorMessage: this.props.t('pages.register.Login has been canceled')
				})
			}
	};

	async requestAuthentication(providerName, idToken) {
		switch (providerName) {
			case "GOOGLE":
			case "APPLE":
			case "FACEBOOK":
				// const idToken = await firebase.auth().currentUser.getIdToken(true);
				return await AuthService.authenticateByFirebaseToken(idToken);
			// case "COGNITO":
			default:
				const cognitoSession = await Auth.currentSession();
				return await AuthService.authenticateByCognitoToken(cognitoSession.idToken.jwtToken, this.state.confirmCode);
		}
	}

	async loginByGochiAccount(e){
		e.preventDefault();
		//login by cognito provider
		log("login by cognito: " + this.state.email);
		log("config = " + Auth.configure());
		this.props.showLoading(true);
		if (!this.state.email) {
			this.props.showLoading(false);
			return this.setState({
				errorMessage: this.props.t('pages.register.Login has been canceled')
			})
		}

		if (!this.state.password) {
			this.props.showLoading(false);
			return this.setState({
				errorMessage: this.props.t('pages.register.Login has been canceled')
			})
		}
		try {
			const cognitoUser = await Auth.signIn(this.state.email, this.state.password);
			log('successful sign in: ' + cognitoUser.username);
			this.setState({cognitoUser: cognitoUser});
			log('Cognito challenge: ' + cognitoUser.challengeName);

			// Request MFA challenge from GOCHI platform after login Cognito success
			const loginMfaResponse = await AuthService.loginMfa({ cognitoUserId: cognitoUser.username });
			if (loginMfaResponse.status === 200) {
				this.setState({
					errorMessage: ''
				});
				if (loginMfaResponse.data.enableMfa === 1) {
					this.setState({
						showOtpInput: true
					});
				} else {
					//update user to gochimeshi server
					await this.getUserAttributes(cognitoUser.username, "COGNITO");
				}
			} else {
				this.setState({
					errorMessage: this.props.t('pages.register.Login has been canceled')
				});
			}
			this.props.showLoading(false);
		} catch (error) {
			console.log('error', error.code);
			if (error.code === 'UserNotConfirmedException'){
				this.setState({
					userNotConfirmed: true,
					redirectTo: true
				})
			}
			this.setState({
				errorMessage: this.props.t('pages.register.user not confirmed exception')
			})
			this.props.showLoading(false);
		}
	}

	onEmailChange(e) {
		this.setState({email: e.target.value});
	}

	onPasswordChange(e) {
		this.setState({password: e.target.value});
	}

	async confirmLogin(e) {
		e.preventDefault();

		if (!this.state.confirmCode) {
			this.setState({
				errorMessage: this.props.t('pages.register.Invalid code or auth state for the user')
			});
			return;
		}

		this.props.showLoading(true);
		try {
			//update user to gochimeshi server
			await this.getUserAttributes(this.state.cognitoUser.username, "COGNITO"); 
		} catch (error) {
			console.log('error', error.code);
			if (error.code === 'CodeMismatchException') {
				this.setState({
					errorMessage: this.props.t('pages.register.Invalid code or auth state for the user')
				})
			} else {
				this.setState({
					errorMessage: this.props.t('pages.register.user not confirmed exception')
				})
			}
			this.props.showLoading(false);
		}
	}

	onConfirmCodeChange(e) {
		this.setState({confirmCode: e.target.value});
	}

	onShowHidePass(){
		this.setState({
			showPassword: !this.state.showPassword
		})
	}

	loginAsGuestAccount(e){
	    e.preventDefault();
	    var user = {};
	    this.props.saveUserInfo(true, true, user);
	    this.setState({
            redirectTo: true
        });

        var userInfo = {
			isLogined: true,
			isGuest: true
		};

		sessionStorage.removeItem("isRegister")
		localStorage.setItem('userInfo', JSON.stringify(userInfo));
	}

	UNSAFE_componentWillMount() {
		sessionStorage.removeItem("fullInviteContent");
		sessionStorage.removeItem("selectFriend");
		sessionStorage.removeItem("inviteContent");
		sessionStorage.removeItem("paymentMethod");
		sessionStorage.removeItem("menuInfo");
		sessionStorage.removeItem("comment");
		sessionStorage.removeItem("guestInfo");
		sessionStorage.removeItem("messageShop");
		sessionStorage.removeItem("selectPayment");
		sessionStorage.removeItem("paypayResponse");
		sessionStorage.removeItem("isRegister");
		var userInfo = this.props.userInfo;
		if (userInfo) {
			if(userInfo.isGuest === true) {
				// localStorage.removeItem("userInfo");
				this.props.saveUserInfo(false, userInfo.isGuest, []);
			}
		}
    }

	async componentDidMount() {
		document.body.className = "auth login";
		window.scrollTo(0, 0);

		let url = window.location.search;
		let params = queryString.parse(url);
		var menuId = params.id;
		var shopId = params.shopId;
        var src = params.src;
        var isSite = params.isSite;
        const fromShashoku = params.fromShashoku;

		if (src) {
			this.setState({
				src: src
			});
		}

		if (isSite) {
			this.setState({
				isSite: isSite
			});

			if (isSite === "mogugori") {
				document.body.className += " mogugori-login";
			}
		}

		this.setState({
			menuId: menuId
        });

		this.setState({
			shopId: shopId
		});
        
        if (fromShashoku) {
            localStorage.removeItem("userInfo");
            this.setState({
				paramShashoku: `&fromShashoku=${encodeURIComponent(fromShashoku)}`
			});
        }
	}

	showAppleConfirm() {
		this.setState({
			popupShowing: true,
		})
	}

	handlePopupClose() {
		this.setState({
			popupShowing: false,
		})
	}

	handlePopupConfirm() {
		this.loginByApple();
	}

	changeUserLogin(e) {
		localStorage.removeItem("userInfo")
		this.setState({
			goLogin: true
		})
	}
	
	goToRegister(e) {
		log(["state goToRegister"]);
		sessionStorage.setItem("isRegister", true)
		this.setState({
			redirectTo: true
		})
	}

	changeGuestLogin(e) {
		this.setState({
			goLogin: false
		})
	}

	handleShowListShops() {
		this.setState({
			showListShopPopup: true
		});
	};

	handleCloseListShopPopup() {
		this.setState({
			showListShopPopup: false
		});
	};

	redirectToCallbackUrl() {
		if (this.state.loginCallbackUrl) {
			return;
		}
		var isSiteParam = this.state.isSite ? {name: 'isSite', value: this.state.isSite} : {};
		window.history.pushState(null, null, addParamUrl('/mypage', [isSiteParam]));
		let link = addParamUrl(sessionStorage.getItem('loginCallbackUrl'), [isSiteParam]) || addParamUrl("/mypage", [isSiteParam]);
		sessionStorage.removeItem('loginCallbackUrl');
		this.setState({ loginCallbackUrl: link });
		window.location.href = link;
		// return <Redirect to={link} />
	}

	render() {
		const { t } = this.props;

		let url = window.location.search;
		let params = queryString.parse(url);
		var menuId = params.id;
		var shopId = params.shopId;

		if (params.isSite === 'mogugori') {
			document.body.className = "auth login " + params.isSite + '-login';
		} else {
			document.body.className = "auth login";
		}

		if (!menuId) {
			menuId = this.state.menuId;
		}
		if (!shopId) {
			shopId = this.state.shopId;
		}

		log(["state login", this.state]);
		var srcParam = this.state.src ? '&src=' + this.state.src : '';

		const redirectTo = this.state.redirectTo;
		var notHasMenu = menuId ? false : true;
		var notHasMenuPrice = shopId ? false : true;
		var isRegister = sessionStorage.getItem('isRegister');
		var isSiteParam = params.isSite ? {name: 'isSite', value: this.state.isSite} : {};

        if (redirectTo === true) {
			if (notHasMenu && notHasMenuPrice) {
				if (this.state.userNotConfirmed){
					window.history.pushState(null, null, addParamUrl('/login/confirm?registerInput=' + encodeURIComponent(this.state.email), [isSiteParam]));
					return <Redirect to={addParamUrl('/login/confirm?registerInput=' + encodeURIComponent(this.state.email), [isSiteParam])} />
				}
				return this.redirectToCallbackUrl();
			} else if(isRegister){
				if (this.state.userNotConfirmed){
					if (!notHasMenuPrice) {
						window.history.pushState(null, null, addParamUrl('/login/confirm?shopId='  + this.state.shopId + '&registerInput=' + encodeURIComponent(this.state.email) + srcParam, [isSiteParam]));
						return <Redirect to={addParamUrl('/login/confirm?shopId='  + this.state.shopId + '&registerInput=' + encodeURIComponent(this.state.email) + srcParam, [isSiteParam])} />
					} else {
						window.history.pushState(null, null, addParamUrl('/login/confirm?id='  + this.state.menuId + '&registerInput=' + encodeURIComponent(this.state.email) + srcParam, [isSiteParam]));
						return <Redirect to={addParamUrl('/login/confirm?id='  + this.state.menuId + '&registerInput=' + encodeURIComponent(this.state.email) + srcParam, [isSiteParam])} />
					}
				}
				if (!notHasMenuPrice) {
					window.history.pushState(null, null, addParamUrl('/signup?shopId='  + this.state.shopId + srcParam, [isSiteParam]));
					let link = addParamUrl("/signup?shopId=" + this.state.shopId + srcParam + this.state.paramShashoku, [isSiteParam]);
					return <Redirect to={link} />
				} else {
					window.history.pushState(null, null, addParamUrl('/signup?id='  + this.state.menuId + srcParam, [isSiteParam]));
					let link = addParamUrl("/signup?id=" + this.state.menuId + srcParam + this.state.paramShashoku, [isSiteParam]);
					return <Redirect to={link} />
				}
			} else {
				if (this.state.userNotConfirmed){
					if (!notHasMenuPrice) {
						window.history.pushState(null, null, addParamUrl('/login/confirm?shopId='  + this.state.shopId + '&registerInput=' + encodeURIComponent(this.state.email) + srcParam, [isSiteParam]));
						return <Redirect to={addParamUrl('/login/confirm?shopId='  + this.state.shopId + '&registerInput=' + encodeURIComponent(this.state.email) + srcParam, [isSiteParam])} />
					} else {
						window.history.pushState(null, null, addParamUrl('/login/confirm?id='  + this.state.menuId + '&registerInput=' + encodeURIComponent(this.state.email) + srcParam, [isSiteParam]));
						return <Redirect to={addParamUrl('/login/confirm?id='  + this.state.menuId + '&registerInput=' + encodeURIComponent(this.state.email) + srcParam, [isSiteParam])} />
					}
					
				}
                if (this.state.menuId) {
                    //window.history.pushState(null, null, '/menu?id=' + this.state.menuId + srcParam);
                    //let link = "/invite?id=" + this.state.menuId + srcParam + this.state.paramShashoku;
                    //return <Redirect to={link} />
					window.location.href = addParamUrl("/invite?id=" + this.state.menuId + srcParam + this.state.paramShashoku, [isSiteParam]);
                } else if (this.state.shopId) {
					window.location.href = addParamUrl("/invite?shopId=" + this.state.shopId + srcParam + this.state.paramShashoku, [isSiteParam]);
				} else { 
					return this.redirectToCallbackUrl();
                }
			}
		}
		

        var isLoggedIn = false;
        var userInfo = JSON.parse(localStorage.getItem('userInfo'));

        if (userInfo) {  
            isLoggedIn = !!userInfo.userId; 
		}
		if (isLoggedIn === true && notHasMenu && notHasMenuPrice) {
			return this.redirectToCallbackUrl();
		}
		if (isLoggedIn === true && !notHasMenuPrice) {
			window.location.href = addParamUrl("/invite?shopId=" + this.state.shopId + srcParam + this.state.paramShashoku, [isSiteParam]);
		}

        return (
			<div>
				<MetaTags>
					<title>ログイン｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス</title>
					<meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
					<meta name="description" content="ログインページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
					<meta name="twitter:card swiper-slide" content="summary_large_image" />
					<meta name="twitter:title" content="ログイン｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
					<meta name="twitter:description" content="ログインページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
					<meta name="twitter:image" content={window.location.origin + "/assets/img/common/ogp.png"}  />
					<meta name="twitter:site" content="@gochi_meshi" />
					<meta name="twitter:creator" content="@gochi_meshi" />
					<meta name="twitter:image:width" content="300" />
					<meta name="twitter:image:height" content="300" />
					<meta property="og:site_name" content="ログイン｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
					<meta property="og:title" content="ログイン｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
					<meta property="og:type" content="article" />
					<meta property="og:url" content={window.location.href} />
					<meta property="og:image" content={window.location.origin + "/assets/img/common/ogp.png"} />
					<meta property="og:description" content="ログインページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
				</MetaTags>
				<PopupRegisConfirm 
					title={this.state.popupTitle} 
					className="modal-group" 
					showing={this.state.popupShowing} 
					content={this.state.popupContent} 
					handleConfirm={this.handlePopupConfirm} 
					handleClose={this.handlePopupClose}
				/>

                <header className="global-login-header-new">
                    <div className="logo-nav">
                      <h1 className="logo">
                        <a href="/">
                          <div className="new-logo-login">
                            <source
                              media="(max-width: 180px)"
                              srcSet="/assets/img/common/logo_gochi_header_mypage.svg"
                            />
                            <img
                              src="/assets/img/common/logo_gochi_header_mypage.svg "
                              alt="気持ちを「ごちそう」にして贈れるアプリ ごちめし"
                              loading="lazy"
                            />
                          </div>
                          <span className="title-page">GOCHIアカウントにログイン</span>
                        </a>
                      </h1>
                    </div>
                    <div className="box-text-gray">
                      <div className="container">
                        <div className={this.state.showMoreText ? "open-more text-content" : "text-content"}>
                          <p>
                            GOCHIマイページとは、GOCHIアカウントをお持ちの方がさまざまなオンラインサービスで利用できる
                            <span className="font-weight-700">共通マイページ</span>
                            です。本マイページは
                            <span className="font-weight-700">ごちめし、さきめし、こどもごちめし、地域通貨など</span>
                            でご利用いただけます。
                          </p>
                          <p>
                            <span className="font-weight-700">GOCHIアカウント</span>
                            とは、さまざまなオンラインサービスで利用できる共通アカウントです。本サービスでは、
                            <span className="font-weight-700">ごちめし、さきめし、こどもごちめし、地域通貨</span>
                            などでご利用いただけます。
                          </p>
                          <p class="read-more"><span onClick={() => this.setState({showMoreText: !this.state.showMoreText})}>{this.state.showMoreText === true ? "閉じる" : "もっと見る"}</span></p>
                        </div>
                      </div>
                    </div>
                </header> 

                <main>
					{!isLoggedIn && (
						<div className="container new-login-container">
							{!this.state.showOtpInput ? (
								<div className="login-area">
									<a href="#signup" data-scroll className="under-tab text-underline">会員登録がお済みでない方はこちら</a>
									<section className="section login-sns-group">
										<header className="section-header login-sns-group-header">
											<div className="heading-md">
												<div className="title">SNSでログイン</div>
											</div>
										</header>

										<div className="section-body login-sns-group-body">
											<div className="btn-group sns-btn-group">
												<a className="btn btn-rect btn-sm btn-apple sns-btn" href onClick={this.showAppleConfirm}>
													<img src="/assets/img/common/icon-apple.svg" className="sns-icon" loading="lazy" alt="" />
													<p className="text">Apple IDでログインする</p>
												</a>
												<a className="btn btn-rect btn-sm btn-facebook sns-btn" href onClick={this.loginByFacebook}>
													<img src="/assets/img/common/icon-facebook-white.svg" className="sns-icon" loading="lazy" alt="" />
													<p className="text">Facebook IDでログインする</p>
												</a>
												<a className="btn btn-rect btn-sm btn-google sns-btn" href onClick={this.loginByGoogle}>
													<img src="/assets/img/common/icon-google.svg" className="sns-icon" loading="lazy" alt="" />
													<p className="text">Googleでログインする</p>
												</a>
											</div>
										</div>
									</section>

									<section className="section-body login-email-group">
										<header className="section-heade login-email-header">
											<div className="heading-md">
												<div className="title">メールアドレスでログイン</div>
											</div>
										</header>
										<div className="section-body login-email-body">
											<form onSubmit={this.loginByGochiAccount} method="post" >
												<p className="text-gray">
													ご登録のメールアドレスとパスワードをご入力の上ログインしてください。<em className="text-red">ごちめしアプリ・さきめしサイトでご登録のGOCHIアカウント用メールアドレスとパスワードでもログイン可能</em>です。
												</p>

												<div className="fieldset field-column">
													<div className="fieldset-header">
														<label for="email" className="title">メールアドレス</label>
													</div>
													<div className="fieldset-body">
														<div className="field">
															<input 
																onChange={this.onEmailChange} 
																defaultValue={this.state.email}
																id="email" 
																type="email" 
																className="" 
																placeholder="例) mail@gochi.online" 
																name="email" 
																autocomplete="email" 
																autofocus="" />
														</div>
													</div>
												</div>
												<div className="fieldset field-column">
													<div className="fieldset-header">
														<label for="password" className="title">パスワード</label>
													</div>
													<div className="fieldset-body">
														<div className="field field-password">
															<input
																id="password"
																type={this.state.showPassword ? "text" : "password"}
																name="password"
																placeholder="半角英数字を入力"
																className="form-control"
																onChange={this.onPasswordChange}
																autocomplete="current-password"
																defaultValue={this.state.password}
															/>
															<button className="btn" type="button" onClick={this.onShowHidePass}>
																<span className={this.state.showPassword ? "icon icon-eye open" : "icon icon-eye close"}></span>
															</button>
														</div>
													</div>
												</div>
												{this.state.errorMessage &&
													<div className="text-danger mb-2 fs-14 font-weight-bold">{this.state.errorMessage}</div>
												}
												<div className="btn-group">
													<button className="btn btn-rect ml-auto mr-auto">ログイン</button>
												</div>

											</form>
											<ul className="list-block mt-20">
												<li>
													{
														this.state.menuId ? (
															<a href={addParamUrl(`/password_reset?id=` + this.state.menuId + srcParam, [isSiteParam])} className="text-underline">パスワードを忘れた方</a>
														) : (
															<a href={addParamUrl(`/password_reset`, [isSiteParam])} className="text-underline">パスワードを忘れた方</a>
														)
													}
												</li>
											</ul>
											<aside className="box box-sm">
												<p>
													サービスのご利用をされる場合は、ごちめし/さきめしの
													<a href="https://gochimeshi.com/policy/terms/" className="text-underline" target="_blank" rel="noopener noreferrer">GOCHIプラットフォーム利用規約</a>及び<a href="https://gochimeshi.com/policy/privacy/" className="text-underline" target="_blank" rel="noopener noreferrer">プライバシーポリシー</a>に同意いただけたものとみなします。
												</p>
											</aside>
										</div>
									</section>

									<section className="section-body login-signup-group" id="signup">
										<header className="section-header signup-header">
											<div className="heading-md">
												<div className="title">会員登録がお済みでない方</div>
											</div>
										</header>
										<div className="section-body signup-body">
											<div className="btn-group under-tab">
												{
													this.state.menuId ? (
														<a href={addParamUrl(`/signup?id=` + this.state.menuId + srcParam, [isSiteParam])} className="btn btn-rect btn-outline ml-auto mr-auto">新規会員登録</a>
													) : (
														<a href={addParamUrl(`/signup`, [isSiteParam])} className="btn btn-rect btn-outline ml-auto mr-auto">新規会員登録</a>
													)
												}
											</div>

											<div className="btn-group only-pc">
												{
													this.state.menuId ? (
														<a href={addParamUrl(`/signup?id=` + this.state.menuId + srcParam, [isSiteParam])} className="btn btn-rect btn-outline ml-auto mr-auto">新規会員登録</a>
													) : (
														<a href={addParamUrl(`/signup`, [isSiteParam])} className="btn btn-rect btn-outline ml-auto mr-auto">新規会員登録</a>
													)
												}
											</div>

											<aside className="box box-sm">
												<p className="text-gray">
													会員登録されますと、マイページで<em className="text-bold">欲しいものリスト・購入履歴・フォロー・領収書発行・利用可能チケットの表示</em>など便利な機能をご利用いただけます。
													年会費、登録料、更新料は一切かからず、無料でご利用いただけます。
												</p>
											</aside>
										</div>
									</section>
								</div>
							) : (
								<>
									<div className="heading-lg">
										<div className="title">メールアドレスでログイン</div>
									</div>
									<form onSubmit={this.confirmLogin} method="post" className="mt-50">
										<div className="text-box-login">
											<div className="box box-sm">
												<p>
													<span className="text-red">登録メールアドレス</span>
													に届いた認証コードをご入力ください。認証コードが届くまでお時間がかかる場合がございます。
													<br />
													※届かない場合は迷惑メールフォルダなどもご確認ください。
													<br />
													<a href="https://user-guide.gochimeshi.com/gochi-faq63/" target="_blank">Q.メールアドレスに認証コードが届かない</a>
												</p>
											</div>

											<div className="warning-box-login">
												<i className="icon-warning-go-shop"/>
												2024年7月15日（月）より、ログイン時の二段階認証をSMS認証からメールアドレス認証に変更しました。
											</div>
										</div>

										<div className="login-form">
											<div className="field-group field-group-column">
												<div className="fieldset">
													<div className="fieldset-header">
														<div className="title">認証コードの入力</div>
														<em className="fieldset-mark">必須</em>
													</div>
													<div className="fieldset-body">
                                        				<div className="field-group field-group-column">
															<div className="field field-fluid">
																<input type="text" placeholder="認証コードを入力" onChange={this.onConfirmCodeChange} defaultValue={this.state.confirmCode}/>
															</div>
															{this.state.errorMessage &&
																<div className="text-danger mb-2 fs-14 font-weight-bold">{t(this.state.errorMessage)}</div>
															}
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="btn-group signup-input-btn-group login-form">
											<button type="submit" className="btn btn-rect signup-input-btn button-reset-pass width100"><div>次へ</div></button>
										</div>
									</form>
								</>
							)}
						</div>
					)}
                </main>

                <footer className="global-footer footer-mypage new-footer-login">
                    <section className="global-footer-nav-area">
                      <div className="container">
                        <div className="section-header">
                          <div className="heading-xs">
                            <div className="title">
                              <h1>
                                <img
                                  src="/assets/img/common/logo_gochi_footer.svg"
                                  alt="ごちめし"
                                  loading="lazy"
                                />
                              </h1>
                            </div>
                          </div>
                        </div>
                        <div className="section-body footer-nav">
                          <ul className="footer-link-area">
                            <li>
                              <a href="https://gochimeshi.com/policy/">各種規約</a>
                            </li>
                            <li>
                              <a href="https://gochimeshi.com/policy/privacy/">
                                プライバシーポリシー
                              </a>
                            </li>
                            <li>
                              <a href="https://gochimeshi.com/policy/legal/">
                                特定商取引法に基づく表記
                              </a>
                            </li>
                            <li>
                              <a href="https://user-guide.gochimeshi.com/">よくある質問</a>
                            </li>
                            <li>
                              <a href="https://gochimeshi.com/info/">お問い合わせ</a>
                            </li>
                            <li>
                              <a href="https://www.gigi.tokyo/">運営会社（Gigi株式会社）</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </section>

                    <div className="copyright">
                        <small>
                          GOCHI®︎ ごちめし®︎ こどもごちめし® びずめし®︎ ︎ さきめし®︎ は、
                          <br />
                          Gigi株式会社の登録商標です
                          <br />
                          GOCHIプラットフォームの機能は、
                          <br />
                          特許取得済み（特許番号：特許第7572763号）
                          <br />
                          © Gigi Inc.
                        </small>
                    </div>
                </footer>
				<LoadingOverlay loading={this.state.loading} />
			</div>
        );
    }
}

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
	showError: (title, content) => dispatch(showError(true, title, content)),
	saveUserInfo: (isLogined, isGuest, userInfo) => dispatch(saveUserInfo(isLogined, isGuest, userInfo)),
	showLoading: (showing) => dispatch(showLoading(showing)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Login));

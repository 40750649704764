import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";
import queryString from "query-string";
import { showError } from "../../../actions/action_error_alert";
import { showLoading } from "../../../actions/action_loading";
import Header from "../layout/Header";
import DonateService from "../../../api/services/GochiKid/DonateService";
import * as constants from "../../../constants";
import { savePaymentMethod } from "../../../actions/action_payment";
import { log } from "../../../utils";
import { clearDonate } from "../../../actions/GochiKid/action_donate";
import ShopService from "../../../api/services/ShopService";
import ActivityInfo from "../common/ActivityInfo";
import imagePaperPlane from "../../../assets/GochiKid/img/paper-plane.png";
import { Modal } from "react-bootstrap";

function DonateComplete(props) {
  const match = useRouteMatch();
  const destination = match.params.destination;
  const location = useLocation();
  const { merchantPaymentId, checkoutId, retry } = queryString.parse(location.search);

  const [paymentType, setPaymentType] = useState(null);
  const [isSubscription, setIsSubscription] = useState(false);
  const [donateAmount, setDonateAmount] = useState(null);
  const [donateMessage, setDonateMessage] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [activityInfo, setActivityInfo] = useState(null);
  const [showRetry, setShowRetry] = useState(false);

  const getDestinationText = () => {
    switch (destination) {
      case 'region':
        return '都道府県';
      case 'activity':
        return '自治体/活動';
      default:
        return '全国';
    }
  };

  const getPaymentTypeText = () => {
    switch (paymentType) {
      case constants.PAYMENT_TYPE_PAYPAY:
        return 'PayPayで決済';
      case constants.PAYMENT_TYPE_ONEPAY_ALIPAY:
        return 'Alipayで決済';
      case constants.PAYMENT_TYPE_ATONE:
        return 'atone 翌月払い（コンビニ/口座振替）';
      default:
        return 'クレジットカードで決済';
    }
  };

  const getActivityInfo = async ({ shopId, groupId }) => {
    if (!shopId && !groupId) {
      return;
    }
    let response;
    if (shopId) {
      response = await ShopService.getDetailShop(shopId);
    } else if (groupId) {
      response = await ShopService.getDetailGroup(groupId);
    }
    if (response.status === 200) {
      setActivityInfo(response.data);
    } else {
      log('getActivityInfo failed');
    }
  };

  const fetchPaymentInfo = async () => {
    props.showLoading(true);
    let response = await DonateService.completePayment(merchantPaymentId, { checkoutId });
    // If payment gateway is Onepay, re-call API complete until response status is not 404 or timeout
    const maxRetry = parseInt(retry) || 0;
    for (let i = 0; i < maxRetry; i++) {
      if (response.status !== 404) break;
      await new Promise(r => setTimeout(r, 10000));
      response = await DonateService.completePayment(merchantPaymentId, { checkoutId });
    }
    if (response.status === 200) {
      setPaymentType(response.data.paymentType);
      setIsSubscription(!!response.data.gochiKidSubscriptionId);
      setDonateAmount(response.data.chargeAmount); // donate amount will be apply fee, so we have to show charge amount (original input)
      setDonateMessage(response.data.donateMessage);
      setUserEmail(response.data.userEmail);
      setUserName(response.data.userName);

      /*
      if (props.userInfo.userId &&
        (response.data.paymentType === constants.PAYMENT_TYPE_STRIPE || response.data.paymentType === constants.PAYMENT_TYPE_VERITRANS)
      ) {
        try {
          const { data } = await PaymentService.getDefaultCard(props.userInfo.userId);
          if (data.list.length > 0) {
            props.savePaymentMethod(data.list[0]);
          }
        } catch (error) {
          log('getDefaultCard error with userId ' + props.userInfo.userId);
        }
      }
      */

      await getActivityInfo(response.data);
    } else {
      if (response.status === 404 && retry) {
        setShowRetry(true);
      } else {
        // TODO: Error message
        props.showError(true, 'エラーが発生しました。', '');
      }
    }
    props.showLoading(false);
  };

  const onPageLoad = () => {
    fetchPaymentInfo().then(() => {
      // Scroll to top after first load
      window.scrollTo(0, 0);
    });
  };

  useEffect(() => {
    log('DonateDestination componentDidMount');
    if (document.readyState === 'complete') {
      log('page already complete');
      onPageLoad();
    } else {
      log('add event listener "load"');
      window.addEventListener('load', onPageLoad);
    }

    props.clearDonate();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header />

      <main className="page-wrapper">
        <div className="page-content donate-payment">

          <div className="font-family-rounded title-donate text-center color-2F1A1C mb-3">支援完了</div>
          <ul className="payment-progress-bar align-center">
            <li>入力</li>
            <li>確認</li>
            <li className="active">完了</li>
          </ul>

          {donateAmount && <>
            <div className="fs-20px fw-700 text-center mt-5">
              <div>ご支援頂き、</div>
              <div>誠にありがとうございます！</div>
              <div>支援内容をご確認ください。</div>
            </div>

            <div className="fs-24px fw-800 font-family-rounded text-center mt-5">支援内容</div>

            <div className="block-title-md mt-5">
              <span className="font-family-rounded mr-2">支援先 <span className="color-ED808C">*</span>：</span>
              <span className="fs-16px fw-700">{getDestinationText()}</span>
            </div>

            {activityInfo && <div className="mt-3">
              <ActivityInfo
                className="activity-info-confirm"
                info={activityInfo}
                hideContactInfo={destination === 'region'}
              />
            </div>}

            <div className="block-title-md mt-3">
              <span className="font-family-rounded mr-2">金額(税込)<span className="color-ED808C">*</span>：</span>
              <span className="fw-700">{(donateAmount || 0).toLocaleString()}<span className="fs-16px">円</span></span>
            </div>

            <div className="block-title-md mt-3">
              <span className="font-family-rounded mr-2">回数 <span className="color-ED808C">*</span>：</span>
              <span className="fs-16px fw-700">{isSubscription ? '継続（1ヶ月1回）' : '一度だけ'}</span>
            </div>

            <div className="block-title-md mt-3">
              <span className="font-family-rounded mr-2">お支払い方法 <span className="color-ED808C">*</span>：</span>
            </div>
            <div className="fw-700 mt-2">{getPaymentTypeText()}</div>
            {props.paymentMethod && props.paymentMethod.last4 && props.paymentMethod.expireMonth && props.paymentMethod.expireYear && <div>
              <div className="color-797474 mt-2">****-****-****-{props.paymentMethod.last4}</div>
              <div className="color-797474 mt-2">有効期限：{props.paymentMethod.expireMonth.toString().padStart(2, '0')}/{props.paymentMethod.expireYear}</div>
            </div>}

            {userEmail && userName && <>
              <div className="block-title-md mt-3">
                <span className="font-family-rounded mr-2">支援者情報 <span className="color-ED808C">*</span>：</span>
              </div>
              <div className="fs-18px fw-800 mt-2">メールアドレス</div>
              <div className="overflow-wrap-anywhere fw-400 mt-2">{userEmail}</div>
              <div className="fs-18px fw-800 mt-2">氏名</div>
              <div className="overflow-wrap-anywhere fw-400 mt-2">{userName}</div>
            </>}

            {donateMessage && <>
              <div className="block-title-md block-title-optional mt-3">
                <span className="font-family-rounded mr-2">こども達へのメッセージ：</span>
              </div>
              <div className="overflow-wrap-anywhere white-space-pre-wrap fw-400 p-3">
                {donateMessage}
              </div>
            </>}

            <img className="image-donate-complete" src={imagePaperPlane} alt="Donate Complete" />

            <div className="fs-24px fw-800 font-family-rounded">
              {props.userInfo.userId
                ? (
                  <span>こども達からの感謝のメッセージは、後日お届けします！</span>
                )
                : (
                  <span>会員登録していただいた方に、こども達からの感謝メッセージをお届けします！</span>
                )
              }
            </div>
            <div className="fw-400 mt-2">
              {props.userInfo.userId && (
                <span dangerouslySetInnerHTML={{ __html: '“GOCHIマイページ > こどごちめし食堂支援 > 感謝メッセージ”ページでご確認頂けます。' }} />
              )}
            </div>
            <div className="fw-400 fs-14 mt-2">
              <span>ご支援いただいた内容については、登録メールアドレス宛に送信いたしましたので、ご確認ください。※「@gochimeshi.com」のドメインからメールを送信します。</span>
            </div>
          </>}

          <div className="mt-5">
            {props.userInfo.userId
              ? (
                <>
                  <div>
                    <button className="btn-primary btn-size-md w-100" onClick={() => window.location.href = '/mypage/kodomo_support'}>
                      支援マイページへ
                      <i className="icon-external-link" />
                    </button>
                  </div>
                </>
              )
              : (
                <>
                  <div>
                    <button className="btn-primary btn-size-md w-100" onClick={() => window.location.href = '/signup'}>
                      会員登録する
                    </button>
                  </div>
                  <div className="mt-3">
                    <button className="btn-outline btn-size-md w-100" onClick={() => window.location.href = '/login'}>
                      既にアカウントをお持ちの方
                    </button>
                  </div>
                </>
              )
            }
            <div className="mt-4 text-center">
              <button className="btn-link fw-400 border-0 p-0" onClick={() => window.location.href = 'http://kodomo-gochimeshi.org/'}>
                トップページに戻る
              </button>
            </div>
          </div>

        </div>
      </main>

      <Modal
        size="sm"
        className="no-header-modal"
        animation={false}
        centered
        show={showRetry}
        onHide={() => setShowRetry(false)}
      >
        <Modal.Body>
          システム内に支援履歴がまだ反映されないので、支払った場合リトライボタンで再読み込みをお試しください。
          <br />
          まだ支払わなかった場合、キャンセルボタンを押してください。
        </Modal.Body>
        <Modal.Footer>
          <button className="btn-primary" onClick={() => { fetchPaymentInfo(); setShowRetry(false); }}>リトライ</button>
          <button className="btn-outline" onClick={() => setShowRetry(false)}>キャンセル</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapStateToProps = state => ({
  userInfo: state.userInfo,
  paymentMethod: state.paymentMethod
});

const mapDispatchToProps = dispatch => ({
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content, buttonName, gotoUrl) => dispatch(showError(showing, title, content, buttonName, gotoUrl)),
  clearDonate: () => dispatch(clearDonate()),
  savePaymentMethod: (paymentMethod) => dispatch(savePaymentMethod(paymentMethod))
});

export default connect(mapStateToProps, mapDispatchToProps)(DonateComplete);

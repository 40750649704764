import React, { Component } from "react";    
import {connect} from 'react-redux';
import {log} from '../../../utils';
import {addMessageCard} from "../../../actions/action_message_card";
import { withTranslation } from 'react-i18next';
import { Modal } from "react-bootstrap";
import InfiniteScroll from 'react-infinite-scroller';
import GiftCardService from '../../../api/services/GiftCardService';
import ListGiftCards from '../../common/ListGiftCards';
import {showLoading} from "../../../actions/action_loading";
import {showError} from "../../../actions/action_error_alert";

class ThankYouCard extends Component { 
	constructor(props) { 
	  	super(props); 
	  	this.state = {
			thankYouText: '',
            giftCardId: this.props.messageCard.giftCardId ? this.props.messageCard.giftCardId : sessionStorage.getItem('giftCardDefault') && JSON.parse(sessionStorage.getItem('giftCardDefault')).giftCardId,
            giftCardName: '',
			thankYouImage: this.props.messageCard.thankYouImage ? this.props.messageCard.thankYouImage : sessionStorage.getItem('giftCardDefault') && JSON.parse(sessionStorage.getItem('giftCardDefault')).thankYouImage,
            showListCardPopup: false,
            listDataCategories: [],
            pageCategory: 0,
            limitCategory: 10,
            nextHrefCategory: 1,
            hasMoreItemsCategories: true,
            limitCard: 5,
            // count: 4,
            hasMoreItems: true,
            nextValueCard: {
                cardId: null,
                cardImage: null
            },
            selectedCard: null
	  	};

        this.handleOpenListCardPopup = this.handleOpenListCardPopup.bind(this);
        this.handleCloseListCardPopup = this.handleCloseListCardPopup.bind(this);
	  	this.handleChange = this.handleChange.bind(this);
        this.handleSelectCard = this.handleSelectCard.bind(this);
        this.fetchDataCategory = this.fetchDataCategory.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
	}

    handleOpenListCardPopup = () => {
        this.setState({
            showListCardPopup: true,
            hasMoreItems: true
        });
        this.fetchDataCategory();
    };

    handleCloseListCardPopup() {
        this.setState({
            listDataCategories: [],
            showListCardPopup: false,
            hasMoreItems: false,
            nextHrefCategory: 1,
            selectedCard: this.state.giftCardId
        });
    };

    handleSelectCard(card, currentCardId) {
        this.setState({
            nextValueCard: {
                cardId: card.giftCardId,
                cardImage: card.image
            },
            selectedCard: currentCardId
        });
    }

    handleSubmit() {
        this.setState({
            listDataCategories: [],
            hasMoreItems: false,
            nextHrefCategory: 1,
            showListCardPopup: false,
            thankYouImage: this.state.nextValueCard.cardImage,
            giftCardId: this.state.nextValueCard.cardId
        });

        var messageCard = {};
        messageCard = {
            thankYouText: this.state.thankYouText,
            thankYouImage: this.state.nextValueCard.cardImage,
            giftCardId: this.state.nextValueCard.cardId
        };
        sessionStorage.setItem('messageCard', JSON.stringify(messageCard));
        this.props.addMessageCard(this.state.thankYouText, messageCard.thankYouImage, messageCard.giftCardId);
    }

    async fetchDataCategory() {
        try {
            this.props.showLoading(true);
            let page = this.state.nextHrefCategory;
            let limit = this.state.limitCategory;

            let response = await GiftCardService.getListCardCategory(page, limit);
            if (response.data.count > 0) {
                var list = this.state.listDataCategories;

                response.data.list.map(item => {
                    return list.push(item);
                });

                if (page*limit < response.data.count) {
                    this.setState({
                        nextHrefCategory: page + 1,
                        listDataCategories: list,
                        hasMoreItemsCategories: true
                    });
                } else {
                    this.setState({
                        hasMoreItemsCategories: false,
                        nextHrefCategory: 1
                    });
                }
            }
            this.props.showLoading(false);
        } catch (e) {
            log(e);
            this.props.showLoading(false);
        }
    }

	handleChange = (e) => {
        this.setState({
            thankYouText: e.target.value
        });
        this.props.addMessageCard(e.target.value, this.state.thankYouImage, this.state.giftCardId);
        var messageCard = {};
        messageCard = {
            thankYouText: e.target.value,
            thankYouImage: this.state.thankYouImage,
            giftCardId: this.state.giftCardId
        }
		sessionStorage.setItem('messageCard', JSON.stringify(messageCard));
	};

	componentDidMount(){

	}

    render() {
		const { t } = this.props;
        log(["this state of card", this.state]);

        const loader = <div className="loader" key="loader">Loading ...</div>;

        return (
        	<>
                <div className="dish-infor mt-5">
                    <div className="required-dot">
                        <div className="dot-red"></div>
                        <div className="dot-text-label fs-18">{t('pages.invite.Choose a card design')}</div>
                    </div>
                    <div className="line-horizontal label-ln mb-2"></div>

                    <div className="box-card align-center">
                        <img
                            // width={"100%"}
                            className="mr-3"
                            src={this.state.thankYouImage}
                            alt={this.state.gitCardName}
                            name="imageCard"
                        />
                    </div>

                    <button type="button" onClick={this.handleOpenListCardPopup} className="btn btn-outline-green-38 mt-3">
                        {t('pages.invite.Select from the card list')}
                    </button>

                    <div className="required-dot mt-5">
                        <div className="dot-text-label fs-18 pl-0">{t('pages.invite.Enter a message')}</div>
                    </div>
                    <div className="line-horizontal label-ln mb-2"></div>
                    <div className="fs-13 mb-2">
                        {t('pages.invite.Please enter the message within 300 characters')}
                    </div>
                    
                    <textarea 
                        className={this.props.messageCardError ? "form-control mt-1 textarea-message-lg ex-form-required" : "form-control mt-1 textarea-message-lg"} 
                        placeholder={t('pages.invite.thankYouMessagePlaceholder')}  
                        defaultValue={this.state.thankYouText ? this.state.thankYouText : (this.props.messageCard && this.props.messageCard.thankYouText)}
                        onChange={this.handleChange}
                        name="messageCard"
                    />

                    {this.props.messageCardError &&
                        <div className="text-danger fs-14 mb-2 fs-12 box-warning-message">{this.props.messageCardError}</div>
                    }
                </div>
                <Modal
                    className="modal-list-gift-card"
					size="lg"
                    centered
					show={this.state.showListCardPopup}
					onHide={this.handleCloseListCardPopup}
					aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Body className="d-flex flex-column">
                            <div className="title-lst-shop align-center mt-4 font-weight-bold">{t('pages.invite.Select from the card list')}</div>

                            {this.state.listDataCategories.length > 0 && (
                                <div className="mt-2" style={{overflow: "auto", flex: 1, display:'flex'}}>
                                    <InfiniteScroll
                                        pageStart={0}
                                        loadMore={this.fetchDataCategory}
                                        hasMore={this.state.hasMoreItemsCategories && !this.props.loading}
                                        loader={loader}
                                        useWindow={false}
                                    > 
                                        {this.state.listDataCategories.map((category, key) => {
                                            return (
                                                <ListGiftCards
                                                    key={key}
                                                    giftCardCategoryId={category.giftCardCategoryId}
                                                    giftCardCategoryName={category.giftCardCategoryName}
                                                    giftCardCount={category.giftCardCount}
                                                    handleSelectCard={this.handleSelectCard}
                                                    selectedCard={this.state.selectedCard}
                                                />
                                            );
                                        })}
                                    </InfiniteScroll>
                                </div>
                            )}
                                                <div style={{padding: 20}}>
                        <button type="button" onClick={this.handleSubmit} className="btn btn-green-38 mt-3">
                            {t('pages.invite.Determine')}
                        </button>
                    </div>
                    <div className="fs-15 clr-000000 align-center mb-3">
						<button type="button" onClick={this.handleCloseListCardPopup} className="btn btn-close-popup">
							{t('pages.mypage.title button cancel')}
						</button>
					</div>
                    </Modal.Body>
                </Modal>
            </>
        ); 
    }
} 

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
    showLoading: (showing) => dispatch(showLoading(showing)),
	showError: (showing, title, content, buttonName, gotoUrl) =>dispatch(showError(showing, title, content, buttonName, gotoUrl)),
	addMessageCard: (thankYouText, thankYouImage, giftCardId) => dispatch(addMessageCard(thankYouText, thankYouImage, giftCardId)),
});
 
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ThankYouCard));

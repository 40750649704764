import React, { useState, useEffect } from "react";
import {
  useSituations,
  useGenres,
  useRecentlyViewedMenus,
  useNews,
  useMagazines,
  useSpecialPickup,
  useSpecialCampaign,
  useScript,
  useWindowSize,
} from "../../../../utils";
import { useHistory } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { ListMenus } from "../../common/ListMenus";
import { ModalGroup } from "../../common/ModalGroup";
import { SituationModal } from "../../common/SituationModal";
import { GiftToModal } from "../../common/GiftToModal";
import { NotLoggedModal } from "../../common/NotLoggedModal";
import { Footer } from "../../layout/Footer";
import Header from "../../layout/Header";
import { Explanation } from "../../layout/Explanation";
import { TopPageModal } from "./TopPageModal";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { getSlidePerView, swiperConfigs } from "../../common/SwiperConfigs";
import { SliderPagination } from "../../common/SliderPagination";
import "swiper/swiper-bundle.min.css";
import { showLoading } from "../../../../actions/action_loading";
import { showError } from "../../../../actions/action_error_alert";
import { connect } from "react-redux";
import queryString from "query-string";
import moment from "moment";
import { WishListModal } from "../../common/WishListModal";
import SurveyForm from "../../../common/SurveyForm";

export function TopPage(props) {
  useScript("/assets/js/app.js");

  const NO_IMAGE = require("../../../../assets/img/icons/noimage.png");
  const colors = [
    "#cc5858",
    "#8bce57",
    "#db758f",
    "#efb259",
    "#57c7ce",
    "#ce7357",
    "#ab7fd6",
    "#587acc",
    "#58cc9d",
  ];

  const params = queryString.parse(window.location.search);
  if (params.id) {
    window.location.href = `/menu?id=${params.id}`;
  }

  const [prefecture, setPrefecture] = useState("");
  const [prefectureId, setPrefectureId] = useState("");
  const listCities = useSelector((state) => state.cities);
  const filteredCities = listCities.filter(
    (city) => city.prefCode === prefectureId
  );
  const [city, setCity] = useState("");
  // const [cityId, setCityId] = useState("");
  const listPrefectures = useSelector((state) => state.prefectures);
  const [keyword, setKeyword] = useState("");

  const [loadingNews, setLoadingNews] = useState(true);
  const [loadingRecent, setLoadingRecent] = useState(true);
  const [loadingSpecialPickups, setLoadingSpecialPickups] = useState(true);
  const [loadingSpecialCampaigns, setLoadingSpecialCampaigns] = useState(true);
  const [loadingMagazines, setLoadingMagazines] = useState(true);

  const listPickups = useSpecialPickup(() => setLoadingSpecialPickups(false), () => setLoadingSpecialPickups(false));
  const listSituations = useSituations();
  const listSpecialCampaigns = useSpecialCampaign(1, 5, () => setLoadingSpecialCampaigns(false), () => setLoadingSpecialCampaigns(false));
  const listGenres = useGenres();
  const listMagazines = useMagazines(1, 8, () => setLoadingMagazines(false), () => setLoadingMagazines(false));
  const listNews = useNews(1, 3, () => setLoadingNews(false), () => setLoadingNews(false));
  const [recentlyViewedMenus] = useRecentlyViewedMenus(() =>
    setLoadingRecent(false)
  );

  const history = useHistory();

  useEffect(() => {
    document.body.className = "home";
    window.scrollTo(0, 0);
  }, []);

  const loading = () => {
    if (
      listPickups.length > 0 &&
      listSituations.length > 0 &&
      listSpecialCampaigns.length > 0 &&
      listGenres.length > 0 &&
      listMagazines.length > 0
    ) {
      return false;
    }
    return true;
  };

  function handleSubmit() {
    var prefectureTmp = prefecture === "選択なし" ? "" : prefecture;
    var cityTmp = city === "選択なし" ? "" : city;
    if (prefectureTmp === "") {
      cityTmp = "";
    }

    const searchParams = [];
    if (prefectureTmp) searchParams.push(`prefecture=${prefectureTmp}`);
    if (cityTmp) searchParams.push(`city=${cityTmp}`);
    if (keyword) searchParams.push(queryString.stringify({ keyword }));

    history.push({
      pathname: "/products",
      search: `?${searchParams.join("&")}`,
    });
  }

  function handleSearchMenuBySituation(situation) {
    history.push({
      pathname: "/products",
      search: `?situation=${situation}`,
    });
  }

  function handleSearchMenuByGenre(genre) {
    history.push({
      pathname: "/products",
      search: `?genre=${genre}`,
    });
  }

  function replaceString(value, limit) {
    return value.length > limit ? value.substr(0, limit - 1) + "..." : value;
  }

  function formatDate(value) {
    return value && value !== "" ? moment(value).format("YYYY.MM.DD") : value;
  }

  function handleViewSpecialDetail(slug) {
    history.push({
      pathname: "/specials/" + slug,
    });
    window.scrollTo(0, 0);
  }

  const windowSize = useWindowSize();
  const innerWidth = windowSize[0];

  return (
    <>
      <MetaTags>
        <title>「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content="「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。メールやLINE、携帯電話のメッセージ機能で住所を知らない相手にも思い立ったらすぐに贈れて便利です。" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta name="twitter:description" content="「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。メールやLINE、携帯電話のメッセージ機能で住所を知らない相手にも思い立ったらすぐに贈れて便利です。" />
        <meta name="twitter:image" content={window.location.origin + "/assets/img/common/ogp.png"} />
        <meta name="twitter:site" content="@gochi_meshi" />
        <meta name="twitter:creator" content="@gochi_meshi" />
        <meta name="twitter:image:width" content="300" />
        <meta name="twitter:image:height" content="300" />
        <meta name="twitter:url" content={window.location.href} />
        <meta property="og:site_name" content="「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:title" content="「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={window.location.origin + "/assets/img/common/ogp.png"} />
        <meta property="og:description" content="「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。メールやLINE、携帯電話のメッセージ機能で住所を知らない相手にも思い立ったらすぐに贈れて便利です。" />
      </MetaTags>
      <Header listPrefectures={listPrefectures} listCities={listCities} />

      <main className="top-page-main">
        <div className="keyvisual-area">
          <div className="container-lg">
            <img
              className="bg pc-tab"
              src="/assets/img/home/img-keyvisual.png"
              loading="lazy"
              alt=""
            />
            <div className="keyvisual-header">
              <div className="title">GOCHIMESHI</div>
            </div>
            <div className="keyvisual-body pc-tab">
              <h1 className="lead text-outline">
                飲食店のメニューをお食事券で
                <br className="under-tab" />
                <span className="lead-underline">プレゼント</span>
                <br className="over-pc" />
                できる
                <br className="under-tab" />
                サービス<span className="lead-underline">「ごちめし」</span>
              </h1>
            </div>
            <div className="keyvisual-footer">
              <form
                action=""
                method="POST"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className="search-area">
                  <div className="search-header heading-lg">
                    <div className="title text-outline">
                      お食事券・プレゼント・ギフトで送りたいキーワードを検索！
                    </div>
                  </div>
                  <div className="search-body">
                    <div className="fieldset fieldset-lg is-area">
                      <div className="fieldset-header">
                        <svg className="icon">
                          <use xlinkHref="/assets/img/common/icon.svg#map-marker" />
                        </svg>
                        <div className="title">エリア</div>
                      </div>
                      <div className="fieldset-body">
                        <div className="field field-pill">
                          <div className="field field-sm">
                            <select
                              onChange={(e) => {
                                setPrefectureId(e.target.value);
                                let index = e.nativeEvent.target.selectedIndex;
                                let label = e.nativeEvent.target[index].text;
                                setPrefecture(label);
                              }}
                            >
                              <option value="">選択なし</option>
                              {listPrefectures &&
                                listPrefectures.length > 0 &&
                                listPrefectures.map((item, key) => {
                                  let index = `prefecture-${item.prefectureId}-${key}`;
                                  return (
                                    <option
                                      value={item.prefectureId}
                                      key={index}
                                    >
                                      {item.prefectureName}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="field field-pill field-sm">
                            <select
                              className="select-box-custom"
                              onChange={(e) => {
                                // setCityId(e.target.value);
                                let index = e.nativeEvent.target.selectedIndex;
                                let label = e.nativeEvent.target[index].text;
                                setCity(label);
                              }}
                            >
                              <option value="">選択なし</option>
                              {filteredCities &&
                                filteredCities.length > 0 &&
                                filteredCities.map((item, key) => {
                                  let index = `city-${key}`;
                                  return (
                                    <option value={item.cityId} key={index}>
                                      {item.cityName}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.fieldset */}
                    <div className="unit over-pc">
                      <svg className="icon">
                        <use xlinkHref="/assets/img/common/icon.svg#times" />
                      </svg>
                    </div>
                    {/* /.unit */}
                    <div className="fieldset fieldset-lg is-keyword">
                      <div className="fieldset-header">
                        <svg className="icon">
                          <use xlinkHref="/assets/img/common/icon.svg#search" />
                        </svg>
                        <div className="title text-normal">キーワード</div>
                      </div>
                      <div className="fieldset-body">
                        <div className="field field-pill field-lg">
                          <input
                            type="text"
                            placeholder="例:  お寿司"
                            onChange={(e) => {
                              setKeyword(e.target.value.trim());
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /.fieldset */}
                    <div className="fieldset fieldset-submit is-submit">
                      <button
                        type="button"
                        className="btn btn-submit search-btn"
                        onClick={handleSubmit}
                      >
                        検索する
                      </button>
                    </div>
                    {/* /.fieldset */}
                  </div>
                  {/* /.search-body */}
                </div>
                {/* /.search-area */}
              </form>
            </div>
          </div>
        </div>
        {/*SP・PCによって配置替え*/}
        <div className="d-flex flex-direction-column">
          <section className="section pc-top">
            <div className="container">
              <div className="section-header">
                <div className="heading-lg">
                  <h2 className="title">
                    ごちめしで実施中のキャンペーン
                  </h2>
                </div>
              </div>
              <div className="section-body pickup">
                <Swiper {...swiperConfigs.pickup}>
                  <div
                    className={
                      loadingSpecialPickups ? "swiper-wrapper loading" : "swiper-wrapper"
                    }
                  >
                    {listPickups &&
                      listPickups.length > 0 &&
                      listPickups.map((item, key) => {
                        let index = `special-pickup-${key}`;
                        return (
                          <SwiperSlide key={index}>
                            <a
                              className="swiper-slide card-image"
                              href={item.link_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={item.image_url}
                                className="img"
                                alt=""
                              />
                            </a>
                          </SwiperSlide>
                        );
                      })}
                  </div>
                  <div className="slider-pagination-area">
                    <div className="slider-btn-group">
                      <button className="slider-btn slider-btn-prev">
                        <svg className="icon">
                          <use xlinkHref="/assets/img/common/icon.svg#angle-left" />
                        </svg>
                      </button>
                      <button className="slider-btn slider-btn-next">
                        <svg className="icon">
                          <use xlinkHref="/assets/img/common/icon.svg#angle-right" />
                        </svg>
                      </button>
                    </div>
                    <div className="swiper-pagination" />
                  </div>
                </Swiper>
              </div>
            </div>
          </section>
          <section className="section sp-top">
            <div className="container">
              <div className="section-header">
                <div className="heading-lg">
                  <h2 className="title">
                    シチュエーションから
                    <br className="only-sp" />
                    プレゼント・ギフトを選ぶ
                  </h2>
                  <div className="subtitle">
                    ごちめしでは、シチュエーションでプレゼント・ギフトにあったお食事券を選べます
                  </div>
                </div>
              </div>
              <div className="section-body" id="situation">
                {listSituations.length <=
                getSlidePerView(innerWidth, swiperConfigs.situation) ? (
                  <div className="situation swiper-container disabled">
                    <div
                      className={
                        loading() ? "swiper-wrapper loading" : "swiper-wrapper"
                      }
                    >
                      {listSituations &&
                        listSituations.length > 0 &&
                        listSituations.map((item, key) => {
                          let index = `situation-${item.menuSituationId}-${key}`;
                          return (
                            <a
                              href
                              tag="a"
                              className="card swiper-slide"
                              key={index}
                              onClick={(e) => {
                                e.preventDefault();
                                handleSearchMenuBySituation(
                                  item.menuSituationName
                                );
                              }}
                            >
                              <div className="card-thumbnail">
                                <div className="thumbnail">
                                  <img
                                    src={item.menuSituationImage}
                                    className="img"
                                    loading="lazy"
                                    alt={item.menuSituationName}
                                  />
                                </div>
                              </div>
                              <div className="card-header heading-xs">
                                <h3 className="title">
                                  {item.menuSituationName}
                                </h3>
                              </div>
                              <div className="card-body over-pc">
                                <p>
                                  {replaceString(
                                    item.menuSituationDescription,
                                    40
                                  )}
                                </p>
                              </div>
                            </a>
                          );
                        })}
                    </div>
                  </div>
                ) : (
                  <Swiper className="situation" {...swiperConfigs.situation}>
                    <div
                      className={
                        loading() ? "swiper-wrapper loading" : "swiper-wrapper"
                      }
                    >
                      {listSituations &&
                        listSituations.length > 0 &&
                        listSituations.map((item, key) => {
                          let index = `situation-${item.menuSituationId}-${key}`;
                          return (
                            <SwiperSlide
                              tag="a"
                              className="card swiper-slide"
                              key={index}
                              onClick={() =>
                                handleSearchMenuBySituation(
                                  item.menuSituationName
                                )
                              }
                            >
                              <div className="card-thumbnail">
                                <div className="thumbnail">
                                  <img
                                    src={item.menuSituationImage}
                                    className="img"
                                    loading="lazy"
                                    alt={item.menuSituationName}
                                  />
                                </div>
                              </div>
                              <div className="card-header heading-xs">
                                <h3 className="title">
                                  {item.menuSituationName}
                                </h3>
                              </div>
                              <div className="card-body over-pc">
                                <p>
                                  {replaceString(
                                    item.menuSituationDescription,
                                    40
                                  )}
                                </p>
                              </div>
                            </SwiperSlide>
                          );
                        })}
                    </div>
                    <SliderPagination />
                  </Swiper>
                )}
              </div>
              <div className="section-footer">
                <a href="/products-purpose/scenes" className="link">
                  <span className="only-pc">シチュエーションを</span>もっと見る
                </a>
              </div>
            </div>
          </section>
        </div>
        {/*SP・PCによって配置替え*/}
        <section className="section">
          <div className="container">
            <div className="section-header">
              <div className="heading-lg">
                <h2 className="title">
                  キャンペーン・特集からプレゼント・ギフトを選ぶ
                </h2>
                <div className="subtitle">
                  ごちめしでは、キャンペーン・特集からプレゼント・ギフトにあったお食事券を探せます
                </div>
              </div>
            </div>
            <div className="section-body" id="specials">
              {listSpecialCampaigns.length <=
              getSlidePerView(innerWidth, swiperConfigs.special) ? (
                <div className="swiper-container disabled">
                  <div
                    className={
                      loadingSpecialCampaigns ? "swiper-wrapper loading" : "swiper-wrapper"
                    }
                  >
                    {listSpecialCampaigns &&
                      listSpecialCampaigns.length > 0 &&
                      listSpecialCampaigns.map((item, key) => {
                        let index = `special-campaign-${key}`;
                        var startDate = item.event_date_start
                          ? formatDate(item.event_date_start)
                          : null;
                        var endDate = item.event_date_end
                          ? formatDate(item.event_date_end)
                          : null;
                        return (
                          <a
                            href
                            // href={`/specials/${item.slug}`}
                            onClick={(e) => {
                              e.preventDefault();
                              handleViewSpecialDetail(item.slug);
                            }}
                            className="card swiper-slide"
                            key={index}
                          >
                            <div className="card-thumbnail">
                              <div className="thumbnail">
                                <img
                                  src={
                                    item.thumbnails?.large?.url
                                      ? item.thumbnails.large.url
                                      : NO_IMAGE
                                  }
                                  className="img"
                                  alt={item.short_title}
                                />
                              </div>
                              {(startDate || endDate) && (
                                <time className="date-area">
                                  {startDate}-{endDate}
                                </time>
                              )}
                            </div>
                            <div className="card-header">
                              <h3>{item.short_title}</h3>
                            </div>
                            <div className="card-body">
                              <p>{item.excerpt}</p>
                            </div>
                            {/* </a> */}
                          </a>
                        );
                      })}
                  </div>
                </div>
              ) : (
                <Swiper {...swiperConfigs.special}>
                  <div
                    className={
                      loadingSpecialCampaigns ? "swiper-wrapper loading" : "swiper-wrapper"
                    }
                  >
                    {listSpecialCampaigns &&
                      listSpecialCampaigns.length > 0 &&
                      listSpecialCampaigns.map((item, key) => {
                        let index = `special-campaign-${key}`;
                        var startDate = item.event_date_start
                          ? formatDate(item.event_date_start)
                          : null;
                        var endDate = item.event_date_end
                          ? formatDate(item.event_date_end)
                          : null;
                        return (
                          <SwiperSlide
                            tag="a"
                            // href={`/specials/${item.slug}`}
                            onClick={() => handleViewSpecialDetail(item.slug)}
                            className="card swiper-slide"
                            key={index}
                          >
                            <div className="card-thumbnail">
                              <div className="thumbnail">
                                <img
                                  src={
                                    item.thumbnails?.large?.url
                                      ? item.thumbnails.large.url
                                      : NO_IMAGE
                                  }
                                  className="img"
                                  alt={item.short_title}
                                />
                              </div>
                              {(startDate || endDate) && (
                                <time className="date-area">
                                  {startDate}-{endDate}
                                </time>
                              )}
                            </div>
                            <div className="card-header">
                              <h3>{item.short_title}</h3>
                            </div>
                            <div className="card-body">
                              <p>{item.excerpt}</p>
                            </div>
                            {/* </a> */}
                          </SwiperSlide>
                        );
                      })}
                  </div>
                  <SliderPagination />
                </Swiper>
              )}

              {/* <div className="swiper-container">
                <div className="swiper-wrapper">

                </div>
                <div className="slider-pagination-area">
                  <div className="slider-btn-group">
                    <button className="slider-btn slider-btn-prev">
                      <svg className="icon">
                        <use xlinkHref="/assets/img/common/icon.svg#angle-left" />
                      </svg>
                    </button>
                    <button className="slider-btn slider-btn-next">
                      <svg className="icon">
                        <use xlinkHref="/assets/img/common/icon.svg#angle-right" />
                      </svg>
                    </button>
                  </div>
                  <div className="swiper-pagination" />
                </div>
              </div> */}
            </div>
            <div className="section-footer">
              <a href="/specials" className="link">
                <span className="only-pc">キャンペーン・特集を</span>もっと見る
              </a>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="section-header">
              <div className="heading-lg">
                <h2 className="title">
                  ジャンルからプレゼント・ギフトを選ぶ
                </h2>
                <div className="subtitle">
                  ごちめしでは、お料理のジャンルからプレゼント・ギフトにあったお食事券を探せます
                </div>
              </div>
            </div>
            <div className="section-body menu">
              {listGenres.length <
              getSlidePerView(innerWidth, swiperConfigs.menu) ? (
                <div className="swiper-container disabled">
                  <div
                    className={
                      loading() ? "swiper-wrapper loading" : "swiper-wrapper"
                    }
                  >
                    {listGenres &&
                      listGenres.length > 0 &&
                      listGenres.map((item, key) => {
                        let index = `genre-${item.menuGenreId}-${key}`;
                        let i = key % 9;
                        return (
                          <a
                            href
                            className="card card-genre swiper-slide"
                            style={{ backgroundColor: `${colors[i]}` }}
                            key={index}
                            onClick={(e) => {
                              e.preventDefault();
                              handleSearchMenuByGenre(item.menuGenreName);
                            }}
                          >
                            <div className="genre-image">
                              <img
                                src={item.menuGenreImage}
                                className="img"
                                alt={item.menuGenreName}
                              />
                            </div>
                            <div className="heading-md">
                              <div className="title">{item.menuGenreName}</div>
                            </div>
                          </a>
                        );
                      })}
                  </div>
                </div>
              ) : (
                <Swiper {...swiperConfigs.menu}>
                  <div
                    className={
                      loading() ? "swiper-wrapper loading" : "swiper-wrapper"
                    }
                  >
                    {listGenres &&
                      listGenres.length > 0 &&
                      listGenres.map((item, key) => {
                        let index = `genre-${item.menuGenreId}-${key}`;
                        let i = key % 9;
                        return (
                          <SwiperSlide
                            tag="a"
                            className="card card-genre swiper-slide"
                            style={{ backgroundColor: `${colors[i]}` }}
                            key={index}
                            onClick={() =>
                              handleSearchMenuByGenre(item.menuGenreName)
                            }
                          >
                            <div className="genre-image">
                              <img
                                src={item.menuGenreImage}
                                className="img"
                                alt={item.menuGenreName}
                              />
                            </div>
                            <div className="heading-md">
                              <div className="title">{item.menuGenreName}</div>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                  </div>
                  <SliderPagination />
                </Swiper>
              )}
            </div>
            <div className="section-footer">
              <a href="/products-purpose/genres" className="link">
                <span className="only-pc">ジャンルを</span>もっと見る
              </a>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="section-header">
              <div className="heading-lg">
                <h2 className="title">ごちめしマガジン</h2>
                <div className="subtitle">
                  ごちめしのおすすめの商品情報やおいしいレポート記事を紹介します。プレゼントやギフトに最適なお食事券を見つけましょう
                </div>
              </div>
            </div>
            <div className="section-body magazine">
              {listMagazines.length <=
              getSlidePerView(innerWidth, swiperConfigs.magazine) ? (
                <div className="swiper-container disabled">
                  <div
                    className={
                      loadingMagazines ? "swiper-wrapper loading" : "swiper-wrapper"
                    }
                  >
                    {listMagazines &&
                      listMagazines.length > 0 &&
                      listMagazines.map((item, key) => {
                        let index = `magazine-${item.post_id}-${key}`;
                        return (
                          <a
                            className="swiper-slide card-magazine"
                            href={`/magazine/articles/${item.post_id}`}
                            key={index}
                          >
                            <div className="card-magazine-thumbnail">
                              <img
                                src={
                                  item.thumbnails &&
                                  item.thumbnails.large &&
                                  item.thumbnails.large.url
                                    ? item.thumbnails.large.url
                                    : NO_IMAGE
                                }
                                alt={item.title}
                              />
                            </div>
                            <div className="card-magazine-header">
                              {item.categories &&
                                item.categories.length > 0 && (
                                  <div className="badge">
                                    {item.categories[0].name}
                                  </div>
                                )}
                              <div className="date">
                                {formatDate(item.modified)}
                              </div>
                              <div className="heading-sm">
                                <div className="title">{item.title}</div>
                              </div>
                            </div>
                          </a>
                        );
                      })}
                  </div>
                </div>
              ) : (
                <Swiper {...swiperConfigs.magazine}>
                  <div
                    className={
                      loadingMagazines ? "swiper-wrapper loading" : "swiper-wrapper"
                    }
                  >
                    {listMagazines &&
                      listMagazines.length > 0 &&
                      listMagazines.map((item, key) => {
                        let index = `magazine-${item.post_id}-${key}`;
                        return (
                          <SwiperSlide
                            className="swiper-slide card-magazine"
                            href={`/magazine/articles/${item.post_id}`}
                            key={index}
                            tag="a"
                          >
                            <div className="card-magazine-thumbnail">
                              <img
                                src={
                                  item.thumbnails &&
                                  item.thumbnails.large &&
                                  item.thumbnails.large.url
                                    ? item.thumbnails.large.url
                                    : NO_IMAGE
                                }
                                alt={item.title}
                              />
                            </div>
                            <div className="card-magazine-header">
                              {item.categories &&
                                item.categories.length > 0 && (
                                  <div className="badge">
                                    {item.categories[0].name}
                                  </div>
                                )}
                              <div className="date">
                                {formatDate(item.modified)}
                              </div>
                              <div className="heading-sm">
                                <div className="title">{item.title}</div>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                  </div>
                  <SliderPagination />
                </Swiper>
              )}
            </div>
            <div className="section-footer">
              <a href="/magazine" className="link">
                <span className="only-pc">ごちめしマガジンを</span>もっと見る
              </a>
            </div>
          </div>
        </section>
        <ListMenus
          title="ごちめしで最近見た商品・お食事券"
          className={loadingRecent && "loading"}
          swiperId="list-recently-view-menu-container"
          data={recentlyViewedMenus.map((menu) => ({
            ...menu,
            price: menu.lastPrice,
          }))}
        />
        <section className="section">
          <div className="container">
            <div className="section-header">
              <div className="heading-lg">
                <h2>お知らせ</h2>
              </div>
            </div>
            <div
              className={loadingNews ? "section-body loading" : "section-body"}
            >
              <ul className="news-list">
                {listNews &&
                  listNews.length > 0 &&
                  listNews.map((item, key) => {
                    let index = `news-${item.post_id}-${key}`;
                    return (
                      <li key={index}>
                        <a
                          href={`/news/${item.post_id}`}
                          className="d-flex align-items-center"
                        >
                          <div>
                            <div className="news-info">
                              <span className="badge">お知らせ</span>
                              <span className="date-area">
                                {formatDate(item.modified)}
                              </span>
                            </div>
                            <div className="heading-xs">
                              <div className="title">{item.title}</div>
                            </div>
                          </div>
                          <svg className="icon only-pc">
                            <use xlinkHref="/assets/img/common/icon.svg#angle-right" />
                          </svg>
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="section-footer">
              <a href="/news" className="link">
                <span className="only-pc">お知らせを</span>もっと見る
              </a>
            </div>
          </div>
        </section>

        <Explanation />
      </main>

      <Footer />

      <ModalGroup>
        <SituationModal />
        <GiftToModal />
        <NotLoggedModal />
        <WishListModal />
        <TopPageModal />
      </ModalGroup>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content, buttonName, gotoUrl) =>
    dispatch(showError(showing, title, content, buttonName, gotoUrl)),
});

export default connect(null, mapDispatchToProps)(TopPage);

import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { CustomPagination } from "../../common/Pagination";
import { ShopSearchGochiKidItem } from "../../common/ShopSearchGochiKidItem";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import GiftService from "../../../../api/services/GiftService";
import {
  toggleFavoriteShop,
  toggleWishListShop,
  useGenres
} from "../../../../utils";
import { showNotLoggedModal } from "../../../../actions/action_show_not_logged_modal";
import { showWishListModal } from "../../../../actions/action_show_wish_list_modal";
import { setTempWishListShopAction } from "../../../../actions/action_wishlist_shop";
import { setTempFavoriteShopAction } from "../../../../actions/action_favorite_shop";
import * as constants from '../../../../constants';

export function GochiKidSelectShop(props) {

  const params = queryString.parse(window.location.search);
  const location = useLocation();
  const history = useHistory();
  const gochiKidGroupId = params.gochiKidGroupId || null;

  const [prefecture, setPrefecture] = useState(params.prefecture || "");
  const [city, setCity] = useState(params.city || "");
  const listPrefectures = useSelector((state) => state.prefectures);
  const listCities = useSelector((state) => state.cities);

  const prefectureId = getPrefectureId(prefecture);
  const filteredCities = listCities.filter(
    (city) => Number(city.prefCode) === prefectureId
  );

  const [genre, setGenre] = useState(params.genre || "");
  const genres = useGenres();
  const [keyword, setKeyword] = useState(params.keyword || "");
  const [gochiKidQrFlag, setGochiKidQrFlag] = useState(params.gochiKidQrFlag || "");
  const [page, setPage] = useState(1);
  const LIMIT = 10;
  const [searchResults, setSearchResults] = useState([]);
  const [totalResult, setTotalResults] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(false);
  const userInfo = useSelector((state) => state.userInfo);
  const isLoggedIn = userInfo && userInfo.userId;
  const dispatch = useDispatch();

  async function getPosition() {
    try {
      let position = null;
      position = await getCoords();
      if (position) {
        setCurrentPosition({
          latitude: position.coords && position.coords.latitude,
          longitude: position.coords && position.coords.longitude,
        });
        return {
          latitude: position.coords && position.coords.latitude,
          longitude: position.coords && position.coords.longitude,
        };
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async function getCoords() {
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(
        (success) => {
          resolve(success);
        },
        (error) => {
          if (error.code === 1) {
            resolve(null);
          }
          reject(error);
        },
        options
      )
    );
  }

  function fetchData(newParams) {
    if (genres.length === 0) return;
    getShops(newParams);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, genres, location.search]);

  function getPrefectureId(name) {
    if (name) {
      const prefectureObj = listPrefectures.find(
        (item) => item.prefectureName === name
      );
      if (prefectureObj) return prefectureObj.prefectureId;
    }
    return "";
  }

  function getGenreId(name) {
    if (name) {
      const genreObj = genres.find((item) => item.menuGenreName === name);
      if (genreObj) return genreObj.menuGenreId;
    }
    return "";
  }

  function handleSearch() {
    const params = [];
    if (prefecture) params.push(`prefecture=${prefecture}`);
    if (city) params.push(`city=${city}`);
    if (genre) params.push(`genre=${genre}`);
    if (keyword) params.push(`keyword=${keyword}`);
    if (gochiKidQrFlag) params.push(`gochiKidQrFlag=${gochiKidQrFlag}`);
    if (gochiKidGroupId) params.push(`gochiKidGroupId=${gochiKidGroupId}`);

    const query = params.join("&");
    history.replace({
      search: `?${query}`,
    });
    if (location.search === `?${query}` && page === 1) {
      getShops();
    }
    setPage(1);
  }

  async function getShops(newParams) {
    const getParams = newParams || params;
    let position = {};
    if (currentPosition) {
      position = currentPosition;
    } else {
      position = await getPosition();
    }
    const parameters = {
      page,
      limit: LIMIT,
      prefectures: getParams.prefecture,
      city: getParams.city,
      genreId: getGenreId(getParams.genre) || undefined,
      keyword: getParams.keyword,
      gochiKidQrFlag: getParams.gochiKidQrFlag || undefined,
      latitude:
        position && position.latitude
          ? position.latitude
          : null,
      longitude:
        position && position.longitude
          ? position.longitude
          : null,
    };
    setLoading(true);
    if (gochiKidGroupId) {
      GiftService.getShopsGroupSpecialGochiKid(parameters, gochiKidGroupId)
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            setSearchResults(response.data.list);
            setTotalResults(response.data.count);
          } else if (response.status === 404) {
            setSearchResults([]);
            setTotalResults(0);
          }
        })
        .catch((e) => {
          setLoading(false);
          console.error("GET LIST SHOPS ERROR: ", e);
        });
    } else {
      GiftService.getShopsGochiKid(parameters)
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            // redmine 116707: hard code hide shop gochi kid have id = 23359
            let listShopResponse = response.data.list;
            // redmine 124279: remove hard code hide shop gochi kid have id = 23359
            //if (listShopResponse) {
            //  listShopResponse = listShopResponse.filter(shop => shop.shopId !== constants.HIDE_SHOP_GOCHI_KID_ID);
            //}
            setSearchResults(listShopResponse);
            setTotalResults(response.data.count);
          } else if (response.status === 404) {
            setSearchResults([]);
            setTotalResults(0);
          }
        })
        .catch((e) => {
          setLoading(false);
          console.error("GET LIST SHOPS ERROR: ", e);
        });
    }
  }

  function handleChangePage(toPage) {
    setPage(toPage);
    window.scrollTo(0, 0);
  }

  const searchParamsArr = [];
  if (params.prefecture) {
    searchParamsArr.push(params.prefecture);
  }
  if (params.city) {
    searchParamsArr.push(params.city);
  }
  if (params.genre) {
    searchParamsArr.push(params.genre);
  }
  if (params.keyword) {
    searchParamsArr.push(params.keyword);
  }
  if (params.gochiKidQrFlag) {
    if (params.gochiKidQrFlag == 1) {
      searchParamsArr.push('対応可');
    } else if (params.gochiKidQrFlag == 0) {
      searchParamsArr.push('対応不可');
    }
  }
  const searchStr = searchParamsArr.join("・");

  const tempShopFavorite = useSelector(state => state.favoriteShop);
  const tempShopWishList = useSelector(state => state.wishListShop);

  function onToggleShopWishList(shopId, current) {
    if (!isLoggedIn) {
      dispatch(showNotLoggedModal(true));
      return;
    }
    const currentState =
      tempShopWishList[shopId] !== undefined
        ? tempShopWishList[shopId]
        : current;
    toggleWishListShop(shopId, currentState, () => {
      dispatch(setTempWishListShopAction(shopId, !currentState));
      if (!currentState) dispatch(showWishListModal(true));
    });
  }

  function onToggleShopFavorite(shopId, current) {
    if (!isLoggedIn) {
      dispatch(showNotLoggedModal(true));
      return;
    }
    const currentState =
      tempShopFavorite[shopId] !== undefined
        ? tempShopFavorite[shopId]
        : current;
    toggleFavoriteShop(shopId, currentState, () => {
      dispatch(setTempFavoriteShopAction(shopId, !currentState));
      if (!currentState) dispatch(showWishListModal(true, true));
    });
  }

  let searchConditionCount = 0;
  params.genre && searchConditionCount++;
  params.prices && searchConditionCount++;
  params.gochiKidQrFlag && searchConditionCount++;
  const isMultiSearchCondition = searchConditionCount > 1;
  const isNoSearchCondition =
    !params.prefecture &&
    !params.city &&
    searchConditionCount === 0 &&
    !params.keyword;

  function getTitle() {
    if (isNoSearchCondition) {
      return "お店一覧｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス";
    }
    if (isMultiSearchCondition || params.keyword)
      return "お店検索結果｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス";
    let ret = "";
    ret = ret + (params.prefecture ? params.prefecture : "");
    ret = ret + (params.city ? params.city : "");
    if (params.genre) {
      ret = (ret ? ret + "の" : "") + params.genre;
    }
    if (params.prices) {
      ret = (ret ? ret + "の" : "") + params.prices;
    }
    ret = ret + "のお店一覧｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス";
    return ret;
  }

  function getMetaDescription() {
    if (isNoSearchCondition) {
      return "お店一覧です。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。";
    }
    if (isMultiSearchCondition || params.keyword)
      return "お店検索結果一覧です。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。";
    let ret = "";
    ret = ret + (params.prefecture ? params.prefecture : "");
    ret = ret + (params.city ? params.city : "");
    if (params.genre) {
      ret = (ret ? ret + "の" : "") + params.genre;
    }
    if (params.prices) {
      ret = (ret ? ret + "の" : "") + params.prices;
    }
    ret =
      ret +
      "のお店一覧です。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。";
    return ret;
  }

  const masterDataIsReady = !(genres.length === 0);

  return (
    <>
      <MetaTags>
        <title>自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content="自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta name="twitter:description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:image" content="/assets/img/common/ogp.png" />
        <meta property="og:site_name" content="自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:title" content="自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content="/assets/img/common/ogp.png" />
        <meta property="og:description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
      </MetaTags>

      <div className="shop-gochi-kid">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch();
          }}
          method="POST"
          className="shops search-area"
        >
          <div className="container">
            <div className="title-search">利用店舗を探す</div>
            <div className="search-body">
              <div className="fieldset fieldset-lg search-genre">
                <div className="fieldset-header">
                  <svg className="icon">
                    <use xlinkHref="/assets/img/common/icon.svg#utensils" />
                  </svg>
                  <div className="title">ジャンル</div>
                </div>
                <div className="fieldset-body">
                  <div className="field field-pill field-sm">
                    <select
                      className="select-box-custom"
                      onChange={(e) => setGenre(e.target.value)}
                      value={genre}
                    >
                      <option value="">選択なし</option>
                      {genres.map((item, index) => (
                        <option value={item.menuGenreName} key={index}>
                          {item.menuGenreName}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* /.field */}
                </div>
              </div>
              {/* /.fieldset */}
              <div className="fieldset fieldset-lg search-keyword">
                <div className="fieldset-header">
                  <svg className="icon">
                    <use xlinkHref="/assets/img/common/icon.svg#search" />
                  </svg>
                  <div className="title">キーワード</div>
                </div>
                <div className="fieldset-body">
                  <div className="field field-pill field-md">
                    <input
                      value={keyword}
                      type="text"
                      placeholder="キーワード"
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                  </div>
                  {/* /.field */}
                </div>
              </div>
              <div className="fieldset fieldset-lg search-qr-shop">
                <div className="fieldset-header">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.31833 7.38023H7.37833V4.32023H4.31833V7.38023ZM8.45828 8.46023H3.23828V3.24023H8.45828V8.46023Z" fill="#35A99E"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6193 7.38023H13.6793V4.32023H10.6193V7.38023ZM14.7591 8.46023H9.53906V3.24023H14.7591V8.46023Z" fill="#35A99E"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.31833 13.6791H7.37833V10.6191H4.31833V13.6791ZM8.45828 14.7591H3.23828V9.53906H8.45828V14.7591Z" fill="#35A99E"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.53906 14.7591V9.53906H14.7591V14.7591H9.53906Z" fill="#35A99E"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0V4.32H1.08V1.08H4.32V0H0Z" fill="#35A99E"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6797 0V1.08H16.9197V4.32H17.9997V0H13.6797Z" fill="#35A99E"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 13.6797V17.9997H4.32V16.9197H1.08V13.6797H0Z" fill="#35A99E"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9197 13.6797V16.9197H13.6797V17.9997H17.9997V13.6797H16.9197Z" fill="#35A99E"/>
                  </svg>
                  <div className="title">カード型会員証対応</div>
                </div>
                <div className="fieldset-body">
                  <div className="field field-pill field-sm">
                    <select
                      className="select-box-custom"
                      onChange={(e) => setGochiKidQrFlag(e.target.value)}
                      value={gochiKidQrFlag}
                    >
                      <option value="">選択なし</option>
                      <option value={1}>対応可</option>
                      <option value={0}>対応不可</option>
                    </select>
                  </div>
                  {/* /.field */}
                </div>
              </div>
              {/* /.fieldset */}
              <div className="fieldset fieldset-submit under-tab">
                <button
                  type="button"
                  className="btn btn-submit search-btn"
                  onClick={handleSearch}
                >
                  検索
                </button>
              </div>
              {/* /.fieldset */}
            </div>
            {/* /.search-body */}
            <div className="search-footer over-pc">
              <button className="btn search-btn" type="button" onClick={handleSearch}>
                検索
              </button>
            </div>
            {/* /.search-footer */}
          </div>
          {/* /.container */}
        </form>
        <div className="container">
          {/* 店舗一覧のHTML */}
          <section
            className={
              !masterDataIsReady || (loading && searchResults.length === 0)
                ? "section loading"
                : "section"
            }
          >
            <div className="section-header">
              <div className="heading-lg">
                <h1 className="title">
                  {searchStr}
                  {searchStr ? "で見つかった店舗：" : "見つかった店舗："}
                  {totalResult || 0}件
                </h1>
              </div>
            </div>
            <div className="section-body">
              <div className="shop-group">
                {searchResults.map((item) => (
                  <ShopSearchGochiKidItem
                    item={item}
                    loading={loading}
                    tempShopWishList={tempShopWishList}
                    onToggleShopWishList={onToggleShopWishList}
                    tempShopFavorite={tempShopFavorite}
                    onToggleShopFavorite={onToggleShopFavorite}
                    handShowErrorUseGochiKidPopup={props.handShowErrorUseGochiKidPopup}
                    userDetails={props.userDetails}
                    gochiKidGroupId={gochiKidGroupId}
                  />
                ))}
              </div>
              {searchResults.length > 0 && (
                <CustomPagination
                  count={Math.ceil(totalResult / LIMIT)}
                  page={page}
                  onChange={handleChangePage}
                />
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

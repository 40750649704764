import React, { Component } from 'react';
import { connect } from "react-redux";

import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import { Modal, Media, ListGroup, Button } from 'react-bootstrap';
import {showLoading} from "../../../actions/action_loading";
import {showError} from "../../../actions/action_error_alert";
import GochiService from "../../../api/services/GochiService";
// import GochiUseStaff from './GochiUseStaff';
import {log} from '../../../utils'; 

class GochiUseInfor extends Component { 
  constructor(){
    super(); 
    this.state = { 
      listData: [],
      count: 0,
      hasMoreItems: true,
      nexHref: 1,
      page: 0,
      limit: 10,
      callApi: true,
      quantity: 1,
      displayGochiUse: true,
      showGochiUseStaff: false,
      childFoodId: 0,
      quantityError: null
    }
    this.fetchData = this.fetchData.bind(this);
    this.getListChildFoods = this.getListChildFoods.bind(this);
		// this.onChangeNumber = this.onChangeNumber.bind(this);
		this.onOpenGochiStaff = this.onOpenGochiStaff.bind(this);
		this.onCloseGochiStaff = this.onCloseGochiStaff.bind(this);
		this.onCloseGochiInfor = this.onCloseGochiInfor.bind(this);
    this.increment = this.increment.bind(this);
    this.decrement = this.decrement.bind(this);
  }

  async fetchData(){
    const maxGochiCount = this.props.gochiInfo ? (this.props.gochiInfo.gochiCountAvailable || this.props.gochiInfo.gochiCount) : 1;
    const gochiInfo = this.props.gochiInfo || {};
    try {
      this.props.showLoading(true);
      var shopId = this.props.gochiInfo.shopId;
      var menuId = this.props.gochiInfo.menuId;
      var page = this.state.nexHref;
      var limit = this.state.limit;

      if(this.state.callApi) {
        this.setState({
          callApi: false,
        });
        let data = await this.getListChildFoods(
          shopId,
          menuId,
          page,
          limit
        );
        if(data.count === 0){
          // this.props.showError(true, "There are no data", "");
          if (maxGochiCount === 1 || this.props.sushiroFlag === 1) { 
            this.onOpenGochiStaff(gochiInfo);
          }
        } else {
          var list = this.state.listData;
          this.setState({
            count: data.count,
          });
          data.list.map(data => {
            return list.push(data);
          });

          if(page*limit < data.count) {
            this.setState({
              nexHref: page + 1,
              listData: list,
              hasMoreItems: true,
              callApi: true
            });
          } else {
            this.setState({
              hasMoreItems: false,
              nexHref: 1
            });
          }
        }
      }
      this.props.showLoading(false);
    } catch (e) {
      log(e);
      this.props.showLoading(false);
      this.props.showError(true, this.props.t("There is something wrong"), "");
    }
  }

  getListChildFoods = async (
    shopId,
    menuId,
    page,
    limit
  ) => {
    try {
      const {data} = await GochiService.getChildFoods({
        shopId,
        menuId,
        page,
        limit
      });
      return data;
    } catch (error) {
      log('getListChildFoods error');
      this.props.showError(true, this.props.t("There is something wrong"), "");
    }
  };

  // onChangeNumber(e) {
  //   this.setState({
  //     quantity: e.target.value
  //   });
  // }

  increment() {
    const maxGochiCount = this.props.gochiInfo ? (this.props.gochiInfo.gochiCountAvailable || this.props.gochiInfo.gochiCount) : 1;
    let value = this.state.quantity;
    value = value + 1;
    if (value <= (maxGochiCount)) {
      this.setState({
        quantity: value
      });
    }
  }

  decrement() {
    let value = this.state.quantity;
    value = value - 1;
    if (value >= 1) {
      this.setState({
        quantity: value
      });
    }
  }

  componentDidMount() {
    const maxGochiCount = this.props.gochiInfo ? (this.props.gochiInfo.gochiCountAvailable || this.props.gochiInfo.gochiCount) : 1;
    const url = window.location.search;
    let params = queryString.parse(url);
    const accessToken = params.accessToken ? params.accessToken : "";
    var userInfo = this.props.userInfo;

    if (userInfo || accessToken) {
      if ((userInfo.isLogined && !userInfo.isGuest) || accessToken) {
        if(this.props.gochiInfo.menuChildType === 3) {
          this.fetchData();
        } else if (this.props.gochiInfo && (maxGochiCount === 1 || this.props.sushiroFlag === 1)) {
          this.onOpenGochiStaff(this.props.gochiInfo);
          // sessionStorage.setItem('usingFlowGochiInfo', JSON.stringify(this.props.gochiInfo));
        }
      }
    }
  }

  onOpenGochiStaff(gochiInfo) {
    if (this.state.quantity === 0) {
      this.setState({
        quantityError: this.props.t('pages.gochiuse.Please select at least one')
      });
    } else {
      this.setState({
        showGochiUseStaff: true,
        childFoodId: gochiInfo && gochiInfo.childFoodId,
        displayGochiUse: false
      });
  
      const url = window.location.search;
      let params = queryString.parse(url);
      const shopId = params.shopId;
      console.log('params', params);
      const accessToken = params.accessToken ? "&accessToken=" + params.accessToken : "";
      const siteType = params.siteType ? "&siteType=" + params.siteType : "";
      // const gochiBillId = params.gochiBillId;
  
      var usingUrl = "/gochiuse/staff";
      sessionStorage.setItem('quantityWillBeUsed', this.state.quantity);
      sessionStorage.setItem('usingFlowGochiInfo', JSON.stringify(gochiInfo));
      if(shopId) {
        window.location.href = usingUrl+`?shopId=${shopId}&menuId=${gochiInfo && gochiInfo.menuId}${accessToken}${siteType}`;
      } else {
        window.location.href = usingUrl+`?gochiBillId=${gochiInfo.gochiBillId}${accessToken}${siteType}`;
      }
    }
    
  }

  onCloseGochiStaff() {
    this.props.onCloseGochiInfo();
		this.setState({
      quantity: 1,
      listData: [],
      nexHref: 1,
      callApi: true,
      hasMoreItems: true,
      count: 0,
      showGochiUseStaff: false,
      displayGochiUse: false
		});
	}

  onCloseGochiInfor() {
    this.props.onCloseGochiInfo();
		this.setState({
      quantity: 1,
      listData: [],
      nexHref: 1,
      callApi: true,
      hasMoreItems: true,
      count: 0,
      displayGochiUse: false
		});
  }

  render() {
    const gochiInfo = this.props.gochiInfo || {};
    const maxGochiCount = this.props.gochiInfo ? (this.props.gochiInfo.gochiCountAvailable || this.props.gochiInfo.gochiCount) : 1;
    // let optionNums = [];

    // for (var i = 1; i <= gochiInfo.gochiCount; i++) {
    //   optionNums.push(<option key={i}>{i}</option>);
    // }

    const onOpenGochiStaff = this.onOpenGochiStaff;

    // const loader = <div className="loader" key="loader">Loading ...</div>;

    const { t } = this.props;
    console.log('gochiInfo=====', gochiInfo);
    const isShowPrice = gochiInfo.showPrice !== 0;

    return ( 
      <>
        <Modal 
          size="sm" 
          show={this.state.displayGochiUse}
          onHide={this.onCloseGochiInfor} 
          animation={false} 
          aria-labelledby="contained-modal-title-vcenter"
          // centered
          className="modal-gochi"
          backdrop="static"
        > 
          <Modal.Header className="header-close" closeButton />
          <Modal.Body className="gochi-content">
            {gochiInfo.shopStatus === 'CLOSED' &&
              <div className="shop-closed">
                {t('pages.menu.This shop has closed')}
              </div>
            }
            <div className="gochi-info dish-infor">
              <div className="gochi-infor-shop mb-2 mt-1">{t('pages.tome.Number of tickets used')}</div>
              <div className="media">
                <div className="dish-image">
                  <figure className="mb-0 dish-fig">
                    <img
                      src={gochiInfo.menuImage || (gochiInfo.gochiBillImage.length > 0 && gochiInfo.gochiBillImage[0].image)}
                      alt={gochiInfo.menuName || gochiInfo.menu.menuName}
                      width="100%"
                    />
                  </figure>
                  {gochiInfo.menuCombo === 1 && (
                    <div>
                        <div className="triangle-label">
                          <span>{t('pages.invite.Coupon')}</span>
                        </div>
                        {gochiInfo.menuComboDetail && gochiInfo.quantityFree ? 
                            <div className="bonus-label">
                                <span>+{gochiInfo.menuComboDetail && gochiInfo.quantityFree.toLocaleString()}{t('pages.invite.Free of charge')}</span>
                            </div>
                        : null }
                    </div>
                  )}
                </div>

                <div className="media-body dish-body pl-2">
                  <div className="mt-0 dish-link fs-19">
                    <span className="fs-16 clr-636363 font-weight-normal">{gochiInfo.shopName || gochiInfo.groupName}</span>
                    <br />
                    <span>{gochiInfo.menuName || gochiInfo.menu.menuName}</span>
                    {isShowPrice && (
                      <>
                        <div className="line-horizontal menu-name-ln pd-bold"></div>
                        <div className="fs-19">
                          <span>1{t('pages.invite.pieces')}</span>
                          <span className="pl-3">{gochiInfo.displayAmount ? gochiInfo.displayAmount.toLocaleString() : gochiInfo.includedTaxPrice && gochiInfo.includedTaxPrice.toLocaleString()}</span>
                          <span className="fs-13">{t('pages.invite.Yen Unit')}</span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="line-horizontal menu-name-ln pd-bold mt-2 mb-3"></div>
                        
              <div className="text-center">
                <div className="d-flex align-items-center justify-content-center">
                  {/* <Form.Control 
                    as="select" 
                    className="form-number mr-2" 
                    onChange={this.onChangeNumber}
                    value={this.state.quantity}
                  >
                    {optionNums}
                  </Form.Control> */}
                  <button type="button" className="btn btn-outlined-light btn-quantity-change" onClick={this.increment}>+</button>
                  <input 
                    className={`col-form-label border-0 text-center w-60 bgclr-ffffff mr-1 ml-1`} 
                    // readOnly 
                    // disabled 
                    min={1}
                    type="number"
                    name="quantity" 
                    value={this.state.quantity}
                    onChange={e => {
                      let value = e.target.value;
                      if (!value || value === '') {
                        this.setState({quantity: 0});
                      } else if (parseInt(value) > parseInt(maxGochiCount)) {
                        this.setState({quantity: parseInt(maxGochiCount)});
                      } else {
                        this.setState({quantity: parseInt(value)});
                      }
                    }}
                  ></input>
								  <button type="button" className="btn btn-outlined-light btn-quantity-change" onClick={this.decrement}>-</button>
                  <span className="fs-16 ml-1">
                    / <span className="ml-1">{maxGochiCount}</span> {t('pages.gochiuse.Order feast')}
                  </span>
                </div>
                {this.state.quantityError &&
									<div className="text-danger fs-14 mt-2 mb-2 fs-12">{this.state.quantityError}</div>
								}
              </div>

              {this.state.listData && this.state.listData.length === 0 && (
                <div className="text-center">
                  <button className="btn btn-green-38 w-100p mt-3" onClick={() => {this.onOpenGochiStaff(gochiInfo)}}>
                    {t('pages.gochiuse.Proceed to order')}
                  </button>
                </div>
              )}

              {this.state.listData && this.state.listData.length > 0 && (
                <div className="list-childfood">
                  <div className="ttl-childfood fs-16 text-center font-weight-bold text-green mt-3 mb-3">
                    {t('pages.gochiuse.Please select one of the children menus')}
                  </div>

                  <ListGroup className="list-items">
                    {this.state.listData.map(function(childFood, key) { 
                      const gochiUseInfo = { ...gochiInfo, childFoodId: childFood.childFoodId };
                      return (
                        <ListGroup.Item key={childFood.childFoodId} className="shop-item">
                          <Media>
                            <img
                              width={66}
                              className="mr-2"
                              src={childFood.childFoodImage && 
                                (childFood.childFoodImage.length > 0) 
                                  ? childFood.childFoodImage[0].image 
                                  : require("../../../assets/img/icons/gochi_noimg-ver4.jpg")} 
                              alt={childFood.childFoodName}
                            />
                            <Media.Body>
                              <h5 className="shop-name">{childFood.childFoodName}</h5>
                              <div className="descript">
                                {childFood.childFoodDescription}
                              </div>

                              <div className="mt-2">
                                <Button 
                                  variant="success"
                                  className="btn btn-gochi w-100 ex-bg-green"
                                  onClick={() => onOpenGochiStaff(gochiUseInfo)}
                                >
                                  {t('pages.gochiuse.Proceed to order')}
                                </Button>
                              </div>
                            </Media.Body>
                          </Media>
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                  {this.state.hasMoreItems && (
                    <div className="text-center mt-3">
                      <Button variant="link" className="btn btn-load-more" onClick={this.fetchData}>{t('pages.login.Load more')}</Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
        
        {/* {this.state.showGochiUseStaff ? (
          <GochiUseStaff
            showing={this.state.showGochiUseStaff} 
            onCloseGochiStaff={this.onCloseGochiStaff}
            gochiInfo = {gochiInfo}
            quantity = {this.state.quantity}
            childFoodId = {this.state.childFoodId}
          />
        ) : null} */}
        
      </>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content) => dispatch(showError(showing, title, content)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GochiUseInfor));

import React, { Component } from "react";   
import {Link, Redirect} from 'react-router-dom'; 
import {connect} from 'react-redux'; 
import { withTranslation } from 'react-i18next';
import {log, addParamUrl} from '../../../utils'; 
import PaymentService from "../../../api/services/PaymentService";
import {savePaymentMethod} from "../../../actions/action_payment";
import {showLoading} from "../../../actions/action_loading";
import {showError} from "../../../actions/action_error_alert";
import queryString from 'query-string'
// import Header from "../../layout/header/Header";
import { Modal } from "react-bootstrap";

class PaymentList extends Component {
	constructor(){
	    super(); 
	    this.state = { 
	    	menuId: null,
            redirectToReferrer: false, 
			paymentList: [],
			src: "",
            paramShashoku: '',
			shopId: null,
			isSite: ''
        }
	    this.handleSubmit = this.handleSubmit.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
	}

	async fetchPaymentList(userId) { 
		try { 
			if (userId) {
				this.props.showLoading(true);
				var paymentList = await this.getPaymentList(userId);
			    this.setState({
			    	paymentList: paymentList
			    }) 
			    this.props.showLoading(false);
			} 
		} catch (e) {
			log(e); 
		}		
	}

	getPaymentList = async (
		userId 
		) => { 
			try {
			  	const { data } = await PaymentService.getPaymentList(userId);
			  	return data;
			} catch (error) { 
			  	log('getPaymentList error'); 
			  	this.props.showError(true, this.props.t("There is something wrong"), "");
			}
	};

	UNSAFE_componentWillMount(){
		let url = window.location.search;
		let params = queryString.parse(url);
		var menuId = params.id; 
        var src = params.src;
		var shopId = params.shopId;
        const fromShashoku = params.fromShashoku;
		const referer = params.referer;
		const isSite = params.isSite;

		if (src) {
			this.setState({
				src: src
			});
		}

		if (isSite) {
			this.setState({
				isSite: isSite
			});
		}

		this.setState({
			menuId: menuId,
			referer,
        });
        
        if (fromShashoku) {
            this.setState({
				paramShashoku: `&fromShashoku=${encodeURIComponent(fromShashoku)}`
			});
        }

		if (shopId) {
			this.setState({
				shopId: shopId
			});
		}
	}

	componentDidMount(){
		var userInfo = this.props.userInfo;
    	if (userInfo) {
    		if (userInfo.userId) {
	            this.fetchPaymentList(userInfo.userId);
	        }
    	}
		
	}

	handleSubmit(e){
	    e.preventDefault();  
		var token = e.target.elements.paymentSelect.value;

		var paymentList = this.state.paymentList.list; 

		var payment = paymentList.find(function(element) {
		  return element.token === token;
		}); 

		sessionStorage.setItem('paymentMethod', JSON.stringify(payment)); 

		this.props.savePaymentMethod(payment); 
		this.setState({ 
            redirectToReferrer: true
        })
	}

	handleCancel(){
		this.setState({
			redirectToReferrer: true,
		});
	}

    render() {
		console.log('payment list ====', this.state.paymentList);
		// return console.log(this.props)
		var userInfo = this.props.userInfo;
		var srcParam = this.state.src ? '&src=' + this.state.src : '';
		const refererParam = this.state.referer ? `&referer=${encodeURIComponent(this.state.referer)}` : '';
		srcParam += refererParam;
		var isSiteParam =  this.state.isSite ? {name: 'isSite', value: this.state.isSite} : {};

    	var linkLogin = addParamUrl("/menu?id=" + this.state.menuId + srcParam, [isSiteParam]);

    	if (userInfo) { 
    		if (userInfo.isLogined === false) {
				if (!this.state.menuId && this.state.shopId) {
					window.history.pushState(null, null, addParamUrl('invite/shopId=' + this.state.shopId, [isSiteParam]));
					return <Redirect to={addParamUrl('/login', [isSiteParam])} />
				} else {
					window.history.pushState(null, null, addParamUrl('/payment-list?id=' + this.state.menuId + srcParam + this.state.paramShashoku, [isSiteParam]));
	           		return <Redirect to={linkLogin} />
				}
	        }

			if (userInfo.isGuest) {
				if (!this.state.menuId && this.state.shopId) {
					window.history.pushState(null, null, addParamUrl('invite/shopId=' + this.state.shopId, [isSiteParam]));
					return <Redirect to={addParamUrl('/login', [isSiteParam])} />
				} else {
					window.history.pushState(null, null, addParamUrl('/payment-input?id=' + this.state.menuId + srcParam + this.state.paramShashoku, [isSiteParam]));
					return <Redirect to={addParamUrl("/invite?id=" + this.state.menuId + srcParam + this.state.paramShashoku, [isSiteParam])} />
				}
			}
    	} else {
			if (!this.state.menuId && this.state.shopId) {
				window.history.pushState(null, null, addParamUrl('invite/shopId=' + this.state.shopId, [isSiteParam]));
				return <Redirect to={addParamUrl('/login', [isSiteParam])} />
			} else {
				window.history.pushState(null, null, addParamUrl('/payment-list?id=' + this.state.menuId + srcParam + this.state.paramShashoku, [isSiteParam]));
    			return <Redirect to={linkLogin} />
			}
    	} 


    	var link = "";
		if (this.state.referer) {
			link = this.state.referer;
		} else
		if (!this.state.menuId && this.state.shopId) {
			link = addParamUrl("/invite?shopId=" + this.state.shopId, [isSiteParam]);
		} else {
			link = addParamUrl("/invite?id=" + this.state.menuId + srcParam + this.state.paramShashoku, [isSiteParam]);
		}
		const redirectToReferrer = this.state.redirectToReferrer;
        if (redirectToReferrer === true) {
        	window.history.pushState(null, null, addParamUrl('/payment-list?id=' + this.state.menuId + srcParam + this.state.paramShashoku, [isSiteParam]));
            return <Redirect to={link} />
        }

    	const { t } = this.props;

    	var paymentList = this.state.paymentList;   

        return (
        	<div className="content">
				<Modal
					className="modal-list-payments"
					size="sm"
					show
					onHide={this.handleCancel}
				>
					<Modal.Body>
						{/* <Header title=
						{(this.props.shopInfo.shopName || (this.props.groupInfo ? this.props.groupInfo.groupName : ''))}
						{t('pages.invite.Payment method selection')}
						isLoggedIn={isLoggedIn}
						t={t}/> */}
						<div className="form-block mt-0">
							<form onSubmit={this.handleSubmit}>  
								<div className="form-group lst-payments mt-2">
									<div className="title-lst-shop align-center">{t('pages.invite.Payment method selection')}</div> 

									<div className="list-payment mt-4">
										<div className="card">
											{(paymentList.count > 0 && userInfo.isGuest === false) &&
												<div className="card-body">
													<ul className="lst-payment-ul">  
														{paymentList.list.map(function(card, key){
															return <li key={ key }> 
																<label className="box-payment w-100 mb-0">
																	<div className="radio-custom">
																		<input type="radio" name="paymentSelect" value={card.token} defaultChecked={card.defaultFlag}/>
																		<span className="checkmark"></span> 
																	</div>

																	<div className="payment-info ">
																		<div className="fs-16">{card.brand}****-****-****-{card.last4}</div>
																		<div className="fs-16 font-weight-bold">{card.holder}</div>
																		<div className="payment-date">
																			<div className="">
																				<span>{t('pages.invite.expiredDate')}: </span>
																				<span>{card.expireMonth.toString().padStart(2, '0')}/{card.expireYear}</span>
																			</div> 
																		</div>
																	</div> 
																</label>
															</li>
														})}
													</ul>
												</div>
											}
										</div>
									</div> 
									<div className="select-custom mt-2 d-flex align-items-center">
										<div className="icon-plus mr-2">+</div>
										<Link
											to={!this.state.menuId && this.state.shopId ? addParamUrl(`/payment-input?shopId=`+this.state.shopId + refererParam, [isSiteParam]) : addParamUrl(`/payment-input?id=`+this.state.menuId + srcParam + this.state.paramShashoku, [isSiteParam])}
											className="fs-16 clr-000000"
											style={{textDecoration:"underline"}}>
												{t('pages.invite.Choose a new payment method')}
										</Link>
									</div> 
								</div> 

								<div className="form-group text-center mt-4">
									<button className="btn btn-green-38" disabled={this.state.paymentList.length === 0 || (this.state.paymentList.list && this.state.paymentList.list.length === 0)}>
										{t('pages.invite.Confirm payment method')}
									</button>
								</div>

								<div className="align-center">
									<button type="button" className="btn btn-close-popup" onClick={this.handleCancel}>
										{t('pages.mypage.title button cancel')}
									</button>
								</div>
							</form>
						</div>
					</Modal.Body>
				</Modal>
        		
			</div>
        ); 
    }
}
 
const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({  
	savePaymentMethod: (paymentMethod) => dispatch(savePaymentMethod(paymentMethod)),
    showLoading: (showing) => dispatch(showLoading(showing)),
    showError: (showing, title, content) => dispatch(showError(showing, title, content)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PaymentList)); 
import React from "react";
import moment from 'moment';
import * as constants from "../../../../constants";

export function HistoryChargeGochi(props) {
  const currentGochiBillId = props.currentGochiBillId;

  return (
    <div
      id={5342}
      className={`modal modal-profile modal-profile-01 modal-01 modal-lg history-modal ${props.showPopupHistoryChargeGochi ? 'is-show' : ''}`}
      tabIndex={-1}
    >
      <div className="modal-header-history">
        <button
          className="modal-btn modal-btn-close"
          id="modalClose"
          onClick={props.handleClosePopupHistoryChargeGochi}
          type="button"
        >
          <svg className="icon">
            <title>閉じる</title>
            <use xlinkHref="/assets/img/common/icon.svg#times" />
          </svg>
        </button>
      </div>
      <div className="title-modal-charge">
        <div className="title">チャージ履歴</div>
      </div>
      <div className={`modal-body`}>
        {props.listHistoryChargeGochi?.length > 0 && props.listHistoryChargeGochi?.map(item => {
          let payment;
          if (item?.paymentType) {
            if (item.paymentType === constants.PAYMENT_TYPE_STRIPE || item.paymentType === constants.PAYMENT_TYPE_VERITRANS) {
              payment = "クレジットカード";
            } else if (item.paymentType === constants.PAYMENT_TYPE_PAYPAY) {
              payment = "Paypay";
            } else if (item.paymentType === constants.PAYMENT_TYPE_ONEPAY_ALIPAY) {
              payment = "Alipay";
            } else if (item.paymentType === constants.PAYMENT_TYPE_ATONE) {
              payment = "Atone";
            } else if (item.paymentType === constants.PAYMENT_TYPE_SELF_CHECKOUT) {
              payment = "";
            } 
          } else {
            payment = "QRコード";
          }

          return (
            <div className="history-box" key={item.gochiChargeLogId}>
              <div className="p-3">
                <div className="history-line">
                  <div className="title-lst-history">日付・日時</div>
                  <div className="content-lst-history">{moment(item.createdAt).format('YYYY年MM月DD日 H時mm分')}</div>
                </div>
                <div className="my-8px history-line">
                  <div className="title-lst-history">決済方法</div>
                  <div className="content-lst-history">{payment}</div>
                </div>
                <div className="history-line">
                  <div className="title-lst-history">決済金額</div>
                  <div className="content-lst-history">
                    <span>{item.chargeAmount.toLocaleString()}</span>
                    <span>円</span>
                  </div>
                </div>
                <div className="history-line my-8px">
                  {item.premiumRate === 0 ? (
                    <div className="title-lst-history extra-small-title1">利用可能金額</div>
                  ) : (
                    <div className="title-lst-history extra-large-content1">利用可能金額<br/>（プレミアム付き）</div>
                  )}
                  <div className="content-lst-history extra-small-title">
                    <span>{item.premiumAmount.toLocaleString()}</span>
                    <span>円</span>
                  </div>
                </div>
                <div className="history-line">
                  <div className="title-lst-history extra-small-title1">※利用可能期間</div>
                  <div className="content-lst-history extra-large-content1">
                    <span>{moment(item.useStartAt).format('YYYY年MM月DD日') + "〜" + moment(item.useEndAt).format('YYYY年MM月DD日')}</span>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className={`history-load-more ${props.loadingHistoryCharge ? 'text-decoration-none' : ''}`}>
        {(props.pageHistoryChargeGochi + 1) <= Math.ceil(props.totalHistoryChargeGochi/props.pageLimit) && (
          <span className={`cursor-pointer fs-15 align-center ${props.loadingHistoryCharge ? 'loading' : ''}`} onClick={() => props.loadMoreListHistoryChargeGochi(currentGochiBillId)}>もっと見る</span>
        )} 
      </div>
    </div>
  );
}

export default {
  apiKey: "AIzaSyCHbFn3-Sv8dzdw7KSduc-FeWxJ-R-VMuY",
  authDomain: "gochimeshi-firebase.firebaseapp.com",
  databaseURL: "https://gochimeshi-firebase.firebaseio.com",
  projectId: "gochimeshi-firebase",
  storageBucket: "gochimeshi-firebase.appspot.com",
  messagingSenderId: "736530560804",
  appId: "1:736530560804:web:97b684d71d5de7912465b1",
  measurementId: "G-597217NMM7"
}

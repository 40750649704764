import React, { useState } from "react";
import { Button } from "react-bootstrap";

export function GiftCodeConfirmModal(props) {
  //const { t } = props;

  return (
    <div
      className={
        props.isShow
          ? "custom-modal-container show-modal"
          : "custom-modal-container"
      }
      size="sm"
    >
      <div
        className={
          props.isShow
            ? "modal modal-action modal-dialog border-radius-4 is-show"
            : "modal modal-action modal-dialog border-radius-4"
        }
      >
        <div className="d-flex w-100 modal-header-charge">
          <div className="d-flex justify-content-center align-items-center">
            <i className="icon-service" />
          </div>
          <div className="header-ttl pr-5 fs-12 ml-2 clr-000000 font-weight-bold">
            チャージ金額の確認
          </div>
        </div>
        <div className="modal-body modal-body-charge">
          <div className="form-group lst-payments mt-2">
            <div className="fs-18 modal-content-charge font-weight-bold">{props.messageQrCode}</div>
            {props.hasSubmit && (
              <div>
                {!props.isError && (
                <div className="text-center btn-submit-box width-100">
                  <Button
                    variant="btn btn-green-38 width-100 border-radius-4 fs-16"
                    onClick={props.handleSubmitCode}
                  >
                    チャージを確定する
                  </Button>
                </div>
                )}
                <div className="text-center btn-back">
                  <span
                    className="cursor-pointer fs-15 align-center font-weight-normal text-decoration-underline"
                    onClick={props.onHide}
                  >
                    キャンセル
                  </span>
                </div>
              </div>
            )}
            {props.isSuccess && (
              <div className="text-center btn-submit-box width-100">
                <Button
                  variant="btn btn-green-38 width-100 border-radius-4 fs-16"
                  onClick={props.backToMyPage}
                >
                  マイページに戻る
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div onClick={props.onHide} className="back-drop" />
    </div>
  );
}

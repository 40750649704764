import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

// // // the translations
import { resources } from "./lang/locales/index";

const fallbackLng = ["ja"];
const availableLanguages = ["en", "ja", "zh", "ja_gs"];

export function getLanguage() {
  let browserLang = navigator.language || navigator.userLanguage;

  console.log("browserLangbrowserLang", browserLang);

  if (browserLang.includes("en")) {
    browserLang = "en";
  } else if (browserLang.includes("zh")) {
    browserLang = "zh";
  } else {
    browserLang = "ja";
  }

  let langUser = browserLang;
  if (localStorage.getItem("lang")) {
    langUser = localStorage.getItem("lang");
  } else {
    localStorage.setItem("lang", browserLang);
  }

  return langUser;
}

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: getLanguage(),
    resources,
    fallbackLng: fallbackLng,
    debug: true,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;

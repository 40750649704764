import React from "react";

export function PlaceHolderMessageShop(props) {
  return (
    <>
      {props.isShow && (
        <div className="place-holder-messsage-shop">
          {props.listPlaceHolder?.map((messsage, index) => {
            return (
              <span key={index}>
                {messsage}
                <br />
              </span>
            );
          })}
        </div>
      )}
    </>
  );
}

export default PlaceHolderMessageShop;

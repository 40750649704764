import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";

class AtoneDescriptionPopup extends Component {
  render() {
    return (
      <div
        className={
          this.props.show
            ? "custom-modal-container show-modal"
            : "custom-modal-container"
        }
        size="sm"
      >
        <div
          className={
            this.props.show
              ? "modal modal-action modal-dialog gochi-charge-atone-description is-show"
              : "modal modal-action modal-dialog gochi-charge-atone-description"
          }
        >
          <button
            className="modal-btn modal-btn-close"
            // id="modalClose"
            onClick={this.props.handleClose}
            type="button"
          >
            <svg className="icon">
              <title>閉じる</title>
              <use xlinkHref="/assets/img/common/icon.svg#times" />
            </svg>
          </button>
          <div className="title-atone">atone説明文</div>
          <div className="modal-body p-2">
            <div className="form-block mt-0">
              atone (アトネ) は誰でもすぐに使える翌月払いです。今月のご利用分をまとめて、翌月にコンビニまたは口座振替でお支払いいただけます。
              <br />
              <br />
              200円で1ポイント、atoneのお買い物に使えるお得なNPポイントが貯まります。詳細は <a href="https://atone.be/?_ga=2.15931327.881973822.1667963581-773980648.1667874220" target="_blank" rel="noopener noreferrer">atone の公式ページ</a>をご覧ください。
              <br />
              <br />
              ■お支払い方法
              <br />
              お支払いに必要な番号を、お買い物の翌月初旬にメールでご案内いたします。Loppiなどのコンビニ端末、または銀行ATM (Pay-easy) で、翌月10日までにお支払いください。
              <br />
              お支払い方法は以下4つから選べます。スマホアプリまたはWebマイページから、月末までにお手続きください。
              <br />
              <br />
              (1) コンビニ端末 / 銀行ATM (Pay-easy) ※標準設定
              <br />
              (2) 電子バーコード (コンビニ)
              <br />
              (3) はがき請求書 (コンビニ)
              <br />
              (4) 口座振替
              <br />
              ※コンビニ端末 / 銀行ATM (Pay-easy) は、セブンイレブンではお支払いいただけません。
              <br />
              ※電子バーコード・はがき請求書は、セブンイレブンでもお支払いいただけます。
              <br />
              <br />
              ■注意事項
              <br />
              ・決済手数料は無料です。
              <br />
              ・ご利用月のみ請求手数料209円(税込)が発生いたします。 (口座振替の場合は無料)
              <br />
              ・支払期限日は支払い方法によって異なります。
              <br />
              ・代金は株式会社ネットプロテクションズの会員規約に基づき指定の方法で請求いたします。
            </div>
          </div>
        </div>
        <div onClick={this.props.handleClose} className="back-drop" />
      </div>
    );
  }
}

const mapStateToProps = state => ({
	...state
});

export default connect(mapStateToProps)(
  withTranslation()(AtoneDescriptionPopup)
);

import { HTTP_API, makeRequest } from "../HttpClient";

const API_BASE_URL = process.env.REACT_APP_BASE_URL || "";
const API_GCMS_WP_URL = process.env.REACT_APP_GCMS_WP_URL || "";

export default class GiftService {
  //START API GCMS WP
  static async getNews(page, perPage) {
    const url = API_GCMS_WP_URL + "/news";
    return makeRequest(HTTP_API.GET, url, { page: page, per_page: perPage }, false, null, null, true, true);
  }

  static async getNewsDetail(postId) {
    const url = API_GCMS_WP_URL + "/news/" + postId;
    return makeRequest(HTTP_API.GET, url, null, false, null, null, true, true);
  }

  static async getNewsMonth(year, page, perPage) {
    const url = API_GCMS_WP_URL + "/news/archive/" + year;
    return makeRequest(HTTP_API.GET, url, { page: page, per_page: perPage }, false, null, null, true, true);
  }

  static async getArchive() {
    const url = API_GCMS_WP_URL + "/news/archive/";
    return makeRequest(HTTP_API.GET, url, null, false, null, null, true, true);
  }

  static async getMagazines(page, perPage) {
    const url = API_GCMS_WP_URL + "/magazine";
    return makeRequest(HTTP_API.GET, url, { page: page, per_page: perPage }, false, null, null, true, true);
  }

  static async getCategory() {
    const url = API_GCMS_WP_URL + "/magazine/category";
    return makeRequest(HTTP_API.GET, url, null, false, null, null, true, true);
  }

  static async getTag() {
    const url = API_GCMS_WP_URL + "/magazine/tag";
    return makeRequest(HTTP_API.GET, url, null, false, null, null, true, true);
  }

  static async getCategoryMagazines(categoryId, subCategoryId, page, perPage) {
    const url = API_GCMS_WP_URL + "/magazine/category/" + categoryId;
    return makeRequest(HTTP_API.GET, url, { or: subCategoryId, page: page, per_page: perPage }, false, null, null, true, true);
  }

  static async getTagMagazines(tagId, subTagId, page, perPage) {
    const url = API_GCMS_WP_URL + "/magazine/tag/" + tagId;
    return makeRequest(HTTP_API.GET, url, { or: subTagId, page: page, per_page: perPage }, false, null, null, true, true);
  }

  static async getMagazineDetail(postId) {
    const url = API_GCMS_WP_URL + "/magazine/" + postId;
    return makeRequest(HTTP_API.GET, url, null, false, null, null, true, true);
  }

  static async getSpecialPickup() {
    const url = API_GCMS_WP_URL + "/special/pickup";
    return makeRequest(HTTP_API.GET, url, null, false, null, null, true, true);
  }

  static async getSpecialCampaigns(page, perPage) {
    const url = API_GCMS_WP_URL + "/special";
    return makeRequest(HTTP_API.GET, url, { page: page, per_page: perPage }, false, null, null, true, true);
  }

  static async getSpecialCampaignsDetail(slug) {
    const url = API_GCMS_WP_URL + "/special/slug/" + slug;
    return makeRequest(HTTP_API.GET, url, null, false, null, null, true, true);
  }

  static async getFooterBanners() {
    const url = API_GCMS_WP_URL + "/common";
    return makeRequest(HTTP_API.GET, url, null, false, null, null, true, true);
  }
  //END API GCMS WP

  static async getUserDetails(userId, loginUserId, accessToken, checkTokenExpired) {
    const url = API_BASE_URL + "/users/" + userId;
    return makeRequest(HTTP_API.GET, url, { loginUserId }, null, null, null, null, null, accessToken, checkTokenExpired);
  }

  static async getUserWishList(userId, page, perPage) {
    const url = API_BASE_URL + "/gift/wishlist/" + userId;
    return makeRequest(HTTP_API.GET, url, { page: page, perPage: perPage});
  }

  static async getUserFavoriteList(userId, page, perPage) {
    const url = API_BASE_URL + "/gift/favourite/" + userId;
    return makeRequest(HTTP_API.GET, url, { page: page, perPage: perPage});
  }

  static async getPurchaseHistory({ page, perPage, filterMode}) {
    let params = [];
    params.push(`page=${page}`);
    params.push(`perPage=${perPage}`);
    if (filterMode) {
      params.push(`filterMode=${filterMode}`);
    }
    var url = `${API_BASE_URL}/gift/gochi/history?${params.join("&")}`;
    return makeRequest(HTTP_API.GET, url);
  }

  static async getGochiToMe({
    gochiUserType,
    couponCountGte,
    gochiUserId,
    sort,
    page,
    limit,
    filter
  }){
    let params = [];
    if (gochiUserType) {
      gochiUserType.forEach((userType) => {
      params.push(`gochiUserType=${userType}`);
      });
    }
    if (couponCountGte) {
      params.push(`couponCountGte=${couponCountGte}`);
    }
    if (gochiUserId) {
      params.push(`gochiUserId=${gochiUserId}`);
    }
    if (sort) {
      params.push(`sort=${sort}`);
    }
    params.push(`page=${page}`);
    params.push(`limit=${limit}`);
    if (filter) {
      params.push(`filter=${filter}`);
    }

    var url = `${API_BASE_URL}/gift/gochi/toMe?${params.join("&")}`;
    return makeRequest(HTTP_API.GET, url);
  }

  static async getGochiSubscription({ page, perPage}) {
    let params = [];
    params.push(`page=${page}`);
    params.push(`limit=${perPage}`);
    var url = `${API_BASE_URL}/subscriptions?${params.join("&")}`;
    return makeRequest(HTTP_API.GET, url);
  }

  static async getSituations() {
    const url = API_BASE_URL + "/gift/menus/situations";
    return makeRequest(HTTP_API.GET, url, { page: 1, perPage: 1000 });
  }

  static async getUserTargets() {
    const url = API_BASE_URL + "/gift/menus/userTargets";
    return makeRequest(HTTP_API.GET, url, { page: 1, perPage: 1000 });
  }

  static async getGenres() {
    const url = API_BASE_URL + "/gift/menus/genres";
    return makeRequest(HTTP_API.GET, url, { page: 1, perPage: 1000 });
  }

  static async getPriceRanges() {
    const url = API_BASE_URL + "/gift/priceRange";
    return makeRequest(HTTP_API.GET, url, {}, true, undefined, {
      "Access-Control-Allow-Origin": "*",
      "Allow-Control-Allow-Methods": "DELETE, POST, PUT, GET, OPTIONS",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
      acceptLanguage: "ja_gs",
      "Cache-Control": "no-cache, no-store, max-age=0, must-revalidate"
    },);
  }

  static async getMenus(params) {
    const url = API_BASE_URL + "/gift/menus";
    return makeRequest(HTTP_API.GET, url, params);
  }

  static async getShops(params) {
    const url = API_BASE_URL + "/gift/shops";
    return makeRequest(HTTP_API.GET, url, params);
  }

  static async getShopDetails(shopId, flowGochiKidFlag = null, userIdForGochiKid = null, gochiKidGroupId = null) {
    let params = {}
    if (flowGochiKidFlag) {
      params.flowGochiKidFlag = flowGochiKidFlag;
    }
    if (userIdForGochiKid) {
      params.userIdForGochiKid = userIdForGochiKid;
    }
    if (gochiKidGroupId) {
      params.gochiKidGroupId = gochiKidGroupId;
    }
    const url = API_BASE_URL + "/v2/childShop/shops/" + shopId;
    return makeRequest(HTTP_API.GET, url, params);
  }

  static async getListMenuOfShop(params) {
    const url = API_BASE_URL + "/gift/menu/shop/" + params.shopId;
    return makeRequest(HTTP_API.GET, url, params);
  }

  static async getListCouponMessagesOfShop(params, accessToken, checkTokenExpired) {
    const url = API_BASE_URL + `/gift/shops/${params.shopId}/comments`;
    return makeRequest(HTTP_API.GET, url, params, null, null, null, null, null, accessToken, checkTokenExpired);
  }

  static async getListCouponMessagesOfMenu(params) {
    const url = API_BASE_URL + `/gift/menus/${params.menuId}/comments`;
    return makeRequest(HTTP_API.GET, url, params);
  }

  static async getRecentlyViewMenus(accessToken, checkTokenExpired, flowGochiKidFlag = null) {
    let params = {
      page: 1, 
      perPage: 25
    }
    if (flowGochiKidFlag) {
      params.flowGochiKidFlag = flowGochiKidFlag;
    }
    const url = API_BASE_URL + "/gift/menus/recentlyViewed";
    return makeRequest(HTTP_API.GET, url, params, null, null, null, null, null, accessToken, checkTokenExpired);
  }

  static async getRecentlyViewMenusGuest(menuIds, flowGochiKidFlag = null) {
    let params = {
      menuIds
    }
    if (flowGochiKidFlag) {
      params.flowGochiKidFlag = flowGochiKidFlag;
    }
    const url = API_BASE_URL + "/gift/menus/recentlyViewedGuest?page=1&perPage=25";
    return makeRequest(HTTP_API.POST, url, params)
  }

  static async postRecentlyViewMenu(menuId) {
    const url = API_BASE_URL + "/gift/menus/recentlyViewed/create";
    return makeRequest(HTTP_API.POST, url, { menuId });
  }

  static async postShopFavorite(shopId) {
    const url = API_BASE_URL + "/favorites/shops/create";
    return makeRequest(HTTP_API.POST, url, { shopId });
  }

  static async postShopWishList(shopId) {
    const url = API_BASE_URL + "/gift/wishlist/shops/create";
    return makeRequest(HTTP_API.POST, url, { shopId });
  }

  static async deleteShopFavorite(shopId) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const userId = userInfo?.userId;
    const url = API_BASE_URL + "/favorites/shops/destroy";
    return makeRequest(HTTP_API.POST, url, { shopId, userId });
  }

  static async deleteShopWishList(shopId) {
    const url = API_BASE_URL + "/gift/wishlist/shop/" + shopId;
    return makeRequest(HTTP_API.DELETE, url, { shopId });
  }

  static async postMenuFavorite (menuId) {
    const url = API_BASE_URL + "/gift/favourite/menus/create";
    return makeRequest(HTTP_API.POST, url, { menuId });
  }

  static async postMenuWishList (menuId) {
    const url = API_BASE_URL + "/gift/wishlist/menus/create";
    return makeRequest(HTTP_API.POST, url, { menuId });
  }

  static async deleteMenuFavorite(menuId) {
    const url = API_BASE_URL + "/gift/favourite/menu/" + menuId;
    return makeRequest(HTTP_API.DELETE, url, { menuId });
  }

  static async deleteMenuWishList(menuId) {
    const url = API_BASE_URL + "/gift/wishlist/menu/" + menuId;
    return makeRequest(HTTP_API.DELETE, url, { menuId });
  }

  static async followUser(targetFollowId) {
    const url = API_BASE_URL + "/follow/create";
    return makeRequest(HTTP_API.POST, url, { targetFollowId });
  }

  static async unfollowUser(targetFollowId) {
    const url = API_BASE_URL + "/follow/destroy";
    return makeRequest(HTTP_API.POST, url, { targetFollowId });
  }

  static async getGochiChargeHistory({
    gochiUserId,
    page,
    limit
  }){
    let params = [];
    if (gochiUserId) {
      params.push(`gochiUserId=${gochiUserId}`);
    }
    params.push(`page=${page}`);
    params.push(`limit=${limit}`);
    var url = `${API_BASE_URL}/gochi-charge/gochi/history?${params.join("&")}`;
    return makeRequest(HTTP_API.GET, url);
  }

  static async checkGiftCode(giftCode, menuId, gochiBillId) {
    const url = API_BASE_URL + "/gochi-charge/gift-code/check";
    return makeRequest(HTTP_API.POST, url, { giftCode, menuId, gochiBillId });
  }

  static async useGiftCode(giftCode, menuId, gochiBillId, userId) {
    const url = API_BASE_URL + "/gochi-charge/gift-code/use";
    return makeRequest(HTTP_API.POST, url, { giftCode, menuId, gochiBillId, userId});
  }

  static async getListHistoryChargeGochi(gochiBillId, page, limit) {
    let params = [];
    if (gochiBillId) {
        params.push(`gochiBillId=${gochiBillId}`);
    }
    params.push(`page=${page}`);
    params.push(`limit=${limit}`);
    let url = `${API_BASE_URL}/gochi-charge/history-charged?${params.join("&")}`;
    return makeRequest(HTTP_API.GET, url);
  }

  static async getListHistoryUsedGochiCharge(gochiBillId, page, limit) {
    let params = [];
    if (gochiBillId) {
        params.push(`gochiBillId=${gochiBillId}`);
    }
    params.push(`page=${page}`);
    params.push(`limit=${limit}`);
    let url = `${API_BASE_URL}/gochi-charge/history-used?${params.join("&")}`;
    return makeRequest(HTTP_API.GET, url);
  }

  // API gochi kid
  static async uploadThankMessage(params) {
    const url = API_BASE_URL + "/gochi-kid/my-page/thank-message";
    return makeRequest(HTTP_API.POST, url, params);
  }

  static async getShopsGochiKid(params) {
    const url = API_BASE_URL + "/gochi-kid/my-page/shops";
    return makeRequest(HTTP_API.GET, url, params);
  }

  static async getGroupSpecialGochiKid(params, accessToken, checkTokenExpired) {
    const url = API_BASE_URL + "/gochi-kid/my-page/group-special";
    return makeRequest(HTTP_API.GET, url, params, null, null, null, null, null, accessToken, checkTokenExpired);
  }

  static async getShopsGroupSpecialGochiKid(params, gochiKidGroupId) {
    const url = API_BASE_URL + `/gochi-kid/my-page/group-special/${gochiKidGroupId}/shops`;
    return makeRequest(HTTP_API.GET, url, params);
  }

  static async getListMenuGochiKidOfShop(params, accessToken, checkTokenExpired) {
    const url = API_BASE_URL + "/gochi-kid/my-page/menus";
    return makeRequest(HTTP_API.GET, url, params, null, null, null, null, null, accessToken, checkTokenExpired);
  }

  static async getListGochiKidUsed(params) {
    const url = API_BASE_URL + "/gochi-kid/my-page/history-use";
    return makeRequest(HTTP_API.GET, url, params);
  }
  static async getUserKidData(userId, accessToken, checkTokenExpired) {
    const url = API_BASE_URL + "/gochi-kid/my-page/user-kid/" + userId;
    return makeRequest(HTTP_API.GET, url, null, null, null, null, null, null, accessToken, checkTokenExpired);
  }

  static async getUserKidGochiKidData(params, accessToken, checkTokenExpired) {
    const url = API_BASE_URL + "/gochi-kid/my-page/history-use";
    return makeRequest(HTTP_API.GET, url, params, null, null, null, null, null, accessToken, checkTokenExpired);
  }

  static async postRegisterUserKid(params) {
    const url = API_BASE_URL + "/gochi-kid/my-page/user-kids";
    return makeRequest(HTTP_API.POST, url, params);
  }

  static async getUserKidCanUses(accessToken, checkTokenExpired) {
    const url = API_BASE_URL + "/gochi-kid/my-page/user-kid/can-use-menu";
    return makeRequest(HTTP_API.GET, url, null, null, null, null, null, null, accessToken, checkTokenExpired);
  }

  static async getUserKidCanUsesGroupSpecial(gochiKidGroupId, accessToken, checkTokenExpired) {
    const url = API_BASE_URL + `/gochi-kid/my-page/group-special/${gochiKidGroupId}/user-kid/can-use-menu`;
    return makeRequest(HTTP_API.GET, url, null, null, null, null, null, null, accessToken, checkTokenExpired);
  }

  static async getGochiKidSupportData(params) {
    const url = API_BASE_URL + "/gochi-kid/my-page/donator/history";
    return makeRequest(HTTP_API.GET, url, params);
  }

  static async getGochiKidThankMessageData(params) {
    const url = API_BASE_URL + "/gochi-kid/my-page/donator/message";
    return makeRequest(HTTP_API.GET, url, params);
  }

  static async getGochiKidSubscriptionData(params) {
    const url = API_BASE_URL + "/gochi-kid/my-page/donator/subscription";
    return makeRequest(HTTP_API.GET, url, params);
  }

  static async getGochiKidDonateStaticData(userId) {
    const url = API_BASE_URL + "/gochi-kid/my-page/donator/static/" + userId;
    return makeRequest(HTTP_API.GET, url);
  }

  static async postCancelSubGochiKid(merchantPaymentId) {
    const url = API_BASE_URL + "/gochi-kid/donate/subscription/cancel/" + merchantPaymentId;
    return makeRequest(HTTP_API.POST, url, null);
  }

  static async getGochiKidReportShopMessageData(params) {
    const url = API_BASE_URL + "/gochi-kid/my-page/shops/report";
    return makeRequest(HTTP_API.GET, url, params);
  }
}

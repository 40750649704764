export const getUserTargetsSuccess = (list = []) => {
    return {
      type : 'GET_USER_TARGETS_SUCCESS',
      payload: list 
    }
  };

export const setModalUserTargetAction = (modalUserTarget = null) => {
    return {
        type: 'SET_MODAL_USER_TARGET',
        payload: modalUserTarget
    }
}

export const setUserTargetAction = (userTarget = null) => {
    return {
        type: 'SET_USER_TARGET',
        payload: userTarget
    }
}
import React, { Component } from "react";   
import {Redirect} from 'react-router-dom';    
import * as constants from '../../../constants'; 
import {log} from '../../../utils'; 
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'; 
import UserService from "../../../api/services/UserService"; 
import {selectFriend} from "../../../actions/action_select_friend";
import InfiniteScroll from 'react-infinite-scroller';
import queryString from 'query-string'; 
import Header from "../../layout/header/Header";

class SelectFriends extends Component {
	constructor(){
	    super(); 
	    this.state = { 
            redirectToReferrer: false,
            selectedFriend: [],
            selectedFriendId: null,
            showListFriend: false,
            friendType: null,
            menuId: null,
            listData: [],
            hasMoreItems: true,
            nextHref: 1,
            keyWord: "",
            sort: "displayName",
            page: 0,
            limit: 10,
            loginUserId: null,
            filterName: "followerUserId",
            textButtonSort: "",
            textButtonFilter: "",
			errorMessage: "",
			src: "",
            paramShashoku: '',
			shopId: null
        };
	    this.handleSelectFriend = this.handleSelectFriend.bind(this);
	    this.handleSearchFriend = this.handleSearchFriend.bind(this);
	    this.handleSelectOutSysUser = this.handleSelectOutSysUser.bind(this);
	    this.handleSelectFriendChange = this.handleSelectFriendChange.bind(this);
	    this.fetchData = this.fetchData.bind(this);
	    this.onChangeInput = this.onChangeInput.bind(this);
	    this.getListFriends = this.getListFriends.bind(this); 
	    this.handleSelectFriendChange = this.handleSelectFriendChange.bind(this); 
	    this.onKeyPress = this.onKeyPress.bind(this); 
	    this.onSortList = this.onSortList.bind(this); 
	    this.onFilterList = this.onFilterList.bind(this); 
	}

	handleSelectFriend(e){
	    e.preventDefault(); 
	    const { t } = this.props; 
	    if (e.target.elements.selectFriend) {
		    this.setState({ 
	    		selectedFriendId: e.target.elements.selectFriend.value
	    	}); 
		    this.fetchFriendInfo(e.target.elements.selectFriend.value); 
		    this.setState({ 
	            redirectToReferrer: true,
	            errorMessage: ""
	        })
	    }else{
	    	this.setState({
	    		errorMessage: t('pages.invite.Please select a user')
	    	})
	    }
	} 

	async fetchFriendInfo(friendId) { 
		try { 
			var selectFriend = {};
			if (friendId) { 
				var friendInfo = await this.getFriendInfo(friendId);   
			    this.props.selectFriend(friendId, constants.FRIEND_IN_SYS, friendInfo, constants.GOCHI_TYPE_USER); 

			    selectFriend = {
			   		selectedFriendId: friendId,
			   		friendType: constants.FRIEND_IN_SYS,
			   		friendInfo: friendInfo,
			   		gochiUserType: constants.GOCHI_TYPE_USER
			   	}

			   	sessionStorage.setItem('selectFriend', JSON.stringify(selectFriend));
			} 
		} catch (e) {
			log(e); 
		}		
	}

	getFriendInfo = async (
		userId 
		) => { 
			try {
			  	const { data } = await UserService.getFriendInfo(userId);    
			  	return data;
			} catch (error) { 
			  	log('getFriendInfo error'); 
			}
	};

	handleSelectFriendChange(event) { 
	    this.setState({selectedFriendId: event.target.value})   
	}

	handleSearchFriend(e) {
		e.preventDefault(); 

		this.setState({
			listData: [],
			keyWord: this.state.keyWord,
			errorMessage: ""
		});

		if (this.state.filterName === "allUser" && this.state.keyWord === "") {
			return ;
		}
		this.fetchData();

		this.setState({ 
    		showListFriend: true
    	});  

    	this.setState({ 
            redirectToReferrer: false
        })
	}

	onKeyPress(e) {
	    if(e.which === 13) {
	      this.handleSearchFriend(e);
	    }
	}

	handleSelectOutSysUser(e) {
		e.preventDefault();  
		var gochiUserType = constants.GOCHI_TYPE_NON_USER;
		var friendInfo = {};
		this.props.selectFriend(null, constants.FRIEND_OUT_SYS, friendInfo, gochiUserType);  

		var selectFriend = {
	   		selectedFriendId: null,
	   		friendType: constants.FRIEND_OUT_SYS,
	   		friendInfo: friendInfo,
	   		gochiUserType: gochiUserType
	   	}

	   	sessionStorage.setItem('selectFriend', JSON.stringify(selectFriend));
	    this.setState({ 
            redirectToReferrer: true
        })
	}  

	onSortList(e){
		this.setState({
			sort: e.target.value,
			textButtonSort: e.target.innerText,
			page: 0,
			nextHref: 1
		})
	}

	onFilterList(e){
		this.setState({
			filterName: e.target.value,
			textButtonFilter: e.target.innerText,
			page: 0,
			nextHref: 1,
			keyWord: ""
		})
		if (e.target.value === "allUser"){
			e.preventDefault();
			return this.setState({
				listData: [],
				showListFriend: false
			})
		}
	}

	async fetchData(){ 
        try {
			var keyWord = this.state.keyWord;
			var sort = this.state.sort;  
			var page = this.state.nextHref;  
			var limit = this.state.limit;   
			var loginUserId = this.props.userInfo ? this.props.userInfo.userId : null ;   
			var filterName = this.state.filterName;   

			// if(keyWord){
				let data = await this.getListFriends(keyWord, sort, page, limit, loginUserId, filterName); 
				if(data.count === 0) {
			 
				} else { 
					var list = this.state.listData;  
					data.list.map(data => {
		                return list.push(data);
		            });  
		            
					if(page*limit < data.count) {
		                this.setState({
		                    nextHref: page + 1,
		                    listData: list,
		                    hasMoreItems: true                             
		                });
		            } else {
		                this.setState({
		                    hasMoreItems: false,
							nextHref: 1
		                });
		            }
				} 
			// }
		} catch (e) {
			log(e); 
		}	 
	}


	getListFriends = async (
		keyWord,
		sort,
		page,
		limit,
		loginUserId,
		filterName
		) => { 
			try {
			  	const { data } = await UserService.getFriendList({
				    keyWord,
					sort,
					page,
					limit,
					loginUserId,
					filterName
			  	});   

			  	return data;
			} catch (error) { 
			  	log('getListFriends error'); 
			}
	};

	onChangeInput(e) {
		this.setState({
			keyWord: e.target.value,
			nextHref: 1
		})
	} 

	UNSAFE_componentWillMount(){   
		let url = window.location.search;
		let params = queryString.parse(url);
		var menuId = params.id;
		var shopId = params.shopId;
		var src = params.src;
        const fromShashoku = params.fromShashoku;

		if (src) {
			this.setState({
				src: src
			});
		}

		this.setState({
			menuId: menuId
        });
        
        if (fromShashoku) {
            this.setState({
				paramShashoku: `&fromShashoku=${encodeURIComponent(fromShashoku)}`
			});
        }

		if (shopId) {
			this.setState({
				shopId: shopId
			});
		}
	}

	componentDidMount() {
		this.fetchData();
	}

    render() {
    	let selectedFriend = null;  
		var userInfo = this.props.userInfo;
		var srcParam = this.state.src ? '&src=' + this.state.src : '';
    	// var link = "/invite?id=" + this.state.menuId + srcParam + this.state.paramShashoku; 
        var linkLogin = "/menu?id=" + this.state.menuId + srcParam + this.state.paramShashoku;
        let isLoggedIn = false; 
		if (userInfo) {
            if (userInfo.userId) {
                isLoggedIn = true;
            } 
    		if (userInfo.isLogined === false) { 
				if (!this.state.menuId && this.state.shopId) {
					window.history.pushState(null, null, '/select-friends?shopId=' + this.state.shopId + srcParam + this.state.paramShashoku);
					return <Redirect to={'/login'} />
				} else {
					window.history.pushState(null, null, '/select-friends?id=' + this.state.menuId + srcParam + this.state.paramShashoku);
					return <Redirect to={linkLogin} />
				}
	        } 

	        if (userInfo.isGuest === true) { 
				if (!this.state.menuId && this.state.shopId) {
					window.history.pushState(null, null, '/select-friends?shopId=' + this.state.shopId + srcParam + this.state.paramShashoku);
					return <Redirect to={"/invite?shopId=" + this.state.shopId + srcParam + this.state.paramShashoku} />
				} else {
					window.history.pushState(null, null, '/select-friends?id=' + this.state.menuId + srcParam + this.state.paramShashoku);
					return <Redirect to={"/invite?id=" + this.state.menuId + srcParam + this.state.paramShashoku} />
				}
	        } 
    	} else {
			if (!this.state.menuId && this.state.shopId) {
				linkLogin = "/login";
			}
    		return <Redirect to={linkLogin} />
    	}

		var link = "";
		if (!this.state.menuId && this.state.shopId) {
			link = "/invite?shopId=" + this.state.shopId + srcParam + this.state.paramShashoku;
		} else {
			link = "/invite?id=" + this.state.menuId + srcParam + this.state.paramShashoku;
		}

		const redirectToReferrer = this.state.redirectToReferrer;
        if (redirectToReferrer === true) { 
        	window.history.pushState(null, null, '/select-friends?id=' + this.state.menuId + srcParam + this.state.paramShashoku);
            return <Redirect to={link} />
        }

        const { t } = this.props;  

        const loader = <div className="loader" key="loader">Loading ...</div>;
	    let listFriends; 
	    if(this.state.listData){  
	    	listFriends = this.state.listData.map(function(Friend, key){  
	        	return <li key={ Friend.userId }>
                	<label className="friend-row w-100 mb-0">
    					<div className="media">
                            <img 
                                className="friend-ava" 
                                src={ Friend.userImage[0] ? 
                                        Friend.userImage[0].image : 
                                        require("../../../assets/img/icons/gochi_noimg-ver4.jpg") }  
                                alt={ Friend.name } 
                                width="40" 
                                height="40" 
                            />
						  	<div className="media-body">
						    	<div className="friend-ttl">{ Friend.displayName }</div>
						    	<div className="friend-desc ">
						    		<div className="friend-desc-txt">@{Friend.accountId}</div> 
						    	</div>
						    </div>
						  	<div className="ml-auto media-right">
						  		<div className="radio-custom"> 
						  			<input type="radio"  
						  				name="selectFriend" 
						  				defaultChecked={(!selectedFriend) ? (key === 0 && true) : (selectedFriend === Friend.userId && true)} 
						  				defaultValue={ Friend.userId }  
						  				id={ `friendID_`+Friend.userId } 
						  			/>
						  			<span className="checkmark"></span>
						  		</div>
						  	</div>
						</div>
    				</label> 
                </li>;
	      	});
	    }

        return (
        	<div className="content"> 
                <Header 
                    title={(this.props.shopInfo.shopName || (this.props.groupInfo ? this.props.groupInfo.groupName : ''))}
					isLoggedIn={isLoggedIn}
					t={t}
					hasBack={true}
                />

				<form onSubmit={this.handleSearchFriend} className="select-block-header">
					<div className="select-block-center mt-2">
				    	<div className="d-flex align-items-center mb-2">
				    		<label className="ttl-label mr-2 mb-0 clr-000000">
				    			{t('pages.invite.userSelectionList')} 
				    		</label>
				    	</div>

				    	<div className="mt-1">
				    		<div className="form-search">
				    			<input className="form-control" 
				    					placeholder={t('pages.invite.enterUserName')} 
				    					value={this.state.keyWord}
				    					onChange={this.onChangeInput}
				    					onKeyPress={this.onKeyPress}
				    			/>
				    			<button className="btn btn-form-search" >
				    				<i className="icon-search"></i>
				    			</button>
				    		</div>
				    	</div>

				    	<div className="mt-2">
				    		<div className="row no-gutters">
				    			<div className="col-5">
				    				<div className="dropdown d-flex">
										<button className="btn btn-friends" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> 
										    {
												(this.state.keyWord === "") ?
										    		this.state.textButtonFilter ? this.state.textButtonFilter : t('pages.invite.Follow User')
												:
													t('pages.invite.All users')
										    }
											<FontAwesomeIcon icon={faChevronDown} className="mr-2 ml-auto" />
										</button>

										<div className="dropdown-menu menu-option" aria-labelledby="dropdownMenuLink">
											<button className="dropdown-item" value="followerUserId" onClick={this.onFilterList}>{t('pages.invite.Follow User')}</button>
											<button className="dropdown-item" value="followUserId" onClick={this.onFilterList}>{t('pages.invite.Follower User')}</button>
										    <button className="dropdown-item" value="allUser" onClick={this.onFilterList}>{t('pages.invite.All users')}</button>
										</div>
									</div>
				    			</div>
				    			<div className="col-7 pl-3">
				    				<div className="dropdown">
										<button className="btn btn-friends one-line-button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										    <div className="mr-2 sort-text">
												{this.state.textButtonSort ? this.state.textButtonSort : t('pages.invite.Username-ascending')}
											</div>
											<FontAwesomeIcon icon={faChevronDown} className="mr-2 ml-auto" />
										</button>

										<div className="dropdown-menu menu-option" aria-labelledby="dropdownMenuLink">
										    <button className="dropdown-item" value="displayName" onClick={this.onSortList}>{t('pages.invite.Username-ascending')}</button>
										    <button className="dropdown-item" value="displayNameDesc" onClick={this.onSortList}>{t('pages.invite.Username-descending')} </button>
										    <button className="dropdown-item" value="createdAtDesc" onClick={this.onSortList}>{t('pages.invite.Recently registered')} </button>
										    <button className="dropdown-item" value="gochiAddCountDesc" onClick={this.onSortList}>{t('pages.invite.Falling number')} </button>
										    <button className="dropdown-item" value="gochiUseCountDesc" onClick={this.onSortList}>{t('pages.invite.Number frustrated')} </button>
										</div>
									</div>
				    			</div>
				    		</div>
				    	</div>
				    </div>
			    </form>

        		<form onSubmit={e => this.handleSelectFriend(e)} onChange={this.handleSelectFriendChange}  className="select-block"> 


					{(this.state.listData.length > 0) ?
						<div className="infinite-scroll" id="scrollableDiv">
							<InfiniteScroll
								pageStart={0}
								loadMore={this.fetchData}
								hasMore={this.state.hasMoreItems}
								loader={loader}
								height={450}
								scrollabletarget="scrollableDiv"
							> 
									<div className="select-block-body m-0">
										<div className="lst-friends">  
											<ul style={{minHeight:450}}>
												{listFriends}
											</ul>
										</div> 
									</div>
							</InfiniteScroll> 
						</div>
		    				
				        :
							(!this.state.showListFriend)
							?
								<div className="select-block-body">
									<div className="lst-friends h-100 mt-3">
										<div className="init-lst mt-2" key="noResult">
											<div className="init-lst-box">
												<div className="text-center fs-15 font-weight-bold mt-3 clr-000000">{t('pages.invite.enterUsernameAndSearch')}</div>

												{/* <div className="text-center mt-3 mb-3">
													<img src={require('../../../assets/img/icons/img-search@2x.png')} width="60" alt="Login" />
												</div> */}

												{this.state.errorMessage &&
													<div className="fs-14 text-danger font-weight-bold text-center mt-2">
														{this.state.errorMessage}
													</div>
												}

												<div className="text-center mt-5 fs-13 clr-000000 space-pre-line">
													{t('pages.invite.displaySearchResult')}
												</div>
											</div>
										</div>
									</div>
								</div>
							:
								<div className="select-block-body">
									<div className="lst-friends h-100 mt-3">
										<div className="init-lst mt-2" key="noResult">
											<div className="init-lst-box">
												<div className="text-center fs-15 font-weight-bold mt-3 clr-000000">{t('pages.invite.noResultTitle')}</div>

												{/* <div className="text-center mt-3 mb-3">
													<img src={require('../../../assets/img/icons/img-search@2x.png')} width="60" alt="Login" />
												</div> */}

												{this.state.errorMessage &&
													<div className="fs-14 text-danger font-weight-bold text-center mt-2">
														{this.state.errorMessage}
													</div>
												}

												<div className="text-center mt-5 fs-13 clr-000000 space-pre-line">
													{t('pages.invite.noResultContent')}
												</div>
											</div>
										</div>
									</div>
								</div>
				    	
	    				}
				    		  
			    	<div className="select-block-btm">
			    		<div className="select-block-btm-center"> 
				    		<button className="btn btn-green-38">
				           		{t('pages.invite.confirmUser')} 
				          	</button>
							{/* <div className="nhung"></div> */}
				        </div>
			    	</div>
			    </form>
			</div>
        ); 
    }
}
 

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({ 
	// showError: (title, content) => dispatch(showError(true, title, content)),
	selectFriend: (selectedFriendId, friendType, friendInfo, gochiUserType) => dispatch(selectFriend(selectedFriendId, friendType, friendInfo, gochiUserType))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SelectFriends));
import React from "react";
import MetaTags from 'react-meta-tags';
import { CustomPagination } from "../../common/Pagination";
import * as constants from "../../../../constants";
import moment from "moment";

export function GochiKidSupportContinue(props) {
  return (
    <>
      <MetaTags>
        <title>自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content="自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta name="twitter:description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:image" content="/assets/img/common/ogp.png" />
        <meta property="og:site_name" content="自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:title" content="自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content="/assets/img/common/ogp.png" />
        <meta property="og:description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
      </MetaTags>

      <hr className="mt-30" />
      <div className="gochi-kid-support-list" id="scrollToElementId">
        <div className={`profile-history ${props.loadingGochiCharge ? 'loading': ''}`}>
          <div className="products-group products-group-column">
            <div className="section-header">
              <div className="heading-lg">
                <h1 className="title-gochi-kid-support">
                  継続支援状況
                </h1>
              </div>
            </div>
            <div className="donated-box">
            {props.searchResults.length > 0 ? props.searchResults.map((item, key) => {
              let index = `${item.gochiKidSubscriptionId}-${key}`;
              let image = '';
              const shopName = item.shopName ? item.shopName : null;
              const groupName = item.groupName ? item.groupName : null;

              let payment;
              if (item?.paymentType) {
                if (item.paymentType === constants.PAYMENT_TYPE_STRIPE || item.paymentType === constants.PAYMENT_TYPE_VERITRANS) {
                  payment = "クレジットカード";
                } else if (item.paymentType === constants.PAYMENT_TYPE_PAYPAY) {
                  payment = "Paypay";
                } else if (item.paymentType === constants.PAYMENT_TYPE_ONEPAY_ALIPAY) {
                  payment = "Alipay";
                } else if (item.paymentType === constants.PAYMENT_TYPE_ATONE) {
                  payment = "Atone";
                } else if (item.paymentType === constants.PAYMENT_TYPE_SELF_CHECKOUT) {
                  payment = "";
                } 
              } else {
                payment = "";
              }

              let destinationName = '';
              let destination = '';
              if (item.subscriptionDestination === 2) {
                destination = '自治体/活動';
              } else if (item.subscriptionDestination === 1) {
                destination = '都道府県';
              } else if (!item.subscriptionDestination) {
                destinationName = '全国';
                destination = '全国';
              }

              if (item.groupId) {
                destinationName = item.groupName;
                image = item.groupImages && item.groupImages[0].image ? item.groupImages[0].image : props.NO_IMAGE_MENU
              } else if (item.shopId) {
                destinationName = item.shopName;
                image = item.shopImages && item.shopImages[0].image ? item.shopImages[0].image : props.NO_IMAGE_MENU
              }

              const dataCancelSub = {
                subscriptionDestination: item.subscriptionDestination,
                destination: destination,
                destinationName: destinationName,
                merchantPaymentId: item.merchantPaymentId
              }

              return (
                <article className="products gochi-kid-item" key={index}>
                  <div className="gochi-kid-support-item">
                    <dl className="list-description text-sm">
                      <div className="list-description-item">
                        <dt className="title font-weight-normal">支援開始日</dt>
                        <dd className="text font-weight-normal">{item.createdAt ? moment(item.createdAt).format('YYYY年MM月DD日') : ''}</dd>
                      </div>
                      <div className="list-description-item">
                        <dt className="title font-weight-normal">前回支援日</dt>
                        <dd className="text font-weight-normal">{item.lastDonate ? moment(item.lastDonate).format('YYYY年MM月DD日') : ''}</dd>
                      </div>
                      <div className="list-description-item">
                        <dt className="title font-weight-normal">次回支援日</dt>
                        <dd className="text font-weight-normal">{item.nextDonate ? moment(item.nextDonate).format('YYYY年MM月DD日') : ''}</dd>
                      </div>
                      <div className="list-description-item">
                        <dt className="title font-weight-normal description-gochi-charge">支援先</dt>
                        <dd className="text font-weight-normal">{destination}</dd>
                      </div>
                      {item.subscriptionDestination !==0 && (
                        <div>
                          <div className="gochi-kid-digest">
                            <div className="gochi-kid-thumbnail">
                              <div className="thumbnail">
                                <img
                                  src={image}
                                  alt="menu image"
                                  loading="lazy"
                                />
                              </div>
                            </div>
                            <div className="shop-header-group">
                              <div className="shop-header">
                                {shopName || groupName}
                              </div>
                              <hr />
                              {item.subscriptionDestination !== 1 && (
                                <>
                                  <div className="address">
                                    <svg className="icon">
                                      <title>住所</title>
                                      <use xlinkHref="/assets/img/common/icon.svg#map-marker" />
                                    </svg>
                                    {`${item.prefectures || ''} ${item.city || ''} ${item.address || ''} ${item.building || ''}`}
                                  </div>
                                  <div className="phone">
                                    <img className="icon" src='/assets/img/common/icon-phone.svg' alt=''/>
                                    {item.phone}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="gochi-kid-shop-des">
                           {item.description}
                          </div>
                        </div>
                      )}
                      <div className="list-description-item">
                        <dt className="title font-weight-normal">支援金額</dt>
                        <dd className="text font-weight-normal">
                          {`${item.chargeAmount.toLocaleString()}円`}
                        </dd>
                      </div>
                      <div className="list-description-item">
                        <dt className="title font-weight-normal">お支払い方法</dt>
                        <dd className="text font-weight-normal">
                          {payment}
                        </dd>
                      </div>
                    </dl>
                    <button
                      className="btn btn-rect btn-sm btn-cancel-subscription"
                      type="button"
                      disabled={item.status === 4}
                      onClick={() => {
                        props.handleShowPopupCancelSubscriptionGochiKid(dataCancelSub);
                      }}
                    >
                      {item.status === 4 ? '継続支援中止済み' : '継続支援を中止する'}
                    </button>                    
                  </div>
                </article>
              );
            }) : (
              <p className="no-gochi-kid-used">現在までの継続支援履歴はありません。</p>
            )}
            </div>
          </div>
          {props.searchResults.length > 0 && (
            <CustomPagination
              count={Math.ceil(props.totalResult / props.limit)}
              page={props.page}
              onChange={props.handleChangePage}
            />
          )}
        </div>
      </div>
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import GiftService from "../api/services/GiftService";
import PublicService from "../api/services/PublicService";
import { v4 as uuidv4 } from "uuid";
// import { Storage } from 'aws-amplify';

const cloudFrontPath = process.env.REACT_APP_AWS_CLOUD_FRONT_PATH;
var AWS = require("aws-sdk");
export const uploadImage = (path, id, file, access) => {
  console.log("UPLOAD IMG: ", id, file);
  const filepath = `public/images/${path}/${id}/`;
  const key = `${filepath}${uuidv4()}.${file.name.split(".").pop()}`;

  var upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
      Key: key,
      Body: file,
      // ACL: access
    },
  });

  return upload.promise().then((data) => {
    return Promise.resolve(cloudFrontPath + data.Key);
  });

  // return Storage.put(key, file, options).then((data) => {
  //   console.log('UPLOAD RESPONSE DATA: ', cloudFrontPath + data.key);
  //   return cloudFrontPath + data.key;
  // });
};

export const toBlob = (base64, fileName, reject) => {
  const bin = atob(base64.replace(/^.*,/, ""));
  const buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i += 1) {
    buffer[i] = bin.charCodeAt(i);
  }

  try {
    const blob = new Blob([buffer.buffer], {
      type: "image/*",
    });
    blob.name = fileName;
    return blob;
  } catch (e) {
    reject();
    return false;
  }
};

export const log = (data) => {
  if (process.env.REACT_APP_DEBUG === "true") {
    if (Array.isArray(data)) {
      for (var i = 0; i < data.length; i++) {
        //Make your changes here, then call the original console.log function
        console.log(data[i]);
      }
    } else {
      console.log(data);
    }
  }
};

export const removeSpecialSymbol = (string) => {
  return string.trim().replace(/\?/g, "").replace(/%|％/g, "phan_tram");
};

export const useScript = (url, dep, props) => {
  const [scriptState, setScriptState] = useState(null);
  useEffect(() => {
    console.log("USE SCRIPT");
    const script = document.createElement("script");

    script.src = url;
    script.async = false;
    if (props?.integrity) script.integrity = props.integrity;
    if (props?.crossorigin) script.crossOrigin = props.crossorigin;
    if (props?.referrerpolicy) script.referrerPolicy = props.referrerpolicy;
    if (props?.async) script.async = props.async;
    if (props?.defer) script.defer = props.defer;

    console.log("SCRIPT: ", script);
    script.addEventListener("load", () => {
      setScriptState(script);
    });
    document.body.appendChild(script);

    return () => {
      console.log("REMOVE SCRIPT");
      document.body.removeChild(script);
      setScriptState(null);
    };
  }, [url, dep]);
  return scriptState;
};

export const useStyleSheet = (url, dep, props) => {
  useEffect(() => {
    console.log("USE STYLE SHEET");
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = url;
    if (props?.integrity) link.integrity = props.integrity;
    if (props?.crossorigin) link.crossOrigin = props.crossorigin;
    if (props?.referrerpolicy) link.referrerPolicy = props.referrerpolicy;

    document.body.appendChild(link);

    return () => {
      console.log("REMOVE STYLE SHEET");
      document.body.removeChild(link);
    };
  }, [url]);
};

export const usePrefectures = (onFetched, onError) => {
  const mountedRef = useRef(true);
  const [prefectures, setPrefecture] = useState([]);
  useEffect(() => {
    PublicService.getPrefectures()
      .then((response) => {
        if (response.status === 200) {
          if (!mountedRef.current) return;
          setPrefecture(response.data);
          if (onFetched) onFetched(response.data);
        }
      })
      .catch((e) => {
        console.error(e);
        if (onError) {
          onError(e);
        }
      });
    return () => {
      mountedRef.current = false;
    };
  }, []);
  return prefectures;
};

export const useCities = (onFetched, onError) => {
  const mountedRef = useRef(true);
  const [cities, setCities] = useState([]);
  useEffect(() => {
    PublicService.getCities()
      .then((response) => {
        if (response.status === 200) {
          if (!mountedRef.current) return;
          setCities(response.data);
          if (onFetched) onFetched(response.data);
        }
      })
      .catch((e) => {
        console.error(e);
        if (onError) {
          onError(e);
        }
      });
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return cities;
};

export const useSituations = (onFetched, onError) => {
  const [situations, setSituations] = useState([]);
  useEffect(() => {
    GiftService.getSituations()
      .then((response) => {
        if (response.status === 200) {
          setSituations(response.data?.list);
          if (onFetched) onFetched(response.data?.list);
        }
      })
      .catch((e) => {
        console.error(e);
        if (onError) {
          onError(e);
        }
      });
  }, []);

  return situations;
};

export const useUserTargets = (onFetched, onError) => {
  const [userTargets, setUserTargets] = useState([]);
  useEffect(() => {
    GiftService.getUserTargets()
      .then((response) => {
        if (response.status === 200) {
          setUserTargets(response.data?.list);
          if (onFetched) onFetched(response.data?.list);
        }
      })
      .catch((e) => {
        console.error(e);
        if (onError) {
          onError(e);
        }
      });
  }, []);

  return userTargets;
};

export const useGenres = (onFetched, onError) => {
  const [genres, setGenres] = useState([]);
  useEffect(() => {
    GiftService.getGenres()
      .then((response) => {
        if (response.status === 200) {
          setGenres(response.data?.list);
          if (onFetched) onFetched(response.data?.list);
        }
      })
      .catch((e) => {
        console.error(e);
        if (onError) {
          onError(e);
        }
      });
  }, []);

  return genres;
};

export const usePriceRanges = (onFetched, onError) => {
  const [priceRanges, setPriceRanges] = useState([]);
  useEffect(() => {
    GiftService.getPriceRanges()
      .then((response) => {
        if (response.status === 200) {
          setPriceRanges(response.data);
          if (onFetched) onFetched(response.data);
        }
      })
      .catch((e) => {
        console.error(e);
        if (onError) {
          onError(e);
        }
      });
    return () => {
      console.log("CLEAN UP");
    };
  }, []);

  return priceRanges;
};

export const useCategories = (onFetched, onError) => {
  const [userTargets, setUserTargets] = useState([]);
  useEffect(() => {
    GiftService.getCategory()
      .then((response) => {
        if (response.status === 200) {
          setUserTargets(response.data);
          if (onFetched) onFetched(response.data);
        }
      })
      .catch((e) => {
        console.error(e);
        if (onError) {
          onError(e);
        }
      });
  }, []);

  return userTargets;
};

export const useTags = (onFetched, onError) => {
  const [userTargets, setUserTargets] = useState([]);
  useEffect(() => {
    GiftService.getTag()
      .then((response) => {
        if (response.status === 200) {
          setUserTargets(response.data);
          if (onFetched) onFetched(response.data);
        }
      })
      .catch((e) => {
        console.error(e);
        if (onError) {
          onError(e);
        }
      });
  }, []);

  return userTargets;
};

export const useRecentlyViewedMenus = (onFetched, onError, dep, accessToken, checkTokenExpired, flowGochiKidFlag) => {
  const [recentlyViewedMenus, setRecentlyViewedMenus] = useState([]);
  function getListGuest() {
    const recentlyList =
      JSON.parse(localStorage.getItem("recentlyViewed")) || [];
    const menuIds = recentlyList.join(",");
    GiftService.getRecentlyViewMenusGuest(menuIds, flowGochiKidFlag)
      .then((response) => {
        if (response.status === 200) {
          setRecentlyViewedMenus(response.data?.list);
          if (onFetched) onFetched(response.data?.list);
        }
      })
      .catch((e) => {
        console.error(e);
        if (onError) {
          onError(e);
        }
      });
  }
  function getList() {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const isLoggedIn = userInfo && userInfo.userId;
    console.log("IS LOGGED IN: ", userInfo);
    if (isLoggedIn) {
      GiftService.getRecentlyViewMenus(accessToken, checkTokenExpired, flowGochiKidFlag)
        .then((response) => {
          if (response.status === 200) {
            setRecentlyViewedMenus(response.data?.list);
            if (onFetched) onFetched(response.data?.list);
          } else if (response.status === 401) {
            getListGuest();
          }
        })
        .catch((e) => {
          console.error(e);
          if (e.response.status === 401) {
            getListGuest();
          }
          if (onError) {
            onError(e);
          }
        });
    } else {
      getListGuest();
    }
  }
  useEffect(() => {
    getList();
  }, [dep]);

  return [recentlyViewedMenus, getList];
};

export const useNews = (page, perPage, onFetched, onError) => {
  const [news, setNews] = useState([]);
  useEffect(() => {
    GiftService.getNews(page, perPage)
      .then((response) => {
        if (response.status === 200) {
          setNews(response.data.contents);
          if (onFetched) onFetched(response.data.contents);
        } else {
          if (onError) {
            onError();
          }
        }
      })
      .catch((e) => {
        console.error(e);
        if (onError) {
          onError(e);
        }
      });
  }, [page, perPage]);

  return news;
};

export const useArchive = (onFetched, onError) => {
  const [archive, setArchive] = useState([]);
  useEffect(() => {
    GiftService.getArchive()
      .then((response) => {
        if (response.status === 200) {
          setArchive(response.data);
          if (onFetched) onFetched(response.data);
        }
      })
      .catch((e) => {
        console.error(e);
        if (onError) {
          onError(e);
        }
      });
  }, []);

  return archive;
};

export const useMagazines = (page, perPage, onFetched, onError) => {
  const [magazines, setMagazines] = useState([]);
  useEffect(() => {
    GiftService.getMagazines(page, perPage)
      .then((response) => {
        if (response.status === 200) {
          setMagazines(response.data.contents);
          if (onFetched) onFetched(response.data.contents);
        } else {
          if (onError) {
            onError();
          }
        }
      })
      .catch((e) => {
        console.error(e);
        if (onError) {
          onError(e);
        }
      });
  }, [page, perPage]);

  return magazines;
};

export const useSpecialPickup = (onFetched, onError) => {
  const [specialPickups, setSpecialPickups] = useState([]);
  useEffect(() => {
    GiftService.getSpecialPickup()
      .then((response) => {
        if (response.status === 200) {
          setSpecialPickups(response.data);
          if (onFetched) onFetched(response.data);
        } else {
          if (onError) {
            onError();
          }
        }
      })
      .catch((e) => {
        console.error(e);
        if (onError) {
          onError(e);
        }
      });
  }, []);

  return specialPickups;
};

export const useSpecialCampaign = (page, perPage, onFetched, onError) => {
  const [specialCampaigns, setSpecialCampaigns] = useState([]);
  useEffect(() => {
    GiftService.getSpecialCampaigns(page, perPage)
      .then((response) => {
        if (response.status === 200) {
          setSpecialCampaigns(response.data.contents);
          if (onFetched) onFetched(response.data.contents);
        } else {
          if (onError) {
            onError();
          }
        }
      })
      .catch((e) => {
        console.error(e);
        if (onError) {
          onError(e);
        }
      });
  }, [page, perPage]);

  return specialCampaigns;
};

export const useUserDetails = (userId, loginUserId, onFetched, onError, accessToken, checkTokenExpired) => {
  const [userDetails, setUserDetails] = useState(null);
  useEffect(() => {
    GiftService.getUserDetails(userId, loginUserId, accessToken, checkTokenExpired)
      .then((response) => {
        if (response.status === 200) {
          setUserDetails(response.data);
          if (onFetched) onFetched(response.data);
        } else if (onError) {
          onError(response);
        }
      })
      .catch((e) => {
        console.error(e);
        if (onError) {
          onError(e);
        }
      });
  }, [userId, loginUserId]);

  return userDetails;
};

export function toggleFavoriteShop(shopId, current, onComplete, onError) {
  const handler = current
    ? GiftService.deleteShopFavorite(shopId)
    : GiftService.postShopFavorite(shopId);
  handler
    .then((response) => {
      if (response.status === 200) {
        onComplete && onComplete();
      } else {
        onError && onError(response);
      }
    })
    .catch((e) => {
      onError && onError(e);
    });
}

export function toggleWishListShop(shopId, current, onComplete, onError) {
  const handler = current
    ? GiftService.deleteShopWishList(shopId)
    : GiftService.postShopWishList(shopId);
  handler
    .then((response) => {
      if (response.status === 200) {
        onComplete && onComplete();
      } else {
        onError && onError(response);
      }
    })
    .catch((e) => {
      onError && onError(e);
    });
}

export function toggleFavoriteMenu(menuId, current, onComplete, onError) {
  const handler = current
    ? GiftService.deleteMenuFavorite(menuId)
    : GiftService.postMenuFavorite(menuId);
  handler
    .then((response) => {
      if (response.status === 200) {
        onComplete && onComplete(response);
      } else {
        onError && onError();
      }
    })
    .catch((e) => {
      onError && onError(e);
    });
}

export function toggleWishListMenu(menuId, current, onComplete, onError) {
  const handler = current
    ? GiftService.deleteMenuWishList(menuId)
    : GiftService.postMenuWishList(menuId);
  handler
    .then((response) => {
      if (response.status === 200) {
        onComplete && onComplete(response);
      } else {
        onError && onError();
      }
    })
    .catch((e) => {
      onError && onError(e);
    });
}

export function setMetaDescriptionAndKeywords(description, keywords) {
  const meta = document.getElementById("meta-description");
  meta.setAttribute("content", description);
  const metaKeywords = document.getElementById("meta-keywords");
  metaKeywords.setAttribute("content", keywords);
}

export function getMonth(dateStr) {
  let date = new Date(dateStr);
  return date.getMonth() + 1;
}

export function getDate(dateStr) {
  let date = new Date(dateStr);
  return date.getDate();
}

export function formatDate(dateStr) {
  let date = new Date(dateStr);
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let year = date.getFullYear();
  if (month < 10) {
    month = `0${month}`;
  }
  if (day < 10) {
    day = `0${day}`;
  }
  return `${year}.${month}.${day}`;
}

export function replaceString(value, limit) {
  return value.length > limit ? value.substr(0, limit - 1) + "..." : value;
}

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export function addParamUrl (url, arrayParam) {
  var arr = url ? url.split("/") : [];
  var last = arr[arr.length - 1];
  var end = url;
  if (arrayParam?.length > 0 && last) {
    if (last.includes("?")) {
      for(let i = 0; i < arrayParam.length; i++) {
        if (arrayParam[i].name && arrayParam[i].value){
          end = end + '&' + arrayParam[i].name + '=' + arrayParam[i].value;
        }
      }
    } else {
      for(let i = 0; i < arrayParam.length; i++) {
        if (arrayParam[i].name && arrayParam[i].value){
          if (i === 0){
            end = end + '?' + arrayParam[i].name + '=' + arrayParam[i].value;
          } else {
            end = end + '&' + arrayParam[i].name + '=' + arrayParam[i].value;
          }
        }
      }
    }
  }
  return end;
}

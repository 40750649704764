import React, { useState, useEffect } from "react";
import MetaTags from 'react-meta-tags';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import queryString from 'query-string';
import { ChargeToGochi } from "./ChargeToGochi";
import { QrCodeToGochi } from "./QrCodeToGochi";

export function GochiChargeDetail(props) {

  const history = useHistory();
  let urlhost = window.location.search;
	let paramUrl = queryString.parse(urlhost);

  const [enableTabCharge, setEnableTabCharge] = useState(false);
  const [enableTabQrCode, setEnableTabQrCode] = useState(false);

  const urlPath = history.location.pathname;

  useEffect(() => {
    if (urlPath.match(new RegExp(/mypage\/mygochi_charge_detail/, 'g')) && urlPath.match(new RegExp(/mypage\/mygochi_charge_detail/, 'g'))[0] === 'mypage/mygochi_charge_detail' && !urlPath.match(new RegExp(/qrCode/, 'g'))) {
      setEnableTabCharge(true);
    } else if (urlPath.match(new RegExp(/mypage\/mygochi_charge_detail/, 'g')) && urlPath.match(new RegExp(/mypage\/mygochi_charge_detail/, 'g'))[0] === 'mypage/mygochi_charge_detail' && urlPath.match(new RegExp(/qrCode/, 'g')) && urlPath.match(new RegExp(/qrCode/, 'g'))[0] === 'qrCode') {
      setEnableTabQrCode(true);
    }
  }, [])

  var menuId = props.gochiData?.menuId ? props.gochiData.menuId : null;
  var isWishList = props.gochiData?.menu && props.gochiData.menu.isWishList;
  var isFavourite = props.gochiData?.menu && props.gochiData.menu.isFavourite;
  var image = props.gochiData?.gochiBillImage && props.gochiData.gochiBillImage.length > 0 && props.gochiData.gochiBillImage[0].image ?
                  props.gochiData.gochiBillImage[0].image : props.NO_IMAGE_MENU;
  var ticketFree = props.gochiData?.couponFree ? props.gochiData.couponFree : 0;
  var comboDescription = props.gochiData?.menu?.menuComboDetail?.quantityFreeDes;
  var shopName = props.gochiData?.shop?.shopName ? props.gochiData.shop.shopName : null;
  var groupName = props.gochiData?.group?.groupName ? props.gochiData.group.groupName : null;
  var menuName = props.gochiData?.menu?.menuName ? props.gochiData.menu.menuName : null;
  var menuPriceName = "お値段ごち";
  var isMenuPrice = props.gochiData?.menuId ? false : true;
  var amountCanUse = props.gochiData?.menuChildType5?.amountCanUse ? props.gochiData.menuChildType5.amountCanUse : 0;
  var isMenuCombo = props.gochiData?.menu && props.gochiData.menu.menuCombo === 1 ? true : false;
  var today = new Date();
  var gochiExpired =  moment(props.gochiData?.gochiExpired, 'YYYY-MM-DD').toDate();
  today.setHours(0,0,0,0);
  gochiExpired.setHours(0,0,0,0);
  var feeRate = props.gochiData?.feeRate;
  var feeSystem = props.gochiData?.feeSystem;
  var premiumFeeRate = props.gochiData?.menuChildType5?.premiumFeeRate;
  var userId = props.userId;
  var gochiBillId = props.gochiData?.gochiBillId;

  var dataFeeGochi = {
    feeRate,
    feeSystem,
    premiumFeeRate
  }
  var userInfo = props.userInfo;
  return (
    <>
      <MetaTags>
        <title>自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content="自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta name="twitter:description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:image" content="/assets/img/common/ogp.png" />
        <meta property="og:site_name" content="自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:title" content="自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content="/assets/img/common/ogp.png" />
        <meta property="og:description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
      </MetaTags>

      <div className="gochi-charge-detail" id="scrollToElementId">
        <div className={`profile-history ${props.loadingGochiChargeDetail ? 'loading': ''}`}>
          <div className="products-group products-group-column">
            {enableTabQrCode === false && (
              <article className="products">
                <div className="products-digest">
                  <div className={`products-thumbnail`}>
                    <div className="thumbnail">
                      <img
                        src={image}
                        alt={menuName || menuPriceName}
                        loading="lazy"
                      />
                    </div>
                    {isMenuCombo && (
                      <div className="badge-group">
                        {ticketFree > 0 && (
                          <div className="badge badge-black">無料</div>
                        )}
                        {comboDescription && comboDescription !== '' && (
                          <div className="badge">お得</div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="shop-header-group menu-info-gochi-charge">
                    <div className="shop-header">
                      <div className="font-weight-normal title-name">
                        {shopName || groupName}
                      </div>
                      <h2 className="title text-bold">{menuName || menuPriceName}</h2>
                    </div>
                    <div className="price-area">
                      <span className="unit">計</span>
                      <span className="num">
                        {`${amountCanUse?.toLocaleString()}`}
                      </span>
                      <span className="unit">円</span>
                    </div>
                    {isMenuCombo && (
                      <div className="badge badge-lg badge-red">回数券</div>
                    )}
                  </div>
                </div>
              </article>
            )}
            {enableTabCharge === true && (
              <ChargeToGochi
                setLoading={props.setLoading}
                loadingCharge={props.loadingGochiChargeDetail} 
                dataFeeGochi={dataFeeGochi} 
                userInfo={userInfo}
                gochiData={props.gochiData}
                fetchDataGochiChargeDetail={props.fetchDataGochiChargeDetail}
              />
            )}
            {enableTabQrCode === true && (
              <QrCodeToGochi
                loadingQrCode={props.loadingGochiChargeDetail}
                menuId={menuId}
                userId={userId}
                gochiBillId={gochiBillId}
                fetchDataGochiChargeDetail={props.fetchDataGochiChargeDetail}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

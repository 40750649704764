import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import GiftService from "../../../../api/services/GiftService";
import {
  toggleFavoriteShop,
  toggleWishListShop,
  useGenres,
  usePriceRanges,
  useScript,
  useStyleSheet,
} from "../../../../utils";
import { Footer } from "../../layout/Footer";
import Header from "../../layout/Header";
import { CustomPagination } from "../../common/Pagination";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { showNotLoggedModal } from "../../../../actions/action_show_not_logged_modal";
import { NotLoggedModal } from "../../common/NotLoggedModal";
import { ModalGroup } from "../../common/ModalGroup";
import { GiftToModal } from "../../common/GiftToModal";
import { SituationModal } from "../../common/SituationModal";
import { ShopSearchItem } from "../../common/ShopSearchItem";
import { WishListModal } from "../../common/WishListModal";
import { showWishListModal } from "../../../../actions/action_show_wish_list_modal";
import { setTempWishListShopAction } from "../../../../actions/action_wishlist_shop";
import { setTempFavoriteShopAction } from "../../../../actions/action_favorite_shop";
import { Explanation } from "../../layout/Explanation";

export function ShopSearch(props) {
  useStyleSheet("https://unpkg.com/swiper/swiper-bundle.min.css",
    null,
    {
      integrity:
        "sha384-HUXJxzkMpCxEdXkgl8BSjN5SUcUODepsOLIYIWOtKva+Qo2teMmV1mh6ZVwnthaV",
      crossorigin: "anonymous",
      referrerpolicy: "no-referrer",
    }
  );
  useScript("https://unpkg.com/swiper/swiper-bundle.min.js",
    null,
    { integrity: "sha384-XVTmRHMO2XUTzy8IerJVEy8ddg47Yz5pZrLc7nEWipaTowqT1xNb8qTLaw64Bd4S",
      crossorigin: "anonymous"
    }
  );
  useScript("/assets/js/app.js");

  useEffect(() => {
    document.body.className = "shops";
  }, []);

  const params = queryString.parse(window.location.search);
  const location = useLocation();
  const history = useHistory();

  const [prefecture, setPrefecture] = useState(params.prefecture || "");
  const [city, setCity] = useState(params.city || "");
  const listPrefectures = useSelector((state) => state.prefectures);
  const listCities = useSelector((state) => state.cities);

  const prefectureId = getPrefectureId(prefecture);
  const filteredCities = listCities.filter(
    (city) => Number(city.prefCode) === prefectureId
  );

  const [genre, setGenre] = useState(params.genre || "");
  const genres = useGenres();
  const priceRanges = usePriceRanges();
  const [priceRange, setPriceRange] = useState(params.prices || "");
  const [keyword, setKeyword] = useState(params.keyword || "");
  const [page, setPage] = useState(1);
  const LIMIT = 10;
  const [searchResults, setSearchResults] = useState([]);
  const [totalResult, setTotalResults] = useState(0);
  const [loading, setLoading] = useState(false);
  const userInfo = useSelector((state) => state.userInfo);
  const isLoggedIn = userInfo && userInfo.userId;
  const dispatch = useDispatch();

  function fetchData(newParams) {
    console.log("Fetch list menus", newParams);
    // if master data is not ready, cancel search
    if (genres.length === 0 || priceRanges.length === 0) return;

    getShops(newParams);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, genres, priceRanges, location.search]);

  function getPrefectureId(name) {
    if (name) {
      const prefectureObj = listPrefectures.find(
        (item) => item.prefectureName === name
      );
      if (prefectureObj) return prefectureObj.prefectureId;
    }
    return "";
  }

  function getGenreId(name) {
    if (name) {
      const genreObj = genres.find((item) => item.menuGenreName === name);
      if (genreObj) return genreObj.menuGenreId;
    }
    return "";
  }

  function getPriceRangeFromTo(name) {
    console.log("getPriceFromTo", name, priceRanges);
    if (name) {
      const priceRangeObj = priceRanges.find((item) => item.name === name);
      console.log("price range obj:", priceRangeObj);
      if (priceRangeObj) return [priceRangeObj.from, priceRangeObj.to];
    }
    return ["", ""];
  }

  function getPriceRangeStrFromObj(range) {
    return `${range.name && range.name.toLocaleString()}`;
  }

  function getPriceRangeStrFromName(name) {
    if (name) {
      const priceRangeObj = priceRanges.find((item) => item.name === name);
      if (priceRangeObj) return priceRangeObj.name.toLocaleString();
    }
    return "";
  }

  function handleSearch() {
    const params = [];
    if (prefecture) params.push(`prefecture=${prefecture}`);
    if (city) params.push(`city=${city}`);
    if (genre) params.push(`genre=${genre}`);
    if (priceRange) params.push(`prices=${priceRange}`);
    if (keyword) params.push(`keyword=${keyword}`);

    const query = params.join("&");
    history.replace({
      search: `?${query}`,
    });
    setPage(1);
  }

  function getShops(newParams) {
    const getParams = newParams || params;
    console.log("getShops: ", getParams);
    const [from, to] = getPriceRangeFromTo(getParams.prices);
    const parameters = {
      page,
      perPage: LIMIT,
      prefectures: getParams.prefecture,
      city: getParams.city,
      genreId: getGenreId(getParams.genre),
      priceFrom: from,
      priceTo: to,
      keyword: getParams.keyword,
    };
    setLoading(true);
    GiftService.getShops(parameters)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          setSearchResults(response.data.list);
          setTotalResults(response.data.total);
        } else if (response.status === 404) {
          setSearchResults([]);
          setTotalResults(0);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.error("GET LIST SHOPS ERROR: ", e);
      });
  }

  function handleChangePage(toPage) {
    console.log("HANDLE CHANGE PAGE:", toPage);
    setPage(toPage);
    window.scrollTo(0, 0);
  }

  const searchParamsArr = [];
  if (params.prefecture) {
    searchParamsArr.push(params.prefecture);
  }
  if (params.city) {
    searchParamsArr.push(params.city);
  }
  if (params.genre) {
    searchParamsArr.push(params.genre);
  }
  if (params.prices) {
    searchParamsArr.push(getPriceRangeStrFromName(params.prices));
  }
  if (params.keyword) {
    searchParamsArr.push(params.keyword);
  }
  const searchStr = searchParamsArr.join("・");

  const tempShopFavorite = useSelector(state => state.favoriteShop);
  const tempShopWishList = useSelector(state => state.wishListShop);

  function onToggleShopWishList(shopId, current) {
    if (!isLoggedIn) {
      dispatch(showNotLoggedModal(true));
      return;
    }
    const currentState =
      tempShopWishList[shopId] !== undefined
        ? tempShopWishList[shopId]
        : current;
    toggleWishListShop(shopId, currentState, () => {
      dispatch(setTempWishListShopAction(shopId, !currentState));
      if (!currentState) dispatch(showWishListModal(true));
    });
  }

  function onToggleShopFavorite(shopId, current) {
    if (!isLoggedIn) {
      dispatch(showNotLoggedModal(true));
      return;
    }
    const currentState =
      tempShopFavorite[shopId] !== undefined
        ? tempShopFavorite[shopId]
        : current;
    toggleFavoriteShop(shopId, currentState, () => {
      dispatch(setTempFavoriteShopAction(shopId, !currentState));
      if (!currentState) dispatch(showWishListModal(true, true));
    });
  }

  let searchConditionCount = 0;
  params.genre && searchConditionCount++;
  params.prices && searchConditionCount++;
  const isMultiSearchCondition = searchConditionCount > 1;
  const isNoSearchCondition =
    !params.prefecture &&
    !params.city &&
    searchConditionCount === 0 &&
    !params.keyword;

  function getTitle() {
    if (isNoSearchCondition) {
      return "お店一覧｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス";
    }
    if (isMultiSearchCondition || params.keyword)
      return "お店検索結果｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス";
    let ret = "";
    ret = ret + (params.prefecture ? params.prefecture : "");
    ret = ret + (params.city ? params.city : "");
    if (params.genre) {
      ret = (ret ? ret + "の" : "") + params.genre;
    }
    if (params.prices) {
      ret = (ret ? ret + "の" : "") + params.prices;
    }
    ret = ret + "のお店一覧｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス";
    return ret;
  }

  function getMetaDescription() {
    if (isNoSearchCondition) {
      return "お店一覧です。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。";
    }
    if (isMultiSearchCondition || params.keyword)
      return "お店検索結果一覧です。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。";
    let ret = "";
    ret = ret + (params.prefecture ? params.prefecture : "");
    ret = ret + (params.city ? params.city : "");
    if (params.genre) {
      ret = (ret ? ret + "の" : "") + params.genre;
    }
    if (params.prices) {
      ret = (ret ? ret + "の" : "") + params.prices;
    }
    ret =
      ret +
      "のお店一覧です。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。";
    return ret;
  }

  const masterDataIsReady = !(genres.length === 0 || priceRanges.length === 0);

  var isShowTextShopList1 = false;
  var isShowTextShopList2 = false;
  var isShowTextShopList3 = false;
  if (!isNoSearchCondition) {
    if (!params.prefecture && !params.city) {
      if (!(!params.genre && !params.prices)) {
        isShowTextShopList1 = true;
        isShowTextShopList2 = false;
        isShowTextShopList3 = false;
      }
    } else if (params.prefecture && !params.city) {
      if (!(!params.genre && !params.prices)) {
        isShowTextShopList1 = false;
        isShowTextShopList2 = true;
        isShowTextShopList3 = false;
      } else if (!params.genre && !params.prices) {
        isShowTextShopList1 = false;
        isShowTextShopList2 = true;
        isShowTextShopList3 = false;
      }
    } else if (params.prefecture && params.city) {
      if (!(!params.genre && !params.prices)) {
        isShowTextShopList1 = false;
        isShowTextShopList2 = false;
        isShowTextShopList3 = true;
      } else if (!params.genre && !params.prices) {
        isShowTextShopList1 = false;
        isShowTextShopList2 = false;
        isShowTextShopList3 = true;
      }
    } else {
      isShowTextShopList1 = false;
      isShowTextShopList2 = false;
      isShowTextShopList3 = false;
    }
  }

  return (
    <>
      <MetaTags>
        <title>{getTitle()}</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content={getMetaDescription()} />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content={getTitle()} />
        <meta name="twitter:description" content={getMetaDescription()} />
        <meta name="twitter:image" content={window.location.origin + "/assets/img/common/ogp.png"} />
        <meta name="twitter:site" content="@gochi_meshi" />
        <meta name="twitter:creator" content="@gochi_meshi" />
        <meta name="twitter:image:width" content="300" />
        <meta name="twitter:image:height" content="300" />
        <meta property="og:site_name" content={getTitle()} />
        <meta property="og:title" content={getTitle()} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={window.location.origin + "/assets/img/common/ogp.png"} />
        <meta property="og:description" content={getMetaDescription()} />
      </MetaTags>
      <Header />

      <main>
        <div className="breadcrumb">
          <div className="container">
            <ol className="breadcrumb-list">
              <li>
                <a href="/">ごちめし</a>
              </li>
              {isNoSearchCondition && (
                <li>
                  <span>お店一覧</span>
                </li>
              )}
              {isMultiSearchCondition || params.keyword ? (
                <li>
                  <span> お店の検索結果</span>
                </li>
              ) : (
                !isNoSearchCondition && (
                  <>
                    {params.genre && (
                      <li>
                        <a href={`/shops?genre=${params.genre}`}>
                          {params.genre}
                          {isShowTextShopList1 ? "のお店一覧" : ""}
                        </a>
                      </li>
                    )}
                    {params.prices && (
                      <li>
                        <a href={`/shops?prices=${params.prices}`}>
                          {params.prices}
                          {isShowTextShopList1 ? "のお店一覧" : ""}
                        </a>
                      </li>
                    )}
                    {params.prefecture && (
                      <li>
                        <a
                          href={
                            "/shops?" +
                            (params.genre ? `genre=${params.genre}&` : "") +
                            (params.prices ? `prices=${params.prices}&` : "") +
                            (params.prefecture
                              ? `prefecture=${params.prefecture}`
                              : "")
                          }
                        >
                          {params.prefecture}
                          {isShowTextShopList2 ? "のお店一覧" : ""}
                        </a>
                      </li>
                    )}
                    {params.city && (
                      <li>
                        <a
                          href={
                            "/shops?" +
                            (params.genre ? `genre=${params.genre}&` : "") +
                            (params.prices
                              ? `prices=${params.prices}&`
                              : "") +
                            (params.prefecture
                              ? `prefecture=${params.prefecture}&`
                              : "") +
                            (params.city ? `city=${params.city}` : "")
                          }
                        >
                          {params.city}
                          {isShowTextShopList3 ? "のお店一覧" : ""}
                        </a>
                      </li>
                    )}
                  </>
                )
              )}
            </ol>
          </div>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch();
          }}
          method="POST"
          className="shops search-area"
        >
          <div className="container">
            <div className="search-body">
              <div className="fieldset fieldset-column fieldset-lg">
                <div className="fieldset-header">
                  <svg className="icon">
                    <use xlinkHref="/assets/img/common/icon.svg#map-marker" />
                  </svg>
                  <div className="title">エリア</div>
                </div>
                <div className="fieldset-body">
                  <div className="field">
                    <div className="field field-pill">
                      <select
                        value={prefecture}
                        onChange={(e) => {
                          setPrefecture(e.target.value);
                          setCity("");
                        }}
                      >
                        <option value="">選択なし</option>
                        {listPrefectures.length > 0 &&
                          listPrefectures.map((item, index) => {
                            return (
                              <option value={item.prefectureName} key={index}>
                                {item.prefectureName}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="field field-pill field-sm">
                      <select
                        className="select-box-custom"
                        value={city}
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                      >
                        <option value="">選択なし</option>
                        {filteredCities.length > 0 &&
                          filteredCities.map((item, index) => {
                            return (
                              <option value={item.cityName} key={index}>
                                {item.cityName}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.fieldset */}
              <div className="fieldset fieldset-lg">
                <div className="fieldset-header">
                  <svg className="icon">
                    <use xlinkHref="/assets/img/common/icon.svg#utensils" />
                  </svg>
                  <div className="title">ジャンル</div>
                </div>
                <div className="fieldset-body">
                  <div className="field field-pill field-sm">
                    <select
                      className="select-box-custom"
                      onChange={(e) => setGenre(e.target.value)}
                      value={genre}
                    >
                      <option value="">選択なし</option>
                      {genres.map((item) => (
                        <option value={item.menuGenreName}>
                          {item.menuGenreName}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* /.field */}
                </div>
              </div>
              {/* /.fieldset */}
              <div className="fieldset fieldset-lg">
                <div className="fieldset-header">
                  <svg className="icon">
                    <use xlinkHref="/assets/img/common/icon.svg#yen-sign" />
                  </svg>
                  <div className="title">予算</div>
                </div>
                <div className="fieldset-body">
                  <div className="field field-pill field-md">
                    <select
                      onChange={(e) => setPriceRange(e.target.value)}
                      value={priceRange}
                    >
                      <option value="">選択してください</option>
                      {priceRanges.map((item) => (
                        <option value={item.name}>
                          {getPriceRangeStrFromObj(item)}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* /.field */}
                </div>
              </div>
              {/* /.fieldset */}
              <div className="fieldset fieldset-lg">
                <div className="fieldset-header">
                  <svg className="icon">
                    <use xlinkHref="/assets/img/common/icon.svg#search" />
                  </svg>
                  <div className="title">キーワード</div>
                </div>
                <div className="fieldset-body">
                  <div className="field field-pill field-md">
                    <input
                      value={keyword}
                      type="text"
                      placeholder="キーワード"
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                  </div>
                  {/* /.field */}
                </div>
              </div>
              {/* /.fieldset */}
              <div className="fieldset fieldset-submit under-tab">
                <button
                  type="button"
                  className="btn btn-submit search-btn"
                  onClick={handleSearch}
                >
                  検索する
                </button>
              </div>
              {/* /.fieldset */}
            </div>
            {/* /.search-body */}
            <div className="search-footer over-pc">
              <button className="btn" type="button" onClick={handleSearch}>
                検索する
              </button>
            </div>
            {/* /.search-footer */}
          </div>
          {/* /.container */}
        </form>
        <div className="container">
          {/* 店舗一覧のHTML */}
          <section
            className={
              !masterDataIsReady || (loading && searchResults.length === 0)
                ? "section loading"
                : "section"
            }
          >
            <div className="section-header">
              <div className="heading-lg">
                <h1 className="title">
                  {searchStr}
                  {searchStr ? "で見つかった店舗：" : "見つかった店舗："}
                  {totalResult}件
                </h1>
              </div>
            </div>
            <div className="section-body">
              {/* <nav className="nav">
                <div className="fieldset">
                  <div className="field field-pill field-md">
                    <select>
                      <option value selected>
                        おすすめ順
                      </option>
                      <option value>最新順</option>
                      <option value>価格の高い順</option>
                      <option value>価格の安い順</option>
                    </select>
                  </div>
                </div>
              </nav> */}
              <div className="shop-group mt-20">
                {searchResults.map((item) => (
                  <ShopSearchItem
                    item={item}
                    loading={loading}
                    tempShopWishList={tempShopWishList}
                    onToggleShopWishList={onToggleShopWishList}
                    tempShopFavorite={tempShopFavorite}
                    onToggleShopFavorite={onToggleShopFavorite}
                  />
                ))}
              </div>
              {searchResults.length > 0 && (
                <CustomPagination
                  count={Math.ceil(totalResult / LIMIT)}
                  page={page}
                  onChange={handleChangePage}
                />
              )}
            </div>
          </section>
          {/* 店舗一覧のHTML */}
        </div>

        <Explanation />
      </main>

      <Footer />

      <ModalGroup>
        <SituationModal />
        <GiftToModal />
        <NotLoggedModal />
        <WishListModal />
      </ModalGroup>
    </>
  );
}

const stateDefault = { 
};

export default (state = stateDefault, action) => { 
  switch (action.type){
    case 'MENU_ID_SAVE':
      return {
        menuIdUrl: parseInt(action.menuId)
      };
    case 'MENU_USER_SAVE':
      return {
        ...state, 
        ...action.menuInfo
    };
    case 'UPDATE_QUANTITY_MENU':
      return {
        ...state, 
       	updatedMenuInfo: action.updatedMenuInfo
    };
    case 'SAVE_INIT_AMOUNT':
      return {
        ...state,
        initTotalAmount: action.totalAmount
      };
    case 'SAVE_INVITE':
      return {
        ...state, 
        inviteContent: action.inviteContent
    };
    case 'SAVE_FEE_RATE':
      return {
        ...state,
        feeRate: action.feeRate
      };
    case 'SAVE_FEE_GIGI_RATE':
      return {
        ...state,
        feeGiGiRate: action.feeGiGiRate
      };
    case 'SAVE_DISCOUNT_RATE':
      return {
        ...state,
        discountRate: action.discountRate
      };
    case 'SAVE_MENU_N_COUNT':
      return {
        ...state,
        menuNCount: action.menuNCount
      };
    default:
      return state;
  }
};
 
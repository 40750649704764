import React, { Component } from "react";    
import {connect} from "react-redux"; 
import { withTranslation } from 'react-i18next';

class ServerError extends Component {

    render() {
			const { t } = this.props;
			return (
				<div className="shop-not-avai text-center">
					{/* <img src={require('../../../assets/img/icons/gochi_logo_print.png')}
							 alt={t('other.This feast button cannot be used because the store is not open')}
							 width="100%"
							 className="image-not-available"
					/> */}

					<div className="text-left fs-14">
						エラーが発生しました。時間をおいてお試しください。
						{/* ただいまシステムに内部の構成変更に伴うメンテナンスのため、一時的にサービスの機能を全て停止しております。大変ご不便をおかけいたしますが、今しばらくお待ちください。<br/>
						<br/>
						<b>【メンテナンス作業予定日時】</b><br/>
						2021年7月8日（木）<b>11:00 ~ 13:00</b><br/>
						※メンテナンス作業が終了次第公開いたします。<br/>
						<br/>
						<b>【お問い合わせ】</b><br/>
						ユーザー様<br/>
						<a 
							className="text-green"
							target="_blank"
							rel="noopener noreferrer"
							href="https://share.hsforms.com/1VKsj-5igT1KiIHAjFgs-ZA4fbv9"
						>
							https://share.hsforms.com/1VKsj-5igT1KiIHAjFgs-ZA4fbv9
						</a>
						<br/>
						<br/>
						登録店舗様<br/>
						<a 
							className="text-green"
							target="_blank"
							rel="noopener noreferrer"
							href="https://share.hsforms.com/1cQs7-hA9RNmMqAoHF6tRZA4fbv9"
						>
							https://share.hsforms.com/1cQs7-hA9RNmMqAoHF6tRZA4fbv9
						</a> */}
					</div>
				</div>
			);
    }
}
const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ServerError));


import React from "react";
import MetaTags from 'react-meta-tags';
// import { Link } from "react-router-dom";
import { CustomPagination } from "../../common/Pagination";
import { DishMenu } from "./DishMenu";

export function SubscriptionGochi(props) {

  // console.log('props.total', props.totalResult);
  console.log('props.searchResults', props.searchResults);

  function handleCancelSubscription(subscriptionCode) {
    window.open(`${process.env.REACT_APP_GUEST_URL}/subscription-cancel?code=${subscriptionCode}`, '_blank');
  }

  return (
    <>
      <MetaTags>
        <title>自分宛てごち｜マイページ｜飲食店のメニューを贈れる「ごちめし」</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをプレゼントできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content="自分宛てごち｜マイページ｜飲食店のメニューを贈れる「ごちめし」" />
        <meta name="twitter:description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをプレゼントできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:image" content="/assets/img/common/ogp.png" />
        <meta property="og:site_name" content="自分宛てごち｜マイページ｜飲食店のメニューを贈れる「ごちめし」" />
        <meta property="og:title" content="自分宛てごち｜マイページ｜飲食店のメニューを贈れる「ごちめし」" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content="/assets/img/common/ogp.png" />
        <meta property="og:description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをプレゼントできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
      </MetaTags>

      <hr className="mt-30" />
      <div className="profile-history-area" id="scrollToElementId">
        <div className={`profile-history ${props.loadingGochiSubscription ? 'loading': ''}`}>
          <div className="products-group products-group-column">
            {props.searchResults.length > 0 && props.searchResults.map((item, key) => {
              let index = `${item.subscriptionId}-${key}`;
              var menuId = item.menuId ? item.menuId : null;
              var menuName = item.menu?.menuName ? item.menu.menuName : null;
              var isMenuPrice = item.menuId ? false : true;
              var menuPriceName = "お値段ごち";
              var isWishList = item.menu && item.menu.isWishList;
              var isFavourite = item.menu && item.menu.isFavourite;
              var shopName = item.shop?.shopName ? item.shop.shopName : null;
              var groupName = item.groupInfo?.groupName ? item.groupInfo.groupName : null;
              var couponCount = item.couponCount ? item.couponCount : 0;
              var isMenuCombo = item.menu && item.menu.menuCombo === 1 ? true : false;


              var ticketFree = item.couponFree ? item.couponFree : 0;
              var comboDescription = item.menu?.menuComboDetail?.quantityFreeDes;
              var image = item.menuImage && item.menuImage.length > 0 && item.menuImage[0].image ? 
                              item.menuImage[0].image : props.NO_IMAGE_MENU;
              var includedTaxPrice = item.menu?.includedTaxPrice ? item.menu.includedTaxPrice : 0;
              var includedTaxPriceCombo = item.menu?.menuComboDetail?.includedTaxPriceCombo ? item.menu.menuComboDetail.includedTaxPriceCombo : 0;

              
              var isActive = item.deletedAt ? false : true;
              var frequencyText = item.subscriptionInterval && item.subscriptionInterval === 'MONTH' ? 'ヶ月' : (item.subscriptionInterval === 'WEEK' ? '週間' : '');
              var frequencyCount = item.subscriptionIntervalCount ? item.subscriptionIntervalCount : 0;

              var dishInfo = {
                menuId,
                isWishList,
                isFavourite,
                image,
                menuName,
                menuPriceName,
                isMenuCombo,
                ticketFree,
                comboDescription,
                isMenuPrice
              };

              return (
                <article className="products" key={index}>
                  <div className="products-digest">
                    <DishMenu
                      dishInfo={dishInfo}
                      handleUpdate={props.handleUpdate}
                    />
                    <div className="shop-header-group">
                      <div className="shop-header">
                        <div className="text-sm text-gray">
                          {shopName || groupName}
                        </div>
                        <h2 className="title text-bold">
                          {menuName}
                        </h2>
                      </div>
                      <hr />
                      <div className="price-area">
                        <span className="unit">¥</span>
                        <span className="num">
                          {isMenuCombo && (
                            <>
                              {includedTaxPriceCombo.toLocaleString()} x {couponCount}枚
                              {ticketFree !== 0 ? ` (おまけ${ticketFree}枚含)` : ''}
                            </>
                          )}
                          {!isMenuCombo && (
                            <>
                              {includedTaxPrice.toLocaleString()}{` x `}{couponCount}
                            </>
                          )}
                        </span>
                      </div>
                      {isMenuCombo && (
                        <div className="badge badge-lg badge-red">回数券</div>
                      )}
                    </div>
                  </div>
                  <div className="products-info">
                    <dl className="list-description text-sm">
                      <div className="list-description-item">
                        <dt className="title">次の請求日</dt>
                        <dd className="text">{item.currentPeriodEnd && isActive ? props.formatDate(item.currentPeriodEnd) : ''}</dd>
                      </div>
                      <div className="list-description-item">
                        <dt className="title">購入頻度</dt>
                        <dd className="text">
                          {`${frequencyCount}${frequencyText}1回`}
                        </dd>
                      </div>
                    </dl>
                    <div className="products-btn">
                      <div className="btn-group">
                        <button
                          className="btn btn-rect btn-sm"
                          type="button"
                          disabled={!isActive}
                          onClick={() => {
                            handleCancelSubscription(item.subscriptionCode);
                          }}
                        >
                          {isActive ? 'サブスクキャンセル' : 'サブスクキャンセル済み'}
                        </button>
                      </div>
                    </div>
                  </div>
                </article>
              );
            })}
          </div>
          {props.searchResults.length > 0 && (
            <CustomPagination
              count={Math.ceil(props.totalResult / props.limit)}
              page={props.page}
              onChange={props.handleChangePage}
            />
          )}
        </div>
      </div>
    </>
  );
}

import axios from "axios";
import publicIp from "public-ip";
import axiosRetry from "axios-retry";
import {addParamUrl} from '../utils';
import AuthService from "./services/AuthService";
import i18n from "../i18n";
import qs from "qs";
import queryString from "query-string";

const API_BASE_URL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
  : "";
const API_GCMS_WP_URL = process.env.REACT_APP_GCMS_WP_URL || "";

// (async () => {
//   const address = await axios.get('https://api.ipify.org/?format=json');

//   console.log('ipipipip',address);
// })()
// var ip = require('ip');

// console.log('IP Address', ip.address());

export const HTTP_API = {
  GET: "get",
  POST: "post",
  DELETE: "delete",
  PUT: "put",
  SUCCESS: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  INTERNAL_SERVER: 500,
  ERROR_REQUEST: 406,
  ERROR_NOTFOUND: 404,
  ERROR_CONFLICT: 409,
  ERROR_GONE: 410,
  ERROR_TIMEOUT: 504,
  MAINTAIN_MODE: 503,
};

const NOT_RETRIES = [
  HTTP_API.ERROR_REQUEST,
  HTTP_API.PAYMENT_REQUIRED,
  HTTP_API.ERROR_NOTFOUND,
  HTTP_API.INTERNAL_SERVER,
  HTTP_API.BAD_REQUEST,
  HTTP_API.UNAUTHORIZED,
  HTTP_API.FORBIDDEN,
  HTTP_API.MAINTAIN_MODE,
  HTTP_API.ERROR_CONFLICT,
  HTTP_API.ERROR_GONE
];

var RETRY_LIMITED = process.env.REACT_APP_RETRY_LIMITED;
var RETRY_TIME = process.env.REACT_APP_RETRY_TIME;

const REGEX_API_GCMS_WP_URL = new RegExp(`^${API_GCMS_WP_URL}`);

axiosRetry(axios, {
  retries: RETRY_LIMITED,
  retryDelay: (retryCount) => {
    return retryCount * RETRY_TIME;
  },
  retryCondition: (error) => {
    console.log("RETRY CONDITION", error);
    return !NOT_RETRIES.includes(error.response.status);
  },
});

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("accessToken");
    
    if (token && !config.thirdParty) {
      config.headers["Authorization"] = "Bearer " + token;
      config.headers["X-Access-Token"] = "Bearer " + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    config.headers['Accept'] = 'application/json';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    let url = window.location.search;
    let params = queryString.parse(url);
    var isSiteParam = params.isSite ? {name: 'isSite', value: params.isSite} : {};

    if (
      error.response.status === HTTP_API.UNAUTHORIZED ||
      error.response.status === HTTP_API.FORBIDDEN
    ) {
      const refreshToken = localStorage.getItem("refreshToken");

      const urlAuth = `${API_BASE_URL}/auth`;
      const urlRecentlyViewedMenu = `${API_BASE_URL}/gift/menus/recentlyViewed`;
      if (originalRequest.url === urlAuth || !refreshToken) {
        sessionStorage.clear();
        const recentlyViewed = localStorage.getItem("recentlyViewed");
        const lastVisited = localStorage.getItem("lastVisited");
        localStorage.clear();
        localStorage.setItem("recentlyViewed", recentlyViewed);
        localStorage.setItem("lastVisited", lastVisited);
        window.location.href = addParamUrl("/login", [isSiteParam]);
        return Promise.reject(error);
      }

      if (!originalRequest._retry) {
        originalRequest._retry = true;
        return AuthService.authenticateByRefreshToken(refreshToken).then(
          function (res) {
            if (res.status === HTTP_API.SUCCESS) {
              return axios(originalRequest);
            }
          }
        );
      } else if (originalRequest.url === urlRecentlyViewedMenu) {
        return Promise.reject(error);
      } else {
        const recentlyViewed = localStorage.getItem("recentlyViewed");
        const lastVisited = localStorage.getItem("lastVisited");
        localStorage.clear();
        localStorage.setItem("recentlyViewed", recentlyViewed);
        localStorage.setItem("lastVisited", lastVisited);
        sessionStorage.clear();
        window.location.href = addParamUrl("/login", [isSiteParam]);
      }
    }

    return Promise.reject(error);
  }
);

const headerDefault = { "X-App-Versions": process.env.REACT_APP_VERSION };

// let userLang = "";

// const lang = localStorage.getItem("lang");
// if (lang) {
//   userLang = lang;
// } else {
//   let browserLang = navigator.language || navigator.userLanguage;

//   if (browserLang.includes("en")) {
//     browserLang = "en";
//   } else if (browserLang.includes("zh")) {
//     browserLang = "zh";
//   } else {
//     browserLang = "ja";
//   }

//   userLang = browserLang;
// }

var nAgt = navigator.userAgent;
var browserName = navigator.appName;
var fullVersion = "" + parseFloat(navigator.appVersion);
var nameOffset, verOffset;
// OS name
var OSName = "Unknown OS";
if (navigator.appVersion.indexOf("Win") !== -1) OSName = "Windows";
if (navigator.appVersion.indexOf("Mac") !== -1) OSName = "MacOS";
if (navigator.appVersion.indexOf("X11") !== -1) OSName = "UNIX";
if (navigator.appVersion.indexOf("Linux") !== -1) OSName = "Linux";

// Browser name
// In Opera, the true version is after "Opera" or after "Version"
if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
  browserName = "Opera";
  fullVersion = nAgt.substring(verOffset + 6);
  if ((verOffset = nAgt.indexOf("Version")) !== -1)
    fullVersion = nAgt.substring(verOffset + 8);
}
// In MSIE, the true version is after "MSIE" in userAgent
else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
  browserName = "Microsoft Internet Explorer";
  fullVersion = nAgt.substring(verOffset + 5);
}
// In Chrome, the true version is after "Chrome"
else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
  browserName = "Chrome";
  fullVersion = nAgt.substring(verOffset + 7);
}
// In Safari, the true version is after "Safari" or after "Version"
else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
  browserName = "Safari";
  fullVersion = nAgt.substring(verOffset + 7);
  if ((verOffset = nAgt.indexOf("Version")) !== -1)
    fullVersion = nAgt.substring(verOffset + 8);
}
// In Firefox, the true version is after "Firefox"
else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
  browserName = "Firefox";
  fullVersion = nAgt.substring(verOffset + 8);
}
// In most other browsers, "name/version" is at the end of userAgent
else if (
  (nameOffset = nAgt.lastIndexOf(" ") + 1) < (verOffset = nAgt.lastIndexOf("/"))
) {
  browserName = nAgt.substring(nameOffset, verOffset);
  fullVersion = nAgt.substring(verOffset + 1);
  if (browserName.toLowerCase() === browserName.toUpperCase()) {
    browserName = navigator.appName;
  }
}
console.log("userAgentuserAgent ", fullVersion, browserName);

/**
 *
 * @param type        : Required. The type of api: GET or POST. Default is GET
 * @param urlPath     : Required. url api.
 * @param data        : Optional. list params
 * @param headers     : Optional. Header of api
 * @param progress    : Optional. Handle show progressbar
 * @returns {Promise<{message: string, status: number}|{data: *, message: string, status: number}|{data: any, message: string, status: number}>}
 */

export async function makeRequest(
  type = HTTP_API.GET,
  urlPath,
  data = {},
  retry = true,
  headerParams,
  headers = {
    "Access-Control-Allow-Origin": "*",
    "Allow-Control-Allow-Methods": "DELETE, POST, PUT, GET, OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
    acceptLanguage: i18n.language !== "ja_gs" ? i18n.language : "ja",
  },
  progress = true,
  thirdParty,
  accessToken,
  checkTokenExpired
) {
  let response = "";
  let url = urlPath;

  let ipAddress = {};
  let authorization = {};
  if (accessToken){
    authorization = { "authorization": "Bearer " + accessToken, "X-Access-Token": "Bearer " + accessToken };
  }
  if (checkTokenExpired){
    authorization = { ...authorization,  "checkTokenExpired": checkTokenExpired };
  }

  try {
    ipAddress = { "ip-address": await publicIp.v4() };
  } catch (e) {
    console.log(e);
  }
  try {
    if (type === HTTP_API.GET && thirdParty) {
      response = await axios.get(url, {
        params: { ...data },
        progress: progress,
        thirdParty,
      });
    } else if (type === HTTP_API.GET && !thirdParty) {
      response = await axios.get(url, {
        params: { ...data },
        headers: { ...headerDefault, ...headers, ...ipAddress, ...authorization },
        progress: progress,
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: "brackets" });
        },
      });
    } else if (type === HTTP_API.DELETE) {
      response = await axios.delete(url, {
        params: { ...data },
        headers: { ...headerDefault, ...headers, ...ipAddress, ...authorization },
        progress: progress,
      });
    } else if (type === HTTP_API.POST) {
      let headersend = {
        "Content-Type": "application/json",
        ...headerDefault,
        ...headers,
        ...headerParams,
        ...ipAddress,
        ...authorization
      };
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const deviceInfo = {
        gochiPlatformType: "WEB",
        userId: userInfo && userInfo.userId,
        OSName,
        browserVersion: fullVersion,
        browserName,
      };
      response = await axios.post(
        url,
        { ...data, ...deviceInfo },
        {
          progress: progress,
          headers: headersend,
        }
      );
    } else if (type === HTTP_API.PUT) {
      response = await axios.put(url, data, {
        headers: {
          ...headerDefault,
          ...headers,
          ...headerParams,
          ...ipAddress,
          ...authorization
        },
        progress: progress,
      });
    }

    // return with status 200
    if (response.status === HTTP_API.SUCCESS) {
      return {
        status: HTTP_API.SUCCESS,
        message: "Success",
        data: response.data,
      };
    }

    if (
      [
        HTTP_API.ERROR_REQUEST,
        HTTP_API.PAYMENT_REQUIRED,
        HTTP_API.ERROR_NOTFOUND,
        HTTP_API.INTERNAL_SERVER,
        HTTP_API.BAD_REQUEST,
        HTTP_API.ERROR_TIMEOUT,
        HTTP_API.ERROR_CONFLICT,
        HTTP_API.ERROR_GONE
      ].includes(response.status)
    ) {
      return {
        status: response.status,
        message: `Error ${response.status}`,
      };
    }

    // return with status 503
    if (response.status === HTTP_API.MAINTAIN_MODE && !REGEX_API_GCMS_WP_URL.test(url)) {
      if (response.data?.errorInfo?.content === "SERVER_MAINTAIN") {
        return (window.location.href = "/maintain");
      } else return (window.location.href = "/server-error");
    }

    return {
      status: HTTP_API.BAD_REQUEST,
      message: "No data",
    };
  } catch (e) {
    console.warn("Exception: ", e.response);
    // return with status 503
    if (
      e.response &&
      e.response.status === HTTP_API.MAINTAIN_MODE &&
      !REGEX_API_GCMS_WP_URL.test(url)
    ) {
      if (e.response.data?.errorInfo?.content === "SERVER_MAINTAIN") {
        return (window.location.href = "/maintain");
      }
      return (window.location.href = "/server-error");
    }

    let messageError = "There is something wrong";
    let statusError = HTTP_API.BAD_REQUEST;
    if (e.response) {
      switch (e.response.status) {
        case HTTP_API.ERROR_TIMEOUT:
          messageError = "Request time out";
          break;
        case HTTP_API.INTERNAL_SERVER:
          messageError = "Internal Server Error";
          break;

        default:
          break;
      }
      if (
        [
          HTTP_API.ERROR_REQUEST,
          HTTP_API.PAYMENT_REQUIRED,
          HTTP_API.ERROR_NOTFOUND,
          HTTP_API.INTERNAL_SERVER,
          HTTP_API.ERROR_TIMEOUT,
          HTTP_API.MAINTAIN_MODE,
          HTTP_API.ERROR_CONFLICT,
          HTTP_API.ERROR_GONE
        ].includes(e.response.status)
      ) {
        statusError = e.response.status;
      }
    }

    return {
      status: statusError,
      message:
        e.response && e.response.data && e.response.data.errorInfo
          ? e.response.data.errorInfo.content
          : messageError,
      data:
        statusError === HTTP_API.PAYMENT_REQUIRED ? e.response?.data :
          e.response && e.response.data && e.response.data.errorInfo
            ? e.response.data.errorInfo
            : {},
      responseData: e.response && e.response.data ? e.response && e.response.data : {}
    };
  }
}

const stateDefault =  false;

export default (state = stateDefault, action) => {
  switch (action.type){
    case 'NOT_LOGGED_MODAL_SHOW':
      return action.showing ;
    default:
      return state;
  }
};

import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'; 
import 'react-tabs/style/react-tabs.css';
import InfiniteScroll from 'react-infinite-scroller';
import Button from 'react-bootstrap/Button';
import QrReader from 'react-qr-reader';
import queryString from 'query-string';
import {Link} from 'react-router-dom'; 
import {showLoading} from "../../../actions/action_loading";
import {showError} from "../../../actions/action_error_alert";
import { withTranslation } from 'react-i18next';
import GochiService from "../../../api/services/GochiService"; 
import ShopService from "../../../api/services/ShopService";  
import UserService from "../../../api/services/UserService";
import Header from "../../layout/header/Header";
import {log} from '../../../utils'; 
import GochiUseInfor from "../../common/GochiUse/GochiUseInfor";
import { Modal } from "react-bootstrap";

class QRCode extends Component { 
	constructor(props){
	    super(props); 
	    this.state = { 
            listData: [],
            count: 0,
            hasMoreItems: true,
            nextHref: 1,
            page: 0,
            limit: 10,
            callApi: true,
            gochiInfo: {},
            showGochiUse: false,
            qrCodeResult: null,
            shopInfo: {},
            legacyMode: false,
            isUserUnAuth: false,
            userInfo: queryString.parse(window.location.search).accessToken ? {isGuest: false, isLogined: true, userId: JSON.parse(atob(queryString.parse(window.location.search).accessToken.split(".")[1])).uid} : (this.props.userInfo ? this.props.userInfo : JSON.parse(localStorage.getItem('userInfo'))),
            isLoggedIn: this.props.userInfo?.userId ? true : false,
            accessToken: queryString.parse(window.location.search).accessToken,
            siteType: queryString.parse(window.location.search).siteType,
        }
        this.fetchData = this.fetchData.bind(this);
        this.getListGochis = this.getListGochis.bind(this); 
        this.onGochiClicked = this.onGochiClicked.bind(this);
        this.onUseGochi = this.onUseGochi.bind(this);
		this.onCloseGochiInfo = this.onCloseGochiInfo.bind(this);
		this.handleScanQRCode = this.handleScanQRCode.bind(this);
		this.handleErrorQRCode = this.handleErrorQRCode.bind(this);
        this.getShopDetail = this.getShopDetail.bind(this);
        this.openImageDialog = this.openImageDialog.bind(this);
        this.hideGochiCountError = this.hideGochiCountError.bind(this);
        this.checkAccessScreen = this.checkAccessScreen.bind(this);
        this.getDataDidMount = this.getDataDidMount.bind(this);
    }
    
    async fetchData(shopId){ 
        try {       
            this.props.showLoading(true);
            var gochiUserId = this.state.userInfo.userId;  
            var page = this.state.nextHref;
            var limit = this.state.limit
            if (this.state.callApi) {
                this.setState({
                    callApi: false,
                });
                let data = await this.getListGochis(
                    gochiUserId,
                    shopId,
                    page,
                    limit,
                    this.state.accessToken
                ); 
                var list = this.state.listData;  
                this.setState({
                    count: data.count,
                });
                data.list.map(data => {
                    return list.push(data);
                });  
                
                if(page*limit < data.count) {
                    this.setState({
                        nextHref: page + 1,
                        listData: list,
                        hasMoreItems: true,
                        callApi: true                            
                    });
                } else {
                    this.setState({
                        hasMoreItems: false,
                        nextHref: 1
                    });
                }
            }
            this.props.showLoading(false); 
		} catch (e) {
            log(e);
            this.props.showLoading(false); 
            this.props.showError(true, this.props.t("pages.login.There is something wrong"), "");
        }	 
    }
    
    getListGochis = async (
        gochiUserId,
        shopId,
        page,
        limit,
        accessToken
		) => { 
			try {
                const checkTokenExpired = accessToken ? 1 : null;
			  	const { data } = await GochiService.getQRCodeGochiList({
                    gochiUserId,
                    shopId,
                    page,
                    limit,
                    accessToken,
                    checkTokenExpired
			  	});   
			  	return data;
			} catch (error) { 
                log('getGochiList error');
                this.props.showError(true, this.props.t("pages.login.There is something wrong"), "");
			}
    }; 

    getShopDetail = async (shopId) => { 
        try {
            const { data } = await ShopService.getDetailShop(shopId);   
            this.setState({
                shopInfo: {
                    shopId: data.shopId,
                    shopName: data.shopName,
                    sushiroFlag: data.sushiroFlag,
                    status: data.status
                }
            });
            return data;
        } catch (error) { 
            log('géthopdetail error');
            this.props.showError(true, this.props.t("pages.login.There is something wrong"), "");
        }
    }; 

    onGochiClicked(isMenuCombo, menuId, shopId, gochiUserId, menuLimitTotal, isETicket, moreParams) {
		if (isETicket === 0) {
            this.setState({
                gochiCountError: this.props.t('other.Prevent paper ticket')
            });
            return;
        }
        if (!isMenuCombo) {
            window.location.href = "/qrCode/detail?menuId=" + menuId + "&shopId=" + shopId + moreParams;
        } if (menuLimitTotal) {
            window.location.href = "/gochiBillDetail?menuId=" + menuId + "&gochiUserId=" + gochiUserId + "&shopId=" + shopId + "&shopName=" + encodeURIComponent(this.state.shopInfo.shopName) + moreParams;
        }
        else {
            window.location.href = "/menuCombo/detail?menuId=" + menuId + "&gochiUserId=" + gochiUserId + "&shopId=" + shopId + moreParams;
        }
        
    }

    checkUserAvailable = async (accessCode) => { 
        try {
            const { data } = await UserService.checkUserAvailable(accessCode);   
            return data;
        } catch (error) { 
            log('get accessTkoen error');
        }
    };

    async getDataDidMount(shopId, accessCode){
        if (accessCode) {
            const isAvailable = await this.checkUserAvailable(accessCode);
            if (isAvailable.userId === null){
                window.location.href = "/login";
            }
        }
        if (shopId) {
            this.setState({
                qrCodeResult: shopId
            })
            this.fetchData(shopId);
            this.getShopDetail(shopId);
        } else {
            this.setState({
                qrCodeResult: null
            })
        }
    }

    checkAccessScreen = async () => {
        let url = window.location.search;
        let params = queryString.parse(url);
        let accessToken = params.accessToken;
        let accessCode = params.accessCode;
        let shopId = params.shopId;
        if (!accessToken || !accessCode) {
            var link = "/login";
            if (!this.state.isLoggedIn){
                sessionStorage.setItem('loginCallbackUrl', window.location.pathname);
                return window.location.href = link;
            } else {
                if (this.state.userInfo) { 
                    if (this.state.userInfo.isLogined === false || this.state.userInfo.isGuest) { 
                        window.history.pushState(null, null, '/login');
                        return <Redirect to={link} />
                    }
                    if (this.state.userInfo.isLogined && !this.state.userInfo.isGuest) {
                        this.getDataDidMount(shopId);
                    }
                }else{
                    window.history.pushState(null, null, '/login');
                    return <Redirect to={link} />
                }
            }
        } else {
            if (this.state.isLoggedIn){
                sessionStorage.clear();
                localStorage.clear();
            }
            this.getDataDidMount(shopId, accessCode);
            sessionStorage.setItem("accessCode", accessCode);
        }
    }
    
	UNSAFE_componentWillMount(){ 
        this.checkAccessScreen();
    }
    
    componentDidMount() {
        var iOS = (navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false);
        var isWKWebView = false;
        if (window.webkit && window.webkit.messageHandlers && iOS) {
            isWKWebView = true;
        }

        this.setState({
            legacyMode: isWKWebView
        })
    }

    hideGochiCountError() {
		this.setState({gochiCountError: ''});
	}
    
    onUseGochi(gochiInfo) {
        console.log('onUseGochi', gochiInfo);
        if (gochiInfo.gochiCountAvailable < 1) {
            this.setState({
                gochiCountError: this.props.t('other.Ticket unavailable')
            });
            return;
        }
		if (gochiInfo.isETicket === 0) {
            this.setState({
                gochiCountError: this.props.t('other.Prevent paper ticket')
            });
            return;
        }
        this.setState({
            showGochiUse: true,
            gochiInfo: gochiInfo
        });
    }

    onCloseGochiInfo() {
		this.setState({
			showGochiUse: false,
		})
    }
    
    async handleScanQRCode(data) {
        if (data) {
            try {
                data = atob(data);
            } catch(err) {
                this.setState({
                    gochiCountError: this.props.t('pages.qrcode.Scan the QR code error')
                });
                return;
            }

            this.setState({
                qrCodeResult: data
            })
            await this.fetchData(data);
            await this.getShopDetail(data);
            window.history.pushState(null, null, '/qrCode?shopId='+ data);
        }
    }
    handleErrorQRCode = err => {
        console.error(err)
    }

    openImageDialog() {
        this.refs.qrReader1.openImageDialog()
    }

    getButtonBySystem(gochiInfo, displayLabelTicket, menuLimitTotal, clickHandler, onUseGochi, menu, shopId, userId, moreParams) {
        const url = window.location.search;
        let params = queryString.parse(url);
        if (params.siteType === "fukuoka") {
            return (
                <div className="ml-auto">
                    <Button 
                        variant="link"
                        onClick={
                            displayLabelTicket || menuLimitTotal?
                            () => clickHandler(displayLabelTicket, menu.menuId, shopId, userId, menuLimitTotal, menu.isETicket, moreParams)
                            :() => onUseGochi(gochiInfo)}
                        style={{height: 45}}
                    >
                        <img className="mr-0 rounded-circle fs-10" 
                            src={require('../../../assets/img/icons/fukuoka_button_gochirameru.png')} 
                            width="45" 
                            height="45" 
                            alt="Gochirareru button"
                        />
                    </Button>
                </div>
            )
        } else if (params.siteType === "suita") {
            return (
                <div className="ml-auto">
                    <Button 
                        variant="link"
                        onClick={
                            displayLabelTicket || menuLimitTotal?
                            () => clickHandler(displayLabelTicket, menu.menuId, shopId, userId, menuLimitTotal, menu.isETicket, moreParams)
                            :() => onUseGochi(gochiInfo)}
                        style={{height: 45}}
                    >
                        <img className="mr-0 rounded-circle fs-10" 
                            src={require('../../../assets/img/icons/suita_button_gochirameru.png')} 
                            width="45" 
                            height="45" 
                            alt="Gochirareru button"
                        />
                    </Button>
                </div>
            );
        } else if (params.siteType === "nishiwaki") {
            return (
                <div className="ml-auto">
                    <Button 
                        variant="link"
                        onClick={
                            displayLabelTicket || menuLimitTotal?
                            () => clickHandler(displayLabelTicket, menu.menuId, shopId, userId, menuLimitTotal, menu.isETicket, moreParams)
                            :() => onUseGochi(gochiInfo)}
                        style={{height: 45}}
                    >
                        <img className="mr-0 rounded-circle fs-10" 
                            src={require('../../../assets/img/icons/nishiwaki_button_gochirameru.png')} 
                            width="45" 
                            height="45" 
                            alt="Gochirareru button"
                        />
                    </Button>
                </div>
            );
        } else if (params.siteType === "hamamatsu") {
            return (
                <div className="ml-auto">
                    <Button 
                        variant="link"
                        onClick={
                            displayLabelTicket || menuLimitTotal?
                            () => clickHandler(displayLabelTicket, menu.menuId, shopId, userId, menuLimitTotal, menu.isETicket, moreParams)
                            :() => onUseGochi(gochiInfo)}
                        style={{height: 45}}
                    >
                        <img className="mr-0 rounded-circle fs-10" 
                            src={require('../../../assets/img/icons/hamamatsu_button_gochirameru.png')} 
                            width="45" 
                            height="45" 
                            alt="Gochirareru button"
                        />
                    </Button>
                </div>
            );
        }
    }


    render() {   
    	log(['props : ',this.props]); 
    	const { t } = this.props;   

        const loader = <div className="loader" key="loader">Loading ...</div>;
        
        let listGochis;
        const clickHandler = this.onGochiClicked;
        const onUseGochi = this.onUseGochi;
        const getButtonBySystem = this.getButtonBySystem;
        const url = window.location.search; 
        let params = queryString.parse(url);
        let siteTypeP = params.siteType ? "&siteType=" + params.siteType : '';
        let accessTokenP = this.state.accessToken ? "&accessToken=" + this.state.accessToken : '';
        let moreParams = accessTokenP + siteTypeP;
        
        const shopId = this.state.shopInfo.shopId || params.shopId;
        const shopName = this.state.shopInfo.shopName;
        const shopStatus = this.state.shopInfo.status;
        const userId = this.state.userInfo?.userId; 
        const isEducateSystem = params.siteType === "fukuoka" || params.siteType === "suita" || params.siteType === "nishiwaki" || params.siteType === "hamamatsu";

        if(this.state.listData){ 
	    	listGochis = this.state.listData.map(function(menu, key){
                const includedTaxPrice = menu.includedTaxPrice;
                const excludedTaxPrice = menu.excludedTaxPrice; 

                let menuImage = require("../../../assets/img/icons/gochi_noimg-ver4.jpg"); 
                if (menu.menuImage) { 
                    menuImage = menu.menuImage.length > 0 ? 
                            (menu.menuImage[0].image ? 
                                menu.menuImage[0].image : 
                                require("../../../assets/img/icons/gochi_noimg-ver4.jpg")) : 
                            require("../../../assets/img/icons/gochi_noimg-ver4.jpg");
                } else {
                  if (params.siteType === "fukuoka") {
                    menuImage = require("../../../assets/img/icons/icon_fukuoka_no_image.png");
                  } else if (params.siteType === "suita") {
                    menuImage = require("../../../assets/img/icons/icon_suita_no_image.jpg");
                  } else if (params.siteType === "nishiwaki" || params.siteType === "hamamatsu") {
                    menuImage = require("../../../assets/img/icons/icon_nishiwaki_no_image.png");
                  }
                }

                const gochiInfo = {
                    shopId: shopId,
                    shopName: shopName,
                    shopStatus: shopStatus,
                    groupId: menu.groupId,
                    menuName: menu.menuName,
                    menuId: menu.menuId,
                    menuImage: menuImage,
                    includedTaxPrice: includedTaxPrice,
                    displayAmount: menu.displayAmount,
                    excludedTaxPrice: excludedTaxPrice,
                    gochiCount: menu.gochiCount,
                    gochiExpiredDay: menu.gochiExpiredDay,
                    menuChildType: menu.menuChildType,
                    childFoods: menu.childFoods || [],
                    gochiCountAvailable: menu.gochiCountAvailable,
                    isETicket: menu.isETicket,
                    showPrice: menu.showPrice,
                    usageType: menu.usageType,
                    reducedTaxType: menu.reducedTaxType
                };

                const displayLabelTicket = menu !== null && menu.menuCombo===1;
                const menuLimitTotal = menu.menuLimitTotal === 1;
                const displayFreeTicketCount = menu !== null && menu.menuComboDetail!== null && 
                (menu.menuComboDetail.quantityFree > 0);

                let url = '';
                let campaignId = menu.campaignId ? "&campaignId=" + menu.campaignId : '';
                if (!displayLabelTicket && !menuLimitTotal) {
                    url = "/qrCode/detail?menuId=" + menu.menuId + "&shopId=" + shopId + campaignId + moreParams;
                } else if (menuLimitTotal) {
                    url = "/gochiBillDetail?menuId=" + menu.menuId + "&gochiUserId=" + userId + "&shopId=" + shopId + "&shopName=" + encodeURIComponent(shopName) + moreParams;
                } 
                    else {
                    url = "/menuCombo/detail?menuId=" + menu.menuId + "&gochiUserId=" + userId + "&shopId=" + shopId + moreParams;
                } 

	        	return (
                <div className="list-tome pb-3" key={menu.menuId}>
                    <div className="media pt-4">
                        <div style={{position: "relative", width: "76px"}} className="align-self-start mr-2 cursor">
                            <div style={{position: "relative", overflow:"hidden"}}>
                                <img className="align-self-start mr-2 cursor img-square" 
                                src={menuImage}
                                // onError={(e)=>{e.target.onerror = null; e.target.src="../../../assets/img/icons/gochi_noimg-ver4.jpg"}}
                                width="90" 
                                alt={menu.menuName || 'No image'} 
                                onClick={displayLabelTicket || menuLimitTotal?
                                    () => clickHandler(displayLabelTicket, menu.menuId, shopId, userId, menuLimitTotal, menu.isETicket, moreParams)
                                    :() => onUseGochi(gochiInfo)}
                                />
                                {displayLabelTicket && (
                                    <>
                                    <div className="div-multi-used-menu-qr" />
                                    <span className="label-multi-used-menu-tome">
                                        {t('pages.invite.Coupon')}
                                    </span>
                                    </>
                                )}
                            </div>
                            {displayFreeTicketCount && gochiInfo.showPrice !== 0 && (
                                <div className="pr-1 text-right">
                                    <div className="div-free-ticket-count-qr">
                                        <span className="label-free-ticket-count-qr">+{menu.menuComboDetail.quantityFree}{t('pages.invite.Free of charge')}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="media-body pr-2 width-remain-center">
                            <h5 
                                className="mt-0 fs-12 font-weight-bold cursor" 
                                onClick={displayLabelTicket || menuLimitTotal?
                                    () => clickHandler(displayLabelTicket, menu.menuId, shopId, userId, menuLimitTotal, menu.isETicket, moreParams)
                                    :() => onUseGochi(gochiInfo)}
                            >
                                {menu.menuName}
                                {/* {menu.menuCombo ? (
                                    <>
                                        {menu.menuComboDetail && menu.menuComboDetail.priceCombo && (menu.menuComboDetail.priceCombo).toLocaleString()}円×
                                        {menu.menuComboDetail && menu.menuComboDetail.quantity}枚
                                    </>
                                ) : ''} */}
                            </h5> 

                            {/* <div className="description">{menu.description}</div> */}
                            {/* {menu.description ? (
                                <Truncate 
                                    lines={1} 
                                    ellipsis={<span>... <Link 
                                                to={url} 
                                                className="text-green font-weight-bold showmore">詳細を見る</Link></span>} 
                                    className="text-description"
                                    width={206}
                                >
                                    {menu.description}
                                </Truncate>
                            ) : (
                                <Link
                                    to={url}
                                    className="text-green font-weight-bold showmore d-block">詳細を見る</Link>
                            )} */}

                            {!isEducateSystem && (
                              <div className="box-truncated">
                                  {menu.description && (
                                      <div className="text-truncated">{menu.description}</div>
                                  )}
                                  <a className="text-green font-weight-bold showmore link-detail" href={url}>{t('pages.qrcode.View the details')}</a>
                              </div>
                            )}

                            {menu && menu.menuComboDetail && menu.menuComboDetail.quantityFreeDes && (
                                <div className="div-free-ticket-desc break-line break-word">
                                    {menu.menuComboDetail.quantityFreeDes}
                                </div>
                            )}

                            {displayFreeTicketCount && gochiInfo.showPrice !== 0 && (
                                <div>
                                    <i className="icon-ticket"></i>
                                    <span className="fs-12 expiration ml-2 font-weight-bold">
                                        {"+" + menu.menuComboDetail.quantityFree + t('pages.invite.Free of charge')}
                                    </span>
                                </div>
                            )}

                            {/* <div className="text-green fs-10">詳細を見る</div> */}
                        </div> 
                        {params.siteType && isEducateSystem ? 
                        (
                            getButtonBySystem(gochiInfo, displayLabelTicket, menuLimitTotal, clickHandler, onUseGochi, menu, shopId, userId, moreParams)
                        ) : (
                            <div className="align-btn-use ml-auto">
                                <Button 
                                    variant="link"
                                    onClick={
                                        displayLabelTicket || menuLimitTotal?
                                        () => clickHandler(displayLabelTicket, menu.menuId, shopId, userId, menuLimitTotal, menu.isETicket, moreParams)
                                        :() => onUseGochi(gochiInfo)}
                                    style={{height: 45}}
                                >
                                    <img className="mr-0 rounded-circle fs-10" 
                                        src={require('../../../assets/img/icons/button_gochirareru.png')} 
                                        width="45" 
                                        height="45" 
                                        alt="Gochirareru button"
                                    />
                                </Button>
                            </div>
                        )}
                    </div> 
                    {!params.siteType && (
                        <div className="d-block count-info ml-2">
                            {gochiInfo.showPrice !== 0 && (
                              <>
                                  <span className="gochi-count d-inline-block">
                                      <i className="icon-fork"></i>
                                      <span className="count-no fs-10">{menu.gochiCount}</span>
                                      <span className="fs-10">{t('pages.tome.Gochi')}</span>
                                  </span>
                                  <span className="fs-10 align-self-center pl-1 coupon-price">
                                      {includedTaxPrice && includedTaxPrice.toLocaleString()}円
                                      （{t('pages.invite.Tax')}{excludedTaxPrice && excludedTaxPrice.toLocaleString()}円）
                                  </span>
                              </>
                            )}
                            {gochiInfo.menuChildType !== 5 && (
                            <span className="fs-10 align-self-center pl-1 expiration font-weight-bold text-nowrap">
                                <FontAwesomeIcon icon={faExclamationCircle} />
                                {t('pages.menu.Gochi expired day', {gochiExpiredDay: menu.gochiExpiredDay || 0})}
                            </span>
                            )}
                        </div>
                    )}
                </div>
                );
	      	});
        } 
        
        return (
            <>
                <div className="content">
                    <Header 
                        title = {shopName || t('pages.qrcode.QR code reading')}
                        isLoggedIn={this.state.isLoggedIn}
                        t={t}
                        siteType={this.state.siteType}
                        hasTitle={true}
                    />

                    <div className="content-mypage">
                        {this.state.siteType && isEducateSystem ? 
                        (
                            <div className="label-page d-flex align-items-center mb-0">
                                <span className="title-shop-fukuoka">{shopName ? shopName : t('pages.qrcode.QR code reading')}</span>
                            </div>
                        ) : (
                            <div className="label-page d-flex align-items-center mb-0">
                                <i className="label-menu"></i>
                                <span className="label-symbol">/</span>
                                <span className="label-text fs-11">{t('pages.qrcode.Feast menu & popular menu')}</span>
                            </div>
                        )}

                        {!this.state.qrCodeResult && !this.state.accessToken ? (
                            <div className="mt-3">
                                <QrReader
                                    ref="qrReader1"
                                    delay={300}
                                    onError={this.handleErrorQRCode}
                                    onScan={this.handleScanQRCode}
                                    style={{ width: '100%' }}
                                    // legacyMode={this.state.legacyMode}
                                />

                                {this.state.legacyMode && (
                                    // <input type="button" value="Upload QR Code" onClick={this.openImageDialog} />

                                    <div className="text-red mt-2">
                                        {t('pages.qrcode.Your browser does not support this feature')}
                                    </div>
                                )}
                            </div>
                        ) : ( 
                            <>
                                {(this.state.listData.length > 0) ? 
                                    <div className="pl-3 pr-3">
                                        <div className="infinite-scroll-mypage mt-1">
                                            <InfiniteScroll
                                                pageStart={0} 
                                                loadMore={this.fetchData}
                                                hasMore={this.state.hasMoreItems}
                                                loader={loader}> 
                                                    <div className="m-0">
                                                        {listGochis}
                                                    </div>
                                            </InfiniteScroll> 
                                        </div>
                                    </div>
                                :
                                <div className="div-no-recommend">
                                    <div className="div-no-recommend-txt">
                                        <div className="mb-1 font-weight-bold underline">{t('pages.qrcode.Is it your favorite menu?')}</div>
                                        <div className="mb-1">{t('pages.qrcode.This shop has not been registered yet')}</div>
                                        <div className="mb-1">{t('pages.qrcode.Support the store with a good price')}</div>
                                    </div>
                                    <i className="label-empty-menulist"></i>
                                </div>
                                }
                            </>
                        )}
                    </div>
                </div>

        {this.state.showGochiUse ? (
          <GochiUseInfor 
            showing={this.state.showGochiUse}
            onCloseGochiInfo={this.onCloseGochiInfo}
            gochiInfo={this.state.gochiInfo}
            sushiroFlag={this.state.shopInfo.sushiroFlag}
            />
        ) : null}

        {this.state.gochiCountError && (
				<Modal
					size="sm"
					centered
					show={!!this.state.gochiCountError}
					onHide={this.hideGochiCountError}
                    className="modal-alert-invite-flow"
				>
					<Modal.Header closeButton>
					{this.state.gochiCountError}
					</Modal.Header>
					{/* <ModalBody>
						{this.state.gochiCountError}
					</ModalBody> */}
				</Modal>
			)}
            </>
        ); 
    }
}
 

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
    showLoading: (showing) => dispatch(showLoading(showing)),
    showError: (showing, title, content) => dispatch(showError(showing, title, content)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(QRCode));
import {HTTP_API, makeRequest} from "../HttpClient";

const API_BASE_URL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : '';
const PUBLIC_CHANNEL_URL = process.env.REACT_APP_CHANNEL_PUBLIC_URL || '';

export default class ShopService {
  static async getDetailShop(shopId = null){   
    var url = `${API_BASE_URL}/v2/childShop/shops/${shopId}`; 
    return makeRequest(HTTP_API.GET, url);
  } 

  static async getDetailGroup(groupId = null){   
    var url = `${API_BASE_URL}/childShop/groups/${groupId}`; 
    return makeRequest(HTTP_API.GET, url);
  } 

  static async getListlShopsCmm(menuId = null, loginUserId, sort, page = 1, latitude, longitude, keyword, accessToken, checkTokenExpired){
    // var url = `${API_BASE_URL}/menus/${menuId}/shops?loginUserId=${loginUserId}&sort=${sort}&page=${page}&limit=10&latitude=${latitude}&longitude=${longitude}`;
    let params = [];
    if (loginUserId) {
      params.push(`loginUserId=${loginUserId}`);
    }
    if (latitude) {
      params.push(`latitude=${latitude}`);
    }
    if (longitude) {
      params.push(`longitude=${longitude}`);
    }
    if (sort) {
      params.push(`sort=${sort}`);
    }
    if (keyword) {
      params.push(`keyword=${keyword}`);
    }
    params.push(`page=${page}`);
    params.push(`limit=${10}`);

    var url = `${API_BASE_URL}/v2/menus/${menuId}/shops?${params.join("&")}`;
    return makeRequest(HTTP_API.GET, url, null, null, null, null, null, null, accessToken, checkTokenExpired);
  } 

  static async createFavoriteShop(userId, shopId){
    var params = {userId, shopId};  
    var url = `${API_BASE_URL}/favorites/shops/create`;
    return makeRequest(HTTP_API.POST, url, params);
  };

  static async destroyFavoriteShop(userId, shopId){
    var params = {userId, shopId};
    var url = `${API_BASE_URL}/favorites/shops/destroy`;
    return makeRequest(HTTP_API.POST, url, params);
  };

  static async getGochiBillShopMessages(shopId, page, limit) {
    var url = `${API_BASE_URL}/childShop/shops/messages`;
    return makeRequest(HTTP_API.GET, url, {
      shopId,
      page,
      limit
    });
  }

  static async getShopInGroup(params) {
    var url = `${PUBLIC_CHANNEL_URL}/lexus/shops`;
    return makeRequest(HTTP_API.GET, url, params);
  }

  static async getShopGochiKid(params) {
    var url = `${PUBLIC_CHANNEL_URL}/gochi-kid/home-page/shops`;
    return makeRequest(HTTP_API.GET, url, params);
  }
}

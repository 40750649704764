import SwiperCore, { Navigation, Pagination, Thumbs } from "swiper";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Navigation, Pagination, Thumbs]);

export const swiperConfigs = {
  products: {
    loop: true,
    loopAdditionalSlides: 1,
    watchOverflow: true,
    loopedSlides: 10, // loop: true;の場合必須。すべてのスライドの半分の値
    slidesPerView: 2,
    spaceBetween: 16,
    slidesPerGroup: 2,
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    resizeObserver: true,
    navigation: {
      nextEl: ".slider-btn-next",
      prevEl: ".slider-btn-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    breakpoints: {
      1000: {
        // CSS側で.swiper-slideで横幅と高さを指定
        slidesPerView: 5,
        spaceBetween: 18,
        slidesPerGroup: 5,
      },
    },
    onUpdate: (swiper) => {
      console.log("on update:");
      swiper.slideToLoop(0);
    },
  },
  swiper: {
    loop: true,
    // loopAdditionalSlides: 1,
    watchOverflow: true,
    loopedSlides: 8, // loop: true;の場合必須。すべてのスライドの半分の値
    slidesPerGroup: 2,
    slidesPerView: 2,
    spaceBetween: 18,
    loopAdditionalSlides: 100,
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    resizeObserver: true,
    navigation: {
      nextEl: ".slider-btn-next",
      prevEl: ".slider-btn-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    breakpoints: {
      1000: {
        // CSS側で.swiper-slideで横幅と高さを指定
        slidesPerView: 4,
        spaceBetween: 12,
        slidesPerGroup: 4,
      },
    },
    onUpdate: (swiper) => {
      console.log("on update:");
      swiper.slideToLoop(0);
    },
  },
  pickup: {
    loop: true,
    loopAdditionalSlides: 1,
    watchOverflow: true,
    loopedSlides: 8, // loop: true;の場合必須。すべてのスライドの半分の値
    slidesPerView: 1,
    spaceBetween: 20,
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    resizeObserver: true,
    navigation: {
      nextEl: ".slider-btn-next",
      prevEl: ".slider-btn-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    breakpoints: {
      1000: {
        slidesPerView: 2,
        spaceBetween: 24,
        slidesPerGroup: 2,
      },
    },
    onUpdate: (swiper) => {
      console.log("on update:");
      swiper.slideToLoop(0);
    },
  },
  menu: {
    loop: true,
    loopAdditionalSlides: 1,
    watchOverflow: true,
    loopedSlides: 8, // loop: true;の場合必須。すべてのスライドの半分の値
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 16,
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    resizeObserver: true,
    navigation: {
      nextEl: ".slider-btn-next",
      prevEl: ".slider-btn-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    breakpoints: {
      1000: {
        // CSS側で.swiper-slideで横幅と高さを指定
        slidesPerView: 4,
        spaceBetween: 18,
        slidesPerGroup: 4,
      },
    },
    onUpdate: (swiper) => {
      console.log("on update:", swiper);
      swiper.slideToLoop(0);
    },
  },
  magazine: {
    loop: true,
    loopAdditionalSlides: 1,
    watchOverflow: true,
    // loopedSlides: 8, // loop: true;の場合必須。すべてのスライドの半分の値
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 18,
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    resizeObserver: true,
    navigation: {
      nextEl: ".slider-btn-next",
      prevEl: ".slider-btn-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    breakpoints: {
      1000: {
        // CSS側で.swiper-slideで横幅と高さを指定
        slidesPerView: 6,
        spaceBetween: 12,
        slidesPerGroup: 6,
      },
    },
    onUpdate: (swiper) => {
      console.log("on update:");
      swiper.slideToLoop(0);
    },
  },
  thumbnails: {
    spaceBetween: 10,
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    resizeObserver: true,
    slidesPerView: 5,
    freeMode: true,
    loopFillGroupWithBlank: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    breakpoints: {
      1000: {
        direction: "vertical",
      },
    },
    onUpdate: (swiper) => {
      console.log("on update:");
      swiper.slideToLoop(0);
    },
  },
  gallery: {
    loop: true,
    loopAdditionalSlides: 1,
    watchOverflow: true,
    slidesPerView: 1,
    spaceBetween: 20,
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    resizeObserver: true,
    navigation: {
      nextEl: ".slider-btn-next",
      prevEl: ".slider-btn-prev",
    },
    pagination: {
      el: ".swiper-pagination,.slider-pagination",
      type: "bullets",
      clickable: true,
    },
    onUpdate: (swiper) => {
      console.log("on update:");
      swiper.slideToLoop(0);
    },
  },
  comment: {
    loop: true,
    loopAdditionalSlides: 1,
    watchOverflow: true,
    slidesPerView: 1,
    slidesPerGroup: 1,
    loopedSlides: 8,
    spaceBetween: 20,
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    resizeObserver: true,
    navigation: {
      nextEl: ".slider-btn-next",
      prevEl: ".slider-btn-prev",
    },
    pagination: {
      el: ".slider-coment .swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    breakpoints: {
      660: {
        slidesPerView: 2,
        loopedSlides: 2,
        slidesPerGroup: 2,
      },
      1000: {
        slidesPerView: 3,
        loopedSlides: 3,
        slidesPerGroup: 3,
      },
    },
    onUpdate: (swiper) => {
      console.log("on update:");
      swiper.slideToLoop(0);
    },
  },
  choice: {
    loop: true,
    loopAdditionalSlides: 1,
    watchOverflow: true,
    loopedSlides: 8, // loop: true;の場合必須。すべてのスライドの半分の値
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 20,
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    resizeObserver: true,
    navigation: {
      nextEl: ".slider-btn-next",
      prevEl: ".slider-btn-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    breakpoints: {
      660: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      1000: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
    },
    onUpdate: (swiper) => {
      console.log("on update:");
      swiper.slideToLoop(0);
    },
  },
  footer: {
    loop: true,
    loopAdditionalSlides: 1,
    watchOverflow: true,
    loopedSlides: 8, // loop: true;の場合必須。すべてのスライドの半分の値
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 16,
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    resizeObserver: true,
    navigation: {
      nextEl: ".slider-btn-next",
      prevEl: ".slider-btn-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    onUpdate: (swiper) => {
      console.log("on update:");
      swiper.slideToLoop(0);
    },
  },
  special: {
    // autoplay: {
    //     delay: 5000,
    //     disableOnInteraction: false
    // },
    loop: true,
    loopAdditionalSlides: 1,
    watchOverflow: true,
    loopedSlides: 8, // loop: true;の場合必須。すべてのスライドの半分の値
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 16,
    navigation: {
      nextEl: ".slider-btn-next",
      prevEl: ".slider-btn-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    breakpoints: {
      1000: {
          // CSS側で.swiper-slideで横幅と高さを指定
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 12
      },
    },
    onUpdate: (swiper) => {
      console.log("on update:");
      swiper.slideToLoop(0);
    },
  },
  aside: {
    // autoplay: {
    //     delay: 5000,
    //     disableOnInteraction: false
    // },
    loop: true,
    loopAdditionalSlides: 1,
    watchOverflow: true,
    loopedSlides: 8, // loop: true;の場合必須。すべてのスライドの半分の値
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 16,
    navigation: {
      nextEl: ".slider-btn-next",
      prevEl: ".slider-btn-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    breakpoints: {
      1000: {
        // CSS側で.swiper-slideで横幅と高さを指定
        slidesPerView: 5,
        slidesPerGroup: 5,
        spaceBetween: 18,
      },
    },
    onUpdate: (swiper) => {
      console.log("on update:");
      swiper.slideToLoop(0);
    },
  },
  situation: {
    loop: true,
    loopAdditionalSlides: 1,
    watchOverflow: true,
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 18,
    // loopAdditionalSlides: 100,
    navigation: {
        nextEl: '.slider-btn-next',
        prevEl: '.slider-btn-prev',
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
    },
    breakpoints: {
        1000: {
            // CSS側で.swiper-slideで横幅と高さを指定
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 12
        },
    },
    onUpdate: (swiper) => {
      console.log("on update:");
      swiper.slideToLoop(0);
    },
  }
};

export function getSlidePerView(screenInnerWidth, swiperOptions) {
  const sp = swiperOptions.slidesPerView;
  const pc = swiperOptions.breakpoints && swiperOptions.breakpoints['1000']?.slidesPerView;
  const pcsp = swiperOptions.breakpoints && swiperOptions.breakpoints['660']?.slidesPerView;
  if (screenInnerWidth > 999 && pc) {
    return pc;
  }
  if (screenInnerWidth > 660 && pcsp) {
    return pcsp;
  }
  return sp;
};
import React, { Component } from "react";   
import {connect} from 'react-redux';  
import { withTranslation } from 'react-i18next';
import { log } from '../../utils'; 
import Image from 'react-bootstrap/Image';
import GiftCardService from "../../api/services/GiftCardService";
import {showLoading} from "../../actions/action_loading";
import {showError} from "../../actions/action_error_alert";
import * as HorizontalInfiniteScroll from 'react-bidirectional-infinite-scroll';

// import Button from 'react-bootstrap/Button';
// import Media from 'react-bootstrap/Media';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

class ListGiftCards extends Component {
	constructor(props){
		super(props)
		this.state = {
			listData: [],
            hasMoreItems: true,
			page: 1,
			limit: 5,
			// selectedCard: null,
			// prevSelectedCard: sessionStorage.getItem('messageCard') ? JSON.parse(sessionStorage.getItem('messageCard')).giftCardId : null,
			anchorEl: null
		}
		this.fetchMoreData = this.fetchMoreData.bind(this);
		this.getListCards = this.getListCards.bind(this);
		this.handleSelectCard = this.handleSelectCard.bind(this);
	}

	async getListCards(cateId, page, limit) {
        try {
            let response = await GiftCardService.getListCards(cateId, page, limit);
			let data = response.data;

			if (data.count > 0) {
				var listCards = this.state.listData;
				data.list.map(item => {
					return listCards.push(item);
				});

				this.setState({
					listData: listCards
				});

				if (page*limit < data.count) {
					this.setState({
						// page: this.state.page + 1,
						// listData: list,
						hasMoreItems: true
					});
				} else {
					this.setState({
						hasMoreItems: false,
						page: 1
					});
				}
			}
        } catch (e) {
            log(e);
        }
    }

	fetchMoreData = async (cateId) => {
		try {
			await this.getListCards(cateId, this.state.page + 1, this.state.limit);
			this.setState({
				page: this.state.page + 1
			});
		} catch (e) {
			log(e);
		}
	};

	handleSelectCard = (e, card) => {
		e.preventDefault();
		var currentCardId = parseInt(e.currentTarget.getAttribute('value'));

		// this.setState({
		// 	selectedCard: currentCardId,
		// 	prevSelectedCard: null
		// });
		this.props.handleSelectCard(card, currentCardId);
	}

	componentDidMount(){
		this.getListCards(this.props.giftCardCategoryId, this.state.page, this.state.limit);
	} 

	render() {
		// log(["props on list cards popup", this.props]);
		// const { t } = this.props;

		const cateId = this.props.giftCardCategoryId;
		const cateName = this.props.giftCardCategoryName;
		const giftCardCount = this.props.giftCardCount;
		const key = this.props.key;

		return (
			<>
				<div className="thk-card-div" key={key}>
					<div className="fs-18 clr-000000">{cateName} ({giftCardCount})</div>
					<div className="line-horizontal label-ln mt-1 mb-3"></div>
					<div className="thk-card-row">
						{this.state.listData.length > 0 && (
							<HorizontalInfiniteScroll
								horizontal
								onReachRight={() => {this.state.hasMoreItems && this.fetchMoreData(cateId)}}>
								{this.state.listData.map((i, index) => (
									<Image
										key={i.giftCardName + index}
										fluid
										rounded
										className={`img-thk-card ${(this.props.selectedCard) === i.giftCardId ? 'selected-card' : ''}`}
										alt={i.giftCardName}
										src={i.image} 
										value={i.giftCardId}
										onClick={(e) => this.handleSelectCard(e, i)}
										onError={(e)=>{e.target.onerror = null; e.target.src=require('../../assets/img/icons/no-images.jpeg')}}
									/>
								))}
								{this.state.hasMoreItems ? <span key={0}>Loading...</span> : null}
							</HorizontalInfiniteScroll>
						)}
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
	showLoading: (showing) => dispatch(showLoading(showing)),
	showError: (showing, title, content, buttonName, gotoUrl) =>dispatch(showError(showing, title, content, buttonName, gotoUrl)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ListGiftCards)); 
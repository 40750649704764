import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModalSituationAction, setSituationAction } from "../../../actions/action_situations";

export function SituationModal(props) {
  const dispatch = useDispatch();
  const situations = useSelector((state) => state.listSituations);
  const situation = useSelector((state) => state.situation.situation);
  const setSituation = (payload) => dispatch(setSituationAction(payload));
  const modalSituation = useSelector((state) => state.situation.modalSituation);
  const setModalSituation = (payload) => dispatch(setModalSituationAction(payload));


  function enterSituation() {
    setSituation(modalSituation);
    window.disappearModal();
  }

  function closeSituation() {
    setModalSituation(situation)
    window.disappearModal();
  }

  return (
    <div className="modal" tabIndex={-1} id="situationModal">
      <button
        className="modal-btn"
        id="modalClose"
        type="button"
        onClick={closeSituation}
      >
        <svg className="icon">
          <title>閉じる</title>
          <use xlinkHref="/assets/img/common/icon.svg#times" />
        </svg>
      </button>
      <div className="modal-header heading-lg">
        <div className="title">シチュエーション</div>
      </div>
      <div className="modal-body">
        <div className="field field-radio field-pill">
          {situations.map((item, index) => (
            <label key={index}>
              <input
                className="before-icon"
                value={item.menuSituationName}
                readOnly
                name="situation"
                type="radio"
                checked={modalSituation === item.menuSituationName}
                onClick={(e) => {
                  if (modalSituation === item.menuSituationName) {
                    setModalSituation('');
                  } else
                  setModalSituation(e.target.value)
                }}
              />
              <div className="btn btn-sm btn-outline btn-black">
                {item.menuSituationName}
              </div>
            </label>
          ))}
        </div>
      </div>
      <div className="modal-footer">
        <div className="btn-group">
          <button
            className="btn"
            // id="enterSituation"
            onClick={enterSituation}
          >
            決定する
          </button>
        </div>
      </div>
    </div>
  );
}

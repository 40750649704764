import React, { Component } from "react";    
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'; 
import Truncate from 'react-truncate';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

class GochiMore extends Component { 
	constructor(props) { 
	  	super(props); 
	  	this.state = {
	    	gochiBill: {},  
	  	};
	}  

	componentDidMount(){

	}

    render() {

        const { t } = this.props;
        
        let isUsed = true;
        let numberCounponUsed = 0;
        let listDateUsed = [];
    
        // get list date used of counpon
        const couponMessage = this.props.gochiBill.couponMessage;
        for (let index = 0; index < couponMessage.length; index++) {
            if (couponMessage[index].useDate === null) {
                isUsed = false;
            } else {
                numberCounponUsed++;
                if (couponMessage[index].useDate) {
                listDateUsed.push(couponMessage[index].useDate);
                }
            }
        }
    
        // function get max date in list
        const getLastDateUsed = (list) => {
            var max_dt = list[0];
            var max_dtObj = new Date(list[0]);
            list.forEach(function (dt, i) {
                if (new Date(dt) > max_dtObj) {
                max_dt = dt;
                max_dtObj = new Date(dt);
                }
            });
            return max_dt;
        };

        var d = new Date();
        var n = d.getFullYear();
    
        // function convert date
        const getUseDate = (date) => {
            return date
                ? moment(date).format(
                    moment(date).isAfter(n + '-01-01') ? 'M月 D日' : 'YYYY年 M月 D日',
                )
                : '';
        };

        return (
        	<div className="gochi-more">
                <div className="date-count">
                    {isUsed ? (
                        <div className="gochi-date-use">
                            {/* {getUseDate(getLastDateUsed(listDateUsed))}に利用されました。 */}
                            {t('pages.mypage.It was used on', {usedDate: getUseDate(getLastDateUsed(listDateUsed))})}
                        </div>
                    ) : (
                        <div className="gochi-date-expired">
                            <FontAwesomeIcon icon={faExclamationCircle} className={"icon-warning"}/>
                            <span>{t('pages.menu.Gochi expired day', {gochiExpiredDay: this.props.gochiBill.gochiExpiredDay || 0})}</span>
                        </div>
                    )}
                    <div className="gochi-number-used">{t('pages.mypage.Status of use')}：{numberCounponUsed}/{this.props.gochiBill.couponMessage.length}{t('pages.invite.pieces')}</div>
                </div>
                {this.props.gochiBill.memo ? (
                    <div className="gochi-memo">
                        <FontAwesomeIcon icon={faBookmark} className={"icon-bookmark"}/>
                            <span className="bold">{t('pages.mypage.memo')}：</span>
                        <Truncate lines={1} ellipsis={<span>... <Link to={`/mypage/gochi?gochiBillId=`+this.props.gochiBill.gochiBillId} className="showmore">
                            {t('pages.mypage.showmore')}
                        </Link></span>} className="text-memo" width={220}>
                            {/* {this.props.gochiBill.memo} */}
                            {this.props.gochiBill.memo && this.props.gochiBill.memo.split('\n').map((lineText, i, arr) => {
                                const line = <span key={i}>{lineText}</span>;
                        
                                if (i === arr.length - 1) {
                                    return line;
                                } else {
                                    return [line, <br key={i + 'br'} />];
                                }
                            })}
                        </Truncate>
                        
                    </div>
                ) : null}
                {this.props.gochiBill.gochiShopMessage ? (
                    <div className="date-count mt-2">
                        <div className="gochi-shop-message space-pre-line">{this.props.gochiBill.gochiShopMessage[0] ? this.props.gochiBill.gochiShopMessage[0].shopMessage : ''}</div>
                    </div>
                ) : null}
            </div>
        ); 
    }
} 

const mapStateToProps = state => ({
	...state
}); 
 
export default connect(mapStateToProps)(withTranslation()(GochiMore)); 

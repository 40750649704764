import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { clearDonate } from "../../../actions/GochiKid/action_donate";
import { GOCHI_KID_PATH } from "../../../constants";
import { log, useCities, usePrefectures } from "../../../utils";
import PolicyCheckModal from "../common/PolicyCheckModal";
import Header from "../layout/Header";
import DonateRegionModal from "../common/DonateRegionModal";
import DonateActivityModal from "../common/DonateActivityModal";
import { getPrefecturesSuccess } from "../../../actions/action_prefectures";
import { getCitiesSuccess } from "../../../actions/action_cities";

function DonateDestination(props) {
  usePrefectures((list) => props.savePrefectures(list));
  useCities((list) => props.saveCities(list));
  const history = useHistory();

  const [pageReady, setPageReady] = useState(false);
  const [showPolicyCheck, setShowPolicyCheck] = useState(true);
  const [choice, setChoice] = useState("national");
  const [showRegionModal, setShowRegionModal] = useState(false);
  const [showActivityModal, setShowActivityModal] = useState(false);

  function redirectToDonateInput(extraData) {
    let locationSearch = '';
    if (extraData) {
      const { shopId, groupId } = extraData;
      if (shopId) {
        locationSearch = "?donateShopId=" + shopId;
      } else if (groupId) {
        locationSearch = "?donateGroupId=" + groupId;
      }
    }
    history.push(GOCHI_KID_PATH + "/support/choice/" + choice + "/input" + locationSearch);
  }

  const nextStep = () => {
    switch (choice) {
      case 'region':
        setShowRegionModal(true);
        break;
      case 'activity':
        setShowActivityModal(true);
        break;
      default:
        redirectToDonateInput();
    }
  };

  const onPageLoad = () => {
    setPageReady(true);
  };

  useEffect(() => {
    log('DonateDestination componentDidMount');
    if (document.readyState === 'complete') {
      log('page already complete');
      onPageLoad();
    } else {
      log('add event listener "load"');
      window.addEventListener('load', onPageLoad);
    }

    props.clearDonate();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header />
      {pageReady && <main className="page-wrapper">
        <div className="page-content donate-destination">
          <div className="font-family-rounded title-donate text-center">支援先の選択</div>
          <section className="body-donate-destination">
            <article className="box-donate-destination">
              <button className={`btn-outline btn-donate-destination font-family-rounded ${choice === "national" ? 'background-FBEBEC' : ''}`} onClick={() => setChoice("national")}>
                <i className="icon-national"/>
                全国を支援する
              </button>
              <div className="description">
                <span className="color-35A99E">全国どこでも</span>
                <span>、こどもごちめしの活動を支援してくださる方はこちら</span>
              </div>
            </article>
            <article className="box-donate-destination">
              <button className={`btn-outline btn-donate-destination font-family-rounded ${choice === "region" ? 'background-FBEBEC' : ''}`} onClick={() => setChoice("region")}>
                <i className="icon-group"/>
                都道府県から選択する
              </button>
              <div className="description">
                <span className="color-35A99E">地域</span>
                <span>のこどもごちめしの活動を支援してくださる方はこちら</span>
              </div>
            </article>
            <article className="box-donate-destination">
              <button className={`btn-outline btn-donate-destination font-family-rounded ${choice === "activity" ? 'background-FBEBEC' : ''}`} onClick={() => setChoice("activity")}>
                <i className="icon-shop"/>
                自治体/活動から選択する
              </button>
              <div className="description">
                <span className="color-35A99E">活動から</span>
                <span>こどもごちめしの支援をしてくださる方はこちら</span>
              </div>
            </article>
          </section>
          <div className="box-btn">
            <button className="btn-primary" onClick={nextStep}>
              選択して次へ進む
              <i className="icon-next-destination"/>
            </button>
          </div>
        </div>
      </main>}

      <PolicyCheckModal show={pageReady && showPolicyCheck} handleClose={() => setShowPolicyCheck(false)} />

      <DonateRegionModal
        nextStep={redirectToDonateInput}
        show={showRegionModal}
        handleClose={() => setShowRegionModal(false)}
      />

      <DonateActivityModal
        nextStep={redirectToDonateInput}
        show={showActivityModal}
        handleClose={() => setShowActivityModal(false)}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

const mapDispatchToProps = dispatch => ({
  savePrefectures: (list) => dispatch(getPrefecturesSuccess(list)),
  saveCities: (list) => dispatch(getCitiesSuccess(list)),
  clearDonate: () => dispatch(clearDonate())
});

export default connect(mapStateToProps, mapDispatchToProps)(DonateDestination);

import React from "react";
import { Link } from "react-router-dom";

export function InfoBox(props) {
  function getPriceRangeStrFromObj(range) {
    return `${range.priceRangeName && range.priceRangeName.toLocaleString()}`;
  }

  return (
    <section className={props.loading ? "section" : "section loading"}>
      <div className="section-header">
        <div className="heading-lg">
          <h2 className="title">{props.shopName}に関連のあるタグ</h2>
        </div>
      </div>
      <div className="section-body">
        <section className="box">
          <dl className="list-category">
            <div className="list-category-item">
              <dt className="title">エリア</dt>
              <dd className="text">
                <ul className="list-inline">
                  <li>
                    <Link to={`/products?prefecture=${props.prefectures}`}>
                      {props.prefectures || ""}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/products?prefecture=${props.prefectures}&city=${props.city}`}
                    >
                      {props.city || ""}
                    </Link>
                  </li>
                </ul>
              </dd>
            </div>
            {props.genre && (
              <div className="list-category-item">
                <dt className="title">ジャンル</dt>
                <dd className="text">
                  <ul className="list-inline">
                    <li>
                      <Link
                        to={`/products?genre=${
                          props.genre?.genreName || props.genre?.menuGenreName
                        }`}
                      >
                        {props.genre?.genreName || props.genre?.menuGenreName}
                      </Link>
                    </li>
                    {/* <li>
                          <a href>海鮮</a>
                        </li> */}
                  </ul>
                </dd>
              </div>
            )}
            {props.priceRanges?.length > 0 && (
              <div className="list-category-item">
                <dt className="title">予算</dt>
                <dd className="text">
                  <ul className="list-inline">
                    {props.priceRanges?.map((item) => (
                      <li key={item.priceRangeId}>
                        <Link to={`/products?prices=${item.priceRangeName}`}>
                          {getPriceRangeStrFromObj(item)}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </dd>
              </div>
            )}
            {props.menuSituations?.length > 0 && (
              <div className="list-category-item">
                <dt className="title">シチュエーション</dt>
                <dd className="text">
                  <ul className="list-inline">
                    {props.menuSituations?.map((item) => (
                      <li key={item.menuSituationId}>
                        <Link
                          to={`/products?situation=${item.menuSituationName}`}
                        >
                          {item.menuSituationName}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </dd>
              </div>
            )}
            {props.menuUserTargets?.length > 0 && (
              <div className="list-category-item">
                <dt className="title">贈り相手</dt>
                <dd className="text">
                  <ul className="list-inline">
                    {props.menuUserTargets?.map((item) => (
                      <li key={item.menuUserTargetId}>
                        <Link to={`/products?who=${item.name}`}>
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </dd>
              </div>
            )}
          </dl>
        </section>
      </div>
    </section>
  );
}

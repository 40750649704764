import {HTTP_API, makeRequest} from "../HttpClient";

const API_BASE_URL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : '';

export default class GochiService {
  static async getGochiList(
    {shopId,
    menuId,
    gochiType,
    gochiUserType,
    couponCountGte,
    gochiRegisterId,
    gochiUserId,
    userInvitedId,
    status,
    page,
    limit,
    sort
    }){
    let  params = [];
    if (shopId) {
        params.push(`shopId=${shopId}`);
    }
    if (menuId) {
        params.push(`menuId=${menuId}`);
    }
    if (gochiType) {
        params.push(`gochiType=${gochiType}`);
    }
    if (gochiUserType) {
        gochiUserType.forEach((userType) => {
        params.push(`gochiUserType=${userType}`);
        });
    }
    if (couponCountGte) {
        params.push(`couponCountGte=${couponCountGte}`);
    }
    if (gochiRegisterId) {
        params.push(`gochiRegisterId=${gochiRegisterId}`);
    }
    if (userInvitedId) {
        params.push(`userInvitedId=${userInvitedId}`);
    }
    if (gochiUserId) {
        params.push(`gochiUserId=${gochiUserId}`);
    }
    if (status) {
        status.map((s) => params.push(`status=${s}`));
    }
    if (sort) {
        params.push(`sort=${sort}`);
    }
    params.push(`page=${page}`);
    params.push(`limit=${limit}`);

    var url = `${API_BASE_URL}/v2/gochi?${params.join("&")}`;
    return makeRequest(HTTP_API.GET, url);
  }
  
  static async getGochi({gochiBillId, accessToken, checkTokenExpired}){
    let params = [];
    var url = `${API_BASE_URL}/gochi/${gochiBillId}?${params.join("&")}`; 
    return makeRequest(HTTP_API.GET, url, null, null, null, null, null, null, accessToken, checkTokenExpired);
  }

  static async getGochiCombo(gochiBillId, menuId, shopId, gochiUserId){
    let params = [];

    if (gochiBillId) {
        params.push(`gochiBillId=${gochiBillId}`);
    }
    if (shopId) {
        params.push(`shopId=${shopId}`);
    }
    if (gochiUserId) {
        params.push(`gochiUserId=${gochiUserId}`);
    }
    var url = `${API_BASE_URL}/menus/menuCombo/${menuId}?${params.join("&")}`;
    return makeRequest(HTTP_API.GET, url);
  }

  static async getGochiToMe(
      {
        gochiUserType,
        couponCountGte,
        gochiUserId,
        sort,
        page,
        limit
      }){
    let params = [];
    if (gochiUserType) {
        gochiUserType.forEach((userType) => {
        params.push(`gochiUserType=${userType}`);
        });
    }
    if (couponCountGte) {
        params.push(`couponCountGte=${couponCountGte}`);
    }
    if (gochiUserId) {
        params.push(`gochiUserId=${gochiUserId}`);
    }
    if (sort) {
        params.push(`sort=${sort}`);
    }
    params.push(`page=${page}`);
    params.push(`limit=${limit}`);

    var url = `${API_BASE_URL}/v2/gochi/toMe?${params.join("&")}`;
    return makeRequest(HTTP_API.GET, url);
  }

  static async getChildFoods({
      shopId,
      menuId,
      page,
      limit
    }){
        let params = [];
        if (shopId) {
            params.push(`shopId=${shopId}`);
        }
        if (menuId) {
            params.push(`menuId=${menuId}`);
        }
        params.push(`page=${page}`);
        params.push(`limit=${limit}`);

        var url = `${API_BASE_URL}/childShop/foods?${params.join("&")}`;
        return makeRequest(HTTP_API.GET, url);
    };

  static async updateMemo(params){    
    var url = `${API_BASE_URL}/gochi/memo`;
    return makeRequest(HTTP_API.PUT, url, params);
  }

  static async useGochi(params, crfsToken, accessToken){ 
    var url = `${API_BASE_URL}/v3/gochi`;
    return makeRequest(HTTP_API.PUT, url, params, true, {'csrf_token': crfsToken}, null, null, null, accessToken);
  };

//   static async getGochiDetail({gochiBillId}){
//     var url = `${API_BASE_URL}/gochi/${gochiBillId}`;
//     return makeRequest(HTTP_API.GET, url);
//   }

    static async getQRCodeGochiList(
        {
            gochiUserId, 
            shopId, 
            page,
            limit,
            accessToken,
            checkTokenExpired
        }){
        let  params = [];
        if (shopId) {
            params.push(`shopId=${shopId}`);
        }
         
        if (gochiUserId) {
            params.push(`gochiUserId=${gochiUserId}`);
        }
        
        params.push(`page=${page}`);
        params.push(`limit=${limit}`);
        var url = `${API_BASE_URL}/childShop/menus/scanQr?${params.join("&")}`;
        return makeRequest(HTTP_API.GET, url, null, null, null, null, null, null, accessToken, checkTokenExpired);
    };

    static async getPastOrders(
        {
            gochiRegisterId,
            sort,
            page,
            limit 
        }){
        let  params = [];
        if (gochiRegisterId) {
            params.push(`gochiRegisterId=${gochiRegisterId}`);
        }
         
        if (sort) {
            params.push(`sort=${sort}`);
        }
        
        params.push(`page=${page}`);
        params.push(`limit=${limit}`);

        var url = `${API_BASE_URL}/v3/orders?${params.join("&")}`;
        return makeRequest(HTTP_API.GET, url);
    };

    static async sendMailGochiBill(receiptAddress, gochiBillIds){
        var params = {
            receiptAddress: receiptAddress,
            gochiBillIds: gochiBillIds
        };
        var url = `${API_BASE_URL}/orders/sendmail`; 
        return makeRequest(HTTP_API.POST, url, params);
    };

    static async confirmGochiKid(shopId, menuId, useAmount, totalUseAmount, userKidIds, accessToken) {
        console.log('userKidIds', userKidIds);
        let  params = [];
        if (Array.isArray(userKidIds)){
            userKidIds.forEach(element => {
                params.push(`userKidIds=${element}`);
            });
        } else {
            params.push(`userKidIds=${userKidIds}`);
        }
        if (shopId) {
            params.push(`shopId=${shopId}`);
        }
        if (menuId) {
            params.push(`menuId=${menuId}`);
        }
        if (useAmount) {
            params.push(`useAmount=${useAmount}`);
        }
        if (totalUseAmount) {
            params.push(`totalUseAmount=${totalUseAmount}`);
        }
        var url = `${API_BASE_URL}/gochi-kid/my-page/confirm-use?${params.join("&")}`;
        return makeRequest(HTTP_API.GET, url, null, null, null, null, null, null, accessToken);
    };

    static async confirmGochiKidGroupSpecial(shopId, menuId, useAmount, totalUseAmount, userKidIds, gochiKidGroupId, accessToken) {
        console.log('userKidIds', userKidIds);
        let  params = [];
        if (Array.isArray(userKidIds)){
            userKidIds.forEach(element => {
                params.push(`userKidIds=${element}`);
            });
        } else {
            params.push(`userKidIds=${userKidIds}`);
        }
        if (shopId) {
            params.push(`shopId=${shopId}`);
        }
        if (menuId) {
            params.push(`menuId=${menuId}`);
        }
        if (useAmount) {
            params.push(`useAmount=${useAmount}`);
        }
        if (gochiKidGroupId) {
          params.push(`gochiKidGroupId=${gochiKidGroupId}`);
        }
        if (totalUseAmount) {
          params.push(`totalUseAmount=${totalUseAmount}`);
        }
        var url = `${API_BASE_URL}/gochi-kid/my-page/group-special/confirm-use?${params.join("&")}`;
        return makeRequest(HTTP_API.GET, url, null, null, null, null, null, null, accessToken);
    };

    static async useGochiKid(params, crfsToken, accessToken){ 
        var url = `${API_BASE_URL}/gochi-kid/my-page/use-menu`;
        return makeRequest(HTTP_API.POST, url, params, true, {'csrf_token': crfsToken}, null, null, null, accessToken);
    };

    static async useGochiKidGroupSpecial(params, crfsToken, accessToken){ 
        var url = `${API_BASE_URL}/gochi-kid/my-page/group-special/use-menu`;
        return makeRequest(HTTP_API.POST, url, params, true, {'csrf_token': crfsToken}, null, null, null, accessToken);
    };

    static async getSponsorGochiKid(accessToken, params){
        var url = `${API_BASE_URL}/gochi-kid/my-page/company-funds`;
        return makeRequest(HTTP_API.GET, url, params, null, null, null, null, null, accessToken, null);
    }

    static async getSponsorGochiKidGroupSpecial(gochiKidGroupId, accessToken, params){
        var url = `${API_BASE_URL}/gochi-kid/my-page/company-funds/group-special/${gochiKidGroupId}`;
        return makeRequest(HTTP_API.GET, url, params, null, null, null, null, null, accessToken, null);
    }
  }

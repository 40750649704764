import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";

function CommonModal(props) {
  const location = useLocation();
  return(
    <>
      <Modal
        className="modal-custom-mogugori"
        size="sm"
        show={props.show}
        onHide={props.closePopup}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="mt-4 mt-4-custome width-custom text-left title-popup align-center">
            {props.title}
          </div>
          <div className="content-popup align-center">
            {props.content}
          </div>
        </Modal.Body>
        <div className="fs-15 clr-000000 align-center mb-3 button-box">
          <button
            type="button"
            onClick={props.closePopup}
            className="btn btn-close-popup fs-16 custome-btn-close"
          >
            {props.textButtonClose}
          </button>
        </div>
      </Modal>
    </>
  )
}

export default CommonModal;
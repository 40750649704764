import React from "react";
import moment from 'moment';
import * as constants from "../../../../constants";

export function HistoryUsedGochiCharge(props) {
  const currentGochiBillId = props.currentGochiBillId;

  return (
    <div
      id={5342}
      className={`modal modal-profile modal-profile-01 modal-01 modal-lg history-modal ${props.showPopupHistoryUsedGochiCharge ? 'is-show' : ''}`}
      tabIndex={-1}
    >
      <div className="modal-header-history">
        <button
          className="modal-btn modal-btn-close"
          id="modalClose"
          onClick={props.handleClosePopupHistoryUsedGochiCharge}
          type="button"
        >
          <svg className="icon">
            <title>閉じる</title>
            <use xlinkHref="/assets/img/common/icon.svg#times" />
          </svg>
        </button>
      </div>
      <div className="title-modal-charge">
        <div className="title">利用履歴</div>
      </div>
      {props.listHistoryUsedGochiCharge?.length > 0 ? (
      <>
        <div className={`modal-body`}>
          {props.listHistoryUsedGochiCharge?.map(item => {
            return (
              <div className="history-box" key={item.couponId}>
                <div className="p-3">
                  <div className="history-line">
                    <div className="title-lst-history">日付・日時</div>
                    <div className="content-lst-history">{moment(item?.useDate).format('YYYY年MM月DD日 H時mm分')}</div>
                  </div>
                  <div className="my-8px history-line">
                    <div className="title-lst-history extra-small-title">利用店舗</div>
                    <div className="content-lst-history extra-large-content">{item?.shopName}</div>
                  </div>
                  <div className="history-line">
                    <div className="title-lst-history">利用金額</div>
                    <div className="content-lst-history">
                      <span>{item?.displayAmount.toLocaleString()}</span>
                      <span>円</span>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div className={`history-load-more ${props.loadingHistoryUsedGochiCharge ? 'text-decoration-none' : ''}`}>
          {(props.pageHistoryUsedGochiCharge + 1) <= Math.ceil(props.totalHistoryUsedGochiCharge/props.pageLimit) && (
            <span className={`cursor-pointer fs-15 align-center ${props.loadingHistoryUsedGochiCharge ? 'loading' : ''}`} onClick={() => props.loadMoreListHistoryUsedGochiCharge(currentGochiBillId)}>もっと見る</span>
          )} 
        </div>
      </>) : (<div className="no-line-history">利用履歴がございません</div>)}
    </div>
  );
}

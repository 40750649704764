import moment from "moment";
import React, { useState } from "react";

export function ExplainInputGochiKid(props) {
  const [showNoteAmount, setShowNoteAmount] = useState(false);
  const numChoiceKids = props.choiceUserKids.length;
  const kidFirstUsed = props.choiceUserKids.filter(
    (kid) => kid.firstUsed === 1
  );
  const isPoor = props.choiceUserKids[0].flagPoor === 1;
  let minAmount = 1000;

  if (kidFirstUsed.length > 0 && isPoor) {
    minAmount = Math.min(...kidFirstUsed.map((kid) => kid.amountKidCanUse));
    if (minAmount > 1000 && !props.gochiKidUnlimitShopChoice) {
      minAmount = 1000;
    }
  }

  return (
    <div
      className={`custom-modal-container explain-input-gochi-kid-modal ${
        props.showing ? "show-modal" : ""
      }`}
      size="sm"
    >
      <div
        className={`modal modal-action modal-dialog history-modal ${
          props.showing ? "is-show" : ""
        }`}
      >
        <div className={`modal-body`}>
          <div className="how-to-calculate-box">
            <div className="how-to-calculate-title">
              同時利用可能残高（合計）
            </div>
            <div className="how-to-calculate-text">
              ※利用されるお子様の内の最低利用可能残高を基に算出しています。
            </div>
            <div className="how-to-calculate-recipe">
              {minAmount.toLocaleString()}円 ×{numChoiceKids}人
              <br />= {(minAmount * numChoiceKids).toLocaleString()}円
            </div>
            {props.listUserKids.map((kids) => {
              let divInfoUserKid = null;
              if (kids.ageLimit && !kids.canUse) {
                divInfoUserKid = (
                  <div className="age-note">
                    <span>
                      <i className="icon-age-note" />
                    </span>
                    対象のお子さまは支援元の”{kids.ageLimit}”制限(
                    {kids.ageLimit}
                    以下)により本サービスを利用できなくなりました。
                    <br />
                    これまでのご利用ありがとうございました。
                  </div>
                );
              } else if (kids.canUseDate && !kids.canUse) {
                divInfoUserKid = (
                  <div className="item-box">
                    <span>次回利用可能日</span>
                    <span className="red-text">
                      {moment(kids.canUseDate).format("YYYY年MM月DD日")}
                    </span>
                    <a
                      href="https://user-guide.gochimeshi.com/restart-date/"
                      target="_blank"
                      className="can-use-date-link"
                    >
                      <i className="icon-link-restart-date" />
                    </a>
                  </div>
                );
              }

              return (
                <div className="how-to-calculate-item" key={kids.userKidId}>
                  <div className="name">{kids.displayName}</div>
                  {!kids.ageLimit && (
                    <div className="item-box">
                      <span>利用可能残高</span>
                      <span className="red-text">
                        {kids.amountKidCanUse
                          ? kids.amountKidCanUse.toLocaleString()
                          : 0}
                        円
                      </span>
                    </div>
                  )}
                  {divInfoUserKid}
                </div>
              );
            })}
          </div>

          <div className={`${showNoteAmount ? "border-black" : "border-gray"}`}>
            <div
              className={`title-note-dropdown`}
              onClick={() => {
                setShowNoteAmount(!showNoteAmount);
              }}
            >
              <span>利用可能残高とは</span>
              {showNoteAmount ? (
                <svg className="icon">
                  <use xlinkHref="/assets/img/common/icon.svg#angle-up" />
                </svg>
              ) : (
                <svg className="icon">
                  <use xlinkHref="/assets/img/common/icon.svg#angle-down" />
                </svg>
              )}
            </div>

            {showNoteAmount && (
              <div className="text-note-dropdown">
                現時点の利用可能残高を表示しております。
                <br />
                利用可能日であっても、寄付金の状況によりご利用いただけない場合がございます。
                <br />
                あらかじめご了承ください。
              </div>
            )}
          </div>

          <div className="modal-footer">
            <button
              className="btn btn-rect btn-submit"
              onClick={() => props.handleClose()}
            >
              閉じる
            </button>
          </div>
        </div>
      </div>
      <div onClick={() => props.handleClose()} className="back-drop" />
    </div>
  );
}

import React from "react";

export default function ErrorAlert({ children, className }) {
  let wrapperClassName = 'error-alert';
  if (className) {
    wrapperClassName += ' ' + className
  }
  return (
    <div className={wrapperClassName}>
      <div className="error-alert-icon">
        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.6421 11.175C10.8689 11.175 11.0565 11.1042 11.2048 10.9625C11.3531 10.8208 11.4273 10.6417 11.4273 10.425V5.575C11.4273 5.375 11.3488 5.20417 11.1917 5.0625C11.0347 4.92083 10.8515 4.85 10.6421 4.85C10.4152 4.85 10.2276 4.92083 10.0793 5.0625C9.931 5.20417 9.85684 5.38333 9.85684 5.6V10.45C9.85684 10.65 9.93536 10.8208 10.0924 10.9625C10.2495 11.1042 10.4327 11.175 10.6421 11.175ZM10.5635 15C10.8078 15 11.0129 14.9208 11.1786 14.7625C11.3444 14.6042 11.4273 14.4083 11.4273 14.175C11.4273 13.9417 11.3444 13.7458 11.1786 13.5875C11.0129 13.4292 10.8078 13.35 10.5635 13.35C10.3193 13.35 10.1142 13.4292 9.94845 13.5875C9.78268 13.7458 9.69979 13.9417 9.69979 14.175C9.69979 14.4083 9.78268 14.6042 9.94845 14.7625C10.1142 14.9208 10.3193 15 10.5635 15ZM10.5635 20C9.08033 20 7.7018 19.7458 6.42798 19.2375C5.15415 18.7292 4.0461 18.025 3.10382 17.125C2.16154 16.225 1.42429 15.1667 0.892072 13.95C0.359857 12.7333 0.09375 11.4167 0.09375 10C0.09375 8.6 0.359857 7.29167 0.892072 6.075C1.42429 4.85833 2.16154 3.8 3.10382 2.9C4.0461 2 5.15415 1.29167 6.42798 0.775C7.7018 0.258333 9.08033 0 10.5635 0C12.0293 0 13.3991 0.258333 14.6729 0.775C15.9468 1.29167 17.0548 2 17.9971 2.9C18.9394 3.8 19.681 4.85833 20.2219 6.075C20.7629 7.29167 21.0333 8.6 21.0333 10C21.0333 11.4167 20.7629 12.7333 20.2219 13.95C19.681 15.1667 18.9394 16.225 17.9971 17.125C17.0548 18.025 15.9468 18.7292 14.6729 19.2375C13.3991 19.7458 12.0293 20 10.5635 20Z" fill="#FF91B5"/>
        </svg>
      </div>
      <div>
        {children}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { setModalSituationAction } from "../../../../actions/action_situations";
import { setModalUserTargetAction } from "../../../../actions/action_user_targets";
import {
  useGenres,
  usePriceRanges,
  useScript,
} from "../../../../utils";
import { GiftToModal } from "../../common/GiftToModal";
import { ModalGroup } from "../../common/ModalGroup";
import { SituationModal } from "../../common/SituationModal";
import { Footer } from "../../layout/Footer";
import Header from "../../layout/Header";
import queryString from "query-string";
import { Explanation } from "../../layout/Explanation";

function HowTo(props) {
  const smoothScroll = useScript(
    "https://cdn.jsdelivr.net/gh/cferdinandi/smooth-scroll/dist/smooth-scroll.polyfills.min.js",
    null,
    { integrity: "sha384-1Jq1gXc0sLyvpP1iLt5QoryVCyJkBLyfNkaeECRYRX4u1Nx7gT547w5/5YZ1b5DG",
      crossorigin: "anonymous"
    }
  );
  useScript("/assets/js/app.js");

  useEffect(() => {
    if (smoothScroll !== null) {
      new window.SmoothScroll('a[href*="#"]', {
        offset: 25,
        header: ".global-header",
      });
    }
  }, [smoothScroll]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.className = "howto";
  }, []);

  const history = useHistory();
  const dispatch = useDispatch();

  const situation = useSelector((state) => state.situation.situation);
  const setModalSituation = (payload) =>
    dispatch(setModalSituationAction(payload));

  const userTarget = useSelector((state) => state.userTarget.userTarget);
  const setModalUserTarget = (payload) =>
    dispatch(setModalUserTargetAction(payload));

  const [prefecture, setPrefecture] = useState("");
  const [city, setCity] = useState("");
  const listPrefectures = useSelector((state) => state.prefectures);
  const listCities = useSelector((state) => state.cities);
  const prefectureId = getPrefectureId(prefecture);
  const [genre, setGenre] = useState("");
  const genres = useGenres();
  const priceRanges = usePriceRanges();
  const [priceRange, setPriceRange] = useState("");
  const [keyword, setKeyword] = useState("");

  const filteredCities = listCities.filter(
    (city) => Number(city.prefCode) === prefectureId
  );

  function getPrefectureId(name) {
    if (name) {
      const prefectureObj = listPrefectures.find(
        (item) => item.prefectureName === name
      );
      if (prefectureObj) return prefectureObj.prefectureId;
    }
    return "";
  }

  function getPriceRangeStrFromObj(range) {
    return `${range.name && range.name.toLocaleString()}`;
  }

  function handleSearch() {
    const params = [];
    if (prefecture) params.push(`prefecture=${prefecture}`);
    if (city) params.push(`city=${city}`);
    if (situation) params.push(`situation=${situation}`);
    if (genre) params.push(`genre=${genre}`);
    if (priceRange) params.push(`prices=${priceRange}`);
    if (userTarget) params.push(`who=${userTarget}`);
    if (keyword) params.push(queryString.stringify({ keyword }));

    const query = params.join("&");
    history.push({
      pathname: "/products",
      search: `?${query}`,
    });
  }

  return (
    <>
      <MetaTags>
        <title>ごちめしの使い方｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content="ごちめしの使い方ページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content="ごちめしの使い方｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta name="twitter:description" content="ごちめしの使い方ページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:image" content={window.location.origin + "/assets/img/common/ogp.png"}  />
        <meta name="twitter:site" content="@gochi_meshi" />
        <meta name="twitter:creator" content="@gochi_meshi" />
        <meta name="twitter:image:width" content="300" />
        <meta name="twitter:image:height" content="300" />
        <meta property="og:site_name" content="ごちめしの使い方｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:title" content="ごちめしの使い方｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={window.location.origin + "/assets/img/common/ogp.png"} />
        <meta property="og:description" content="ごちめしの使い方ページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
      </MetaTags>
      <Header />

      <main>
        <div className="breadcrumb">
          <div className="container">
            <ol className="breadcrumb-list">
              <li>
                <a href="/">ごちめし</a>
              </li>
              <li>
                <span>使い方</span>
              </li>
            </ol>
          </div>
        </div>
        <div className="container">
          <section className="section">
            <div className="section-header">
              <div className="heading-xl">
                <h1 className="title">ごちめしの使い方</h1>
              </div>
            </div>
            <div className="section-body">
              {/*ごちめしstep*/}
              <section className="section gochimeshi-step-section">
                <div className="section-header">
                  <div className="heading-sm">
                    <div className="subtitle">
                      ごちめしの使い方はとっても簡単！
                      <br />
                      ３ステップでどこでも今すぐギフトが贈れます
                    </div>
                  </div>
                </div>
                <div className="section-body">
                  <ul className="gochimeshi-step-list">
                    <li className="gochimeshi-step-item">
                      <img
                        src="/assets/img/howto/gochimeshi-step-01-sp.png"
                        className="sp under-tab"
                        alt="STEP1 贈り主が商品(贈りたいお店)を探す"
                      />
                      <img
                        src="/assets/img/howto/gochimeshi-step-01.png"
                        className="pc over-pc"
                        alt="STEP1 贈り主が商品(贈りたいお店)を探す"
                      />
                    </li>
                    <li className="gochimeshi-step-item">
                      <img
                        src="/assets/img/howto/gochimeshi-step-02-sp.png"
                        className="sp under-tab"
                        alt="STEP2 贈り主が購入する"
                      />
                      <img
                        src="/assets/img/howto/gochimeshi-step-02.png"
                        className="pc over-pc"
                        alt="STEP2 贈り主が購入する"
                      />
                    </li>
                    <li className="gochimeshi-step-item">
                      <img
                        src="/assets/img/howto/gochimeshi-step-03-sp.png"
                        className="sp under-tab"
                        alt="STEP3 贈られた人が店舗で受け取る"
                      />
                      <img
                        src="/assets/img/howto/gochimeshi-step-03.png"
                        className="pc over-pc"
                        alt="STEP3 贈られた人が店舗で受け取る"
                      />
                    </li>
                  </ul>
                  <div className="btn-group gochimeshi-btn-group">
                    <a href="#send" data-scroll className="btn btn-rect">
                      <p>
                        <span className="text-sm">もっと詳しく！</span>
                        <br />
                        プレゼント・ギフトを
                        <span className="text-yellow">贈りたい人</span>
                      </p>
                    </a>
                    <a
                      href="#receive"
                      data-scroll
                      className="btn btn-rect btn-brown"
                    >
                      <p>
                        <span className="text-sm">もっと詳しく！</span>
                        <br />
                        プレゼント・ギフトを
                        <span className="text-yellow">受け取った人</span>
                      </p>
                    </a>
                  </div>
                </div>
              </section>
              {/*プレゼント・ギフトを贈りたい人*/}
              <section className="section" id="send">
                <div className="section-header">
                  <div className="heading-lg">
                    <h2 className="title">
                      プレゼント・ギフトを
                      <span className="text-main">贈りたい人</span>
                    </h2>
                    <div className="subtitle">
                      ごちめしでギフトを贈るのは簡単
                      <span className="num">５</span>ステップ！
                    </div>
                  </div>
                </div>
                <div className="section-body">
                  <ul className="sender-step-list">
                    <li className="sender-step-item sender-step-01">
                      <img
                        src="/assets/img/howto/sender-step-01-sp.png"
                        className="sp under-tab"
                        alt="STEP1 商品を探す"
                      />
                      <img
                        src="/assets/img/howto/sender-step-01.png"
                        className="pc over-pc"
                        alt="STEP1 商品を探す"
                      />
                    </li>
                    <li className="step-item sender-step-02">
                      <img
                        src="/assets/img/howto/sender-step-02-sp.png"
                        className="sp under-tab"
                        alt="STEP2 購入するをクリック"
                      />
                      <img
                        src="/assets/img/howto/sender-step-02.png"
                        className="pc over-pc"
                        alt="STEP2 購入するをクリック"
                      />
                    </li>
                    <li className="step-item sender-step-03">
                      <img
                        src="/assets/img/howto/sender-step-03-sp.png"
                        className="sp under-tab"
                        alt="STEP3 贈る相手を選ぶ"
                      />
                      <img
                        src="/assets/img/howto/sender-step-03.png"
                        className="pc over-pc"
                        alt="STEP3 贈る相手を選ぶ"
                      />
                    </li>
                    <li className="step-item sender-step-04">
                      <img
                        src="/assets/img/howto/sender-step-04-sp.png"
                        className="sp under-tab"
                        alt="STEP4 お支払い"
                      />
                      <img
                        src="/assets/img/howto/sender-step-04.png"
                        className="pc over-pc"
                        alt="STEP4 お支払い"
                      />
                    </li>
                    <li className="step-item sender-step-05">
                      <img
                        src="/assets/img/howto/sender-step-05-sp.png"
                        className="sp under-tab"
                        alt=""
                      />
                      <img
                        src="/assets/img/howto/sender-step-05.png"
                        className="pc over-pc"
                        alt=""
                      />
                    </li>
                  </ul>
                </div>
              </section>
              {/*ギフトを贈りたい人にオススメの機能*/}
              <section className="section">
                <div className="section-header">
                  <div className="heading-lg">
                    <h2 className="title">
                      ギフトを<span className="text-main">贈りたい人</span>に
                      <br className="under-tab" />
                      オススメの機能
                    </h2>
                    <div className="subtitle">
                       「ごちめし」の充実した機能で、あなたのギフト探しをもっと楽しく！
                    </div>
                  </div>
                </div>
                <div className="section-body">
                  <div className="card-howto-group">
                    <div className="card card-howto">
                      <div className="card-howto-header">
                        <div className="heading-md text-main">
                          マイページ機能
                        </div>
                      </div>
                      <div className="card-howto-body">
                        <div className="card-howto-body-thumbnail">
                          <img
                            src="/assets/img/howto/icon-tag.svg"
                            className
                            alt=""
                          />
                        </div>
                        <div className="text">
                          自分専用ページを作成することができます（会員登録で利用可能）
                        </div>
                      </div>
                    </div>
                    <div className="card card-howto">
                      <div className="card-howto-header">
                        <div className="heading-md text-main">
                          豊富な
                          <br className="under-tab" />
                          デジタルギフト
                        </div>
                      </div>
                      <div className="card-howto-body">
                        <div className="card-howto-body-thumbnail">
                          <img
                            src="/assets/img/howto/icon-phone.svg"
                            className
                            alt=""
                          />
                        </div>
                        <div className="text">
                          飲食店のメニューをデジタルギフトとして贈れます
                        </div>
                      </div>
                    </div>
                    <div className="card card-howto">
                      <div className="card-howto-header">
                        <div className="heading-md text-main">
                          カードやメッセージも贈れる
                        </div>
                      </div>
                      <div className="card-howto-body">
                        <div className="card-howto-body-thumbnail">
                          <img
                            src="/assets/img/howto/icon-letter.svg"
                            className
                            alt=""
                          />
                        </div>
                        <div className="text">
                          チケットを贈るとき、ギフトカードやメッセージを添えて送ることができます
                        </div>
                      </div>
                    </div>
                    <div className="card card-howto">
                      <div className="card-howto-header">
                        <div className="heading-md text-main">
                          お気に入り機能
                        </div>
                      </div>
                      <div className="card-howto-body">
                        <div className="card-howto-body-thumbnail">
                          <img
                            src="/assets/img/howto/icon-heart.svg"
                            className
                            alt=""
                          />
                        </div>
                        <div className="text">
                          お気に入り一覧を作成することができます（会員登録で利用可能）
                        </div>
                      </div>
                    </div>
                    <div className="card card-howto">
                      <div className="card-howto-header">
                        <div className="heading-md text-main">フォロー機能</div>
                      </div>
                      <div className="card-howto-body">
                        <div className="card-howto-body-thumbnail">
                          <img
                            src="/assets/img/howto/icon-girl.svg"
                            className
                            alt=""
                          />
                        </div>
                        <div className="text">
                          ごちめしに登録している知人をフォローすることが可能なため、より手軽にギフト作成することができます（会員登録で利用可能）
                        </div>
                      </div>
                    </div>
                    <div className="card card-howto">
                      <div className="card-howto-header">
                        <div className="heading-md text-main">
                          クチコミを<br className="under-tab" />参考にできる
                        </div>
                      </div>
                      <div className="card-howto-body">
                        <div className="card-howto-body-thumbnail">
                          <img
                            src="/assets/img/howto/icon-people.svg"
                            className
                            alt=""
                          />
                        </div>
                        <div className="text">
                          商品や店舗に寄せられたお客様の声を見ることができるので、購入前に参考にすることができます
                        </div>
                      </div>
                    </div>
                    <div className="card card-howto">
                      <div className="card-howto-header">
                        <div className="heading-md text-main">
                          店舗・商品
                          <br className="under-tab" />
                          検索機能
                        </div>
                      </div>
                      <div className="card-howto-body">
                        <div className="card-howto-body-thumbnail">
                          <img
                            src="/assets/img/howto/icon-search.svg"
                            className
                            alt=""
                          />
                        </div>
                        <div className="text">
                          エリア・シチュエーション・贈り相手・ジャンル・予算を組み合わせて検索できます
                        </div>
                      </div>
                    </div>
                    <div className="card card-howto">
                      <div className="card-howto-header">
                        <div className="heading-md text-main">
                          決済方法が
                          <br className="under-tab" />
                          選べる
                        </div>
                      </div>
                      <div className="card-howto-body">
                        <div className="card-howto-body-thumbnail">
                          <img
                            src="/assets/img/howto/icon-wallet.svg"
                            className
                            alt=""
                          />
                        </div>
                        <div className="text">
                          クレジットカードの他にもPaypayも利用できます
                        </div>
                      </div>
                    </div>
                    <div className="card card-howto">
                      <div className="card-howto-header">
                        <div className="heading-md text-main">
                          領収書発行機能
                        </div>
                      </div>
                      <div className="card-howto-body">
                        <div className="card-howto-body-thumbnail">
                          <img
                            src="/assets/img/howto/icon-memo.svg"
                            className
                            alt=""
                          />
                        </div>
                        <div className="text">
                          過去の購入履歴から領収書を発行できます（会員登録で利用可能）
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section-footer">
                  <div className="btn-group gochimeshi-signup-btn-group">
                    <Link
                      to="/signup"
                      className="btn btn-rect btn-black btn-xl"
                    >
                      会員登録してギフトを贈る
                    </Link>
                  </div>
                </div>
              </section>
              {/*プレゼント・ギフトを受け取った人*/}
              <section className="section" id="receive">
                <div className="section-header">
                  <div className="heading-lg">
                    <h2 className="title">
                      プレゼント・ギフトを
                      <br className="under-tab" />
                      <span className="text-brown">受け取った人</span>
                    </h2>
                    <div className="subtitle">
                      ごちめしの使い方は簡単<span className="num">3</span>
                      ステップ！
                    </div>
                  </div>
                </div>
                <div className="section-body">
                  <ul className="receiver-step-list">
                    <li className="step-item receiver-step-01">
                      <img
                        src="/assets/img/howto/receiver-step-01-sp.png"
                        className="sp under-tab"
                        alt="STEP1 ギフトURLが届く"
                      />
                      <img
                        src="/assets/img/howto/receiver-step-01.png"
                        className="pc over-pc"
                        alt="STEP1 ギフトURLが届く"
                      />
                    </li>
                    <li className="step-item receiver-step-02">
                      <img
                        src="/assets/img/howto/receiver-step-02-sp.png"
                        className="sp under-tab"
                        alt="STEP2 店舗でギフト受け取り"
                      />
                      <img
                        src="/assets/img/howto/receiver-step-02.png"
                        className="pc over-pc"
                        alt="STEP2 店舗でギフト受け取り"
                      />
                    </li>
                    <li className="step-item receiver-step-03">
                      <img
                        src="/assets/img/howto/receiver-step-03-sp.png"
                        className="sp under-tab"
                        alt="STEP3 スマホ画面で会計"
                      />
                      <img
                        src="/assets/img/howto/receiver-step-03.png"
                        className="pc over-pc"
                        alt="STEP3 スマホ画面で会計"
                      />
                    </li>
                  </ul>
                </div>
              </section>
              {/*ギフトを受け取る人にオススメの機能*/}
              <section className="section">
                <div className="section-header">
                  <div className="heading-lg">
                    <h2 className="title">
                      ギフトを<span className="text-brown">受け取る人</span>に
                      <br className="under-tab" />
                      オススメの機能
                    </h2>
                    <div className="subtitle">
                      ごちめしの充実した機能はあなたのギフト探しをもっと楽しくしてくれます
                    </div>
                  </div>
                </div>
                <div className="section-body">
                  <div className="card-howto-group">
                    <div className="card card-howto">
                      <div className="card-howto-header">
                        <div className="heading-md text-brown">
                          マイページ機能
                        </div>
                      </div>
                      <div className="card-howto-body">
                        <div className="card-howto-body-thumbnail bg-brown">
                          <img
                            src="/assets/img/howto/icon-tag.svg"
                            className
                            alt=""
                          />
                        </div>
                        <div className="text">
                          お気に入り一覧を作成できます（会員登録で利用可能）
                        </div>
                      </div>
                    </div>
                    <div className="card card-howto">
                      <div className="card-howto-header">
                        <div className="heading-md text-brown">
                          お礼メッセージ
                          <br className="under-tab" />
                          送信
                        </div>
                      </div>
                      <div className="card-howto-body">
                        <div className="card-howto-body-thumbnail bg-brown">
                          <img
                            src="/assets/img/howto/icon-letter-close.svg"
                            className
                            alt=""
                          />
                        </div>
                        <div className="text">
                          ごちチケットを贈られたらすぐに、お礼のメッセージを送ることができます
                        </div>
                      </div>
                    </div>
                    <div className="card card-howto">
                      <div className="card-howto-header">
                        <div className="heading-md text-brown">
                          欲しい物
                          <br className="under-tab" />
                          リスト公開
                        </div>
                      </div>
                      <div className="card-howto-body">
                        <div className="card-howto-body-thumbnail bg-brown">
                          <img
                            src="/assets/img/howto/icon-star.svg"
                            className
                            alt=""
                          />
                        </div>
                        <div className="text">
                          欲しい物リストを作成し公開できます（会員登録で利用可能）
                        </div>
                      </div>
                    </div>
                    <div className="card card-howto">
                      <div className="card-howto-header">
                        <div className="heading-md text-brown">
                          ギフト一覧機能
                        </div>
                      </div>
                      <div className="card-howto-body">
                        <div className="card-howto-body-thumbnail bg-brown">
                          <img
                            src="/assets/img/howto/icon-list.svg"
                            className
                            alt=""
                          />
                        </div>
                        <div className="text">
                          自分に届いたギフトの履歴を確認できます（会員登録で利用可能）
                        </div>
                      </div>
                    </div>
                    <div className="card card-howto">
                      <div className="card-howto-header">
                        <div className="heading-md text-brown">
                          豊富な
                          <br className="under-tab" />
                          デジタルギフト
                        </div>
                      </div>
                      <div className="card-howto-body">
                        <div className="card-howto-body-thumbnail bg-brown">
                          <img
                            src="/assets/img/howto/icon-phone.svg"
                            className
                            alt=""
                          />
                        </div>
                        <div className="text">
                          70,000を超えるデジタルギフトから商品を受け取ることが可能です。
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section-footer">
                  <div className="btn-group gochimeshi-signup-btn-group">
                    <Link
                      to="/signup"
                      className="btn btn-rect btn-black btn-xl"
                    >
                      会員登録してギフトを受け取る
                    </Link>
                  </div>
                </div>
              </section>
              {/*画像*/}
              <section className="section">
                <div className="section-body">
                  <div className="thumbnali gochimeshi-howto-thumbnail">
                    <img
                      className="img pc over-pc"
                      loading="lazy"
                      src="./assets/img/howto/gochimeshi-howto.png"
                      alt="デジタルギフトの良いところ"
                    />
                    <img
                      className="img sp under-tab"
                      loading="lazy"
                      src="./assets/img/howto/gochimeshi-howto-sp.png"
                      alt="デジタルギフトの良いところ"
                    />
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div>
        <aside>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSearch();
            }}
            method="POST"
            className="search-area"
          >
            <div className="container">
              <div className="search-header text-center">
                <div className="heading-xl heading-slash search-header-heading">
                  <h1 className="title search-header-heading-title">
                    <span className="search-header-heading-title-first">
                      <em className="text-xxl">70,000</em>点以上の商品から
                    </span>
                    <span className="search-header-heading-title-second">
                      プレゼント・ギフトを探してみる
                    </span>
                  </h1>
                </div>
              </div>
              <div className="search-body">
                <div className="fieldset fieldset-column fieldset-lg">
                  <div className="fieldset-header">
                    <svg className="icon">
                      <use xlinkHref="/assets/img/common/icon.svg#map-marker" />
                    </svg>
                    <div className="title">エリア</div>
                  </div>
                  <div className="fieldset-body">
                    <div className="field">
                      <div className="field field-pill field-sm">
                        <select
                          value={prefecture}
                          onChange={(e) => {
                            setPrefecture(e.target.value);
                            setCity("");
                          }}
                        >
                          <option value="">選択なし</option>
                          {listPrefectures.length > 0 &&
                            listPrefectures.map((item, key) => {
                              return (
                                <option key={key} value={item.prefectureName}>
                                  {item.prefectureName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="field field-pill field-sm">
                        <select
                          className="select-box-custom"
                          value={city}
                          onChange={(e) => {
                            setCity(e.target.value);
                          }}
                        >
                          <option value="">選択なし</option>
                          {filteredCities.length > 0 &&
                            filteredCities.map((item, key) => {
                              return (
                                <option key={key} value={item.cityName}>
                                  {item.cityName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="fieldset fieldset-lg">
                  <div className="fieldset-header">
                    <svg className="icon">
                      <use xlinkHref="/assets/img/common/icon.svg#popper" />
                    </svg>
                    <div className="title">シチュエーション</div>
                  </div>
                  <div className="fieldset-body">
                    <div className="field">
                      <button
                        className="btn btn-black btn-elipsis"
                        id="openSearchSituation"
                        data-target="modal01"
                        type="button"
                        onClick={() => {
                          setModalSituation(situation);
                        }}
                      >
                        <div id="searchselectedSituation">
                          {situation || "選択"}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="fieldset fieldset-lg">
                  <div className="fieldset-header">
                    <svg className="icon">
                      <use xlinkHref="/assets/img/common/icon.svg#user" />
                    </svg>
                    <div className="title">贈り相手</div>
                  </div>
                  <div className="fieldset-body">
                    <div className="field">
                      <button
                        className="btn btn-black btn-elipsis"
                        id="openSearchGiftTo"
                        data-target="modal01"
                        type="button"
                        onClick={() => {
                          setModalUserTarget(userTarget);
                        }}
                      >
                        <div id="searchselectedGiftTo">
                          {userTarget || "選択"}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="fieldset fieldset-lg">
                  <div className="fieldset-header">
                    <svg className="icon">
                      <use xlinkHref="/assets/img/common/icon.svg#utensils" />
                    </svg>
                    <div className="title">ジャンル</div>
                  </div>
                  <div className="fieldset-body">
                    <div className="field field-pill field-md">
                      <select
                        className="select-box-custom"
                        onChange={(e) => setGenre(e.target.value)}
                        value={genre}
                      >
                        <option value="">選択なし</option>
                        {genres.map((item) => (
                          <option key={item.genreId} value={item.menuGenreName}>
                            {item.menuGenreName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="fieldset fieldset-lg">
                  <div className="fieldset-header">
                    <svg className="icon">
                      <use xlinkHref="/assets/img/common/icon.svg#yen-sign" />
                    </svg>
                    <div className="title">予算</div>
                  </div>
                  <div className="fieldset-body">
                    <div className="field field-pill field-md">
                      <select
                        onChange={(e) => setPriceRange(e.target.value)}
                        value={priceRange}
                      >
                        <option value="">選択してください</option>
                        {priceRanges.map((item, key) => (
                          <option key={key} value={item.name}>
                            {getPriceRangeStrFromObj(item)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="fieldset fieldset-lg">
                  <div className="fieldset-header">
                    <svg className="icon">
                      <use xlinkHref="/assets/img/common/icon.svg#search" />
                    </svg>
                    <div className="title">キーワード</div>
                  </div>
                  <div className="fieldset-body">
                    <div className="field field-pill field-lg">
                      <input
                        value={keyword}
                        type="text"
                        placeholder="キーワード"
                        onChange={(e) => setKeyword(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="fieldset fieldset-submit under-tab">
                  <button type="button" onClick={handleSearch} className="btn btn-submit search-btn">
                    検索する
                  </button>
                </div>
                {/* /.fieldset */}
              </div>
              {/* /.search-body */}
              <div className="search-footer over-pc">
                <button className="btn" type="button" onClick={handleSearch}>
                  検索する
                </button>
              </div>
              {/* /.search-footer */}
            </div>
            {/* /.container */}
          </form>
        </aside>

        <Explanation />
      </main>

      <Footer />

      <ModalGroup>
        <SituationModal />
        <GiftToModal />
      </ModalGroup>
    </>
  );
}

export default HowTo;

import React from "react";

export function TopPageModal(props) {
  return (
    <div className="modal" tabIndex={-1}>
      <button className="modal-btn" id="modalClose" type="button">
        <svg className="icon">
          <title>閉じる</title>
          <use xlinkHref="/assets/img/common/icon.svg#times" />
        </svg>
      </button>
      <div className="modal-header heading-lg">
        <div className="title">シチュエーション</div>
      </div>
      <div className="modal-body">
        <div className="field field-radio field-pill">
          <label>
            <input
              className="before-icon"
              name="aaa"
              type="radio"
              defaultChecked
            />
            <div className="btn btn-sm btn-outline btn-black">誕生日</div>
          </label>
          <label>
            <input className="before-icon" name="aaa" type="radio" />
            <div className="btn btn-sm btn-outline btn-black">白寿</div>
          </label>
          <label>
            <input className="before-icon" name="aaa" type="radio" />
            <div className="btn btn-sm btn-outline btn-black">傘寿</div>
          </label>
          <label>
            <input className="before-icon" name="aaa" type="radio" />
            <div className="btn btn-sm btn-outline btn-black">米寿</div>
          </label>
          <label>
            <input className="before-icon" name="aaa" type="radio" />
            <div className="btn btn-sm btn-outline btn-black">古希</div>
          </label>
          <label>
            <input className="before-icon" name="aaa" type="radio" />
            <div className="btn btn-sm btn-outline btn-black">還暦祝い</div>
          </label>
          <label>
            <input className="before-icon" name="aaa" type="radio" />
            <div className="btn btn-sm btn-outline btn-black">父の日</div>
          </label>
          <label>
            <input className="before-icon" name="aaa" type="radio" />
            <div className="btn btn-sm btn-outline btn-black">母の日</div>
          </label>
          <label>
            <input className="before-icon" name="aaa" type="radio" />
            <div className="btn btn-sm btn-outline btn-black">敬老の日</div>
          </label>
        </div>
        <hr />
        <div className="field field-radio field-pill">
          <label>
            <input
              className="before-icon"
              name="aaa"
              type="radio"
              defaultChecked
            />
            <div className="btn btn-sm btn-outline btn-black">結婚祝い</div>
          </label>
          <label>
            <input className="before-icon" name="aaa" type="radio" />
            <div className="btn btn-sm btn-outline btn-black">結婚記念日</div>
          </label>
          <label>
            <input className="before-icon" name="aaa" type="radio" />
            <div className="btn btn-sm btn-outline btn-black">結婚内祝い</div>
          </label>
          <label>
            <input className="before-icon" name="aaa" type="radio" />
            <div className="btn btn-sm btn-outline btn-black">引き出物</div>
          </label>
          <label>
            <input className="before-icon" name="aaa" type="radio" />
            <div className="btn btn-sm btn-outline btn-black">金婚式</div>
          </label>
          <label>
            <input className="before-icon" name="aaa" type="radio" />
            <div className="btn btn-sm btn-outline btn-black">銀婚式</div>
          </label>
        </div>
        <hr />
        <div className="field field-radio field-pill">
          <label>
            <input
              className="before-icon"
              name="aaa"
              type="radio"
              defaultChecked
            />
            <div className="btn btn-sm btn-outline btn-black">出産祝い</div>
          </label>
          <label>
            <input className="before-icon" name="aaa" type="radio" />
            <div className="btn btn-sm btn-outline btn-black">出産内祝い</div>
          </label>
          <label>
            <input className="before-icon" name="aaa" type="radio" />
            <div className="btn btn-sm btn-outline btn-black">新築祝い</div>
          </label>
        </div>
        <hr />
        <div className="field field-radio field-pill">
          <label>
            <input
              className="before-icon"
              name="aaa"
              type="radio"
              defaultChecked
            />
            <div className="btn btn-sm btn-outline btn-black">お見舞い</div>
          </label>
          <label>
            <input className="before-icon" name="aaa" type="radio" />
            <div className="btn btn-sm btn-outline btn-black">快気祝い</div>
          </label>
        </div>
        <hr />
        <div className="field field-radio field-pill">
          <label>
            <input
              className="before-icon"
              name="aaa"
              type="radio"
              defaultChecked
            />
            <div className="btn btn-sm btn-outline btn-black">お中元</div>
          </label>
          <label>
            <input className="before-icon" name="aaa" type="radio" />
            <div className="btn btn-sm btn-outline btn-black">お歳暮</div>
          </label>
          <label>
            <input className="before-icon" name="aaa" type="radio" />
            <div className="btn btn-sm btn-outline btn-black">手土産</div>
          </label>
          <label>
            <input className="before-icon" name="aaa" type="radio" />
            <div className="btn btn-sm btn-outline btn-black">餞別</div>
          </label>
        </div>
        <hr />
        <div className="field field-radio field-pill">
          <label>
            <input
              className="before-icon"
              name="aaa"
              type="radio"
              defaultChecked
            />
            <div className="btn btn-sm btn-outline btn-black">成人祝い</div>
          </label>
          <label>
            <input className="before-icon" name="aaa" type="radio" />
            <div className="btn btn-sm btn-outline btn-black">入学祝い</div>
          </label>
          <label>
            <input className="before-icon" name="aaa" type="radio" />
            <div className="btn btn-sm btn-outline btn-black">合格祝い</div>
          </label>
          <label>
            <input className="before-icon" name="aaa" type="radio" />
            <div className="btn btn-sm btn-outline btn-black">卒業祝い</div>
          </label>
          <label>
            <input className="before-icon" name="aaa" type="radio" />
            <div className="btn btn-sm btn-outline btn-black">昇進祝い</div>
          </label>
        </div>
        <hr />
        <div className="field field-radio field-pill">
          <label>
            <input
              className="before-icon"
              name="aaa"
              type="radio"
              defaultChecked
            />
            <div className="btn btn-sm btn-outline btn-black">クリスマス</div>
          </label>
          <label>
            <input className="before-icon" name="aaa" type="radio" />
            <div className="btn btn-sm btn-outline btn-black">バレンタイン</div>
          </label>
          <label>
            <input className="before-icon" name="aaa" type="radio" />
            <div className="btn btn-sm btn-outline btn-black">ホワイトデー</div>
          </label>
          <label>
            <input className="before-icon" name="aaa" type="radio" />
            <div className="btn btn-sm btn-outline btn-black">パーティー</div>
          </label>
        </div>
      </div>
      <div className="modal-footer">
        <div className="btn-group">
          <button className="btn">決定する</button>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import CheckboxInput from "./CheckboxInput";
import ModalCloseButton from "./ModalCloseButton";

function PolicyCheckModal({
  show,
  handleClose,
  userInfo
}) {
  const history = useHistory();
  const location = useLocation();
  const { skipPolicyCheck } = queryString.parse(location.search);

  const [policyChecked, setPolicyChecked] = useState(false);

  const completePolicyCheck = () => {
    handleClose();
  };

  const redirectToLogin = () => {
    sessionStorage.setItem('loginCallbackUrl', window.location.pathname + '?skipPolicyCheck=true');
    window.location.href = '/login';
  };

  return (
    <Modal
      size="sm"
      className="no-header-modal"
      scrollable={false}
      animation={false}
      centered
      show={show && skipPolicyCheck !== 'true'}
      onHide={() => {}}
    >
      <Modal.Body className="px-3 pb-5">

        <div className="fs-18px fw-800 mt-3">
          会員登録&ログインされますと、支援先からの感謝メッセージをお届けいたします!
        </div>

        <div className="terms-policy-link mt-2">
          <a href="https://gochimeshi.com/policy/terms/" target="_blank" rel="noopener noreferrer">
            GOCHIプラットフォーム利用規約
          </a>
          <span> ・</span>
          <a href="https://gochimeshi.com/policy/privacy/" target="_blank" rel="noopener noreferrer">
            プライバシーポリシー
          </a>
        </div>

        <div className="d-flex justify-content-center mt-4 pb-2">
          <CheckboxInput
            checked={policyChecked}
            onChange={() => setPolicyChecked((prev) => !prev)}
          >
            上記規約に同意する
          </CheckboxInput>
        </div>

        <div className="mt-4">
          {userInfo.userId
            ? (
              <>
                <button
                  className="btn-primary btn-size-md w-100"
                  disabled={!policyChecked}
                  onClick={completePolicyCheck}
                >
                  支援先選択に進む
                </button>
              </>
            )
            : (
              <>
                <button
                  className="btn-primary btn-size-md w-100"
                  disabled={!policyChecked}
                  onClick={redirectToLogin}
                >
                  会員登録&ログインして支援する
                </button>
                <button
                  className="btn-outline btn-size-md w-100 mt-3"
                  disabled={!policyChecked}
                  onClick={completePolicyCheck}
                >
                  ログインせずに支援する
                </button>
              </>
            )
          }
        </div>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = state => ({
  userInfo: state.userInfo
});

export default connect(mapStateToProps)(PolicyCheckModal);

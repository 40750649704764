import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { createBrowserHistory as useHistory } from "history";
import { withTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { log } from "../../../utils";
import GochiService from "../../../api/services/GochiService";
import { showError } from "../../../actions/action_error_alert";
import Header from "../../layout/header/Header";
import queryString from "query-string";
import { showLoading } from "../../../actions/action_loading";
import * as constants from "../../../constants";
import MenuService from "../../../api/services/MenuService";
import { isMobile } from "react-device-detect";
import { savePaymentMethod } from "../../../actions/action_payment";

class GochiUseConfirm extends Component {
  constructor() {
    super();
    this.state = {
      showGochiUseConfirm: false,
      submitConfirm: false,
      confirmError: "",
      redirect: false,
      redirectUrl: "",
      couponId: null,
      gochiTipUrl: null,
      crfsToken: "",
      gochiInfo: {},
      quantity: sessionStorage.getItem("quantityWillBeUsed")
        ? parseInt(sessionStorage.getItem("quantityWillBeUsed"))
        : 1,
      shopId: null,
      gochiUserId: null,
      menuId: null,
      paymentType: sessionStorage.getItem("selectPayment")
        ? parseInt(sessionStorage.getItem("selectPayment"))
        : constants.PAYMENT_TYPE_CREDIT_CARD,
      locationKeys: [],
      accessToken: queryString.parse(window.location.search).accessToken,
    };
    this.onCloseGochiConfirm = this.onCloseGochiConfirm.bind(this);
    this.onSubmitConfirm = this.onSubmitConfirm.bind(this);
    this.generateRandomString = this.generateRandomString.bind(this);
    this.getGochi = this.getGochi.bind(this);
  }

  generateRandomString(length = 32) {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    this.setState({
      crfsToken: text,
    });
    console.log("crfs token", text);
    return text;
  }

  getGochi = async (gochiBillId) => {
    this.props.showLoading(true);
    try {
      const { data } = await GochiService.getGochi({ gochiBillId });
      this.props.showLoading(false);
      return data;
    } catch (error) {
      this.props.showLoading(false);
      this.props.showError(
        true,
        this.props.t("pages.login.There is something wrong"),
        ""
      );
    }
  };

  async componentDidMount() {
    this.generateRandomString();

    const url = window.location.search;
    const userInfo = this.props.userInfo;
    let params = queryString.parse(url);
    const gochiBillId = params.gochiBillId;
    const shopId = params.shopId;
    const gochiUserId = params.gochiUserId;
    const menuId = params.menuId;

    if (
      !this.props.paymentMethod ||
      Object.entries(this.props.paymentMethod).length === 0
    ) {
      var payment = JSON.parse(sessionStorage.getItem("paymentMethod"));
      this.props.savePaymentMethod(payment);
    }

    if (shopId) {
      this.setState({
        shopId: shopId,
      });
    }

    if (gochiUserId) {
      this.setState({
        gochiUserId: gochiUserId,
      });
    }

    if (menuId) {
      this.setState({
        menuId: menuId,
      });
    }

    if (userInfo) {
      if (userInfo.isLogined && !userInfo.isGuest) {
        if (gochiBillId) {
          var dataGochi = sessionStorage.getItem("usingFlowGochiInfo")
            ? JSON.parse(sessionStorage.getItem("usingFlowGochiInfo"))
            : {};
          console.log("gochiDetails22 ===", dataGochi);

          // const dataGochi = await this.getGochi(gochiBillId);
          // console.log('datagochi of GochiUseConfirm ===', dataGochi);
          this.setState({
            gochiInfo: {
              gochiBillId: dataGochi.gochiBillId,
              gochiBillNo: dataGochi.gochiBillNo,
              shopId: dataGochi.shopId,
              shopName: dataGochi.shopName,
              menuName: dataGochi.menuName,
              menuId: dataGochi.menuId,
              menuImage: dataGochi.menuImage
                ? dataGochi.menuImage
                : require("../../../assets/img/icons/gochi_noimg-ver4.jpg"),
              includedTaxPrice: dataGochi.includedTaxPrice,
              excludedTaxPrice: dataGochi.excludedTaxPrice,
              displayAmount: dataGochi.displayAmount,
              gochiCount: dataGochi.gochiCount,
              gochiExpiredDay: dataGochi.gochiExpiredDay,
              menuChildType: dataGochi.menu && dataGochi.menu.menuChildType,
              // childFoods: childFoods,
              groupId: dataGochi && dataGochi.groupId,
              groupName: dataGochi.groupInfo && dataGochi.groupInfo.groupName,
              shopStatus: dataGochi.shop && dataGochi.shop.status,
              menuCombo: dataGochi.menuCombo,
              comboOutQuantity: dataGochi.comboOutQuantity
                ? dataGochi.comboOutQuantity
                : 0,
              quantityFree: dataGochi.quantityFree ? dataGochi.quantityFree : 0,
              quantityFreeDes: dataGochi.quantityFreeDes,
              priceCombo: dataGochi.priceCombo,
              includedTaxPriceCombo: dataGochi.includedTaxPriceCombo,
              couponCount: dataGochi.couponCount,
              displayAmountOneTicket: dataGochi.displayAmountOneTicket,
            },
          });
        } else if (shopId) {
          var gochiDetails = sessionStorage.getItem("usingFlowGochiInfo")
            ? JSON.parse(sessionStorage.getItem("usingFlowGochiInfo"))
            : {};
          this.setState({
            gochiInfo: gochiDetails,
          });
          console.log("datagochi222222 ===", gochiDetails);
        }
      }
    }

    const history = useHistory();
    history.listen(location => {
      console.log('history', history.action);
      const urlSiteType = params.siteType ? "&siteType=" + params.siteType : "";
      const urlAccessToken = this.state.accessToken ? "&accessToken=" + this.state.accessToken : "";
      const urlAccessCode = sessionStorage.getItem("accessCode") ? "&accessCode=" + sessionStorage.getItem("accessCode") : "";
      if (history.action === 'PUSH') {
        this.setState({ locationKeys: [ location.key ] });
      }
      if (history.action === 'POP') {
        if (this.state.locationKeys[1] === location.key) {
          this.setState({ locationKeys: ([ _, ...keys ]) => keys });
          console.log('location forward', location);
          if (location.pathname === '/gochiuse/staff'){
            window.location.href = '/qrCode?shopId=' + shopId + urlAccessToken + urlAccessCode + urlSiteType;
          }
          // Handle forward event
        } else {
          this.setState({locationKeys: (keys) => [ location.key, ...keys ] });
          console.log('location back', location);
          // Handle back event
          if (location.pathname === '/gochiuse/staff'){
            window.location.href = '/qrCode?shopId=' + shopId + urlAccessToken + urlAccessCode + urlSiteType;
          }
        }
      }
    })
  }

  onCloseGochiConfirm() {
    // this.props.onCloseGochiConfirm();
    this.props.history.goBack();
    this.setState({
      confirmError: "",
    });
  }

  async submitUseWithAddedAmount(params) {
    const { t } = this.props;
    this.props.showLoading(true);
    this.setState({ buttonDisabled: true });
    let windowReference;
    try {
      if (params) {
        let result = null;
        this.setState({
          redirectUrl: "",
        });
        this.setState({
          redirectUrlStripe: "",
        });
        // charge added amount with payment Credit Card (Stripe/Veritrans)
        if (
          !this.state.paymentType ||
          this.state.paymentType === constants.PAYMENT_TYPE_VERITRANS ||
          this.state.paymentType === constants.PAYMENT_TYPE_STRIPE
        ) {
          result = await MenuService.chargeAddedAmount(
            params,
            this.state.crfsToken
          );

          log(result);
          if (result.status === 200) {
            // Not save data to props.menuInfo incase buy menu Price
            if (this.state.menuId && !this.state.shopId) {
              this.props.saveInvite(result.data);
            }

            var chargeAddedAmountResponse = result.data;
            let accessToken = queryString.parse(window.location.search).accessToken ? "&accessToken=" + queryString.parse(window.location.search).accessToken : '';
            let siteType = queryString.parse(window.location.search).siteType ? "&siteType=" + queryString.parse(window.location.search).siteType : '';
            chargeAddedAmountResponse.menuName = this.props.menuInfo.menuName;
            chargeAddedAmountResponse.description =
              this.props.menuInfo.description;
            sessionStorage.setItem(
              "chargeAddedAmountResponse",
              JSON.stringify(chargeAddedAmountResponse)
            );
            sessionStorage.removeItem("messageCard");
            this.setState({
              errorMessage: "",
              redirectToReferrer: true,
            });
            let link = `/gochiUse/finish?gochiBillId=${
              this.state.gochiInfo.gochiBillId
            }&gochiUsedNo=${1}&couponId=${
              this.state.gochiInfo?.coupons[0]?.couponId
            }&shopId=${this.state.shopId}`;
            console.log("history redirect to: ", link);
            this.props.history.push({
              pathname: "/gochiUse/finish",
              search: `?gochiBillId=${
                this.state.gochiInfo.gochiBillId
              }&gochiUsedNo=${1}&couponId=${
                this.state.gochiInfo?.coupons[0]?.couponId
              }&shopId=${this.state.shopId}${accessToken}${siteType}`,
              state: {
                useAmount: chargeAddedAmountResponse?.amount,
              },
            });
            this.props.showLoading(false);
          } else if (result.status === 406) {
            this.props.showError(
              true,
              t("pages.invite.Error create gochi"),
              ""
            );
            this.props.showLoading(false);
            return this.setState({
              errorMessage: t("pages.invite.Error create gochi"),
              buttonDisabled: false,
            });
          } else if (result.status === 402) {
            let messageErr = t(
              "pages.invite.error create gochi using stripe incase other charging"
            );
            if (result.data) {
              if (result.data.stripe_code === "expired_card") {
                messageErr = t(
                  "pages.invite.error create gochi using stripe incase expired card"
                );
              } else if (result.data.stripe_code === "amount_too_small") {
                messageErr = t(
                  "pages.invite.error create gochi using stripe incase amount to small"
                );
              } else if (result.data.stripe_code === "insufficient_funds") {
                messageErr = t(
                  "pages.invite.error create gochi using stripe incase insufficient funds"
                );
              } else if (result.data.stripe_code === "card_velocity_exceeded") {
                messageErr = t(
                  "pages.invite.error create gochi using stripe incase card velocity exceeded"
                );
              }
              // subscription error
              else if (this.props.menuInfo.subscription === 1) {
                messageErr = t("pages.invite.error subsription failed");
              }
              // other cases
              else {
                messageErr = t("pages.invite.error create gochi user");
              }
            }
            this.props.showError(true, messageErr, "");
            this.props.showLoading(false);
            return this.setState({
              errorMessage: messageErr,
              buttonDisabled: false,
            });
          } else if (
            result.status === 404 ||
            result.status === 400 ||
            result.status === 500
          ) {
            this.props.showError(
              true,
              t("pages.invite.error create gochi user"),
              ""
            );
            this.props.showLoading(false);
            return this.setState({
              errorMessage: t("pages.invite.error create gochi user"),
              buttonDisabled: false,
            });
          } else if (result.status === 504) {
            this.props.showError(
              true,
              t("pages.invite.error create gochi with user"),
              "",
              t("pages.invite.go to mypage"),
              "/mypage?id=" + this.state.menuId
            );
            this.props.showLoading(false);
            return this.setState({
              errorMessage: t("pages.invite.error create gochi with user"),
              buttonDisabled: false,
            });
          } else {
            this.props.showError(
              true,
              t("pages.invite.error create gochi user"),
              ""
            );
            this.props.showLoading(false);
            return this.setState({
              errorMessage: t("pages.invite.error create gochi user"),
              buttonDisabled: false,
            });
          }
          // charge added amount with payment not Stripe
        } else {
          if (this.state.paymentType !== constants.PAYMENT_TYPE_PAYPAY) {
            windowReference = window.open();
          }
          result = await MenuService.createSessionAddedAmount(params);
          log(result);
          if (result.status === 200) {
            var paypayResponse = result.data;
            paypayResponse.menuName = this.props.menuInfo.menuName;
            paypayResponse.description = this.props.menuInfo.description;
            sessionStorage.setItem(
              "paypayResponse",
              JSON.stringify(paypayResponse)
            );
            this.props.showLoading(false);
            if (this.state.paymentType === constants.PAYMENT_TYPE_PAYPAY) {
              this.setState({
                redirectUrl:
                  !this.state.menuId && this.state.shopId
                    ? `/ticket-use-complete?shopId=${this.state.shopId}&redirectFromPaypay=true&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}`
                    : `/ticket-use-complete?id=${this.state.menuId}&redirectFromPaypay=true&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}`,
              });
              if (isMobile) {
                window.location.href = result.data.redirectUrl;
              } else {
                window.open(result.data.redirectUrl, "_blank");
              }
            } else {
              // Payment type is Alipay or Linepay or Merupay
              // this.setState({
              // 	redirectUrl: `/invite-finish?id=${this.state.menuId}&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}&paymentGateway=onepay`
              // });
              const redirectUrl =
                !this.state.menuId && this.state.shopId
                  ? `/ticket-use-complete?shopId=${this.state.shopId}&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}&paymentGateway=onepay`
                  : `/ticket-use-complete?id=${this.state.menuId}&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}&paymentGateway=onepay`;
              // this.setState({
              // 	redirectToReferrer: true
              // });

              if (
                this.state.paymentType ===
                  constants.PAYMENT_TYPE_ONEPAY_ALIPAY &&
                !isMobile
              ) {
                // Show QR code
                windowReference.location = `${
                  process.env.REACT_APP_DOMAIN_URL
                }/alipay-qr-code?value=${encodeURIComponent(
                  result.data.redirectUrl
                )}&ts=${Date.now()}`;
                window.location.href = redirectUrl;
              } else {
                windowReference.location = result.data.redirectUrl;
                setTimeout(() => {
                  try {
                    const refHostname = windowReference.location.hostname;
                    if (refHostname !== "mobile.alipay.com")
                      windowReference.close();
                  } catch (e) {}
                  window.location.href = redirectUrl;
                }, 5000);
              }

              // Payment type = WechatPay
              if (this.state.paymentType === constants.PAYMENT_TYPE_WECHATPAY) {
                if (!isMobile) {
                  // Show QR code
                  windowReference.location = `${
                    process.env.REACT_APP_DOMAIN_URL
                  }/wechatpay-qr-code?value=${encodeURIComponent(
                    result.data.redirectUrl
                  )}&ts=${Date.now()}`;
                  window.location.href = redirectUrl;
                } else {
                  window.location.href = result.data.redirectUrl;
                }
              }
            }
            return null;
          } else if (result.status === 406) {
            this.props.showError(
              true,
              t("pages.invite.Error create gochi"),
              ""
            );
            this.props.showLoading(false);
            if (windowReference) {
              windowReference.close();
            }
            return this.setState({
              errorMessage: t("pages.invite.Error create gochi"),
              buttonDisabled: false,
            });
          } else if (
            result.status === 404 ||
            result.status === 400 ||
            result.status === 500
          ) {
            this.props.showError(
              true,
              t("pages.invite.error create gochi user"),
              ""
            );
            this.props.showLoading(false);
            if (windowReference) {
              windowReference.close();
            }
            return this.setState({
              errorMessage: t("pages.invite.error create gochi user"),
              buttonDisabled: false,
            });
          } else {
            this.props.showError(
              true,
              t("pages.invite.error create gochi with guest"),
              ""
            );
            this.props.showLoading(false);
            if (windowReference) {
              windowReference.close();
            }
            return this.setState({
              errorMessage: t("pages.invite.error create gochi with guest"),
              buttonDisabled: false,
            });
          }
        }
      } else {
        this.props.showError(true, "There is something wrong", "");
        this.props.showLoading(false);
        this.setState({
          errorMessage: "Fail",
          buttonDisabled: false,
        });
      }
    } catch (error) {
      log(error);
      this.props.showError(true, "There is something wrong", "");
      this.props.showLoading(false);
      if (windowReference) {
        windowReference.close();
      }
      this.setState({
        errorMessage: "Fail",
        buttonDisabled: false,
      });
    }
  }

  async onSubmitConfirm() {
    this.setState({
      submitConfirm: true,
      confirmError: "",
    });

    const shopId = this.state.gochiInfo.shopId;
    const menuId = this.state.gochiInfo.menuId;
    const gochiUserId = this.props.userInfo && this.props.userInfo.userId;
    const gochiBillId = this.state.gochiInfo.gochiBillId;
    const quantity = this.state.quantity;
    const groupId = this.state.gochiInfo.groupId;
    const childFoodId = this.state.gochiInfo.childFoodId;
    const menuLimitTotal = this.state.gochiInfo.menuLimitTotal === 1;
    const useAmount = this.props.location.state.useAmount;
    const addedAmount = this.props.location.state.addedAmount;
    const paymentSource =
      (!this.state.paymentType ||
        this.state.paymentType === constants.PAYMENT_TYPE_VERITRANS ||
        this.state.paymentType === constants.PAYMENT_TYPE_STRIPE) &&
      this.props.paymentMethod
        ? this.props.paymentMethod.token
        : "";

    const url = window.location.search;
    let params = queryString.parse(url);
    const shopIdP = params.shopId && !isNaN(parseInt(params.shopId)) ? parseInt(params.shopId) : null;

    if (menuLimitTotal && addedAmount > 0) {
      const chargeAddedAmountParams = {
        gochiUserId,
        premiumAmount: this.state.gochiInfo.remainAmount,
        couponId:
          this.state.gochiInfo.coupons &&
          this.state.gochiInfo.coupons[0] &&
          this.state.gochiInfo.coupons[0].couponId,
        useAmount: useAmount,
        paymentType: this.state.paymentType,
        platformType: "WEB",
        source: paymentSource,
        domainUrl: window.location.hostname,
        userAgent: navigator.userAgent,
        useForShopId: this.state.shopId,
      };
      this.submitUseWithAddedAmount(chargeAddedAmountParams);
      return;
    }

    try {
      this.props.showLoading(true);
      const response = await GochiService.useGochi(
        {
          gochiBillId,
          shopId: shopId ? shopId : shopIdP,
          menuId,
          gochiUserId,
          quantity,
          groupId,
          childFoodId,
          // new gochi
          useAmount: menuLimitTotal ? useAmount : undefined,
          memo: { shopId: null },
        },
        this.state.crfsToken
      );
      this.props.showLoading(false);

      if (response.status !== 200) {
        if (response.data) {
          if (
            response.data.code === "validation.errors.menuCombo.used.over.limit"
          ) {
            this.props.onCloseGochiConfirm();
            this.props.showError(
              true,
              "",
              this.props.t("other.This ticket can only be used once a day")
            );
          } else 
          if (response.data.code === "exception.errors.external.coupon.exception") {
            this.generateRandomString();
            this.props.showError(
              true,
              "",
              this.props.t("other.external coupon exception")
            );
          } else {
            this.setState({
              confirmError: this.props.t("other.Your login has been cancelled"),
            });
          }
        }
        else 
        this.props.showError(true, this.props.t("other.Unexpected error"), "");
      } else {
        const gochiBillId =
          response.data &&
          response.data.list &&
          response.data.list.length > 0 &&
          response.data.list[0].gochiBillId;
        const gochiUsedNo = response.data && response.data.count;
        const gochiTipUrl = response.data && response.data.gochiTipUrl;
        let accessToken = queryString.parse(window.location.search).accessToken ? "&accessToken=" + queryString.parse(window.location.search).accessToken : '';
        let siteType = queryString.parse(window.location.search).siteType ? "&siteType=" + queryString.parse(window.location.search).siteType : '';
        var couponId =
          response.data &&
          response.data.list &&
          response.data.list.length > 0 &&
          response.data.list[0].couponId;
        if (menuLimitTotal) {
          this.props.history.push({
            pathname: "/gochiUse/finish",
            search: `?gochiBillId=${
              this.state.gochiInfo.gochiBillId
            }&gochiUsedNo=${1}&couponId=${
              this.state.gochiInfo?.coupons[0]?.couponId
            }&shopId=${this.state.shopId}${accessToken}${siteType}`,
            state: {
              useAmount: useAmount,
              useCouponId: couponId,
            },
          });
          return;
        }
        let link = `/gochiUse/finish?gochiBillId=${gochiBillId}&gochiUsedNo=${gochiUsedNo}&couponId=${couponId}&gochiTipUrl=${
          gochiTipUrl || ""
        }${accessToken}${siteType}`;
        if (this.state.shopId) {
          link = `/gochiUse/finish?gochiBillId=${gochiBillId}&gochiUsedNo=${gochiUsedNo}&shopId=${
            this.state.shopId
          }&couponId=${couponId}&gochiTipUrl=${gochiTipUrl || ""}${accessToken}${siteType}`;
        } else if (this.state.gochiUserId) {
          link = `/gochiUse/finish?gochiBillId=${gochiBillId}&gochiUsedNo=${gochiUsedNo}&gochiUserId=${
            this.state.gochiUserId
          }&menuId=${this.state.menuId}&couponId=${couponId}&gochiTipUrl=${
            gochiTipUrl || ""
          }${accessToken}${siteType}`;
        }
        this.setState({
          confirmError: "",
          redirect: true,
          redirectUrl: link,
          couponId: couponId,
          gochiTipUrl: gochiTipUrl,
        });
      }

      this.setState({
        submitConfirm: false,
      });
    } catch (error) {
      console.log("error in gochiusse", error);
      if (error.code === "exception.errors.security.has.csrf.token") {
        this.setState({
          submitConfirm: false,
          confirmError: this.props.t(
            "other.Please wait for a while as it is being processed"
          ),
        });
      } else {
        this.setState({
          submitConfirm: false,
          confirmError: this.props.t("There is something wrong"),
        });
        this.props.showError(
          true,
          this.props.t("There is something wrong"),
          ""
        );
      }
    }
  }

  render() {
    log(["props gochiUseConfirm", this.props]);
    const { t } = this.props;
    const gochiInfo = this.state.gochiInfo;
    const menuLimitTotal = this.state.gochiInfo.menuLimitTotal === 1;
    const useAmount = this.props.location.state.useAmount;
    const addedAmount = this.props.location.state?.addedAmount;
    const remainAmountAfterUse =
      addedAmount > 0
        ? 0
        : this.state.gochiInfo?.remainAmount - this.props.location?.state?.useAmount;

    let optionNums = [];

    for (var i = 1; i <= this.state.gochiInfo.gochiCount; i++) {
      optionNums.push(<option key={i}>{i}</option>);
    }

    if (this.state.redirect) {
      window.history.pushState(
        {
          couponId: this.state.couponId,
          gochiTipUrl: this.state.gochiTipUrl,
        },
        null,
        this.state.redirectUrl
      );
      return <Redirect to={this.state.redirectUrl} />;
    }

    var userInfo = this.props.userInfo;
    var link = "/login";
    let isLoggedIn = false;
    if (userInfo) {
      if (userInfo.userId) {
        isLoggedIn = true;
      }
      if (userInfo.isLogined === false || userInfo.isGuest) {
        window.history.pushState(null, null, "/login");
        return <Redirect to={link} />;
      }
    } else {
      window.history.pushState(null, null, "/login");
      return <Redirect to={link} />;
    }

    return (
      <div className="content">
        <Header
          title={t("steps.step_confirm")}
          isLoggedIn={isLoggedIn}
          t={t}
          hasBack={true}
          usingFlow={true}
        />

        <div className="heading-page">
          <div className="heading-img text-center">
            <div className="heading-ttl ex-ttl-gochi">
              {t("steps.step_confirm")}
            </div>
          </div>
        </div>

        <div className="input-infor">
          <div className="mt-3">
            <ul className="progressbar-2 align-center">
              <li className="step-choice">
                <strong>STEP </strong>
                <strong className="fs-17">1</strong>
              </li>
              <li className="step-select active">
                <strong>STEP </strong>
                <strong className="fs-17">2</strong>
              </li>
              <li className="step-confirm">
                <strong>STEP </strong>
                <strong className="fs-17">3</strong>
              </li>
            </ul>
          </div>

          <div className="red-message-box">
            {t("pages.gochiuse.Screen only for shop")}
          </div>

          <div className="gochi-info dish-infor mt-3">
            <div className="media">
              <div className="dish-image">
                <figure className="mb-0 dish-fig">
                  <img
                    src={gochiInfo.menuImage}
                    alt={gochiInfo.menuName}
                    width="100%"
                  />
                </figure>
                {gochiInfo.menuCombo === 1 && (
                  <div>
                    <div className="triangle-label">
                      <span>{t("pages.invite.Coupon")}</span>
                    </div>
                    {gochiInfo.quantityFree !== 0 ? (
                      <div className="bonus-label">
                        <span className="clr-fff">
                        {`+`}{t('pages.invite.Advantageous (total)', { 
                                freeTicketCount: gochiInfo.quantityFree, 
                                totalCoupon: gochiInfo.quantityFree +
                                gochiInfo.comboOutQuantity})}
                        </span>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
              <div className="media-body dish-body pl-2">
                <div className="mt-0 dish-link fs-19">
                  <span className="fs-16 clr-636363 font-weight-normal">
                    {gochiInfo.shopName || gochiInfo.groupName}
                  </span>
                  <br />
                  <span>{gochiInfo.menuName}</span>
                  {gochiInfo.menuCombo === 1 && (
                    <>
                      <br />
                      <span>
                        {gochiInfo.displayAmountOneTicket
                          ? gochiInfo.displayAmountOneTicket.toLocaleString()
                          : 0}
                        円ｘ
                      </span>
                      <span>
                        {gochiInfo.comboOutQuantity
                          ? gochiInfo.comboOutQuantity + gochiInfo.quantityFree
                          : 0}
                        {t("pages.login.sheet")}
                      </span>
                      <span>
                        {gochiInfo.quantityFree !== 0
                          ? `(${t("pages.invite.Include N ticket free", {
                              number: gochiInfo.quantityFree,
                            })})`
                          : null}
                      </span>
                    </>
                  )}
                </div>

                {menuLimitTotal && (
                  <div className="d-flex justify-content-around align-items-baseline">
                    <span className="fs-16 text-dark font-weight-bolder line-height-47">
                      {this.props.t("pages.gochiuse.total")}
                    </span>
                    <span className="fs-47 text-dark font-weight-bolder">
                      {this.state.gochiInfo?.remainAmount?.toLocaleString()}
                    </span>
                    <span className="fs-16 text-dark font-weight-bolder line-height-47">
                      円
                    </span>
                  </div>
                )}

                {menuLimitTotal && (
                  <div className="d-flex justify-content-around align-items-baseline">
                    <span className="fs-12 text-dark font-weight-bolder line-height-22 clr-A0A0A0">
                      {this.props.t("pages.gochiuse.balance after use")}
                    </span>
                    <span className="fs-22 text-dark font-weight-bolder clr-A0A0A0">
                      {remainAmountAfterUse?.toLocaleString()}
                    </span>
                    <span className="fs-16 text-dark font-weight-bolder line-height-22 clr-A0A0A0">
                      円
                    </span>
                  </div>
                )}
              </div>
            </div>

            {menuLimitTotal && (
              <div className="border-top p-2 mt-2">
                <div className="d-flex justify-content-between">
                  <div className="required-dot">
                    <div className="dot-red"></div>
                    <div className="dot-text-label fs-18">
                      {this.props.t("pages.gochiuse.amount to use")}
                    </div>
                  </div>
                  <span className="fs-44">
                    <span className="text-red font-weight-bold">
                      {useAmount?.toLocaleString()}
                    </span>
                    <span className="fs-20 line-height-44 text-red font-weight-bolder">
                      円
                    </span>
                  </span>
                </div>
              </div>
            )}

            {!menuLimitTotal &&
              (gochiInfo.menuCombo === 1 ? (
                <div className="using-statistic-green">
                  {t("pages.invite.Coupon")}
                  <span className="fs-21">
                    {gochiInfo.couponCount - gochiInfo.gochiCount + 1}/
                    {gochiInfo.couponCount}
                  </span>
                  {t("pages.gochiuse.used")}
                  <br />
                  <span className="fs-44">1</span>
                  {t("pages.login.sheet")}
                  <span className="pl-2">{t("pages.gochiuse.total")}</span>
                  <span className="fs-44">
                    {gochiInfo.displayAmountOneTicket
                      ? gochiInfo.displayAmountOneTicket.toLocaleString()
                      : 0}
                  </span>
                  {t("pages.invite.Yen Unit")}
                </div>
              ) : (
                <div className="using-statistic-green">
                  <span className="fs-44">{this.state.quantity}</span>
                  {t("pages.invite.pieces")}
                  <span> {t("pages.gochiuse.total")}</span>
                  <span className="fs-44">
                    {(
                      this.state.quantity *
                      (gochiInfo.displayAmount || gochiInfo.includedTaxPrice)
                    ).toLocaleString()}
                  </span>
                  {t("pages.invite.Yen Unit")}
                </div>
              ))}

            {gochiInfo.menuCombo === 1 && gochiInfo.quantityFreeDes && (
              <div className="mt-2">
                <div className="arrow-up"></div>
                <div className="div-free-ticket-desc break-line break-word">
                  <div className="font-weight-bold">
                    {t("pages.invite.Bonus")}
                  </div>
                  <div>{gochiInfo.quantityFreeDes}</div>
                </div>
              </div>
            )}
          </div>
        </div>

        {menuLimitTotal && this.state.redirectUrl && (
          <div className="pm-info">
            <>
              {this.state.paymentType ? (
                <div className="dish-infor text-center mb-2">
                  <label className="ttl-label fs-16 clr-040404">
                    {t("pages.invite.paymentMethodConfirm")}
                  </label>

                  <div className="payment-info">
                    <div className="fs-16 font-weight-bold">
                      {this.state.paymentType ===
                        constants.PAYMENT_TYPE_PAYPAY &&
                        t("pages.invite.payment paypay")}
                      {this.state.paymentType ===
                        constants.PAYMENT_TYPE_ONEPAY_ALIPAY &&
                        t("pages.invite.payment alipay")}
                      {this.state.paymentType ===
                        constants.PAYMENT_TYPE_WECHATPAY &&
                        t("pages.invite.payment wechatpay")}
                      {this.state.paymentType ===
                        constants.PAYMENT_TYPE_ONEPAY_LINEPAY &&
                        t("pages.invite.payment linepay")}
                      {this.state.paymentType ===
                        constants.PAYMENT_TYPE_ONEPAY_MERUPAY &&
                        t("pages.invite.payment merupay")}
                      {this.state.paymentType ===
                        constants.PAYMENT_TYPE_STRIPE &&
                        t("pages.invite.payment other")}
                      {this.state.paymentType ===
                        constants.PAYMENT_TYPE_VERITRANS &&
                        t("pages.invite.payment other")}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="dish-infor text-center mb-2">
                  <label className="ttl-label fs-16 clr-040404">
                    {t("pages.invite.paymentMethodConfirm")}
                  </label>
                  <div className="payment-info">
                    <div className="fs-16 font-weight-bold">
                      {t("pages.invite.payment other")}
                    </div>
                  </div>
                </div>
              )}
            </>
            <div className="text-recreate-paypay">
              {t("pages.invite.link recreate paypay")}
              <a
                href={this.state.redirectUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="link-recreate-paypay"
              >
                {t("pages.invite.link detail")}
              </a>
            </div>
          </div>
        )}

        <div className="order-btn-container mt-4">
          <div className="fs-16 font-weight-bold clr-000000">
            {t("pages.gochiuse.Do you want to confirm this order?")}
          </div>
          <Button
            className="btn btn-use-55 mt-2 bg-red"
            variant="danger"
            disabled={this.state.submitConfirm}
            onClick={this.onSubmitConfirm}
          >
            {t("pages.gochiuse.yes")} / confirm
          </Button>

          <Button
            className="btn btn-use-55 mt-2 bg-grey"
            variant="secondary"
            onClick={this.onCloseGochiConfirm}
          >
            {t("pages.gochiuse.no")} / cancel
          </Button>

          <div className="text-red mt-2 text-center fs-13 font-weight-bold">
            {t("pages.gochiuse.The store staff should click the button")}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content, buttonName, gotoUrl) =>
    dispatch(showError(showing, title, content, buttonName, gotoUrl)),
  savePaymentMethod: (paymentMethod) =>
    dispatch(savePaymentMethod(paymentMethod)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(GochiUseConfirm))
);

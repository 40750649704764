import * as constants from "../../constants";

const initialState = {
  paymentType: constants.PAYMENT_TYPE_CREDIT_CARD,
  donateAmount: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GOCHI_KID_DONATE_CONFIRM':
      return {
        ...state,
        ...action.paymentData
      };
    case 'GOCHI_KID_DONATE_CLEAR':
      return {
        ...initialState
      };
    default:
      return state;
  }
};

import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import {
  useScript,
} from "../../../utils";
import Header from "../layout/Header";
import GiftService from "../../../api/services/GiftService";
import MogugoriService from "../../../api/services/MogugoriService";
import { useHistory, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";
import { showLoading } from "../../../actions/action_loading";
import { showError } from "../../../actions/action_error_alert";
import { SHOP_ID_SHOW_RANK_MOGUGORI } from "../../../constants";

const SHOP_NO_IMAGE = require("../../../assets/Mogugori/img/icon-shop-detail-background.png");

export function UserRank(props) {
  const match = useRouteMatch();
  const history = useHistory();
  const shopId = match.params.shopId;
  const [shopDetails, setShopDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingRank, setLoadingRank] = useState(false);
  const [listRankShop, setListRankShop] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const LIMIT = 100;

  useScript("/assets/js/app.js");
  useEffect(() => {
    if (!SHOP_ID_SHOW_RANK_MOGUGORI.includes(parseInt(shopId))) {
      history.push("/mogugori");
    }
    document.body.className = "mogugori shop-detail";
    window.scrollTo(0, 0);
    getShopDetails();
    getListRankOfShop(shopId, 1);
  }, [shopId]);

  useEffect(() => {
    sessionStorage.setItem('mogugoriShopId', shopId);
  }, []);

  function getShopDetails() {
    setLoading(true);
    GiftService.getShopDetails(shopId)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          setShopDetails(response.data);
        } else if (response.status === 404) {
          history.push("/404");
        }
      })
      .catch((e) => {
        setLoading(false);
        console.error("GET SHOP DETAILS ERROR: ", e);
      });
  }

  function getListRankOfShop(shopId, page) {
    setLoadingRank(true);
    MogugoriService.getListUserOfShop(shopId, page, LIMIT)
      .then((response) => {
        setLoadingRank(false);
        if (response.status === 200) {
          let tempList = [...listRankShop];
          tempList = tempList.concat(response.data.list);
          setListRankShop(tempList);
          setTotal(response.data.total);
          setPage(page);
        } else if (response.status === 500) {
          alert("エラーが発生しました。");
        }
      })
      .catch((e) => {
        setLoadingRank(false);
      })
  }

  function handleLoadMore() {
    let nextPage = page + 1;
    if (nextPage <= Math.ceil(total/LIMIT)) {
      getListRankOfShop(shopId, nextPage);
    }
  }

  const [expandDescription, setExpandDescription] = useState(false);

  //const displayDescription =
  //  !expandDescription && shopDetails.introduction?.length >= 195
  //    ? shopDetails.introduction?.slice(0, 194) + "…"
  //    : shopDetails.introduction;

  const displayDescription = shopDetails.introduction;

  return (
    <>
      <MetaTags>
        <title>お店一覧｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content="お店一覧です。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
      </MetaTags>
      <Header />

      <main>
        <div className="container shop-detail container-mogugori">
          <header className={loading ? "loading" : ""}>
            <div className="shop-detail-header">
              <div className="logo-shopdetail">
                <img
                src={shopDetails.shopImage &&
                  shopDetails.shopImage[0]
                    ? shopDetails.shopImage[0] &&
                      shopDetails.shopImage[0].image
                    : SHOP_NO_IMAGE}
                loading="lazy"
                alt=""
              />
              </div>
              <div className="shop-name-detail">
                {shopDetails.shopName}
                <div className="shop-name-box">
                  {shopDetails.snsTwitter && (
                    <a href={shopDetails.snsTwitter}>
                      <i className="icon-twitter"></i>
                    </a>
                  )}
                  {shopDetails.webPage && (
                    <a href={shopDetails.webPage}>
                      <i className="icon-calendar"></i>
                    </a>
                  )}
                </div>
              </div>
              <div className="shop-name-des">
                <p className="title">店舗詳細</p>
                <p className="pre-line" dangerouslySetInnerHTML={{ __html: displayDescription }}></p>
                {/*{!expandDescription && shopDetails.introduction?.length >= 200 && (
                  <div className="text-sm text-right">
                    <a
                      className="link text-underline"
                      onClick={() => setExpandDescription(true)}
                    >
                      もっと見る
                    </a>
                  </div>
                )}*/}
              </div>
            </div>
            {/* redmine 115253: open ranking, hard code shop id = 26586 */}
            <div className="shop-rank-box">
              <div className="title-ranking">月間おごりユーザー</div>
              {listRankShop.map((item, index) => (
                <div className="item-ranking" key={item.email}>
                  <span className="item-rank-number">{index + 1}</span>
                  <span className="item-rank-name">{item.displayName}</span>
                </div>
              ))}
              <div className={`load-more-box  ${loadingRank ?  "loading" : ""}`}>
                {(page + 1) <= Math.ceil(total/LIMIT) && (
                  <span
                  className="btn-load-more"
                  onClick={() => handleLoadMore()}
                  >
                    もっと見る
                  </span>
                )}
              </div>
            </div>
          </header>
        </div>
      </main>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content, buttonName, gotoUrl) =>
    dispatch(showError(showing, title, content, buttonName, gotoUrl)),
});

export default connect(null, mapDispatchToProps)(UserRank);

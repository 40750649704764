const stateDefault =  {};

export default (state = stateDefault, action) => {
  switch (action.type){
    case 'SET_TEMP_WISH_LIST_MENU':
      let newState = {...state};
      newState[action.menuId] = action.isWishList;
      return newState;
    default:
      return state;
  }
};

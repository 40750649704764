import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  formatDate,
  toggleFavoriteMenu,
  toggleWishListMenu,
  useRecentlyViewedMenus,
  useScript,
} from "../../../../utils";
import { Footer } from "../../layout/Footer";
import Header from "../../layout/Header";
import { Explanation } from "../../layout/Explanation";
import GiftService from "../../../../api/services/GiftService";
import { ShopDetails } from "../../common/ShopDetails";
import MenuService from "../../../../api/services/MenuService";
import ShopService from "../../../../api/services/ShopService";
import { useRouteMatch } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { ListMenus } from "../../common/ListMenus";
import { useDispatch, useSelector } from "react-redux";
import { showNotLoggedModal } from "../../../../actions/action_show_not_logged_modal";
import { ModalGroup } from "../../common/ModalGroup";
import { NotLoggedModal } from "../../common/NotLoggedModal";
import { ListShopModal } from "../../common/ListShopModal";
import { SituationModal } from "../../common/SituationModal";
import { GiftToModal } from "../../common/GiftToModal";
import { InfoBox } from "../../common/InfoBox";
import { setTempFavoriteMenuAction } from "../../../../actions/action_favorite_menu";
import { setTempWishListMenuAction } from "../../../../actions/action_wishlist_menu";
import { WishListModal } from "../../common/WishListModal";
import { showWishListModal } from "../../../../actions/action_show_wish_list_modal";
import moment from "moment";

const NO_IMAGE = require("../../../../assets/img/icons/square_blank.png");

export function MenuDetails(props) {
  useScript("/assets/js/app.js");

  const match = useRouteMatch();
  const history = useHistory();
  const shopId = match.params.shopId;
  const menuId = match.params.menuId;
  const [shopDetails, setShopDetails] = useState({});
  const [menuDetails, setMenuDetails] = useState({});
  const userInfo = useSelector((state) => state.userInfo);
  const loginUserId = userInfo?.userId;
  const [listShopSameCity, setListShopSameCity] = useState([]);
  const [listMenuSameCity, setListMenuSameCity] = useState([]);
  const [recentlyViewedMenus] = useRecentlyViewedMenus(null, null, menuId);
  const [listShops, setListShops] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [page, setPage] = useState(1);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [keyword, setKeyword] = useState(null);
  const [listComments, setListComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const isLoggedIn = userInfo && userInfo.userId;
  const [loadingShops, setLoadingShops] = useState(false);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.className = "shopdetails";
    window.scrollTo(0, 0);
    getMenuDetails();
    getComments();
    if (shopId !== "group-menu") getShopDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopId, menuId]);

  useEffect(() => {
    if (menuDetails.menuChildType === 3 || menuDetails.menuChildType === 4 || menuDetails.menuChildType === 5) {
      setListShops([]);
      setKeyword('');
      setPage(1);
    }
    setListMenuSameCity([]);
    setListShopSameCity([]);
  }, [menuId]);

  useEffect(() => {
    const title =
      (menuDetails.menuName || "") +
      (shopDetails.shopName ? `｜${shopDetails.shopName}` : "") +
      "｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス";
    setTitle(title);
    const description = shopDetails.shopName
      ? `${shopDetails.shopName}の${menuDetails.menuName}のページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。`
      : menuDetails.menuName +
        "のページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。";
    setDescription(description);
  }, [shopDetails, menuDetails]);

  function postRecentlyViewMenu() {
    const isLoggedIn = userInfo && userInfo.userId;
    if (isLoggedIn) {
      GiftService.postRecentlyViewMenu(menuId);
    } else {
      const recentlyList =
        JSON.parse(localStorage.getItem("recentlyViewed")) || [];
      const index = recentlyList.indexOf(menuId);
      if (index > -1) {
        recentlyList.splice(index, 1);
      }
      recentlyList.push(menuId);
      console.log("RECENTLY: ", recentlyList);
      localStorage.setItem("recentlyViewed", JSON.stringify(recentlyList));
    }
  }

  function getCoords() {
    var options = {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    };

    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(
        (success) => {
          resolve(success);
        },
        (error) => {
          if (error.code === 1) {
            resolve(null);
          } else {
            reject(error);
          }
        },
        options
      )
    );
  }

  const getListShops = async (
    menuId,
    loginUserId,
    sort,
    page,
    latitude,
    longitude,
    keyword
  ) => {
    setLoadingShops(true);
    try {
      const { data } = await ShopService.getListlShopsCmm(
        menuId,
        loginUserId,
        sort,
        page,
        latitude,
        longitude,
        keyword
      );
      setLoadingShops(false);
      if (data.list) {
        setListShops((old) => [...old, ...data.list]);
        setPage(page);
        setTotalResults(data.count);
      }
    } catch (error) {
      console.log("error getListShops", error);
    }
  };

  function loadMore() {
    if (hasMore) {
      getListShops(
        menuId,
        loginUserId,
        "distance",
        page + 1,
        latitude,
        longitude,
        keyword
      );
    }
  }

  function showListShop() {
    setListShops([]);
    getListShops(
      menuId,
      loginUserId,
      "distance",
      1,
      latitude,
      longitude,
      keyword
    );
    console.log(listShops);
  }

  const hasMore = listShops.length < totalResults;

  async function handleOpenListShop() {
    setLoadingShops(true);
    setListShops([]);
    setKeyword('');
    setPage(1);
    window.openModal("groupShopsModal");

    let lat = null;
    let long = null;

    try {
      const position = await getCoords();
      console.log("ggg=====", position);

      if (position && position.coords) {
        lat = position.coords.latitude;
        long = position.coords.longitude;
      }
    } catch (e) {
      console.log("======================", e);
    }
    setLatitude(lat);
    setLongitude(long);

    await getListShops(menuId, loginUserId, "distance", 1, lat, long);
  }

  function getListShopSameCity(city, prefectures) {
    GiftService.getShops({
      page: 1,
      perPage: 25,
      city,
      excludeShopId: shopId,
    })
      .then((response) => {
        if (response.status === 200) {
          setListShopSameCity(response.data.list);
        } else if (response.status === 404) {
          GiftService.getShops({
            page: 1,
            perPage: 25,
            prefectures,
            excludeShopId: shopId,
          }).then((response) => {
            if (response.status === 200) {
              setListShopSameCity(response.data.list);
            } else if (response.status === 404) {
              // No shop
            }
          });
        }
      })
      .catch((e) => {
        console.error("GET LIST SHOPS ERROR: ", e);
      });
  }

  function getListMenuSameCity(city, prefectures) {
    GiftService.getMenus({
      page: 1,
      perPage: 25,
      city,
      orderBy: 1,
      excludeShopId: shopId,
    })
      .then((response) => {
        if (response.status === 200) {
          setListMenuSameCity(response.data.list);
        } else if (response.status === 404) {
          GiftService.getMenus({
            page: 1,
            perPage: 25,
            prefectures,
            excludeShopId: shopId,
            orderBy: 1,
          }).then((response) => {
            if (response.status === 200) {
              setListMenuSameCity(response.data.list);
            } else if (response.status === 404) {
              // No menu
            }
          });
        }
      })
      .catch((e) => {
        console.error("GET LIST SHOPS ERROR: ", e);
      });
  }

  function getShopDetails() {
    GiftService.getShopDetails(shopId)
      .then((response) => {
        if (response.status === 200) {
          setShopDetails(response.data);
          const city = response.data.city;
          const prefecture = response.data.prefectures;
          getListMenuSameCity(city, prefecture);
          getListShopSameCity(city, prefecture);
        } else if (response.status === 404) {
          // TODO
        }
      })
      .catch((e) => {
        console.error("GET SHOP DETAILS ERROR: ", e);
      });
  }

  function getComments() {
    GiftService.getListCouponMessagesOfMenu({
      page: 1,
      perPage: 3,
      menuId,
    })
      .then((response) => {
        if (response.status === 200) {
          setListComments((old) => response.data.list);
        }
      })
      .catch((e) => {
        console.error("GET LIST COUPON MESSAGES ERROR: ", e);
      });
  }

  function getGroupDetails() {}

  function getMenuDetails() {
    setLoading(true);
    MenuService.getDetailMenu(menuId)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          // check if menu is valid
          if (
            shopId !== "group-menu" &&
            response.data.shopId !== Number(shopId)
          ) {
            history.push("/404");
          }
          if (shopId === "group-menu" && response.data.shopId) {
            history.push("/404");
          }
          setMenuDetails(response.data);
          postRecentlyViewMenu();
          if (shopId === "group-menu") {
            getGroupDetails();
          }
        } else if (response.status === 404) {
          history.push("/404");
        }
      })
      .catch((e) => {
        setLoading(false);
        console.error("GET MENU DETAILS ERROR: ", e);
      });
  }

  const isMenuCombo = menuDetails.menuCombo === 1;
  const isCommonMenu =
    menuDetails.menuChildType === 3 || menuDetails.menuChildType === 4 || menuDetails.menuChildType === 5;
  const menuComboDetail = menuDetails.menuComboDetail;

  const favoriteState = useSelector((state) => state.favoriteMenu);
  const wishListState = useSelector((state) => state.wishListMenu);

  const tempFavorite =
    favoriteState[menuId] !== undefined ? favoriteState[menuId] : null;
  const tempWishList =
    wishListState[menuId] !== undefined ? wishListState[menuId] : null;
  const setTempFavorite = (isFav) => {
    dispatch(setTempFavoriteMenuAction(menuId, isFav));
  };
  const setTempWishList = (isWishList) => {
    dispatch(setTempWishListMenuAction(menuId, isWishList));
  };
  const feePaidByShop = menuDetails.feePaidByShop
    ? menuDetails.feePaidByShop
    : false;

  function onToggleMenuWishList() {
    if (!isLoggedIn) {
      dispatch(showNotLoggedModal(true));
      return;
    }
    const current =
      tempWishList !== null ? tempWishList : menuDetails.isWishList;
    console.log("TOGGLE: ", current, tempWishList, menuDetails.isWishList);
    toggleWishListMenu(menuDetails.menuId, current, () => {
      setTempWishList(!current);
      if (!current) dispatch(showWishListModal(true));
    });
  }

  function onToggleMenuFavorite() {
    if (!isLoggedIn) {
      dispatch(showNotLoggedModal(true));
      return;
    }
    const current =
      tempFavorite !== null ? tempFavorite : menuDetails.isFavourite;
    console.log("TOGGLE: ", current, tempFavorite, menuDetails.isFavourite);
    toggleFavoriteMenu(menuDetails.menuId, current, () => {
      setTempFavorite(!current);
      if (!current) dispatch(showWishListModal(true, true));
    });
  }

  return (
    <>
      <MetaTags>
        <title>{title}</title>
        <meta
          name="keywords"
          content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト"
        />
        <meta name="description" content={description} />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content={window.location.origin + "/assets/img/common/ogp.png"}
        />
        <meta name="twitter:site" content="@gochi_meshi" />
        <meta name="twitter:creator" content="@gochi_meshi" />
        <meta name="twitter:image:width" content="300" />
        <meta name="twitter:image:height" content="300" />
        <meta property="og:site_name" content={title} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={window.location.origin + "/assets/img/common/ogp.png"}
        />
        <meta property="og:description" content={description} />
      </MetaTags>
      <Header />

      <main>
        <div className="breadcrumb">
          <div className="container">
            <ol className="breadcrumb-list">
              <li>
                <a href="/">ごちめし</a>
              </li>
              {!isCommonMenu && shopDetails.prefectures && (
                <li>
                  <Link to={`/shops?prefecture=${shopDetails.prefectures}`}>
                    {shopDetails.prefectures}
                  </Link>
                </li>
              )}
              {!isCommonMenu && shopDetails.city && (
                <li>
                  <Link
                    to={`/shops?prefecture=${shopDetails.prefectures}&city=${shopDetails.city}`}
                  >
                    {shopDetails.city}
                  </Link>
                </li>
              )}
              {!isCommonMenu && shopDetails.shopName && (
                <li>
                  <Link to={`/shopdetails/${shopDetails.shopId}`}>
                    {shopDetails.shopName}
                  </Link>
                </li>
              )}
              <li>
                <span>{menuDetails.menuName}</span>
              </li>
            </ol>
          </div>
        </div>
        {shopId !== "group-menu" && (
          <div className="main-nav">
            <div className="container">
              <Link to={`/shopdetails/${shopDetails.shopId}`}>
                店舗詳細に戻る
              </Link>
            </div>
          </div>
        )}
        <div className="container">
          <header className={loading ? "menu-digest loading" : "menu-digest"}>
            <div className="menu-digest-thumbnail">
              <div className="thumbnail">
                {menuDetails.menuImage !== undefined && (
                  <img
                    src={
                      menuDetails.menuImage
                        ? menuDetails.menuImage[0] &&
                          menuDetails.menuImage[0].image
                        : NO_IMAGE
                    }
                    alt={menuDetails.menuName}
                    loading="lazy"
                  />
                )}
              </div>
              <div className="badge-group">
                {menuDetails.menuComboDetail?.quantityFree > 0 && (
                  <div className="badge badge-black">無料</div>
                )}
                {menuDetails.menuComboDetail?.quantityFreeDes && (
                  <div className="badge">お得</div>
                )}
              </div>
            </div>
            <div className="menu-digest-section">
              <div className="menu-digest-header">
                <div className="menu-digest-header-aside">
                  <div className="action-group action-group-lg">
                    <div
                      className={
                        (
                          tempWishList !== null
                            ? tempWishList
                            : menuDetails.isWishList
                        )
                          ? "action action-list is-active"
                          : "action action-list is-inactive"
                      }
                    >
                      <button
                        type="button"
                        aria-label="記事を保存"
                        onClick={onToggleMenuWishList}
                      >
                        <svg className="icon">
                          <use xlinkHref="/assets/img/common/icon.svg#list" />
                        </svg>
                      </button>
                    </div>
                    <div
                      className={
                        (
                          tempFavorite !== null
                            ? tempFavorite
                            : menuDetails.isFavourite
                        )
                          ? "action action-like is-active"
                          : "action action-like is-inactive"
                      }
                    >
                      <button
                        type="button"
                        aria-label="記事を保存"
                        onClick={onToggleMenuFavorite}
                      >
                        <svg className="icon">
                          <use xlinkHref="/assets/img/common/icon.svg#heart" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="menu-digest-header-body">
                  <div className="heading-lg">
                    <h1>
                      {menuDetails.menuName}{" "}
                      {isMenuCombo &&
                        `${menuComboDetail?.includedTaxPriceCombo?.toLocaleString()}円ｘ${
                          menuComboDetail?.quantity +
                          menuComboDetail?.quantityFree
                        }枚`}{" "}
                      {menuComboDetail?.quantityFree > 0 &&
                        `(${menuComboDetail?.quantityFree}枚お得)`}
                    </h1>
                  </div>
                  {isCommonMenu ? (
                    <p className="text-gray text-sm">{menuDetails.groupName}</p>
                  ) : (
                    <Link
                      className="link text-sm"
                      to={`/shopdetails/${shopId}`}
                    >
                      {shopDetails.shopName}
                    </Link>
                  )}
                </div>
              </div>
              {isMenuCombo ? (
                <div className="menu-digest-body">
                  {menuDetails.menuComboDetail?.quantityFreeDes && (
                    <div className="balloon">
                      <div className="title">おまけ</div>
                      <div className="text">
                        {menuDetails.menuComboDetail?.quantityFreeDes}
                      </div>
                    </div>
                  )}
                  {menuComboDetail?.quantityFree > 0 && (
                    <p className="badge badge-ticket badge-red mr-2 my-0">
                      +{menuComboDetail?.quantityFree}枚お得(合計
                      {menuComboDetail?.quantityFree +
                        menuComboDetail?.quantity}
                      枚)
                    </p>
                  )}
                  {feePaidByShop && typeof feePaidByShop !== "undefined" && (
                      <p className="badge badge-ticket badge-red my-0">
                        手数料店舗負担
                      </p>
                  )}
                  <dl className="list-bullet">
                    {menuDetails?.menuExpiration && (
                      <div className="list-bullet-item">
                        <dt className="title">使用期限</dt>
                        <dd className="text text-bold text-red">
                          {moment(menuDetails?.menuExpiration).format('YYYY年MM月DD日まで')}
                        </dd>
                      </div>
                    )}
                    {/*{menuComboDetail?.expiration && (
                      <div className="list-bullet-item">
                        <dt className="title">利用期間</dt>
                        <dd className="text text-bold text-red">
                          購入後{menuComboDetail?.expiration}日間
                        </dd>
                      </div>
                    )}*/}
                    {/*{menuComboDetail?.expirationEndDate && (
                      <div className="list-bullet-item">
                        <dt className="title">利用期間</dt>
                        <dd className="text text-bold text-red">
                          {formatDate(
                            menuComboDetail?.expirationEndDate
                          )?.replaceAll(".", "/")}
                          まで利用可能
                        </dd>
                      </div>
                    )}*/}
                    {menuComboDetail?.numberUsePerDay === 1 && (
                      <div className="list-bullet-item">
                        <dt className="title">利用制限</dt>
                        <dd className="text text-bold text-red">1日1枚</dd>
                      </div>
                    )}
                  </dl>
                  {isCommonMenu && (
                    <div className="btn-group">
                      <button
                        className="btn btn-sm btn-outline btn-rect btn-fluid"
                        onClick={handleOpenListShop}
                      >
                        この商品が使えるお店一覧
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <div className="menu-digest-body">
                    <dl className="list-bullet">
                      {menuDetails?.menuExpiration && (
                        <div className="list-bullet-item">
                          <dt className="title">使用期限</dt>
                          <dd className="text text-bold text-red">
                            {moment(menuDetails?.menuExpiration).format('YYYY年MM月DD日まで')}
                          </dd>
                        </div>
                      )}
                    </dl>
                  </div>
                  {isCommonMenu && (
                    <div className="btn-group mt-20px">
                      <button
                        className="btn btn-sm btn-outline btn-rect btn-fluid"
                        onClick={handleOpenListShop}
                      >
                        この商品が使えるお店一覧
                      </button>
                    </div>
                  )}
                </>
              )}
              {!isMenuCombo && feePaidByShop && (
                <div className="menu-digest-body">
                  <p className="badge badge-ticket badge-red my-0">
                    手数料店舗負担
                  </p>
                </div>
              )}
              <div className="menu-digest-footer">
                <div className="price-area">
                  <span className="unit">¥</span>
                  <span className="num">
                    {menuDetails.chargeAmount?.toLocaleString()}
                  </span>
                </div>
                <div className="btn-group over-pc">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`/menu?id=${menuId}`}
                    className="btn btn-rect btn-fluid"
                  >
                    購入する
                  </a>
                </div>
              </div>
            </div>
          </header>
          <div className="menu-btn-group under-tab">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`/menu?id=${menuId}`}
              className="btn btn-rect btn-fluid"
            >
              購入する
            </a>
          </div>
          <div className="section">
            <p>{menuDetails.description}</p>
          </div>
          {/* <hr className="over-pc mt-20" /> */}

          {listComments?.length > 0 && (
            <section className="section">
              <div className="section-header">
                <div className="heading-lg">
                  <h2 className="title">最近のコメント</h2>
                </div>
                {!isCommonMenu && (
                  <Link
                    to={`/shopdetails/${shopId}/comments`}
                    className="link only-pc text-underline"
                  >
                    最近のコメントもっと見る
                  </Link>
                )}
              </div>
              <div className="section-body">
                <div className="card-group card-group-comment">
                  {listComments && listComments[0] && (
                    <div
                      className="card card-comment card-elipsis"
                      // href="#"
                    >
                      <p className="comment">
                        {/* <span className="caution">最大120文字</span> */}
                        {listComments[0].shopMessage?.length >= 95
                          ? listComments[0].shopMessage?.slice(0, 94) + "…"
                          : listComments[0].shopMessage}
                      </p>
                    </div>
                  )}
                  {listComments && listComments[1] && (
                    <div
                      className="card card-comment card-elipsis"
                      // href="shopdetails-%7BshopID%7D-comments.html"
                    >
                      <p className="comment">
                        {/* <span className="caution">最大120文字</span> */}
                        {listComments[1].shopMessage?.length >= 95
                          ? listComments[1].shopMessage?.slice(0, 94) + "…"
                          : listComments[1].shopMessage}
                      </p>
                    </div>
                  )}
                  {listComments && listComments[2] && (
                    <div
                      className="card card-comment card-elipsis"
                      // href="shopdetails-%7BshopID%7D-comments.html"
                    >
                      <p className="comment">
                        {/* <span className="caution">最大120文字</span> */}
                        {listComments[2].shopMessage?.length >= 95
                          ? listComments[2].shopMessage?.slice(0, 94) + "…"
                          : listComments[2].shopMessage}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {!isCommonMenu && (
                <div className="section-footer text-right under-tab">
                  <Link
                    to={`/shopdetails/${shopId}/comments`}
                    className="link text-underline"
                  >
                    最近のコメントもっと見る
                  </Link>
                </div>
              )}
            </section>
          )}

          {!isCommonMenu && (
            <InfoBox
              loading={!!menuDetails.menuId}
              prefectures={menuDetails.prefectures}
              city={menuDetails.city}
              genre={menuDetails.menuGenre}
              priceRanges={menuDetails.priceRanges}
              menuSituations={menuDetails.menuSituations}
              menuUserTargets={menuDetails.menuUserTargets}
              shopName={shopDetails.shopName}
            />
          )}

          {!isCommonMenu && shopId !== "group-menu" && (
            <ShopDetails
              hideShopInfo
              shopDetails={shopDetails}
              shopId={shopId}
            />
          )}
        </div>
        <aside>
          {/*同じエリアの店舗*/}
          <ListMenus data={listShopSameCity} title="同じエリアの店舗" />
          {/*同じエリアの店舗*/}
          {/*同じエリアのギフト商品*/}
          <ListMenus
            swiperId="list-menu-same-city-container"
            title="同じエリアのギフト商品"
            data={listMenuSameCity.map((menu) => ({
              ...menu,
              price: menu.lastPrice,
            }))}
          />
          {/*同じエリアのギフト商品*/}

          <ListMenus
            swiperId="list-recently-view-menu-container"
            title="ごちめしで最近見た商品・お食事券"
            data={recentlyViewedMenus.map((menu) => ({
              ...menu,
              price: menu.lastPrice,
            }))}
          />
        </aside>

        <Explanation />
      </main>

      <Footer />

      <ModalGroup>
        <SituationModal />
        <GiftToModal />
        <ListShopModal
          totalResults={totalResults}
          listShops={listShops}
          loginUserId={loginUserId}
          distance="distance"
          page={page + 1}
          latitude={latitude}
          longitude={longitude}
          menuId={menuId}
          setKeyword={setKeyword}
          keyword={keyword}
          ShowListShop={showListShop}
          hasMore={hasMore}
          loadMore={loadMore}
          loading={loadingShops}
        />
        <NotLoggedModal />
        <WishListModal />
      </ModalGroup>
    </>
  );
}

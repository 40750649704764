import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { showError } from "../../../actions/action_error_alert";
import { showLoading } from "../../../actions/action_loading";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import CampaignService from "../../../api/services/CampaignService";
import { HTTP_API } from "../../../api/HttpClient";

export function BookingTicket(props) {
  const { t } = props;
  const match = useRouteMatch();
  const campaignId = match.params.campaignId;
  const currentDate = new Date().toISOString().split("T")[0];
  const currentMonth =
    currentDate.split("-")[0] + "-" + currentDate.split("-")[1];
  const maxMonth =
    parseInt(currentDate.split("-")[0]) + 1 + "-" + currentDate.split("-")[1];
  const [month, setMonth] = useState(currentMonth);
  const [numberRemaining, setNumberRemaining] = useState("0");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [numberEmailError, setNumberEmailError] = useState(1);
  const [policeCheck, setPoliceCheck] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isCampaign, setIsCampaign] = useState(false);
  useEffect(() => {
    if (month === "") {
      setNumberRemaining("-");
    } else {
      getNumberRemaining();
    }
  }, [month]);

  useEffect(() => {
    valid();
  }, [numberEmailError, numberRemaining]);

  function validEmail() {
    let value = String(email).toLowerCase().trim();
    if (value === "") {
      setEmailError("必須項目です。");
      return setNumberEmailError(1);
    }
    if (
      !/^(("[\w+-\s]+")|([\w+-]+(?:\.[\w+-]+)*)|("[\w+-\s]+")([\w+-]+(?:\.[\w+-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(
        value
      )
    ) {
      setEmailError("メールアドレスの形式が正しくありません。");
      return setNumberEmailError(1);
    }
    setEmailError("");
    setEmail(value);
    return setNumberEmailError(0);
  }

  function valid() {
    if (
      numberEmailError === 0 &&
      numberRemaining != 0 &&
      numberRemaining !== "-"
    ) {
      setPoliceCheck(true);
    } else {
      setPoliceCheck(false);
    }
  }

  async function getNumberRemaining() {
    const data = await CampaignService.getNumberRemaining(campaignId, month);
    if (data.status === HTTP_API.SUCCESS) {
      let total = data.data.total;
      let numberBooking = data.data.numberBooking;
      setNumberRemaining("" + (total - numberBooking));
      setIsCampaign(true);
    } else {
      if (data.data.code === "exception.errors.gochi.campaign.not.found") {
        window.location.href = "/404"
      } else {
        setNumberRemaining("-");
      }
    }
  }

  async function createCampaignBooking() {
    props.showLoading(true);
    const campaignBooking = {
      email: email,
      month: month,
    };
    const data = await CampaignService.createCampaignBooking(
      campaignId,
      campaignBooking
    );
    props.showLoading(false);
    if (data.status === HTTP_API.SUCCESS) {
      setIsSuccess(true);
    } else {
      props.showError("Error", data.message);
    }
  }

  return (
    <>
      {isCampaign ? (
        <>
          <div className="header">
            <div
              className={
                props.usingFlow ? "header-row below-line" : "header-row"
              }
            >
              <div className="d-flex w-100">
                <div className="d-flex justify-content-center align-items-center">
                  <i className="icon-service" />
                </div>
              </div>
            </div>
          </div>
          <main>
            {isSuccess ? (
              <div className="dish-infor mt-3">
                <label className="ttl-label">
                  OK
                  {/* {t("pages.invite.Email address")} */}
                  {/* <span className="required-tag">{t('other.requiredTag')} </span> */}
                </label>
              </div>
            ) : (
              <div className="input-infor">
                <div className="dish-infor mt-3">
                  <label className="ttl-label">
                    Month
                    {/* {t('pages.invite.Customer name')} */}
                    {/* <span className="required-tag">{t('other.requiredTag')} </span> */}
                  </label>

                  <div className="d-flex align-items-center">
                    <input
                      className="form-control"
                      type="month"
                      value={month}
                      min={currentMonth}
                      max={maxMonth}
                      onChange={(e) => {
                        setMonth(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="dish-infor mt-3">
                  <p className="ttl-label">
                    Number remaining
                    {/* {t('pages.invite.Customer name')} */}
                    {/* <span className="required-tag">{t('other.requiredTag')} </span> */}
                  </p>

                  <div className="d-flex align-items-center">
                    <span className="ttl-label text-danger">
                      {numberRemaining}
                    </span>
                  </div>
                </div>
                <div className="dish-infor mt-3">
                  <label className="ttl-label">
                    {t("pages.invite.Email address")}
                    {/* <span className="required-tag">{t('other.requiredTag')} </span> */}
                  </label>

                  <input
                    className="form-control"
                    placeholder={t("pages.invite.Placeholder email address")}
                    type="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    name="customerEmail"
                    onBlur={validEmail}
                  />

                  {emailError && (
                    <div className="text-danger fs-14 mb-2 fs-12 box-warning-message">
                      {emailError}
                    </div>
                  )}
                </div>

                <div className="mt-4 text-center">
                  <button
                    className={`btn btn-green-38 ${
                      policeCheck ? "" : "disabled-btn"
                    }`}
                    onClick={createCampaignBooking}
                  >
                    {t("pages.login.loginAsGuest")}
                  </button>
                </div>
              </div>
            )}
          </main>
        </>
      ) : null}
    </>
  );
}

const mapStateToProps = () => ({
  showLoading,
  showError,
});

const mapDispatchToProps = (dispatch) => ({
  showError: (title, content) => dispatch(showError(true, title, content)),
  showLoading: (showing) => dispatch(showLoading(showing)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(BookingTicket));

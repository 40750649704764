import React, { useEffect, useState } from "react";
import MogugoriService from "../../../api/services/MogugoriService";
import { GenreInfoShop } from "./GenreInfoShop";

export function ListGenrus(props) {
  const shopId = props.shopId;
  const shopName = props.shopName;
  const [listGenres, setListGenres] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getListGenres();
  }, [shopId]);

  function getListGenres() {
    setLoading(true);
    MogugoriService.getListGenreOfShop(shopId)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          setListGenres(response.data.listGenre);
        } else if (response.status === 404) {
          window.location.href = "/404";
        } else if (response.status === 500) {
          alert("エラーが発生しました。");
        }
      })
      .catch((e) => {
        setLoading(false);
        console.error("GET LIST GENRES ERROR: ", e);
      });
  }

  return (
    <>
        <section className={listGenres !== null ? "section" : "section loading"}>
            <div className={`list-genru ${loading ?  "loading" : ""}`}>
            {listGenres?.map((item) => (
                <GenreInfoShop key={item.mogugoriGenreId} genre={item}/>
            ))}
            </div>
        </section>  
    </>
  );
}

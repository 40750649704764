import React, { useEffect } from "react";
import MetaTags from 'react-meta-tags';
import {
  useScript,
  useStyleSheet,
  useUserTargets,
} from "../../../../utils";
import { Explanation } from "../../layout/Explanation";
import { Footer } from "../../layout/Footer";
import Header from "../../layout/Header";

export function MenuPurposeWho(props) {
  useStyleSheet("https://unpkg.com/swiper/swiper-bundle.min.css",
    null,
    {
      integrity:
        "sha384-HUXJxzkMpCxEdXkgl8BSjN5SUcUODepsOLIYIWOtKva+Qo2teMmV1mh6ZVwnthaV",
      crossorigin: "anonymous",
      referrerpolicy: "no-referrer",
    }
  );
  useScript("https://unpkg.com/swiper/swiper-bundle.min.js",
    null,
    { integrity: "sha384-XVTmRHMO2XUTzy8IerJVEy8ddg47Yz5pZrLc7nEWipaTowqT1xNb8qTLaw64Bd4S",
      crossorigin: "anonymous"
    }
  );
  useScript("/assets/js/app.js");

  const listUserTargets = useUserTargets();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.className = "menu-purpose";
  }, []);
  return (
    <>
      <MetaTags>
        <title>贈り先一覧｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content="贈り先一覧です。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content="贈り先一覧｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta name="twitter:description" content="贈り先一覧です。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:image" content={window.location.origin + "/assets/img/common/ogp.png"}  />
        <meta name="twitter:site" content="@gochi_meshi" />
        <meta name="twitter:creator" content="@gochi_meshi" />
        <meta name="twitter:image:width" content="300" />
        <meta name="twitter:image:height" content="300" />
        <meta property="og:site_name" content="贈り先一覧｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:title" content="贈り先一覧｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={window.location.origin + "/assets/img/common/ogp.png"} />
        <meta property="og:description" content="贈り先一覧です。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
      </MetaTags>
      <Header />

      <main>
        <div className="breadcrumb">
          <div className="container">
            <ol className="breadcrumb-list">
              <li>
                <a href="/">ごちめし</a>
              </li>
              <li>
                <span>商品の贈り先一覧</span>
              </li>
            </ol>
          </div>
        </div>
        <div className="container">
          <section className="section">
            <div className="section-header">
              <div className="heading-lg">
                <h1 className="title">贈り相手でプレゼント・ギフトを探す</h1>
              </div>
            </div>
            <div className="section-body">
              <div className="btn-group menu-purpose-btn-group">
                <a
                  className="btn btn-rect btn menu-purpose-btn"
                  href="/products-purpose/scenes"
                >
                  シチュエーションで探す
                </a>
                <a
                  className="btn btn-rect btn menu-purpose-btn"
                  href="/products-purpose/genres"
                >
                  ジャンルで探す
                </a>
                <a
                  className="btn btn-rect btn menu-purpose-btn"
                  href="/products-purpose/prices"
                >
                  予算で探す
                </a>
                <a
                  className="btn btn-rect btn menu-purpose-btn btn-outline"
                  href="/products-purpose/who"
                >
                  贈り相手で探す
                </a>
              </div>
              <ul className="link-list">
                {listUserTargets &&
                  listUserTargets.length > 0 &&
                  listUserTargets.map((item, key) => {
                    let index = `situation-${item.menuUserTargetId}-${key}`;
                    let link = `/products?who=${item.name}`;
                    return (
                      <li key={index}>
                        <a href={link}>{item.name}</a>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </section>
        </div>

        <Explanation />
      </main>

      <Footer />
    </>
  );
}

const stateDefault = { 
};

export default (state = stateDefault, action) => { 
  switch (action.type){
    case 'SHOP_INFO_SAVE':
      return {
        ...state, 
        ...action.shopInfo
      };
    case 'SAVE_FEE_RATE_MENU_PRICE':
      return {
        ...state,
        feeRate: action.feeRate
      };
    case 'UPDATE_MENU_PRICE_INFO':
      return {
        ...state, 
        updatedMenuPriceInfo: action.updatedMenuPriceInfo
      };
    case 'SAVE_TAX_RATE':
      return {
        ...state, 
        taxRate: action.taxRate
      };
    case 'SAVE_INIT_AMOUNT_MENU_PRICE':
      return {
        ...state, 
        initTotalAmount: action.totalAmount
      };
    case 'SAVE_INIT_MENU_PRICE':
      return {
        ...state, 
        includedTaxMenuPrice: action.initMenuPrice
      };
    case 'SAVE_INIT_VALUE_INPUT':
      return {
        ...state, 
        initAmount: action.initAmount
      };
    default:
      return state;
  }
};


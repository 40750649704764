import {HTTP_API, makeRequest} from "../HttpClient";
import queryString from 'query-string';
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : '';
const API_STRIPE_URL = process.env.REACT_APP_STRIPE_API ? process.env.REACT_APP_STRIPE_API : '';

export default class MenuService {
  static async getDetailMenu(menuId, shopId, gochiUserId, ignoreShowHide, bookingCampaign, accessToken, checkTokenExpired) {
    let  params = [];
    if (ignoreShowHide) {
      params.push(`ignoreShowHide=${ignoreShowHide}`);
    }
    if (menuId) {
      params.push(`menuId=${menuId}`);
    }
    if (shopId) {
      params.push(`shopId=${shopId}`);
    }
    if (gochiUserId) {
      params.push(`gochiUserId=${gochiUserId}`);
    }
    if (bookingCampaign) {
      params.push(`${bookingCampaign}`);
    }
    var url = `${API_BASE_URL}/childShop/menus/${menuId}?${params.join("&")}`; 
    return makeRequest(HTTP_API.GET, url, null, null, null, null, null, null, accessToken, checkTokenExpired);
  }

  static async getTaxRate(){   
    var url = `${API_BASE_URL}/tax`; 
    return makeRequest(HTTP_API.GET, url);
  }

  static async getFeeRate(){   
    var url = `${API_BASE_URL}/fee`; 
    return makeRequest(HTTP_API.GET, url);
  }

  static async getFeeGiGiRate(){
    var url = `${API_BASE_URL}/fee_gigi`;
    return makeRequest(HTTP_API.GET, url);
  }

  static async getDiscountRate(){
    var url = `${API_BASE_URL}/discount`;
    return makeRequest(HTTP_API.GET, url);
  }

  static async createPaymentMethod(guid, muid, card){
    var params = { guid, muid, card};
    var url = `${API_STRIPE_URL}/tokens`;
    return makeRequest(HTTP_API.POST, url, params);
  };

  static async createInvite(inviteContent, crfsToken){
    var params = inviteContent;
    var url = `${API_BASE_URL}/v3/gochi`;
    return makeRequest(HTTP_API.POST, url, params, false, {'csrf_token': crfsToken});
  };

  static async create3dsCheckout(inviteContent) {
    var params = inviteContent;
    var url = `${API_BASE_URL}/gochi/3d-secure/checkout`;
    return makeRequest(HTTP_API.POST, url, params);
  };

  static async createSession(inviteContent){
    var params = inviteContent;
    var url = `${API_BASE_URL}/v3/gochi/session/create`;
    return makeRequest(HTTP_API.POST, url, params);
  };

  static async completeCheckout(merchantPaymentId, params){
    var url = `${API_BASE_URL}/v3/gochi/session/complete/${merchantPaymentId}`; 
    return makeRequest(HTTP_API.GET, url, params);
  };

  // API thanh toán tiền chênh gochi mới
  static async chargeAddedAmount(params, crfsToken){
    var url = `${API_BASE_URL}/addPayment`;
    return makeRequest(HTTP_API.POST, url, params, false, {'csrf_token': crfsToken});
  };

  static async createSessionAddedAmount(params){
    var url = `${API_BASE_URL}/addPayment/session/create`;
    return makeRequest(HTTP_API.POST, url, params);
  };

  static async completeCheckoutAddedAmount(merchantPaymentId){
    var url = `${API_BASE_URL}/addPayment/session/complete/${merchantPaymentId}`; 
    return makeRequest(HTTP_API.GET, url);
  };

  static async getMenuIdNCount(requestCode){
    var url = `${API_BASE_URL}/suntory/redirect/getMenu?id=${requestCode}`;
    return makeRequest(HTTP_API.GET, url);
  }

  static async createPaypayQrCode(inviteContent){
    var params = inviteContent;
    var url = `${API_BASE_URL}/paypay/create`;
    return makeRequest(HTTP_API.POST, url, params);
  };

  static async completePaypayCheckout(merchantPaymentId){
    var params = merchantPaymentId;
    var url = `${API_BASE_URL}/paypay/confirm`; 
    return makeRequest(HTTP_API.POST, url, params);
  };

  static async getMessages(menuId, couponMessageSent, page, limit) {
    var url = `${API_BASE_URL}/coupons?menuId=${menuId}&couponMessageSent=${couponMessageSent}&page=${page}&limit=${limit}`;
    return makeRequest(HTTP_API.GET, url);
  }

  static async getMenuCombo(menuId, gochiUserId, gochiBillId, accessToken, checkTokenExpired) {
    var url = `${API_BASE_URL}/gochi/details/useMenuCombo?menuId=${menuId}&gochiUserId=` + gochiUserId;
    if (gochiBillId) {
      url += '&gochiBillId=' + gochiBillId;
    }
    return makeRequest(HTTP_API.GET, url, null, null, null, null, null, null, accessToken, checkTokenExpired)
  }

  static async getMenuLimitTotal(menuId, gochiUserId, gochiBillId, accessToken, checkTokenExpired) {
    var url = `${API_BASE_URL}/gochi/details/useMenu?menuId=${menuId}&gochiUserId=` + gochiUserId;
    if (gochiBillId) {
      url += '&gochiBillId=' + gochiBillId;
    }
    return makeRequest(HTTP_API.GET, url, null, null, null, null, null, null, accessToken, checkTokenExpired)
  }

  static async sendSms(gochiBillId, phoneNumber, invitationCode) {
    var url = `${API_BASE_URL}/gochi/inviteUrl/sms/send`;
    return makeRequest(HTTP_API.POST, url, {
      gochiBillId,
      phoneNumber,
      invitationCode: invitationCode
    });
  }

  static async getSurvey(params) {
    var url = `${API_BASE_URL}/survey?${queryString.stringify(params)}`;
    return makeRequest(HTTP_API.GET, url)
  }

  static async postSurveyAnswer(surveyId, answers, gochiBillId) {
    const url = `${API_BASE_URL}/survey/answer`;
    return makeRequest(HTTP_API.POST, url, {
      surveyId,
      gochiBillId,
      answers
    });
  }

  static async updateSurveyGochiBillId(surveySubmissionId, gochiBillId) {
    const url = `${API_BASE_URL}/survey/answer`;
    return makeRequest(HTTP_API.PUT, url, {
      surveySubmissionId,
      gochiBillId
    });
  }

  static async postImage(image, surveyId, folder, userId) {
    const url = `${API_BASE_URL}/upload/image`;
    var formData = new FormData();
    formData.append("imageFile", image);
    formData.append("surveyId", surveyId);
    if (folder) {
      formData.append("folder", folder);
    }
    if (userId) {
      formData.append("userId", userId);
    }
    return axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    });
  }

  static async createInviteNoPayment(inviteContent, crfsToken) {
    var params = inviteContent;
    var url = `${API_BASE_URL}/v3/gochiWithoutCard`;
    return makeRequest(HTTP_API.POST, url, params, false, {'csrf_token': crfsToken});
  };

  static async createCharge(inviteContent, crfsToken){
    var params = inviteContent;
    var url = `${API_BASE_URL}/gochi-charge/charge`;
    return makeRequest(HTTP_API.POST, url, params, false, {'csrf_token': crfsToken});
  };

    static async createChargeSession(inviteContent){
    var params = inviteContent;
    var url = `${API_BASE_URL}/gochi-charge/session/create`;
    return makeRequest(HTTP_API.POST, url, params);
  };

  static async completeChargeCheckout(merchantPaymentId, params){
    var url = `${API_BASE_URL}/gochi-charge/session/complete/${merchantPaymentId}`; 
    return makeRequest(HTTP_API.GET, url, params);
  };
}

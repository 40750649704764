import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next'; 
import queryString from 'query-string';
import {connect} from 'react-redux'; 
import { withRouter, useHistory, useLocation } from "react-router-dom";

import * as constants from '../../../constants';
import {addParamUrl, log} from '../../../utils'; 
import {CopyToClipboard} from 'react-copy-to-clipboard';  
import MenuService from "../../../api/services/MenuService";
import {selectFriend} from "../../../actions/action_select_friend"; 
import {saveInvite} from "../../../actions/action_menu";
import {showLoading} from "../../../actions/action_loading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import {
	FacebookShareButton,
	LineShareButton,
	TwitterShareButton,
	// FacebookIcon,
	// LineIcon,
	// TwitterIcon
} from "react-share";
import { faFacebook, faTwitter, faLine } from '@fortawesome/free-brands-svg-icons';
import {showError} from "../../../actions/action_error_alert";
import Header from "../../layout/header/Header";
import SmsInput from "./SmsInput";
import PopupConfirm from "../../common/PopupConfirm";
import moment from "moment";


class InviteFinish extends Component {
	constructor(props){
		super(props);
		this.state = {
			menuId: null,
			copied: false,
			loadUrl: ((JSON.parse(sessionStorage.getItem('fullInviteContent')) && !JSON.parse(sessionStorage.getItem('fullInviteContent')).inviteUrl) || (this.props.menuInfo.inviteContent && !this.props.menuInfo.inviteContent.inviteUrl)) || !JSON.parse(sessionStorage.getItem('fullInviteContent')) ? true : false,
			inviteUrl: JSON.parse(sessionStorage.getItem('fullInviteContent')) && JSON.parse(sessionStorage.getItem('fullInviteContent')).inviteUrl ? JSON.parse(sessionStorage.getItem('fullInviteContent')).inviteUrl : (this.props.menuInfo.inviteContent && this.props.menuInfo.inviteContent.inviteUrl ? this.props.menuInfo.inviteContent.inviteUrl : null),
			gochiCode: JSON.parse(sessionStorage.getItem('fullInviteContent')) && JSON.parse(sessionStorage.getItem('fullInviteContent')).gochiCode ? JSON.parse(sessionStorage.getItem('fullInviteContent')).gochiCode : null,
			subscriptionInfo: JSON.parse(sessionStorage.getItem('fullInviteContent')) && JSON.parse(sessionStorage.getItem('fullInviteContent')).subscriptionDto ? JSON.parse(sessionStorage.getItem('fullInviteContent')).subscriptionDto : null,
			loading: true,
			paymentType: sessionStorage.getItem('selectPayment') ? parseInt(sessionStorage.getItem('selectPayment')) : null,
			paypayResponse: sessionStorage.getItem('paypayResponse') ? JSON.parse(sessionStorage.getItem('paypayResponse')) : '',
			stripeResponse: sessionStorage.getItem('fullInviteContent') ? JSON.parse(sessionStorage.getItem('fullInviteContent')) : '',
			userName: '',
			shopId: null,
			popupShowing: false,
			popupTitle: '',
			popupContent: this.props.t('pages.invite.please try reloading with the retry button'),
			isMogugori: false,
			showPage: false
		}
		this.onCopy = this.onCopy.bind(this);
		this.goBack = this.goBack.bind(this);
		this.handlePopupConfirm = this.handlePopupConfirm.bind(this);

	  	let url = window.location.search;
		let params = queryString.parse(url);
		// var redirectFromStripe = params.redirectFromStripe;
		// var redirectFromPaypay = params.redirectFromPaypay;
		var merchantPaymentId = params.merchantPaymentId || JSON.parse(sessionStorage.getItem('fullInviteContent')).merchantPaymentId;
		const gochiBillId = JSON.parse(sessionStorage.getItem('fullInviteContent'))?.gochiBillId || JSON.parse(sessionStorage.getItem('paypayResponse'))?.gochiBillId;
		this.state.paymentGateway = params.paymentGateway;

		const completeInviteParams = {
			checkoutId: params.checkoutId
		};
		if (
			sessionStorage.getItem("emailBookingCampaign") &&
			sessionStorage.getItem("secretCode") &&
			sessionStorage.getItem("monthBookingCampaign") &&
			sessionStorage.getItem("campaignId")
		) {
			completeInviteParams.emailBookingCampaign = sessionStorage.getItem("emailBookingCampaign");
			completeInviteParams.secretCode = sessionStorage.getItem("secretCode");
			completeInviteParams.monthBookingCampaign = sessionStorage.getItem("monthBookingCampaign");
			completeInviteParams.campaignId = sessionStorage.getItem("campaignId");
		}

		if (merchantPaymentId && !gochiBillId) {
			this.state.inviteUrl = null;
			this.state.loadUrl = true;
			setTimeout(() => {
				this.completeInvite(merchantPaymentId, completeInviteParams);
			}, 300);
		} else {
			this.state.showPage = true;
			const surveySubmissionId = JSON.parse(sessionStorage.getItem('fullInviteContent'))?.surveySubmissionId;
			if (surveySubmissionId && gochiBillId) MenuService.updateSurveyGochiBillId(surveySubmissionId, gochiBillId);
		}
		
		sessionStorage.removeItem('messageCard');
	}
	onCopy(e){
		if (this.state.inviteUrl)
	    this.setState({copied: true});
	};

	onClick(e){
		// window.open('https://www.sakimeshi.com','_blank');
		window.location.href='https://www.sakimeshi.com';
		var _paq = window._paq = window._paq || [];
		// / tracker methods like "setCustomDimension" should be called before "trackPageView" /
		_paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
		_paq.push(["setCookieDomain", "*.web-sakimeshi.app-gochimeshi.com"]);
		_paq.push(["setDomains", ["*.web-sakimeshi.app-gochimeshi.com"]]);
		// _paq.push(["setCookieDomain", "*.stgweb-suntory.app-gochimeshi.com"]);
		// _paq.push(["setDomains", ["*.stgweb-suntory.app-gochimeshi.com"]]);
		// _paq.push(["setCookieDomain", "*.localhost:3000"]);
		// _paq.push(["setDomains", ["*.localhost:3000"]]);
		// you can set up to 5 custom variables for each visitor
		_paq.push(["setCustomVariable", 1, "\u30b5\u30a4\u30c8TOP\u3078\u623b\u308b", "https://www.sakimeshi.com", "visit"]);
		_paq.push(['trackPageView']);
		_paq.push(['enableLinkTracking']);
		_paq.push(['trackLink', 'https://www.sakimeshi.com', 'link']);
		// (function() {
		// 	var u="//matomo.1steam.com/";
		// 	_paq.push(['setTrackerUrl', u+'matomo.php']);
		// 	// _paq.push(['setSiteId', '3']);
		// 	_paq.push(['setSiteId', '4']);
		// 	var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
		// 	g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
		// })();
	};

	UNSAFE_componentWillMount(){ 
		let url = window.location.search;
		let params = queryString.parse(url);
		var menuId = params.id;
		var shopId = params.shopId;
		if (params.userName) {
			this.setState({
				userName: params.userName
			});
		} 

		this.setState({
			menuId: menuId
		});

		if(shopId) {
			this.setState({
				shopId: shopId
			});
		}

		// Scroll to top when show page
		window.scrollTo(0,0);
	}

	componentDidMount(){ 
		let url = window.location.search;
		let params = queryString.parse(url);
		var redirectFromStripe = params.redirectFromStripe;
		var isSite = params.isSite;

		if (isSite === 'mogugori') {
			this.setState({
				isMogugori: true
			});
		}

		if (redirectFromStripe) {
			// var inviteContent = JSON.parse(sessionStorage.getItem('inviteContent'));
			var fullInviteContent = JSON.parse(sessionStorage.getItem('fullInviteContent')); 
 			
 			if (fullInviteContent) { 
 				var friendInfo = {
 					situation: fullInviteContent.situation
 				}
 				this.props.selectFriend(null, null, friendInfo, fullInviteContent.gochiUserType)  
 			}
			// if (inviteContent) {
			// 	if (fullInviteContent) {
			// 		if (!fullInviteContent.completed) {
			// 			this.completeInvite(inviteContent);
			// 		}
			// 	}
			// }
		}
		sessionStorage.removeItem("messageShop");

		// this.pageLoad();
		
	}

	queryStringToJSON(queryString) {
		if(queryString.indexOf('?') > -1){
		  queryString = queryString.split('?')[1];
		}
		var pairs = queryString.split('&');
		var result = {};
		pairs.forEach(function(pair) {
		  pair = pair.split('=');
		  result[pair[0]] = decodeURIComponent(pair[1] || '');
		});
		return result;
	}

	async completeInvite(merchantPaymentId, completeInviteParams) {  
		const params = queryString.parse(window.location.search);
		const isAdmin = params.admin;
	    try {
	      	if (merchantPaymentId) {
				// If payment gateway is Onepay, re-call API complete until response status is not 404 or timeout (5 * 10 = 50 seconds)
				let result;
				for (let i = 0; i < 5; i++) {
					result = await MenuService.completeCheckout(merchantPaymentId, completeInviteParams);
					if (result.status !== 404) break;
					await new Promise(r => setTimeout(r, 10000));
				}
				let showPage = true;
		        if (result.status === 200) {
		        	this.props.saveInvite(result.data);
		        	let fullInviteContent = result.data;
					// TODO: remove the next line
			        fullInviteContent.completed = true;
					// TODO: remove the next line
			        fullInviteContent.inviteUrl = result.data.inviteUrl;
			        fullInviteContent.menuName = this.props.menuInfo.menuName;
					fullInviteContent.description = this.props.menuInfo.description;
					const surveySubmissionId = fullInviteContent.surveySubmissionId
							? fullInviteContent.surveySubmissionId
							: JSON.parse(sessionStorage.getItem('fullInviteContent'))?.surveySubmissionId;
					if (surveySubmissionId && fullInviteContent.gochiBillId) {
						MenuService.updateSurveyGochiBillId(surveySubmissionId, fullInviteContent.gochiBillId);
					}
					sessionStorage.removeItem("fullInviteContent");
					sessionStorage.setItem('fullInviteContent', JSON.stringify(fullInviteContent));
					
					this.setState({
						inviteUrl: result.data.inviteUrl,
						subscriptionInfo: result.data.subscriptionDto
					});

					this.setState({
						gochiCode: result.data.gochiCode
					});

					this.setState({
						userName: result.data.userName
					});

					// TODO: Remove action selectFriend
			        var friendInfo = {
								situation: fullInviteContent.situation
							}
					this.props.selectFriend(null, null, friendInfo, fullInviteContent.gochiUserType);
					// TODO: remove the next line
					sessionStorage.removeItem("srcGmb");
					this.setState({
						loadUrl: false
					});

					if (isAdmin) {
						this.props.showError(true, this.props.t('pages.invite.gen url success'), "");
					}
					// sessionStorage.removeItem("selectPayment");
					
					// set flag bookingCampaignFinish when gen gochi success
					var bookingCampaignFinish = sessionStorage.getItem("emailBookingCampaign") && sessionStorage.getItem("secretCode") && sessionStorage.getItem("monthBookingCampaign") && sessionStorage.getItem("campaignId") ? 1 : 0;
					sessionStorage.setItem("bookingCampaignFinish", bookingCampaignFinish);

				} else if (result.status === 402) {
					showPage = false;
					let messageErr = '3Dセキュア支払い実行時にエラーが発生しました。\nGOCHIプラットフォームのクレジット支払いは 3Dセキュア対応のカードのみとなっています。\n他の支払い手段をお試し下さい。\n3Dセキュア対応に関する詳細は カード発行会社に問い合わせ下さい。';
					if (result.data) {
						if (result.data.stripe_code === 'expired_card') {
							messageErr = this.props.t('pages.invite.error create gochi using stripe incase expired card');
						} else if (result.data.stripe_code === 'amount_too_small') {
							messageErr = this.props.t('pages.invite.error create gochi using stripe incase amount to small');
						} else if (result.data.stripe_code === 'insufficient_funds') {
							messageErr = this.props.t('pages.invite.error create gochi using stripe incase insufficient funds');
						} else if (result.data.stripe_code === 'card_velocity_exceeded') {
							messageErr = this.props.t('pages.invite.error create gochi using stripe incase card velocity exceeded');
						} else if (/^G.{3}AG6400000000$/.test(result.data.veritrans_error_code)) {
							messageErr = 'クレジットカード支払いに失敗しました。有効期限エラーです。';
						} else if (/^G.{3}AG4400000000$/.test(result.data.veritrans_error_code)) {
							messageErr = 'クレジットカード支払いに失敗しました。1口座利用回数または金額オーバーです。';
						} else if (/^G.{3}AG4500000000$/.test(result.data.veritrans_error_code)) {
							messageErr = 'クレジットカード支払いに失敗しました。1日限度額オーバーです。';
						} else if (/^G.{3}AG5100000000$/.test(result.data.veritrans_error_code)) {
							messageErr = 'クレジットカード支払いに失敗しました。金額エラーです。';
						} else if (/^G.{3}AG3300000000$/.test(result.data.veritrans_error_code)) {
							messageErr = 'クレジットカード支払いに失敗しました。カード使用不可です。';
						}
					}
					// this.props.showError(true, messageErr, "");
					this.setState({
						errorMessage: messageErr
					});
				} else if (this.state.paymentGateway === 'onepay' || result.status === 404) {
					this.setState(state => ({
						loadUrl: false,
						popupShowing: true,
					}))
				} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.not.found') {
					let messageErr;
					messageErr = this.props.t('pages.bookingCamaign.campaign_not_found');
					this.props.showError(true, messageErr, "");
				} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.total.limited') {
					let messageErr;
					messageErr = this.props.t('pages.bookingCamaign.campaign_total_limited');
					this.props.showError(true, messageErr, "");
				} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.booking.duplicate') {
					let messageErr;
					messageErr = this.props.t('pages.bookingCamaign.campaign_booking_duplicate');
					this.props.showError(true, messageErr, "");
				} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.params.invalid') {
					let messageErr;
					messageErr = this.props.t('pages.bookingCamaign.campaign_params_invalid');
					this.props.showError(true, messageErr, "");
				} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.activecode.invalid') {
					let messageErr;
					messageErr = this.props.t('pages.bookingCamaign.campaign_activecode_invalid');
					this.props.showError(true, messageErr, "");
				} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.booking.not.found') {
					let messageErr;
					messageErr = this.props.t('pages.bookingCamaign.campaign_booking_not_found');
					this.props.showError(true, messageErr, "");
				} else {
					if (JSON.parse(sessionStorage.getItem('fullInviteContent')) && !JSON.parse(sessionStorage.getItem('fullInviteContent')).inviteUrl) {
						this.props.showError(true, this.props.t('pages.invite.error create gochi user'), "");
						this.setState({
							loadUrl: false
						});
					} else {
						this.setState({
							inviteUrl: JSON.parse(sessionStorage.getItem('fullInviteContent')) && JSON.parse(sessionStorage.getItem('fullInviteContent')).inviteUrl ? JSON.parse(sessionStorage.getItem('fullInviteContent')).inviteUrl : null
						})
					}
					if (isAdmin) {
						this.props.showError(true, this.props.t('pages.invite.error create gochi user'), "");
					}
					if (result.status === 404 || result.status === 400 || result.status === 500) {
						this.setState({
							inviteUrl: null
						});
						this.props.showError(true, this.props.t('pages.invite.error create gochi user'), "");
					}
					if (result.status === 504) {
						this.setState({
							inviteUrl: null
						});
						this.props.showError(true, this.props.t('pages.invite.error create gochi with guest'), "");
					}
				}

				this.setState({ showPage });
	      	} else { 
	        	this.setState({
					  errorMessage: "Fail",
					  loadUrl: false
				});
				this.props.showError(true, this.props.t('pages.invite.error create gochi user'), "");
			}
			if (isAdmin){
				this.setState({
					loading: false
				});
			}
	    } catch (error) {
			log(error);
	      	this.setState({
				  errorMessage: "Fail",
				  loadUrl: false
			})
			if (isAdmin){
				this.setState({
					loading: false
				});
			}
			this.props.showError(true, this.props.t('pages.invite.error create gochi user'), "");
	    }  
	}

	// TODO: Remove this function if not use anymore
	async completePaypayInvite(merchantPaymentId) {  
		const isAdmin = queryString.parse(window.location.search).admin;
	    try {
	      	if (merchantPaymentId) {
				this.setState({
					loadUrl: true
				});
				this.setState({
					inviteUrl: null
				});
				
		        const result = await MenuService.completePaypayCheckout(
					{merchantPaymentId: merchantPaymentId}
				);
		        if (result.status === 200) {
					let fullInviteContent = result.data;
					fullInviteContent.menuName = this.state.paypayResponse.menuName;
					fullInviteContent.description = this.state.paypayResponse.description;
					sessionStorage.setItem('fullInviteContent', JSON.stringify(fullInviteContent));
					
					this.setState({
						inviteUrl: result.data.inviteUrl
					});

					this.setState({
						gochiCode: result.data.gochiCode
					});

					this.setState({
						userName: result.data.userName
					});

					sessionStorage.removeItem("srcGmb");
					this.setState({
						loadUrl: false
					});

					if (isAdmin) {
						this.props.showError(true, this.props.t('pages.invite.gen url success'), "");
					}
					// sessionStorage.removeItem("selectPayment");

					// set flag bookingCampaignFinish when gen gochi success
					var bookingCampaignFinish = sessionStorage.getItem("emailBookingCampaign") && sessionStorage.getItem("secretCode") && sessionStorage.getItem("monthBookingCampaign") && sessionStorage.getItem("campaignId") ? 1 : 0;
					sessionStorage.setItem("bookingCampaignFinish", bookingCampaignFinish);

		        } else if (this.state.paymentGateway === 'onepay' || result.status === 404) {
					this.setState(state => ({
						loadUrl: false,
						popupShowing: true,
					}))
				} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.not.found') {
					let messageErr;
					messageErr = this.props.t('pages.bookingCamaign.campaign_not_found');
					this.props.showError(true, messageErr, "");
				} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.total.limited') {
					let messageErr;
					messageErr = this.props.t('pages.bookingCamaign.campaign_total_limited');
					this.props.showError(true, messageErr, "");
				} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.booking.duplicate') {
					let messageErr;
					messageErr = this.props.t('pages.bookingCamaign.campaign_booking_duplicate');
					this.props.showError(true, messageErr, "");
				} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.params.invalid') {
					let messageErr;
					messageErr = this.props.t('pages.bookingCamaign.campaign_params_invalid');
					this.props.showError(true, messageErr, "");
				} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.activecode.invalid') {
					let messageErr;
					messageErr = this.props.t('pages.bookingCamaign.campaign_activecode_invalid');
					this.props.showError(true, messageErr, "");
				} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.booking.not.found') {
					let messageErr;
					messageErr = this.props.t('pages.bookingCamaign.campaign_booking_not_found');
					this.props.showError(true, messageErr, "");
				} else {
					if (JSON.parse(sessionStorage.getItem('fullInviteContent')) && !JSON.parse(sessionStorage.getItem('fullInviteContent')).inviteUrl) {
						this.props.showError(true, this.props.t('pages.invite.error create gochi user'), "");
						this.setState({
							loadUrl: false
						});
					} else {
						this.setState({
							inviteUrl: JSON.parse(sessionStorage.getItem('fullInviteContent')) && JSON.parse(sessionStorage.getItem('fullInviteContent')).inviteUrl ? JSON.parse(sessionStorage.getItem('fullInviteContent')).inviteUrl : null
						})
					}
					if (isAdmin) {
						this.props.showError(true, this.props.t('pages.invite.error create gochi user'), "");
					}
					if (result.status === 404 || result.status === 400 || result.status === 500) {
						this.setState({
							inviteUrl: null
						});
						this.props.showError(true, this.props.t('pages.invite.error create gochi user'), "");
					}
					if (result.status === 504) {
						this.setState({
							inviteUrl: null
						});
						this.props.showError(true, this.props.t('pages.invite.error create gochi with guest'), "");
					}
				}
	      	} else { 
	        	this.setState({
					  errorMessage: "Fail",
					  loadUrl: false
				});
				this.props.showError(true, this.props.t('pages.invite.error create gochi user'), "");
			}
			if (isAdmin){
				this.setState({
					loading: false
				});
			}
	    } catch (error) {
			log(error);
	      	this.setState({
				  errorMessage: "Fail",
				  loadUrl: false
			})
			if (isAdmin){
				this.setState({
					loading: false
				});
			}
			this.props.showError(true, this.props.t('pages.invite.error create gochi user'), "");
	    }  
	}

	handlePopupConfirm = () => {
		let url = window.location.search;
		let params = queryString.parse(url);
		var merchantPaymentId = params.merchantPaymentId;
		this.setState({
			loadUrl: true,
			popupShowing: false
		});
		const completeInviteParams = {
			checkoutId: params.checkoutId
		};
		if (params.emailBookingCampaign && params.secretCode && params.monthBookingCampaign && params.campaignId) {
			completeInviteParams.emailBookingCampaign = params.emailBookingCampaign;
			completeInviteParams.secretCode = params.secretCode;
			completeInviteParams.monthBookingCampaign = params.monthBookingCampaign;
			completeInviteParams.campaignId = params.campaignId;
		}
		this.completeInvite(merchantPaymentId, completeInviteParams);
	}

	handlePopupClose = () => {
		this.setState(state => ({
			popupShowing: false,
		}))
	}

	handleUseGochiNow = () => {
		if (this.state.inviteUrl) {
			window.open(this.state.inviteUrl);
		}
	}

  goHome = () => {
    window.location.href = '/mogugori';
  }

  goMyPage = () => {
    const url = window.location.search;
    const params = queryString.parse(url);
    const isSite = params.isSite;
    const isSiteParam = isSite ? {name: 'isSite', value: isSite} : {};
    window.location.href = addParamUrl("/mypage", [isSiteParam]);
  }

	goBack = () => {
		const url = window.location.search;
		const params = queryString.parse(url);
		let paramUrlBack = [];
		if (params.id) {
			paramUrlBack.push(`id=${params.id}`);
		}
		if (params.shopId) {
			paramUrlBack.push(`shopId=${params.shopId}`);
		}
		if (params.isSite) {
			paramUrlBack.push(`isSite=${params.isSite}`);
		}
		if (params.src) {
			paramUrlBack.push(`src=${params.src}`);
		}
		window.location.href = `/invite?${paramUrlBack.join('&')}`;
	}

  render() {
        var isLoggedIn = false;
        var userInfo = this.props.userInfo;

        if (userInfo) {
            isLoggedIn = userInfo.userId ? true : false;
        }
		const { t } = this.props;
		let url = window.location.search;
		let params = queryString.parse(url); 

		var redirectFromStripe = params.redirectFromStripe;

		var imgMenu = this.props.menuInfo && 
                            this.props.menuInfo.menuImage && 
                            this.props.menuInfo.menuImage[0] && 
                            this.props.menuInfo.menuImage[0].image ? 
                            this.props.menuInfo.menuImage[0].image : 
                            require("../../../assets/img/icons/gochi_noimg-ver4.jpg");
		var menuName ="";
		var description ="";
		var showModal = true;
		var anyon_opt = params.gochiUserType === 'ANYONE';
		var fullInviteContent = JSON.parse(sessionStorage.getItem('fullInviteContent'));
		var gochiBillId;
		var guestInfo = JSON.parse(sessionStorage.getItem('guestInfo'));
		var customerEmail = guestInfo ? guestInfo.customerEmail: '';
		if (redirectFromStripe) {
			anyon_opt = (this.props.friend.gochiUserType === constants.GOCHI_TYPE_ANYONE);
			if (fullInviteContent) {
                imgMenu = this.props.menuInfo && 
                                this.props.menuInfo.menuImage && 
                                this.props.menuInfo.menuImage[0] && 
                                this.props.menuInfo.menuImage[0].image ? 
                                this.props.menuInfo.menuImage[0].image : 
                                    (fullInviteContent.image ? fullInviteContent.image : require("../../../assets/img/icons/gochi_noimg-ver4.jpg"));
				menuName = fullInviteContent.menuName;
				description = fullInviteContent.description;
				anyon_opt = fullInviteContent.gochiUserType === constants.GOCHI_TYPE_ANYONE;
				gochiBillId = fullInviteContent.gochiBillId;
			}
		}else {
			menuName = this.props.menuInfo.menuName && this.props.menuInfo.menuName;
			menuName = this.props.menuInfo.menuName;
			description = this.props.menuInfo.description;
			if (this.props.menuInfo.inviteContent) {
				anyon_opt = this.props.menuInfo.inviteContent.gochiUserType === constants.GOCHI_TYPE_ANYONE;
				if (this.props.menuInfo.inviteContent.gochiBillImage?.length > 0) {
					if (this.props.menuInfo.inviteContent.gochiBillImage[0].image){
						imgMenu = this.props.menuInfo.inviteContent.gochiBillImage[0].image;
					}
					gochiBillId = this.props.menuInfo.inviteContent.gochiBillImage[0].gochiBillId;
				}
			} else {
				if(fullInviteContent){
                    imgMenu = fullInviteContent.gochiBillImage && 
                                (fullInviteContent.gochiBillImage.length > 0) && 
                                fullInviteContent.gochiBillImage[0].image ? 
                                fullInviteContent.gochiBillImage[0].image : 
                                require("../../../assets/img/icons/gochi_noimg-ver4.jpg");
					anyon_opt = (fullInviteContent.gochiUserType === constants.GOCHI_TYPE_ANYONE);
					menuName = fullInviteContent.menuName;
					description = fullInviteContent.description;
					// gochiBillId = fullInviteContent.gochiBillImage[0].gochiBillId;
					gochiBillId = fullInviteContent.gochiBillId;
				}
			}
		}

		let noteFinishInvite = '';
		if (this.props.menuInfo?.noPayment === 1) {
			noteFinishInvite = t('pages.share.Note finish invite no payment bank transfer');
		} else if (this.props.menuInfo?.noPayment === 2) {
			noteFinishInvite = t('pages.share.Note finish invite no payment form submit');
		} else {
			noteFinishInvite = t('pages.share.Note finish invite with url');
		}

		const isNoPayment2 = this.props.menuInfo?.noPayment === 2;

		const imageMenu = this.props.menuInfo && this.props.menuInfo.menuImage && this.props.menuInfo.menuImage[0] && this.props.menuInfo.menuImage[0].image ? this.props.menuInfo?.menuImage[0].image : '';

		if (!this.state.showPage) {
			return (
				<>
					{this.state.errorMessage && (
						<div className="message-error-invite">
							{this.state.errorMessage}
							<button
								className="btn btn-bg-corner w-240 m-auto mt-3-custome"
								onClick={this.goBack}
							>
								他の支払い手段で購入する
							</button>
						</div>
					)}
				</>
			);
		}
		return !params.admin ? (
			<div className="content">
				<Header title=
					{anyon_opt ?
						t('pages.share.Gochi has entered the shop')
					:
						(showModal ?
							<div className="space-pre-line fs-14">
								{t('pages.share.Account Completed Title')}
							</div>
						:
							t('pages.share.I am here')
						)
					} 
					isLoggedIn={isLoggedIn}
					t={t}
					showModal={showModal}
					>
				</Header>
				{
					showModal &&
						<div>
							<div className="mt-3">
								<ul className="progressbar align-center">
									<li className="step-choice"><strong>{t('steps.step_choice')}</strong></li>
									<li className="step-select"><strong>{t('steps.step_select')}</strong></li>
									<li className="step-confirm"><strong>{t('steps.step_confirm')}</strong></li>
									<li className="step-complete active"><strong>{t('steps.step_complete')}</strong></li>
								</ul>
							</div>
							{!!this.state.subscriptionInfo?.createdAt && (
								<div className="mb-2">
									<div className="text-dark fs-18 font-weight-bold text-center">{t('pages.invite.subscription finish title')}</div>
									<div className="text-center fs-14 text-dark">{t('pages.invite.subscription finish message')}</div>
									<div className="mt-3 fs-14 d-flex justify-content-between">
										<span className="text-dark font-weight-bold">{t('pages.invite.subcription date')}</span>
										<span className="text-dark">{moment(this.state.subscriptionInfo?.createdAt).format('YYYY.MM.DD')}</span>
									</div>
									<div className="d-flex fs-14 justify-content-between">
										<span className="text-dark font-weight-bold">{t('pages.invite.subscription interval')}{t('pages.invite.colon')}</span>
										<span className="text-dark">{`${t('pages.invite.per')}${this.state.subscriptionInfo.subscriptionIntervalCount}${
								this.state.subscriptionInfo.subscriptionInterval === 'WEEK' ? t('pages.invite.week') : t('pages.invite.month')
								}${t('pages.invite.one time')}`}</span>
									</div>
								</div>
							)}
							{!anyon_opt && <SmsInput t={this.props.t}
								gochiBillId={gochiBillId}
								email={customerEmail}
								inviteUrl={this.state.inviteUrl}/>}
							{!anyon_opt &&
							<div
								className="fs-18 font-weight-bold clr-000000 space-pre-line text-center complete-note"
								dangerouslySetInnerHTML={{
									__html: noteFinishInvite,
									interpolation: {escapeValue: false}
								}}
							>
								{/* {t('pages.share.Note finish invite with url')} */}
							</div>
  							}
							{!anyon_opt && this.state.gochiCode &&
							<div className="fs-18 font-weight-bold space-pre-line text-center gochi-code">
								{t('pages.share.gochiCode')}: {this.state.gochiCode}
							</div>
  							}
							<div className="mt-3 text-center ">
								<div className="input-copy">
									{!anyon_opt ?
									<>
										<div className="w-100 position-relative">
											{this.state.copied &&
											<div className="copied-tag">{t('pages.mypage.Copied')}</div>
											}
											<input className="form-control text-center fs-14 clr-636363" readOnly 
														defaultValue={this.state.inviteUrl}
											/>
											{this.state.loadUrl ?
											<div className="loading-url">
												<FontAwesomeIcon icon={faSpinner} className="" />
												<span className="loading-url-text">{t('pages.invite.loading')}</span>
											</div>
											: null}
										</div>
										<div className="mt-2">
											<CopyToClipboard
												onCopy={this.onCopy}
												text={this.state.inviteUrl}
											>
												<button className="btn btn-green-38 ml-auto mr-auto" onCopy={this.onCopy} disabled={!this.state.inviteUrl}>
													{t('pages.share.Copy link')}
												</button>
											</CopyToClipboard>
										</div>
										{!isNoPayment2 && <button className="btn btn-green-38 mt-3 align-center" onClick={this.handleUseGochiNow} disabled={!this.state.inviteUrl}>
											{t('pages.invite.use ticket now')}
										</button>}
										<div>
											<div className="fs-13 mt-3">
												{t('pages.share.Des finish')}
											</div>
											<div className="fs-13 clr-ec4a56">
												{t('pages.share.Note finish')}
											</div>
										</div>
										<div className="mt-1">
											<div className="box_notice_gray txt-indent-10 font-weight-bold">
												{t('pages.share.Notice')}
											</div>
										</div>
										<div className="mt-5">
											<div className="box_notice">
												{/* <div className="title_box_notice">PRESENT</div> */}
												<img
													src={require('../../../assets/img/icons/icon-present-text.png')}
													alt=""
												/>
												<div className="content_box_notice space-pre-line">
													{t('pages.share.Notice present')}
												</div>
												<div className="dot lefttop"></div>
												<div className="dot leftbottom"></div>
												<div className="dot righttop"></div>
												<div className="dot rightbottom"></div>
											</div>
										</div>
									</>
  									: 	<div className="fs-14 font-weight-bold space-pre-line text-center">
									  		{t('pages.invite.note anyone one')}<br/>
									  		{this.state.userName}
									  		{t('pages.invite.note anyone two')}
								  		</div>
								  	}
								</div>
							</div>
						</div>
					}

			    <div className="input-infor">
					<div className="mt-3 image-full">
						<div className="dish-img text-center mt-2">
							<img src={imageMenu ? imageMenu : imgMenu} alt={this.props.menuInfo.menuName} width="340" />
						</div>
					</div>
			    </div>

				<Link 
					to={{ pathname:`https://support.gigi.tokyo/contact/receipt/`}} 
					target="_blank" 
					className="btn btn-link mt-3"
					style={{height: 'auto', lineHeight: '24px'}}
				>
					<span className="btn-back fs-16 clr-000000">
						{t('pages.invite.title not mail')}
					</span>
				</Link>
				{this.state.isMogugori && (
					<div className="mt-3 text-center mb-3 fs-18 clr-000000 font-weight-bold space-pre-line txt-underline-green">
						<button onClick={this.goHome} className="btn btn-green-38 mb-2">
							{t('pages.share.Return to site TOP')}
						</button>
						{isLoggedIn && 
							(<button onClick={this.goMyPage} className="btn btn-green-38 mb-2">
								{t('pages.invite.go to mypage')}
							</button>)
						}
					</div>
				)}
				<div className="mt-3 text-center mb-3 fs-18 clr-000000 font-weight-bold space-pre-line txt-underline-green">{t('pages.share.For everyone For shops')}</div>

			    {showModal &&
				    <div className="modal fade" id="modalSendGochi">
					  	<div className="modal-dialog">
					    	<div className="modal-content">
						    	<div className="modal-body">
						    		<button type="button" className="close" data-dismiss="modal">&times;</button>
								    <div className="heading-page">
											<div className="heading-img text-center">
												<img src={require('../../../assets/img/icons/heading_send-gochi@2x.png')} width="340" alt="Login" />
												<div className="heading-ttl">{t('pages.share.Giving Gourmet')}</div>
											</div>
								    </div>

								    <div className="input-infor">
								    	<div className="dish-infor mt-4">
										    <div className="media">
												<figure className="mr-3 mb-0 dish-fig">
													<img className="" src={imageMenu ? imageMenu : imgMenu}
														alt={menuName}
														width="76" height="76" />
												</figure>
												<div className="media-body">
												    <div className="mt-0 dish-link" >{menuName}</div>

												    <div className="dish-desc">
												    	{description}
												    </div>
												</div>
											</div>
										</div>

								    	<div className="mt-3 text-center ">
								    		<div className="input-copy">
								    			<div className="pr-2 w-100 position-relative">
								    				{this.state.copied &&
								    					<div className="copied-tag">{t('pages.mypage.Copied')}</div>
								    				}
										            <input className="form-control text-center" readOnly 
									    				defaultValue={this.state.inviteUrl}
									    			/>
													{this.state.loadUrl ?
													<div className="loading-url">
														<FontAwesomeIcon icon={faSpinner} className="" />
														<span className="loading-url-text">{t('pages.invite.loading')}</span>
													</div>
													: null}
								    			</div>
								    			<div className="mt-2">
								    				<CopyToClipboard
															onCopy={this.onCopy}
															text={this.state.inviteUrl}
														>
									    				<button className="btn btn-copy btn-bg-full w-240 ml-auto mr-auto" onCopy={this.onCopy}>
									    					{t('pages.share.Copy link')}
									    				</button>
									    			</CopyToClipboard>
								    			</div>
								    		</div>
								    	</div>
								    </div>
								</div>
							</div>
						</div>
					</div>
				}
				<div className="box-share-sns">
					<div className="share-sns">
						<div className="item-share-sns">
							<TwitterShareButton
								url={this.props.menuInfo.shopId ? constants.LINK_SHARE + this.props.menuInfo.shopId : constants.LINK_SHARE_SAKIMESHI}
								title={t('pages.mypage.TwitterShareQuote')}
								className="button-twitter button-share-sns"
							>
								{/* <TwitterIcon size={24} round iconFillColor="white" bgStyle={{fill:'#22345d'}} /> */}
								<FontAwesomeIcon icon={faTwitter} className={"fs-24"}/>
							</TwitterShareButton>
						</div>
						<div className="item-share-sns">
							<FacebookShareButton
								url={this.props.menuInfo.shopId ? constants.LINK_SHARE + this.props.menuInfo.shopId : constants.LINK_SHARE_SAKIMESHI}
								quote={t('pages.mypage.FacebookShareQuote')}
								className="button-facebook button-share-sns"
							>
								{/* <FacebookIcon size={52} round iconFillColor="black" bgStyle={{fill:'#ffffff'}} borderRadius={6} /> */}
								<FontAwesomeIcon icon={faFacebook} className={"fs-24"}/>
							</FacebookShareButton>
						</div>
						<div className="item-share-sns">
							<LineShareButton
								url={this.props.menuInfo.shopId ? constants.LINK_SHARE + this.props.menuInfo.shopId : constants.LINK_SHARE_SAKIMESHI}
								title={t('pages.mypage.LineShareQuote')}
								className="button-line  button-share-sns"
							>
								{/* <LineIcon size={24} round iconFillColor="white" bgStyle={{fill:'#22345d'}} /> */}
								<FontAwesomeIcon icon={faLine} className={"fs-24"}/>
							</LineShareButton>
						</div>
					</div>
				</div>

				<div className="box-footer">
					<div className="box-footer-content">
						{isLoggedIn && (
							<>
								<div
									className="box-footer-note fs-18"
									dangerouslySetInnerHTML={{__html: t('pages.invite.Checkout your ticket from My page', {interpolation: {escapeValue: false}})}}
								/>
								<Link to={{ pathname: "/mypage/purchase_histories" }} target="_blank" className="btn btn-green-38 mt-3 align-center white-border">
									{t('pages.invite.Click here for order history')}
								</Link>
							</>
						)}
						{!isLoggedIn && (
							<>
								<div
									className="box-footer-note fs-18"
									dangerouslySetInnerHTML={{__html: t('pages.invite.Register info to use gochi more conveniently', {interpolation: {escapeValue: false}})}}
								/>
								<Link to={{ pathname: "/signup" }} target="_blank" className="btn btn-green-38 mt-3 align-center white-border">
									{t('pages.invite.Click here to create a new account')}
								</Link>
							</>
						)}
					</div>
				</div>

				<PopupConfirm
					showing={this.state.popupShowing}
					title={this.state.popupTitle}
					content={this.state.popupContent}
					handleConfirm={this.handlePopupConfirm}
					handleClose={this.handlePopupClose}
					confirmation={this.props.t('pages.invite.retry')}
					cancelation={this.props.t('pages.invite.cancel')}
				/>
			</div>
        ) : (
			this.state.loading &&
				<div className="loading-overlay">
				  <FontAwesomeIcon icon={faSpinner} className="" />
				</div>
		);
    }
} 

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
	showError: (showing, title, content) =>dispatch(showError(showing, title, content)),
	showLoading: (showing) => dispatch(showLoading(showing)),    
	saveInvite: (inviteContent) => dispatch(saveInvite(inviteContent)),
	selectFriend: (selectedFriendId, friendType, friendInfo, gochiUserType) => dispatch(selectFriend(selectedFriendId, friendType, friendInfo, gochiUserType))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InviteFinish))); 

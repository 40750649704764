import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showNotLoggedModal } from "../../../actions/action_show_not_logged_modal";

export function NotLoggedModal() {
  const showing = useSelector((state) => state.showNotLoggedModal);
  const dispatch = useDispatch();
  useEffect(() => {
    if (showing) {
      window.openModal && window.openModal('notLoggedActionModal');
    } else {
      window.disappearModal && window.disappearModal();
    }
  }, [showing]);
  return (
    <div
      id="notLoggedActionModal"
      className={
        showing
          ? "modal modal-action modal-dialog is-show"
          : "modal modal-action modal-dialog"
      }
      tabIndex={-1}
    >
      <button
        className="modal-btn modal-btn-close"
        id="modalClose"
        onClick={() => dispatch(showNotLoggedModal(false))}
        type="button"
      >
        <svg className="icon">
          <title>閉じる</title>
          <use xlinkHref="/assets/img/common/icon.svg#times" />
        </svg>
      </button>
      <div className="modal-body">
        <p>
          新規アカウント登録するとマイページで欲しいものリストやお気に入りを管理することができます。
        </p>
      </div>
      <div className="modal-footer">
        <div className="btn-group">
          <a href="/signup" className="btn btn-rect btn-outline">
            新規会員登録
          </a>
        </div>
      </div>
    </div>
  );
}

import React, { Component } from "react";    
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom'; 
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import {log} from '../../../utils';
import {showLoading} from "../../../actions/action_loading";
import {showError} from "../../../actions/action_error_alert";
import GochiService from "../../../api/services/GochiService"; 
import Header from "../../layout/header/Header";
import MenuService from "../../../api/services/MenuService";
import ShopService from "../../../api/services/ShopService";
import { Button, Modal, ModalBody } from "react-bootstrap";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import GochiUseStaff from "../../common/GochiUse/GochiUseStaff";
import GochiUseInfor from "../../common/GochiUse/GochiUseInfor";

class QRCodeDetail extends Component { 
	constructor(props) { 
	  	super(props); 
	  	this.state = {
			menuId: null,
			shopId: null,
			page: 1,
			showGochiUseStaff: false,
			quantity: 1,
			showGochiUse: false,
			gochiInfo: {},
            shopInfo: {},
			userInfo: queryString.parse(window.location.search).accessToken ? {isGuest: false, isLogined: true, userId: JSON.parse(atob(queryString.parse(window.location.search).accessToken.split(".")[1])).uid} : (this.props.userInfo ? this.props.userInfo : JSON.parse(localStorage.getItem('userInfo'))),
			isLoggedIn: this.props.userInfo?.userId ? true : false,
			accessToken: queryString.parse(window.location.search).accessToken,
		};
		this.loadMore = this.loadMore.bind(this);
		this.onCloseGochiStaff = this.onCloseGochiStaff.bind(this);
		this.onOpenGochiUse = this.onOpenGochiUse.bind(this);
		this.onOpenGochiUseStaff = this.onOpenGochiUseStaff.bind(this);
		this.onCloseGochiInfo = this.onCloseGochiInfo.bind(this);
		this.getShopDetail = this.getShopDetail.bind(this);
		this.hideGochiCountError = this.hideGochiCountError.bind(this);
		this.checkAccessScreen = this.checkAccessScreen.bind(this);
    	this.getDataDidMount = this.getDataDidMount.bind(this);
	}

	onCloseGochiStaff() {
		this.setState({
			showGochiUseStaff: false
		});
	}

	onCloseGochiInfo() {
		this.setState({
			showGochiUse: false,
		})
	}
	  
    onOpenGochiUse(gochiInfo) {
		console.log('onOpenGochiUse', gochiInfo.gochiCountAvailable);
		if (gochiInfo.gochiCountAvailable < 1) {
            this.setState({
                gochiCountError: this.props.t('other.Ticket unavailable')
            });
            return;
        }

		if (gochiInfo.isETicket === 0) {
            this.setState({
                gochiCountError: this.props.t('other.Prevent paper ticket')
            });
            return;
        }

        if (gochiInfo.gochiCount === 1 && gochiInfo.menuChildType !== 3) {
            this.setState({
                showGochiUseStaff: true,
                // showGochiUse: true,
                gochiInfo: gochiInfo
            });
        } else {
            this.setState({
            //   showGochiUseStaff: true,
                showGochiUse: true,
                gochiInfo: gochiInfo
            });
        }
    }

	async fetchData(menuId, shopId, gochiUserId, bookingCampaignFinish, accessToken){ 
        try {       
            this.props.showLoading(true);

            let detailMenuData = await this.getDetailMenu(menuId, shopId, gochiUserId, bookingCampaignFinish, accessToken); 
            if(detailMenuData.length <= 0) {
                this.props.showError(true, this.props.t("There is no data"), "");
            } else {  
				console.log("DETAIL MENU DATA:");
				this.setState({
					detailMenu: detailMenuData
				})
			}
			
			let recommendListData = await this.getRecommendList(shopId, menuId, gochiUserId, 1);
			if(recommendListData.length <= 0) {
                this.props.showError(true, this.props.t("There is no data"), "");
            } else {  
				console.log("RECOMMEND LIST DATA:");
				this.setState({
					recommendList: recommendListData
				})
			}

			let messsages = await this.getMessages(menuId);
			if (recommendListData.length <= 0) {
                this.props.showError(true, this.props.t("There is no data"), "");
            } else {  
				console.log("MESSAGES DATA:");
				console.log(messsages);
				this.setState({
					messsages: messsages
				})
			}
            this.props.showLoading(false); 
		} catch (e) {
            log(e); 
            this.props.showLoading(false);
            this.props.showError(true, this.props.t("There is something wrong"), "");
		}	 
	}


	getDetailMenu = async (menuId, shopId, gochiUserId, bookingCampaignFinish, accessToken) => {
		try {
			const checkTokenExpired = accessToken ? 1 : null;
			const { data } = await MenuService.getDetailMenu(menuId, shopId, gochiUserId, 1, bookingCampaignFinish, accessToken, checkTokenExpired);
			return data;
		} catch (error) { 
			log('getGochiList error');
			console.log(error);
			this.props.showError(true, this.props.t("There is something wrong"), "");
		}
	}

	getRecommendList = async (shopId, menuId, gochiUserId, page) => {
		try {
			const { data } = await GochiService.getGochiList({
			  shopId: shopId,
			  menuId: menuId,
			  gochiType: null,
			  gochiUserType: ["ANYONE"],
			  couponCountGte: 1,
			  gochiRegisterId: null,
			  userInvitedId: null,
			  page: page,
			  limit: 5,
			  sort: "lastAddTimeDesc"
			});   

			return data;
	  } catch (error) { 
		  log('getGochiList error');
		  console.log(error);
		  this.props.showError(true, this.props.t("There is something wrong"), "");
	  }
	}

	getMessages = async (menuId) => {
		try {
			const { data } = await MenuService.getMessages(menuId, true, 1, 5);
			return data;
		} catch (error) { 
			log('getGochiList error');
			console.log(error);
			this.props.showError(true, this.props.t("There is something wrong"), "");
		}
	}

	async loadMore() {
		const page = this.state.page + 1;
		this.setState({page: page});
		this.getRecommendList(this.state.shopId, this.state.menuId, this.state.gochiUserId, page);
	}

	onOpenGochiUseStaff(gochiInfo) {
		console.log('onOpenGochiUseStaff', gochiInfo.gochiCountAvailable);
		if (gochiInfo.gochiCountAvailable < 1) {
            this.setState({
                gochiCountError: this.props.t('other.Ticket unavailable')
            });
            return;
        }

		if (gochiInfo.isETicket === 0) {
            this.setState({
                gochiCountError: this.props.t('other.Prevent paper ticket')
            });
            return;
        }

		this.setState({
		  showGochiUseStaff: true,
		  // showGochiUse: true,
		  gochiInfo: gochiInfo
		});
		sessionStorage.setItem('usingFlowGochiInfo', JSON.stringify(gochiInfo));
		var usingUrl = "/gochiuse/staff";
		let siteTypeP = queryString.parse(window.location.search).siteType ? "&siteType=" + queryString.parse(window.location.search).siteType : '';
		let accessTokenP = this.state.accessToken ? "&accessToken=" + this.state.accessToken : '';
		let moreParams = accessTokenP + siteTypeP;
		window.location.href = usingUrl+'?shopId='+gochiInfo.shopId+'&menuId='+gochiInfo.menuId+moreParams;
    }
    
	hideGochiCountError() {
		this.setState({gochiCountError: ''});
	}

    getShopDetail = async (shopId) => { 
        try {
            const { data } = await ShopService.getDetailShop(shopId);   
            this.setState({
                shopInfo: {
                    shopId: data.shopId,
                    shopName: data.shopName,
                    sushiroFlag: data.sushiroFlag,
                    shopStatus: data.status
                }
            });
            return data;
        } catch (error) { 
            log('géthopdetail error');
            this.props.showError(true, this.props.t("There is something wrong"), "");
        }
    };
	
	checkAccessScreen = async () => {
		let url = window.location.search;
		let params = queryString.parse(url);
		let accessToken = params.accessToken;
		let menuId = params.menuId;;
		const userInfo = this.state.userInfo;
		const gochiUserId = userInfo?.userId;
		const bookingCampaignFinish = params.campaignId ? "bookingCampaignFinish=1" : '';
		let shopId = params.shopId;
		if (!accessToken) {
			var link = "/login";
			if (!this.state.isLoggedIn){
				sessionStorage.setItem('loginCallbackUrl', window.location.pathname);
				return window.location.href = link;
			} else {
				if (this.state.userInfo) { 
					if (this.state.userInfo.isLogined === false || this.state.userInfo.isGuest) { 
						window.history.pushState(null, null, '/login');
						return <Redirect to={link} />
					}
					this.getDataDidMount(menuId, gochiUserId, shopId, bookingCampaignFinish);
				}else{
					window.history.pushState(null, null, '/login');
					return <Redirect to={link} />
				}
			}
		} else {
			if (this.state.isLoggedIn){
				sessionStorage.clear();
				localStorage.clear();
			}
			this.getDataDidMount(menuId, gochiUserId, shopId, bookingCampaignFinish, accessToken);
		}
	}
		
		UNSAFE_componentWillMount(){ 
			this.checkAccessScreen();
	}

	async getDataDidMount(menuId, gochiUserId, shopId, bookingCampaignFinish, accessToken){
		if (menuId && shopId) {

            this.fetchData(menuId, shopId, gochiUserId, bookingCampaignFinish, accessToken);
            this.getShopDetail(shopId);
		}

		this.setState({
			shopId: shopId,
			menuId: menuId,
			gochiUserId: gochiUserId
		});
	}

	componentDidMount() {
		//
	}

    render() {
		const {t} = this.props;

		var imgMenu = require("../../../assets/img/icons/gochi_noimg-ver4.jpg");
        if (this.state.detailMenu && 
                this.state.detailMenu.menuImage && 
                    (this.state.detailMenu.menuImage.length > 0) && 
                    this.state.detailMenu.menuImage[0].image) 
        {
			imgMenu = this.state.detailMenu.menuImage[0].image
		}

		const recommendList = this.state.recommendList;
		// const hasMore = this.state.recommendList && (this.state.recommendList.count > this.state.page * 5);

		const gochiMenuInfo = {
			shopId: this.state.shopId,
			shopName: this.state.shopInfo.shopName,
			shopStatus: this.state.shopInfo.shopStatus,
			menuName: this.state.detailMenu && this.state.detailMenu.menuName,
			menuId: this.state.menuId,
			menuImage: imgMenu,
			includedTaxPrice: this.state.detailMenu && this.state.detailMenu.includedTaxPrice,
			displayAmount: this.state.detailMenu && this.state.detailMenu.displayAmount,
			excludedTaxPrice: this.state.detailMenu && this.state.detailMenu.excludedTaxPrice,
			gochiCount: this.state.detailMenu && this.state.detailMenu.gochiCount,
			gochiExpiredDay: this.state.detailMenu && this.state.detailMenu.gochiExpiredDay,
			menuChildType: this.state.detailMenu && this.state.detailMenu.menuChildType,
			childFoods: this.state.detailMenu && this.state.detailMenu.childFoods,
			groupId: this.state.detailMenu && this.state.detailMenu.groupId,
			menuCombo: this.state.detailMenu && this.state.detailMenu.menuCombo,
			quantityFree: this.state.detailMenu && this.state.detailMenu.menuComboDetail ? this.state.detailMenu.menuComboDetail.quantityFree : 0,
			comboOutQuantity: this.state.detailMenu && this.state.detailMenu.menuComboDetail ? this.state.detailMenu.menuComboDetail.quantity : 0,
			gochiCountAvailable: this.state.detailMenu && this.state.detailMenu.gochiCountAvailable,
			isETicket: this.state.detailMenu && this.state.detailMenu.isETicket,
      showPrice: this.state.detailMenu && this.state.detailMenu.showPrice,
      usageType: this.state.detailMenu && this.state.detailMenu.usageType,
      reducedTaxType: this.state.detailMenu && this.state.detailMenu.reducedTaxType
        };
        
        const shopName = this.state.shopInfo.shopName;

        console.log('gochiMenuInfo', gochiMenuInfo);
		
        return (
			<>
        	<div className="content">
				<Header 
					title={shopName || t('pages.qrcode.QR code reading')}
					isLoggedIn={this.state.isLoggedIn}
					t={t}
					hasBack={true}
				/>

				<div className="content-mypage">
                    {gochiMenuInfo.shopStatus === 'CLOSED' &&
                        <div className="shop-closed">
                            {t('pages.menu.This shop has closed')}
                        </div>
                    }
					<div className="label-page d-flex align-items-center mb-0">
						<i className="label-menu"></i>
						<span className="label-symbol">/</span>
						<span className="label-text fs-11">{t('pages.qrcode.Menu details')}</span>
					</div>

					<div className="media">
						<div className="dish-image w-auto overflow-normal">
							<figure className="dish-fig mb-0">
								<img src={imgMenu}
									alt="shop"
									className="img-square-100" 
                                />
							</figure>
						</div>
						<div className="media-body font-weight-bold wrap-overflow">
							<div className="mx-1 text-green fs-14">{shopName}</div>
							<div className="mx-1 fs-14">{this.state.detailMenu && this.state.detailMenu.menuName}</div>
						</div>
					</div>

					<div className="fs-12 break-word clr-666666 break-line mt-2">
						{/* {this.state.detailMenu && this.state.detailMenu.description.replaceAll('\r', '\n')} */}
						{this.state.detailMenu && this.state.detailMenu.description}
					</div>

					<div 
						onClick={
							this.state.detailMenu && this.state.detailMenu.gochiCount
							&& (this.state.detailMenu.gochiCount > 1)
							&& (this.state.detailMenu.menuCombo !== 1)
							&& !this.state.shopInfo.sushiroFlag
							? () => this.onOpenGochiUse(gochiMenuInfo)
							: () => this.onOpenGochiUseStaff(gochiMenuInfo)}
						className="btn btn-bg-full text-center my-4 mx-auto" 
					>
						{t('pages.qrcode.You can enjoy this menu')}
						<i className="icon-next"></i>
					</div>

					<div className="label-page d-flex align-items-center mb-0">
						<i className="label-recommend"></i>
						<span className="label-symbol">/</span>
						<span className="label-text fs-11">{t('pages.qrcode.Recommended points')}</span>
					</div>

					{(recommendList && recommendList.count > 0) ? (
                        <>
                            {
                                recommendList.list.map((recommend, key) => {
                                    const gochiInfo = {
                                        gochiBillId: recommend.gochiBillId,
                                        gochiBillNo: recommend.gochiBillNo,
                                        shopId: recommend.shopId,
                                        shopName: recommend.shop.shopName,
                                        shopStatus: recommend.shop.status,
                                        menuName: recommend.menu.menuName,
                                        menuId: recommend.menuId,
                                        menuImage: (recommend.menu.menuImage && recommend.menu.menuImage.length > 0) 
                                            ?  recommend.menu.menuImage[0].image 
                                            : require("../../../assets/img/icons/gochi_noimg-ver4.jpg"),
                                        includedTaxPrice: recommend.menu.includedTaxPrice,
                                        displayAmount: recommend.menu.displayAmount,
                                        excludedTaxPrice: recommend.menu.excludedTaxPrice,
                                        gochiCount: recommend.gochiCount,
                                        gochiExpiredDay: recommend.menu.gochiExpiredDay,
                                        menuChildType: recommend.menu.menuChildType,
                                        groupId: recommend.groupId,
                                        menuCombo: recommend.menu.menuCombo,
                                        quantityFree: recommend.menu.menuComboDetail ? recommend.menu.menuComboDetail.quantityFree : 0,
                                        comboOutQuantity: recommend.menu.menuComboDetail ? recommend.menu.menuComboDetail.quantity : 0,
                                        situation: recommend && recommend.gochiBillMessage && recommend.gochiBillMessage[0].situation,
										gochiCountAvailable: recommend && recommend.gochiCountAvailable,
										isETicket: recommend?.menu?.isETicket
                                    };
                                return (
                                    <div key={key}>
                                        <div className="media pb-4">
                                            <img className="align-self-start mr-2 cursor img-square-80" 
                                                src={recommend.menu && recommend.menu.menuImage &&
                                                (recommend.menu.menuImage.length > 0) 
                                                ? recommend.menu.menuImage[0].image 
                                                : require("../../../assets/img/icons/gochi_noimg-ver4.jpg")}
                                                alt="gochi"></img>
                                            <div className="media-body media-body-80">
                                                <img src={recommend.gochiRegister && recommend.gochiRegister.userImage &&
                                                (recommend.gochiRegister.userImage.length > 0) 
                                                ? recommend.gochiRegister.userImage[0].image 
                                                : require("../../../assets/img/icons/gochi_noimg-ver4.jpg")}
                                                    className="rounded-circle mr-1"
                                                    width="20"
                                                    height="20"
                                                    style={{display: "inline"}}
                                                    alt="user avatar"></img>
                                                <h5 
                                                    className="mt-0 fs-12 font-weight-bold cursor text-green"
                                                    style={{display: "inline"}} 
                                                >
                                                    {recommend && recommend.gochiRegister && recommend.gochiRegister.displayName}
                                                </h5>

                                                <div className="d-flex align-self-center coupon-info">
                                                    <div className="bottom-left">
                                                        <div className="fs-14 break-line hidden-over-flow font-weight-bold mt-3">
                                                            {/* {recommend && recommend.gochiBillMessage && recommend.gochiBillMessage[0].situation.replaceAll('\r', '\n')} */}
                                                            {recommend && recommend.gochiBillMessage && recommend.gochiBillMessage[0].situation}
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="border border-primary coupon-quantity fs-11" >
                                                                {recommend ? recommend.gochiCount : 0}{t('pages.tome.Gochi')}</div>
                                                            <span className="fs-10 align-self-center pl-1 expiration font-weight-bold">
                                                            <FontAwesomeIcon icon={faExclamationCircle} />
                                                                {t('pages.menu.Gochi expired day', {gochiExpiredDay: recommend && recommend.gochiExpiredDay})}
                                                            </span>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="align-self-center">
                                                <Button
                                                    style={{height:45}}
                                                    variant="link"
                                                    onClick={
                                                        recommend.gochiCount
                                                        && (recommend.gochiCount > 1)
                                                        && (recommend.menuCOmbo !== 1)
                                                        && !this.state.shopInfo.sushiroFlag
                                                        ? () => this.onOpenGochiUse(gochiInfo)
                                                        : () => this.onOpenGochiUseStaff(gochiInfo)}
                                                >
                                                    <img className="mr-0 fs-10" 
                                                        src={require('../../../assets/img/icons/button_gochirareru.png')} 
                                                        width="45" 
                                                        height="45" 
                                                        alt="Gochirareru button"
                                                    />
                                                </Button>
                                            </div> 
                                        </div>
                                        
                                    </div>
                                )})
                            }

                            {/* {hasMore && (
                                <div className="text-center mt-1">
                                    <Button variant="link" className="btn btn-load-more" onClick={this.loadMore}>{t('pages.login.Load more')}</Button>
                                </div>
                            )} */}
                        </>
                    )
                        : 
                    (
						<div className="div-no-recommend">
							<div className="div-no-recommend-txt">
								<div className="mb-1 font-weight-bold underline">{t('pages.qrcode.Is it your favorite menu?')}</div>
								<div className="mb-1">{t('pages.qrcode.This shop has not been registered yet')}</div>
								<div className="mb-1">{t('pages.qrcode.Let put in a feast and recommend the menu')}</div>
							</div>
							<i className="label-empty-menulist"></i>
						</div>
					)}

					<div className="label-page d-flex align-items-center mb-0">
						<i className="label-message"></i>
						<span className="label-symbol">/</span>
						<span className="label-text fs-11">{t('pages.qrcode.Thank you message')}</span>
					</div>

					{this.state.messsages && this.state.messsages.list.length > 0 && this.state.messsages.list.map((message, key) => (
						<div className="media pt-4" key={'message'+key}>
							<img className="align-self-start mr-2 cursor" 
							src={(message && message.couponImage)
									  ? message.couponImage.image 
									  : require("../../../assets/img/icons/gochi_noimg-ver4.jpg")}
								width="80"
								alt="menu"></img>
							<div className="media-body">
								<img src={message.gochiUser && message.gochiUser.image? message.gochiUser.image: require("../../../assets/img/icons/gochi_noimg-ver4.jpg")}
									className="rounded-circle m-1"
									width="20"
									height="20"
									style={{display: "inline"}}
									alt="user avatar"></img>
								<h5 
									className="mt-0 fs-12 font-weight-bold cursor text-green" 
									style={{display: "inline"}}
								>
									{message.gochiUser && message.gochiUser.displayName}
								</h5>
								{/* <div className="coupon-mess break-line">{message.couponMessage.message.replaceAll('\r', '\n')}</div> */}
								<div className="coupon-mess break-line break-word">{message.couponMessage.message}</div>
							</div>
						</div>
					))}

				</div>
			</div>

			{this.state.showGochiUse ? (
				<GochiUseInfor 
				showing={this.state.showGochiUse}
				onCloseGochiInfo={this.onCloseGochiInfo}
				gochiInfo={this.state.gochiInfo}
        	/>
        	) : null}

			{this.state.gochiCountError && (
				<Modal
					size="sm"
					centered
					show={!!this.state.gochiCountError}
					onHide={this.hideGochiCountError}
				>
					<Modal.Header closeButton>
					{this.state.gochiCountError}
					</Modal.Header>
					{/* <ModalBody>
						{this.state.gochiCountError}
					</ModalBody> */}
				</Modal>
			)}
            {/* {this.state.showGochiUseStaff ? (
                <GochiUseStaff
                    showing={this.state.showGochiUseStaff} 
                    onCloseGochiStaff={this.onCloseGochiStaff}
                    gochiInfo={this.state.gochiInfo}
                    quantity = {this.state.quantity}
                    childFoodId = {this.state.childFoodId}
                />
            ) : null} */}
			</>
        ); 
    }
} 

const mapStateToProps = state => ({
	...state
}); 

const mapDispatchToProps = dispatch => ({
    showLoading: (showing) => dispatch(showLoading(showing)),
    showError: (showing, title, content) => dispatch(showError(showing, title, content)),
});
 
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(QRCodeDetail)); 

import React, { useState } from "react";

export function InputGochiKidPriceModal(props) {
  const [errorMessage, setErrorMessage] = useState('');

  const accessToken = props.accessToken ? "&accessToken=" + props.accessToken : "";
  const accessCode = props.accessToken ? "&accessCode=" + props.accessCode : "";
  const gochiKidGroupId = props.gochiKidGroupId ? "&gochiKidGroupId=" + props.gochiKidGroupId : "";
  let maxAmount = 1000 * props.choiceUserKidIds.length;
  const kidFirstUsed = props.choiceUserKids.filter(kid => kid.firstUsed === 1);
  const isPoor = props.choiceUserKids[0].flagPoor === 1;

  if (kidFirstUsed.length > 0 && isPoor) {
    let minAmount = Math.min(...kidFirstUsed.map(kid => kid.amountKidCanUse));
    if (minAmount > 1000 && !props.gochiKidUnlimitShopChoice) {
      minAmount = 1000;
    }
    maxAmount = minAmount * props.choiceUserKidIds.length;
  }

  const validate = (val) => {
    if (val && val > 0) {
      if  (kidFirstUsed.length > 0 && isPoor) {
        if (val <= maxAmount) {
          setErrorMessage('');
          return true;
        }  else  {
          setErrorMessage('同時利用可能残高以上の金額が入力されています。');
        }
      } else {
        if (props.gochiKidUnlimitShopChoice === 1 && !props.gochiKidGroupId) {
          setErrorMessage('');
          return true;
        } else if (val <= maxAmount) {
          setErrorMessage('');
          return true;
        } else {
          setErrorMessage('上限以上の金額を入力されています。');
        }
      }
    } else {
      setErrorMessage('必須項目です。');
    }
    return false;
  };

  const onChangeUseAmount = (event) => {
    // Remove all non-digit characters
    const val = parseInt(event.target.value.replace(/\D/g, '')) || null;
    props.setUseAmount(val);

    validate(val);
  };

  const onSubmit = () => {
    if (!validate(props.useAmount)) {
      return;
    }
    const arr = [];
    props.choiceUserKidIds.forEach(id => arr.push(`userKidIds=${id}`));
    const userKidIds = arr.join("&");
    window.location.href = `/kodomo/use?shopId=${props.choiceShopId}&totalUseAmount=${props.useAmount}&${userKidIds}${gochiKidGroupId}${accessToken}${accessCode}`;
  };

  return (
    <div
      className={`modal modal-profile modal-profile-02 modal-02 modal-lg select-user-kid-modal ${props.isShow ? 'is-show' : ''}`}
      tabIndex={-1}
    >
      <div className="header-register-user-kid">
        <button
          className="modal-btn modal-btn-close"
          id="modalClose"
          onClick={props.onHide}
          type="button"
        >
          <svg className="icon">
            <title>閉じる</title>
            <use xlinkHref="/assets/img/common/icon.svg#times" />
          </svg>
        </button>
      </div>
      <div className="title-modal-register">
        <div className="title">利用金額を入力</div>
      </div>
      <div className="modal-body body-modal-register">
        <div className="title-warning">
          <div className="dot-red"></div>
          必須入力ですので、必ずご入力お願い致します
        </div>
        <div className="register-poor">
          <div className="register-title mt-16px">
            <div className="dot-red"></div>
            利用する金額（税込）
          </div>
          <div className="form-block">
            <div className="d-flex align-items-center">
              <input
                className="amount-text-input form-control text-right mr-2"
                type="tel"
                inputMode="numeric"
                value={props.useAmount || ''}
                onChange={onChangeUseAmount}
              />
              <span className="fs-18">円</span>
            </div>
          </div>
          <div className="title-warning mt-2">{errorMessage}</div>
          {(props.gochiKidUnlimitShopChoice !== 1 || (kidFirstUsed.length > 0 && isPoor) || props.gochiKidGroupId) && (
            <div className="mt-2 btn-gochi-tip">
              税込{maxAmount.toLocaleString()}円以内でご入力ください
              <i className="icon-explain-validate-input-gochi-kid" onClick={() => {
                props.setShowExplainInputGochiKidModal(true);
              }}/>
            </div>
          )}
        </div>
      </div>
      <div className="modal-footer">
        <div>
          <button
            onClick={onSubmit}
            className='btn btn-rect btn-confirm'
          >
            確定する
          </button>
        </div>
        <div>
          <button
            onClick={props.onHide}
            className={'btn btn-outline-rect btn-text'}
          >
            前の画面に戻る
          </button>
        </div>
      </div>
    </div>
  );
}
import React, { Component } from "react";    
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next';
import {log} from '../../../utils';
import {showLoading} from "../../../actions/action_loading";
import {showError} from "../../../actions/action_error_alert";
import GochiService from "../../../api/services/GochiService";

class GochiraeruDetail extends Component { 
	constructor(props) { 
	  	super(props); 
	  	this.state = {
			gochiBillId: null, 
			gochiBill: null,
			tagList: null,
			userType: null,
			amount: 0,
			isSakimeshi: true,
			redirectMypage: false
		};
		this.addComma = this.addComma.bind(this);
		this.getGochi = this.getGochi.bind(this);
		this.handleSetMemo = this.handleSetMemo.bind(this);
		this.handleSaveMemo = this.handleSaveMemo.bind(this);
	}

	async fetchData(gochiBillId){ 
        try {       
            this.props.showLoading(true);     

            let data = await this.getGochi(gochiBillId); 
            console.log(data);
            if(data.length <= 0) {
                this.props.showError(true, "There are no data", "");
            } else {  
				const feeGigiAmount  =   data.feeGigiAmount ? data.feeGigiAmount : 0
  				const discountAmount  =   data.discountAmount ? data.discountAmount : 0
                this.setState({
					gochiBill: data,
					tagList: (data.shop && data.shop.shopName) || (data.menu && data.menu.menuName) ? ["さきめし", data.shop && data.shop.shopName ? data.shop.shopName : '', data.menu && data.menu.menuName ? data.menu.menuName : ''].filter(Boolean).map((s = "") => s.replace(/\s+/g, "_")) : null,
					userType: data.userType === "ANYONE" ? this.props.t("pages.mypage.title how to dust") : this.props.t("pages.mypage.title fool"),
					amount: this.addComma(
						data.amount + data.taxAmount + data.feeAmount - feeGigiAmount - discountAmount, true
					),
					memo: data.memo
                });
                
            }
            this.props.showLoading(false); 
		} catch (e) {
            log(e); 
            this.props.showLoading(false);
            this.props.showError(true, this.props.t("pages.login.There is something wrong"), "");
		}	 
	}
	
	addComma = (num, addY) => {
		if (!num) {
			return "0";
		}
		const parts = num.toString().split(".");
		parts[0] = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
		if(addY){
			return "¥" + parts.join(".");
		}else{
			return parts.join(".");
		}
	}
    
    getGochi = async (gochiBillId) => { 
			try {
			  	const { data } = await GochiService.getGochi({gochiBillId});   
			  	return data;
			} catch (error) { 
                log('getGochiList error');
                this.props.showError(true, this.props.t("pages.login.There is something wrong"), "");
			}
	};

	handleSetMemo = (e) => {
		this.setState({
			memo: e.target.value
		});
	}

	handleSaveMemo = async (e) => {
		e.preventDefault();
		try {
			const result = await GochiService.updateMemo({
				memo: this.state.memo ? this.state.memo.toString() : '',
				gochiBillId: this.state.gochiBillId
			});
			if(result.status !== 200) {
				this.props.showError(true, this.props.t("pages.mypage.Update memo failed"), "");
			} else {
				this.setState({
					redirectMypage: true
				})
			}
			return result;
		} catch (error) { 
			log('update memo error');
			this.props.showError(true, this.props.t("pages.login.There is something wrong"), "");
		}
	}

	componentDidMount(){
	}

    render() {


        return (
        	<div className="content">
						<div className="header">
							<div className="header-row">
								<div className="header-ttl">
								</div>
							</div>
						</div> 
					</div>
        ); 
    }
} 

const mapStateToProps = state => ({
	...state
}); 

const mapDispatchToProps = dispatch => ({
    showLoading: (showing) => dispatch(showLoading(showing)),
    showError: (showing, title, content) => dispatch(showError(showing, title, content)),
});
 
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GochiraeruDetail)); 

import React from "react";
import * as constants from '../../constants';
import moment from "moment";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle,
  Row,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { withTranslation } from "react-i18next";

function ChargeHistoryModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="sm"
      centered
      dialogClassName="history-modal-container"
      scrollable
    >
      <ModalHeader closeButton className="history-modal-header"></ModalHeader>
      <ModalTitle className="text-center mb-1 fs-20 font-weight-bold">
        チャージ履歴
      </ModalTitle>
      <ModalBody className="py-0 modal-history">
        {props.listCharges.map((item) => {
              let payment;
              if (item?.paymentType) {
                if (item.paymentType === constants.PAYMENT_TYPE_STRIPE || item.paymentType === constants.PAYMENT_TYPE_VERITRANS) {
                  payment = "クレジットカード";
                } else if (item.paymentType === constants.PAYMENT_TYPE_PAYPAY) {
                  payment = "Paypay";
                } else if (item.paymentType === constants.PAYMENT_TYPE_ONEPAY_ALIPAY) {
                  payment = "Alipay";
                } else if (item.paymentType === constants.PAYMENT_TYPE_ONEPAY_LINEPAY) {
                  payment = "Linepay";
                } else if (item.paymentType === constants.PAYMENT_TYPE_ONEPAY_MERUPAY) {
                  payment = "Merupay";
                } else if (item.paymentType === constants.PAYMENT_TYPE_WECHATPAY) {
                  payment = "Wechatpay";
                } else if (item.paymentType === constants.PAYMENT_TYPE_ATONE) {
                  payment = "Atone";
                } else if (item.paymentType === constants.PAYMENT_TYPE_ATONE) {
                  payment = "";
                }
              } else {
                payment = "QRコード";
              }

              return (
                <div className="history-box" key={item.gochiChargeLogId}>
                  <div className="p-3">
                    <div className="history-line">
                      <div className="title-lst-history">日付・日時</div>
                      <div className="content-lst-history">{moment(item.createdAt).format('YYYY年MM月DD日 H時mm分')}</div>
                    </div>
                    <div className="my-8px history-line">
                      <div className="title-lst-history">決済方法</div>
                      <div className="content-lst-history">{payment}</div>
                    </div>
                    <div className="history-line">
                      <div className="title-lst-history">決済金額</div>
                      <div className="content-lst-history">
                        <span>{item.chargeAmount.toLocaleString()}</span>
                        <span>円</span>
                      </div>
                    </div>
                    <div className="history-line my-8px">
                      {item.premiumRate === 0 ? (
                        <div className="title-lst-history extra-large-content1">利用可能金額</div>
                      ) : (
                        <div className="title-lst-history extra-large-content1">利用可能金額<br/>（プレミアム付き）</div>
                      )}
                      <div className="content-lst-history extra-small-title1">
                        <span>{item.premiumAmount.toLocaleString()}</span>
                        <span>円</span>
                      </div>
                    </div>
                    <div className="history-line">
                      <div className="title-lst-history extra-small-title2">※利用可能期間</div>
                      <div className="content-lst-history extra-large-content2">
                        <span>{moment(item.useStartAt).format('YYYY年MM月DD日') + "〜" + moment(item.useEndAt).format('YYYY年MM月DD日')}</span>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
      </ModalBody>
      <ModalFooter className="history-modal-footer">
        <div className="history-load-more">
          {(props.page+1) <= Math.ceil(props.count/props.limit) && (
            !props.loadingLoadMore ? 
            (<span className="cursor-pointer fs-15" onClick={() => props.loadMoreHistoryCharge(props.gochiBillId)}>
              もっと見る
            </span>) : (<i className="icon-loader-new"></i>)
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default withTranslation()(ChargeHistoryModal);

import React from "react";
import { Link } from "react-router-dom";

function HowToModal(props) {
  return (
    <div
      className={
        props.isShow
          ? "custom-modal-container show-modal"
          : "custom-modal-container"
      }
      size="sm"
    >
      <div
        className={
          props.isShow
            ? "modal modal-action modal-dialog is-show"
            : "modal modal-action modal-dialog"
        }
      >
        <button
          className="modal-btn modal-btn-close"
          // id="modalClose"
          onClick={props.onHide}
          type="button"
        >
          <svg className="icon">
            <title>閉じる</title>
            <use xlinkHref="/assets/img/common/icon.svg#times" />
          </svg>
        </button>
        <div className="modal-body p-2 px-10">
          <p className="fs-18 font-weight-bold">
          ごちめしの使い方をチェック！
          </p>
        </div>
        <div className="modal-footer">
          <div className="btn-group">
            <Link to="/howto" className="btn btn-rect bg-35A99E border-radius-10">
                ごちめしの使い方
            </Link>
          </div>
        </div>
      </div>
      <div onClick={props.onHide} className="back-drop" />
    </div>
  );
}

export default HowToModal;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, useHistory, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Moment from "react-moment";
import Header from "../../layout/header/Header";
import { log } from "../../../utils";
import queryString from "query-string";
import { showLoading } from "../../../actions/action_loading";
import { showError } from "../../../actions/action_error_alert";
import GochiService from "../../../api/services/GochiService";
import { Button, Modal } from "react-bootstrap";
import GochiKidUseConfirmModal from "./GochiKidUseConfirmModal";
import PopupErrorV2 from "../PopupErrorV2";
import { MENU_USAGE_TYPE, TAX_RATE } from "../../../constants";
import Barcode from "../Barcode";
const NO_IMAGE = require("../../../assets/img/icons/gochi_logo_print.png");

class GochiKidUse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showGochiUseConfirm: false,
      dataInfo: {},
      quantity: 1,
      menuId: null,
      gochiUserId: null,
      shopId: null,
      useAmount: null,
      totalUseAmount: null,
      crfsToken: "",
      locationKeys: [],
      userInfo: queryString.parse(window.location.search).accessToken ? {isGuest: false, isLogined: true, userId: JSON.parse(atob(queryString.parse(window.location.search).accessToken.split(".")[1])).uid} : (this.props.userInfo ? this.props.userInfo : JSON.parse(localStorage.getItem('userInfo'))),
      isLoggedIn: this.props.userInfo?.userId ? true : false,
      isAccess: false,
      isFinished: false,
      listSponsor: {},
      usedAt: '',
      userKidIds: [],
      accessToken: queryString.parse(window.location.search).accessToken,
      gochiKidGroupId: queryString.parse(window.location.search).gochiKidGroupId,
      shopNameInFinish: '',
      menuNameInFinish: '',
      includedTaxPriceInFinish: '',
      numberUseInFinish: '',
      flowQrUserGochiKidPopupFinish: false,
      shopIdInFinish: '',
      imageLogoInFinish: null,
      barCodeYoshinoyaInFinish: null,
      isShowError: false,
      titleError: '',
      contentError: '',
      textBtnError: '',
      urlError: '',
      taxRate: TAX_RATE.TAX_RATE_10_PERCENT
    };
    this.onCloseGochiConfirm = this.onCloseGochiConfirm.bind(this);
    this.generateRandomString = this.generateRandomString.bind(this);
    this.onSubmitConfirm = this.onSubmitConfirm.bind(this);
    this.checkAccessScreen = this.checkAccessScreen.bind(this);
    this.getDataDidMount = this.getDataDidMount.bind(this);
    this.closeWindowScanQRUser = this.closeWindowScanQRUser.bind(this);
  }

  generateRandomString(length = 32) {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    this.setState({
      crfsToken: text,
    });
    console.log("crfs token", text);
    return text;
  }

  onCloseGochiConfirm() {
    this.props.onCloseGochiStaff();
    this.setState({
      showGochiUseConfirm: false,
    });
  }

  checkAccessScreen = async () => {
      let url = window.location.search;
      let params = queryString.parse(url);
      let menuId = params.menuId;
      let shopId = params.shopId;
      let useAmount = params.useAmount;
      let totalUseAmount = params.totalUseAmount;
      let accessToken = params.accessToken;
      let accessCode = params.accessCode;
      let userKidIds = params.userKidIds;
      let gochiKidGroupId = params.gochiKidGroupId;
      var link = "/login";
      if (!accessToken) {
        if (!this.state.isLoggedIn){
            sessionStorage.setItem('loginCallbackUrl', window.location.pathname);
            return window.location.href = link;
        } else {
            if (this.state.userInfo) {
                if (this.state.userInfo.isLogined === false || this.state.userInfo.isGuest) { 
                    window.history.pushState(null, null, '/login');
                    return <Redirect to={link} />
                }
                this.getDataDidMount(this.state.userInfo, menuId, shopId, useAmount, totalUseAmount, gochiKidGroupId, userKidIds);
            }else{
                window.history.pushState(null, null, '/login');
                return <Redirect to={link} />
            }
        }
      } else {
        if (this.state.isLoggedIn){
          sessionStorage.clear();
          localStorage.clear();
        }
        this.getDataDidMount(this.state.userInfo, menuId, shopId, useAmount, totalUseAmount, gochiKidGroupId, userKidIds, accessToken, accessCode);
      }
  }

  UNSAFE_componentWillMount(){ 
        this.checkAccessScreen();
  }

  async getDataDidMount(userInfo, menuId, shopId, useAmount, totalUseAmount, gochiKidGroupId, userKidIds, accessToken, accessCode) {
    let url = window.location.search;
    let params = queryString.parse(url);
    let isFinished = params.isFinished;
    if (userInfo) {
      if (userInfo.isLogined && !userInfo.isGuest) {
        if (shopId && userKidIds && userKidIds.length > 0 && !isFinished) {
          let response = await this.confirmUseGochiKid(
            shopId,
            menuId,
            useAmount,
            totalUseAmount,
            userKidIds,
            accessToken,
            gochiKidGroupId
          );
          console.log('response', response);
          if (response && response.status === 200) {
            this.setState({
              dataInfo: response.data,
              isAccess: true
            });
            if (response.data.usageType === MENU_USAGE_TYPE.USAGE_TYPE_MENU) {
              this.setState({
                taxRate: response.data.taxRate
              });
            }
          } else {
            if (response.data.code === 'gochi.kid.thank.message.not.found') {
              this.props.showError(
                true,
                "",
                response?.responseData?.errorInfo?.content,
                `利用履歴画面に戻る`,
                accessToken ? `/mypage/kodomo_use/search_shop/${shopId}?accessToken=${accessToken}&accessCode=${accessCode}${gochiKidGroupId ? `&gochiKidGroupId=${gochiKidGroupId}` : ''}` : '/mypage/kodomo_use'
              );
            } else if (response.data.code === 'gochi.kid.limit.day' && response?.responseData?.errorInfo?.content === '本日の食堂利用回数を超えました。') {
              const elementContentText = <p><p style={{ color: 'red'}}>利用可能人数を超えているため、決済できませんでした。</p>※利用可能残数をご確認ください。</p>;
              this.setState({
                isShowError: true,
                titleError: '',
                contentError: elementContentText,
                textBtnError: '利用店舗画面に戻る',
                urlError: accessToken ? `/mypage/kodomo_use/search_shop/${shopId}?accessToken=${accessToken}&accessCode=${accessCode}${gochiKidGroupId ? `&gochiKidGroupId=${gochiKidGroupId}` : ''}` : `/mypage/kodomo_use/search_shop${gochiKidGroupId ? `?gochiKidGroupId=${gochiKidGroupId}` : ''}`
              });
            } else {
              this.props.showError(
                true,
                "",
                response?.responseData?.errorInfo?.content,
                `利用店舗画面に戻る`,
                accessToken ? `/mypage/kodomo_use/search_shop/${shopId}?accessToken=${accessToken}&accessCode=${accessCode}${gochiKidGroupId ? `&gochiKidGroupId=${gochiKidGroupId}` : ''}` : `/mypage/kodomo_use/search_shop${gochiKidGroupId ? `?gochiKidGroupId=${gochiKidGroupId}` : ''}`
              );
            }
          }
        }
        if (isFinished) {
          let tempShopId = this.state.shopId || this.state.shopIdInFinish || (sessionStorage.getItem("gochiKidUsed") ? JSON.parse(sessionStorage.getItem("gochiKidUsed")).shopId : "");
          let response = await this.getListSponsorGochiKid(accessToken, tempShopId, gochiKidGroupId);
          console.log('response', response);
          if (response && response.status === 200) {
            this.setState({
              listSponsor: response.data
            });
          } else {
            this.props.showError(
              true,
              "",
              response?.responseData?.errorInfo?.content
            );
          }
        }
      }
    }
  }

  async componentDidMount() {
    this.generateRandomString();
    const url = window.location.search;
    let params = queryString.parse(url);
    const gochiUserId = params.gochiUserId;
    const menuId = params.menuId;
    const shopId = params.shopId;
    const useAmount = params.useAmount;
    const totalUseAmount = params.totalUseAmount;
    const userKidIds = params.userKidIds;
    const accessToken = params.accessToken;
    const isFinished = params.isFinished;

    if (shopId) {
      this.setState({
        shopId: shopId,
      });
    }

    if (gochiUserId) {
      this.setState({
        gochiUserId: gochiUserId,
      });
    }

    if (menuId) {
      this.setState({
        menuId: menuId,
      });
    }

    if (useAmount) {
      this.setState({
        useAmount: useAmount,
      });
    }

    if (userKidIds) {
      this.setState({
        userKidIds: !Array.isArray(userKidIds) ? [userKidIds] : userKidIds,
      });
    }

    if (totalUseAmount) {
      this.setState({
        totalUseAmount: totalUseAmount,
      });
    }

    if (accessToken) {
      this.setState({
        accessToken: accessToken,
      });
    }

    if (isFinished) {
      this.setState({
        isFinished: isFinished,
        shopNameInFinish: sessionStorage.getItem("gochiKidUsed") ? JSON.parse(sessionStorage.getItem("gochiKidUsed")).shopName : (this.state.dataInfo.shopName || this.props.shopInfo?.shopName),
        menuNameInFinish: sessionStorage.getItem("gochiKidUsed") ? JSON.parse(sessionStorage.getItem("gochiKidUsed")).menuName : "",
        includedTaxPriceInFinish: sessionStorage.getItem("gochiKidUsed") ? JSON.parse(sessionStorage.getItem("gochiKidUsed")).includedTaxPrice : "",
        numberUseInFinish: sessionStorage.getItem("gochiKidUsed") ? JSON.parse(sessionStorage.getItem("gochiKidUsed")).numberUse : "",
        usedAt: sessionStorage.getItem("gochiKidUsed") ? JSON.parse(sessionStorage.getItem("gochiKidUsed")).usedAt : "",
        shopIdInFinish: sessionStorage.getItem("gochiKidUsed") ? JSON.parse(sessionStorage.getItem("gochiKidUsed")).shopId : "",
        totalUseAmountInFinish: sessionStorage.getItem("gochiKidUsed") ? JSON.parse(sessionStorage.getItem("gochiKidUsed")).totalUseAmount : "",
        imageLogoInFinish: sessionStorage.getItem('gochiKidUsed') ? JSON.parse(sessionStorage.getItem("gochiKidUsed")).imageLogo : null,
        barCodeYoshinoyaInFinish: sessionStorage.getItem('gochiKidUsed') ? JSON.parse(sessionStorage.getItem("gochiKidUsed")).barCodeYoshinoya : null,
        isAccess: true
      });
      if (accessToken) {
        this.setState({
          flowQrUserGochiKidPopupFinish: true
        });
      }
    } else {
      sessionStorage.removeItem("gochiKidUsed");
    }
  }

  async confirmUseGochiKid(shopId, menuId, useAmount, totalUseAmount, userKidIds, accessToken, gochiKidGroupId) {
    try {
      if (gochiKidGroupId) {
        const data = await GochiService.confirmGochiKidGroupSpecial(shopId, menuId, useAmount, totalUseAmount, userKidIds, gochiKidGroupId, accessToken);
        return data;
      } else {
        const data = await GochiService.confirmGochiKid(shopId, menuId, useAmount, totalUseAmount, userKidIds, accessToken);
        return data;
      }
    } catch (error) {
      log('confirm use gochi kid error');
      this.props.showError(true, this.props.t("There is something wrong"), "");
    }
  }

  async getListSponsorGochiKid(accessToken, shopId, gochiKidGroupId) {
    try {
      let params = {
        shopId
      }
      if (gochiKidGroupId) {
        const data = await GochiService.getSponsorGochiKidGroupSpecial(gochiKidGroupId, accessToken, params);
        return data;
      } else {
        const data = await GochiService.getSponsorGochiKid(accessToken, params);
        return data;
      }
    } catch (error) {
      log('confirm use gochi kid error');
      this.props.showError(true, this.props.t("There is something wrong"), "");
    }
  }

  async onSubmitConfirm() {
    this.setState({
      submitConfirm: true,
    });

    const shopId = this.state.shopId;
    const menuId = this.state.menuId;
    const useAmount = this.state.useAmount;
    const totalUseAmount = this.state.totalUseAmount;
    let userKidIds = this.state.userKidIds;
    const accessToken = this.state.accessToken;
    let url = window.location.search;
    let params = queryString.parse(url);
    let accessCode = params.accessCode;
    let gochiKidGroupId = params.gochiKidGroupId;
    let taxRate = this.state.taxRate;

    if (!Array.isArray(userKidIds)){
      userKidIds = [userKidIds];
    }

    try {
      this.props.showLoading(true); 
      let response = null;
      if (gochiKidGroupId) {
        response = await GochiService.useGochiKidGroupSpecial(
          {
            shopId,
            menuId,
            useAmount,
            totalUseAmount,
            userKidIds,
            gochiKidGroupId,
            taxRate
          },
          this.state.crfsToken,
          accessToken
        );
      } else {
        response = await GochiService.useGochiKid(
          {
            shopId,
            menuId,
            useAmount,
            totalUseAmount,
            userKidIds,
            taxRate
          },
          this.state.crfsToken,
          accessToken
        );
      }
      this.props.showLoading(false);

      if (response.status !== 200) {
        if (response.data) {
          if (response.data.code === "exception.errors.security.has.csrf.token") {
            this.generateRandomString();
            this.props.showError(
              true,
              "",
              this.props.t("other.Please wait for a while as it is being processed")
            );
          }
          if (response.data.code === "gochi.kid.only.poor.use") {
            this.props.showError(
              true,
              "",
              response?.responseData?.errorInfo?.content,
              `利用店舗画面に戻る`,
              accessToken ? `/mypage/kodomo_use/search_shop/${shopId}?accessToken=${accessToken}&accessCode=${accessCode}${gochiKidGroupId ? `&gochiKidGroupId=${gochiKidGroupId}` : ''}` 
                : `/mypage/kodomo_use/search_shop${gochiKidGroupId ? `?gochiKidGroupId=${gochiKidGroupId}` : ''}`
            );
          } else if (response.data.code === "exception.errors.external.coupon.exception") {
            this.props.showError(
              true,
              "",
              "クーポンコードが取得できませんでした。サポートセンターまで問合せください"
            );
          } else if (response.data.message) {
            this.props.showError(
              true,
              "",
              response.data.message
            );
          } else {
            this.props.showError(true, this.props.t("other.Unexpected error"), "");
          }
        }
        else 
        this.props.showError(true, this.props.t("other.Unexpected error"), "");
      } else {
        this.setState({
          isFinished: true,
          usedAt: response.data.usedAt,
          shopNameInFinish: this.state.dataInfo.shopName || this.props.shopInfo?.shopName,
          menuNameInFinish: this.state.dataInfo.menuName,
          includedTaxPriceInFinish: this.state.dataInfo.includedTaxPrice,
          numberUseInFinish: this.state.userKidIds.length,
          shopIdInFinish: shopId,
          imageLogoInFinish: response.data.imageLogo,
          barCodeYoshinoyaInFinish: response.data.barCodeYoshinoya
        });
        const paramUsed = {
          includedTaxPrice: this.state.dataInfo.includedTaxPrice ? this.state.dataInfo.includedTaxPrice : 0,
          menuName: this.state.dataInfo.menuName,
          shopName: this.state.dataInfo.shopName || this.props.shopInfo?.shopName,
          numberUse: this.state.userKidIds.length || 0,
          usedAt: response.data.usedAt,
          shopId: shopId,
          imageLogo: response.data.imageLogo,
          barCodeYoshinoya: response.data.barCodeYoshinoya,
        };
        paramUsed.totalUseAmount = this.state.totalUseAmount
          ? paramUsed.includedTaxPrice
          : paramUsed.includedTaxPrice * paramUsed.numberUse;
        const currentUrl  = window.location.href;
        let responseSponsor = await this.getListSponsorGochiKid(accessToken, shopId, gochiKidGroupId);
        if (responseSponsor && responseSponsor.status === 200) {
          this.setState({
            listSponsor: responseSponsor.data
          });
          sessionStorage.setItem('gochiKidUsed', JSON.stringify(paramUsed));
          this.props.history.push({
            pathname: '/kodomo/use',
            search: `${new URL(currentUrl).search}&isFinished=true`
          });
          if (accessToken) {
            this.setState({
              flowQrUserGochiKidPopupFinish: true
            });
          }
        } else {
          this.props.showError(
            true,
            "",
            responseSponsor?.responseData?.errorInfo?.content
          );
        }
      }
    } catch (error) {
      console.log("error in gochi kid use", error);
      if (error.code === "exception.errors.security.has.csrf.token") {
        this.props.showError(
          true,
          this.props.t("other.Please wait for a while as it is being processed"),
          ""
        );
      } else {
        this.props.showError(
          true,
          this.props.t("There is something wrong"),
          ""
        );
      }
    }

    this.setState({ showGochiUseConfirm: false });

    this.setState({
      submitConfirm: false,
    });
  }

  showGochiConfirm() {
    this.setState({ showGochiUseConfirm: true });
  }

  closeWindowScanQRUser() {
    window.close();
  }

  render() {
    if (!this.state.isAccess) {
      return (
      <>
        <div className="shop-not-avai text-center">
          <img
            src={NO_IMAGE}
            alt={this.props.t(
              "other.This feast button cannot be used because the store is not open"
            )}
            width="100%"
            className="image-not-available"
          />
        </div>

        {this.state.isShowError && (
          <PopupErrorV2 
            showing={this.state.isShowError}
            hideError={() => this.setState({isShowError: false})}
            title={this.state.titleError}
            content={this.state.contentError}
            gotoUrl={this.state.urlError}
            buttonName={this.state.textBtnError}
          />
        )}
      </>);
    }

    const { t } = this.props;
    const dataInfo = this.state.dataInfo;
    const shopName = this.state.dataInfo.shopName || this.props.shopInfo?.shopName;
    const includedTaxPrice = this.state.dataInfo.includedTaxPrice;
    let totalUseAmount = this.state.totalUseAmount
      ? includedTaxPrice
      : includedTaxPrice * this.state.userKidIds.length;
    if (this.state.isFinished && this.state.totalUseAmountInFinish) {
      totalUseAmount = this.state.totalUseAmountInFinish;
    }

    return (
      <>
        <div className="content font-gothic clr-000000">
          <Header
            title={t("pages.tome.Use this ticket")}
            isLoggedIn={this.state.isLoggedIn}
            t={t}
            hasBack={true}
            usingFlow={true}
          />
          <div className="input-infor">
            <div className="fs-22 font-weight-bold my-5 text-center">
              {this.state.isFinished ? 'お支払い完了' : 'ご注文内容の確認'}
            </div>
            <div className="used-price-box mt-3">
              <div className="top">
                {this.state.isFinished ? this.state.shopNameInFinish : shopName}
              </div>
              <div className="mid mt-3 mb-2">
                <span
                  className={
                    totalUseAmount < 1000000
                      ? 'fs-72 line-height-72'
                      : 'fs-48 line-height-48'
                  }
                >
                  {/* {this.state.isFinished && this.state.includedTaxPriceInFinish ? (this.state.includedTaxPriceInFinish ? (this.state.includedTaxPriceInFinish * this.state.numberUseInFinish).toLocaleString() : '') : (includedTaxPrice ? (includedTaxPrice * this.state.userKidIds.length).toLocaleString() : '')} */}
                  {totalUseAmount ? totalUseAmount.toLocaleString() : ""}
                </span>
                <span className="fs-24 line-height-24 fw-700">円</span>
              </div>
              <Moment
                className="bot"
                interval={1000}
                format="YYYY.MM.DD HH:mm:ss"
                local
              />
            </div>

            <div className="pt-2">
              {this.state.isFinished && this.state.barCodeYoshinoyaInFinish && !(this.state.accessToken || this.state.accessCode) && (
                <div>
                  <Barcode value={this.state.barCodeYoshinoyaInFinish} options={{ format: 'codabar' }} className="barcode" />
                </div>
              )}

              <div className="mt-2 fs-18 font-weight-bold text-break">
                {this.state.isFinished ? this.state.menuNameInFinish : dataInfo.menuName}
              </div>

              {this.state.totalUseAmount
                ? 
                  <div className="fs-18 font-weight-medium infor-price-gochi-kid">
                    <span className="title">利用人数: </span>
                    <span className="title">{this.state.isFinished ? this.state.numberUseInFinish : this.state.userKidIds.length}</span>
                    <span className="fs-12 yen">人</span>
                    <span className="ml-4 title">合計金額: </span>
                    <span className="title">{totalUseAmount ? totalUseAmount.toLocaleString() : ""}</span>
                    <span className="fs-12 yen">円</span>
                  </div>
                :
                  <div className="fs-18 font-weight-medium infor-price-gochi-kid">
                    <span className="title">単価: </span>
                    <span className="title">
                      {this.state.isFinished && this.state.includedTaxPriceInFinish ? this.state.includedTaxPriceInFinish.toLocaleString() : (includedTaxPrice ? (includedTaxPrice).toLocaleString() : '')}
                    </span>
                    <span className="fs-12 yen">円</span>
                    <span className="ml-4 title">個数: </span>
                    <span className="title">{this.state.isFinished ? this.state.numberUseInFinish : this.state.userKidIds.length}</span>
                    <span className="fs-12 yen">個</span>
                  </div>
              }
              {this.state.isFinished ? (
                <>
                  {this.state.imageLogoInFinish ? (
                    <div>
                      <div className="text-title-sponsor">
                        このお食事は、下記企業・店舗さまからご支援いただいています
                      </div>
                      <div className="sponsor">
                        <div className="row content">
                          <div className="list-rank-1 col-12">
                            <span className="link-rank-1">
                              <img src={this.state.imageLogoInFinish ? this.state.imageLogoInFinish : NO_IMAGE} alt={this.state.shopNameInFinish} width="100%" className="image-sponsor" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {/*<div className="finished-note">
                        ご利用いただいたメニュー内容については、登録メールアドレス宛に送信いたしましたので、ご確認ください。
                        <br />
                        ※「@gochimeshi.com」のドメインからメールを送信します。
                      </div>*/}
                      {(this.state.listSponsor?.listRank1?.length > 0 || this.state.listSponsor?.listRank2?.length > 0 || this.state.listSponsor?.listRank3?.length > 0) &&(
                        <div className="text-title-sponsor">
                          このお食事は、個人のご寄付や以下の皆さまからご支援いただいています
                        </div>
                      )}
                      <div className="sponsor">
                        <div className="row content">
                        {Object.entries(this.state.listSponsor).map(([key, obj]) => {
                          if (key === 'listRank1' && obj.length > 0) {
                            return (
                              obj.map(i => (
                                <div className="list-rank-1 col-12" key={i.gochiKidCompanyFundId}>
                                  <a href={i.pageUrl} target="_blank" className="link-rank-1">
                                    <img src={i.logoImage ? i.logoImage : NO_IMAGE} alt={i.companyName} width="100%" className="image-sponsor" />
                                  </a>
                                </div>
                              ))
                            )
                          }
                        }
                        )}
                        </div>
                      </div>
                      <div className="sponsor">
                        <div className="row content">
                        {Object.entries(this.state.listSponsor).map(([key, obj]) => {
                          if (key === 'listRank2' && obj.length > 0) {
                            return (
                              obj.map(i => (
                                <div className="list-rank-2 col-6" key={i.gochiKidCompanyFundId}>
                                  <a href={i.pageUrl} target="_blank" className="link-rank-2">
                                    <img src={i.logoImage ? i.logoImage : NO_IMAGE} alt={i.companyName} width="100%" className="image-sponsor" />
                                  </a>
                                </div>
                              ))
                            )
                          }
                        }
                        )}
                        </div>
                      </div>
                      <div className="sponsor">
                        <div className="row content">
                        {Object.entries(this.state.listSponsor).map(([key, obj]) => {
                          if (key === 'listRank3' && obj.length > 0) {
                            return (
                              obj.map(i => (
                                <div className="list-rank-3 col-4" key={i.gochiKidCompanyFundId}>
                                  <a href={i.pageUrl} target="_blank" className="link-rank-3">
                                    <img src={i.logoImage ? i.logoImage : NO_IMAGE} alt={i.companyName} width="100%" className="image-sponsor" />
                                  </a>
                                </div>
                              ))
                            )
                          }
                        }
                        )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {(this.state.dataInfo.usageType === MENU_USAGE_TYPE.USAGE_TYPE_VOUCHER || !this.state.menuId) && (
                    <div className="mt-40">
                      <div className="required-dot mb-3-custome">
                        <div className="dot-red"></div>
                        <div className="dot-text-label fs-16 font-weight-bold">
                          消費税区分
                        </div>
                      </div>
                      <div className="d-flex">
                        <label className="radio-custom">
                          <input
                            type="radio"
                            name="taxRate"
                            value={TAX_RATE.TAX_RATE_10_PERCENT}
                            onChange = {(e) => {
                              this.setState({
                                taxRate: TAX_RATE.TAX_RATE_10_PERCENT
                              });
                            }}
                            checked={this.state.taxRate === TAX_RATE.TAX_RATE_10_PERCENT}
                          />
                          <span className="checkmark"></span>
                          <span className="fs-16">10%対象</span>
                        </label>
                        <label className="radio-custom ml-5">
                          <input
                            type="radio"
                            name="taxRate"
                            value={TAX_RATE.TAX_RATE_8_PERCENT}
                            onChange = {(e) => {
                              this.setState({
                                taxRate: TAX_RATE.TAX_RATE_8_PERCENT
                              });
                            }}
                            checked={this.state.taxRate === TAX_RATE.TAX_RATE_8_PERCENT}
                          />
                          <span className="checkmark"></span>
                          <span className="fs-16">8%対象</span>
                        </label>
                      </div>
                    </div>
                  )}
                  <div className="mt-5 clr-F44A4A font-weight-bold">
                    ※オーダー確認ボタンはお店の方の指示に従うか、お客様ご自身でタップしてください。
                  </div>
                </>
              )}
            </div>
            {this.state.isFinished ? (
              <a
                className="btn border-btn-green mt-3 mb-3"
                href="/mypage/kodomo_use"
              >マイページに戻る</a>
            ) : (
            <>
              <div
                className="btn btn-use-55 bg-red mt-3"
                onClick={() => this.showGochiConfirm(dataInfo)}
              >オーダー確認</div>
              <div
                className="btn border-btn mt-3"
                onClick={() => this.props.history.goBack()}
              >戻る</div>
              <div className="gochi-stamp-combo mt-4">
                <div className="gochi-stamp-combo-info border-radius-0">
                  <div>
                    <div><span className="fs-12 clr-000000">チケット利用問合先 : </span><a href="tel:050-5536-3317" className="fs-12 clr-F44A4A text-decoration-underline">050-5536-3317</a></div>
                    <div className="fs-12 text-red">※サポートセンターへの問い合わせ電話です。ご予約は、直接店舗様にお願いいたします。</div>
                    <div className="fs-12 clr-000000">平日（月～金） 10:00～18:00／土曜日 10:00～15:00</div>
                    <div className="fs-12 clr-000000">※お休み：日曜日・祝日 ・長期休暇・お盆休み・年末年始</div>
                  </div>
                </div>
              </div>
            </>
            )}
          </div>
        </div>

        {this.state.showGochiUseConfirm && (
          <GochiKidUseConfirmModal
            show={this.state.showGochiUseConfirm} 
            onCancel={() => this.setState({ showGochiUseConfirm: false })}
            disabledSubmitButton={this.state.submitConfirm}
            shopName={shopName}
            includedTaxPrice={totalUseAmount}
            onSubmit={this.onSubmitConfirm}
          />
        )}
        {this.state.flowQrUserGochiKidPopupFinish && (
          <Modal show={this.state.flowQrUserGochiKidPopupFinish} className="modal-error-gochi-charge" centered> 
            <div className="modal-body"> 
              <Button variant="secondary" className="close" onClick={() => {this.setState({flowQrUserGochiKidPopupFinish: false})}}>&times;</Button>
    
              <div className="input-infor">
                <div >
                  <h5 className="space-pre-line title-error-gochi-charge">メニューの消し込みが完了しました。「管理画面に戻る」をタップしGOCHIプラットフォーム管理システムに戻ります。</h5>
                  <button className="btn btn-green-38 w-240 m-auto mt-3 btn-error-gochi-charge" onClick={() => this.closeWindowScanQRUser()}>
                    管理画面に戻る
                  </button>
                </div>
              </div>
            </div> 
          </Modal>
        )}

        {this.state.isShowError && (
          <PopupErrorV2 
            showing={this.state.isShowError}
            onHide={() => this.setState({isShowError: false})}
            title={this.state.titleError}
            content={this.state.contentError}
            gotoUrl={this.state.urlError}
            buttonName={this.state.textBtnError}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content, buttonName, gotoUrl) =>
    dispatch(showError(showing, title, content, buttonName, gotoUrl)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GochiKidUse))
);

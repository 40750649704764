import React from "react";
import { connect, useSelector } from "react-redux";
import Header from "../../GochiKid/layout/Header";
import { BoxHomePageButton } from "../common/BoxHomePageButton";

export function HomePage(props) {
  const userInfo = useSelector((state) => state.userInfo);

  return (
    <>
      <Header />
      <div className="evocation-block">
        <div className="evocation-head-block">
          <div className="text-evocation font-family-rounded">
            地域の飲食店がこども食堂に
            <br />
            〜 こどもごちめし 〜
          </div>
          <div className="box-button">
            <button className="btn-tertiary mr-5">
              <i className="icon-support" />
              食堂支援する
            </button>
            <button className="btn-outline-tertiary">
              <i className="icon-use" />
              食堂利用する
            </button>
          </div>
        </div>
      </div>

      <div className="mx-200px introduce-block result-block">
        <div className="background-ED808C title-introduct">
          <div className="background-FDF5F5 title-introduct-insite">
            <span className="color-ED808C">「こどもごちめし」</span>
            とは？
          </div>
        </div>
        <div className="content-result">
          <div className="header-content-result">
            <img src="/assets/img/gochi-kid/logo_gochi_kid.png" alt="logo gochi kid"/>
            <div className="text-header-content font-family-rounded">
              <span className="color-ED808C">「地域の飲食店を起点</span>
              <span>にこどもの居場所をつくり、</span>
              <br/>
              <span>まちの未来を育む</span>
              <span className="color-ED808C">こども食堂サービス」</span>
              <span>です。</span>
            </div>
          </div>
          <div className="body-content-result">
            <div className="box-result">
              <div className="title-box color-ED808C">「 食堂ご支援者へ 」</div>
              <img src="/assets/img/gochi-kid/image_introduct_1.jpg" alt="result block 1"/>
              <div className="description-box">
              支援した食堂からの
              <span className="color-35A99E">感謝メッセージが届く</span>
              ので、自分の支援が実りあるものであることを実感できます。
              <br/>
              <span className="color-35A99E">地域の子どもたちに笑顔を届ける支援</span>
              に、あなたも参加しませんか？
              </div>
            </div>
            <div className="box-result">
              <div className="title-box color-ED808C">「 食堂ご利用者へ 」</div>
              <img src="/assets/img/gochi-kid/image_introduct_1.jpg" alt="result block 2"/>
              <div className="description-box">
              「こどもごちめし」は、
              <span className="color-35A99E">地域の飲食店を利用</span>
              でき、好きな時間・日にこども食堂として利用可能です。食事とともに、
              <span className="color-35A99E">地域とのつながりを深め</span>
              、心豊かな時間を過ごしませんか？
              </div>
            </div>
          </div>
        </div>
      </div>

      <BoxHomePageButton />
      
      <div className="mx-200px introduce-block sponsor-introduce-block">
        <div className="background-ED808C title-introduct">
          <div className="background-FDF5F5 title-introduct-insite">
            <span className="color-ED808C">協賛企業様 </span>
            のご紹介
          </div>
        </div>
      </div>
      
      <div className="mx-200px evidence-block">
        <div className="background-ED808C title-introduct">
          <div className="background-FDF5F5 title-introduct-insite">
            食堂支援・利用の
            <span className="color-ED808C"> 仕組み</span>
          </div>
        </div>
        <div className="content-evidence">
          <div className="header-content-evidence">
            皆様からいただいた支援金を電子チケットにして、飲食店で利用することが可能です。
            <br />
            <span className="color-35A99E">また、飲食店、ご利用者様共に手数料金をいただいておりません。</span>
          </div>
          <div className="body-content-evidence">
            <img src="/assets/img/gochi-kid/image_diagram_home_page_pc.png" alt="diagram home page pc"/>
          </div>
        </div>
      </div>

      <BoxHomePageButton />

      <div className="mx-200px introduce-block resonance-block">
        <div className="background-ED808C title-introduct">
          <div className="background-FDF5F5 title-introduct-insite">
            <span className="color-ED808C">導入・利用 </span>
            事例
          </div>
        </div>
        <div id="carouselHomePage" className="carousel slide content-resonance" data-ride="carousel">
          <div className="carousel-inner">
            <div class="carousel-item carousel-box active">
              <div className="row">
                <div className="col-4 image-carousel">
                  <img className="w-100" src="/assets/img/gochi-kid/image_carousel_1.png" />
                </div>
                <div className="col-8 header-carousel">
                  <div className="font-family-rounded color-ED808C title-carousel">茨城県猿島郡境町</div>
                  <div>2019年3月 開始</div>
                  <div>全国で初めて「ふるさと納税」寄付金の使い道としてごちめしを活用したこども食堂への支援を開始</div>
                </div>
              </div>
              <div>
                自治体が運営するこども食堂として、運営資金に全国で初めて「ふるさと納税」への寄付金の使い道として当社のプラットフォームを活用したこども食堂への支援を開始しました。新型コロナウイルスの影響を受けている地域の飲食店の食事を子どもたちに無料で提供しています。
                <br />
                境町と当社のこども食堂運営の取り組みは、「地方創生SDGS官民連携プラットフォーム」の2021年 優良事例に選出されました。
              </div>
            </div>
          </div>
          <a className="carousel-control-prev" href="#carouselHomePage" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#carouselHomePage" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
          <ol className="carousel-indicators">
            <li data-target="#carouselHomePage" data-slide-to="0" class="active"></li>
            <li data-target="#carouselHomePage" data-slide-to="1"></li>
            <li data-target="#carouselHomePage" data-slide-to="2"></li>
          </ol>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

export default connect(mapStateToProps, null)(HomePage);
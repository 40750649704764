import React, { Component } from "react";
import { Redirect} from 'react-router-dom';
import queryString from 'query-string';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faMapMarkerAlt, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'; 
import { Modal } from 'react-bootstrap';
// import { Card, Button, Media, ListGroup } from 'react-bootstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {connect} from 'react-redux';
import {showLoading} from "../../../actions/action_loading";
import {showError} from "../../../actions/action_error_alert";
import { withTranslation } from 'react-i18next';
import ShopService from "../../../api/services/ShopService";
import MenuService from "../../../api/services/MenuService";
import Header from "../../layout/header/Header";
// import GochiUseInfor from "../../common/GochiUse/GochiUseInfor";
import GochiUseStaff from '../../common/GochiUse/GochiUseStaff';
import Coupons from "./Coupons";
import ListShop from '../../common/ListShop';
import * as constants from '../../../constants';
import CouponService from "../../../api/services/CouponService";

class MenuComboDetail extends Component {
	constructor(props){
    super(props);
    this.state = {
      textButtonSort: "現在地からの距離",
      gochiDetail: {},
      listShop: [],
      shopCount: 0,
      hasMore: false,
      page: 1,
      menuId: 0,
      sort: 'distance',
      showGochiUse: false,
      gochiInfo: {},
      latitude: 0,
      longitude: 0,
      childFoods: null,
      showGochiUseStaff: false,
      quantity: 1,
      includedTaxPrice: 0,
      showListShopPopup: false,
      messageText: null,
      messageTextError: null,
      disabledBtnSendMessage: true,
      showConfirmMessagePopup: false,
      sentMessage: null,
      sentMessageToGiver: false,
      userInfo: queryString.parse(window.location.search).accessToken ? {isGuest: false, isLogined: true, userId: JSON.parse(atob(queryString.parse(window.location.search).accessToken.split(".")[1])).uid} : (this.props.userInfo ? this.props.userInfo : JSON.parse(localStorage.getItem('userInfo'))),
      isLoggedIn: this.props.userInfo?.userId ? true : false,
      accessToken: queryString.parse(window.location.search).accessToken,

    }
	  this.getMenuCombo = this.getMenuCombo.bind(this);
    this.loadMoreShop = this.loadMoreShop.bind(this);
    this.getListShops = this.getListShops.bind(this);
    this.onCopy = this.onCopy.bind(this);
    this.onFavouriteShop = this.onFavouriteShop.bind(this);
    this.onOpenGochiUse = this.onOpenGochiUse.bind(this);
    this.onCloseGochiInfo = this.onCloseGochiInfo.bind(this);
    this.onCloseGochiStaff = this.onCloseGochiStaff.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOpenConfirmPopup = this.handleOpenConfirmPopup.bind(this);
    this.handleSendMessage = this.handleSendMessage.bind(this);
    this.onCloseConfirmMessagePopup = this.onCloseConfirmMessagePopup.bind(this);
    this.handleShowListShops = this.handleShowListShops.bind(this);
    this.handleCloseListShopPopup = this.handleCloseListShopPopup.bind(this);
    this.openMap = this.openMap.bind(this);
    this.hideGochiCountError = this.hideGochiCountError.bind(this);
    this.checkAccessScreen = this.checkAccessScreen.bind(this);
    this.getDataDidMount = this.getDataDidMount.bind(this);
  }

  handleShowListShops() {
		this.setState({
			showListShopPopup: true
		});
	}

  handleCloseListShopPopup() {
		this.setState({
			showListShopPopup: false
		});
	}

  handleChange = (e) => {
    let mess = e.target.value;
    console.log('message to buyer ===', mess);
    if (mess.trim() !== '') {
        this.setState({
            disabledBtnSendMessage: false
        });
    } else {
        this.setState({
            disabledBtnSendMessage: true
        });
    }
    this.setState({
        messageText: mess
    });
  }

  handleOpenConfirmPopup() {
    const { t } = this.props;

    if (this.state.messageText.length < 1 || this.state.messageText.length > 200) {
      this.setState({
          showConfirmMessagePopup: false,
          messageTextError: t('pages.invite.Please enter a message within 200 characters')
      });
    } else {
      this.setState({
          showConfirmMessagePopup: true,
          messageTextError: null
      });
    }
  }

  async handleSendMessage() {
    let gochiBillId = this.state.gochiDetail.gochiBillId;
    let couponId = null;
    this.props.showLoading(true);
    try {
        const { data } = await CouponService.sendReviewMessageToReceivedUser(this.state.messageText, couponId, gochiBillId);
        console.log('data ===', data);
        this.props.showLoading(false);
        this.setState({
            showConfirmMessagePopup: false,
            sentMessageToGiver: true,
            sentMessage: this.state.messageText
        });
    } catch(error) {
        console.log(error);
        this.props.showLoading(false);
        this.props.showError(true, this.props.t("There is something wrong"), "");
    }
  }

  onCloseConfirmMessagePopup() {
    this.setState({
        showConfirmMessagePopup: false
    });
  }

  getMenuCombo = async (menuId, gochiUserId, gochiBillId, accessToken) => {
    this.props.showLoading(true);
    try {
      const checkTokenExpired = accessToken ? 1 : null;
      const { data } = await MenuService.getMenuCombo(menuId, gochiUserId, gochiBillId, accessToken, checkTokenExpired);
      this.props.showLoading(false);
      return data;
    } catch (error) {
      console.log(error);
      this.props.showLoading(false);
      this.props.showError(true, this.props.t("pages.login.There is something wrong"), "");
    }
  }

  getShopDetail = async (shopId) => {
    this.props.showLoading(true);
    try {
      const { data } = await ShopService.getDetailShop(shopId);
      this.props.showLoading(false);
      return data;
    } catch (error) {
      console.log(error);
      this.props.showLoading(false);
      this.props.showError(true, this.props.t("pages.login.There is something wrong"), "");
    }
  }

  getGroupDetail = async (groupId) => {
    this.props.showLoading(true);
    try {
      const { data } = await ShopService.getDetailGroup(groupId);
      this.props.showLoading(false);
      return data;
    } catch (error) {
      console.log(error);
      this.props.showLoading(false);
      this.props.showError(true, this.props.t("pages.login.There is something wrong"), "");
    }
  }

  getCoords() {
    var options = {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0
    };

    this.props.showLoading(true);

    return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(
            (success) => {
                this.props.showLoading(false);
                resolve(success);
            },
            (error) => {
                this.props.showLoading(false);
                if (error.code === 1) {
                  resolve(null);
                } else {
                    reject(error);
                }
            },
            options)
    )
  }

  getListShops = async (menuId, loginUserId, sort, page, latitude, longitude) => {
	this.props.showLoading(true);
    try {
      const accessToken = this.state.accessToken;
      const checkTokenExpired = this.state.accessToken ? 1 : null;
      const { data } = await ShopService.getListlShopsCmm(menuId, loginUserId, sort, page, latitude, longitude, null, accessToken, checkTokenExpired);
      const listShop = this.state.listShop;
      data.list &&
        data.list.map(shop => {
          return listShop.push(shop);
        });

      this.setState({
        listShop: listShop,
        childFoods: data.childFoods,
        shopCount: data.count
      });
      if (listShop.length < data.count) {
        this.setState({
          hasMore: true
        });
      } else {
        this.setState({
          hasMore: false
        });
      }
      this.props.showLoading(false);
      // return listShop;
    } catch (error) {
      console.log('error getListShops', error);
      this.props.showLoading(false);
      this.props.showError(true, this.props.t("pages.login.There is something wrong"), "");
    }
  };

  checkAccessScreen = async () => {
      let url = window.location.search;
      let params = queryString.parse(url);
      let accessToken = params.accessToken;
      let menuId = params.menuId;
      let gochiBillId = params.gochiBillId;
      let gochiUserId = params.gochiUserId;
      let shopIdParam = params.shopId;
      if (!accessToken) {
          var link = "/login";
          if (!this.state.isLoggedIn){
              sessionStorage.setItem('loginCallbackUrl', window.location.pathname);
              return window.location.href = link;
          } else {
              console.log('this.state.userInfo', this.state.userInfo);
              if (this.state.userInfo) { 
                  if (this.state.userInfo.isLogined === false || this.state.userInfo.isGuest) { 
                      // window.history.pushState(null, null, '/login');
                      // return <Redirect to={link} />
                  }
                  this.getDataDidMount(this.state.userInfo, menuId, gochiBillId, gochiUserId, shopIdParam);
              }else{
                  // window.history.pushState(null, null, '/login');
                  // return <Redirect to={link} />
              }
          }
      } else {
          if (this.state.isLoggedIn){
              sessionStorage.clear();
              localStorage.clear();
          }
          this.getDataDidMount(this.state.userInfo, menuId, gochiBillId, gochiUserId, shopIdParam, accessToken);
      }
  }
    
	UNSAFE_componentWillMount(){ 
        this.checkAccessScreen();
  }

  async getDataDidMount(userInfo, menuId, gochiBillId, gochiUserId, shopIdParam, accessToken){
    if (userInfo) {
      if (userInfo.isLogined && !userInfo.isGuest) {
        const dataGochi = await this.getMenuCombo(menuId, gochiUserId, gochiBillId, accessToken);
        const shopDetail = dataGochi.shop;
        const groupDetail = dataGochi.group;
        const shopId = shopDetail? shopDetail.shopId: null;
        const groupId = groupDetail? groupDetail.groupId: null;

        const loginUserId = userInfo.userId;

        const isMultiUsedMenu = dataGochi.menu && (dataGochi.menu.menuChildType === 3 || dataGochi.menu.menuChildType === 4 || dataGochi.menu.menuChildType === 5);
        if (groupId) {
            this.props.showLoading(true);
            let latitude = null;
            let longitude = null;
            // if (navigator.geolocation) {
            //     const position = await this.getCoords();

            //     console.log(position);

            //     if (position && position.coords) {
            //         latitude = position.coords.latitude;
            //         longitude = position.coords.longitude;
            //     }
            // }

            try {
              const position = await this.getCoords();
              console.log('ggg=====', position);

              if (position && position.coords) {
                latitude = position.coords.latitude;
                longitude = position.coords.longitude;
              }
              this.setState({
                loading: false
              });
            } catch (e) {
              this.setState({
                  loading: false
              });
              console.log('======================', e);
            }

            this.setState({
                latitude: latitude,
                longitude: longitude
            });
            this.props.showLoading(false);
            await this.getListShops(dataGochi.menuId, loginUserId, this.state.sort, this.state.page, latitude, longitude);
        }

        this.setState({
          gochiDetail: dataGochi,
          isMultiUsedMenu: isMultiUsedMenu,
          shopId: shopId,
          shopDetail: shopDetail,
          groupId: groupId,
          groupDetail: groupDetail,
          isFromQr: gochiBillId? false: true,
          shopIdParam: shopIdParam,
          menuId: menuId,
          sentMessageToGiver: dataGochi.couponMessageDto && dataGochi.couponMessageDto.message ? true : false,
          sentMessage: dataGochi.couponMessageDto && dataGochi.couponMessageDto.message ? dataGochi.couponMessageDto.message : ''
        });
      }
    }
  }

  async componentDidMount() {
    //
  }

  async loadMoreShop() {
    const userInfo = this.state.userInfo;
    try {
      await this.getListShops(
                              this.state.menuId,
                              userInfo.userId,
                              this.state.sort,
                              this.state.page + 1,
                              this.state.latitude,
                              this.state.longitude
                            );
      this.setState({
        page: this.state.page + 1
      });
    } catch (error) {
      console.log('error loadMoreShop', error);
    }
  }

  onCopy(e){
    this.setState({copied: true});
  };

  async onFavouriteShop(shopId, isFavorite){
    const userId = this.state.userInfo && this.state.userInfo.userId;
    this.props.showLoading(true);
    let data = null;
    try {
      if (isFavorite) {
        data = await ShopService.destroyFavoriteShop(userId, shopId);
      } else {
        data = await ShopService.createFavoriteShop(userId, shopId);
      }

      if (data.status === 200) {
        this.setState({
          listShop: [],
          shopCount: 0,
          page: 1
        });
        await this.getListShops(
                                this.state.menuId,
                                userId,
                                this.state.sort,
                                1,
                                this.state.latitude,
                                this.state.longitude
                              );
      } else {
        this.props.showError(true, data.message, "");
      }
      this.props.showLoading(false);
      // return data;
    } catch (error) {
      this.props.showLoading(false);
      this.props.showError(true, this.props.t("pages.login.There is something wrong"), "");
    }
  };


  hideGochiCountError() {
		this.setState({gochiCountError: ''});
	}

  onOpenGochiUse(gochiInfo) {
    console.log('onOpenGochiUse', gochiInfo.gochiCountAvailable);
    if (gochiInfo.gochiCountAvailable < 1) {
      this.setState({
          gochiCountError: this.props.t('other.Ticket unavailable')
      });
      return;
  }
  	if (gochiInfo.isETicket === 0) {
		this.setState({
			gochiCountError: this.props.t('other.Prevent paper ticket')
		});
		return;
	}
    const url = window.location.search;
    let params = queryString.parse(url);
    const shopId = params.shopId;
    const gochiUserId = params.gochiUserId;

    const gochiDetail = this.state.gochiDetail;
    if (gochiDetail.canUse === false) {
      this.props.showError(true, "", this.props.t('other.This ticket can only be used once a day'));
    } else {
      this.setState({
        showGochiUseStaff: true,
        // showGochiUse: true,
        gochiInfo: gochiInfo
      });

      let accessToken = params.accessToken ? "&accessToken=" + params.accessToken : '';
      var usingUrl = "/gochiuse/staff";
      sessionStorage.setItem('quantityWillBeUsed', this.state.quantity);
      sessionStorage.setItem('usingFlowGochiInfo', JSON.stringify(gochiInfo));
      if(shopId) {
        window.location.href = usingUrl+'?shopId='+shopId+'&menuId='+gochiInfo.menuId+'&gochiUserId='+gochiUserId+accessToken;
      } else {
        window.location.href = usingUrl+'?gochiBillId='+gochiInfo.gochiBillId+'&menuId='+gochiInfo.menuId+'&gochiUserId='+gochiUserId+accessToken;
      }
    }
    this.handleCloseListShopPopup();
  }

  onCloseGochiInfo() {
		this.setState({
			showGochiUse: false,
		})
  }

  onCloseGochiStaff() {
		this.setState({
			showGochiUseStaff: false
		});
  }

  openMap() {
    let lat = this.state.gochiDetail.shop && this.state.gochiDetail.shop.latitude ? this.state.gochiDetail.shop.latitude : '';
    let long = this.state.gochiDetail.shop && this.state.gochiDetail.shop.longitude ? this.state.gochiDetail.shop.longitude : '';
    window.open("https://maps.google.com?q="+lat+","+long, "_blank");
  }

  render() {
    const { t } = this.props;

    // const onFavouriteShop = this.onFavouriteShop;
    const onOpenGochiUse = this.onOpenGochiUse;
    const menuName = this.state.gochiDetail.gochiType === 'PRICE' ?
      t('pages.menu.searchResult') : (this.state.gochiDetail.menu && this.state.gochiDetail.menu.menuName);
    const menuId = this.state.gochiDetail.menuId;
    let menuImage = require("../../../assets/img/icons/gochi_noimg-ver4.jpg");
    if (this.state.gochiDetail.gochiType === 'PRICE') {
      menuImage = require("../../../assets/img/icons/gochi_price.jpg");
    } else {
      menuImage =   this.state.gochiDetail.menu &&
                    this.state.gochiDetail.menu.menuImage &&
                    this.state.gochiDetail.menu.menuImage.length > 0 ?
                    this.state.gochiDetail.menu.menuImage[0].image :
                    require("../../../assets/img/icons/gochi_noimg-ver4.jpg");
    }

    const userImage = this.state.gochiDetail.gochiRegister && this.state.gochiDetail.gochiRegister.userImages
                      && this.state.gochiDetail.gochiRegister.userImages.length > 0?
                      this.state.gochiDetail.gochiRegister.userImages[0].image :
                      require("../../../assets/img/icons/gochi_noimg-ver4.jpg");
    const userName = this.state.gochiDetail.gochiRegister && this.state.gochiDetail.gochiRegister.displayName;
    const gochiBillMessage = this.state.gochiDetail.gochiBillMessage;

    // const includedTaxPrice = this.state.gochiDetail.menu && this.state.gochiDetail.menu.includedTaxPrice;
    const excludedTaxPrice = this.state.gochiDetail.menu && this.state.gochiDetail.menu.excludedTaxPrice;
    const gochiCount = this.state.gochiDetail.gochiCount;
    const gochiExpiredDay = this.state.gochiDetail.gochiExpiredDay;
    const menuChildType = this.state.gochiDetail.menu && this.state.gochiDetail.menu.menuChildType;
    const childFoods = this.state.childFoods ? this.state.childFoods : [];
    const shopId = this.state.isFromQr? this.state.shopIdParam: (this.state.gochiDetail && this.state.gochiDetail.shopId);
    const shopName = this.state.shopDetail && this.state.shopDetail.shopName;
    const groupId = this.state.groupDetail && this.state.groupDetail.groupId;
    // const shopAddress = this.state.shopDetail && (this.state.shopDetail.prefectures + ' ' + this.state.shopDetail.city+ ' ' + this.state.shopDetail.address + ' ' + this.state.shopDetail.building);
    // const freeTicket = this.state.gochiDetail.coupons && this.state.gochiDetail.coupons.filter(coupon => (!coupon.taxAmount) && (!coupon.totalAmount) && (!coupon.useDate));
    const freeTicketCount = this.state.gochiDetail.menu && this.state.gochiDetail.menu.menuComboDetail && this.state.gochiDetail.menu.menuComboDetail.quantityFree ? this.state.gochiDetail.menu.menuComboDetail.quantityFree : 0;
    const description = this.state.gochiDetail.menu && this.state.gochiDetail.menu.description;
    const quantityFreeDes = this.state.gochiDetail.menu && this.state.gochiDetail.menu.menuComboDetail && this.state.gochiDetail.menu.menuComboDetail.quantityFreeDes;
    const menuQuantity = this.state.gochiDetail.menu && this.state.gochiDetail.menu.menuComboDetail && this.state.gochiDetail.menu.menuComboDetail.quantity;
    // const totalShopsBelongToGroup = this.state.groupDetail && this.state.groupDetail.shopCount; 
    const priceCombo = this.state.gochiDetail.menu && this.state.gochiDetail.menu.menuComboDetail && this.state.gochiDetail.menu.menuComboDetail.priceCombo;
    const includedTaxPriceCombo = this.state.gochiDetail.menu && this.state.gochiDetail.menu.menuComboDetail && this.state.gochiDetail.menu.menuComboDetail.includedTaxPriceCombo;
    const displayAmount = this.state.gochiDetail?.menu?.displayAmount;
    const displayAmountOneTicket = displayAmount ? Math.round(displayAmount / menuQuantity) : includedTaxPriceCombo;
    const menuComboExpiration = this.state.gochiDetail.menu && this.state.gochiDetail.menu.menuComboDetail && this.state.gochiDetail.menu.menuComboDetail.expiration;
    const menuComboExpirationEndDate = this.state.gochiDetail.menu && this.state.gochiDetail.menu.menuComboDetail && this.state.gochiDetail.menu.menuComboDetail.expirationEndDate ? new Date(this.state.gochiDetail.menu.menuComboDetail.expirationEndDate) : null;
    const numberUsePerDay = this.state.gochiDetail.menu && this.state.gochiDetail.menu.menuComboDetail && this.state.gochiDetail.menu.menuComboDetail.numberUsePerDay;

    const giftCardMessage = this.state.gochiDetail && this.state.gochiDetail.gochiBillMessage && this.state.gochiDetail.gochiBillMessage.message;
    const giftCardImage = this.state.gochiDetail && this.state.gochiDetail.giftCard && this.state.gochiDetail.giftCard.image;
    const feePaidByShop = this.state.gochiDetail?.menu?.feePaidByShop ? this.state.gochiDetail.menu.feePaidByShop : false;

    const gochiInfo = {
      shopId: shopId,
      gochiBillId: this.state.gochiDetail.gochiBillId,
      gochiBillNo: this.state.gochiDetail.gochiBillNo,
      shopName: shopName,
      menuName: menuName,
      menuId: menuId,
      menuImage: menuImage,
      includedTaxPrice: includedTaxPriceCombo,
      excludedTaxPrice: excludedTaxPrice,
      gochiCount: gochiCount,
      gochiExpiredDay: gochiExpiredDay,
      menuChildType: menuChildType,
      childFoods: childFoods,
      groupId: groupId,
      groupName: this.state.groupDetail ? this.state.groupDetail.groupName : '',
      menuCombo: this.state.gochiDetail.menu && this.state.gochiDetail.menu.menuCombo,
      quantityFree: freeTicketCount,
      couponCount: this.state.gochiDetail.couponCount,
      comboOutQuantity: this.state.gochiDetail.menu && this.state.gochiDetail.menu.menuComboDetail ? this.state.gochiDetail.menu.menuComboDetail.quantity : 0,
      quantityFreeDes: this.state.gochiDetail.menu && this.state.gochiDetail.menu.menuComboDetail && this.state.gochiDetail.menu.menuComboDetail.quantityFreeDes,
      priceCombo: priceCombo,
      includedTaxPriceCombo: includedTaxPriceCombo,
      shopStatus: this.state.shopDetail && this.state.shopDetail.status,
      gochiCountAvailable: this.state.gochiDetail.gochiCountAvailable,
      isETicket: this.state.gochiDetail.menu && this.state.gochiDetail.menu.isETicket,
      displayAmountOneTicket,
      showPrice: this.state.gochiDetail.menu && this.state.gochiDetail.menu.showPrice,
      usageType: this.state.gochiDetail.menu && this.state.gochiDetail.menu.usageType,
      reducedTaxType: this.state.gochiDetail.menu && this.state.gochiDetail.menu.reducedTaxType
    };
    const isShowPrice = this.state.gochiDetail?.menu?.showPrice !== 0;
    return (
      <>
        <div className="content">
          <Header
            title = {this.state.isMultiUsedMenu ? this.state.groupDetail.groupName : shopName}
            isLoggedIn={this.state.isLoggedIn}
            t={t}
            hasBack={true}
            usingFlow={true}
          />
          <div className="content-mypage">
            {this.state.gochiDetail.shop && this.state.gochiDetail.shop.status === 'CLOSED' &&
              <div className="shop-closed">
                {t('pages.menu.This shop has closed')}
              </div>
            }

            {this.state.gochiDetail.menu && this.state.gochiDetail.menu.isETicket === 0 && 
            (<div className="mb-3 paper-ticket-note">{t("pages.gochiuse.paper ticket note")}</div>)}

            {this.state.gochiDetail.gochiUserType !== constants.GOCHI_TYPE_YOURSELF && this.state.gochiDetail.giftCard && (
              <>
                <div
                  className="pb-2 fs-18 clr-000000 font-weight-bold gift-card-user-info"
                  dangerouslySetInnerHTML={{__html: t('pages.gochiuse.A feast from USERNAME has arrived', {username: userName}), interpolation: {escapeValue: false}}}
                >
                </div>

                <div className="box-card mb-3 text-center">
                  <img src={giftCardImage} alt="" />
                </div>
                {giftCardMessage && (
                  <>
                    <div className="corner-border-top-left"></div>
                    <div className="mt-1 space-pre-line break-word font-weight-bold" style={{padding: '0 15px'}}>
                      {giftCardMessage}
                    </div>
                    <div className="corner-border-bottom-right float-right"></div>
                    <div className="clearfix mb-3"></div>
                  </>
                )}
              </>
            )}

            {this.state.gochiDetail.gochiUserType !== constants.GOCHI_TYPE_YOURSELF ? (
              this.state.sentMessageToGiver ? (
                <>
                  <div className="fs-13 clr-000000 font-weight-bold">{t('pages.gochiuse.I sent a thank you message')}</div>
                  <div className="message-thank-you-to-giver space-pre-line mt-1 mb-4 break-word">
                    <span className="fs-35 font-weight-bold">MESSAGE THANK YOU</span>
                    <br />
                    {this.state.sentMessage}
                  </div>
                </>
              ) : (
                <>
                  <div className="fs-13 clr-000000 font-weight-bold">{t('pages.gochiuse.Would you like to send thank you message')}</div>
                  <textarea
                    className={this.state.messageTextError ? "form-control mt-1 textarea-message ex-form-required" : "form-control mt-1 textarea-message" }
                    placeholder={t('pages.gochiuse.messageToBuyerPlaceholder')}
                    value={this.state.messageText ? this.state.messageText: ''}
                    onChange={this.handleChange}
                    name="messageToBuyer"
                  />
                  {this.state.messageTextError &&
                    <div className="text-danger fs-14 mb-2 fs-12">{this.state.messageTextError}</div>
                  }

                  <button type="button" className="btn btn-green-38 mt-2 mb-4" onClick={this.handleOpenConfirmPopup} disabled={this.state.disabledBtnSendMessage}>
                    {t('pages.gochiuse.Send a message')}
                  </button>
                </>
              )
            ) : null }

            <div className="dish-infor">
              <div className="media mb-2">
                <div className="dish-image">
                  <figure className="mb-0 dish-fig">
                    <img
                      src={menuImage}
                      alt={menuName}
                      width='100%'
                    />
                  </figure>
                  <div>
                    <div className="triangle-label">
                            <span>{t('pages.invite.Coupon')}</span>
                    </div>
                    {freeTicketCount !== 0 ?
                      (isShowPrice ? (
                        <div className="bonus-label">
                          <span className="clr-fff">
                            {`+`}{t('pages.invite.Advantageous (total)', { freeTicketCount, totalCoupon: freeTicketCount + menuQuantity})}
                          </span>
                        </div>
                      ) : (
                        <div className="bonus-label show-hide-bonus-label">
                          <span className="clr-fff">
                            {"合計" + (freeTicketCount + menuQuantity) + "枚"}
                          </span>
                        </div>
                      ))
                    : null }
                  </div>
                </div>
                <div className="media-body dish-body pl-2">
                  <div className="fs-19 dish-link mt-0">
                    {menuName}
                    <br />
                    {isShowPrice && (
                      <>
                        {displayAmountOneTicket?.toLocaleString()}{t('pages.invite.Yen Unit')}ｘ
                      </>
                    )}
                    {menuQuantity + freeTicketCount}{t('pages.login.sheet')}
                    {isShowPrice && (
                      <>
                        {freeTicketCount !== 0 ? `(${t('pages.invite.Include N ticket free', {number: freeTicketCount})})` : null}
                      </>
                    )}
                    {/* <div className="line-horizontal menu-name-ln"></div> */}
                    {/* <div className="fs-19"> */}
                      {/* <span>{gochiCount}{t('pages.invite.pieces')}</span> */}
                      {/* <span>1{t('pages.invite.pieces')}</span>
                      <span className="pl-3">{includedTaxPrice && includedTaxPrice.toLocaleString()}</span>
                      <span className="fs-13">{t('pages.invite.Yen Unit')}</span> */}
                    {/* </div> */}
                  </div>

                </div>
              </div>

              {description && (
                <div className="fs-13 break-line mb-2">
                  {/* <ShowMoreText
                      lines={3}
                      more={t('pages.mypage.showmore')}
                      less={t('pages.mypage.showless')}
                      anchorClass='showmore'
                      onClick={this.executeOnClick}
                      expanded={false}
                      width={0}
                  >
                    <span className='break-all'>
                      {description}
                    </span>
                  </ShowMoreText> */}
                  {description}
                </div>
              )}

              {quantityFreeDes && (
                <>
                  <div className="arrow-up"></div>
                  <div className="div-free-ticket-desc break-line break-word">
                      <div className="font-weight-bold">{t('pages.invite.Bonus')}</div>
                      <div className="break-word">{quantityFreeDes}</div>
                  </div>
                </>
              )}

              {freeTicketCount !== 0 && (
                <div className={(feePaidByShop && typeof feePaidByShop !== 'undefined') ? "mt-2 has-feePaidByShop" : "mt-2"}>
                  <span className="menu-combo-total-coupons">
                    {isShowPrice ? (
                      <>
                        {`+`}{t('pages.invite.Advantageous (total)', { freeTicketCount, totalCoupon: freeTicketCount + menuQuantity})}
                      </>
                    ) : (
                      "合計" + (freeTicketCount + menuQuantity) + "枚"
                    )}
                  </span>
                </div>
              )}

              {feePaidByShop && typeof feePaidByShop !== 'undefined' && (
                  <div className="mt-2 mb-3">
                      <span className="menu-combo-total-coupons">
                          {t('pages.invite.Fee paid by shop')}
                      </span>
                  </div>
              )}

              {menuComboExpiration && (
                <div>
                  <span className="fs-12 clr-000000">{t('pages.login.usage period')}</span>
                  <span className="text-red fs-13 mt-2 font-weight-bold ml-2">{t('pages.login.available days', {days: menuComboExpiration})}</span>
                </div>
              )}

              {menuComboExpirationEndDate && (
                <div>
                  <span className="fs-12 clr-000000">{t('pages.login.usage period')}</span>
                  <span className="text-red fs-13 mt-2 font-weight-bold ml-2">
                      {t('pages.login.available to date', {date: `${menuComboExpirationEndDate.getFullYear()}/${menuComboExpirationEndDate.getMonth() +
                          1 < 10 ? '0' : ''}${menuComboExpirationEndDate.getMonth() +
                          1}/${menuComboExpirationEndDate.getDate()}`})}
                  </span>
                </div>
              )}

              {numberUsePerDay === 1 && (
                <div>
                  <span className="fs-12 clr-000000">{t('pages.login.usage limit')}</span>
                  <span className="text-red fs-13 font-weight-bold ml-2">{t('pages.login.1 sheet per day')}</span>
                </div>
              )}

              {groupId && !this.state.isFromQr && (
                <button type="button" onClick={this.handleShowListShops} className="btn btn-outline-red-38 mt-3 align-center fs-16 font-weight-bold mb-3">
                  {t('pages.login.List of stores where this meal voucher can be used')}
                </button>
              )}
            </div>

            {this.state.gochiDetail.shopId && (
              <>
                <div className="line-horizontal menu-name-ln mb-2 mt-2"></div>

                <div className="mt-3">
                  <div className="fs-18 clr-000000 font-weight-bold">
                    {shopName}
                  </div>
                  {this.state.gochiDetail.shop && this.state.gochiDetail.shop.prefectures && (
                    <div className="fs-13">
                      {this.state.gochiDetail.shop.prefectures}
                      {this.state.gochiDetail.shop.city ? this.state.gochiDetail.shop.city : ''}
                      {this.state.gochiDetail.shop.address ? this.state.gochiDetail.shop.address : ''}
                      {this.state.gochiDetail.shop.building ? this.state.gochiDetail.shop.building : ''}
                    </div>
                  )}
                </div>

                <button type="button" onClick={this.openMap} className="btn btn-green-38 mt-2 mb-3 align-center fs-16">
                  {t('pages.tome.View map')}
                </button>
              </>
            )}

            <div className="line-horizontal menu-name-ln mt-3 mb-2"></div>

            {this.state.gochiDetail && this.state.gochiDetail.gochiCode && (
              <div className="fs-13 space-pre-line clr-000000 mt-4">
                <span className="font-weight-bold">{t('pages.share.gochiCode')}: </span>
                <span>{this.state.gochiDetail.gochiCode}</span>
              </div>
            )}

            {/* Button copy  */}
            {this.state.gochiDetail.gochiUserType !== 'ANYONE' && (
              <div className="input-copy mt-2">
                <div className="w-100 position-relative">
                  {this.state.copied &&
                  <div className="copied-tag">{t('pages.mypage.Copied')}</div>
                  }
                  <input
                    className="form-control text-center fs-14"
                    readOnly
                    defaultValue={this.state.gochiDetail && this.state.gochiDetail.inviteUrl}
                    style={{height:53}}
                  />
                </div>
                <div className="mt-2">
                  <CopyToClipboard
                    onCopy={this.onCopy}
                    text={this.state.gochiDetail && this.state.gochiDetail.inviteUrl}
                  >
                    <button className="btn btn-green-38 ml-auto mr-auto" onCopy={this.onCopy}>
                      {t('pages.menu.Copy usage link')}
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
            )}

            <div className="div-coupon-container mt-3">
            <div className="menu-combo-ticket-use-container p-0">
              {(this.state.shopId || this.state.isFromQr) && (
                  <>
                    <button className="btn btn-red-38 mb-3 mt-3" onClick={() => onOpenGochiUse(gochiInfo)}>
                      {t('pages.tome.Use this ticket menu combo')}
                    </button>
                    <div className="fs-13">
                      {t('pages.tome.Use ticket note menu combo')}
                    </div>
                  </>
              )}
              </div>
              <Coupons
                coupons={this.state.gochiDetail.coupons}
                gochiExpired={this.state.gochiDetail.gochiExpired}
                numberUsePerDay={numberUsePerDay}
                t={t}
              />
              <div className="menu-combo-ticket-use-container">
              {/* User Infor */}
              <div className="pt-2 pb-2 fs-14">
                <div >
                  <img className="m-1 rounded-circle"
                       src={userImage}
                       width="20"
                       height="20"
                       alt="User Avatar"
                       style={{display: "inline"}}
                  />
                  <span className="text-green font-weight-bold">{userName}</span>
                  <span>{t('pages.menu.There is gochi bill message from')}</span>
                </div>
                {/* {gochiBillMessage && gochiBillMessage.message && (
                <span className="m-1 mb-3 break-line">{gochiBillMessage.message.replaceAll('\r', '\n')}</span>
              )} */}
                {gochiBillMessage && gochiBillMessage.situation && (
                    <span className="m-1 mb-3 break-line">{gochiBillMessage.situation.replaceAll('\r', '\n')}</span>
                )}
              </div>
              </div>


            </div>




            {/* <div className="label-page mt-5">
              <div className="menu-image-container">
                <div style={{position: "relative", width: "150px"}} className="mx-auto mb-0 ">
                  <div style={{position: "relative", overflow:"hidden"}}>
                    <figure className="dish-fig" style={{width: 150}}>
                      <img 
                        src={menuImage} 
                        alt={menuName} 
                        className="img-square-150"
                      /> 
                    </figure>
                    <div className="div-multi-used-menu-150px" />
                      <span className="label-multi-used-menu-150px">
                        {t('pages.invite.Coupon')}
                      </span>
                  </div>
                </div>
              </div>
            </div> */}

            {/* Menu infor */}
            {/* <div className="dish-infor">
              <div className="media mb-3">

                <div className="media-body">
                  <div className="shop-name div-break-word fs-16">
                    {this.state.isMultiUsedMenu? this.state.groupDetail.groupName: shopName}
                  </div>
                  <div className="div-break-word fs-12">
                    {!this.state.isMultiUsedMenu && <FontAwesomeIcon icon={faMapMarkerAlt} className="icon-loca mr-2" />}
                    {this.state.isMultiUsedMenu ? 
                      <>
                        {t('pages.menu.Stores in total', {total: totalShopsBelongToGroup})}
                      </>
                       : shopAddress
                    } 
                  </div>
                  <hr />
                  
                  <div className="mb-1 font-weight-bold text-black fs-18">
                    {menuName}
                  </div>
                  {description && description !== '' && (
                    <div className="fs-12 clr-666666 mb-1 break-line">                
                      {description}
                    </div>
                  )}

                  { quantityFreeDes && (quantityFreeDes.length !== "") && (
                    <div className="gochi-desc-container mb-1">
                      <div className="gochi-desc break-line font-weight-bold fs-12">
                        {quantityFreeDes}
                      </div>
                    </div>
                  )}

                  {(freeTicketCount !== 0) && (freeTicketCount !== null) && (freeTicketCount !== undefined) && (
                    <>
                      <i className="icon-ticket"></i>
                      <span className="fs-12 expiration ml-2 font-weight-bold">{"+" + freeTicketCount + t('pages.invite.Free of charge')}</span>
                    </>
                  )}
                
                  <div className="gochi-count">
                    <i className="icon-fork" style={{top: 3}}></i>
                    <span className="fs-12 clr-666666 mr-2">{t('pages.menu.Gochi count', {gochiCount: gochiCount})}</span>
                    <span className="clr-666666 font-weight-bold fs-12 mb-2 mr-2">
                      {includedTaxPrice && includedTaxPrice.toLocaleString()}
                      円
                    </span>
                      <span className="fs-10 align-self-center pl-1 expiration font-weight-bold">
                      <FontAwesomeIcon icon={faExclamationCircle} />
                        {t('pages.menu.Gochi expired day', {gochiExpiredDay: gochiExpiredDay})}
                    </span> 
                    
                  </div>
                </div>
              </div>
            </div>  */}

            <div className="mb-3">
              <div className="explain-icon ic-1 align-center">
                <div className="explain-note">{t('pages.gochiuse.Explain IC 1 menu combo')}</div>
              </div>
              <div className="explain-icon ic-2 align-center">
                <div className="explain-note">{t('pages.gochiuse.Explain IC 2 menu combo')}</div>
              </div>
              <div className="explain-icon ic-3 align-center">
                <div className="explain-note">{t('pages.gochiuse.Explain IC 3 menu combo')}</div>
              </div>
            </div>

            <div className="div-coupon-container mt-3">
              <div className="card ex-border-none border-radius-0">
                <div className="p-2">
                  <div className="clr-000000 fs-18 font-weight-bold">領収書番号 : {this.state.gochiDetail.gochiBillNo}</div>
                  <div><span className="fs-12 clr-000000">チケット利用問合先 : </span><a href="tel:050-5536-3317" className="fs-12 text-red clr-000000">050-5536-3317</a></div>
                  <div className="fs-12 text-red">※サポートセンターへの問い合わせ電話です。ご予約は、直接店舗様にお願いいたします。</div>
                  <div className="fs-12 clr-000000">平日（月～金） 10:00～18:00／土曜日 10:00～15:00</div>
                  <div className="fs-12 clr-000000">※お休み：日曜日・祝日 ・長期休暇・お盆休み・年末年始</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.showListShopPopup &&
          <ListShop
            showListShopPopup={this.state.showListShopPopup}
            handleCloseListShopPopup={this.handleCloseListShopPopup}
            menuId={menuId}
            menuChildType={menuChildType}
            showUsingBtn={true}
            gochiInfo={gochiInfo}
            onOpenGochiUse={this.onOpenGochiUse}
          />
        }

        {this.state.showGochiUseStaff ? (
          <GochiUseStaff
            showing={this.state.showGochiUseStaff}
            onCloseGochiStaff={this.onCloseGochiStaff}
            gochiInfo={this.state.gochiInfo}
            quantity = {this.state.quantity}
            childFoodId = {this.state.childFoodId}
          />
        ) : null}

      {this.state.gochiCountError && (
				<Modal
					size="sm"
					centered
					show={!!this.state.gochiCountError}
					onHide={this.hideGochiCountError}
				>
					<Modal.Header closeButton>
					{this.state.gochiCountError}
					</Modal.Header>
					{/* <ModalBody>
						{this.state.gochiCountError}
					</ModalBody> */}
				</Modal>
			)}

        <Modal
          size="sm"
          show={this.state.showConfirmMessagePopup}
          onHide={this.onCloseConfirmMessagePopup}
          animation={false}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
        >
          <Modal.Header className="header-close" closeButton />
          <Modal.Body className="gochi-content">
            <div className="dish-infor">
              <div className="gochi-infor-shop mb-2 mt-1">{t('pages.gochiuse.Message confirmation')}</div>
              <div className="fs-13 align-center">{t('pages.gochiuse.The following message will be sent')}</div>
              <div className="line-horizontal break-ln mt-1 mb-2"></div>
              <div className="space-pre-line break-word">
                  {this.state.messageText}
              </div>
              <div className="line-horizontal break-ln mt-2 mb-3"></div>
              <button type="button" className="btn btn-green-38 mt-2" onClick={this.handleSendMessage}>
                  {t('pages.gochiuse.Send a message review')}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}


const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
    showLoading: (showing) => dispatch(showLoading(showing)),
    showError: (showing, title, content) => dispatch(showError(showing, title, content))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MenuComboDetail));

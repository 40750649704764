import React, { Component } from "react";    
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next';
import * as constants from '../../../constants';
import {CopyToClipboard} from 'react-copy-to-clipboard';

class gochiUserType extends Component { 
	constructor(props) { 
	  	super(props); 
	  	this.state = {
            gochiBill: {}, 
            copied: false 
        };
        this.onCopy = this.onCopy.bind(this);
    }  
    
    onCopy(e){  
        this.setState({copied: true});
        setTimeout(() => {
            this.setState({copied: false});
        }, 3000);
	};

	componentDidMount(){

	}

    render() {

        const { t } = this.props;

        return (
        	<div className="gochi-type">
                {this.props.gochiBill.gochiUserType === constants.GOCHI_TYPE_NON_USER ? 
                <div>
                    <div className="bold">
                        {t('pages.mypage.gochi type non user')}
                    </div>
                    {this.props.gochiBill.inviteUrl ? 
                        <>
                            <a className="link-invite" target="_blank" rel="noopener noreferrer" href={this.props.gochiBill.inviteUrl}>{this.props.gochiBill.inviteUrl}</a>
                            <CopyToClipboard
                                onCopy={this.onCopy}
                                text={this.props.gochiBill.inviteUrl}
                            >
                                <button className="btn button-copy ml-auto mr-auto" onCopy={this.onCopy}>
                                    {t('pages.mypage.Copy link')}
                                </button>
                            </CopyToClipboard>
                            {this.state.copied &&
                                <div className="copied-tag">{t('pages.mypage.Copied')}</div>
                            }
                        </>
                    : null}
                </div>
                : null}
                {this.props.gochiBill.gochiUserType === constants.GOCHI_TYPE_USER ? 
                <>
                    <div className="user-info-avatar">
                        {this.props.gochiBill && this.props.gochiBill.couponMessage[0] && this.props.gochiBill.couponMessage[0].userInvited && this.props.gochiBill.couponMessage[0].userInvited.userImage ? (
                            <img className="avatar-icon" src={this.props.gochiBill.couponMessage[0].userInvited.userImage && this.props.gochiBill.couponMessage[0].userInvited.userImage ? this.props.gochiBill.couponMessage[0].userInvited.userImage : require("../../../assets/img/icons/avatar.png")}  alt={this.props.gochiBill.couponMessage[0].userInvited ? this.props.gochiBill.couponMessage[0].userInvited.displayName : ''} width="18" height="18" />
                        ) : (
                            <div className="no-avatar">{t('pages.mypage.You')}</div>
                        )}
                        <span className="">{this.props.gochiBill.couponMessage[0].userInvited ? this.props.gochiBill.couponMessage[0].userInvited.displayName : ''}</span>
                    </div>
                    {this.props.gochiBill.inviteUrl ? 
                        <>
                            <a className="link-invite" target="_blank" rel="noopener noreferrer" href={this.props.gochiBill.inviteUrl}>{this.props.gochiBill.inviteUrl}</a>
                            <CopyToClipboard
                                onCopy={this.onCopy}
                                text={this.props.gochiBill.inviteUrl}
                            >
                                <button className="btn button-copy ml-auto mr-auto" onCopy={this.onCopy}>
                                    {t('pages.mypage.Copy link')}
                                </button>
                            </CopyToClipboard>
                            {this.state.copied &&
                                <div className="copied-tag">{t('pages.mypage.Copied')}</div>
                            }
                        </>
                    : null}
                </>
                : null}
                {this.props.gochiBill.gochiUserType === constants.GOCHI_TYPE_YOURSELF ? 
                <>
                    <div className="bold">
                        {t('pages.mypage.gochi type yourself')}
                    </div>
                    {this.props.gochiBill.inviteUrl ? 
                        <>
                            <a className="link-invite" target="_blank" rel="noopener noreferrer" href={this.props.gochiBill.inviteUrl}>{this.props.gochiBill.inviteUrl}</a>
                            <CopyToClipboard
                                onCopy={this.onCopy}
                                text={this.props.gochiBill.inviteUrl}
                            >
                                <button className="btn button-copy ml-auto mr-auto" onCopy={this.onCopy}>
                                    {t('pages.mypage.Copy link')}
                                </button>
                            </CopyToClipboard>
                            {this.state.copied &&
                                <div className="copied-tag">{t('pages.mypage.Copied')}</div>
                            }
                        </>
                    : null}
                </>
                : null}
                {this.props.gochiBill.gochiUserType === constants.GOCHI_TYPE_ANYONE ? (
                <div>
                    <div>
                        <span className="bold">{t('pages.mypage.title choose go to shop')}</span>
                    </div>
                    <div>
                        {this.props.gochiBill.gochiBillMessage && this.props.gochiBill.gochiBillMessage[0].situation ? this.props.gochiBill.gochiBillMessage[0].situation : ''}
                    </div>
                </div>
                ) : null}
            </div>
        ); 
    }
} 

const mapStateToProps = state => ({
	...state
}); 
 
export default connect(mapStateToProps)(withTranslation()(gochiUserType)); 

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { showError } from "../../../actions/action_error_alert";
import { showLoading } from "../../../actions/action_loading";
import { savePaymentMethod } from "../../../actions/action_payment";
import PaymentService from "../../../api/services/PaymentService";
import { log } from "../../../utils";
import AddPaymentCardModal from "./AddPaymentCardModal";
import ModalCloseButton from "./ModalCloseButton";
import RadioInput from "./RadioInput";

function ListPaymentCardModal({
  show,
  handleClose,
  showLoading,
  showError,
  userInfo,
  savePaymentMethod
}) {
  const history = useHistory();
  const [cardList, setCardList] = useState([]);
  const [selectedCardToken, setSelectedCardToken] = useState(null);
  const [showAddCard, setShowAddCard] = useState(false);

  const fetchPaymentList = async (userId) => {
    showLoading(true);
    try {
      const { data } = await PaymentService.getPaymentList(userId);
      if (!data.list) {
        throw 'getPaymentList API error';
      }
      setCardList(data.list);
      for (const card of data.list) {
        if (card.defaultFlag) {
          setSelectedCardToken(card.token);
          savePaymentMethod(card);
          break;
        }
      }
    } catch (error) {
      log('getDefaultCard error with userId ' + userInfo?.userId);
      showError(true, 'インターネット接続には問題が発生しました。後でもう一度試してください。', '');
    }
    showLoading(false);
  };

  const chooseCard = () => {
    const selectedCard = cardList.find(card => card.token === selectedCardToken);
    savePaymentMethod(selectedCard);
    handleClose();
  };

  const onShow = () => {
    log('ListPaymentCardModal onShow');
    if (!userInfo?.userId) {
      history.goBack();
      return;
    }
    fetchPaymentList(userInfo.userId);
  };

  return (
    <>
    <Modal
      size="sm"
      className="no-header-modal"
      scrollable={false}
      animation={false}
      show={show && !showAddCard}
      onShow={onShow}
      onHide={() => {}}
    >
      <Modal.Body className="pb-5">
        <ModalCloseButton onClick={handleClose} />

        <div className="fs-24px fw-800 text-center font-family-rounded mt-2">クレジットカード登録</div>
        
        {cardList.map((card, index) => (
          <div key={index + '-' + card.token} className="payment-card-wrapper">
            <RadioInput
              inputName="defaultCard"
              inputValue={card.token}
              checked={card.token === selectedCardToken}
              onChange={(e) => setSelectedCardToken(e.target.value)}
            >
              <div>
                <div className="fs-18px fw-700">{card.brand}****-****-****-{card.last4}</div>
                <div className="fs-16px fw-700 color-797474">{card.holder}</div>
                <div className="fs-14px fw-400 color-797474">
                  <span>Date:</span>
                  <span>{card.expireMonth.toString().padStart(2, '0')}/{card.expireYear}</span>
                </div>
              </div>
            </RadioInput>
          </div>
        ))}

        <div className="mt-3 text-center">
        <div className="d-inline-flex justify-content-center align-items-center cursor-pointer" onClick={() => setShowAddCard(true)}>
          <span className="btn-circle-add mr-2">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 14C6.78333 14 6.60417 13.9292 6.4625 13.7875C6.32083 13.6458 6.25 13.4667 6.25 13.25V7.75H0.75C0.533333 7.75 0.354167 7.67917 0.2125 7.5375C0.0708334 7.39583 0 7.21667 0 7C0 6.78333 0.0708334 6.60417 0.2125 6.4625C0.354167 6.32083 0.533333 6.25 0.75 6.25H6.25V0.75C6.25 0.533333 6.32083 0.354167 6.4625 0.2125C6.60417 0.0708334 6.78333 0 7 0C7.21667 0 7.39583 0.0708334 7.5375 0.2125C7.67917 0.354167 7.75 0.533333 7.75 0.75V6.25H13.25C13.4667 6.25 13.6458 6.32083 13.7875 6.4625C13.9292 6.60417 14 6.78333 14 7C14 7.21667 13.9292 7.39583 13.7875 7.5375C13.6458 7.67917 13.4667 7.75 13.25 7.75H7.75V13.25C7.75 13.4667 7.67917 13.6458 7.5375 13.7875C7.39583 13.9292 7.21667 14 7 14Z" fill="white"/>
            </svg>
          </span>
          <span className="fs-18px fw-700 color-000000">新しいお支払い方法を登録する</span>
        </div>
        </div>

        <div className="mt-4">
          <button className="btn-primary btn-size-md w-100" onClick={chooseCard}>
            お支払い方法を確定する
          </button>
        </div>
        <div className="mt-4 text-center">
          <button className="btn-link fw-400 border-0 p-0" onClick={handleClose}>
            キャンセル
          </button>
        </div>
      </Modal.Body>
    </Modal>

    <AddPaymentCardModal
      show={showAddCard}
      handleRegisterSuccess={() => fetchPaymentList(userInfo.userId)}
      handleClose={() => setShowAddCard(false)}
    />
    </>
  );
}

const mapStateToProps = state => ({
  userInfo: state.userInfo,
  paymentMethod: state.paymentMethod
});

const mapDispatchToProps = dispatch => ({
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content, buttonName, gotoUrl) => dispatch(showError(showing, title, content, buttonName, gotoUrl)),
  savePaymentMethod: (paymentMethod) => dispatch(savePaymentMethod(paymentMethod))
});

export default connect(mapStateToProps, mapDispatchToProps)(ListPaymentCardModal);

import React, { Component } from "react";    
import {connect} from 'react-redux';
import moment from 'moment';
import {Link} from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import i18next from "i18next";

class GochiInfo extends Component { 
	constructor(props) { 
	  	super(props); 
	  	this.state = {
	    	gochiBill: {},  
        };
	}  

	componentDidMount(){

	}

    render() {
        const { t } = this.props;

        let gochiTypeText;
        if (this.props.gochiBill.gochiUserType === 'YOURSELF') {
            gochiTypeText = t('pages.invite.chooseYourSelf');
        } else if (this.props.gochiBill.gochiUserType === 'ANYONE') {
            gochiTypeText = t('pages.invite.chooseAnyone');
        } else if (this.props.gochiBill.gochiUserType === 'USER') {
            gochiTypeText = t('pages.invite.chooseUser');
        } else if (this.props.gochiBill.gochiUserType === 'NONUSER') {
            gochiTypeText = t('pages.invite.selectNoneUser');
        }

        var d = new Date();
        var n = d.getFullYear();
        const createdAt = this.props.gochiBill.createdAt ? moment(this.props.gochiBill.createdAt).format(moment(this.props.gochiBill.createdAt).isAfter(n + '-01-01') ? 'M月 D日' : 'YYYY年 M月 D日') : '';
        // const menu = this.props.gochiBill.menu;
        // const displayLabelTicket = menu !== null && menu.menuCombo===1;
        // const displayFreeTicketCount = menu !== null && menu.menuComboDetail!== null && 
        // (menu.menuComboDetail.quantityFree > 0);

        return (
            <Link to={`/mypage/gochi?gochiBillId=`+this.props.gochiBill.gochiBillId} className="gochi-detail-link">
                <div className="media">
                    <div className="dish-image">
                        <figure className="mb-0 dish-fig">
                            <img 
                                className="gochi-ava" 
                                src={ this.props.gochiBill.gochiBillImage[0] && this.props.gochiBill.gochiBillImage[0].image ? this.props.gochiBill.gochiBillImage[0].image : require("../../../assets/img/icons/gochi_noimg-ver4.jpg") } 
                                alt={ this.props.gochiBill.menu && this.props.gochiBill.menu.menuName } 
                                width="56"
                            />
                        </figure>
                        {this.props.gochiBill.menu && this.props.gochiBill.menu.menuCombo === 1 && (
							<div>
								<div className="triangle-label-mypage">
										<span>{t('pages.invite.Coupon')}</span>
								</div>
								<div className="bonus-label-mypage">
                                    {this.props.gochiBill.menu.menuComboDetail && this.props.gochiBill.menu.menuComboDetail.quantityFree ? 
                                        <span>+{this.props.gochiBill.menu.menuComboDetail.quantityFree.toLocaleString()}{t('pages.invite.Free of charge')}</span>
                                    : null}
								</div>
							</div>
						)}
                    </div>
                    <div className="media-body dish-body pl-2">
                        {this.props.gochiBill.couponMessage && this.props.gochiBill.gochiUserType === 'USER' && this.props.gochiBill.couponMessage[0].userInvited ? (
                            <div className="gochi-title">
                                {i18next.language === 'en' ? (
                                <div className="user-label">
                                    
                                    <div className="user-name">
                                        {t('pages.mypage.purchased')}
                                        <span className="bold">
                                            {this.props.gochiBill.menu && this.props.gochiBill.menu.menuName 
                                            ? this.props.gochiBill.menu.menuName 
                                            : ''}
                                        </span>
                                        {t('pages.mypage.title_to')}
                                        <img className="avatar-icon" src={this.props.gochiBill.couponMessage[0] && this.props.gochiBill.couponMessage[0].userInvited && this.props.gochiBill.couponMessage[0].userInvited.userImage ? this.props.gochiBill.couponMessage[0].userInvited.userImage : require("../../../assets/img/icons/avatar.png")}  alt={this.props.gochiBill.couponMessage[0] && this.props.gochiBill.couponMessage[0].userInvited ? this.props.gochiBill.couponMessage[0].userInvited.displayName : ''} width="18" height="18" />
                                        <span className="user-name-text">
                                            {this.props.gochiBill.couponMessage[0].userInvited.displayName}
                                        </span>
                                    </div>
                                </div>
                                ) : (
                                <div className="user-label">
                                    <img className="avatar-icon" src={this.props.gochiBill.couponMessage[0] && this.props.gochiBill.couponMessage[0].userInvited && this.props.gochiBill.couponMessage[0].userInvited.userImage ? this.props.gochiBill.couponMessage[0].userInvited.userImage : require("../../../assets/img/icons/avatar.png")}  alt={this.props.gochiBill.couponMessage[0] && this.props.gochiBill.couponMessage[0].userInvited ? this.props.gochiBill.couponMessage[0].userInvited.displayName : ''} width="18" height="18" />
                                    <div className="user-name">
                                        <span className="user-name-text">
                                            {this.props.gochiBill.couponMessage[0].userInvited.displayName}
                                        </span>
                                        {t('pages.mypage.Mr/Ms')}
                                        <span className="bold">
                                            {this.props.gochiBill.menu && this.props.gochiBill.menu.menuName 
                                            ? this.props.gochiBill.menu.menuName 
                                            : ''}
                                        </span>
                                        {t('pages.mypage.I bought')}
                                    </div>
                                </div>
                                )}

                            </div>
                        ) : this.props.gochiBill.gochiUserType === 'YOURSELF' ? (
                            <div className="gochi-title">
                                {i18next.language === 'en' ? (
                                    <div className="user-label">
                                        <div className="user-name">
                                            {t('pages.mypage.purchased')}
                                            <span className="bold">
                                                {this.props.gochiBill.menu && this.props.gochiBill.menu.menuName 
                                                ? this.props.gochiBill.menu.menuName 
                                                : ''}
                                            </span>
                                            {t('pages.mypage.title_to')}
                                            <img className="avatar-icon" src={this.props.gochiBill.gochiRegister && this.props.gochiBill.gochiRegister.userImage && this.props.gochiBill.gochiRegister.userImage[0].image ? this.props.gochiBill.gochiRegister.userImage[0].image : require("../../../assets/img/icons/avatar.png")}  alt={this.props.gochiBill.gochiRegister ? this.props.gochiBill.gochiRegister.displayName : ''} width="18" height="18" />
                                            <span className="user-name-text">
                                                {this.props.gochiBill.gochiRegister 
                                                ? this.props.gochiBill.gochiRegister.displayName 
                                                : ''}
                                            </span>

                                        </div>
                                    </div>
                                ) : (
                                    <div className="user-label">
                                    <img className="avatar-icon" src={this.props.gochiBill.gochiRegister && this.props.gochiBill.gochiRegister.userImage && this.props.gochiBill.gochiRegister.userImage[0].image ? this.props.gochiBill.gochiRegister.userImage[0].image : require("../../../assets/img/icons/avatar.png")}  alt={this.props.gochiBill.gochiRegister ? this.props.gochiBill.gochiRegister.displayName : ''} width="18" height="18" />
                                    <div className="user-name">
                                        <span className="user-name-text">
                                            {this.props.gochiBill.gochiRegister 
                                            ? this.props.gochiBill.gochiRegister.displayName 
                                            : ''}
                                        </span>{t('pages.mypage.Mr/Ms')}
                                        <span className="bold">
                                            {this.props.gochiBill.menu && this.props.gochiBill.menu.menuName 
                                            ? this.props.gochiBill.menu.menuName 
                                            : ''}
                                        </span>{t('pages.mypage.I bought')}
                                    </div>
                                </div>
                                )}
                            </div>
                            ) : (
                            <div className="gochi-title">
                                {i18next.language === 'en' ? (
                                    <div className="user-label">
                                        <div className="user-name">
                                            {t('pages.mypage.purchased')}
                                            <span className="bold">
                                                {this.props.gochiBill.menu && this.props.gochiBill.menu.menuName 
                                                ? this.props.gochiBill.menu.menuName 
                                                : ''}
                                            </span>
                                        </div>
                                    </div>  
                                ) : (
                                    <div className="user-label">
                                        <div className="user-name">
                                            <span className="bold">
                                                {this.props.gochiBill.menu && this.props.gochiBill.menu.menuName 
                                                ? this.props.gochiBill.menu.menuName 
                                                : ''}
                                            </span>
                                            {t('pages.mypage.purchased')}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="shop-info">
                            {this.props.gochiBill.shop ?
                            <>
                                <FontAwesomeIcon icon={faMapMarkerAlt} className={"icon-loca"}/>
                                <span>{this.props.gochiBill.shop ? this.props.gochiBill.shop.shopName : ''}</span>
                            </>
                            : (this.props.gochiBill.groupInfo ?
                                <>
                                    <FontAwesomeIcon icon={faMapMarkerAlt} className={"icon-loca"}/>
                                    <span>{this.props.gochiBill.groupInfo ? this.props.gochiBill.groupInfo.groupName : ''}</span>
                                </>
                                : null)}
                        </div>
                        <div className="date-info">
                            {gochiTypeText}{` `}{createdAt}
                        </div>
                        {/* {displayFreeTicketCount && (
                            <div className="div-free-ticket-desc">
                                <span className="free-ticket-desc">{menu.menuComboDetail.quantityFreeDes}</span>
                            </div>
                        )} */}
                    </div>
                </div>
            </Link>
        ); 
    }
} 

const mapStateToProps = state => ({
	...state
}); 
 
export default connect(mapStateToProps)(withTranslation()(GochiInfo)); 

import { HTTP_API, makeRequest } from "../HttpClient";

const API_BASE_URL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : '';

export default class LogService {
  static async sendLog(params) {
    const url = `${API_BASE_URL}/event-log`;
    return makeRequest(HTTP_API.GET, url, params);
  }
}

import {HTTP_API, makeRequest} from "../HttpClient";

const API_BASE_URL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : '';

export default class CouponService {
    static async sendReviewMessageToReceivedUser(message, couponId, gochiBillId){
        var params = {
            gochiBillId: gochiBillId,
            couponId: couponId,
            image: "",
            message: message
        };
        var url = `${API_BASE_URL}/coupons/userRegister/message`; 
        return makeRequest(HTTP_API.POST, url, params);
    };

    static async sendReviewMessageToShop(message, couponId){
        var params = {
            couponId: couponId,
            shopMessage: message
        };
        var url = `${API_BASE_URL}/coupons/shop/message`; 
        return makeRequest(HTTP_API.POST, url, params);
    };

    static async getCouponShopMessage(couponId){   
        var url = `${API_BASE_URL}/coupons/shop/message/${couponId}`; 
        return makeRequest(HTTP_API.GET, url);
    }
}

import React, { Component } from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap'


class ClearCacheInstructionPopup extends Component { 
  render() { 
    return ( 
      <Modal show={this.props.showing} onHide={this.props.onHide} className="modal-error"> 
        <div className="modal-body"> 
          <Button variant="secondary" className="close" onClick={this.props.onHide}>&times;</Button>

          <div className="input-infor mt-4">   
            <div>
              <div>2023/12/21（木）より、クレジットカードをより安全にご利用いただけるシステムに変更いたしました。</div>
              <div>システム変更後に初めて購入される方は「キャッシュクリア」をしてからご購入手続きにお進みください。</div>
              <div>※キャッシュクリアの方法は<a href="https://user-guide.gochimeshi.com/gochi-faq76/" target="_blank">こちら</a></div>
            </div>
          </div>
        </div> 
      </Modal>

    );
  }
}

export default connect(null, null)(withTranslation()(ClearCacheInstructionPopup));

import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import GiftService from "../../../../api/services/GiftService";
import {
  toggleFavoriteMenu,
  toggleFavoriteShop,
  toggleWishListMenu,
  toggleWishListShop,
  useGenres,
  usePriceRanges,
  useRecentlyViewedMenus,
  useScript,
} from "../../../../utils";
import { ListMenus } from "../../common/ListMenus";
import { CustomPagination } from "../../common/Pagination";
import { Footer } from "../../layout/Footer";
import Header from "../../layout/Header";
import { Explanation } from "../../layout/Explanation";
import queryString from "query-string";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { showLoading } from "../../../../actions/action_loading";
import { showError } from "../../../../actions/action_error_alert";
import { ModalGroup } from "../../common/ModalGroup";
import { SituationModal } from "../../common/SituationModal";
import { GiftToModal } from "../../common/GiftToModal";
import { NotLoggedModal } from "../../common/NotLoggedModal";
import { showNotLoggedModal } from "../../../../actions/action_show_not_logged_modal";
import {
  setModalSituationAction,
  setSituationAction,
} from "../../../../actions/action_situations";
import {
  setModalUserTargetAction,
  setUserTargetAction,
} from "../../../../actions/action_user_targets";
import { setTempWishListMenuAction } from "../../../../actions/action_wishlist_menu";
import { setTempFavoriteMenuAction } from "../../../../actions/action_favorite_menu";
import { WishListModal } from "../../common/WishListModal";
import { showWishListModal } from "../../../../actions/action_show_wish_list_modal";
import { setTempWishListShopAction } from "../../../../actions/action_wishlist_shop";
import { setTempFavoriteShopAction } from "../../../../actions/action_favorite_shop";

const NO_IMAGE = require("../../../../assets/img/icons/square_blank.png");
const SHOP_NO_IMAGE = require("../../../../assets/img/icons/shop01_blank.png");

function MenuSearch(props) {
  useScript("/assets/js/app.js");

  useEffect(() => {
    document.body.className = "productss";
  }, []);

  const params = queryString.parse(window.location.search);
  const location = useLocation();

  const history = useHistory();
  const [prefecture, setPrefecture] = useState(params.prefecture || "");
  const [city, setCity] = useState(params.city || "");
  const listPrefectures = useSelector((state) => state.prefectures);
  const listCities = useSelector((state) => state.cities);

  const prefectureId = getPrefectureId(prefecture);

  const filteredCities = listCities.filter(
    (city) => Number(city.prefCode) === prefectureId
  );

  const situation = useSelector((state) => state.situation.situation);
  const setSituation = (payload) => dispatch(setSituationAction(payload));
  const setModalSituation = (payload) =>
    dispatch(setModalSituationAction(payload));
  const situations = useSelector((state) => state.listSituations);

  const userTarget = useSelector((state) => state.userTarget.userTarget);
  const setUserTarget = (payload) => dispatch(setUserTargetAction(payload));
  const setModalUserTarget = (payload) =>
    dispatch(setModalUserTargetAction(payload));
  const userTargets = useSelector((state) => state.listUserTargets);

  const [genre, setGenre] = useState(params.genre || "");
  const genres = useGenres();
  const priceRanges = usePriceRanges();
  const [priceRange, setPriceRange] = useState(params.prices || "");

  const [keyword, setKeyword] = useState(params.keyword || "");
  const [orderBy, setOrderBy] = useState(1);
  const [page, setPage] = useState(1);
  const LIMIT = 25;
  const [searchResults, setSearchResults] = useState([]);
  const [totalResult, setTotalResults] = useState(0);
  const [recentlyViewedMenus] = useRecentlyViewedMenus();
  const [listShops, setListShops] = useState([]);
  const [totalShops, setTotalShops] = useState(0);
  const [loading, setLoading] = useState(false);
  const userInfo = useSelector((state) => state.userInfo);
  const isLoggedIn = userInfo && userInfo.userId;
  const dispatch = useDispatch();
  const masterDataIsReady = !(
    situations.length === 0 ||
    userTargets.length === 0 ||
    genres.length === 0 ||
    priceRanges.length === 0
  );

  function fetchData(newParams) {
    console.log("Fetch list menus", newParams);
    // if master data is not ready, cancel search
    if (!masterDataIsReady) return;

    getMenus(newParams);
    getShops(newParams);
  }

  useEffect(() => {
    // Run on search conditions change
    window.scrollTo(0, 0);
    setSituation(params.situation || "");
    setUserTarget(params.who || "");
    setPrefecture(params.prefecture || "");
    setCity(params.city || "");
    setGenre(params.genre || "");
    setKeyword(params.keyword || "");
    setPriceRange(params.prices || "");
    // Only fetch data when current page is 1
    if (page === 1) {
      fetchData();
    } else {
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, situations, userTargets, genres, priceRanges, location.search]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  function getPrefectureId(name) {
    if (name) {
      const prefectureObj = listPrefectures.find(
        (item) => item.prefectureName === name
      );
      if (prefectureObj) return prefectureObj.prefectureId;
    }
    return "";
  }

  function getSituationId(name) {
    if (name) {
      const situationObj = situations.find(
        (item) => item.menuSituationName === name
      );
      if (situationObj) return situationObj.menuSituationId;
    }
    return "";
  }

  function getGenreId(name) {
    if (name) {
      const genreObj = genres.find((item) => item.menuGenreName === name);
      if (genreObj) return genreObj.menuGenreId;
    }
    return "";
  }

  function getPriceRangeFromTo(name) {
    if (name) {
      const priceRangeObj = priceRanges.find((item) => item.name === name);
      console.log("price range obj:", priceRangeObj);
      if (priceRangeObj) return [priceRangeObj.from, priceRangeObj.to];
    }
    return ["", ""];
  }

  function getUserTargetId(name) {
    if (name) {
      const userTargetObj = userTargets.find((item) => item.name === name);
      if (userTargetObj) return userTargetObj.menuUserTargetId;
    }
    return "";
  }

  function getPriceRangeStrFromObj(range) {
    return `${range.name && range.name.toLocaleString()}`;
  }

  function getPriceRangeStrFromName(name) {
    if (name) {
      const priceRangeObj = priceRanges.find((item) => item.name === name);
      if (priceRangeObj) return priceRangeObj.name.toLocaleString();
    }
    return "";
  }

  function handleSearch(newParams) {
    const params = [];
    if (newParams?.prefecture || prefecture)
      params.push(`prefecture=${newParams?.prefecture || prefecture}`);
    if (newParams?.city || city) params.push(`city=${newParams?.city || city}`);
    if (newParams?.situation || situation)
      params.push(`situation=${newParams?.situation || situation}`);
    if (newParams?.genre || genre)
      params.push(`genre=${newParams?.genre || genre}`);
    if (newParams?.priceRange || priceRange)
      params.push(`prices=${newParams?.priceRange || priceRange}`);
    if (newParams?.userTarget || userTarget)
      params.push(`who=${newParams?.userTarget || userTarget}`);
    if (newParams?.keyword || keyword)
      params.push(
        queryString.stringify({ keyword: newParams?.keyword || keyword })
      );

    const query = params.join("&");
    history.replace({
      search: `?${query}`,
    });
    setPage(1);
  }

  function getShops(newParams) {
    const getParams = newParams || params;
    console.log("getShops: ", getParams);
    const [from, to] = getPriceRangeFromTo(getParams.prices);
    const parameters = {
      page: 1,
      perPage: 5,
      prefectures: getParams.prefecture,
      city: getParams.city,
      genreId: getGenreId(getParams.genre),
      priceFrom: from,
      priceTo: to,
      keyword: getParams.keyword,
    };
    GiftService.getShops(parameters)
      .then((response) => {
        if (response.status === 200) {
          setListShops(response.data.list);
          setTotalShops(response.data.total);
        } else if (response.status === 404) {
          setListShops([]);
          setTotalShops(0);
        }
      })
      .catch((e) => {
        console.error("GET LIST SHOPS ERROR: ", e);
      });
  }

  function getMenus(newParams) {
    setLoading(true);
    const getParams = newParams || params;
    console.log("getMenus: ", getParams);
    const [from, to] = getPriceRangeFromTo(getParams.prices);
    const parameters = {
      page: page,
      perPage: LIMIT,
      prefectures: getParams.prefecture,
      city: getParams.city,
      situationId: getSituationId(getParams.situation),
      menuUserTargetId: getUserTargetId(getParams.who),
      genreId: getGenreId(getParams.genre),
      priceFrom: from,
      priceTo: to,
      keyword: getParams.keyword,
      orderBy: orderBy,
    };
    GiftService.getMenus(parameters)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          setSearchResults(response.data.list);
          setTotalResults(response.data.total);
        } else if (response.status === 404) {
          setSearchResults([]);
          setTotalResults(0);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.error("SEARCH MENU ERROR: ", e);
      });
  }

  function handleChangePage(toPage) {
    console.log("HANDLE CHANGE PAGE:", toPage);
    setPage(toPage);
  }

  function handleChangeOrder(by) {
    console.log("HANDLE CHANGE PAGE:", by);
    setOrderBy(by);
  }

  const searchParamsArr = [];
  if (params.prefecture) {
    searchParamsArr.push(params.prefecture);
  }
  if (params.city) {
    searchParamsArr.push(params.city);
  }
  if (params.genre) {
    searchParamsArr.push(params.genre);
  }
  if (params.situation) {
    searchParamsArr.push(params.situation);
  }
  if (params.who) {
    searchParamsArr.push(params.who);
  }
  if (params.prices) {
    searchParamsArr.push(getPriceRangeStrFromName(params.prices));
  }
  if (params.keyword) {
    searchParamsArr.push(params.keyword);
  }
  const searchStr = searchParamsArr.join("・");
  const searchStr2 = searchParamsArr.map((item) => `「${item}」`).join("");

  function toggleSuggestionExpaned() {
    setSuggestionExpanded((old) => !old);
  }

  const [suggestionExpanded, setSuggestionExpanded] = useState(false);
  const showSituationSuggestion = !params.situation;
  const showGenreSuggestion = !params.genre;
  const showUserTargetSuggestion =
    !params.who &&
    (suggestionExpanded || !showSituationSuggestion || !showGenreSuggestion);
  // const showPriceRangeSuggestion =
  //   !params.prices &&
  //   (suggestionExpanded ||
  //     (!showSituationSuggestion &&
  //       !showGenreSuggestion &&
  //       !showUserTargetSuggestion));
  var showPriceRangeSuggestion = false;
  if (!params.prices) {
    if (suggestionExpanded === false) {
      if (params.situation && params.genre && params.who) {
        showPriceRangeSuggestion = true;
      } else if ((params.situation && params.genre && !params.who) || (!params.situation && params.genre && params.who) || (params.situation && !params.genre && params.who)) {
        showPriceRangeSuggestion = true;
      } else {
        showPriceRangeSuggestion = false;
      }
    } else {
      showPriceRangeSuggestion = true;
    }
  }

  let suggestionCount = 4;
  if (params.situation) suggestionCount--;
  if (params.genre) suggestionCount--;
  if (params.who) suggestionCount--;
  if (params.prices) suggestionCount--;

  const tempShopFavorite = useSelector(state => state.favoriteShop);
  const tempShopWishList = useSelector(state => state.wishListShop);
  const tempMenuFavorite = useSelector((state) => state.favoriteMenu);
  const tempMenuWishList = useSelector((state) => state.wishListMenu);

  function onToggleMenuWishList(menuId, current) {
    if (!isLoggedIn) {
      dispatch(showNotLoggedModal(true));
      return;
    }
    const currentState =
      tempMenuWishList[menuId] !== undefined
        ? tempMenuWishList[menuId]
        : current;
    toggleWishListMenu(menuId, currentState, () => {
      dispatch(setTempWishListMenuAction(menuId, !currentState));
      if (!currentState) dispatch(showWishListModal(true));
    });
  }

  function onToggleMenuFavorite(menuId, current) {
    if (!isLoggedIn) {
      dispatch(showNotLoggedModal(true));
      return;
    }
    const currentState =
      tempMenuFavorite[menuId] !== undefined
        ? tempMenuFavorite[menuId]
        : current;
    toggleFavoriteMenu(menuId, currentState, () => {
      dispatch(setTempFavoriteMenuAction(menuId, !currentState));
      if (!currentState) dispatch(showWishListModal(true, true));
    });
  }

  function onToggleShopWishList(shopId, current) {
    if (!isLoggedIn) {
      dispatch(showNotLoggedModal(true));
      return;
    }
    const currentState =
      tempShopWishList[shopId] !== undefined
        ? tempShopWishList[shopId]
        : current;
    toggleWishListShop(shopId, currentState, () => {
      dispatch(setTempWishListShopAction(shopId, !currentState));
      if (!currentState) dispatch(showWishListModal(true));
    });
  }

  function onToggleShopFavorite(shopId, current) {
    if (!isLoggedIn) {
      dispatch(showNotLoggedModal(true));
      return;
    }
    const currentState =
      tempShopFavorite[shopId] !== undefined
        ? tempShopFavorite[shopId]
        : current;
    toggleFavoriteShop(shopId, currentState, () => {
      dispatch(setTempFavoriteShopAction(shopId, !currentState));
      if (!currentState) dispatch(showWishListModal(true, true));
    });
  }

  let searchConditionCount = 0;
  params.situation && searchConditionCount++;
  params.genre && searchConditionCount++;
  params.prices && searchConditionCount++;
  params.who && searchConditionCount++;
  const isMultiSearchCondition = searchConditionCount > 1;
  const isNoSearchCondition =
    !params.prefecture &&
    !params.city &&
    searchConditionCount === 0 &&
    !params.keyword;

  function getTitle() {
    if (isNoSearchCondition) {
      return "プレゼント一覧｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス";
    }
    if (isMultiSearchCondition || params.keyword)
      return "プレゼント検索結果 | 飲食店のメニューを贈れる「ごちめし」";
    let ret = "";
    ret = ret + (params.prefecture ? params.prefecture : "");
    ret = ret + (params.city ? params.city : "");
    if (params.situation) {
      ret = (ret ? ret + "の" : "") + params.situation;
    }
    if (params.genre) {
      ret = (ret ? ret + "の" : "") + params.genre;
    }
    if (params.prices) {
      ret = (ret ? ret + "の" : "") + params.prices;
    }
    if (params.who) {
      ret = (ret ? ret + "の" : "") + params.who + '向け';
    }
    ret = ret + "のプレゼント一覧｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス";
    return ret;
  }

  function getMetaDescription() {
    if (isNoSearchCondition) {
      return "プレゼント一覧です。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。";
    }
    if (isMultiSearchCondition || params.keyword)
      return "プレゼント検索結果一覧です。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。";
    let ret = "";
    ret = ret + (params.prefecture ? params.prefecture : "");
    ret = ret + (params.city ? params.city : "");
    if (params.situation) {
      ret = (ret ? ret + "の" : "") + params.situation;
    }
    if (params.genre) {
      ret = (ret ? ret + "の" : "") + params.genre;
    }
    if (params.prices) {
      ret = (ret ? ret + "の" : "") + params.prices;
    }
    if (params.who) {
      ret = (ret ? ret + "の" : "") + params.who + '向け';
    }
    ret =
      ret +
      "のプレゼント一覧です。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。";
    return ret;
  }

  const shopSearchParams = [];
  if (params.prefecture)
    shopSearchParams.push(`prefecture=${params.prefecture}`);
  if (params.city) shopSearchParams.push(`city=${params.city}`);
  if (params.genre) shopSearchParams.push(`genre=${params.genre}`);
  if (params.prices) shopSearchParams.push(`prices=${params.prices}`);
  if (params.keyword) shopSearchParams.push(`keyword=${params.keyword}`);
  const shopSearchQuery = "?" + shopSearchParams.join("&");

  var isShowTextMenuList1 = false;
  var isShowTextMenuList2 = false;
  var isShowTextMenuList3 = false;
  if (!isNoSearchCondition) {
    if (!params.prefecture && !params.city) {
      if (
        !(!params.situation && !params.genre && !params.prices && !params.who)
      ) {
        isShowTextMenuList1 = true;
        isShowTextMenuList2 = false;
        isShowTextMenuList3 = false;
      }
    } else if (params.prefecture && !params.city) {
      if (
        !(!params.situation && !params.genre && !params.prices && !params.who)
      ) {
        isShowTextMenuList1 = false;
        isShowTextMenuList2 = true;
        isShowTextMenuList3 = false;
      } else if (
        !params.situation &&
        !params.genre &&
        !params.prices &&
        !params.who
      ) {
        isShowTextMenuList1 = false;
        isShowTextMenuList2 = true;
        isShowTextMenuList3 = false;
      }
    } else if (params.prefecture && params.city) {
      if (
        !(!params.situation && !params.genre && !params.prices && !params.who)
      ) {
        isShowTextMenuList1 = false;
        isShowTextMenuList2 = false;
        isShowTextMenuList3 = true;
      } else if (
        !params.situation &&
        !params.genre &&
        !params.prices &&
        !params.who
      ) {
        isShowTextMenuList1 = false;
        isShowTextMenuList2 = false;
        isShowTextMenuList3 = true;
      }
    } else {
      isShowTextMenuList1 = false;
      isShowTextMenuList2 = false;
      isShowTextMenuList3 = false;
    }
  }

  return (
    <>
      <MetaTags>
        <title>{getTitle()}</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content={getMetaDescription()} />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content={getTitle()} />
        <meta name="twitter:description" content={getMetaDescription()} />
        <meta name="twitter:image" content={window.location.origin + "/assets/img/common/ogp.png"}  />
        <meta name="twitter:site" content="@gochi_meshi" />
        <meta name="twitter:creator" content="@gochi_meshi" />
        <meta name="twitter:image:width" content="300" />
        <meta name="twitter:image:height" content="300" />
        <meta property="og:site_name" content={getTitle()} />
        <meta property="og:title" content={getTitle()} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={window.location.origin + "/assets/img/common/ogp.png"} />
        <meta property="og:description" content={getMetaDescription()} />
      </MetaTags>
      <Header />

      <main>
        <div className="breadcrumb">
          <div className="container">
            <ol className="breadcrumb-list">
              <li>
                <a href="/">ごちめし</a>
              </li>
              {isNoSearchCondition && (
                <li>
                  <span>メニュー一覧</span>
                </li>
              )}
              {isMultiSearchCondition || params.keyword ? (
                <li>
                  <span> メニューの検索結果</span>
                </li>
              ) : (
                !isNoSearchCondition && (
                  <>
                    {params.situation && (
                      <li>
                        <a href={`/products?situation=${params.situation}`}>
                          {params.situation}
                          {isShowTextMenuList1 ? "のメニュー一覧" : ""}
                        </a>
                      </li>
                    )}
                    {params.genre && (
                      <li>
                        <a href={`/products?genre=${params.genre}`}>
                          {params.genre}
                          {isShowTextMenuList1 ? "のメニュー一覧" : ""}
                        </a>
                      </li>
                    )}
                    {params.prices && (
                      <li>
                        <a href={`/products?prices=${params.prices}`}>
                          {params.prices}
                          {isShowTextMenuList1 ? "のメニュー一覧" : ""}
                        </a>
                      </li>
                    )}
                    {params.who && (
                      <li>
                        <a href={`/products?who=${params.who}`}>
                          {params.who}
                          {isShowTextMenuList1 ? "のメニュー一覧" : ""}
                        </a>
                      </li>
                    )}
                    {params.prefecture && (
                      <li>
                        <a
                          href={
                            "/products?" +
                            (params.situation
                              ? `situation=${params.situation}&`
                              : "") +
                            (params.genre ? `genre=${params.genre}&` : "") +
                            (params.prices ? `prices=${params.prices}&` : "") +
                            (params.who ? `who=${params.who}&` : "") +
                            (params.prefecture
                              ? `prefecture=${params.prefecture}`
                              : "")
                          }
                        >
                          {params.prefecture}
                          {isShowTextMenuList2 ? "のメニュー一覧" : ""}
                        </a>
                      </li>
                    )}
                    {params.city && (
                      <li>
                        <a
                          href={
                            "/products?" +
                            (params.situation
                              ? `situation=${params.situation}&`
                              : "") +
                            (params.genre ? `genre=${params.genre}&` : "") +
                            (params.prices ? `prices=${params.prices}&` : "") +
                            (params.who ? `who=${params.who}&` : "") +
                            (params.prefecture
                              ? `prefecture=${params.prefecture}&`
                              : "") +
                            (params.city ? `city=${params.city}` : "")
                          }
                        >
                          {params.city}
                          {isShowTextMenuList3 ? "のメニュー一覧" : ""}
                        </a>
                      </li>
                    )}
                  </>
                )
              )}
            </ol>
          </div>
        </div>
        <form
          action=""
          method="POST"
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch();
          }}
          className="search-area"
        >
          <div className="container">
            <div className="search-body">
              <div className="fieldset fieldset-column fieldset-lg">
                <div className="fieldset-header">
                  <svg className="icon">
                    <use xlinkHref="/assets/img/common/icon.svg#map-marker" />
                  </svg>
                  <div className="title">エリア</div>
                </div>
                <div className="fieldset-body">
                  <div className="field">
                    <div className="field field-pill field-sm">
                      <select
                        value={prefecture}
                        onChange={(e) => {
                          setPrefecture(e.target.value);
                          setCity("");
                        }}
                      >
                        <option value="">選択なし</option>
                        {listPrefectures.length > 0 &&
                          listPrefectures.map((item, key) => {
                            return (
                              <option key={key} value={item.prefectureName}>
                                {item.prefectureName}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="field field-pill field-sm">
                      <select
                        className="select-box-custom"
                        value={city}
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                      >
                        <option value="">選択なし</option>
                        {filteredCities.length > 0 &&
                          filteredCities.map((item, key) => {
                            return (
                              <option key={key} value={item.cityName}>
                                {item.cityName}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="fieldset fieldset-lg">
                <div className="fieldset-header">
                  <svg className="icon">
                    <use xlinkHref="/assets/img/common/icon.svg#popper" />
                  </svg>
                  <div className="title">シチュエーション</div>
                </div>
                <div className="fieldset-body">
                  <div className="field">
                    <button
                      className="btn btn-black btn-elipsis"
                      id="openSearchSituation"
                      data-target="modal01"
                      type="button"
                      onClick={() => {
                        setModalSituation(situation);
                      }}
                    >
                      <div id="searchselectedSituation">
                        {situation || "選択"}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div className="fieldset fieldset-lg">
                <div className="fieldset-header">
                  <svg className="icon">
                    <use xlinkHref="/assets/img/common/icon.svg#user" />
                  </svg>
                  <div className="title">贈り相手</div>
                </div>
                <div className="fieldset-body">
                  <div className="field">
                    <button
                      className="btn btn-black btn-elipsis"
                      id="openSearchGiftTo"
                      data-target="modal01"
                      type="button"
                      onClick={() => {
                        setModalUserTarget(userTarget);
                      }}
                    >
                      <div id="searchselectedGiftTo">
                        {userTarget || "選択"}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div className="fieldset fieldset-lg">
                <div className="fieldset-header">
                  <svg className="icon">
                    <use xlinkHref="/assets/img/common/icon.svg#utensils" />
                  </svg>
                  <div className="title">ジャンル</div>
                </div>
                <div className="fieldset-body">
                  <div className="field field-pill field-md">
                    <select
                      onChange={(e) => setGenre(e.target.value)}
                      value={genre}
                    >
                      <option value="">選択なし</option>
                      {genres.map((item, index) => (
                        <option
                          // key={`${item.genreId}`}
                          key={index}
                          value={item.menuGenreName}
                        >
                          {item.menuGenreName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="fieldset fieldset-lg">
                <div className="fieldset-header">
                  <svg className="icon">
                    <use xlinkHref="/assets/img/common/icon.svg#yen-sign" />
                  </svg>
                  <div className="title">予算</div>
                </div>
                <div className="fieldset-body">
                  <div className="field field-pill field-md">
                    <select
                      onChange={(e) => setPriceRange(e.target.value)}
                      value={priceRange}
                    >
                      <option value="">選択してください</option>
                      {priceRanges.map((item, key) => (
                        <option key={key} value={item.name}>
                          {getPriceRangeStrFromObj(item)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="fieldset fieldset-lg">
                <div className="fieldset-header">
                  <svg className="icon">
                    <use xlinkHref="/assets/img/common/icon.svg#search" />
                  </svg>
                  <div className="title">キーワード</div>
                </div>
                <div className="fieldset-body">
                  <div className="field field-pill field-lg">
                    <input
                      value={keyword}
                      type="text"
                      placeholder="キーワード"
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="fieldset fieldset-submit under-tab">
                <button
                  type="button"
                  className="btn btn-submit search-btn"
                  onClick={handleSearch}
                >
                  検索する
                </button>
              </div>
              {/* /.fieldset */}
            </div>
            <div className="search-footer over-pc">
              <button className="btn" type="button" onClick={handleSearch}>
                検索する
              </button>
            </div>
          </div>
        </form>
        <div className="container">
          {/* 店舗一覧のHTML */}
          <section
            className={
              !masterDataIsReady || (loading && searchResults.length === 0)
                ? "section loading"
                : "section"
            }
          >
            <div className="section-header">
              <div className="heading-lg">
                <h1 className="title">
                  {searchStr}
                  {searchStr ? `で見つかった商品・お食事券: ` : `見つかった商品・お食事券: `}
                  {totalResult.toLocaleString()}件
                </h1>
              </div>
            </div>
            <div className="section-body">
              {searchResults.length > 0 && (
                <nav className="nav">
                  <div className="fieldset">
                    <div className="field field-pill field-md">
                      <select
                        onChange={(e) => handleChangeOrder(e.target.value)}
                      >
                        {/* <option value="すべて">すべて</option> */}
                        <option value={1}>おすすめ順</option>
                        <option value={3}>価格の高い順</option>
                        <option value={4}>価格の安い順</option>
                        <option value={2}>新着順</option>
                      </select>
                    </div>
                  </div>
                </nav>
              )}
              <div className="products-group mt-20">
                {searchResults.map((menu) => (
                  <article
                    key={menu.menuId}
                    className={
                      loading
                        ? "products bg-white loading"
                        : "products bg-white"
                    }
                  >
                    <Link
                      className="products-link"
                      to={`/shopdetails/${menu.shopId || "group-menu"}/menu/${
                        menu.menuId
                      }`}
                    />
                    <div className="products-thumbnail">
                      <div className="thumbnail">
                        <img
                          src={
                            menu.menuImage
                              ? menu.menuImage[0] && menu.menuImage[0].image
                              : NO_IMAGE
                          }
                          alt={menu.menuName}
                          loading="lazy"
                        />
                      </div>
                      <div className="badge-group">
                        {menu.menuComboDetail?.quantityFree > 0 && (
                          <div className="badge badge-black">無料</div>
                        )}
                        {menu.menuComboDetail?.quantityFreeDes && (
                          <div className="badge">お得</div>
                        )}
                      </div>
                      <div className="action-group">
                        <div
                          className={
                            (
                              tempMenuWishList[menu.menuId] !== undefined
                                ? tempMenuWishList[menu.menuId]
                                : menu.isWishList
                            )
                              ? "action action-list is-active"
                              : "action action-list is-inactive"
                          }
                        >
                          <button
                            type="button"
                            aria-label="記事を保存"
                            onClick={() =>
                              onToggleMenuWishList(menu.menuId, menu.isWishList)
                            }
                          >
                            <svg className="icon">
                              <use xlinkHref="/assets/img/common/icon.svg#list" />
                            </svg>
                          </button>
                        </div>
                        <div
                          className={
                            (
                              tempMenuFavorite[menu.menuId] !== undefined
                                ? tempMenuFavorite[menu.menuId]
                                : menu.isFavourite
                            )
                              ? "action action-like is-active"
                              : "action action-like is-inactive"
                          }
                        >
                          <button
                            type="button"
                            aria-label="記事を保存"
                            onClick={() =>
                              onToggleMenuFavorite(
                                menu.menuId,
                                menu.isFavourite
                              )
                            }
                          >
                            <svg className="icon">
                              <use xlinkHref="/assets/img/common/icon.svg#heart" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="products-header">
                      <div className="text-sm text-gray title-custom">
                        {menu.menuChildType === 1 || menu.menuChildType === 2
                          ? menu.shopName
                          : menu.groupName}
                      </div>
                      <h2 className="title">{menu.menuName}</h2>
                    </div>
                    <div className="price-area">
                      <span className="unit">¥</span>
                      <span className="num">
                        {menu.chargeAmount?.toLocaleString()}
                      </span>
                      {/* <span className="dash">〜</span> */}
                    </div>
                    {menu.menuCombo === 1 && (
                      <div className="badge badge-lg badge-red">回数券</div>
                    )}
                  </article>
                ))}
              </div>
              {searchResults.length > 0 && (
                <CustomPagination
                  count={Math.ceil(totalResult / LIMIT)}
                  page={page}
                  onChange={handleChangePage}
                />
              )}
            </div>
            <div className="section-footer">
              <div className="card card-md mt-50">
                <p className="text-lg">
                  {searchStr2}で見つかったお食事券 (
                  {totalResult.toLocaleString()}
                  件) です。
                  <br />
                  「ごちめし」は飲食店のメニューをお食事券でプレゼントできるサービスです。ジャンルやシチュエーションに合ったお食事券を見つけて、大切なあの人にプレゼントしましょう。
                </p>
              </div>
              {(showSituationSuggestion ||
                showUserTargetSuggestion ||
                showGenreSuggestion ||
                showPriceRangeSuggestion) && (
                <div className="card card-lg mt-50 menu-select">
                  <div className="section-hide">
                    <div className="section-body">
                      {showSituationSuggestion && (
                        <section className="section-sm">
                          <div className="section-header">
                            <div className="heading-md">
                              <div className="title">
                                シチュエーションで絞り込む
                              </div>
                            </div>
                          </div>
                          <div className="section-body">
                            <div className="field field-radio field-pill">
                              {situations.map((item, index) => (
                                <label key={index}>
                                  <input
                                    className="before-icon"
                                    value={item.menuSituationName}
                                    readOnly
                                    // name="situation"
                                    type="radio"
                                    checked={
                                      params.situation ===
                                      item.menuSituationName
                                    }
                                    onClick={(e) => {
                                      handleSearch({
                                        situation: e.target.value,
                                      });
                                      setSituation(e.target.value);
                                    }}
                                  />
                                  <div className="btn btn-sm btn-outline btn-black">
                                    {item.menuSituationName}
                                  </div>
                                </label>
                              ))}
                            </div>
                          </div>
                        </section>
                      )}
                      {showGenreSuggestion && (
                        <section className="section-sm">
                          <div className="section-header">
                            <div className="heading-md">
                              <div className="title">ジャンルで絞り込む</div>
                            </div>
                          </div>
                          <div className="section-body">
                            <div className="field field-radio field-pill">
                              {genres.map((item, index) => (
                                <label key={index}>
                                  <input
                                    className="before-icon"
                                    value={item.menuGenreName}
                                    readOnly
                                    type="radio"
                                    checked={
                                      params.genre === item.menuGenreName
                                    }
                                    onClick={(e) => {
                                      handleSearch({ genre: e.target.value });
                                      setGenre(e.target.value);
                                    }}
                                  />
                                  <div className="btn btn-sm btn-outline btn-black">
                                    {item.menuGenreName}
                                  </div>
                                </label>
                              ))}
                            </div>
                          </div>
                        </section>
                      )}
                      {showUserTargetSuggestion && (
                        <section className="section-sm">
                          <div className="section-header">
                            <div className="heading-md">
                              <div className="title">贈り相手で絞り込む</div>
                            </div>
                          </div>
                          <div className="section-body">
                            <div className="field field-radio field-pill">
                              {userTargets.map((item) => (
                                <label key={item.menuUserTargetId}>
                                  <input
                                    className="before-icon"
                                    value={item.name}
                                    type="radio"
                                    checked={params.who === item.name}
                                    onClick={(e) => {
                                      handleSearch({
                                        userTarget: e.target.value,
                                      });
                                      setUserTarget(e.target.value);
                                    }}
                                  />
                                  <div className="btn btn-sm btn-outline btn-black">
                                    {item.name}
                                  </div>
                                </label>
                              ))}
                            </div>
                          </div>
                        </section>
                      )}
                      {showPriceRangeSuggestion && (
                        <section className="section-sm">
                          <div className="section-header">
                            <div className="heading-md">
                              <div className="title">予算で絞り込む</div>
                            </div>
                          </div>
                          <div className="section-body">
                            <div className="field field-radio field-pill">
                              {priceRanges.map((item, key) => (
                                <label key={key}>
                                  <input
                                    className="before-icon"
                                    value={item.name}
                                    type="radio"
                                    checked={params.prices === item.name}
                                    onClick={(e) => {
                                      handleSearch({
                                        priceRange: e.target.value,
                                      });
                                      setPriceRange(e.target.value);
                                    }}
                                  />
                                  <div className="btn btn-sm btn-outline btn-black">
                                    {getPriceRangeStrFromObj(item)}
                                  </div>
                                </label>
                              ))}
                            </div>
                          </div>
                        </section>
                      )}
                    </div>
                    {suggestionCount > 2 && (
                      <div
                        className={
                          suggestionExpanded
                            ? "btn-group section-btn-group "
                            : "btn-group section-btn-group is-close"
                        }
                      >
                        <button
                          style={{visibility: 'visible', marginTop: '20px'}}
                          className="section-toggle"
                          onClick={toggleSuggestionExpaned}
                        >
                          <svg className="icon">
                            <use
                              xlinkHref={
                                suggestionExpanded
                                  ? "/assets/img/common/icon.svg#angle-up"
                                  : "/assets/img/common/icon.svg#angle-down"
                              }
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
        <aside>
          <ListMenus
            swiperId="list-recently-view-menu-container"
            title="ごちめしで最近見た商品・お食事券"
            data={recentlyViewedMenus.map((menu) => ({
              ...menu,
              price: menu.lastPrice,
            }))}
          />
          <section className="section">
            <div className="container">
              <div className="section-header">
                <div className="heading-lg">
                  <h2 className="title">
                    ごちめしで見つかったお店: {totalShops?.toLocaleString()}件
                  </h2>
                </div>
              </div>
              <div className="section-body">
                <div className="products-group">
                  {listShops.map((item) => (
                    <article className="products" key={item.shopId}>
                      <Link
                        className="products-link"
                        to={`/shopdetails/${item.shopId}`}
                      />
                      <div className="products-thumbnail">
                        <div className="thumbnail">
                          <img
                            src={
                              item.shopImage
                                ? item.shopImage[0] && item.shopImage[0].image
                                : SHOP_NO_IMAGE
                            }
                            alt=""
                            loading="lazy"
                          />
                        </div>
                        <div className="action-group">
                          <div
                            className={
                              (
                                tempShopWishList[item.shopId] !== undefined
                                  ? tempShopWishList[item.shopId]
                                  : item.isWishList
                              )
                                ? "action action-list is-active"
                                : "action action-list is-inactive"
                            }
                          >
                            <button
                              type="button"
                              aria-label="記事を保存"
                              onClick={() =>
                                onToggleShopWishList(
                                  item.shopId,
                                  item.isWishList
                                )
                              }
                            >
                              <svg className="icon">
                                <use xlinkHref="/assets/img/common/icon.svg#list" />
                              </svg>
                            </button>
                          </div>
                          <div
                            className={
                              (
                                tempShopFavorite[item.shopId] !== undefined
                                  ? tempShopFavorite[item.shopId]
                                  : item.isFavourite
                              )
                                ? "action action-like is-active"
                                : "action action-like is-inactive"
                            }
                          >
                            <button
                              type="button"
                              aria-label="記事を保存"
                              onClick={() =>
                                onToggleShopFavorite(
                                  item.shopId,
                                  item.isFavourite
                                )
                              }
                            >
                              <svg className="icon">
                                <use xlinkHref="/assets/img/common/icon.svg#heart" />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="products-header">
                        <h2 className="title">{item.shopName}</h2>
                      </div>
                      <hr />
                      {/* <div className="price-area">
                      <span className="unit">¥</span>
                      <span className="num">500</span>
                      <span className="dash">〜</span>
                    </div> */}
                      {/* <div className="badge badge-lg badge-red">回数券</div> */}
                    </article>
                  ))}
                </div>
              </div>
              <div className="section-footer">
                <Link
                  className="link more-shop-link text-underline"
                  to={`/shops${shopSearchQuery}`}
                >
                  見つかったお店をもっと見る
                </Link>
              </div>
            </div>
          </section>
        </aside>
        
        <Explanation />
      </main>

      <Footer />

      <ModalGroup>
        <SituationModal />
        <GiftToModal />

        <NotLoggedModal />
        <WishListModal />
      </ModalGroup>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content, buttonName, gotoUrl) =>
    dispatch(showError(showing, title, content, buttonName, gotoUrl)),
});

export default connect(null, mapDispatchToProps)(MenuSearch);

import React, { Component } from "react";
import { connect } from "react-redux";
import { saveUserInfo } from "../../../actions/action_users";
import queryString from "query-string";
import AuthService from "../../../api/services/AuthService";
import {addParamUrl} from '../../../utils';

class Logout extends Component {
  UNSAFE_componentWillMount() {
    this.props.saveUserInfo(false);
    let url = window.location.search;
    let params = queryString.parse(url);
    var menuId = params.id;
    var src = params.src;
    var isSite = params.isSite;
    var isSiteParam = isSite ? {name: 'isSite', value: isSite} : {};
    var paramShashoku = '';
    const fromShashoku = params.fromShashoku;
    if (fromShashoku) {
      paramShashoku = `&fromShashoku=${encodeURIComponent(fromShashoku)}`;
    }

    // handle keep booking info when logout
    var emailBookingCampaign = sessionStorage.getItem("emailBookingCampaign");
    var secretCode = sessionStorage.getItem("secretCode");
    var monthBookingCampaign = sessionStorage.getItem("monthBookingCampaign");
    var campaignId = sessionStorage.getItem("campaignId");
    var bookingCampaignFinish = sessionStorage.getItem("bookingCampaignFinish");
    sessionStorage.clear();
    if(emailBookingCampaign !== null) sessionStorage.setItem("emailBookingCampaign", emailBookingCampaign);
    if(secretCode !== null) sessionStorage.setItem("secretCode", secretCode);
    if(monthBookingCampaign !== null) sessionStorage.setItem("monthBookingCampaign", monthBookingCampaign);
    if(campaignId !== null) sessionStorage.setItem("campaignId", campaignId);
    if(bookingCampaignFinish !== null) sessionStorage.setItem("bookingCampaignFinish", bookingCampaignFinish);


    const recentlyViewed = localStorage.getItem("recentlyViewed");
    const lastVisited = localStorage.getItem("lastVisited");
    localStorage.clear();
    localStorage.setItem("recentlyViewed", recentlyViewed);
    localStorage.setItem("lastVisited", lastVisited);

    var link = addParamUrl("/login", [isSiteParam]);

    const srcParam = src ? "&src=" + src : "";

    if (menuId) {
      link = addParamUrl("/login?id=" + menuId + srcParam + paramShashoku, [isSiteParam]);
    }

    AuthService.logout().then(() => {
      window.location.href = link;
    });
  }

  componentDidMount() {}

  render() {
    return <></>;
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  saveUserInfo: (isLogined, isGuest, userInfo) =>
    dispatch(saveUserInfo(isLogined, isGuest, userInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);

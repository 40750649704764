import { HTTP_API, makeRequest } from "../HttpClient";

const API_BASE_URL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
  : "";

export default class CampaignService {
  static async createCampaignBooking(campaignId, campaignBooking) {
    var url = `${API_BASE_URL}/campaign/${campaignId}/booking`;
    return makeRequest(HTTP_API.POST, url, campaignBooking);
  }

  static async getNumberRemaining(campaignId, month) {
    let params = {
      month: month,
    };
    let url = `${API_BASE_URL}/campaign/${campaignId}/info`;
    return makeRequest(HTTP_API.GET, url, params);
  }

  static async checkActiveCode(campaignId, activeCode) {
    var url = `${API_BASE_URL}/campaign/${campaignId}/active-code`;
    return makeRequest(HTTP_API.POST, url, activeCode);
  }

  static async checkMenu(campaignId, secretCode, email, month, menuId) {
    let params = {
      secretCode: secretCode,
      emailBookingCampaign: email,
      monthBookingCampaign: month,
      menuId: menuId
    };
    var url = `${API_BASE_URL}/campaign/${campaignId}/check-menu`;
    return makeRequest(HTTP_API.POST, url, params);
  }
}

import React, { useEffect, useRef } from "react";
import JsBarcode from "jsbarcode";

function Barcode({
  style,
  className,
  value,
  options,
  renderer = 'img'
}) {
  const containerRef = useRef(null);

  useEffect(() => {
    JsBarcode(containerRef.current, value, options);
  }, [containerRef.current, value, options, renderer]);

  switch (renderer) {
    case 'canvas':
      return <canvas ref={containerRef} style={style} className={className} />;
    case 'svg':
      return <svg ref={containerRef} style={style} className={className} />;
    default:
      return <img ref={containerRef} style={style} className={className} />;
  }
}

export default Barcode;

import React from "react";

function CampaignRedirect() {
  window.location.replace("https://kodomo-gochimeshi.org");
  return (
    <div></div>
  );
}

export default CampaignRedirect;

import * as constants from '../constants';

const initialState = {
  selectedFriendId: null,
  friendType: null,
  friendInfo: {
    situation: ""
  },
  gochiUserType: constants.GOCHI_TYPE_YOURSELF
};

export default (state = initialState, action) => { 
  switch (action.type){
    case 'SELECT_FRIEND':  
      return {       		
      		selectedFriendId: action.selectedFriendId,
      		friendType: action.friendType,
      		friendInfo: action.friendInfo,
          gochiUserType: action.gochiUserType
      };
    default:
      return state;
  }
};
const stateDefault = { 
};

export default (state = stateDefault, action) => { 
  switch (action.type){
    case 'GROUP_INFO_SAVE':
      return {
        ...state, 
        ...action.groupInfo
      };
    default:
      return state;
  }
};


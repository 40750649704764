import React from "react";
import MetaTags from 'react-meta-tags';
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { GOCHI_KID_PATH } from '../../../../constants';

import { CustomPagination } from "../../common/Pagination";
import moment from "moment";

export function GochiKidSupport(props) {
  const convertDate = (date, type) => {
    let d = new Date(date);
    let bYear = d.getFullYear();
    let bMonth = d.getMonth() + 1;
    let bDay = d.getDate();
    if (bMonth < 10) {
      bMonth = `0${bMonth}`;
    }
    if (bDay < 10) {
      bDay = `0${bDay}`;
    }
    let data = '';
    if (type === 'month') {
      data = `${bYear}年${bMonth}月`;
    } else if (type === 'year') {
      data = `${bYear}`;
    } else {
      data = `${bYear}年${bMonth}月${bDay}日`;
    }

    return data;
  }
  return (
    <>
      <MetaTags>
        <title>自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content="自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta name="twitter:description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:image" content="/assets/img/common/ogp.png" />
        <meta property="og:site_name" content="自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:title" content="自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content="/assets/img/common/ogp.png" />
        <meta property="og:description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
      </MetaTags>

      <hr className="mt-30" />
      <div className="gochi-kid-support-list" id="scrollToElementId">
        <div className={`profile-history ${props.loadingGochiCharge ? 'loading': ''}`}>
          <div className="products-group products-group-column">
            <div className="title-gochi-support-des">{props.userKidStaticData.displayName}様からのご支援も含め、{convertDate(props.userKidStaticData.currentMonth, 'month')}は<span>{props.userKidStaticData.allSet}</span>のこどもごちめし支援を達成しました！</div>
            <div className="section-header">
              <div className="heading-lg">
                <h1 className="title-gochi-kid-support">
                  支援履歴
                </h1>
              </div>
            </div>
            <div className="donated-box">
              {props.searchResults.length > 0 && props.searchResults.map((item, key) => {
                let index = `${item.prePaymentId}-${key}`;
                var shopName = item?.shopName ? item.shopName : null;
                var groupName = item?.groupName ? item.groupName : null;

                let image = props.NO_IMAGE_MENU;
                if (item.groupId) {
                  image = item.groupImages && item.groupImages.length > 0 && item.groupImages[0].image ? item.groupImages[0].image : props.NO_IMAGE_MENU
                } else if (item.shopId) {
                  image = item.shopImages && item.shopImages.length > 0 && item.shopImages[0].image ? item.shopImages[0].image : props.NO_IMAGE_MENU
                }

                let donateTo = '';
                if (item.isGlobal === 0) {
                  if (item.subscriptionDestination === 1) {
                    donateTo = '都道府県';
                  } else if (item.subscriptionDestination === 2) {
                    donateTo = '自治体/活動';
                  } else if (item.subscriptionDestination === 0) {
                    donateTo = '全国';
                  }
                } else {
                  donateTo = '全国';
                }

                return (
                  <article className="products gochi-kid-item" key={index}>
                    <div className="gochi-kid-support-item">
                      <dl className="list-description text-sm">
                        <div className="list-description-item">
                          <dt className="title font-weight-normal">支援日</dt>
                          <dd className="text font-weight-normal">{item.donateAt ? moment(item.donateAt).format('YYYY年MM月DD日') : ''}</dd>
                        </div>
                        <div className="list-description-item">
                          <dt className="title font-weight-normal description-gochi-charge">支援先</dt>
                          <dd className="text font-weight-normal">{donateTo}</dd>
                        </div>
                        {item.isGlobal === 0 && (
                          <>
                            <div className="gochi-kid-digest">
                              <div className="gochi-kid-thumbnail">
                                <div className="thumbnail">
                                  <img
                                    src={image}
                                    alt="menu image"
                                    loading="lazy"
                                  />
                                </div>
                              </div>
                              <div className="shop-header-group">
                                <div className="shop-header">
                                  {shopName || groupName}
                                </div>
                                <hr />
                                
                                {item.subscriptionDestination !== 1 && (
                                  <>
                                    <div className="address">
                                      <img className="icon" src='/assets/img/common/icon-map-marker.svg' alt=''/>
                                      {`${item.prefectures || ''} ${item.city || ''} ${item.address || ''} ${item.building || ''}`}
                                    </div>
                                    <div className="phone">
                                      <img className="icon" src='/assets/img/common/icon-phone.svg' alt=''/>
                                      {item.phone}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            {(item.description || item.introduction) && (
                              <div className="gochi-kid-shop-des">
                                {item.description || item.introduction}
                              </div>
                            )}
                          </>
                        )}
                        <div className="list-description-item">
                          <dt className="title font-weight-normal">支援金額</dt>
                          <dd className="text font-weight-normal">
                            {item.chargeAmount.toLocaleString()}円
                          </dd>
                        </div>
                        {item.donateMessage?.length > 0 && (
                          <>
                            <div className="list-description-item donate-massage-title">
                              <dt className="title font-weight-normal">応援メッセージ</dt>
                            </div>
                            <div className="donate-massage-text font-weight-normal">
                              {item.donateMessage}
                            </div>
                          </>
                        )}
                      </dl>
                      <div className="title-share-social">Share</div>
                      <div className="button-share-social-share">
                        <TwitterShareButton
                          title={item.isGlobal ? `${convertDate(item.donateAt)}、「こどもごちめし」を通じて支援しました。` : `${convertDate(item.donateAt)}、${shopName || groupName}のこども達を「こどもごちめし」を通じて支援しました。`}
                          url={"https://gochi.online" + GOCHI_KID_PATH + "/support/choice\n"}
                          hashtags={["毎日おいしくたのしく飲食店をこども食堂に\n", "こども達の未来を応援\n", "こどもごちめし\n"]}
                        >
                          <i className="icon-twitter-share" />
                        </TwitterShareButton>
                        <FacebookShareButton
                          url={"https://gochi.online" + GOCHI_KID_PATH + "/support/choice"}
                          quote={item.isGlobal ? `${convertDate(item.donateAt)}、「こどもごちめし」を通じて支援しました。` : `${convertDate(item.donateAt)}、${shopName || groupName}のこども達を「こどもごちめし」を通じて支援しました。`}
                          hashtag={"#こどもごちめし"}
                          description={item.isGlobal ? `${convertDate(item.donateAt)}、「こどもごちめし」を通じて支援しました。` : `${convertDate(item.donateAt)}、${shopName || groupName}のこども達を「こどもごちめし」を通じて支援しました。`}
                          className="Demo__some-network__share-button"
                        >
                          <i className="icon-facebook-share" />
                        </FacebookShareButton>
                      </div>
                    </div>
                  </article>
                );
              })}
            </div>
          </div>
          {props.searchResults.length > 0 && (
            <CustomPagination
              count={Math.ceil(props.totalResult / props.limit)}
              page={props.page}
              onChange={props.handleChangePage}
            />
          )}
        </div>
      </div>
      <div id="fb-root"></div>
    </>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import { PaginationItem } from "@material-ui/lab";

export function CustomPagination(props) {
  return (
    <nav className="pagination pagination-mogugori">
      <Pagination
        count={props.count}
        page={props.page}
        onChange={(e, p) => {
          e.preventDefault();
          if (p <= 0 || p > props.count) return;
          props.onChange(p);
        }}
        renderItem={(item) => {
          switch (item.type) {
            case "previous":
              return (
                <Link
                  className={
                    props.page > 1
                      ? "pagination-item pagination-prev"
                      : "d-none"
                  }
                  to="#"
                  onClick={item.onClick}
                >
                  <svg className="icon">
                    <title>前へ</title>
                    <use xlinkHref="/assets/img/common/icon.svg#angle-left" />
                  </svg>
                </Link>
              );
            case "page":
              return item.selected ? (
                <span className="pagination-item current">{item.page}</span>
              ) : (
                <Link className="pagination-item" to="#" onClick={item.onClick}>
                  {item.page}
                </Link>
              );
            case "next":
              return (
                <Link
                  to="#"
                  className={
                    props.page < props.count
                      ? "pagination-item pagination-next"
                      : "d-none"
                  }
                  onClick={item.onClick}
                >
                  <svg className="icon">
                    <title>次へ</title>
                    <use xlinkHref="/assets/img/common/icon.svg#angle-right" />
                  </svg>
                </Link>
              );
            case "start-ellipsis":
              return (
                <span className="pagination-item pagination-dots">....</span>
              );
            case "end-ellipsis":
              return (
                <span className="pagination-item pagination-dots">....</span>
              );
            default:
              return <PaginationItem {...item} />;
          }
        }}
      />
    </nav>
  );
}

import React from 'react';

function PaymentCardBrands(props) {
  let wrapperClassName = 'payment-card-brands';
  if (props.className) {
    wrapperClassName += ' ' + props.className;
  }
  return (
    <div className={wrapperClassName}>
      <div className="payment-card-brand payment-card-brand-visa"></div>
      <div className="payment-card-brand payment-card-brand-jcb"></div>
      <div className="payment-card-brand payment-card-brand-mastercard"></div>
      <div className="payment-card-brand payment-card-brand-amex"></div>
      <div className="payment-card-brand payment-card-brand-diners"></div>
    </div>
  );
}

export default PaymentCardBrands;

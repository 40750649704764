/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import {
  useArchive,
  useScript, 
  useStyleSheet
} from "../../../../utils";
import { useRouteMatch } from "react-router";
import { Footer } from "../../layout/Footer";
import Header from "../../layout/Header";
import GiftService from "../../../../api/services/GiftService";
import { connect } from "react-redux";
import ReactHtmlParser from 'react-html-parser';
import { useHistory } from "react-router-dom";
import { showLoading } from "../../../../actions/action_loading";
import { showError } from "../../../../actions/action_error_alert";
import { ModalGroup } from "../../common/ModalGroup";
import { SituationModal } from "../../common/SituationModal";
import { GiftToModal } from "../../common/GiftToModal";
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { Explanation } from "../../layout/Explanation";

export function NewsDetail(props){
  useStyleSheet("https://unpkg.com/swiper/swiper-bundle.min.css",
    null,
    {
      integrity:
        "sha384-HUXJxzkMpCxEdXkgl8BSjN5SUcUODepsOLIYIWOtKva+Qo2teMmV1mh6ZVwnthaV",
      crossorigin: "anonymous",
      referrerpolicy: "no-referrer",
    }
  );
  useScript("https://unpkg.com/swiper/swiper-bundle.min.js",
    null,
    { integrity: "sha384-XVTmRHMO2XUTzy8IerJVEy8ddg47Yz5pZrLc7nEWipaTowqT1xNb8qTLaw64Bd4S",
      crossorigin: "anonymous"
    }
  );
  useScript("/assets/js/app.js");

  const NO_IMAGE = require("../../../../assets/img/icons/noimage.png");

  const match = useRouteMatch();
  const postId = match.params.postId;
  const page = 1;
  const LIMIT = 5;
  const [showNews, setShowNews] = useState([]);
  const [showNewsDetail, setShowNewsDetail] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const archive = useArchive();

  const history = useHistory();

  const { t } = props;


  const fetchJsFromCDN = (href, externals = []) => {
    new Promise((resolve, reject) => {
      const link = document.createElement('link')
      link.setAttribute('href', href)
      link.setAttribute('rel', 'stylesheet')
      link.addEventListener('load', () => {
        resolve(externals.map(key => {
          const ext = window[key]
          typeof ext === 'undefined' && console.warn(`No external named '${key}' in window`)
          return ext
        }))
      })
      link.addEventListener('error', reject)
      document.body.appendChild(link)
    })
  }

  useEffect(() => {
    fetchJsFromCDN('https://gochimeshi.com/wordpress/wp-content/themes/gochimeshi-wp-theme-legacy/gift-site-style.css', ['giftsite']);
  }, []);

  useEffect(() =>{
    window.scrollTo(0, 0);
    getNews(page, LIMIT);
    getNewsDetail(postId);

  }, [page, LIMIT, postId]);

  function getTitle() {
    return (showNewsDetail.title || "") + "｜お知らせ一覧｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス";
  }
  
  function getMetaDescription() {
    return `${showNewsDetail.title || ""}ページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。`;
  }

  async function getNews(page, perPage) {
    setLoadingList(true);
    GiftService.getNews(page, perPage).then((response) => {
      setLoadingList(false);
      if(response.status === 200){
        setShowNews(response.data.contents);
      } else {
        setShowNews([]);
      }
    })
    .catch((e) => {
      setLoadingList(false);
      console.error("GET ALL NEWS ERROR: ", e);
    });
  }

  async function getNewsDetail(postId) {
    setLoadingDetail(true);
    GiftService.getNewsDetail(postId).then((response) => {
      setLoadingDetail(false);
      if(response.status === 200){
        setShowNewsDetail(response.data);
      } else if (response.status === 404) {
        setLoadingDetail(false);
        history.push({
          pathname: '/404'
        });
      }
    })
    .catch((e) => {
      setLoadingDetail(false);
      console.error("GET NEWS DETAIL ERROR: ", e);
    });
  }

  function formatDate(value) {
    return value && value !== ""
      ? moment(value).format('YYYY.MM.DD')
      : value;
  }

  function getSrc(item) {
    if (!loadingDetail) {
      if (item.thumbnails?.full?.url) {
        return item.thumbnails.full.url
      } else return NO_IMAGE;
    }
    return '';
  }

  function handleClickArchive(year) {
    history.push({
      pathname: '/news',
      state: {
        year
      },
    });
  }

  const loading = () => {
    if (archive.length > 0) {
      return false;
    }
    return true;
  }

  return (
    <>
      <MetaTags>
        <title>{getTitle()}</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content={getMetaDescription()} />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content={getTitle()} />
        <meta name="twitter:description" content={getMetaDescription()} />
        <meta name="twitter:image" content={window.location.origin + "/assets/img/common/ogp.png"}  />
        <meta name="twitter:site" content="@gochi_meshi" />
        <meta name="twitter:creator" content="@gochi_meshi" />
        <meta name="twitter:image:width" content="300" />
        <meta name="twitter:image:height" content="300" />
        <meta property="og:site_name" content={getTitle()} />
        <meta property="og:title" content={getTitle()} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={window.location.origin + "/assets/img/common/ogp.png"} />
        <meta property="og:description" content={getMetaDescription()} />
      </MetaTags>
      <Header />
    
      <main>
        <div className={loadingDetail ? "breadcrumb loading" : "breadcrumb"}>
          <div className="container">
            <ol className="breadcrumb-list">
              <li>
                <a href="/">{t('pages.news.topPage')}</a>
              </li>
              <li>
                <a href="/news">{t('pages.news.shortTitle')}</a>
              </li>
              <li>
                <span>{showNewsDetail.title}</span>
              </li>
            </ol>
          </div>
        </div>

        <div className="container">
          <div className="sidebar-group">
            <article className={loadingDetail ? "article loading" : "article"}>
              <header className="article-header">
                <div className="heading">
                  <h1 className="title">{showNewsDetail.title}</h1>
                </div>
                <div className="article-header-info">
                  <div className="article-header-info-date">
                    <ul className="list-inline text-gray text-nowrap">
                      <li>{t('pages.news.detail.postDate')}<time>{formatDate(showNewsDetail.date)}</time></li>
                      <li>{t('pages.news.detail.updateDate')}<time>{formatDate(showNewsDetail.modified)}</time></li>
                    </ul>
                  </div>
                </div>
              </header>
              <figure className="article-thumbnail">
                <img 
                  src={getSrc(showNewsDetail)}
                  alt={showNewsDetail.post_id}
                  loading="lazy"
                />
              </figure>
              <section className="article-body">
                {ReactHtmlParser(showNewsDetail.content)}
              </section>
              <div className="article-footer">
                <nav className="pagination is-article">
                  <a
                    className={showNewsDetail.prev_post_id
                      ? "pagination-item pagination-prev"
                      : "pagination-item pagination-prev disabled"}
                    href={`/news/${showNewsDetail.prev_post_id}`}
                  >
                    <div className="icon-area">
                      <svg className="icon">
                        <title>{t('pages.news.detail.forwardTitle')}</title>
                        <use xlinkHref="/assets/img/common/icon.svg#angle-left"></use>
                      </svg>
                    </div>
                    <div className="text">{t('pages.news.detail.nextNews')}</div>
                  </a>
                  <a className="pagination-item pagination-back" href="/news">{t('pages.news.detail.list')}</a>
                  <a
                    className={showNewsDetail.next_post_id
                      ? "pagination-item pagination-next"
                      : "pagination-item pagination-next disabled"}
                    href={`/news/${showNewsDetail.next_post_id}`}
                  >
                    <div className="text">{t('pages.news.detail.oldNews')}</div>
                    <div className="icon-area">
                      <svg className="icon">
                        <title>{t('pages.news.detail.backTitle')}</title>
                        <use xlinkHref="/assets/img/common/icon.svg#angle-right"></use>
                      </svg>
                    </div>
                  </a>
                </nav>
              </div>
            </article>
            <aside className="sidebar box">
              <section className="sidebar-section">
                <header className="sidebar-header">
                  <div className="heading">
                    <h3 className="title">{t('pages.news.detail.recentNews')}</h3>
                  </div>
                </header>
                <div className={
                  loadingList
                  ? "sidebar-body loading"
                  : "sidebar-body"
                }>
                  {showNews && showNews.length > 0 && (
                    <ul className="latest-news-list">
                      {showNews.map((news, key) => {
                        let index = `news-${key}`;
                        return (
                          <li className="latest-news-list-item" key={index}>
                            <a 
                              href={`/news/${news.post_id}`}
                              className="text-black"
                            >
                              <div className="date-area text-gray">
                                <time className="date">{formatDate(news.modified)}</time>
                              </div>
                              <div className="title text-black text-bold">{news.title}</div>
                            </a>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </div>
              </section>
              
              <section className="sidebar-section">
                <header className="sidebar-header">
                  <div className="heading">
                    <h3 className="title">{t('pages.news.detail.archive')}</h3>
                  </div>
                </header>
                <div className={loading() ? "sidebar-body loading" : "sidebar-body"}>
                  {archive && archive.length > 0 && (
                    <ul className="archive-list">
                      {archive.map((item, key) => {
                        let index = `archive-${key}`;
                        return (
                          <li className="archive-list-item" key={index}>
                            <a
                              className="text-black"
                              onClick={() => handleClickArchive(item.year)}
                              href
                            >
                              {item.year}{t('other.year')}{t('other.leftBracket')}{item.count}{t('other.rightBracket')}
                            </a>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </div>
                <div className="sidebar-footer">
                  <div className="sidebar-banner-group">
                    <a 
                      href="https://user-guide.gochimeshi.com/"
                      className="sidebar-banner-faq bg-white"
                      target="blank"
                    >
                      <img src="/assets/img/common/faq-banner.png" alt="よくある質問" className="img" />
                    </a>
                  </div>
                </div>
              </section>
            </aside>
          </div>
        </div>
        
        <Explanation />
      </main>
    
      <Footer />

      <ModalGroup>
        <SituationModal />
        <GiftToModal />
      </ModalGroup>
    </>
  )
}

const mapStateToProps = (state) => ({ props: state.props });
const mapDispatchToProps = (dispatch) => ({
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content, buttonName, gotoUrl) =>
    dispatch(showError(showing, title, content, buttonName, gotoUrl)),
});

export default connect(mapDispatchToProps, mapStateToProps)(withTranslation()(NewsDetail));
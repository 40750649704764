/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Fragment, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getCitiesSuccess } from "../../../actions/action_cities";
import { getPrefecturesSuccess } from "../../../actions/action_prefectures";
import {
  getSituationsSuccess,
  setModalSituationAction,
  setSituationAction,
} from "../../../actions/action_situations";
import {
  getUserTargetsSuccess,
  setModalUserTargetAction,
  setUserTargetAction,
} from "../../../actions/action_user_targets";
import {
  usePrefectures,
} from "../../../utils";
import queryString from "query-string";

function Header(props) {

  usePrefectures((list) => props.savePrefectures(list));

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = queryString.parse(window.location.search);

  const setSituation = (payload) => dispatch(setSituationAction(payload));
  const setUserTarget = (payload) => dispatch(setUserTargetAction(payload));
  const setModalSituation = (payload) =>
    dispatch(setModalSituationAction(payload));
  const setModalUserTarget = (payload) =>
    dispatch(setModalUserTargetAction(payload));

  useEffect(() => {
    setSituation(params.situation || "");
    setUserTarget(params.who || "");
    setPrefecture(params.prefecture || "");
    setCity(params.city || "");
    setGenre(params.genre || "");
    setKeyword(params.keyword || "");
    setPriceRange(params.prices || "");
  }, [location.search]);

  const [prefecture, setPrefecture] = useState(params.prefecture || "");
  const [city, setCity] = useState(params.city || "");
  const listPrefectures = useSelector((state) => state.prefectures);
  const listCities = useSelector((state) => state.cities);
  const prefectureId = getPrefectureId(prefecture);
  const [genre, setGenre] = useState(params.genre || "");
  const [priceRange, setPriceRange] = useState(params.prices || "");
  const userTarget = useSelector((state) => state.userTarget.userTarget);
  const situation = useSelector((state) => state.situation.situation);
  const [keyword, setKeyword] = useState(params.keyword || "");
  const userInfo = useSelector((state) => state.userInfo);

  const filteredCities = listCities.filter(
    (city) => Number(city.prefCode) === prefectureId
  );

  function getPrefectureId(name) {
    if (name) {
      const prefectureObj = listPrefectures.find(
        (item) => item.prefectureName === name
      );
      if (prefectureObj) return prefectureObj.prefectureId;
    }
    return "";
  }

  function getPriceRangeStrFromObj(range) {
    return `${range.name && range.name.toLocaleString()}`;
  }

  function handleSearchAllParams() {
    const params = [];
    if (prefecture) params.push(`prefecture=${prefecture}`);
    if (city) params.push(`city=${city}`);
    if (situation) params.push(`situation=${situation}`);
    if (genre) params.push(`genre=${genre}`);
    if (priceRange) params.push(`prices=${priceRange}`);
    if (userTarget) params.push(`who=${userTarget}`);
    if (keyword) params.push(queryString.stringify({ keyword }));

    const query = params.join("&");
    history.push({
      pathname: "/products",
      search: `?${query}`,
    });

    document
      .getElementsByTagName("html")[0]
      .classList.remove("sp-header-search-active", "sp-header-search-enter");
  }

  function handleSearch() {
    const params = [];
    if (prefecture) params.push(`prefecture=${prefecture}`);
    if (city) params.push(`city=${city}`);
    if (keyword) params.push(queryString.stringify({ keyword }));

    const query = params.join("&");
    history.push({
      pathname: "/products",
      search: `?${query}`,
    });

    document
      .getElementsByTagName("html")[0]
      .classList.remove("global-nav-active");
  }

  return (
    <Fragment></Fragment>
  );
}

const mapStateToProps = (state) => ({
  prefectures: state.prefectures,
  cities: state.cities,
  userInfo: state.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  savePrefectures: (list) => dispatch(getPrefecturesSuccess(list)),
  saveCities: (list) => dispatch(getCitiesSuccess(list)),
  saveSituations: (list) => dispatch(getSituationsSuccess(list)),
  saveUserTargets: (list) => dispatch(getUserTargetsSuccess(list)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

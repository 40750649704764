import React, { Component } from "react";   
import {Link} from 'react-router-dom'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import {connect} from 'react-redux'; 
import {log, addParamUrl} from '../../../utils'; 
import PaymentService from "../../../api/services/PaymentService";
import * as constants from '../../../constants';
import { withTranslation } from 'react-i18next';
import {savePaymentMethod} from "../../../actions/action_payment";
import queryString from 'query-string'
import PaymentCardBrands from "../../common/PaymentCardBrands";
import logoAtone from "../../../assets/img/icons/logo_atone.png";
import PopupAtoneDescription from "../../common/PopupAtoneDescription";

class Payment extends Component {
	constructor(){
	    super(); 
	    this.state = { 
	    	menuId: null,
			redirectToReferrer: false,
			src: "",
            paramShashoku: '',
			shopId: null,
			paymentDefault: [],
			showAtoneDescription: false,
			isSite: '',
			atoneHide: false
        } 
	}

	async fetchPaymentDefault(userId) { 
		try { 
			console.log('paymentMethod', sessionStorage.getItem('paymentMethod'));
			if (userId) {
				if (!sessionStorage.getItem('paymentMethod')) {
					var paymentDefault = await this.getPaymentDefault(userId);
					// this.setState({
					// 	paymentDefault: paymentDefault.list[0]
					// }) 
					if (paymentDefault.list.length > 0){
						sessionStorage.setItem('paymentMethod', JSON.stringify(paymentDefault.list[0])); 
						this.props.savePaymentMethod(paymentDefault.list[0]);
					}
				}else{
					this.props.savePaymentMethod(JSON.parse(sessionStorage.getItem('paymentMethod')));
				}
			} 
		} catch (e) {
			log(e); 
		}		
	}

	getPaymentDefault = async (
		userId 
		) => { 
			try {
			  	const { data } = await PaymentService.getDefaultCard(userId);
			  	return data;
			} catch (error) { 
			  	log('getDefaultCard error'); 
			  	this.props.showError(true, this.props.t("There is something wrong"), "");
			}
	};

	UNSAFE_componentWillMount(){ 
		let url = window.location.search;
		let params = queryString.parse(url);
		var menuId = params.id; 
        var src = params.src;
		var shopId = params.shopId;
        const fromShashoku = params.fromShashoku;
		const isSite = params.isSite;

		if (src) {
			this.setState({
				src: src
			});
		}

		if (isSite) {
			this.setState({
				isSite: isSite
			});
		}

		this.setState({
			menuId: menuId
        });
        
        if (fromShashoku) {
            this.setState({
				paramShashoku: `&fromShashoku=${encodeURIComponent(fromShashoku)}`
			});
        }

		if(shopId) {
			this.setState({
				shopId: shopId
			});
		}
	}

	componentDidMount(){
		var userInfo = this.props.userInfo;
    	if (userInfo) {
    		if (userInfo.userId) {
	            this.fetchPaymentDefault(userInfo.userId);
	        }
    	}
		if (!window.Atone) {
			this.setState({
				atoneHide: true
			});
		} else {
			this.setState({
				atoneHide: false
			});
		}
	}

	handleActiveButton = (e) => {
        this.setState({ buttonActive: !this.state.buttonActive });
		sessionStorage.setItem('selectPayment', e.target.value);
    }
    render() {
		const isMenuSubscription = this.props.menuInfo.subscription === 1;

		console.log('paymentDefault', this.state.paymentDefault);
		const paypaySupport = ((this.props.shopInfo 
            && this.props.shopInfo.paymentTyptes 
            && this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_PAYPAY)
            && this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_PAYPAY).status === 1) || this.props.menuInfo.groupId)
			&& !isMenuSubscription
            ? true : false;
		
		const alipaySupport = ((this.props.shopInfo 
			&& this.props.shopInfo.paymentTyptes 
			&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_ONEPAY_ALIPAY)
			&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_ONEPAY_ALIPAY).status === 1) || this.props.menuInfo.groupId) 
			&& !isMenuSubscription
			? true : false;
		
		const wechatPaySupport = this.props.shopInfo 
			&& this.props.shopInfo.paymentTyptes 
			&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_WECHATPAY)
			&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_WECHATPAY).status === 1 
			&& !isMenuSubscription
			? true : false;

		const linepaySupport = this.props.shopInfo 
			&& this.props.shopInfo.paymentTyptes 
			&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_ONEPAY_LINEPAY)
			&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_ONEPAY_LINEPAY).status === 1 
			&& !isMenuSubscription
			? true : false;

		const merupaySupport = this.props.shopInfo 
			&& this.props.shopInfo.paymentTyptes 
			&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_ONEPAY_MERUPAY)
			&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_ONEPAY_MERUPAY).status === 1 
			&& !isMenuSubscription
			? true : false;

		// const atoneSupport = this.props.shopInfo 
		// 	&& this.props.shopInfo.paymentTyptes 
		// 	&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_ATONE)
		// 	&& this.props.shopInfo.paymentTyptes.find(el => el.paymentType === constants.PAYMENT_TYPE_ATONE).status === 1 
		// 	&& !isMenuSubscription
		// 	? true : false;
		const atoneSupport = !isMenuSubscription;

		const validPaymentTypes = [];
		validPaymentTypes.push(constants.PAYMENT_TYPE_CREDIT_CARD);
		if (paypaySupport) validPaymentTypes.push(constants.PAYMENT_TYPE_PAYPAY);
		if (alipaySupport) validPaymentTypes.push(constants.PAYMENT_TYPE_ONEPAY_ALIPAY);
		if (wechatPaySupport) validPaymentTypes.push(constants.PAYMENT_TYPE_WECHATPAY);
		if (linepaySupport) validPaymentTypes.push(constants.PAYMENT_TYPE_ONEPAY_LINEPAY);
		if (merupaySupport) validPaymentTypes.push(constants.PAYMENT_TYPE_ONEPAY_MERUPAY);
		if (atoneSupport) validPaymentTypes.push(constants.PAYMENT_TYPE_ATONE);

		const onlyCreditCardSupport = !paypaySupport && !alipaySupport && !wechatPaySupport && !linepaySupport && !merupaySupport && !atoneSupport;

		const { t } = this.props;

		if (this.props.shopInfo) {
			if (sessionStorage.getItem('selectPayment')) {
				if (!validPaymentTypes.includes(parseInt(sessionStorage.getItem('selectPayment')))) {
					sessionStorage.removeItem('selectPayment');
				}
			}
			if (!sessionStorage.getItem('selectPayment')) {
				if (paypaySupport) {
					sessionStorage.setItem('selectPayment', constants.PAYMENT_TYPE_PAYPAY);
				} else if (alipaySupport) {
					sessionStorage.setItem('selectPayment', constants.PAYMENT_TYPE_ONEPAY_ALIPAY);
				} else if (wechatPaySupport) {
					sessionStorage.setItem('selectPayment', constants.PAYMENT_TYPE_WECHATPAY);
				} else if (linepaySupport) {
					sessionStorage.setItem('selectPayment', constants.PAYMENT_TYPE_ONEPAY_LINEPAY);
				} else if (merupaySupport) {
					sessionStorage.setItem('selectPayment', constants.PAYMENT_TYPE_ONEPAY_MERUPAY);
				} else {
					sessionStorage.setItem('selectPayment', constants.PAYMENT_TYPE_CREDIT_CARD);
				}
			}
		}

		let isCreditCard = parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_STRIPE ||
				parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_VERITRANS;

		var srcParam = this.state.src ? '&src=' + this.state.src : '';
		const referer = this.props.referer;
		const refererParam = referer ? `&referer=${encodeURIComponent(referer)}` : '';
		srcParam += refererParam;
		console.log('referer: ', srcParam);
		var isSiteParam =  this.state.isSite ? {name: 'isSite', value: this.state.isSite} : {};

		var url = (!this.state.menuId && this.state.shopId) ? addParamUrl(`/payment-list?shopId=`+this.state.shopId + refererParam, [isSiteParam])  : addParamUrl(`/payment-list?id=`+this.state.menuId + srcParam + this.state.paramShashoku, [isSiteParam]);

		return (
        	<div className="dish-infor pm-info mt-3">
				<div className="required-dot mb-3">
					<div className="dot-red"></div>
					<div className="dot-text-label fs-18">{t('pages.invite.paymentMethod')}</div>
				</div>

				{this.props.selectPaymentError &&
	    			<div className="text-danger fs-14 mb-2 fs-12 box-warning-message">{this.props.selectPaymentError}</div>
	    		}
				<div className="box-option">
					{paypaySupport && 
						<div className="d-flex">
							<div className="">
								<label className="radio-custom fs-14">
									<input type="radio" name="paymentType" value={constants.PAYMENT_TYPE_PAYPAY}
										onChange={this.handleActiveButton}
										checked={parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_PAYPAY}
									/>
									<span className="checkmark"></span>
									<span>{t('pages.invite.payment paypay')}</span>
								</label>
							</div>
							{/* <a 
								href="https://paypay.ne.jp/event/matsuri202103-paypay-jumbo-20210327/" 
								target="_blank" 
								rel="noopener noreferrer"
								className="ml-auto banner-width-40"
							>
								<img src={require("../../../assets/img/icons/paypay_banner_new.jpg")} alt="register" width="100%" />
							</a> */}
						</div>
					}
					
					{alipaySupport &&
						<div className="d-flex">
							<div className="">
								<label className="radio-custom fs-14">
									<input type="radio" name="paymentType" value={constants.PAYMENT_TYPE_ONEPAY_ALIPAY}
										onChange={this.handleActiveButton}
										checked={parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_ONEPAY_ALIPAY}
									/>
									<span className="checkmark"></span>
									<span>{t('pages.invite.payment alipay')}</span>
								</label>
								{parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_ONEPAY_ALIPAY &&
									<div className="payment-warning-message">{t('pages.invite.alipay warning')}</div>}
							</div>
						</div>
					}
					{wechatPaySupport && 
						<div className="d-flex">
							<div className="">
								<label className="radio-custom fs-14">
									<input type="radio" name="paymentType" value={constants.PAYMENT_TYPE_WECHATPAY}
										onChange={this.handleActiveButton}
										checked={parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_WECHATPAY}
									/>
									<span className="checkmark"></span>
									<span>{t('pages.invite.payment wechatpay')}</span>
								</label>
								{parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_WECHATPAY &&
									<div className="payment-warning-message">{t('pages.invite.wechatpay warning')}</div>}
							</div>
						</div>
					}
					{linepaySupport &&
						<div className="d-flex">
							<div className="">
								<label className="radio-custom fs-14">
									<input type="radio" name="paymentType" value={constants.PAYMENT_TYPE_ONEPAY_LINEPAY}
										onChange={this.handleActiveButton}
										checked={parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_ONEPAY_LINEPAY}
									/>
									<span className="checkmark"></span>
									<span>{t('pages.invite.payment linepay')}</span>
								</label>
								{parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_ONEPAY_LINEPAY &&
									<div className="payment-warning-message">{t('pages.invite.linepay warning')}</div>}
							</div>
						</div>
					}
					{merupaySupport &&
						<div className="d-flex">
							<div className="">
								<label className="radio-custom fs-14">
									<input type="radio" name="paymentType" value={constants.PAYMENT_TYPE_ONEPAY_MERUPAY}
										onChange={this.handleActiveButton}
										checked={parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_ONEPAY_MERUPAY}
									/>
									<span className="checkmark"></span>
									<span>{t('pages.invite.payment merupay')}</span>
								</label>
								{parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_ONEPAY_MERUPAY &&
									<div className="payment-warning-message">{t('pages.invite.merpay warning')}</div>}
							</div>
						</div>
					}
					{(!onlyCreditCardSupport) && <div className="d-flex">
						<div className="">
							<label className="radio-custom fs-14">
								<input type="radio" name="paymentType" value={constants.PAYMENT_TYPE_CREDIT_CARD}
									onChange={this.handleActiveButton}
									checked={parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_CREDIT_CARD}
								/>
								<span className="checkmark"></span>
								<span>{t('pages.invite.payment other')}</span>
							</label>
						</div>
					</div>}
					{this.props.enable3dSecure && (
						<div className="text-red mb-2 fs-12 text-note-veritrans">※3Dセキュア対応のカードのみ</div>
					)}
					{((this.props.shopInfo && onlyCreditCardSupport && !this.props.menuInfo.groupId) || (this.props.shopInfo && isCreditCard) || (this.props.menuInfo.groupId && isCreditCard)) &&
						<div className="payment-info ">
							<div className="d-flex align-items-end">
								{ this.props.paymentMethod ? 
									(this.props.paymentMethod.length === 0) ?
										<div className="fs-14">
											{t('pages.invite.Please select your payment method')}
										</div>
									:  
										<div className="">
											<div className="fs-14">{this.props.paymentMethod.brand}****-****-****-{this.props.paymentMethod.last4}</div>
											<div className="fs-12 font-weight-bold">{this.props.paymentMethod.holder}</div>
											<div className="payment-date">
												<div className="">
													<span>{t('pages.invite.expiredDate')}: </span>
													<span>{this.props.paymentMethod.expireMonth.toString().padStart(2, '0')}/{this.props.paymentMethod.expireYear}</span>
												</div> 
											</div>
										</div>
								:
									<div className="fs-14">
										{t('pages.invite.Please select your payment method')}
									</div> 
								}

								<div className="ml-auto">
									<Link to={url} className="btn btn-white-26 ml-2">
										<FontAwesomeIcon icon={faPen} className="mr-2" />
										{this.props.paymentMethod ? 
												(this.props.paymentMethod.length === 0) ? 
												t('pages.invite.selectPayment') 
											: 
												t('pages.invite.changePayment')
										:
											t('pages.invite.selectPayment') 
										} 
									</Link>
								</div>
							</div> 
							<div className="mt-2 mb-4">
								<PaymentCardBrands />
							</div>
						</div>
					}

					{atoneSupport &&
						<div className="d-flex">
							<div className="">
								{!this.state.atoneHide &&
									<label className="radio-custom fs-14">
										<input type="radio" name="paymentType" value={constants.PAYMENT_TYPE_ATONE}
											onChange={this.handleActiveButton}
											checked={parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_ATONE}
										/>
										<span className="checkmark"></span>
										<span>{t('pages.invite.payment atone')}</span>
									</label>
								}
								{/* {parseInt(sessionStorage.getItem('selectPayment')) === constants.PAYMENT_TYPE_ATONE && */}
									<div className="atone-description">
										{!this.state.atoneHide ? (
										<>
											<div className="text-red mb-2 fs-12">※atoneアカウントをお持ちではない方は、会員登録が必要です</div>
											<div>
												<a
													href="https://atone.be/"
													target="_blank"
													rel="noopener noreferrer"
												>
													<img src={logoAtone} alt="Atone" />
												</a>
											</div>
										</>
										) : (
										<div className="fs-12">
											※広告ブロック等の理由により、atone決済が表示されません。<br />
											atone決済をご利用ご希望の方は、ご利用のブラウザを変更してお試しください。
										</div>
										)}
										<span onClick={() => this.setState({ showAtoneDescription: true })}>atone説明文</span>
									</div>
								{/* } */}
							</div>
						</div>
					}
				</div>
				<PopupAtoneDescription show={this.state.showAtoneDescription} handleClose={() => this.setState({ showAtoneDescription: false })} />
			</div>
        ); 
    }
}
 

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({   
	savePaymentMethod: (paymentMethod) => dispatch(savePaymentMethod(paymentMethod)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Payment)); 


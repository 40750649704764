import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faMapMarkerAlt, faSortAmountDown } from '@fortawesome/free-solid-svg-icons'; 
import 'react-tabs/style/react-tabs.css';
import InfiniteScroll from 'react-infinite-scroller';
import Button from 'react-bootstrap/Button';
import {showLoading} from "../../../actions/action_loading";
import {showError} from "../../../actions/action_error_alert";
import { withTranslation } from 'react-i18next';
import GochiService from "../../../api/services/GochiService"; 
import Header from "../../layout/header/Header";
import {log} from '../../../utils'; 
import GochiUseInfor from "../../common/GochiUse/GochiUseInfor";
// import GochiUseStaff from "../../common/GochiUse/GochiUseStaff";

class ToMe extends Component { 
	constructor(){
	    super(); 
	    this.state = { 
            listData: [],
            count: 0,
            hasMoreItems: true,
            nextHref: 1,
            gochiUserType: ["YOURSELF", "ANYONE", "USER", "NONUSER"],
            status: ["PRE_REGISTER", "REGISTERED"],
            sort: "lastAddTimeDesc",
            page: 0,
            limit: 10,
            callApi: true,
            gochiInfo: {},
            showGochiUse: false,
            showGochiUseStaff: false
        }
        this.fetchData = this.fetchData.bind(this);
        this.getListGochis = this.getListGochis.bind(this);
        this.onSortList = this.onSortList.bind(this);
        this.onGochiClicked = this.onGochiClicked.bind(this);
        this.onUseGochi = this.onUseGochi.bind(this);
		this.onCloseGochiInfo = this.onCloseGochiInfo.bind(this);
		this.onCloseGochiStaff = this.onCloseGochiStaff.bind(this);
    }

    onCloseGochiStaff() {
		this.setState({
			showGochiUseStaff: false
		});
	}
    
    async fetchData(){ 
        try {       
            this.props.showLoading(true);
            var gochiUserId = this.props.userInfo.userId;
            var gochiUserType = this.state.gochiUserType;
            var sort = this.state.sort;
            var page = this.state.nextHref;
            var limit = this.state.limit
            if (this.state.callApi) {
                this.setState({
                    callApi: false,
                });
                let data = await this.getListGochis(
                    gochiUserType,
                    1,
                    gochiUserId,
                    sort,
                    page,
                    limit
                ); 
                if(data.count === 0) {
                    // this.props.showError(true, "There are no data", "");
                } else { 
                    var list = this.state.listData;  
                    this.setState({
                        count: data.count,
                    });
                    data.list.map(data => {
                        return list.push(data);
                    });  
                    
                    if(page*limit < data.count) {
                        this.setState({
                            nextHref: page + 1,
                            listData: list,
                            hasMoreItems: true,
                            callApi: true                            
                        });
                    } else {
                        this.setState({
                            hasMoreItems: false,
                            nextHref: 1
                        });
                    }
                }
            }
            this.props.showLoading(false); 
		} catch (e) {
            log(e);
            this.props.showLoading(false); 
            this.props.showError(true, this.props.t("pages.login.There is something wrong"), "");
        }	 
    }
    
    getListGochis = async (
        gochiUserType,
        couponCountGte,
        gochiUserId,
        sort,
        page,
        limit
		) => { 
			try {
			  	const { data } = await GochiService.getGochiToMe({
                    gochiUserType,
                    couponCountGte,
                    gochiUserId,
                    sort,
                    page,
                    limit
			  	});   
			  	return data;
			} catch (error) { 
                log('getGochiList error');
                this.props.showError(true, this.props.t("pages.login.There is something wrong"), "");
			}
    };

    async onSortList(e){
        this.setState({
            textButtonSort: e.target.innerText,
            nextHref: 1
        });
        try {
            this.props.showLoading(true);
            var gochiUserId = this.props.userInfo.userId;
            var gochiUserType = this.state.gochiUserType;
            var sort = e.target.value;
            var page = 1;
            var nextHref = 1;
            var limit = 10;
            var listData = [];
            let data = await this.getListGochis(
                gochiUserType,
                1,
                gochiUserId,
                sort,
                nextHref,
                limit
            );

            this.setState({
                count: data.count,
            });
            data.list.map(data => {
                return listData.push(data);
            });
            
            if(page*limit < data.count) {
                this.setState({
                    sort: sort,
                    nextHref: nextHref + 1,
                    listData: listData,
                    hasMoreItems: true,
                    callApi: true                            
                });
            } else {
                this.setState({
                    hasMoreItems: false,
                    nextHref: 1,
                    listData
                });
            }
            this.props.showLoading(false); 
		} catch (e) {
            log(e);
            this.props.showLoading(false); 
            this.props.showError(true, this.props.t("pages.login.There is something wrong"), "");
        }	 
    }

    onGochiClicked(gochiBillId, menuId, shopId, gochiUserId, isMenuCombo) { 
        let url = '';
        if (isMenuCombo) {
            let params = [];
            if (gochiBillId) {
                params.push(`gochiBillId=${gochiBillId}`);
            }
            if (gochiUserId) {
                params.push(`gochiUserId=${gochiUserId}`);
            }

            if (menuId) {
                params.push(`menuId=${menuId}`);
            }

            url = `/menuCombo/detail?${params.join("&")}`;
        } else {
            url = "/tome/detail?gochiBillId=" + gochiBillId;
        }
        window.location.href = url;
    }
    
	UNSAFE_componentWillMount(){ 
    }
    
    componentDidMount() {
        var userInfo = this.props.userInfo;
    	if (userInfo) { 
    		if (userInfo.isLogined && !userInfo.isGuest) {
                this.fetchData();
            }
        }
    }
    
    onUseGochi(gochiInfo) {
        if (gochiInfo.menuChildType === 3 || gochiInfo.menuChildType === 4 || gochiInfo.menuCombo === 1 || 
                gochiInfo.menuChildType === 1 || gochiInfo.menuChildType === 2 || gochiInfo.menuChildType === 5 || !gochiInfo.menuChildType) {
            let url = '';
            if (gochiInfo.menuCombo === 1) {
                let params = [];
                if (gochiInfo.gochiBillId) {
                    params.push(`gochiBillId=${gochiInfo.gochiBillId}`);
                }
                if (gochiInfo.gochiUserId) {
                    params.push(`gochiUserId=${gochiInfo.gochiUserId}`);
                }

                if (gochiInfo.menuId) {
                    params.push(`menuId=${gochiInfo.menuId}`);
                }

                url = `/menuCombo/detail?${params.join("&")}`;
            } else {
                url = `tome/detail?gochiBillId=${gochiInfo.gochiBillId}`;
            }
            window.location.href = url;
        } else {
            if (gochiInfo.gochiCount === 1) {
                this.setState({ 
                    showGochiUseStaff: true,
                    gochiInfo: gochiInfo
                });
                var url = "/gochiuse/staff";
                sessionStorage.setItem('quantityWillBeUsed', 1);
                sessionStorage.setItem('usingFlowGochiInfo', JSON.stringify(gochiInfo));
                window.location.href = url+`?gochiBillId=${gochiInfo.gochiBillId}`;
            } else {
                this.setState({ 
                    showGochiUse: true,
                    gochiInfo: gochiInfo
                });
            }
        }
    }

    onCloseGochiInfo() {
		this.setState({
			showGochiUse: false,
		})
	}

    render() {   
    	log(['props : ',this.props]); 
    	const { t } = this.props;   
    	var userInfo = this.props.userInfo;
        var link = "/login";
        let isLoggedIn = false; 
		if (userInfo) {
            if (userInfo.userId) {
                isLoggedIn = true;
            } 
    		if (userInfo.isLogined === false || userInfo.isGuest) { 
    			window.history.pushState(null, null, '/login');
	            return <Redirect to={link} />
	        } 
    	}else{
    		window.history.pushState(null, null, '/login');
    		return <Redirect to={link} />
        }

        const loader = <div className="loader" key="loader">Loading ...</div>;
        
        let listGochis;
        const clickHandler = this.onGochiClicked;
        const onUseGochi = this.onUseGochi;
        if(this.state.listData){  
	    	listGochis = this.state.listData.map(function(gochiBill, key){
                let includedTaxPrice = 0;
                let excludedTaxPrice = 0;

                if (gochiBill.menuId && gochiBill.menu) {
                    includedTaxPrice = gochiBill.menu.includedTaxPrice;
                    excludedTaxPrice = gochiBill.menu.excludedTaxPrice;
                } else {
                    includedTaxPrice = gochiBill.amount/gochiBill.couponCount + gochiBill.taxAmount/gochiBill.couponCount;
                    excludedTaxPrice = gochiBill.amount/gochiBill.couponCount;
                } 

                const menuImage = gochiBill.gochiBillImage.length > 0 ? 
                                    (gochiBill.gochiBillImage[0].image ? 
                                        gochiBill.gochiBillImage[0].image : 
                                        require("../../../assets/img/icons/gochi_noimg-ver4.jpg")) : 
                                    require("../../../assets/img/icons/gochi_noimg-ver4.jpg");

                const gochiInfo = {
                    gochiBillId: gochiBill.gochiBillId,
                    gochiBillNo: gochiBill.gochiBillNo,
                    gochiUserId: gochiBill.gochiUserId,
                    shopId: gochiBill.shopId,
                    shopName: gochiBill.shop && gochiBill.shop.shopName,
                    menuName: gochiBill.menu ? gochiBill.menu.menuName : t('pages.menu.Menu Price'),
                    menuId: gochiBill.menuId,
                    menuImage: menuImage,
                    menuChildType: gochiBill.menu && gochiBill.menu.menuChildType,
                    menuCombo: gochiBill.menu ? gochiBill.menu.menuCombo : 0,
                    quantityFree: gochiBill.menu ? gochiBill.menu.quantityFree : 0,
                    includedTaxPrice: includedTaxPrice,
                    displayAmount: gochiBill.menu && gochiBill.menu.displayAmount,
                    excludedTaxPrice: excludedTaxPrice,
                    gochiCount: gochiBill.gochiCount,
                    gochiExpiredDay: gochiBill.gochiExpiredDay,
                    // menuChildType: gochiBill.menu && gochiBill.menu.menuChildType,
                    childFoods: [],
                    shopStatus: gochiBill.shop && gochiBill.shop.status
                };

                const menu = gochiBill.menu;
                const displayLabelTicket = menu !== null && menu.menuCombo===1;
                const displayFreeTicketCount = menu !== null && menu.menuComboDetail!== null && 
                (menu.menuComboDetail.quantityFree > 0);

                const isMenuCombo = menu !== null && menu.menuCombo === 1; 

	        	return (
                <div className="list-tome pb-2" key={gochiBill.gochiBillId}>
                    <div className="media pt-4">
                        <div style={{position: "relative", width: "76px", marginRight: '10px'}} className="align-self-start cursor">
                            <div style={{position: "relative", overflow:"hidden"}}>
                                <img className="align-self-start cursor img-square" 
                                src={menuImage}
                                // onError={(e)=>{e.target.onerror = null; e.target.src="../../../assets/img/icons/gochi_noimg-ver4.jpg"}}
                                width="90" 
                                alt={gochiBill.menu ? gochiBill.menu.menuName : 'No image'} 
                                onClick={() => clickHandler(gochiBill.gochiBillId, gochiBill.menuId, gochiBill.shopId, gochiBill.gochiUserId, isMenuCombo)}
                                />
                                
                                {displayLabelTicket && (
                                    <>
                                    <div className="div-multi-used-menu-tome" />
                                    <span className="label-multi-used-menu-tome">
                                        {t('pages.invite.Coupon')}
                                    </span>
                                    </>
                                )}
                            </div>
                            {displayFreeTicketCount && (
                                <div className="pr-1 text-right">
                                    <div className="div-free-ticket-count-tome">
                                        <span className="label-free-ticket-count-tome">+{menu.menuComboDetail.quantityFree}{t('pages.invite.Free of charge')}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="media-body">
                            <h5 
                                className="mt-0 fs-12 font-weight-bold cursor" 
                                onClick={() => clickHandler(gochiBill.gochiBillId, gochiBill.menuId, gochiBill.shopId, gochiBill.gochiUserId, isMenuCombo)}
                            >
                                {gochiBill.menuId ? gochiBill.menu.menuName ? gochiBill.menu.menuName : '' : t('pages.menu.Menu Price')}
                                {/* {` `}
                                {gochiBill.menu && gochiBill.menu.menuCombo ? (
                                    <>
                                        {gochiBill.menu.menuComboDetail && 
                                            gochiBill.menu.menuComboDetail.priceCombo && 
                                                (gochiBill.menu.menuComboDetail.priceCombo).toLocaleString()}円×
                                        {gochiBill.menu.menuComboDetail && gochiBill.menu.menuComboDetail.quantity}枚
                                    </>
                                ) : ''} */}
                            </h5>
                            <p className="mt-2 fs-12 mb-0 one-line">
                                {gochiBill.shop ? gochiBill.shop.shopName : (gochiBill.groupInfo ? gochiBill.groupInfo.groupName : '')}
                            </p>
                            {gochiBill.shop && gochiBill.shop.nearestStation && (
                                <div className="fs-10 align-self-center">
                                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                                    &nbsp;{gochiBill.shop.nearestStation}
                                </div>
                            )}
                            {menu && menu.menuComboDetail && menu.menuComboDetail.quantityFreeDes && (
                                <div className="div-free-ticket-desc break-line break-word">
                                    {menu.menuComboDetail.quantityFreeDes}
                                </div>
                            )} 
                        </div>
                        
                        <div className="align-btn-use">
                            <Button 
                                variant="link"  
                                onClick={() => onUseGochi(gochiInfo)}
                                style={{height: 45}}
                            >
                                <img className="mr-0 rounded-circle fs-10" 
                                    src={require('../../../assets/img/icons/button_gochirareru.png')} 
                                    width="45" 
                                    height="45" 
                                    alt="Gochirareru button"
                                />
                            </Button>
                        </div> 
                    </div>
                    <div className="d-block align-self-center coupon-info">
                        <span className="border border-primary coupon-quantity fs-11 d-inline-block">
                            {gochiBill.gochiCount ? gochiBill.gochiCount : 0} {t('pages.tome.Gochi')}
                        </span>
                        <span className="fs-10 align-self-center pl-1 coupon-price">
                            {includedTaxPrice ? includedTaxPrice.toLocaleString() : 0}円
                            （{t('pages.invite.Tax')}{excludedTaxPrice ? excludedTaxPrice.toLocaleString() : 0}円）
                        </span>
                        <span className="fs-10 align-self-center pl-1 expiration font-weight-bold text-nowrap">
                            <FontAwesomeIcon icon={faExclamationCircle} />
                                {t('pages.menu.Gochi expired day', {gochiExpiredDay: gochiBill.gochiExpiredDay || 0})}
                        </span>
                    </div>
                </div>
                );
	      	});
        }
        
        return (
            <>
                <div className="content">
                    <Header 
                        title = {t('pages.tome.Address to yourself')}
                        isLoggedIn={isLoggedIn}
                        t={t}
                        usingFlow={true}
                    />

                    <div className="content-mypage">
                        <div className="label-page">
                            <i className="label-tome"></i>
                            <span className="label-symbol">/</span>
                            <span className="label-text">{t('pages.tome.Address to yourself')}</span>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-9 pl-0">
                                <div className="dropdown">
                                    <button className="btn btn-select font-weight-normal" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <FontAwesomeIcon icon={faSortAmountDown} className="mr-2" />
                                        {this.state.textButtonSort ? this.state.textButtonSort : t('pages.tome.Order by recent feasts')}
                                    </button>

                                    <div className="dropdown-menu menu-option" aria-labelledby="dropdownMenuLink">
                                        <button className="dropdown-item" value="lastAddTimeDesc" onClick={this.onSortList}>{t('pages.tome.Order by recent feasts')}</button>
                                        <button className="dropdown-item" value="expiredAsc" onClick={this.onSortList}>{t('pages.tome.Order by nearest expiration')}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3 align-self-center">
                                <div className="text-right fs-12">{t('pages.login.total', {count: this.state.count ? this.state.count: 0})}</div>
                            </div>
                        </div>
                        
                        {(this.state.listData.length > 0) ? 
                            <div className="pl-3 pr-3">
                                <div className="infinite-scroll-mypage mt-1">
                                    <InfiniteScroll
                                        pageStart={0} 
                                        loadMore={this.fetchData}
                                        hasMore={this.state.hasMoreItems}
                                        loader={loader}> 
                                            <div className="m-0">
                                                {listGochis}
                                            </div>
                                    </InfiniteScroll> 
                                </div>
                            </div>
                        :
                            <div className="tome-init-lst" key="noResult">
                                <div className="init-lst-box">
                                    <div className="text-center fs-14 font-weight-bold mt-3">{t('pages.invite.emptyListTome')}</div>
                                    <div className="text-center mt-3 mb-3">
                                        <img src={require('../../../assets/img/icons/img-search@2x.png')} width="60" alt="Login" />
                                    </div>

                                    {this.state.errorMessage &&
                                        <div className="fs-14 text-danger font-weight-bold text-center mt-2">
                                            {this.state.errorMessage}
                                        </div>
                                    }
                                </div>    
                            </div>
                        }
                    </div>
                </div>

                {this.state.showGochiUse ? (
                    <GochiUseInfor 
                    showing={this.state.showGochiUse}
                    onCloseGochiInfo={this.onCloseGochiInfo}
                    gochiInfo={this.state.gochiInfo}
                />
                ) : null}
                {/* {this.state.showGochiUseStaff ? (
                    <GochiUseStaff
                        showing={this.state.showGochiUseStaff} 
                        onCloseGochiStaff={this.onCloseGochiStaff}
                        gochiInfo={this.state.gochiInfo}
                        quantity = {1}
                        // childFoodId = {this.state.childFoodId}
                    />
                ) : null} */}
            </>
        ); 
    }
}
 

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
    showLoading: (showing) => dispatch(showLoading(showing)),
    showError: (showing, title, content) => dispatch(showError(showing, title, content)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ToMe));
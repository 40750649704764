import React, { Component } from "react";
import {Link, Redirect} from 'react-router-dom';
import {log} from '../../../utils'; 
import {connect} from 'react-redux';
import GochiInfo from './GochiInfo';
import GochiComment from './GochiComment';
import GochiMore from './GochiMore';
import {showLoading} from "../../../actions/action_loading";
import {showError} from "../../../actions/action_error_alert";
import { withTranslation } from 'react-i18next';
import GochiService from "../../../api/services/GochiService";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import InfiniteScroll from 'react-infinite-scroller';
import Header from "../../layout/header/Header";

class Mypage extends Component { 
	constructor(){
	    super(); 
	    this.state = { 
            listData: [],
            count: 0,
            hasMoreItems: true,
            nextHref: 1,
            gochiUserType: ["YOURSELF", "ANYONE", "USER", "NONUSER"],
            status: ["PRE_REGISTER", "REGISTERED"],
            sort: "lastAddTimeDesc",
            page: 0,
            limit: 10,
            callApi: true
        }
        window.history.pushState(null, null, '/mypage');
        this.fetchData = this.fetchData.bind(this);
        this.getListGochis = this.getListGochis.bind(this);
    }
    
    async fetchData(){ 
        try {       
            this.props.showLoading(true);
            var gochiRegisterId = this.props.userInfo.userId;
            var gochiUserType = this.state.gochiUserType;
            var status = this.state.status;
            var sort = this.state.sort;
            var page = this.state.nextHref;
            var limit = this.state.limit
            if (this.state.callApi) {
                this.setState({
                    callApi: false,
                });
                let data = await this.getListGochis(
                    null,
                    null,
                    null,
                    gochiUserType,
                    null,
                    gochiRegisterId,
                    null,
                    null,
                    status,
                    page,
                    limit,
                    sort
                ); 
                if(data.count === 0) {
                    // this.props.showError(true, "There are no data", "");
                } else { 
                    var list = this.state.listData;  
                    this.setState({
                        count: data.count,
                    });
                    data.list.map(data => {
                        return list.push(data);
                    });  
                    
                    if(page*limit < data.count) {
                        this.setState({
                            nextHref: page + 1,
                            listData: list,
                            hasMoreItems: true,
                            callApi: true                            
                        });
                    } else {
                        this.setState({
                            hasMoreItems: false,
                            nextHref: 1
                        });
                    }
                }
            }
            this.props.showLoading(false); 
		} catch (e) {
            log(e);
            this.props.showLoading(false); 
            this.props.showError(true, "There is something wrong", "");
        }	 
    }
    
    getListGochis = async (
		shopId,
        menuId,
        gochiType,
        gochiUserType,
        couponCountGte,
        gochiRegisterId,
        gochiUserId,
        userInvitedId,
        status,
        page,
        limit,
        sort
		) => { 
			try {
			  	const { data } = await GochiService.getGochiList({
				    shopId,
                    menuId,
                    gochiType,
                    gochiUserType,
                    couponCountGte,
                    gochiRegisterId,
                    gochiUserId,
                    userInvitedId,
                    status,
                    page,
                    limit,
                    sort
			  	});   

			  	return data;
			} catch (error) { 
                log('getGochiList error');
                this.props.showError(true, this.props.t("pages.login.There is something wrong"), "");
			}
	};

	UNSAFE_componentWillMount(){ 
    }
    
    componentDidMount() {
        var userInfo = this.props.userInfo;
    	if (userInfo) { 
    		if (userInfo.isLogined && !userInfo.isGuest) {
                this.fetchData();
            }
        }
	}

    render() {   
    	log(['props : ',this.props]); 
    	const { t } = this.props;   
    	var userInfo = this.props.userInfo;
        var link = "/login";
        let isLoggedIn = false; 
		if (userInfo) {
            if (userInfo.userId) {
                isLoggedIn = true;
            } 
    		if (userInfo.isLogined === false || userInfo.isGuest) { 
    			window.history.pushState(null, null, '/login');
	            return <Redirect to={link} />
	        } 
    	}else{
    		window.history.pushState(null, null, '/login');
    		return <Redirect to={link} />
        }

        const loader = <div className="loader" key="loader">Loading ...</div>;
        
        let listGochis; 
        if(this.state.listData){  
	    	listGochis = this.state.listData.map(function(gochiBill, key){

	        	return <li key={ key }>
                	<label className="gochi-row w-100 mb-0">
                        <GochiInfo gochiBill={gochiBill} />
                        <GochiComment gochiBill={gochiBill} />
                        <GochiMore gochiBill={gochiBill} />
    				</label> 
                </li>;
	      	});
        }
        
        return (
        	<div className="content">
        		<Header title={t('pages.mypage.header title')}
                    isLoggedIn={isLoggedIn}
                    t={this.props.t}>
                    <div className="button-sakimeshi-mypage">
                        <Link to={{ pathname:`https://www.sakimeshi.com/`}} target="_blank" className="btn btn-bg-corner w-240 m-auto mt-3 fs-14">
                            {t('pages.mypage.link sakimeshi')}
                        </Link>
                    </div>
				</Header>

			    <div className="content-mypage">
                <Tabs>
                    <div className="tab-block-header">
                    <TabList>
                        <Tab>{t('pages.mypage.title list gochi')} ({this.state.count})</Tab>
                    </TabList>
                    </div>

                    <TabPanel>
                        <div className="select-block">
                            {(this.state.listData.length > 0) ?
                                <div className="infinite-scroll-mypage">
                                    <InfiniteScroll
                                        pageStart={0} 
                                        loadMore={this.fetchData}
                                        hasMore={this.state.hasMoreItems}
                                        loader={loader}> 
                                            <div  className="select-block-body m-0">
                                                <div className="lst-friends h-100">  
                                                    <ul>
                                                        {listGochis}
                                                    </ul>
                                                </div> 
                                            </div>
                                    </InfiniteScroll> 
                                </div>
                            : (
                                <div className="no-gochi-list space-pre-line">
                                    {t('pages.mypage.no gochi register')}<br /><br />
                                    <div className="button-sakimeshi-mypage">
                                        <Link to={{ pathname:`https://www.sakimeshi.com/`}} target="_blank" className="btn btn-bg-corner w-240 m-auto mt-3 fs-14">
                                            {t('pages.mypage.link sakimeshi')}
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </div>
                    </TabPanel>
                </Tabs>
			    </div>
			</div>
        ); 
    }
}
 

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
    showLoading: (showing) => dispatch(showLoading(showing)),
    showError: (showing, title, content) => dispatch(showError(showing, title, content)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Mypage));
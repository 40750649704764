import React, { useState } from "react";
import QrReader from 'react-qr-reader';
import queryString from 'query-string';
import {addParamUrl} from '../../../../utils';
import { GiftCodeConfirmModal } from "../../common/GiftCodeConfirmModal";
import GiftService from "../../../../api/services/GiftService";

export function QrCodeToGochi(props) {
  const paramsUrl = queryString.parse(window.location.search);
  const isSite = paramsUrl.isSite;
  const isSiteParam = isSite ? {name: 'isSite', value: isSite} : {};

  const [showGiftCodeConfirmModal, setShowGiftCodeConfirmModal] = useState(false);
  const [messageQrCode, setMessageQrCode] = useState('');
  const [hasSubmit, setHasSubmit] = useState(false);
  const [giftCode, setGiftCode] = useState('');
  const [amount, setAmount] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [scanningQrCode, setScanningQrCode] = useState(false);
  
  async function handleScanQRCode (data){
      if (scanningQrCode) {
        return;
      }

      if (data) {
          setScanningQrCode(true);
          let dataCheck = await checkGiftCodeData(data, props.menuId, props.gochiBillId);
          if (dataCheck.status === 200){
            setMessageQrCode(`QRコードで${dataCheck.data?.amount?.toLocaleString()}円をチャージしてよろしいでしょうか？`);
            setHasSubmit(true);
            setIsError(false);
            setIsSuccess(false);
            setGiftCode(dataCheck.data.giftCode);
            setAmount(dataCheck.data.amount);
          } else {
            setMessageQrCode('QRコードが無効です。もう一度ご確認ください。');
            setHasSubmit(true);
            setIsError(true);
            setGiftCode('');
          }
          setShowGiftCodeConfirmModal(true);
      }
  }

  function handleErrorQRCode(err){
      console.error(err);
  }

  async function checkGiftCodeData(giftCode, menuId, gochiBillId) {
    try {
      const data = await GiftService.checkGiftCode(
        giftCode,
        menuId,
        gochiBillId
      );
      return data;
    } catch (error) {
      console.log(error);
      if (error.status === 400) {
        setMessageQrCode('QRコードが無効です。もう一度ご確認ください。');
        setHasSubmit(true);
        setIsError(true);
      }
    }
  }

  async function handleSubmitCode() { 
		let useGiftCode = await sendGiftCodeData();
		if (useGiftCode.status === 200) {
			setMessageQrCode(`QRコードで${amount.toLocaleString()}円をチャージ完了しました！`);
      setHasSubmit(false);
      setIsError(false);
      setIsSuccess(true);
		} else {
      setMessageQrCode('QRコードでのチャージに失敗しました。もう一度試して下さい。');
      setHasSubmit(true);
      setIsError(true);
    }
	}

	async function sendGiftCodeData(){
		try {
		const data = await GiftService.useGiftCode(
			giftCode,
			props.menuId,
			props.gochiBillId,
			props.userId
		);
		return data;
		} catch (error) {
			console.log(error);
			if (error.status === 400) {
				setMessageQrCode('fail');
			}
		}
	}

  function backToMyPage() {
    if (isSuccess) {
      window.location.href = addParamUrl("/mypage/mygochi_charge", [isSiteParam]);
    }
    setShowGiftCodeConfirmModal(false);
    setScanningQrCode(false);
  }

  function closeGiftCodeConfirmModal() {
    if(isSuccess) {
      window.location.href = addParamUrl("/mypage/mygochi_charge", [isSiteParam]);
    }
    setShowGiftCodeConfirmModal(false);
    setScanningQrCode(false);
  }


  return (
    <>
      <div className={`profile-qrcode ${props.loadingQrCode ? 'loading': ''}`} id="scrollToElementId">
        <div className="profile-qrcode-header">
          <div>
            <div className="required-dot mb-3 mt-37px input-title">
              <div className="dot-red"></div>
              <div className="dot-text-label fs-28">QRコードを読み取る</div>
            </div>
            <p>
              <QrReader
                  //ref="qrReader1"
                  delay={300}
                  onError={handleErrorQRCode}
                  onScan={handleScanQRCode}
                  style={{ width: '100%' }}
                  // legacyMode={this.state.legacyMode}
              />
            </p>
          </div>
          <a href={addParamUrl("/mypage/mygochi_charge", [isSiteParam])} className="align-center back-step-btn">
            前の画面に戻る
          </a>
        </div>
      </div>
      <GiftCodeConfirmModal 
        isShow={showGiftCodeConfirmModal} 
        onHide={closeGiftCodeConfirmModal}
        hasSubmit={hasSubmit}
        handleSubmitCode={handleSubmitCode}
        setHasSubmit={() => setHasSubmit}
        isSuccess={isSuccess}
        isError={isError}
        backToMyPage={backToMyPage}
        messageQrCode={messageQrCode} />
    </>
  );
}

import React, { useState } from "react";

export function Comment({ comment, key }) {
  const [collapse, setCollapse] = useState(true);
  const display =
    collapse && comment?.length >= 120 ? comment.slice(0, 119) + "…" : comment;

  return (
    <div key={key} className="card card-comment card-lg">
      <div className="comment-container">
        <p className="comment pre-line">{display}</p>
        {collapse && comment?.length >= 120 && (
          <div className="text-sm text-right">
            <a href className="link text-underline" onClick={() => setCollapse(false)}>
              もっと見る
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

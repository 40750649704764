import React from "react";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import GiftService from "../../../../api/services/GiftService";
// import { setMetaDescriptionAndKeywords } from "../../../../utils";
import { CustomPagination } from "../../common/Pagination";

export function Follow(props) {

  const loginUserId = props.loginUserId;

  function onUnfollowUser(targetFollowId) {
    GiftService.unfollowUser(targetFollowId).then((response) => {
      if (response.status === 200) {
        props.setFollowCount((old) => old - 1);
        props.setTempFollowList((old) => {
          var newState = { ...old };
          newState[targetFollowId] = false;
          return newState;
        });
      }
    });
  }

  function onFollowUser(targetFollowId) {
    GiftService.followUser(targetFollowId).then((response) => {
      if (response.status === 200) {
        props.setFollowCount((old) => old + 1);
        props.setTempFollowList((old) => {
          var newState = { ...old };
          newState[targetFollowId] = true;
          return newState;
        });
      }
    });
  }

  return (
    <>
      <MetaTags>
        <title>フォロー・フォロワー｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content="フォロー・フォロワーページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。 " />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content="フォロー・フォロワー｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta name="twitter:description" content="フォロー・フォロワーページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。 " />
        <meta name="twitter:image" content="/assets/img/common/ogp.png" />
        <meta property="og:site_name" content="フォロー・フォロワー｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:title" content="フォロー・フォロワー｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content="/assets/img/common/ogp.png" />
        <meta property="og:description" content="フォロー・フォロワーページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。 " />
      </MetaTags>

      <div className="profile-follow-area" id="scrollToElementId">
        <div className="profile-search">
          <div className="fieldset fieldset-column">
            <div className="fieldset-header">
              <div className="title">ユーザー検索</div>
              <div className="subtitle">全ユーザーから検索されます</div>
            </div>
            <div className="fieldset-body">
              <div className="field field-search field-lg">
                <input
                  type="search "
                  placeholder="ユーザー名を入力"
                  onChange={(e) => {
                    props.handleInputUsername(e.target.value);
                  }}
                />
                <button
                  className="btn"
                  onClick={() =>
                    props.onSearchListFollow(props.isFollowTabActive)
                  }
                >
                  <svg className="icon">
                    <title>検索する</title>
                    <use xlinkHref="/assets/img/common/icon.svg#search" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`profile-follow ${props.loadingFollow ? "loading" : ""}`}
        >
          <div className="nav">
            <Link
              to="#"
              id="tabNav01"
              className={`nav-link nav-link-01 ${
                props.isFollowTabActive ? "active" : ""
              }`}
              onClick={(e) => {
                props.handleChangeListFollowTab(true);
              }}
            >
              フォロー
            </Link>
            <a
              id="tabNav02"
              className={`nav-link nav-link-02 ${
                !props.isFollowTabActive ? "active" : ""
              }`}
              href
              onClick={() => {
                props.handleChangeListFollowTab(false);
              }}
            >
              フォロワー
            </a>
          </div>
          <div className="tab-content">
            <div
              id="tabPane01"
              className={
                props.isFollowTabActive
                  ? "tab-pane tab-pane-01 fade is-show active"
                  : "tab-pane tab-pane-01 fade"
              }
            >
              <dl className="follow-list">
                {props.searchResults.length > 0 &&
                  props.searchResults.map((item, key) => {
                    let index = `follow-${key}`;
                    return (
                      <div className="follow-list-item" key={index}>
                        <Link
                          to={`/mypage/user/${item.userId}`}
                          className="user-link"
                        >
                          {item.userImage[0] ? (
                            <dt className="avatar">
                              <img
                                src={item.userImage[0].image}
                                alt="avatar"
                                className="img"
                              />
                            </dt>
                          ) : (
                            <dt className="avatar is-empty"></dt>
                          )}

                          <dd className="user">
                            <div className="name">
                              {item.displayName ? item.displayName : ""}
                            </div>
                            <div className="id">@{item.accountId}</div>
                          </dd>
                        </Link>

                        {item.userId !== loginUserId && (
                          <dd className="btn-group">
                            {/* <button onClick={() => onUnfollowUser(item.userId)} className="btn btn-sm btn-rect btn-gray text-gray">
                            フォローされています
                          </button> */}
                            {(
                              props.tempFollowList[item.userId] !== undefined
                                ? props.tempFollowList[item.userId]
                                : item.follow
                            ) ? (
                              <button
                                onClick={() => onUnfollowUser(item.userId)}
                                // className="btn btn-sm btn-rect btn-gray text-gray"
                                className="btn btn-sm btn-rect"
                              >
                                フォロー中
                              </button>
                            ) : (
                              <button
                                onClick={() => onFollowUser(item.userId)}
                                // className="btn btn-sm btn-rect btn-gray text-gray"
                                className="btn btn-sm btn-rect btn-outline"
                              >
                                フォローする
                              </button>
                            )}
                          </dd>
                        )}
                      </div>
                    );
                  })}
              </dl>
              {props.searchResults.length > 0 && (
                <CustomPagination
                  count={Math.ceil(props.totalResult / props.limit)}
                  page={props.page}
                  onChange={props.handleChangePage}
                />
              )}
            </div>
            <div
              id="tabPane02"
              className={
                props.isFollowTabActive
                  ? "tab-pane tab-pane-02 fade"
                  : "tab-pane tab-pane-02 fade active is-show"
              }
            >
              <dl className="follow-list">
                {props.searchResults.length > 0 &&
                  props.searchResults.map((item, key) => {
                    let index = `follower-${key}`;
                    return (
                      <div className="follow-list-item" key={index}>
                        <Link
                          to={`/mypage/user/${item.userId}`}
                          className="user-link"
                        >
                          {item.userImage[0] ? (
                            <dt className="avatar">
                              <img
                                src={item.userImage[0].image}
                                alt="avatar"
                                className="img"
                              />
                            </dt>
                          ) : (
                            <dt className="avatar is-empty"></dt>
                          )}
                          <dd className="user">
                            <div className="name">
                              {item.displayName ? item.displayName : ""}
                            </div>
                            <div className="id">@{item.accountId}</div>
                          </dd>
                        </Link>
                        {props.isFollowTabActive === false &&
                          item.userId !== loginUserId && (
                            <dd className="btn-group">
                              {(
                                props.tempFollowList[item.userId] !== undefined
                                  ? props.tempFollowList[item.userId]
                                  : item.follow
                              ) ? (
                                <button
                                  onClick={() => onUnfollowUser(item.userId)}
                                  className="btn btn-sm btn-rect"
                                >
                                  フォロー中
                                </button>
                              ) : (
                                <button
                                  onClick={() => onFollowUser(item.userId)}
                                  className="btn btn-sm btn-rect btn-outline"
                                >
                                  フォローする
                                </button>
                              )}
                            </dd>
                          )}
                      </div>
                    );
                  })}
              </dl>
              {props.searchResults.length > 0 && (
                <CustomPagination
                  count={Math.ceil(props.totalResult / props.limit)}
                  page={props.page}
                  onChange={props.handleChangePage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Follow;

import React, { Component } from 'react';
import { connect } from "react-redux";

import { withTranslation } from 'react-i18next';
import {showError} from "../../actions/action_error_alert";
import {Modal, Button} from 'react-bootstrap'


class PopupErrorGochiCharge extends Component { 
  render() { 
    return (
      <Modal show={this.props.showing} onHide={this.props.hideError} className="modal-error"> 
        <div className="modal-body"> 
          <Button variant="secondary" className="close" onClick={this.props.hideError}>&times;</Button>

          <div className="input-infor">
            <div >
              {this.props.title !== '' &&
                <h5 className="space-pre-line">{this.props.title}</h5>
              }

              {this.props.content &&
                <p className="space-pre-line">{this.props.content}</p>
              }
              {this.props.gotoUrl && 
                <a href={this.props.gotoUrl} className="btn btn-bg-corner w-240 m-auto mt-3" onClick={this.props.hideError}>
                  {this.props.buttonName}
                </a>
              }
            </div>
          </div>
        </div> 
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PopupErrorGochiCharge));

export const showError = (showing = false, title = '', content = '', buttonName = '', gotoUrl = '') => {
  return {
    type : 'ERROR_SHOW',
    showing,
    title,
    content,
    buttonName,
    gotoUrl
  }
};

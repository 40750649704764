import React from "react";

function RadioInput({
  children,
  className,
  inputName,
  inputValue,
  checked,
  disabled,
  readOnly,
  onChange
}) {
  let wrapperClassName = 'radio-input';
  if (className) {
    wrapperClassName += ' ' + className
  }
  return (
    <label className={wrapperClassName}>
      <input
        type="radio"
        checked={checked}
        disabled={disabled}
        readOnly={readOnly}
        name={inputName}
        value={inputValue}
        onChange={onChange} />
      <span className="radio-button"></span>
      <span className="radio-label">{children}</span>
    </label>
  );
}

export default RadioInput;

import React, { Component } from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import queryString from 'query-string';
import HamburgerMenu from "./HamburgerMenu";

class Header extends Component {
  // constructor(props) {
  //     super(props);
  //     // this.goBack = this.getGochi.bind(this);
  // }
  render() {
    const isShowMenu = queryString.parse(window.location.search).accessToken;
    const isEducateSystem = this.props.siteType === 'fukuoka' || this.props.siteType === 'suita' || this.props.siteType === 'nishiwaki' || this.props.siteType === 'hamamatsu';

    return (
      <div className="header">
        <div
          className={
            this.props.usingFlow ? "header-row below-line" : `header-row ${this.props.siteType && isEducateSystem ? "fukuoka-border-bottom" : ""}`
          }
        > 
          {this.props.siteType && isEducateSystem ? (
            <div className="d-flex w-100">
              {this.props.siteType === 'fukuoka' && (
                <div className="d-flex justify-content-center align-items-center">
                  <i className="icon-service-fukuoka" />
                </div>
              )}
              {this.props.siteType === 'nishiwaki' && (
                <div className="d-flex justify-content-center align-items-center">
                  <i className="icon-service-nishiwaki" />
                </div>
              )}
              {this.props.siteType === 'hamamatsu' && (
                <div className="d-flex justify-content-center align-items-center">
                  <i className="icon-service-hamamatsu" />
                </div>
              )}
              {this.props.hasTitle && (
                <div className="header-fukuoka">{this.props.title}</div>
              )}
            </div>
          ) : (
            <div className="d-flex w-100">
              <div className="d-flex justify-content-center align-items-center">
                {/* {this.props.hasBack && (
                  <button
                    type="button"
                    className="btn"
                    onClick={() => {
                      if (this.props.backAction) {
                        this.props.backAction();
                      } else window.history.back();
                    }}
                  >
                    <i className="arrow left width-15"></i>
                  </button>
                )} */}
                <i className="icon-service" />
              </div>
              <div className="header-ttl pr-5 fs-12 ml-2 clr-000000">{this.props.title}</div>
            </div>
          )}
          {!isShowMenu &&
            <div style={{paddingRight: 15}} className="position-absolute right-0">
              <HamburgerMenu isLoggedIn={this.props.isLoggedIn} t={this.props.t} />
            </div>
          }
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default Header;

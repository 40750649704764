import React, { useState } from "react";

export function PolicyGochiKid(props) {
  const [policy1, setPolicy1] = useState(false);
  const [policy2, setPolicy2] = useState(false);
  const [policy3, setPolicy3] = useState(false);
  const [policy4, setPolicy4] = useState(false);
  const [policy5, setPolicy5] = useState(false);

  const handleRedirectGochiKidUse = () => {
    window.open("https://kids-future-passport.org/contact/user");
  }

  return (
    <div
      id={5342}
      className={`modal modal-profile modal-profile-01 modal-01 modal-lg history-modal policy-gochi-kid-modal policy-gochi-kid-modal-1 ${
        props.showPolicyGochiKidModal ? "is-show" : ""
      }`}
      tabIndex={-1}
    >
      <div className="modal-header-history">
        <button
          className="modal-btn modal-btn-close"
          id="modalClose"
          onClick={props.handleClosePolicyGochiKidModal}
          type="button"
        >
          <svg className="icon">
            <title>閉じる</title>
            <use xlinkHref="/assets/img/common/icon.svg#times" />
          </svg>
        </button>
      </div>
      <div className={`modal-body`}>
        <div className="policy-text">
          カード型会員証の発行申請にあたり、以下をご確認いただき、同意いただける場合はチェックをお願いします。
        </div>
        <div className="checkbox-container">
          <div className="field field-checkbox policy-text-box">
            <label>
              <input
                className="before-icon"
                type="checkbox"
                checked={policy1}
                onChange={(e) => {
                  setPolicy1(e.target.checked);
                }}
              />
              <i className="icon" />
            </label>
            <div className="policy-text-description">
              <span className="text-red">お子様の審査が通過した後</span>
              <span>にご申請ください。</span>
            </div>
          </div>
          <div className="field field-checkbox policy-text-box">
            <label>
              <input
                className="before-icon"
                type="checkbox"
                checked={policy2}
                onChange={(e) => {
                  setPolicy2(e.target.checked);
                }}
              />
              <i className="icon" />
            </label>
            <div className="policy-text-description">
              <span>『こどもごちめし』は保護者様の</span>
              <span className="text-red">スマートフォン等でのご利用</span>
              <span>を推奨しております。</span>
            </div>
          </div>
          <div className="field field-checkbox policy-text-box">
            <label>
              <input
                className="before-icon"
                type="checkbox"
                checked={policy3}
                onChange={(e) => {
                  setPolicy3(e.target.checked);
                }}
              />
              <i className="icon" />
            </label>
            <div className="policy-text-description">
              <span>カード型会員証は、</span>
              <span className="text-red">対応可としている一部の店舗様のみ</span>
              <span>でのご利用となります。ご利用店舗が</span>
              <span className="text-red">「カード型会員証対応可」</span>
              <span>になっているか、</span>
              <span className="text-red">事前にご確認</span>
              <span>ください。</span>
              <span className="text-red">※「築地銀だこ」</span>
              <span>ではご利用できません</span>
            </div>
          </div>
          <div className="field field-checkbox policy-text-box">
            <label>
              <input
                className="before-icon"
                type="checkbox"
                checked={policy4}
                onChange={(e) => {
                  setPolicy4(e.target.checked);
                }}
              />
              <i className="icon" />
            </label>
            <div className="policy-text-description">
              <span>申請後、カード型会員証を画像データで送付いたします。ご自身で</span>
              <span className="text-red">印刷したものを店舗へご提示</span>
              <span>ください。</span>
            </div>
          </div>
          <div className="field field-checkbox policy-text-box">
            <label>
              <input
                className="before-icon"
                type="checkbox"
                checked={policy5}
                onChange={(e) => {
                  setPolicy5(e.target.checked);
                }}
              />
              <i className="icon" />
            </label>
            <div className="policy-text-description">
              <span>カード型会員証の不正利用を避けるため、</span>
              <span className="text-red">紛失などされないようご注意</span>
              <span>ください。不正利用された可能性がある場合は、ただちに事務局へご連絡ください。</span>
            </div>
          </div>
        </div>
        <div className="note-policy-modal">
          ※下記「申請する」ボタンをクリックすると、フォームが開きます。「お問い合わせカテゴリ」で「会員証発行依頼」を選択いただき、必要事項を記載してご送信ください。
        </div>
        <div className="btn-container">
          <button
            className="btn btn-rect btn-accept-policy"
            disabled={!(policy1 && policy2 && policy3 && policy4 && policy5)}
            onClick={() => handleRedirectGochiKidUse()}
          >
            申請する
          </button>
        </div>
      </div>
    </div>
  );
}

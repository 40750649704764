import {HTTP_API, makeRequest} from "../HttpClient";
import * as constants from "../../constants";

const API_BASE_URL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : '';

export default class PaymentService {
  static async getPaymentList(userId = null){
    var params = {
      paymentType: constants.PAYMENT_TYPE_CREDIT_CARD
    };
    var url = `${API_BASE_URL}/v3/users/${userId}/cards`;
    return makeRequest(HTTP_API.GET, url, params, true);
  }

  static async getDefaultCard(userId = null){
    var params = {
      paymentType: constants.PAYMENT_TYPE_CREDIT_CARD,
      defaultFlag: true
    };
    var url = `${API_BASE_URL}/v3/users/${userId}/cards`;
    return makeRequest(HTTP_API.GET, url, params, true);
  }

  static async registerCardtoken(userId, source){
    var params = {
      paymentType: constants.PAYMENT_TYPE_CREDIT_CARD,
      source: source
    };
    var url = `${API_BASE_URL}/v3/users/${userId}/cards`;
    return makeRequest(HTTP_API.POST, url, params);
  }

  static async setDefaultCard(userId, source){
    var params = {
      paymentType: constants.PAYMENT_TYPE_CREDIT_CARD,
      source: source
    };
    var url = `${API_BASE_URL}/v3/users/${userId}/cards/defaultFlag`;
    return makeRequest(HTTP_API.PUT, url, params);
  }

  static async deleteCard(userId, source){
    var params = {
      paymentType: constants.PAYMENT_TYPE_CREDIT_CARD
    };
    var url = `${API_BASE_URL}/v3/users/${userId}/cards/${source}`;
    return makeRequest(HTTP_API.DELETE, url, params);
  }
};

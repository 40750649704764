// import {
//   faEnvelope,
//   faEye,
//   faEyeSlash,
// } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Button } from "react-bootstrap";
import MetaTags from 'react-meta-tags';
import React, { useEffect, useState } from "react";
import { FormGroup } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { showError } from "../../../actions/action_error_alert";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { showLoading } from "../../../actions/action_loading";
import queryString from "query-string";
import {addParamUrl} from '../../../utils';

function ForgotPassword(props) {
  const { t } = props;
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [formError, setFormError] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const history = useHistory();

  const isValidEmail = (email) => {
    var pattern = new RegExp(
      /^(("[\w+-\s]+")|([\w+-]+(?:\.[\w+-]+)*)|("[\w+-\s]+")([\w+-]+(?:\.[\w+-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    return pattern.test(email);
  }

  const onSendCode = async (email) => {
    if(isValidEmail(email)) {
      props.showLoading(true);
      try {
        await Auth.forgotPassword(email);
        await props.showError(
          true,
          t("pages.forgotPassword.message_verification_code_sent")
        );
      } catch {
        props.showError(
          true,
          t("pages.forgotPassword.message_authentication_code_could_not_be_sent")
        );
      } finally {
        props.showLoading(false);
      }
    } else {
      props.showError(
        true,
        t("pages.register.message please enter email")
      );
    }
  };

  const validEmail = (formError) => {
    const value = email.trim();
    if (value.length <= 0) {
      formError.email = t("pages.forgotPassword.message_please_enter_email");
    } else if (value.length > 200) {
      formError.email = t("pages.forgotPassword.message_email_within_200");
    } else if (!isValidEmail(value)) {
      formError.email = t("pages.register.message please enter email");
    }
    setEmail(value);
  };

  const validCode = (formError) => {
    const value = code.trim();
    if (value.length <= 0) {
      formError.code = t("pages.forgotPassword.message_please_enter_code");
    }
    setCode(value);
  };

  const validPassword = (formError) => {
    const value = password.trim();
    if (value.length <= 0) {
      formError.password = t(
        "pages.forgotPassword.message_please_sure_your_password"
      );
    } else if (value.length < 8 || value.length > 99) {
      formError.password = t("pages.forgotPassword.message_password_8_99");
    } else if (
      !/[A-Z]/.test(value) ||
      !/[a-z]/.test(value) ||
      !/[0-9]/.test(value)
    ) {
      formError.password = t("pages.forgotPassword.message_suggest_password");
    }
    setPassword(value);
  };

  const validPasswordConfirm = (formError) => {
    const value = passwordConfirm.trim();
    if (value.length <= 0) {
      formError.passwordConfirm = t(
        "pages.forgotPassword.message_please_sure_your_password"
      );
    } else if (value.length < 8 || value.length > 99) {
      formError.passwordConfirm = t(
        "pages.forgotPassword.message_password_8_99"
      );
    } else if (
      !/[A-Z]/.test(value) ||
      !/[a-z]/.test(value) ||
      !/[0-9]/.test(value)
    ) {
      formError.passwordConfirm = t(
        "pages.forgotPassword.message_suggest_password"
      );
    }
    setPasswordConfirm(value);
  };

  const valid = () => {
    const errors = {};
    validEmail(errors);
    validCode(errors);
    validPassword(errors);
    validPasswordConfirm(errors);
    if (password !== passwordConfirm) {
      errors.passwordConfirm = t(
        "pages.forgotPassword.message_password_not_match"
      );
    }
    setFormError(errors);
    return errors;
  };

  const onSubmit = async () => {
    props.showLoading(true);

    let url = window.location.search;
    let params = queryString.parse(url);
    var isSiteParam = params.isSite ? {name: 'isSite', value: params.isSite} : {};

    const errors = valid();
    if (Object.keys(errors).length) {
      setIsValid(false);
      props.showLoading(false);
      return;
    }
    setIsValid(true);

    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      history.push(addParamUrl("/password_reset/complete", [isSiteParam]));
    } catch (error) {
      if (error.code === "CodeMismatchException") {
        await props.showError(
          true,
          t("pages.forgotPassword.message_authen_code_not_match")
        );
      } else if (error.code === "ExpiredCodeException") {
        await props.showError(
          true,
          t("pages.forgotPassword.message_authen_code_expired")
        );
      } else if (error.code === "TooManyRequestsException") {
        await props.showError(
          true,
          t("pages.forgotPassword.message_access_concentrated")
        );
      } else {
        await props.showError(
          true,
          t("pages.forgotPassword.message_error_has_occured_try_again")
        );
      }
    } finally {
      props.showLoading(false);
    }
  };

  useEffect(() => {
    document.body.className = "auth signup";
  }, []);

  let url = window.location.search;
  let params = queryString.parse(url);
  var menuId = params.id;
  var src = params.src;
  var srcParam = src ? "&src=" + src : "";
  var isSite = params.isSite;
  var isSiteParam = isSite ? {name: 'isSite', value: isSite} : {};

  return (
    <div>
      <MetaTags>
        <title>パスワードを忘れた方｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content="パスワードを忘れた方ページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content="パスワードを忘れた方｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta name="twitter:description" content="パスワードを忘れた方ページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:image" content={window.location.origin + "/assets/img/common/ogp.png"}  />
        <meta name="twitter:site" content="@gochi_meshi" />
        <meta name="twitter:creator" content="@gochi_meshi" />
        <meta name="twitter:image:width" content="300" />
        <meta name="twitter:image:height" content="300" />
        <meta property="og:site_name" content="パスワードを忘れた方｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:title" content="パスワードを忘れた方｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={window.location.origin + "/assets/img/common/ogp.png"} />
        <meta property="og:description" content="パスワードを忘れた方ページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
      </MetaTags>
      <header className="global-login-header-new forgot-password-header">
        <div className="logo-nav">
          <h1 className="logo">
            <a href="/">
              <div className="new-logo-login">
                <source
                  media="(max-width: 180px)"
                  srcSet="/assets/img/common/logo_gochi_header_mypage.svg"
                />
                <img
                  src="/assets/img/common/logo_gochi_header_mypage.svg "
                  alt="気持ちを「ごちそう」にして贈れるアプリ ごちめし"
                  loading="lazy"
                />
              </div>
              <span className="title-page">パスワードを忘れた方</span>
            </a>
          </h1>
        </div>
      </header>

      <main>
        <div className="container">
          <div className="heading-lg">
            <div className="title">パスワードを忘れた方</div>
          </div>
          <div className="box box-sm">
            <p className="text-gray">
              ご登録のメールアドレスに認証コードをお送りします。
              <br />
              認証コードと新しいパスワードを入力してパスワードの再設定を行ってください。
            </p>
          </div>

          <div className="signup-input">
            <div className="signup-input-group">
              <div className="signup-input-header">
                <div className="heading-md">
                  <div className="title">パスワード再設定</div>
                </div>
              </div>
              <div className="signup-input-body">
                <div className="fieldset">
                  <div className="fieldset-header">
                    <div className="title">メールアドレス</div>
                  </div>
                  <div className="fieldset-body">
                    <div className="field-group field-group-column">
                      <div className="field field-lg">
                        <input
                          type="email"
                          placeholder="例) mail@gochi.online"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    {!isValid && formError.email && (
                      <div className="text-danger fs-14 mt-2 fs-12">
                        {formError.email}
                      </div>
                    )}
                  </div>
                </div>
                <div className="btn-group signup-submit-sms-btn-group">
                  <button
                    className="btn btn-rect btn-fluid signup-submit-sms-btn"
                    onClick={() => onSendCode(email)}
                  >
                    <div>認証コード送信</div>
                  </button>
                </div>
              </div>
              <div className="signup-input-footer">
                <FormGroup className="mt-50">
                  <div className="fieldset field-column">
                    <div className="fieldset-header">
                      <div className="title">認証コードの入力</div>
                    </div>
                    <div className="fieldset-body">
                      <div className="field-group field-group-column">
                        <div className="field field-lg">
                          <input
                            type="text"
                            placeholder="認証コードを入力"
                            onChange={(e) => setCode(e.target.value)}
                          />
                        </div>
                        {!isValid && formError.code && (
                          <div className="text-danger fs-14 mt-2 fs-12">
                            {formError.code}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="fieldset field-column">
                    <div className="fieldset-header">
                      <div className="title">パスワード</div>
                    </div>
                    <div className="fieldset-body">
                      <div className="field-group field-group-column">
                        <div className="field field-lg field-password">
                          <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <button
                            className="btn"
                            type="button"
                            onClick={() => setShowPassword((show) => !show)}
                          >
                            <span
                              className={
                                showPassword
                                  ? "icon icon-eye open"
                                  : "icon icon-eye close"
                              }
                            ></span>
                          </button>
                        </div>
                        {!isValid && formError.password && (
                          <div className="text-danger fs-14 mb-t fs-12">
                            {formError.password}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="fieldset field-column">
                    <div className="fieldset-header">
                      <div className="title">パスワード確認用</div>
                    </div>
                    <div className="fieldset-body">
                      <div className="field-group field-group-column">
                        <div className="field field-lg field-password">
                          <input
                            type={showPasswordConfirm ? "text" : "password"}
                            id="passwordConfirm"
                            onChange={(e) => setPasswordConfirm(e.target.value)}
                          />
                          <button
                            className="btn"
                            type="button"
                            onClick={() =>
                              setShowPasswordConfirm((show) => !show)
                            }
                          >
                            <span
                              className={
                                showPasswordConfirm
                                  ? "icon icon-eye open"
                                  : "icon icon-eye close"
                              }
                            ></span>
                          </button>
                        </div>
                        {!isValid && formError.passwordConfirm && (
                          <div className="text-danger fs-14 mb-t fs-12">
                            {formError.passwordConfirm}
                          </div>
                        )}
                        <div className="invalid-feedback">
                          ※英大文字・小文字・数字の３種類全てを使ってください。
                          <br />
                          ※８桁以上でご登録ください。
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-group signup-input-btn-group">
                    {menuId ? (
                      <a
                        href={addParamUrl(`/login?id=` + menuId + srcParam, [isSiteParam])}
                        className="btn btn-rect btn-cancel signup-input-btn"
                      >
                        <div>戻る</div>
                      </a>
                    ) : (
                      <a
                        href={addParamUrl(`/login`, [isSiteParam])}
                        className="btn btn-rect btn-cancel signup-input-btn"
                      >
                        <div>戻る</div>
                      </a>
                    )}
                    <button
                      className="btn btn-rect signup-input-btn"
                      onClick={() => onSubmit()}
                    >
                      <div>次へ</div>
                    </button>
                  </div>
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer className="global-footer footer-mypage new-footer-login">
        <section className="global-footer-nav-area">
          <div className="container">
            <div className="section-header">
              <div className="heading-xs">
                <div className="title">
                  <h1>
                    <img
                      src="/assets/img/common/logo_gochi_footer.svg"
                      alt="ごちめし"
                      loading="lazy"
                    />
                  </h1>
                </div>
              </div>
            </div>
            <div className="section-body footer-nav">
              <ul className="footer-link-area">
                <li>
                  <a href="https://gochimeshi.com/policy/">各種規約</a>
                </li>
                <li>
                  <a href="https://gochimeshi.com/policy/privacy/">
                    プライバシーポリシー
                  </a>
                </li>
                <li>
                  <a href="https://gochimeshi.com/policy/legal/">
                    特定商取引法に基づく表記
                  </a>
                </li>
                <li>
                  <a href="https://user-guide.gochimeshi.com/">よくある質問</a>
                </li>
                <li>
                  <a href="https://gochimeshi.com/info/">お問い合わせ</a>
                </li>
                <li>
                  <a href="https://www.gigi.tokyo/">運営会社（Gigi株式会社）</a>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <div className="copyright">
          <small>
            GOCHI®︎ ごちめし®︎ こどもごちめし® びずめし®︎ ︎ さきめし®︎ は、
            <br />
            Gigi株式会社の登録商標です
            <br />
            GOCHIプラットフォームの機能は、
            <br />
            特許取得済み（特許番号：特許第7572763号）
            <br />
            © Gigi Inc.
          </small>
        </div>
      </footer>
    </div>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content) =>
    dispatch(showError(showing, title, content)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ForgotPassword));

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Fragment, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getCitiesSuccess } from "../../../actions/action_cities";
import { getPrefecturesSuccess } from "../../../actions/action_prefectures";
import {
  getSituationsSuccess,
  setModalSituationAction,
  setSituationAction,
} from "../../../actions/action_situations";
import {
  getUserTargetsSuccess,
  setModalUserTargetAction,
  setUserTargetAction,
} from "../../../actions/action_user_targets";
import {
  useCities,
  useGenres,
  usePrefectures,
  usePriceRanges,
  useSituations,
  useUserTargets,
} from "../../../utils";
import queryString from "query-string";

function Header(props) {
  // Google Tag Manager
  // useEffect(() => {
  //   const script = document.createElement("script");

  //   script.src = "/assets/js/inline/google-tag-manager.js";
  //   //   script.async = true;

  //   document.head.appendChild(script);
  //   /* Google Tag Manager (noscript) */

  //   const noScriptEl = document.createElement("noscript");
  //   noScriptEl.innerHTML =
  //     `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TL8QNJR"
  //   height="0" width="0" style="display:none;visibility:hidden"></iframe>`.trim();
  //   document.body.prepend(noScriptEl);

  //   /* End Google Tag Manager (noscript) */

  //   return () => {
  //     document.head.removeChild(script);
  //     document.body.removeChild(noScriptEl);
  //   };
  // }, []);

  usePrefectures((list) => props.savePrefectures(list));
  useCities((list) => props.saveCities(list));
  useSituations((list) => props.saveSituations(list));
  useUserTargets((list) => props.saveUserTargets(list));

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = queryString.parse(window.location.search);

  const urlPath = history.location.pathname;
  const isMypage = (urlPath.match(new RegExp('mypage', 'g')) && urlPath.match(new RegExp('mypage', 'g'))[0] === 'mypage') || (urlPath.match(new RegExp('mypage/*', 'g')) && urlPath.match(new RegExp('mypage/*', 'g'))[0] === 'mypage/');

  const setSituation = (payload) => dispatch(setSituationAction(payload));
  const setUserTarget = (payload) => dispatch(setUserTargetAction(payload));
  const setModalSituation = (payload) =>
    dispatch(setModalSituationAction(payload));
  const setModalUserTarget = (payload) =>
    dispatch(setModalUserTargetAction(payload));

  useEffect(() => {
    setSituation(params.situation || "");
    setUserTarget(params.who || "");
    setPrefecture(params.prefecture || "");
    setCity(params.city || "");
    setGenre(params.genre || "");
    setKeyword(params.keyword || "");
    setPriceRange(params.prices || "");
  }, [location.search]);

  const [prefecture, setPrefecture] = useState(params.prefecture || "");
  const [city, setCity] = useState(params.city || "");
  const listPrefectures = useSelector((state) => state.prefectures);
  const listCities = useSelector((state) => state.cities);
  const prefectureId = getPrefectureId(prefecture);
  const [genre, setGenre] = useState(params.genre || "");
  const genres = useGenres();
  const priceRanges = usePriceRanges();
  const [priceRange, setPriceRange] = useState(params.prices || "");
  const userTarget = useSelector((state) => state.userTarget.userTarget);
  const situation = useSelector((state) => state.situation.situation);
  const [keyword, setKeyword] = useState(params.keyword || "");
  const userInfo = useSelector((state) => state.userInfo);

  const filteredCities = listCities.filter(
    (city) => Number(city.prefCode) === prefectureId
  );

  function getPrefectureId(name) {
    if (name) {
      const prefectureObj = listPrefectures.find(
        (item) => item.prefectureName === name
      );
      if (prefectureObj) return prefectureObj.prefectureId;
    }
    return "";
  }

  function getPriceRangeStrFromObj(range) {
    return `${range.name && range.name.toLocaleString()}`;
  }

  function handleSearchAllParams() {
    const params = [];
    if (prefecture) params.push(`prefecture=${prefecture}`);
    if (city) params.push(`city=${city}`);
    if (situation) params.push(`situation=${situation}`);
    if (genre) params.push(`genre=${genre}`);
    if (priceRange) params.push(`prices=${priceRange}`);
    if (userTarget) params.push(`who=${userTarget}`);
    if (keyword) params.push(queryString.stringify({ keyword }));

    const query = params.join("&");
    history.push({
      pathname: "/products",
      search: `?${query}`,
    });

    document
      .getElementsByTagName("html")[0]
      .classList.remove("sp-header-search-active", "sp-header-search-enter");
  }

  function handleSearch() {
    const params = [];
    if (prefecture) params.push(`prefecture=${prefecture}`);
    if (city) params.push(`city=${city}`);
    if (keyword) params.push(queryString.stringify({ keyword }));

    const query = params.join("&");
    history.push({
      pathname: "/products",
      search: `?${query}`,
    });

    document
      .getElementsByTagName("html")[0]
      .classList.remove("global-nav-active");
  }

  return (
    <Fragment>
      <header className={isMypage ? "header-mypage global-header" : "global-header"}>
        {!props.hideButtonOnHeader && (
          <>
            <button
              id="global-nav-btn"
              className="global-nav-btn"
              type="button"
              data-toggle="global-nav"
            >
              <div className="icon icon-global-nav">
                <span className="icon-global-nav-line icon-global-nav-line-01" />
                <span className="icon-global-nav-line icon-global-nav-line-02" />
                <span className="icon-global-nav-line icon-global-nav-line-03" />
                <span className="icon-global-nav-line icon-global-nav-line-04" />
              </div>
            </button>
            <button
              id="header-search-btn"
              className="ml-auto header-search-btn"
              type="button"
              data-toggle="header-search"
            > 
              {!isMypage ? (
              <svg className="icon">
                <use xlinkHref="/assets/img/common/icon.svg#search" />
              </svg>
            ) : (
              <img className="icon-search-mypage" src="/assets/img/common/icon_search_mypage.svg" />
            )}
            </button>
          </>
        )}
        {isMypage ? (
        <div className="logo-nav">
          <h1 className="logo">
            <a href="/">
              <picture>
                <source
                  media="(max-width: 180px)"
                  srcSet="/assets/img/common/logo_gochi_header_mypage.svg"
                />
                <img
                  src="/assets/img/common/logo_gochi_header_mypage.svg "
                  alt="気持ちを「ごちそう」にして贈れるアプリ ごちめし"
                  loading="lazy"
                />
              </picture>
              <span className="title-page">マイページ</span>
            </a>
          </h1>
        </div>
        ) : (
          <div className="logo-nav">
          <h1 className="logo">
            <a href="/">
              <picture>
                <source
                  media="(max-width: 660px)"
                  srcSet="/assets/img/common/logo-sp.svg"
                />
                <img
                  src="/assets/img/common/logo.svg "
                  alt="気持ちを「ごちそう」にして贈れるアプリ ごちめし"
                  loading="lazy"
                />
              </picture>
            </a>
          </h1>
        </div>
        )}
        {/*グローバルメニューのボタン*/}
        {!props.hideButtonOnHeader && (
          <div className="global-header-right-area">
            {/* ログインしていない場合 */}
            {/*
        <a class="btn btn-rect" href="login.html" data-dismiss="global-nav" target="_blank" rel="noopener noreferrer">
            ログイン/新規登録
        </a>
        */}
            {/* ログインしている場合 */}
            {/*
        <a class="btn btn-rect" href="mypage.html" data-dismiss="global-nav">
            マイページ
        </a>
        */}
            {/* マイページ関連 */}
            {/*
            */}
            {/* ログイン後 */}
            {userInfo && userInfo.userId ? (
              <>
                <a
                  className="btn btn-rect"
                  style={{marginRight: '16px'}}
                  href="/mypage"
                  data-dismiss="global-nav"
                >
                  {isMypage ? "GOCHIマイページ" : "マイページ"}
                </a>
                <a
                  className={isMypage ? "btn btn-rect btn-outline" : "btn btn-rect"}
                  href="/logout"
                  data-dismiss="global-nav"
                >
                  ログアウト
                </a>
              </>
            ) : (
              <a className="btn btn-rect" href="/login" data-dismiss="global-nav">
                ログイン
              </a>
            )}
          </div>
        )}
        {/*グローバルメニューのボタン*/}
        {/*グローバルメニューのハンバーガー押下時のボタン*/}
        <nav className="global-nav" id="global-nav">
          <div className="search-area">
            <div className="search-header heading-lg">
              <div className="title text-outline">商品を検索する</div>
            </div>
            {/* /.search-header */}
            <div className="search-body">
              <div className="fieldset fieldset-lg">
                <div className="fieldset-header">
                  <svg className="icon">
                    <use xlinkHref="/assets/img/common/icon.svg#map-marker" />
                  </svg>
                  <div className="title">エリア</div>
                </div>
                <div className="fieldset-body">
                  <div className="field">
                    <div className="field field-pill field-sm">
                      <select
                        value={prefecture}
                        onChange={(e) => {
                          setPrefecture(e.target.value);
                          setCity("");
                        }}
                      >
                        <option value="">選択なし</option>
                        {listPrefectures.length > 0 &&
                          listPrefectures.map((item, key) => {
                            return (
                              <option key={key} value={item.prefectureName}>
                                {item.prefectureName}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="field field-pill field-sm">
                      <select
                        className="select-box-custom"
                        value={city}
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                      >
                        <option value="">選択なし</option>
                        {filteredCities.length > 0 &&
                          filteredCities.map((item, key) => {
                            return (
                              <option key={key} value={item.cityName}>
                                {item.cityName}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.fieldset */}
              <div className="unit over-pc">
                <svg className="icon">
                  <use xlinkHref="/assets/img/common/icon.svg#times" />
                </svg>
              </div>
              {/* /.unit */}
              <div className="fieldset fieldset-lg">
                <div className="fieldset-header">
                  <svg className="icon">
                    <use xlinkHref="/assets/img/common/icon.svg#search" />
                  </svg>
                  <div className="title">キーワード</div>
                </div>
                <div className="fieldset-body">
                  <div className="field field-pill field-lg">
                    <input
                      value={keyword}
                      type="text"
                      placeholder="例:  お寿司"
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              {/* /.fieldset */}
              <div className="fieldset fieldset-submit">
                <button
                  type="button"
                  className="btn btn-submit"
                  onClick={handleSearch}
                >
                  検索する
                </button>
              </div>
              {/* /.fieldset */}
            </div>
            {/* /.search-body */}
          </div>
          {/* /.search-area */}
          <ul className="global-nav-list">
            <li>
              <a href="/products-purpose/scenes">
                シチュエーションからプレゼント・ギフトを選ぶ
              </a>
            </li>
            <li>
              <a href="/products-purpose/genres">
                ジャンルからプレゼント・ギフトを選ぶ
              </a>
            </li>
            <li>
              <a href="/products-purpose/who">
                贈り相手からプレゼント・ギフトを選ぶ
              </a>
            </li>
            <li>
              <a href="/products-purpose/prices">
                予算からプレゼント・ギフトを選ぶ
              </a>
            </li>
            <li>
              <a href="/specials">特集</a>
            </li>
            <li>
              <a href="/magazine">ごちめしマガジン</a>
            </li>
            <li>
              <a href="/about">ごちめしとは</a>
            </li>
            <li>
              <a href="/howto">ごちめしの使い方</a>
            </li>
            <li className="btn-group">
              {/* ログイン前 */}
              {/*
              <a class="btn btn-rect" href="mypage.html" data-dismiss="global-nav" target="_blank" rel="noopener noreferrer">
                  ログイン<span class="over-pc">/</span><br class="under-tab">新規登録
              </a>
              */}
              {userInfo && userInfo.userId && (
                <a
                  className="btn btn-fluid btn-rect"
                  href="/mypage"
                  data-dismiss="global-nav"
                  // target="_blank"
                  rel="noopener noreferrer"
                >
                  GOCHIマイページ
                </a>
              )}
              
              {userInfo && userInfo.userId ? (
                <a
                  className="btn btn-fluid btn-rect under-tab"
                  href="/logout"
                  data-dismiss="global-nav"
                  rel="noopener noreferrer"
                >
                  ログアウト
                </a>
              ) : (
                <a
                className="btn btn-fluid btn-rect under-tab"
                href="/login"
                data-dismiss="global-nav"
                rel="noopener noreferrer"
              >
                ログイン
              </a>
              )}
              
            </li>
          </ul>
        </nav>
        {/*グローバルメニューのハンバーガー押下時のボタン*/}
        {/*スクロール時に表示されるグローバルメニュー - 検索*/}
        {/* デザイン側追加 */}
        <div className={isMypage ? "search-mypage header-search" : "header-search"} id="header-search">
          <nav className="header-search-nav" id="header-search-nav">
            <div
              className="header-search-close-btn"
              data-device="sp"
              data-dismiss="header-search"
            >
              <svg className="icon">
                <title>閉じる</title>
                <use xlinkHref="/assets/img/common/icon.svg#times" />
              </svg>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSearch();
              }}
              method="POST"
              className="header-search-body search-area"
            >
              <div className="fieldset fieldset-column fieldset-lg">
                <div className="fieldset-header">
                  <svg className="icon">
                    <use xlinkHref="/assets/img/common/icon.svg#map-marker" />
                  </svg>
                  <div className="title">エリア</div>
                </div>
                <div className="fieldset-body">
                  <div className="field">
                    <div className="field field-pill field-sm">
                      <select
                        value={prefecture}
                        onChange={(e) => {
                          setPrefecture(e.target.value);
                          setCity("");
                        }}
                      >
                        <option value="">選択なし</option>
                        {listPrefectures.length > 0 &&
                          listPrefectures.map((item, key) => {
                            return (
                              <option key={key} value={item.prefectureName}>
                                {item.prefectureName}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="field field-pill field-sm">
                      <select
                        value={city}
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                      >
                        <option value="">選択なし</option>
                        {filteredCities.length > 0 &&
                          filteredCities.map((item, key) => {
                            return (
                              <option key={key} value={item.cityName}>
                                {item.cityName}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.fieldset */}
              <div className="unit only-pc">
                <svg className="icon">
                  <use xlinkHref="/assets/img/common/icon.svg#times" />
                </svg>
              </div>
              {/* /.unit */}
              <div className="fieldset fieldset-lg under-tab">
                <div className="fieldset-header">
                  <svg className="icon">
                    <use xlinkHref="/assets/img/common/icon.svg#popper" />
                  </svg>
                  <div className="title">シチュエーション</div>
                </div>
                <div className="fieldset-body">
                  <div className="field">
                    <button
                      className="btn btn-fluid btn-black btn-elipsis"
                      data-target="modal01"
                      type="button"
                      id="openSituation"
                      onClick={() => {
                        setModalSituation(situation);
                      }}
                    >
                      <div id="selectedSituation">{situation || "選択"}</div>
                    </button>
                  </div>
                  {/* /.field */}
                </div>
              </div>
              {/* /.fieldset */}
              <div className="fieldset fieldset-lg under-tab">
                <div className="fieldset-header">
                  <svg className="icon">
                    <use xlinkHref="/assets/img/common/icon.svg#user" />
                  </svg>
                  <div className="title">贈り相手</div>
                </div>
                <div className="fieldset-body">
                  <div className="field">
                    <button
                      className="btn btn-fluid btn-black btn-elipsis"
                      data-target="modal01"
                      type="button"
                      id="openGiftTo"
                      onClick={() => {
                        setModalUserTarget(userTarget);
                      }}
                    >
                      <div id="selectedGiftTo">{userTarget || "選択"}</div>
                    </button>
                  </div>
                  {/* /.field */}
                </div>
              </div>
              {/* /.fieldset */}
              <div className="fieldset fieldset-lg under-tab">
                <div className="fieldset-header">
                  <svg className="icon">
                    <use xlinkHref="/assets/img/common/icon.svg#utensils" />
                  </svg>
                  <div className="title">ジャンル</div>
                </div>
                <div className="fieldset-body">
                  <div className="field field-pill field-md">
                    <select
                      onChange={(e) => setGenre(e.target.value)}
                      value={genre}
                    >
                      <option value="">選択なし</option>
                      {genres.map((item, index) => (
                        <option key={index} value={item.menuGenreName}>
                          {item.menuGenreName}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* /.field */}
                </div>
              </div>
              {/* /.fieldset */}
              <div className="fieldset fieldset-lg under-tab">
                <div className="fieldset-header">
                  <svg className="icon">
                    <use xlinkHref="/assets/img/common/icon.svg#yen-sign" />
                  </svg>
                  <div className="title">予算</div>
                </div>
                <div className="fieldset-body">
                  <div className="field field-pill field-md">
                    <select
                      onChange={(e) => setPriceRange(e.target.value)}
                      value={priceRange}
                    >
                      <option value="">選択してください</option>
                      {priceRanges.map((item, key) => (
                        <option key={key} value={item.name}>
                          {getPriceRangeStrFromObj(item)}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* /.field */}
                </div>
              </div>
              {/* /.fieldset */}
              <div className="fieldset fieldset-lg">
                <div className="fieldset-header">
                  <svg className="icon">
                    <use xlinkHref="/assets/img/common/icon.svg#search" />
                  </svg>
                  <div className="title">キーワード</div>
                </div>
                <div className="fieldset-body">
                  <div className="field field-pill field-lg">
                    <input
                      type="text"
                      placeholder="例:  お寿司"
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                  </div>
                  {/* /.field */}
                </div>
              </div>
              {/* /.fieldset */}
              <div className="fieldset fieldset-submit">
                <button
                  type="button"
                  className="btn btn-submit search-btn"
                  onClick={handleSearchAllParams}
                >
                  検索する
                </button>
              </div>
              {/* /.fieldset */}
            </form>
          </nav>
        </div>
        {/*スクロール時に表示されるグローバルメニュー - 検索*/}
      </header>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  prefectures: state.prefectures,
  cities: state.cities,
  userInfo: state.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  savePrefectures: (list) => dispatch(getPrefecturesSuccess(list)),
  saveCities: (list) => dispatch(getCitiesSuccess(list)),
  saveSituations: (list) => dispatch(getSituationsSuccess(list)),
  saveUserTargets: (list) => dispatch(getUserTargetsSuccess(list)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import * as constants from "./../../constants";
import queryString from "query-string";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import { isMobile } from "react-device-detect";
import i18n, { getLanguage } from "../../i18n";
import NavBar from "../GiftSite/layout/NavBar";
import NavBarMogugori from "../Mogugori/layout/NavBar";
import {addParamUrl} from '../../utils';
import MetaTags from 'react-meta-tags';

const GiftSiteStyles = React.lazy(() => import("./GiftSiteStyles"));

const MogugoriStyles = React.lazy(() => import("./MogugoriStyles"));

const ClassicStyles = React.lazy(() => import("./ClassicStyles"));

const GochiKidStyles = React.lazy(() => import("./GochiKidStyles"));

function LayoutSwitch({ component: PageComponent, isGiftSite, isMogugori, isGochiKid, hideGochiKid, isNoMultiLang, isPrivateRoute, path, ...props }) {
  // if (hideGochiKid) {
  //   window.location.href = "/404";
  //   return;
  // }
  let url = window.location.search;
  let params = queryString.parse(url);
  const menuId = params.id;
  const shopId = params.shopId;
  const isSite = params.isSite;
  const isSiteParam = isSite ? {name: 'isSite', value: isSite} : {};
  const mogugori = (path === '/mypage' 
  || path === '/mypage/follow' 
  || path === '/mypage/purchase_histories' 
  || path === '/mypage/payment_informations' 
  || path === '/mypage/receipt' 
  || path === '/mypage/mygochi' 
  || path === '/mypage/gochi_subscription'
  || (path === '/login' && isMobile)
  || (path === '/logout' && isMobile)
  || (path === '/signup' && isMobile)
  || (path === '/signup/form' && isMobile)
  || (path === '/signup/confirm' && isMobile)
  || (path === '/signup/confirm-sms' && isMobile)
  || (path === '/signup/complete' && isMobile)) &&  isSite === 'mogugori' ? true : false;

  const userInfo = queryString.parse(window.location.search).accessToken ? {isGuest: false, isLogined: true, userId: JSON.parse(atob(queryString.parse(window.location.search).accessToken.split(".")[1])).uid} : JSON.parse(localStorage.getItem('userInfo'));
  
  const isLoggedIn = !!userInfo && userInfo.userId;

  // check reload css by site type
  const isGift = isGiftSite ? 'true' : 'false';

  if (sessionStorage.getItem('isGiftSite') !== isGift && (
    path === '/invite' 
    || path === '/menu')) {
    sessionStorage.setItem('isGiftSite', isGiftSite ? true : false);
    window.location.reload();
  }

  sessionStorage.setItem('isGiftSite', isGiftSite ? true : false);
  sessionStorage.setItem('isMogugori', isMogugori ? true : false);

  const current = i18n.language;

  if (current !== 'ja_gs' && (isGiftSite || isMogugori || isGochiKid || isNoMultiLang)) {
    console.log('CHANGE LANG:');
    i18n.changeLanguage('ja_gs');
  } else if (current === 'ja_gs' && !isMogugori && !isGiftSite && !isGochiKid && !isNoMultiLang) {
    i18n.changeLanguage(getLanguage());
  }

  if (isPrivateRoute && !isLoggedIn) {
    sessionStorage.setItem('loginCallbackUrl', window.location.pathname);
    return (
      <Redirect to={addParamUrl("/login", [isSiteParam])} />
    );
  }

  let isAllowInvite = true;
  if (shopId && props.shopInfo?.status !== constants.SHOP_STATUS_AVAILABLE) {
    isAllowInvite = false;
  }
  if (menuId) {
    if (
      props.menuInfo &&
      props.menuInfo.menuType !== constants.MENU_TYPE_DONATION
    ) {
      if (Object.keys(props.shopInfo).length !== 0) {
        if (
          props.shopInfo.status === constants.SHOP_STATUS_AVAILABLE &&
          props.menuInfo.showHide === 1
        ) {
          isAllowInvite = true;
          sessionStorage.removeItem("isMypage");
        } else {
          isAllowInvite = false;
        }
      } else {
        if (props.groupInfo.groupId && props.menuInfo.showHide === 1) {
          isAllowInvite = true;
        } else {
          isAllowInvite = false;
        }
      }
    }
  }

  let pathname = window.location.pathname;
  let arr = pathname.split("/");
  if (arr.includes("invite-finish")) {
    isAllowInvite = true;
  }

  // Check if the shop allows to buy menu price
  if (!menuId && shopId && props.shopInfo.allowMenuPrice !== 1 && pathname.includes('/invite')) {
    isAllowInvite = false;
  }

  if (isGochiKid) {
    document.body.className = "gochikid";
  }

  // if ((isGiftSitePrev === 'true' && !isGiftSite)) {
  //   console.log("Reload to clear imported CSS");
  //   console.log("THANHNT " + window.location.pathname);
  //   window.location.href = window.location.pathname;
  // }
  return (
    <>
      {isMogugori ? (
        <MetaTags>
          <meta property="og:site_name" content="Mogugori" />
          <meta property="og:title" content="Mogugori" />
          <meta property="og:url" content="https://gochi.online/mogugori" />
          <link rel="shortcut icon" type="image/png" href="https://gochi.online/apple-touch-icon-mogugori@2x.png" />
          <link rel="apple-touch-icon" href="https://gochi.online/apple-touch-icon-mogugori@2x.png" />
          <link rel="icon" href="https://gochi.online/favicon-mogugori.ico" />
          <meta property="og:image" content="https://gochi.online/apple-touch-icon-mogugori@2x.png" />
        </MetaTags>
      ) : (
        <MetaTags>
          <link rel="icon" href="/favicon.ico" />
          <meta property="og:url" content="https://gochi.online" />
          <meta property="og:image" content="https://gochi.online/assets/img/common/ogp.png" />
          <link rel="apple-touch-icon" href="https://gochi.online/apple-touch-icon@2x.png" /> 
          <link rel="manifest" href="https://gochi.online/manifest.json"  crossOrigin="use-credentials"/> 
        </MetaTags>
      )}
      <React.Suspense fallback={<></>}>
        {!isGiftSite && <ClassicStyles />}
        {isGiftSite && <GiftSiteStyles />}
        {(isMogugori || mogugori) && <MogugoriStyles />}
        {(isGochiKid) && <GochiKidStyles />}

        {props.loading && !isGiftSite && !isMogugori &&
            <div className="loading-overlay">
              <FontAwesomeIcon icon={faSpinner} className="" />
            </div>
        }

        {isGiftSite ? (
            mogugori ? (
              <>
                {/* <Header /> */}
                <div className="mogugori-main-content">
                  <PageComponent  {...props} />
                </div>
                <NavBarMogugori />
                {/* <Footer /> */}
              </>
            ) : (
              <>
                {/* <Header /> */}
                <div className="gift-site-main-content">
                  <PageComponent  {...props} />
                </div>
                <NavBar />
                {/* <Footer /> */}
              </>
            )
        ) : isMogugori ? (
          <>
            {/* <Header /> */}
            <div className="mogugori-main-content">
              <PageComponent  {...props} />
            </div>
            <NavBarMogugori />
            {/* <Footer /> */}
          </>
        ) : isGochiKid ? (
          <>
            <div className="gochi-kid-main-content">
              <PageComponent  {...props} />
            </div>
          </>
        ) : isAllowInvite ? (
          <div className="main-content">
            <PageComponent {...props} />
          </div>
        ) : (
          <div className="main-content mt-0">
            <div className="shop-not-avai text-center">
              <img
                src={require("../../assets/img/icons/gochi_logo_print.png")}
                alt={props.t(
                  "other.This feast button cannot be used because the store is not open"
                )}
                width="100%"
                className="image-not-available"
              />

              <div className="fs-18 font-weight-bold text-left">
                {shopId && props.shopInfo.allowMenuPrice !== 1 && pathname.includes('/invite')
                  ? props.t(
                      "pages.gochiuse.You cannot buy Gochi Price in this shop"
                    )
                  : (props.shopInfo.status === 'CLOSED' ? 
                        props.t("other.This feast button cannot be used because the store is not open")
                      : null
                    )}
              </div>
            </div>
          </div>
        )}
      </React.Suspense>
    </>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(withTranslation()(LayoutSwitch));

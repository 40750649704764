import React from 'react';
import logoPaypay from '../../assets/img/icons/2scr_728x90_red@2x.png';

function PaypayAd(props) {
  return (
    <div className={props.className}>
      <a
        href="https://paypay.ne.jp/event/matsuri202402-paypay-scratch/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={logoPaypay} alt="PayPay" />
      </a>
    </div>
  );
}

export default PaypayAd;

const stateDefault = {
  situation: null,
  modalSituation: null,
};

export default (state = stateDefault, action) => {
  switch (action.type) {
    case "SET_MODAL_SITUATION":
      return {
        ...state,
        modalSituation: action.payload,
      };
    case "SET_SITUATION":
      return {
        ...state,
        situation: action.payload,
      };
    default:
      return state;
  }
};

import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  useGenres,
  usePriceRanges,
  useRecentlyViewedMenus,
  useScript,
  useSpecialPickup,
  useWindowSize,
} from "../../../../utils";
import { Footer } from "../../layout/Footer";
import Header from "../../layout/Header";
import GiftService from "../../../../api/services/GiftService";
import { useHistory, useRouteMatch } from "react-router";
import { connect, useDispatch, useSelector } from "react-redux";
import { ModalGroup } from "../../common/ModalGroup";
import { SituationModal } from "../../common/SituationModal";
import { GiftToModal } from "../../common/GiftToModal";
import { Link } from "react-router-dom";
import { ListMenus } from "../../common/ListMenus";
import queryString from "query-string";
import { withTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { getSlidePerView, swiperConfigs } from "../../common/SwiperConfigs";
import { SliderPagination } from "../../common/SliderPagination";
import { setModalSituationAction } from "../../../../actions/action_situations";
import { setModalUserTargetAction } from "../../../../actions/action_user_targets";
import moment from "moment";
import { Explanation } from "../../layout/Explanation";

export function MagazineDetail(props) {
  useScript("/assets/js/app.js");

  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const [prefecture, setPrefecture] = useState("");
  const [city, setCity] = useState("");
  const listPrefectures = useSelector((state) => state.prefectures);
  const listCities = useSelector((state) => state.cities);
  const prefectureId = getPrefectureId(prefecture);
  const situation = useSelector((state) => state.situation.situation);
  const setModalSituation = (payload) =>
    dispatch(setModalSituationAction(payload));

  const userTarget = useSelector((state) => state.userTarget.userTarget);
  const setModalUserTarget = (payload) =>
    dispatch(setModalUserTargetAction(payload));
  const [genre, setGenre] = useState("");
  const genres = useGenres();
  const priceRanges = usePriceRanges();
  const [priceRange, setPriceRange] = useState("");

  const [keyword, setKeyword] = useState("");
  const [magazineDetails, setMagazineDetails] = useState({});
  const [magazineSameTags, setMagazineSameTags] = useState([]);
  const [magazineSameCategories, setManagazineSameCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const postId = match.params.postId;
  const [latestPosts, setLatestPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const [recentlyViewedMenus] = useRecentlyViewedMenus();
  const listPickups = useSpecialPickup();

  const { t } = props;

  const windowSize = useWindowSize();
  const innerWidth = windowSize[0];

  const fetchJsFromCDN = (href, externals = []) => {
    new Promise((resolve, reject) => {
      const link = document.createElement('link')
      link.setAttribute('href', href)
      link.setAttribute('rel', 'stylesheet')
      link.addEventListener('load', () => {
        resolve(externals.map(key => {
          const ext = window[key]
          typeof ext === 'undefined' && console.warn(`No external named '${key}' in window`)
          return ext
        }))
      })
      link.addEventListener('error', reject)
      document.body.appendChild(link)
    })
  }

  useEffect(() => {
    fetchJsFromCDN('https://gochimeshi.com/wordpress/wp-content/themes/gochimeshi-wp-theme-legacy/gift-site-style.css', ['giftsite']);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    GiftService.getMagazineDetail(postId).then((response) => {
      setLoading(false);
      if (response.status === 200) {
        setMagazineDetails(response.data);
        getMagazineSameTags(response.data?.tags?.map((item) => item.term_id));
        getMagazineSameCategories(
          response.data?.categories?.map((item) => item.term_id)
        );
      } else if (response.status === 404) {
        history.push({
          pathname: "/404",
        });
      }
    });
  }, [postId, history]);

  useEffect(() => {}, []);

  useEffect(() => {
    getListCategories();
    getLatestPosts();
  }, []);

  function getTitle() {
    return (
      (magazineDetails.title || "") +
      "｜ごちめしマガジン｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス"
    );
  }

  function getMetaDescription() {
    return `${
      magazineDetails.title || ""
    }のページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。`;
  }

  function getLatestPosts() {
    GiftService.getMagazines(1, 5).then((response) => {
      if (response.status === 200) {
        setLatestPosts(response.data.contents);
        setTotal(response.data.totalCount);
      }
    });
  }

  function getListCategories() {
    GiftService.getCategory().then((response) => {
      if (response.status === 200) {
        setCategories(response.data);
      }
    });
  }

  function getMagazineSameTags(tags) {
    if (tags.length === 0) return;
    const subTagsId = tags.slice(1, tags.length).join(",");
    GiftService.getTagMagazines(tags[0], subTagsId, 1, 5).then((response) => {
      if (response.status === 200) {
        setMagazineSameTags(response.data.contents);
      }
    });
  }

  function getMagazineSameCategories(categories) {
    const subCategoriesId = categories.slice(1, categories.length).join(",");
    console.log("getMagazineSameCategories", categories, subCategoriesId);
    GiftService.getCategoryMagazines(categories[0], subCategoriesId, 1, 5).then(
      (response) => {
        if (response.status === 200) {
          setManagazineSameCategories(response.data.contents);
        }
      }
    );
  }

  const filteredCities = listCities.filter(
    (city) => Number(city.prefCode) === prefectureId
  );

  function getPrefectureId(name) {
    if (name) {
      const prefectureObj = listPrefectures.find(
        (item) => item.prefectureName === name
      );
      if (prefectureObj) return prefectureObj.prefectureId;
    }
    return "";
  }

  function getPriceRangeStrFromObj(range) {
    return `${range.name && range.name.toLocaleString()}`;
  }

  function handleSearch() {
    const params = [];
    if (prefecture) params.push(`prefecture=${prefecture}`);
    if (city) params.push(`city=${city}`);
    if (situation) params.push(`situation=${situation}`);
    if (genre) params.push(`genre=${genre}`);
    if (priceRange) params.push(`prices=${priceRange}`);
    if (userTarget) params.push(`who=${userTarget}`);
    if (keyword) params.push(queryString.stringify({ keyword }));

    const query = params.join("&");
    history.push({
      pathname: "/products",
      search: `?${query}`,
    });
  }

  function formatDate(value) {
    return value && value !== "" ? moment(value).format("YYYY.MM.DD") : value;
  }

  return (
    <>
      <MetaTags>
        <title>{getTitle()}</title>
        <meta
          name="keywords"
          content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト"
        />
        <meta name="description" content={getMetaDescription()} />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content={getTitle()} />
        <meta name="twitter:description" content={getMetaDescription()} />
        <meta name="twitter:image" content={window.location.origin + "/assets/img/common/ogp.png"}  />
        <meta name="twitter:site" content="@gochi_meshi" />
        <meta name="twitter:creator" content="@gochi_meshi" />
        <meta name="twitter:image:width" content="300" />
        <meta name="twitter:image:height" content="300" />
        <meta property="og:site_name" content={getTitle()} />
        <meta property="og:title" content={getTitle()} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={window.location.origin + "/assets/img/common/ogp.png"} />
        <meta property="og:description" content={getMetaDescription()} />
      </MetaTags>
      <Header />

      <main className="magazine-detail">
        <div className={loading ? "breadcrumb loading" : "breadcrumb"}>
          <div className="container">
            <ol className="breadcrumb-list">
              <li>
                <Link to="/">{t("pages.magazine.topPage")}</Link>
              </li>
              <li>
                <Link to="/magazine">{t("pages.magazine.title")}</Link>
              </li>
              <li>
                <Link
                  to={`/magazine/categories/${
                    magazineDetails.categories &&
                    magazineDetails.categories[0]?.term_id
                  }`}
                >
                  {magazineDetails.categories &&
                    magazineDetails.categories[0]?.name}
                </Link>
              </li>
              <li>
                <span>{magazineDetails.title}</span>
              </li>
            </ol>
            　
          </div>
        </div>
        <div className="container">
          <div className="sidebar-group">
            <article className={loading ? "article loading" : "article"}>
              <header className="article-header">
                <div className="heading">
                  <h1 className="title">{magazineDetails.title}</h1>
                </div>
                <div className="article-header-info">
                  <div className="article-header-info-category">
                    <div className="badge-group">
                      {magazineDetails.categories?.map((item) => (
                        <Link
                          to={`/magazine/categories/${item.term_id}`}
                          key={item.term_id}
                          className="badge"
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                  <div className="article-header-info-date">
                    <ul className="list-inline text-gray text-nowrap">
                      <li>
                        {t("pages.magazine.detail.postDate")}
                        <time>
                          {magazineDetails.date
                            ? formatDate(magazineDetails.date)
                            : ""}
                        </time>
                      </li>
                      <li>
                        {t("pages.magazine.detail.updateDate")}
                        <time>
                          {magazineDetails.modified
                            ? formatDate(magazineDetails.modified)
                            : ""}
                        </time>
                      </li>
                    </ul>
                  </div>
                </div>
              </header>
              <figure className="article-thumbnail">
                <img
                  src={magazineDetails.thumbnails?.full?.url}
                  alt={magazineDetails.title}
                  loading="lazy"
                />
              </figure>
              <section
                dangerouslySetInnerHTML={{ __html: magazineDetails.content }}
                className="article-body"
              ></section>
              {magazineDetails.tags && magazineDetails.tags.length > 0 && (
                <footer className="article-footer">
                  <div className="box tag-area">
                    <div className="section-hide">
                      <div className="section-body">
                        <section className="section-sm">
                          <div className="section-header">
                            <svg className="icon">
                              <use xlinkHref="/assets/img/common/icon.svg#tag" />
                            </svg>
                            <span className="title">
                              {t("pages.magazine.detail.tag")}
                            </span>
                          </div>
                          <div className="section-body">
                            <div className="btn-group tag-btn-group">
                              {magazineDetails.tags?.map((item) => (
                                <Link
                                  key={item.term_id}
                                  className="btn btn-sm btn-outline btn-black tag-btn"
                                  to={`/magazine/tags/${item.term_id}`}
                                >
                                  {item.name}
                                </Link>
                              ))}
                            </div>
                          </div>
                        </section>
                      </div>
                      {/* <button className="section-toggle tag-section-toggle">
                        <svg className="icon">
                          <use xlinkHref="/assets/img/common/icon.svg#angle-down" />
                        </svg>
                      </button> */}
                    </div>
                  </div>
                </footer>
              )}
            </article>
            <aside className="sidebar">
              <section className="sidebar-section">
                <div className="card-group card-group-column">
                  <Link to="/about" className="card card-banner bg-main">
                    <div className="card-thumbnail">
                      <img
                        src="/assets/img/common/logo-mark.svg"
                        alt=""
                        className="icon"
                      />
                    </div>
                    <div className="card-header">
                      <div className="title">
                        {t("pages.magazine.detail.about")}
                      </div>
                    </div>
                  </Link>
                </div>
              </section>
              <section className="sidebar-section">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSearch();
                  }}
                  method="POST"
                  className="search-area is-sidebar"
                >
                  <div className="search-header">
                    <div className="title">
                      {t("pages.magazine.detail.searchTitle")}
                    </div>
                  </div>
                  <div className="search-body">
                    <div className="fieldset fieldset-column fieldset-lg">
                      <div className="fieldset-header">
                        <svg className="icon">
                          <use xlinkHref="/assets/img/common/icon.svg#map-marker" />
                        </svg>
                        <div className="title">
                          {t("pages.magazine.detail.area")}
                        </div>
                      </div>
                      <div className="fieldset-body">
                        <div className="field flex-wrap">
                          <div className="field field-pill field-sm search-field">
                            <select
                              value={prefecture}
                              onChange={(e) => {
                                setPrefecture(e.target.value);
                                setCity("");
                              }}
                            >
                              <option value="">
                                {t("pages.magazine.detail.notSelect")}
                              </option>
                              {listPrefectures.length > 0 &&
                                listPrefectures.map((item, key) => {
                                  return (
                                    <option
                                      key={key}
                                      value={item.prefectureName}
                                    >
                                      {item.prefectureName}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="field field-pill field-sm search-field">
                            <select
                              value={city}
                              onChange={(e) => {
                                setCity(e.target.value);
                              }}
                            >
                              <option value="">
                                {t("pages.magazine.detail.notSelect")}
                              </option>
                              {filteredCities.length > 0 &&
                                filteredCities.map((item, key) => {
                                  return (
                                    <option key={key} value={item.cityName}>
                                      {item.cityName}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.fieldset */}
                    <div className="fieldset fieldset-lg">
                      <div className="fieldset-header">
                        <svg className="icon">
                          <use xlinkHref="/assets/img/common/icon.svg#popper" />
                        </svg>
                        <div className="title">
                          {t("pages.magazine.detail.situation")}
                        </div>
                      </div>
                      <div className="fieldset-body">
                        <div className="field">
                          <button
                            className="btn btn-fluid btn-black btn-elipsis"
                            id="openSearchSituation"
                            data-target="modal01"
                            type="button"
                            onClick={() => {
                              setModalSituation(situation);
                            }}
                          >
                            <div id="searchselectedSituation">
                              {situation || t("pages.magazine.detail.select")}
                            </div>
                          </button>
                        </div>
                        {/* /.field */}
                      </div>
                    </div>
                    {/* /.fieldset */}
                    <div className="fieldset fieldset-lg">
                      <div className="fieldset-header">
                        <svg className="icon">
                          <use xlinkHref="/assets/img/common/icon.svg#user" />
                        </svg>
                        <div className="title">
                          {t("pages.magazine.detail.who")}
                        </div>
                      </div>
                      <div className="fieldset-body">
                        <div className="field">
                          <button
                            className="btn btn-fluid btn-black btn-elipsis"
                            id="openSearchGiftTo"
                            data-target="modal01"
                            type="button"
                            onClick={() => {
                              setModalUserTarget(userTarget);
                            }}
                          >
                            <div id="searchselectedGiftTo">
                              {userTarget || t("pages.magazine.detail.select")}
                            </div>
                          </button>
                        </div>
                        {/* /.field */}
                      </div>
                    </div>
                    {/* /.fieldset */}
                    <div className="fieldset fieldset-lg">
                      <div className="fieldset-header">
                        <svg className="icon">
                          <use xlinkHref="/assets/img/common/icon.svg#utensils" />
                        </svg>
                        <div className="title">
                          {t("pages.magazine.detail.genre")}
                        </div>
                      </div>
                      <div className="fieldset-body">
                        <div className="field field-pill field-md">
                          <select
                            onChange={(e) => setGenre(e.target.value)}
                            value={genre}
                          >
                            <option value="">
                              {t("pages.magazine.detail.notSelect")}
                            </option>
                            {genres.map((item, index) => (
                              <option key={index} value={item.menuGenreName}>
                                {item.menuGenreName}
                              </option>
                            ))}
                          </select>
                        </div>
                        {/* /.field */}
                      </div>
                    </div>
                    {/* /.fieldset */}
                    <div className="fieldset fieldset-lg">
                      <div className="fieldset-header">
                        <svg className="icon">
                          <use xlinkHref="/assets/img/common/icon.svg#yen-sign" />
                        </svg>
                        <div className="title">
                          {t("pages.magazine.detail.budget")}
                        </div>
                      </div>
                      <div className="fieldset-body">
                        <div className="field field-pill field-md">
                          <select
                            onChange={(e) => setPriceRange(e.target.value)}
                            value={priceRange}
                          >
                            <option value="">
                              {t("pages.magazine.detail.pleaseSelect")}
                            </option>
                            {priceRanges.map((item, key) => (
                              <option key={key} value={item.name}>
                                {getPriceRangeStrFromObj(item)}
                              </option>
                            ))}
                          </select>
                        </div>
                        {/* /.field */}
                      </div>
                    </div>
                    {/* /.fieldset */}
                    <div className="fieldset fieldset-lg">
                      <div className="fieldset-header">
                        <svg className="icon">
                          <use xlinkHref="/assets/img/common/icon.svg#search" />
                        </svg>
                        <div className="title">
                          {t("pages.magazine.detail.keyword")}
                        </div>
                      </div>
                      <div className="fieldset-body">
                        <div className="field field-pill field-lg">
                          <input
                            value={keyword}
                            type="text"
                            placeholder={t("pages.magazine.detail.sample")}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                        </div>
                        {/* /.field */}
                      </div>
                    </div>
                    {/* /.fieldset */}
                    <div className="fieldset fieldset-submit">
                      <button
                        onClick={handleSearch}
                        type="button"
                        className="btn btn-submit search-btn"
                      >
                        {t("pages.magazine.detail.buttonSearch")}
                      </button>
                    </div>
                    {/* /.fieldset */}
                  </div>
                </form>
              </section>
              <section className="sidebar-section">
                <header className="sidebar-header">
                  <div className="heading">
                    <h3 className="title">
                      {t("pages.magazine.detail.special")}
                    </h3>
                  </div>
                </header>
                <div className="sidebar-body">
                  <div className="card-group card-group-sm card-group-column magazine-banner-group">
                    {listPickups.slice(0, 2).map((item, key) => (
                      <a
                        href={item.link_url}
                        className="card-magazine-banner"
                        role="group"
                        key={key}
                      >
                        <img
                          src={item.image_url}
                          className="img"
                          loading="lazy"
                          alt="pickup"
                        />
                      </a>
                    ))}
                  </div>
                </div>
              </section>
              <section className="sidebar-section">
                <header className="sidebar-header">
                  <div className="heading">
                    <h3 className="title">
                      {t("pages.magazine.detail.newMagazine")}
                    </h3>
                  </div>
                </header>
                <div className="sidebar-body">
                  <div className="magazine-group magazine-group-column">
                    {latestPosts.map((item) => (
                      <Link
                        key={item.post_id}
                        className="card-magazine card-magazine-row"
                        to={`/magazine/articles/${item.post_id}`}
                      >
                        <div className="card-magazine-thumbnail">
                          <img
                            src={item.thumbnails?.large?.url}
                            loading="lazy"
                            alt={item.title}
                          />
                        </div>
                        <div className="card-magazine-header">
                          <div className="card-magazine-header-info">
                            <div className="badge">
                              {item.categories[0]?.name}
                            </div>
                            <div className="date">
                              {formatDate(item.modified)}
                            </div>
                          </div>
                          <div className="heading-sm">
                            <div className="title">{item.title}</div>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </section>
            </aside>
            {/* /.sidebar */}
          </div>
          {/* /.sidebar-group */}
        </div>
        {/* /.container */}
        <aside>
          {/* 関連記事 */}
          {magazineSameTags && magazineSameTags.length > 0 && (
            <section className="section">
              <div className="container">
                <div className="section-header">
                  <div className="heading-lg">
                    <h2 className="title text-bold">
                      {t("pages.magazine.detail.related")}
                    </h2>
                  </div>
                </div>
                <div className="section-body only-pc">
                  <div className="article-swiper-container">
                    <div className="swiper-wrapper">
                      {magazineSameTags.map((item) => (
                        <Link
                          key={item.post_id}
                          className="swiper-slide card-magazine"
                          to={`/magazine/articles/${item.post_id}`}
                        >
                          <div className="card-magazine-thumbnail">
                            <img
                              src={item.thumbnails?.large?.url}
                              alt={item.title}
                            />
                          </div>
                          <div className="card-magazine-header">
                            <div className="badge">
                              {item.categories[0]?.name}
                            </div>
                            <div className="date">
                              {formatDate(item.modified)}
                            </div>
                            <div className="heading-sm">
                              <div className="title">{item.title}</div>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="section-body under-tab">
                  {magazineSameTags.length <=
                  getSlidePerView(innerWidth, swiperConfigs.products) ? (
                    <div className="swiper-container disabled" id="sliderProducts">
                      <div className="swiper-wrapper">
                        {magazineSameTags.map((item) => (
                          // <div key={item.post_id}>
                            <Link
                              key={item.post_id}
                              className="swiper-slide card-magazine"
                              to={`/magazine/articles/${item.post_id}`}
                            >
                              <div className="card-magazine-thumbnail">
                                <img
                                  src={item.thumbnails?.large?.url}
                                  alt={item.title}
                                />
                              </div>
                              <div className="card-magazine-header">
                                <div className="badge">
                                  {item.categories[0]?.name}
                                </div>
                                <div className="date">
                                  {formatDate(item.modified)}
                                </div>
                                <div className="heading-sm">
                                  <div className="title">{item.title}</div>
                                </div>
                              </div>
                            </Link>
                          // </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <Swiper {...swiperConfigs.products}>
                      <div className="swiper-wrapper">
                        {magazineSameTags.map((item) => (
                          <SwiperSlide key={item.post_id}>
                            <Link
                              className="swiper-slide card-magazine"
                              to={`/magazine/articles/${item.post_id}`}
                            >
                              <div className="card-magazine-thumbnail">
                                <img
                                  src={item.thumbnails?.large?.url}
                                  alt={item.title}
                                />
                              </div>
                              <div className="card-magazine-header">
                                <div className="badge">
                                  {item.categories[0]?.name}
                                </div>
                                <div className="date">
                                  {formatDate(item.modified)}
                                </div>
                                <div className="heading-sm">
                                  <div className="title">{item.title}</div>
                                </div>
                              </div>
                            </Link>
                          </SwiperSlide>
                        ))}
                      </div>
                      <SliderPagination />
                    </Swiper>
                  )}
                </div>
              </div>
            </section>
          )}

          {/* 関連記事 */}
          {/* 同じカテゴリーの記事 */}
          <section className="section">
            <div className="container">
              <div className="section-header">
                <div className="heading-lg">
                  <h2 className="title text-bold">
                    {t("pages.magazine.detail.sameCategory")}
                  </h2>
                </div>
              </div>
              <div className="section-body only-pc">
                <div className="article-swiper-container">
                  <div className="swiper-wrapper">
                    {magazineSameCategories.map((item) => (
                      <Link
                        key={item.post_id}
                        className="swiper-slide card-magazine"
                        to={`/magazine/articles/${item.post_id}`}
                      >
                        <div className="card-magazine-thumbnail">
                          <img
                            src={item.thumbnails?.large?.url}
                            alt={item.title}
                          />
                        </div>
                        <div className="card-magazine-header">
                          <div className="badge">
                            {item.categories[0]?.name}
                          </div>
                          <div className="date">
                            {formatDate(item.modified)}
                          </div>
                          <div className="heading-sm">
                            <div className="title">{item.title}</div>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="section-body under-tab">
                {magazineSameCategories.length <=
                getSlidePerView(innerWidth, swiperConfigs.products) ? (
                  <div className="swiper-container disabled" id="sliderProducts">
                    <div className="swiper-wrapper">
                      {magazineSameCategories.map((item) => (
                        // <div key={item.post_id}>
                          <Link
                            key={item.post_id}
                            className="swiper-slide card-magazine"
                            to={`/magazine/articles/${item.post_id}`}
                          >
                            <div className="card-magazine-thumbnail">
                              <img
                                src={item.thumbnails?.large?.url}
                                alt={item.title}
                              />
                            </div>
                            <div className="card-magazine-header">
                              <div className="badge">
                                {item.categories[0]?.name}
                              </div>
                              <div className="date">
                                {formatDate(item.modified)}
                              </div>
                              <div className="heading-sm">
                                <div className="title">{item.title}</div>
                              </div>
                            </div>
                          </Link>
                        // </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <Swiper {...swiperConfigs.products}>
                    <div className="swiper-wrapper">
                      {magazineSameCategories.map((item) => (
                        <SwiperSlide key={item.post_id}>
                          <Link
                            className="swiper-slide card-magazine"
                            to={`/magazine/articles/${item.post_id}`}
                          >
                            <div className="card-magazine-thumbnail">
                              <img
                                src={item.thumbnails?.large?.url}
                                alt={item.title}
                              />
                            </div>
                            <div className="card-magazine-header">
                              <div className="badge">
                                {item.categories[0]?.name}
                              </div>
                              <div className="date">
                                {formatDate(item.modified)}
                              </div>
                              <div className="heading-sm">
                                <div className="title">{item.title}</div>
                              </div>
                            </div>
                          </Link>
                        </SwiperSlide>
                      ))}
                    </div>
                    <SliderPagination />
                  </Swiper>
                )}
              </div>
            </div>
          </section>
          {/* 同じカテゴリーの記事 */}
          <section className="section-sm mt-60 category-area bg-gray-light-2">
            <div className="container">
              <div className="section-header">
                <svg className="icon">
                  <use xlinkHref="/assets/img/common/icon.svg#tile-2" />
                </svg>
                <div className="title">
                  {t("pages.magazine.detail.category")}
                </div>
              </div>
              <div className="section-body">
                <div className="btn-group category-btn-group">
                  <Link
                    to="/magazine"
                    className="btn btn-sm btn-outline btn-black category-btn"
                  >
                    {t("pages.magazine.list.all")} {t("other.leftBracket")}
                    {total}
                    {t("other.rightBracket")}
                  </Link>
                  {categories.map((item) => (
                    <Link
                      key={item.term_id}
                      to={`/magazine/categories/${item.term_id}`}
                      className={
                        magazineDetails.categories
                          ?.map((item) => item.term_id)
                          .includes(item.term_id)
                          ? "btn btn-sm btn-black category-btn"
                          : "btn btn-sm btn-outline btn-black category-btn"
                      }
                    >
                      {item.name} {t("other.leftBracket")}
                      {item.count}
                      {t("other.rightBracket")}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            {/* /.container */}
          </section>
          {/* 最近チェックしたメニュー */}
          <ListMenus
            swiperId="list-recently-view-menu-container"
            title={t("pages.magazine.detail.recentView")}
            data={recentlyViewedMenus.map((menu) => ({
              ...menu,
              price: menu.lastPrice,
            }))}
          />
          {/* 最近チェックしたメニュー */}
        </aside>

        <Explanation />
      </main>

      <Footer />

      <ModalGroup>
        <SituationModal />
        <GiftToModal />
      </ModalGroup>
    </>
  );
}

const mapStateToProps = (state) => ({ props: state.props });

export default connect(mapStateToProps)(withTranslation()(MagazineDetail));

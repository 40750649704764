import React, { Component } from "react";    
import {connect} from "react-redux"; 
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
// import ShopService from "../../../api/services/ShopService";
import {showLoading} from "../../../actions/action_loading";
import ListShop from '../../common/ListShop';
import moment from "moment";

class DishInfor extends Component {
    constructor(props) {
		super(props);
		this.state = {
            menuId: null,
            shopId: null,
			showListShopPopup: false
		};
		this.handleShowListShops = this.handleShowListShops.bind(this);
		this.handleCloseListShopPopup= this.handleCloseListShopPopup.bind(this);
	}

    handleShowListShops() {
		this.setState({
			showListShopPopup: true
		});
	};

	handleCloseListShopPopup() {
		this.setState({
			showListShopPopup: false
		});
	};

    async componentDidMount() {
        let url = window.location.search;
        let params = queryString.parse(url);
		var menuId = params.id;
        var shopId = params.shopId;

        this.setState({
			menuId: menuId,
            shopId: shopId
        });
    }

    render() {
		const { t } = this.props;
        const feePaidByShop = (Object.entries(this.props.menuInfo).length > 0) ? this.props.menuInfo.feePaidByShop : (Object.entries(this.props.shopInfo).length > 0 && this.props.shopInfo.feePaidByShop) ? this.props.shopInfo.feePaidByShop : false;
        const discountPercent = Object.entries(this.props.menuInfo).length > 0 ? 100*this.props.menuInfo.discountRate : 100*this.props.shopInfo.discountRate;
        const discountPercentStr = discountPercent && discountPercent.toLocaleString();
        const freeTicketCount = this.props.menuInfo.menuComboDetail && this.props.menuInfo.menuComboDetail.quantityFree && this.props.menuInfo.menuComboDetail.quantityFree !== 0 ? this.props.menuInfo.menuComboDetail.quantityFree : 0;

        const expirationEndDate = 
                this.props.menuInfo.menuComboDetail && 
                this.props.menuInfo.menuComboDetail.expirationEndDate ? 
                    new Date(this.props.menuInfo.menuComboDetail.expirationEndDate)
                    : null; 

        return (
            <>
                <div className="dish-infor mt-4">
                    {this.props.menuInfo.menuChildType !== 5 && (
                      <div className="required-dot mb-3">
                        <div className="dot-red"></div>
                        <div className="dot-text-red fs-13">{t('other.requiredNote')}</div>
                      </div>
                    )}
                    <div className="media">
                        <div className="dish-image">
                            <figure className="mb-0 dish-fig">
                                {Object.entries(this.props.menuInfo).length === 0 ? 
                                    <img
                                        src={require("../../../assets/img/icons/gochi_price.jpg")}
                                        alt={t('pages.menu.Menu Price')}
                                        width="100%"
                                    /> 
                                    :
                                    <img 
                                        src={this.props.menuInfo.menuImage && 
                                            (this.props.menuInfo.menuImage.length > 0) ? 
                                                this.props.menuInfo.menuImage[0].image : 
                                                    require("../../../assets/img/icons/gochi_noimg-ver4.jpg")} 
                                        alt={this.props.menuInfo.menuName} 
                                        width="100%"
                                    />
                                }
                            </figure> 
                            {this.props.menuInfo && this.props.menuInfo.menuCombo === 1 && (
                                <div>
                                    <div className="triangle-label">
                                            <span>{t('pages.invite.Coupon')}</span>
                                    </div>
                                    {freeTicketCount !== 0 ? 
                                        <div className="bonus-label">
                                            <span className="clr-fff">
                                                {`+`}{t('pages.invite.Advantageous (total)', { freeTicketCount, totalCoupon: freeTicketCount + this.props.menuInfo.menuComboDetail.quantity})}
                                            </span>
                                        </div>
                                    : null }
                                </div>
                            )}
                        </div>
                        <div className="media-body dish-body pl-2">
                            <div className="mt-0 dish-link fs-19">
                                {Object.entries(this.props.menuInfo).length > 0 ? this.props.menuInfo.menuName : t('pages.menu.Menu Price')}
                                {Object.entries(this.props.menuInfo).length > 0 && this.props.menuInfo.menuCombo === 1 && (
                                    <>
                                        <br />
                                        {this.props.menuInfo.menuComboDetail ? this.props.menuInfo.menuComboDetail.includedTaxPriceCombo : 0}円ｘ
                                        {this.props.menuInfo.menuComboDetail ? this.props.menuInfo.menuComboDetail.quantity + freeTicketCount : 0}{t('pages.login.sheet')}
                                        {freeTicketCount !== 0 ? `(${t('pages.invite.Include N ticket free', {number: freeTicketCount})})` : null}
                                    </>
                                )}
                                <div className="line-horizontal menu-name-ln pd-bold"></div>
                            </div>
                            <div className="fs-13 mt-1">
                                <p className="mb-0 fs-13 local-shop"><i className="icon-location"></i>{(this.props.shopInfo.shopName || (this.props.groupInfo ? this.props.groupInfo.groupName : ''))}</p>
                            </div>
                            {this.props.shopInfo && this.props.shopInfo.prefectures && (
                                <div className="fs-13 mt-1">
                                    {this.props.shopInfo.prefectures}
                                    {this.props.shopInfo.city ? this.props.shopInfo.city : ''}
                                    {this.props.shopInfo.address ? this.props.shopInfo.address : ''} 
                                    {this.props.shopInfo.building ? this.props.shopInfo.building : ''}
                                </div>
                            )}

                            {/* <div className="dish-price fs-14 font-weight-bold">
                                ¥{this.props.menuInfo.includedTaxPrice && 
                                    this.props.menuInfo.includedTaxPrice.toLocaleString()}
                                円
                                <span className="fs-12">
                                    （
                                    {this.props.menuInfo.taxType === 'DONATION' ? t('pages.invite.TaxDonation') : t('pages.invite.Tax')}
                                    {this.props.menuInfo.excludedTaxPrice && this.props.menuInfo.excludedTaxPrice.toLocaleString()}
                                    円）
                                </span>
                            </div> */}
                        </div>
                    </div>
                    {this.props.menuInfo && this.props.menuInfo.description && (
                        <div className="fs-13 des-menu mt-2 break-line break-all">
                            {/* <ShowMoreText
                                lines={3}
                                more={t('pages.mypage.showmore')}
                                less={t('pages.mypage.showless')}
                                anchorClass='showmore'
                                onClick={this.executeOnClick}
                                expanded={false}
                                width={0}
                            >
                                <span className='break-all'>
                                    {this.props.menuInfo.description}
                                </span>
                            </ShowMoreText> */}
                            {this.props.menuInfo.description}
                        </div>
                    )}
                    {this.props.menuInfo.menuComboDetail && 
                        this.props.menuInfo.menuComboDetail.quantityFreeDes && (
                        <>
                                <div className="arrow-up"></div>
                                <div className="div-free-ticket-desc break-line break-word">
                                    <div className="font-weight-bold">{t('pages.invite.Bonus')}</div>
                                    <div>{this.props.menuInfo.menuComboDetail.quantityFreeDes}</div>
                                </div>
                        </>
                    )}
                    {(freeTicketCount !== 0) && (freeTicketCount !== null) && (freeTicketCount !== undefined) && (
                        <div className={(feePaidByShop && typeof feePaidByShop !== 'undefined') ? "mt-2 mb-3 has-feePaidByShop" : "mt-2 mb-3"}>
                            <span className="menu-combo-total-coupons">
                                {`+`}{t('pages.invite.Advantageous (total)', { freeTicketCount, totalCoupon: freeTicketCount + this.props.menuInfo.menuComboDetail.quantity})}
                            </span>
                        </div>
                    )}
                    
                    {feePaidByShop && typeof feePaidByShop !== 'undefined' && (
                        <div className="mt-2 mb-3">
                            <span className="menu-combo-total-coupons">
                                {t('pages.invite.Fee paid by shop')}
                            </span>
                        </div>
                    )}

                    {this.props.menuInfo.menuExpiration && (
                        <div className="mt-2">
                            <span className="fs-12 clr-000000">使用期限</span>
                            <span className="text-red fs-13 mt-2 font-weight-bold ml-2">
                                {moment(this.props.menuInfo?.menuExpiration).format('YYYY年MM月DD日まで')}
                            </span>
                        </div>
                    )}

                    {/*{this.props.menuInfo.menuComboDetail && 
                        this.props.menuInfo.menuComboDetail.expiration && (
                        <div>
                            <span className="fs-12 clr-000000">{t('pages.login.usage period')}</span>
                            <span className="text-red fs-13 mt-2 font-weight-bold ml-2">{t('pages.login.available days', {days: this.props.menuInfo.menuComboDetail.expiration})}</span>
                        </div>
                    )}

                    {expirationEndDate && (
                        <div>
                            <span className="fs-12 clr-000000">{t('pages.login.usage period')}</span>
                            <span className="text-red fs-13 mt-2 font-weight-bold ml-2">
                                {t('pages.login.available to date', {date: `${expirationEndDate.getFullYear()}/${expirationEndDate.getMonth() +
                                    1 < 10 ? '0' : ''}${expirationEndDate.getMonth() +
                                    1}/${expirationEndDate.getDate()}`})}
                            </span>
                        </div>
                    )}*/}

                    {this.props.menuInfo.menuComboDetail && 
                        this.props.menuInfo.menuComboDetail.numberUsePerDay === 1 && (
                            <div>
                                <span className="fs-12 clr-000000">{t('pages.login.usage limit')}</span>
                                <span className="text-red fs-13 font-weight-bold ml-2">{t('pages.login.1 sheet per day')}</span>
                            </div>
                    )}

                    {this.props.menuInfo && (this.props.menuInfo.menuChildType === 3 || this.props.menuInfo.menuChildType === 4 || this.props.menuInfo.menuChildType === 5) && (
                        <button type="button" onClick={this.handleShowListShops} className="btn btn-outline-green-38 mt-2 align-center fs-13" style={{"height": "30px"}}>
                            {t('pages.login.List of stores where this meal voucher can be used')}
                        </button>
                    )}

                    {discountPercent !== 0 &&  (
                        <div className="dish-price-red">
                            {t('pages.invite.discount text')}<br />
                            {t('pages.invite.discount text percent', {discountPercentStr})}
                        </div>
                    )}
                </div>

                {this.state.showListShopPopup && 
					<ListShop
						showListShopPopup={this.state.showListShopPopup}
						handleCloseListShopPopup={this.handleCloseListShopPopup}
					/>
				}
            </>
        );
    }
}

// export default DishInfor;

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({ 
	// saveMenuInfo: (menuInfo) => dispatch(saveMenuInfo(menuInfo)),
    showLoading: (showing) => dispatch(showLoading(showing)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DishInfor));


import React from "react";

const SHOP_NO_IMAGE = require("../../../assets/Mogugori/img/icon-shop-background.png");

export function ShopSearchItem(props) {
  const { item } = props;
  const { introduction } = item;
  const display =
    introduction?.length >= 120
      ? introduction.slice(0, 119) + "…"
      : introduction;
  return (
    <article
      className={props.loading ? "shop loading" : "shop"}
      key={item.shopId}
    >
      <div className="item-shop">
        <div className="shop-thumbnail">
          <a href={`/mogugori/shopDetail/${item.shopId}`} className="link-image-shop">
            <img
              src={
                item.shopImage
                  ? item.shopImage[0] && item.shopImage[0].image
                  : SHOP_NO_IMAGE
              }
              loading="lazy"
              alt=""
            />
          </a>
        </div>
        <div className="shop-content">
          {/*<h3 className="shop-name">{item.shopName}</h3>*/}
          <div className="image-shop-2">
            {(item.shopImage && item.shopImage[1]) && (
              <a href={`/mogugori/shopDetail/${item.shopId}`}>
                <img
                  src={item.shopImage[1].image}
                  alt=""
                />
              </a>
            )}
          </div>
          <div>
            {item.snsTwitter && (
              <a href={item.snsTwitter}>
                <i className="icon-twitter"></i>
              </a>
            )}
            {item.webPage && (
              <a href={item.webPage}>
                <i className="icon-calendar"></i>
              </a>
            )}
            <a href={`/mogugori/shopDetail/${item.shopId}`} className="button-detail">
              <i className="icon-gift"></i>
              おごる！
            </a>
          </div>
        </div>
      </div>
      <div className="clear-both"></div>
    </article>
  );
}

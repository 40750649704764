import React from "react";

export function GroupSpecialGochiKidDetailModal(props) {
  const IMAGE_NO_GROUP_SPECIAL = require("../../../../assets/img/icons/group_special_gochi_kid_blank_logo.png");

  return (
    <div
      className={
        props.showing
          ? "custom-modal-container group-special-gochi-kid-detail-modal show-modal"
          : "custom-modal-container group-special-gochi-kid-detail-modal"
      }
      size="sm"
    >
      <div
        className={
          props.showing
            ? "modal modal-action modal-dialog is-show history-modal"
            : "modal modal-action modal-dialog history-modal"
        }
      >
        <div className="modal-header-history">
          <button
            className="modal-btn modal-btn-close"
            id="modalClose"
            onClick={() => props.handleClose()}
            type="button"
          >
            <svg className="icon">
              <title>閉じる</title>
              <use xlinkHref="/assets/img/common/icon.svg#times" />
            </svg>
          </button>
        </div>
        <div className="title-modal title-modal-charge">
          <div className="title">支援者のご紹介</div>
        </div>
        <div className={`modal-body`}>
          <div className="group-special-name">
            {props.groupSpecialDetail
              ? "「" + props.groupSpecialDetail.gochiKidGroupName + "」"
              : "「こどもごちめし」"}
          </div>
          {!(props.groupSpecialDetail && !props.groupSpecialImage) && (
            <div className="group-special-logo">
              <img
                className={`group-special-logo-img ${
                  !props.groupSpecialImage ? "group-special-logo-no-img" : ""
                }`}
                src={
                  props.groupSpecialDetail
                    ? props.groupSpecialImage
                    : IMAGE_NO_GROUP_SPECIAL
                }
                alt="sponsor"
                loading="lazy"
              />
            </div>
          )}
          {props.groupSpecialDetail ? (
            props.groupSpecialDetail &&
            props.groupSpecialDetail.description && (
              <div className="group-special-descrition">
                {props.groupSpecialDetail.description}
              </div>
            )
          ) : (
            <div className="group-special-descrition">
              「こどもごちめし」は、さまざまな企業や団体、個人の方からのご寄付により、地域の飲食店やこども食堂などを起点として、こども支援をおこなう取り組みです。
            </div>
          )}
        </div>
      </div>
      <div onClick={() => props.handleClose()} className="back-drop" />
    </div>
  );
}

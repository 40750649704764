import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setTempFavoriteMenuAction } from "../../../actions/action_favorite_menu";
import { setTempFavoriteShopAction } from "../../../actions/action_favorite_shop";
import { showNotLoggedModal } from "../../../actions/action_show_not_logged_modal";
import { showWishListModal } from "../../../actions/action_show_wish_list_modal";
import { setTempWishListMenuAction } from "../../../actions/action_wishlist_menu";
import { setTempWishListShopAction } from "../../../actions/action_wishlist_shop";
import {
  toggleFavoriteMenu,
  toggleWishListMenu,
  toggleFavoriteShop,
  toggleWishListShop,
} from "../../../utils";

const NO_IMAGE = require("../../../assets/img/icons/square_blank.png");

export function MenuInfo(props) {
  const userInfo = useSelector((state) => state.userInfo);
  const isLoggedIn = userInfo && userInfo.userId;
  const dispatch = useDispatch();

  let index = props.menu?.menuId
    ? `menu-${props.menu.menuId}`
    : `shop-${props.menu.shopId}`;
  var isMenu = props.menu && props.menu.menuId ? true : false;
  var menuId = props.menu && props.menu.menuId;
  var menuImage =
    props.menu &&
    props.menu.menuImage &&
    props.menu.menuImage.length > 0 &&
    props.menu.menuImage[0].image
      ? props.menu.menuImage[0].image
      : NO_IMAGE;
  var menuName =
    props.menu && props.menu.menuId && props.menu.menuName
      ? props.menu.menuName
      : "";
  var shopId = props.menu && props.menu.shopId;
  var shopImage =
    props.menu &&
    !props.menu.menuId &&
    props.menu.shopImage &&
    props.menu.shopImage.length > 0 &&
    props.menu.shopImage[0].image
      ? props.menu.shopImage[0].image
      : NO_IMAGE;
  var shopName = props.menu && props.menu.shopName;
  var groupName = props.menu && props.menu.groupName;

  const favoriteState = useSelector((state) => state.favoriteMenu);
  const wishListState = useSelector((state) => state.wishListMenu);

  const tempFavorite =
    favoriteState[menuId] !== undefined ? favoriteState[menuId] : null;
  const tempWishList =
    wishListState[menuId] !== undefined ? wishListState[menuId] : null;
  const setTempFavorite = (isFav) => {
    dispatch(setTempFavoriteMenuAction(menuId, isFav));
  };
  const setTempWishList = (isWishList) => {
    dispatch(setTempWishListMenuAction(menuId, isWishList));
  };

  const shopFavouriteState = useSelector((state) => state.favoriteShop);
  const shopWishListState = useSelector((state) => state.wishListShop);

  const tempShopFavorite =
    shopFavouriteState[shopId] !== undefined
      ? shopFavouriteState[shopId]
      : null;
  const tempShopWishList =
    shopWishListState[shopId] !== undefined ? shopWishListState[shopId] : null;
  const setTempShopFavorite = (isFav) => {
    dispatch(setTempFavoriteShopAction(shopId, isFav));
  };
  const setTempShopWishList = (isWishList) => {
    dispatch(setTempWishListShopAction(shopId, isWishList));
  };

  function onToggleMenuWishList() {
    if (!isLoggedIn) {
      dispatch(showNotLoggedModal(true));
      return;
    }
    const current =
      tempWishList !== null ? tempWishList : props.menu.isWishList;
    console.log("TOGGLE: ", current, tempWishList, props.menu.isWishList);
    toggleWishListMenu(props.menu.menuId, current, () => {
      setTempWishList(!current);
      if (!current) dispatch(showWishListModal(true));
    });
    var tmp = {
      menuId: props.menu.menuId,
      tempWishList: !current,
      tempFavorite:
        tempFavorite !== null ? tempFavorite : props.menu.isFavourite,
      menuData: props.menu,
    };
    props.handleUpdateWishListMenu && props.handleUpdateWishListMenu(tmp);
  }

  function onToggleMenuFavorite() {
    if (!isLoggedIn) {
      dispatch(showNotLoggedModal(true));
      return;
    }
    const current =
      tempFavorite !== null ? tempFavorite : props.menu.isFavourite;
    console.log("TOGGLE: ", current, tempFavorite, props.menu.isFavourite);
    toggleFavoriteMenu(props.menu.menuId, current, () => {
      setTempFavorite(!current);
      if (!current) dispatch(showWishListModal(true, true));
    });
    var tmp = {
      menuId: props.menu.menuId,
      tempWishList:
        tempWishList !== null ? tempWishList : props.menu.isWishList,
      tempFavorite: !current,
      menuData: props.menu,
    };
    props.handleUpdateFavoriteMenu && props.handleUpdateFavoriteMenu(tmp);
  }

  function onToggleShopWishList() {
    if (!isLoggedIn) {
      dispatch(showNotLoggedModal(true));
      return;
    }
    const current =
      tempShopWishList !== null ? tempShopWishList : props.menu.isWishList;
    console.log("TOGGLE: ", current, tempShopWishList, props.menu.isWishList);
    toggleWishListShop(props.menu.shopId, current, () => {
      setTempShopWishList(!current);
      if (!current) dispatch(showWishListModal(true));
    });
    var tmp = {
      shopId: props.menu.shopId,
      tempWishList: !current,
      tempFavorite:
        tempShopFavorite !== null ? tempShopFavorite : props.menu.isFavourite,
      shopData: props.menu,
    };
    props.handleUpdateWishListShop && props.handleUpdateWishListShop(tmp);
  }

  function onToggleShopFavorite() {
    if (!isLoggedIn) {
      dispatch(showNotLoggedModal(true));
      return;
    }
    const current =
      tempShopFavorite !== null ? tempShopFavorite : props.menu.isFavourite;
    console.log("TOGGLE: ", current, tempShopFavorite, props.menu.isFavourite);
    toggleFavoriteShop(props.menu.shopId, current, () => {
      setTempShopFavorite(!current);
      if (!current) dispatch(showWishListModal(true, true));
    });
    var tmp = {
      shopId: props.menu.shopId,
      tempWishList:
        tempShopWishList !== null ? tempShopWishList : props.menu.isWishList,
      tempFavorite: !current,
      shopData: props.menu,
    };
    props.handleUpdateFavoriteShop && props.handleUpdateFavoriteShop(tmp);
  }

  const isFavorite = isMenu ? tempFavorite : tempShopFavorite;
  const isWishList = isMenu ? tempWishList : tempShopWishList;

  let urlRedirect = `/shopdetails/${shopId}`;
  if (props.isShopDetailScreen) {
    urlRedirect = `/mypage/kodomo_use/search_shop/${shopId}`;
  } else {
    urlRedirect = isMenu ? `/shopdetails/${shopId || "group-menu"}/menu/${menuId}` : `/shopdetails/${shopId}`;
  }

  return (
    <article key={index} className="products swiper-slide">
      <Link
        className="products-link"
        to={
          urlRedirect
        }
      />
      <div className="products-thumbnail">
        <div className="thumbnail">
          <img
            src={isMenu ? menuImage : shopImage}
            alt={isMenu ? menuName : shopName}
            loading="lazy"
          />
        </div>
        <div className="badge-group">
          {props.menu.menuComboDetail?.quantityFree > 0 && (
            <div className="badge badge-black">無料</div>
          )}
          {props.menu.menuComboDetail?.quantityFreeDes && (
            <div className="badge">お得</div>
          )}
        </div>
        <div className="action-group">
          <div
            className={
              (isWishList !== null ? isWishList : props.menu.isWishList)
                ? "action action-list is-active"
                : "action action-list is-inactive"
            }
          >
            <button
              type="button"
              aria-label="記事を保存"
              onClick={isMenu ? onToggleMenuWishList : onToggleShopWishList}
            >
              <svg className="icon">
                <use xlinkHref="/assets/img/common/icon.svg#list" />
              </svg>
            </button>
          </div>
          <div
            className={
              (isFavorite !== null ? isFavorite : props.menu.isFavourite)
                ? "action action-like is-active"
                : "action action-like is-inactive"
            }
          >
            <button
              type="button"
              aria-label="記事を保存"
              onClick={isMenu ? onToggleMenuFavorite : onToggleShopFavorite}
            >
              <svg className="icon">
                <use xlinkHref="/assets/img/common/icon.svg#heart" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className="shop-header">
        {isMenu && !props.isShopDetailScreen && (
          <div className="text-sm text-gray title-custom">
            {shopName || groupName}
          </div>
        )}
        <h2 className="title text-bold title-custom">
          {isMenu ? (menuName ? menuName : "") : shopName ? shopName : ""}
        </h2>
      </div>
      <hr />
      {isMenu && (
        <div className="price-area">
          <span className="unit">¥</span>
          <span className="num">
            {props.menu.chargeAmount?.toLocaleString()}
          </span>
          {/* <span className="dash">〜</span> */}
        </div>
      )}
      {isMenu && (props.menu.menuCombo === 1 || props.menu.menuComboDetail) && (
        <div className="badge badge-lg badge-red">回数券</div>
      )}
    </article>
  );
}

import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

function NavBar(props) {
  const userInfo = useSelector((state) => state.userInfo);
  const isLoggedIn = userInfo && userInfo.userId;
  const history = useHistory();
  const location = useLocation();
  function goHome() {
    if (location.pathname === '/') {
      window.scrollTo(0, 0);
    } else {
      history.push('/');
    }
  }
  function search() {
    if (location.pathname === '/products') {
      window.scrollTo(0, 0);
    } else {
      history.push('/products');
    }
  }
  function viewSpecials() {
    if (location.pathname === '/specials') {
      window.scrollTo(0, 0);
    } else {
      history.push('/specials');
    }
  }
  function scanQrCode() {
    if (location.pathname === '/qrCode') {
      window.scrollTo(0, 0);
    } else {
      window.location.href = '/qrCode';
      // history.push('/qrCode');
    }
  }
  function login() {
    if (location.pathname === '/login') {
      window.scrollTo(0, 0);
    } else {
      history.push('/login');
    }
  }
  function mypage() {
    if (location.pathname === '/mypage') {
      window.scrollTo(0, 0);
    } else {
      history.push('/mypage');
    }
  }
  return (
    <div className="nav-bar">
      <div onClick={goHome} className="nav-bar-item border-right">
        <i className="icon-home" />
        <div className="title">ホーム</div>
      </div>
      <div onClick={search} className="nav-bar-item border-right">
        <i className="icon-search" />
        <div className="title">検索</div>
      </div>
      <div onClick={viewSpecials} className="nav-bar-item border-right">
        <i className="icon-special" />
        <div className="title">特集記事</div>
      </div>
      <div onClick={scanQrCode} className="nav-bar-item border-right">
        <i className="icon-qrcode" />
        <div className="title">QRコード</div>
      </div>
      {isLoggedIn ? (
        <div onClick={mypage} className="nav-bar-item">
          <i className="icon-user" />
          <div className="title">マイページ</div>
        </div>
      ) : (
        <div onClick={login} className="nav-bar-item">
          <i className="icon-user" />
          <div className="title">マイページ</div>
        </div>
      )}
    </div>
  );
}

export default NavBar;

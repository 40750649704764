const stateDefault =  {
  showing: false,
  isFavorite: false
};

export default (state = stateDefault, action) => {
  switch (action.type){
    case 'SHOW_WISH_LIST_MODAL':
      return {
        showing: action.showing,
        isFavorite: action.isFavorite
      };
    default:
      return state;
  }
};
